// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../../actions/projectStages";
import ModalForm from "./Form";

class CitydModal extends Component {
  onSave = () => {
    if (this.props.projectStage.id) {
      this.props
        .dispatch(Actions.onUpdate(this.props.projectStage.id))
        .then(() => {
          this.props.dispatch(Actions.fetchProjectStages());
        });
    } else {
      this.props.dispatch(Actions.onCreate()).then(() => {
        this.props.dispatch(Actions.fetchProjectStages());
      });
    }
  };

  onCancel = () => {
    this.props.dispatch(Actions.onToggleVisible());
  };

  onChangeName = e => {
    this.props.dispatch(Actions.onChangeName(e.target.value));
  };

  onChangeDescription = e => {
    this.props.dispatch(Actions.onChangeDescription(e.target.value));
  };

  onChangePosition = value => {
    this.props.dispatch(Actions.onChangePosition(value));
  };


  render() {
    const { isLoading, projectStage, isModalVisible } = this.props;
    return (
      <ModalForm
        destroyOnClose
        isLoading={isLoading}
        visible={isModalVisible}
        projectStage={projectStage}
        onSave={this.onSave}
        onCancel={this.onCancel}
        onChangeName={this.onChangeName}
        onChangeDescription={this.onChangeDescription}
        onChangePosition={this.onChangePosition}
      />
    );
  }
}

CitydModal.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  projectStage: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool
};

const mapStateToProps = state => ({
  isLoading: state.projectStages.isLoading,
  isModalVisible: state.projectStages.isModalVisible,
  errors: state.projectStages.errors,
  projectStage: state.projectStages.projectStage
});

export default connect(mapStateToProps)(withRouter(CitydModal));
