import api from "../api";

const Actions = {};

Actions.fetchEntityTypes = () => {
  return (dispatch, getState) => {
    const params = getState().entityTypes.fetchParams;
    dispatch({ type: "FETCH_ENTITY_TYPES_REQUEST" });
    api
      .fetch("/entity_types", params)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_ENTITY_TYPES", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_ENTITY_TYPES_FAILURE", response });
      });
  };
};

Actions.fetchEntityType = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ENTITY_TYPE_REQUEST" });
    return api
      .fetch(`/entity_types/${id}`)
      .then((response) => {
        dispatch({ type: "FETCH_ENTITY_TYPE", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Тип объекта", error);
          });
        }
        dispatch({ type: "FETCH_ENTITY_TYPE_FAILURE", response });
      });
  };
};

Actions.onNew = () => {
  return (dispatch) => {
    dispatch({ type: "ENTITY_TYPE_ADD" });
  };
};

Actions.onUpdate = (id) => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_ENTITY_TYPE_REQUEST" });
    const entityType = getState().entityTypes.entityType;

    const data = {
      entity_type: {
        name: entityType.name,
        icon: entityType.icon,
      },
    };

    return api
      .patch(`/entity_types/${id}`, data)
      .then((response) => {
        dispatch({ type: "PATCH_ENTITY_TYPE", response });
        api.openNotification("success", "Тип объекта", "Успешно обновлен!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Тип объекта", error);
          });
        }
        dispatch({ type: "PATCH_ENTITY_TYPE_FAILURE", response });
      });
  };
};

Actions.onCreate = () => {
  // console.log(ids)
  return (dispatch, getState) => {
    dispatch({ type: "POST_ENTITY_TYPE_REQUEST" });
    const entityType = getState().entityTypes.entityType;

    const data = {
      entity_type: {
        name: entityType.name,
        icon: entityType.icon,
      },
    };

    return api
      .post(`/entity_types`, data)
      .then((response) => {
        dispatch({ type: "POST_ENTITY_TYPE", response });
        api.openNotification("success", "Тип объекта", "Успешно создан!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Тип объекта", error);
          });
        }
        dispatch({ type: "POST_ENTITY_TYPE_FAILURE", response });
      });
  };
};

Actions.onDelete = (id) => {
  return (dispatch) => {
    dispatch({ type: "DELETE_ENTITY_TYPE_REQUEST" });
    return api
      .delete(`/entity_types/${id}`)
      .then((response) => {
        api.openNotification("success", "Тип объекта", "Успешно удален!");
        dispatch({ type: "DELETE_ENTITY_TYPE", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Тип объекта", error);
          });
        }
        dispatch({
          type: "DELETE_ENTITY_TYPE_FAILURE",
        });
      });
  };
};


Actions.onDeleteAttachment = (id) => {
  return (dispatch) => {
    dispatch({
      type: "ATTACHMENT_DELETE_REQUEST",
    });
    return api
      .delete(`/entity_types/${id}`)
      .then((response) => {
        api.openNotification("success", "Файл", "Успешно удален!");
        dispatch({ type: "ATTACHMENT_DELETE_SUCCEED", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Файл", error);
          });
        }
        dispatch({ type: "ATTACHMENT_DELETE_FAILED" });
      });
  };
};

Actions.onToggleVisible = () => {
  return (dispatch) => {
    dispatch({
      type: "ENTITY_TYPE_TOGGLE_VISIBLE",
    });
  };
};

Actions.onSearch = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_ENTITY_TYPE_SEARCH",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangePage = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_ENTITY_TYPE_CHANGE_PAGE",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeName = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITY_TYPE_CHANGE_NAME",
      value: value,
    });
  };
};

Actions.onChangeIcon = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITY_TYPE_CHANGE_ICON",
      value: value,
    });
  };
};

export default Actions;
