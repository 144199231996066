// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import Actions from "../../../actions/suppliers";
import List from "./List";

class Suppliers extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.fetchSuppliers());
  };

  onDelete = (ids) => {
    ids.map((id) => {
      this.props.dispatch(Actions.onDelete(id)).then(() => {
        this.props.dispatch(Actions.fetchSuppliers());
      });
    });
  };

  onNew = () => {
    this.props.dispatch(Actions.onNew());
  };

  onChangePage = (value) => {
    this.props
      .dispatch(Actions.onChangePage(value))
      .then(() => this.props.dispatch(Actions.fetchSuppliers()));
  };

  onSearch = (e) => {
    this.props
      .dispatch(Actions.onSearch(e.target.value))
      .then(() => this.props.dispatch(Actions.fetchSuppliers()));
  };

  onSaveSupplier = (value) => {
    this.props
      .dispatch(Actions.onCreate(value))
      .then(() => this.props.dispatch(Actions.fetchSuppliers()));
  };

  onChangeIsActive = (id, e) => {
    this.props
      .dispatch(Actions.onUpdate(id, e.target.checked))
      .then(() => this.props.dispatch(Actions.fetchSuppliers()));
  };

  render() {
    const { isLoading, isModalVisible, suppliers, meta, fetchParams } =
      this.props;
    return (
      <List
        loading={isLoading}
        isModalVisible={isModalVisible}
        suppliers={suppliers}
        meta={meta}
        fetchParams={fetchParams}
        onDelete={this.onDelete}
        onSearch={this.onSearch}
        onChangePage={this.onChangePage}
        onSaveSupplier={this.onSaveSupplier}
        onChangeIsActive={this.onChangeIsActive}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.suppliers.isLoading,
  isModalVisible: state.suppliers.isModalVisible,
  suppliers: state.suppliers.suppliers,
  meta: state.suppliers.meta,
  fetchParams: state.suppliers.fetchParams,
});

export default connect(mapStateToProps)(Suppliers);
