// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Col, Row, Form, Card, Input, InputNumber } from "antd";

import SelectFetch from "../../../components/SelectFetch";

const FormItem = Form.Item;
const { TextArea } = Input;

class General extends Component {
  render() {
    const {
      getFieldDecorator,
      project,
      onChangeCustomer,
      onChangeEntity,
      onChangeNumber,
      onChangeProjectType,
      onChangeAmount,
      onChangeDescription,
    } = this.props;
    return (
      <React.Fragment>
        <Row gutter={16}>
          <Col md={{ span: 8 }} xs={{ span: 8 }}>
            <FormItem label={"Клиент"}>
              {getFieldDecorator("customer", {
                initialValue: project.customer ? project.customer : null,
                rules: [
                  {
                    required: true,
                    message: "Введите клиента!",
                  },
                ],
              })(
                <SelectFetch
                  showSearch
                  placeholder="Клиент"
                  onChange={onChangeCustomer}
                  selected={project.customer ? project.customer : null}
                  url={"/customers/search/?"}
                />
              )}
            </FormItem>
          </Col>
          <Col md={{ span: 8 }} xs={{ span: 8 }}>
            <FormItem label={"Объект"}>
              {getFieldDecorator("entity", {
                initialValue: project.entity ? project.entity : null,
                rules: [
                  {
                    required: true,
                    message: "Введите объект!",
                  },
                ],
              })(
                <SelectFetch
                  showSearch
                  placeholder="Объект"
                  onChange={onChangeEntity}
                  selected={project.entity ? project.entity : null}
                  url={`/entities/search/?${
                    project.customer
                      ? `customer_id=${project.customer.id}`
                      : null
                  }`}
                />
              )}
            </FormItem>
          </Col>
          <Col md={{ span: 8 }} xs={{ span: 8 }}>
            <FormItem label={"Тип проекта"}>
              {getFieldDecorator("entity_service", {
                initialValue: project.project_type
                  ? project.project_type
                  : null,
                rules: [
                  {
                    required: true,
                    message: "Введите тип проекта!",
                  },
                ],
              })(
                <SelectFetch
                  showSearch
                  showSubtitle
                  placeholder="Тип проекта"
                  onChange={onChangeProjectType}
                  selected={project.project_type ? project.project_type : null}
                  url={`/project_types/search/?`}
                />
              )}
            </FormItem>
          </Col>
          <Col md={{ span: 18 }} xs={{ span: 18 }}>
            <FormItem label={"Договор"}>
              {getFieldDecorator("number", {
                onChange: onChangeNumber,
                initialValue: project.number,
                rules: [
                  {
                    required: true,
                    message: "Введите договор!",
                  },
                ],
              })(<Input />)}
            </FormItem>
          </Col>
          <Col md={{ span: 6 }} xs={{ span: 6 }}>
            <FormItem label={"Сумма"}>
              {getFieldDecorator("amount", {
                onChange: onChangeAmount,
                initialValue: project.amount,
                rules: [
                  {
                    required: true,
                    message: "Ведите сумму!",
                  },
                ],
              })(<InputNumber min={0} style={{ width: "100%" }} />)}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <FormItem label={"Описание"}>
              {getFieldDecorator("Описание", {
                onChange: onChangeDescription,
                initialValue: project.description,
              })(<TextArea autoSize={{ minRows: 2, maxRows: 6 }} />)}
            </FormItem>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

General.propTypes = {
  entity: PropTypes.object,
};

export default General;
