// @flow
import React, { Component } from "react";
import { Modal, Row, Col, Form } from "antd";
import PropTypes from "prop-types";

import SelectFetch from "../../../components/SelectFetch";

const FormItem = Form.Item;

class ModalForm extends Component {
  onSave = () => {
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }

      this.props.onSave();
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      supplier,
      visible,
      onCancel,
      onChangeCompany,
    } = this.props;

    return (
      <Modal
        title={`Добавить подрядчика`}
        destroyOnClose
        visible={visible}
        width={800}
        onOk={this.onSave}
        onCancel={onCancel}
      >
        <Form layout={"vertical"}>
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col span={24}>
              <FormItem label={"Подрядчик"}>
                {getFieldDecorator("entity_service", {
                  initialValue: supplier.company
                    ? supplier.company
                    : null,
                  rules: [
                    {
                      required: true,
                      message: "Выберите подрядчика!",
                    },
                  ],
                })(
                  <SelectFetch
                    showSearch
                    showSubtitle
                    placeholder="Подрядчик"
                    onChange={onChangeCompany}
                    selected={supplier.company ? supplier.company : null}
                    url={`/companies/?`}
                  />
                )}
              </FormItem>
            </Col>
            
          </Row>
        </Form>
      </Modal>
    );
  }
}

ModalForm.propTypes = {
  entityType: PropTypes.object,
};

export default Form.create()(ModalForm);
