// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "antd";

import { G2, Chart, Tooltip, Interval } from "bizcharts";

import moment from "moment";
import "moment/locale/ru";

class Bar extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { tickets, isBarLoading } = this.props;

    const data = [
      { name: "London", 月份: "Jan.", 月均降雨量: 18.9 },
      { name: "London", 月份: "Feb.", 月均降雨量: 28.8 },
      { name: "London", 月份: "Mar.", 月均降雨量: 39.3 },
      { name: "London", 月份: "Apr.", 月均降雨量: 81.4 },
      { name: "London", 月份: "May", 月均降雨量: 47 },
      { name: "London", 月份: "Jun.", 月均降雨量: 20.3 },
      { name: "London", 月份: "Jul.", 月均降雨量: 24 },
      { name: "London", 月份: "Aug.", 月均降雨量: 35.6 },
      { name: "Berlin", 月份: "Jan.", 月均降雨量: 12.4 },
      { name: "Berlin", 月份: "Feb.", 月均降雨量: 23.2 },
      { name: "Berlin", 月份: "Mar.", 月均降雨量: 34.5 },
      { name: "Berlin", 月份: "Apr.", 月均降雨量: 99.7 },
      { name: "Berlin", 月份: "May", 月均降雨量: 52.6 },
      { name: "Berlin", 月份: "Jun.", 月均降雨量: 35.5 },
      { name: "Berlin", 月份: "Jul.", 月均降雨量: 37.4 },
      { name: "Berlin", 月份: "Aug.", 月均降雨量: 42.4 },
    ];

    return (
      <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
        <Col span={24}>
          <Chart height={400} padding="auto" data={data} autoFit>
            <Interval
              adjust={[
                {
                  type: "stack",
                },
              ]}
              color="name"
              position="月份*月均降雨量"
            />
            <Tooltip shared />
          </Chart>
        </Col>
      </Row>
    );
  }
}

Bar.propTypes = {
  activities: PropTypes.arrayOf(PropTypes.object),
};
export default Bar;
