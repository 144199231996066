// @flow
import React, {Component} from "react";
import {connect} from "react-redux";
import {compose} from "redux";
import {Link, withRouter} from "react-router-dom";
import Actions from "../../../../../actions/articles";
import {Button, Card, Col, Divider, Modal, Row, Tag, List, Icon} from "antd";
import moment from "moment/moment";
import Can from "../../../../../rules/Can";

const {confirm} = Modal;

class Articles extends Component {
  componentWillMount = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.fetchArticles(this.props.match.params.id));
    }
  };

  onDelete = (id) => {
    let props = this.props;
    confirm({
      title: "Статья",
      content: "Удалить статью?",
      onOk() {
        props.dispatch(Actions.deleteArticle(props.match.params.id, id)).then(() => {
          props.dispatch(Actions.fetchArticles(props.match.params.id));
        });
      },
      onCancel() {
      },
    });
  };

  // onChangePage = value => {
  //   this.props
  //     .dispatch(Actions.onChangePage(value))
  //     .then(() => this.props.dispatch(Actions.fetchFaqs()));
  // };
  //
  // onChangeLimit = (current, size) => {
  //   this.props
  //     .dispatch(Actions.onChangeLimit(size))
  //     .then(() => this.props.dispatch(Actions.fetchFaqs()));
  // };
  //
  // onSortBy = (field, direction) => {
  //   this.props
  //     .dispatch(Actions.onSortBy(field, direction))
  //     .then(() => this.props.dispatch(Actions.fetchFaqs()));
  // };

  render() {
    const {
      currentUser,
      isLoading,
      fetchParams,
      section,
      articles
    } = this.props;

    let contentTags = (item) => {
      if (!item.content_tags) {
        return []
      }

      return item.content_tags.map((contentTag, index) => {
        return (
          <Tag key={index} color="#108ee9">
            {contentTag.name}
          </Tag>
        );
      })
    }

    return (
      <>

        <List
          itemLayout="vertical"
          size="large"
          dataSource={articles}
          renderItem={(item) => (
            <List.Item key={item.id}>
              <Card
                title={
                  <Row>
                    <Col span={24}>
                      <Link to={`/sections/${item.section_id}/articles/${item.id}`}>
                        <h3><Icon type={"file"}/>{` ${item.title}`}</h3>
                      </Link>
                    </Col>
                    <Col span={24}>
                      {item.published_at ? `Опубликовано: ${moment(item.published_at).format("LLL")}` : null}
                      {item.published_by ? `, ${item.published_by.name}` : null}
                    </Col>
                  </Row>
                }
                extra={[
                  <Can
                    key={"1"}
                    role={currentUser.role}
                    perform={"articles:delete"}
                    yes={() =>
                      <Button key="1"
                              type="danger"
                              ghost
                              onClick={() => this.onDelete(item.id)}>
                        Удалить
                      </Button>
                    }
                    no={() => null}
                  />
                ]}
              >
                <Card.Meta
                  description={
                    <Row>
                      <Col span={24}>
                        {contentTags(item)}
                      </Col>
                    </Row>
                  }
                />
              </Card>
            </List.Item>
          )}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.session.currentUser,
  isLoading: state.articles.isLoading,
  fetchParams: state.articles.fetchParams,
  section: state.sections.section,
  articles: state.articles.articles
});

export default compose(
  withRouter,
  connect(mapStateToProps)
)(Articles);
