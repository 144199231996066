// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  DatePicker,
  Select,
  Button,
  Typography,
  Statistic,
  Icon
} from "antd";
import { Pagination } from "antd";
import { Row, Col, PageHeader } from "antd";
import _ from "lodash/array";

import PropTypes from "prop-types";
import accounting from "accounting";

import moment from "moment";
import "moment/locale/ru";

const { Title } = Typography;
const { RangePicker } = DatePicker;
const Option = Select.Option;

class DataList extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  render() {
    const {
      loading,
      report,
      fetchParams,
      period,
      onChangeDateRange,
      onChangePeriod
    } = this.props;

    // render: (effectiveness, record) => `${Number(record.closed / record.total * 100).toFixed(2)}%`,

    const dateFormat = "DD.MM.YYYY";

    const tickets = report.tickets || [];
    const tickets_replaced = report.tickets_replaced || [];

    const closed_tickets =
      tickets.length > 0
        ? Number(tickets[1].value) + Number(tickets[1].value_replaced)
        : 0;
    const total_tickets =
      tickets.length > 0
      ? Number(tickets[0].value) + Number(tickets[0].value_replaced)
        : 0;
    const sla = ((closed_tickets / total_tickets) * 100).toFixed(2);

    const columns = [
      {
        title: "Заявки без замены оборудования",
        children: [
          {
            title: "Показатель",
            dataIndex: "name",
            align: "right",
            width: "25%",
            
          },
          {
            title: "Результат",
            dataIndex: "value",
            align: "center",
            width: "25%"
          },
        ]
      },
      {
        title: "Заявки без замены оборудования",
        children: [
          {
            title: "Показатель",
            dataIndex: "name_replaced",
            align: "right",
            width: "25%",
            
          },
          {
            title: "Результат",
            dataIndex: "value_replaced",
            align: "center",
            width: "25%"
          },
        ]
      },
     
    ];

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`SLA выполнения за период c ${fetchParams.startDate} по ${fetchParams.endDate}`}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }} style={{ paddingBottom: 15 }}>
            <Col span={12}>
              <Table
                bordered
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={tickets}
                rowKey="id"
              />
            </Col>
          </Row>
        }
      >
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <RangePicker
              style={{ width: "100%" }}
              value={[
                moment(fetchParams.startDate, dateFormat),
                moment(fetchParams.endDate, dateFormat)
              ]}
              format={dateFormat}
              onChange={onChangeDateRange}
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <Select
              style={{ width: "100%" }}
              value={period}
              placeholder="Период"
              optionFilterProp="children"
              filterOption={false}
              onChange={onChangePeriod}
            >
              <Option value={0}>произвольный период</Option>
              <Option value={1}>этот месяц</Option>
              <Option value={2}>этот квартал</Option>
              <Option value={3}>этот год</Option>
              <Option value={4}>прошлый месяц</Option>
              <Option value={5}>прошлый квартал</Option>
              <Option value={6}>прошлый год</Option>
            </Select>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{ textAlign: "right" }}
          >
            <Statistic
              title="SLA выполнения"
              value={sla > 0 ? sla : 0}
              precision={2}
              valueStyle={{ color: "#cf1322" }}
              // prefix={<Icon type="arrow-down" />}
              suffix="%"
            />
          </Col>
        </Row>
      </PageHeader>
    );
  }
}

DataList.propTypes = {
  payments: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object
};

export default DataList;
