// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import Actions from "../../../actions/tickets";
import FaqActions from "../../../actions/faqs";
import NoticesActions from "../../../actions/notices";
import TicketsList from "./TicketsList";

class Tickets extends Component {        
  componentWillMount = () => {
    this.props.dispatch(Actions.onSetFilters()).then(() => {
      this.props.dispatch(Actions.fetchTickets()).then(() => {
        this.props.dispatch(
          NoticesActions.fetchContextNotices("ticket_comment_marks")
        );
      });
    });
  };

  onDelete = (ids) => {
    ids.map((id) => {
      return this.props.dispatch(Actions.onDelete(id)).then(() => {
        this.props.dispatch(Actions.fetchTickets());
      });
    });
    
  };

  onSearch = (e) => {
    this.props
      .dispatch(Actions.onSearch(e.target.value))
      .then(() => this.props.dispatch(Actions.fetchTickets()));
  };

  onChangePage = (value) => {
    this.props
      .dispatch(Actions.onChangePage(value))
      .then(() => this.props.dispatch(Actions.fetchTickets()))
      .then(() => {
        localStorage.setItem(
          "ticketsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeLimit = (current, size) => {
    this.props
      .dispatch(Actions.onChangeLimit(size))
      .then(() => this.props.dispatch(Actions.fetchTickets()))
      .then(() => {
        localStorage.setItem(
          "ticketsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onSortBy = (field, direction) => {
    this.props
      .dispatch(Actions.onSortBy(field, direction))
      .then(() => this.props.dispatch(Actions.fetchTickets()))
      .then(() => {
        localStorage.setItem(
          "ticketsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  // filters
  onClearFilters = () => {
    this.props.dispatch(Actions.onClearFilters()).then(() => {
      localStorage.setItem(
        "ticketsFilters",
        JSON.stringify(this.props.fetchParams)
      );
    });
  };

  onChangeFilterStatus = (status) => {
    this.props
      .dispatch(Actions.onChangeFilterStatus(status))
      .then(() => this.props.dispatch(Actions.fetchTickets()))
      .then(() => {
        localStorage.setItem(
          "ticketsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeFilterReplaced = (value) => {
    this.props
      .dispatch(Actions.onChangeFilterReplaced(value))
      .then(() => this.props.dispatch(Actions.fetchTickets()))
      .then(() => {
        localStorage.setItem(
          "ticketsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeFilterOrdered = (value) => {
    this.props
      .dispatch(Actions.onChangeFilterOrdered(value))
      .then(() => this.props.dispatch(Actions.fetchTickets()))
      .then(() => {
        localStorage.setItem(
          "ticketsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeFilterPeriod = (value, dateString) => {
    this.props
      .dispatch(Actions.onChangeFilterPeriod(dateString[0], dateString[1]))
      .then(() => this.props.dispatch(Actions.fetchTickets()))
      .then(() => {
        localStorage.setItem(
          "ticketsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeFilterFinishPeriod = (value, dateString) => {
    this.props
      .dispatch(
        Actions.onChangeFilterFinishPeriod(dateString[0], dateString[1])
      )
      .then(() => this.props.dispatch(Actions.fetchTickets()))
      .then(() => {
        localStorage.setItem(
          "ticketsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeFilterCreatedPeriod = (value, dateString) => {
    this.props
      .dispatch(
        Actions.onChangeFilterCreatedPeriod(dateString[0], dateString[1])
      )
      .then(() => this.props.dispatch(Actions.fetchTickets()))
      .then(() => {
        localStorage.setItem(
          "ticketsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeFilterUser = (values) => {
    this.props
      .dispatch(Actions.onChangeFilterUser(values))
      .then(() => this.props.dispatch(Actions.fetchTickets()))
      .then(() => {
        localStorage.setItem(
          "ticketsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeFilterUsers = (values) => {
    this.props
      .dispatch(Actions.onChangeFilterUsers(values))
      .then(() => this.props.dispatch(Actions.fetchTickets()))
      .then(() => {
        localStorage.setItem(
          "ticketsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeFilterCustomer = (value) => {
    this.props
      .dispatch(Actions.onChangeFilterCustomer(value))
      .then(() => this.props.dispatch(Actions.fetchTickets()))
      .then(() => {
        localStorage.setItem(
          "ticketsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeFilterMembers = (values) => {
    this.props
      .dispatch(Actions.onChangeFilterMembers(values))
      .then(() => this.props.dispatch(Actions.fetchTickets()))
      .then(() => {
        localStorage.setItem(
          "ticketsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeFilterPriorities = (values) => {
    this.props
      .dispatch(Actions.onChangeFilterPriorities(values))
      .then(() => this.props.dispatch(Actions.fetchTickets()))
      .then(() => {
        localStorage.setItem(
          "ticketsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeFilterCategories = (values) => {
    // console.log(values)
    this.props
      .dispatch(Actions.onChangeFilterCategories(values))
      .then(() => this.props.dispatch(Actions.fetchTickets()))
      .then(() => {
        localStorage.setItem(
          "ticketsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeFilterServices = (values) => {
    this.props
      .dispatch(Actions.onChangeFilterServices(values))
      .then(() => this.props.dispatch(Actions.fetchTickets()))
      .then(() => {
        localStorage.setItem(
          "ticketsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeFilterRated = (value) => {
    this.props
      .dispatch(Actions.onChangeFilterRated(value))
      .then(() => this.props.dispatch(Actions.fetchTickets()))
      .then(() => {
        localStorage.setItem(
          "ticketsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  downloadReport = () => {
    let params = this.props.fetchParams;
    Actions.downloadReport(
      params,
      "tickets.xlsx",
      params.startDate
        ? `tickets ${params.startDate}-${params.endDate}.xlsx`
        : "tickets.xlsx"
    );
  };

  // notices
  fetchNotices = () => {
    this.props.dispatch(Actions.fetchContextNotices("ticket_comment_marks"));
  };

  onClearNotices = () => {
    this.props.dispatch(NoticesActions.onClearNotices("ticket_comment_marks"));
  };

  // faqs
  onFaqToggle = () => {
    this.props.dispatch(FaqActions.onDrawerToggle()).then(() => {
      this.props.dispatch(FaqActions.fetchFaqs(2, 1));
    });
  };

  render() {
    const {
      currentUser,
      isLoading,
      tickets,
      meta,
      fetchParams,
      isNoticesLoading,
      notices,
    } = this.props;
    return (
      <TicketsList
        loading={isLoading}
        currentUser={currentUser}
        tickets={tickets}
        meta={meta}
        fetchParams={fetchParams}
        notices={notices}
        onDelete={this.onDelete}
        onSearch={this.onSearch}
        onChangePage={this.onChangePage}
        onChangeLimit={this.onChangeLimit}
        onSortBy={this.onSortBy}
        // filters
        onClearFilters={this.onClearFilters}
        onChangeFilterStatus={this.onChangeFilterStatus}
        onChangeFilterReplaced={this.onChangeFilterReplaced}
        onChangeFilterOrdered={this.onChangeFilterOrdered}
        onChangeFilterPeriod={this.onChangeFilterPeriod}
        onChangeFilterFinishPeriod={this.onChangeFilterFinishPeriod}
        onChangeFilterCreatedPeriod={this.onChangeFilterCreatedPeriod}
        onChangeFilterUser={this.onChangeFilterUser}
        onChangeFilterUsers={this.onChangeFilterUsers}
        onChangeFilterCustomer={this.onChangeFilterCustomer}
        onChangeFilterMembers={this.onChangeFilterMembers}
        onChangeFilterPriorities={this.onChangeFilterPriorities}
        onChangeFilterCategories={this.onChangeFilterCategories}
        onChangeFilterServices={this.onChangeFilterServices}
        onChangeFilterRated={this.onChangeFilterRated}
        downloadReport={this.downloadReport}
        // notices
        isNoticesLoading={isNoticesLoading}
        onClearNotices={this.onClearNotices}
        // faqs
        onFaqToggle={this.onFaqToggle}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  isLoading: state.tickets.isLoading,
  tickets: state.tickets.tickets,
  meta: state.tickets.meta,
  fetchParams: state.tickets.fetchParams,
  // notices
  notices: state.notices.notices,
  isNoticesLoading: state.notices.isLoading,
});

export default connect(mapStateToProps)(Tickets);
