// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Col,
  Row,
  DatePicker,
  Select,
  Form,
  Drawer,
} from "antd";
import moment from "moment";
import "moment/locale/ru";
import SelectMultiFetch from "../../../../components/SelectMultiFetch";
import Can from "../../../../rules/Can";

const { RangePicker } = DatePicker;
const { Option, OptGroup } = Select;
const FormItem = Form.Item;

class Filter extends Component {

  render() {
    const {
      visible,
      currentUser,
      startCreatedDate,
      endCreatedDate,
      startDueByDate,
      endDueByDate,
      users,
      onChangeTaskFilterCreatedPeriod,
      onChangeTaskFilterDueByPeriod,
      onChangeTaskFilterUsers,
      onClose,
    } = this.props;
    const {
      getFieldDecorator
    } = this.props.form;

    return (
      <Drawer
        visible={visible}
        title="Фильтры"
        width={540}
        placement={"right"}
        closable={false}
        onClose={onClose}
      >
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <FormItem label={"Дата создания"}>
              {getFieldDecorator("start", {
                onChange: onChangeTaskFilterCreatedPeriod,
                initialValue: [
                  startCreatedDate ? moment(startCreatedDate, "DD.MM.YYYY") : null,
                  endCreatedDate ? moment(endCreatedDate, "DD.MM.YYYY") : null,
                ],
              })(
                <RangePicker
                  showTime={false}
                  format={"DD.MM.YYYY"}
                  placeholder={["начало", "окончание"]}
                  style={{ width: "100%" }}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <FormItem label={"Выполнить до"}>
              {getFieldDecorator("finish", {
                onChange: onChangeTaskFilterDueByPeriod,
                initialValue: [
                  startDueByDate ? moment(startDueByDate, "DD.MM.YYYY") : null,
                  endDueByDate ? moment(endDueByDate, "DD.MM.YYYY") : null,
                ],
              })(
                <RangePicker
                  showTime={false}
                  format={"DD.MM.YYYY"}
                  placeholder={["начало", "окончание"]}
                  style={{ width: "100%" }}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Пользователи"}>
              {getFieldDecorator("users", {
                initialValue: users ? users : [],
              })(
                <SelectMultiFetch
                  showSearch
                  placeholder="Пользователи"
                  onChange={onChangeTaskFilterUsers}
                  selected={users ? users : []}
                  url={"/users/search/?all='true'"}
                />
              )}
            </FormItem>
          </Col>
        </Row>
      </Drawer>
    );
  }
}

Filter.propTypes = {
  entity: PropTypes.object,
};

export default Form.create()(Filter);
