// @flow
import React, { Component } from "react";
import { Table, Button, Tabs, Row, Col, Tooltip } from "antd";
import PropTypes from "prop-types";
import update from "immutability-helper";
import { v4 as uuidv4 } from "uuid";
import Option from "./Option";

const { TabPane } = Tabs;

class Options extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      isVisible: false,
      option: {},
    };
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  onDelete = () => {
    this.props.onDelete(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  // option
  onOpenOption = (item) => {
    this.setState({ option: item }, () => {
      this.setState({ isVisible: true });
    });
  };

  onAddOption = () => {
    const { options } = this.props;
    this.setState(
      {
        option: {
          id: uuidv4(),
          position: options.length + 1,
          value: null,
          is_exist: false,
          _destroy: false,
        },
      },
      () => {
        this.setState({ isVisible: true });
      }
    );
  };

  onSaveOption = () => {
    this.setState({ isVisible: false }, () => {
      this.props.onSaveOption(this.state.option);
    });
  };

  onDeleteOptions = () => {
    this.props.onDeleteOptions(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  onCancelOption = () => {
    this.setState({ isVisible: false });
  };

  onChangeOptionValue = (e) => {
    this.setState({
      option: update(this.state.option, {
        value: { $set: e.target.value },
      }),
    });
  };

  onChangePosition = (value) => {
    this.setState({
      option: update(this.state.option, {
        position: { $set: value },
      }),
    });
  };

  render() {
    const { selectedRowKeys, isVisible, option } = this.state;
    const { options } = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: "Значение",
        dataIndex: "value",
        render: (id, record) => (
          <a onClick={this.onOpenOption.bind(this, record)}>{record.value}</a>
        ),
        key: "value",
        width: "100%",
      },
    ];

    const operations = hasSelected ? (
      <Tooltip placement="bottom" title={"Удалить значение"}>
        <Button type="danger" icon="delete" onClick={this.onDeleteOptions} />
      </Tooltip>
    ) : (
      <Tooltip placement="bottom" title={"Добавить значение"}>
        <Button icon="plus" onClick={this.onAddOption} />
      </Tooltip>
    );

    return (
      <Tabs defaultActiveKey="1" tabBarExtraContent={operations}>
        <TabPane tab="Опции" key="1">
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col
              span={24}
              style={{
                paddingTop: "15px",
                paddingBottom: "15px",
              }}
            >
              <Table
                bordered
                rowSelection={rowSelection}
                size={"small"}
                pagination={false}
                columns={columns}
                dataSource={options}
                onChange={this.onChangeTable}
                rowKey="id"
              />
            </Col>
            <Col span={24}>
              <Option
                visible={isVisible}
                option={option}
                onSave={this.onSaveOption}
                onCancel={this.onCancelOption}
                onChangeValue={this.onChangeOptionValue}
                onChangePosition={this.onChangePosition}
              />
            </Col>
          </Row>
        </TabPane>
      </Tabs>
    );
  }
}

Options.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
};

export default Options;
