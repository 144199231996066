// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../../actions/posts";
import { Spin } from "antd";
import Form from "./Form";

class Post extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: false
    };
  }

  componentWillMount = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.fetchPost(this.props.match.params.id));
    } else {
      this.setState({ isNew: true }, () => {
        this.props.dispatch(Actions.onNew());
      });
    }
  };

  onSave = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.onUpdate(this.props.match.params.id));
    } else {
      this.props.dispatch(Actions.onCreate()).then(() => {
        if (!this.props.errors) {
          this.props.history.push(`/posts/${this.props.post.id}/edit`);
        }
      });
    }
  };

  onClose = () => {
    this.props.history.push(`/posts`);
  };

  onPublish = value => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.onPublish(this.props.match.params.id, value));
    }
  };

  // general
  onChangeTitle = e => {
    this.props.dispatch(Actions.onChangeTitle(e.target.value));
  };

  onChangeBody = (content) => {
    this.props.dispatch(Actions.onChangeBody(content));
  };

  // sidebar
  onChangePostAvailable = value => {
    this.props.dispatch(Actions.onChangePostAvailable(value));
  };

  //   onChangePostUserRoles = values => {
  //     values.map(value => {
  //       this.props.dispatch(Actions.onDelete(id));
  //     });
  //   };

  onChangePostUserRoles = values => {
    // console.log(values)
    this.props.dispatch(Actions.onChangePostUserRoles(values));
  };

  render() {
    const { isNew } = this.state;
    const { isLoading, post } = this.props;
    return isLoading ? (
      <Spin />
    ) : (
      <Form
        isNew={isNew}
        post={post}
        onSave={this.onSave}
        onClose={this.onClose}
        onPublish={this.onPublish}
        // general
        onChangeTitle={this.onChangeTitle}
        onChangeBody={this.onChangeBody}
        // sidebar
        onChangePostAvailable={this.onChangePostAvailable}
        onChangePostUserRoles={this.onChangePostUserRoles}
      />
    );
  }
}

Post.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  post: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool
};

const mapStateToProps = state => ({
  isLoading: state.posts.isLoading,
  errors: state.posts.errors,
  post: state.posts.post
});

export default connect(mapStateToProps)(withRouter(Post));
