// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  DatePicker,
  Select,
  Button,
  Typography,
  Statistic,
  Icon,
} from "antd";
import { Pagination } from "antd";
import { Row, Col, PageHeader } from "antd";
import _ from "lodash/array";

import PropTypes from "prop-types";
import accounting from "accounting";

import moment from "moment";
import "moment/locale/ru";

import Details from "./Details";

const { RangePicker } = DatePicker;
const Option = Select.Option;

class DataList extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  render() {
    const {
      loading,
      tasks,
      fetchParams,
      period,
      onChangeDateRange,
      onChangePeriod,
      onChangeRole,
      // details
      isLoadingDetails,
      onDetails,
      details,
    } = this.props;

    // render: (effectiveness, record) => `${Number(record.closed / record.total * 100).toFixed(2)}%`,

    const dateFormat = "DD.MM.YYYY";

    const columns = [
      {
        title: "Имя",
        dataIndex: "name",
        align: "left",
        width: "30%",
      },
      {
        title: "Всего",
        dataIndex: "total",
        render: (total) => accounting.formatNumber(total, 0, " "),
        align: "center",
        width: "10%",
      },
      {
        title: "Просрочено",
        dataIndex: "total",
        // render: (total, record) => accounting.formatNumber(0 , 0, " "),
        render: (total, record) => "для доработки",
        align: "center",
        width: "10%",
      },
      {
        title: "Время выполнения",
        dataIndex: "duration",
        render: (duration) =>
          duration > 0
            ? moment.duration(duration / 3600, "hours").humanize()
            : null,
        align: "center",
        width: "10%",
      },
      {
        title: "Статусы",
        children: [
          {
            title: "новая",
            dataIndex: "pending",
            render: (pending, record) => (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {pending > 0 ? (
                  <a>
                    <Details
                      loading={isLoadingDetails}
                      progress={record.progress}
                      name={pending}
                      data={details}
                      onChange={onDetails.bind(this, record.id, "1")}
                    />
                  </a>
                ) : (
                  ""
                )}
              </div>
            ),
            align: "center",
            width: "10%",
          },
          {
            title: "в работе",
            dataIndex: "processing",
            render: (processing, record) => (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {processing > 0 ? (
                  <a>
                    <Details
                      loading={isLoadingDetails}
                      progress={record.progress}
                      name={processing}
                      data={details}
                      onChange={onDetails.bind(this, record.id, "2")}
                    />
                  </a>
                ) : (
                  ""
                )}
              </div>
            ),
            align: "center",
            width: "10%",
          },
          {
            title: "выполнена",
            dataIndex: "finished",
            render: (finished, record) => (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {finished > 0 ? (
                  <a>
                    <Details
                      loading={isLoadingDetails}
                      progress={record.progress}
                      name={finished}
                      data={details}
                      onChange={onDetails.bind(this, record.id, "3")}
                    />
                  </a>
                ) : (
                  ""
                )}
              </div>
            ),
            align: "center",
            width: "10%",
          },
          {
            title: "отменена",
            dataIndex: "canceled",
            render: (canceled, record) => (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {canceled > 0 ? (
                  <a>
                    <Details
                      loading={isLoadingDetails}
                      progress={record.progress}
                      name={canceled}
                      data={details}
                      onChange={onDetails.bind(this, record.id, "4")}
                    />
                  </a>
                ) : (
                  ""
                )}
              </div>
            ),
            align: "center",
            width: "10%",
          },
        ],
      },
    ];

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Задачи по исполнителям за период c ${fetchParams.startDate} по ${fetchParams.endDate}`}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }} style={{ paddingBottom: 15 }}>
            <Col span={24}>
              <Table
                bordered
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={tasks}
                rowKey="id"
              />
            </Col>
          </Row>
        }
      >
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <RangePicker
              style={{ width: "100%" }}
              value={[
                moment(fetchParams.startDate, dateFormat),
                moment(fetchParams.endDate, dateFormat),
              ]}
              format={dateFormat}
              onChange={onChangeDateRange}
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <Select
              style={{ width: "100%" }}
              value={period}
              placeholder="Период"
              optionFilterProp="children"
              filterOption={false}
              onChange={onChangePeriod}
            >
              <Option value={0}>произвольный период</Option>
              <Option value={1}>этот месяц</Option>
              <Option value={2}>этот квартал</Option>
              <Option value={3}>этот год</Option>
              <Option value={4}>прошлый месяц</Option>
              <Option value={5}>прошлый квартал</Option>
              <Option value={6}>прошлый год</Option>
            </Select>
          </Col>
          {/* <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <Select
              style={{ width: "100%" }}
              value={fetchParams.role}
              placeholder="Роль"
              filterOption={false}
              onChange={onChangeRole}
            >
              <Option value={3}>инженер</Option>
              <Option value={4}>менеджер</Option>
              <Option value={2}>руководитель</Option>
              <Option value={1}>администратор</Option>
            </Select>
          </Col> */}
        </Row>
      </PageHeader>
    );
  }
}

DataList.propTypes = {
  payments: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default DataList;
