const rules = {
  engineer: {
    static: [
      "entity_tasks:start",
      "entity_tasks:finish",
      "dashboard:view",
    ],
    dynamic: {
      "documents:delete": ({ userId, ownerId }) => {
        if (!userId || !ownerId) return false;
        return userId === ownerId;
      },
      "tasks:due_by": ({ userId, ownerId }) => {
        if (!userId || !ownerId) return false;
        return userId === ownerId;
      },
      "tasks:description": ({ userId, ownerId }) => {
        if (!userId || !ownerId) return false;
        return userId === ownerId;
      }
    }
  },
  supervisor: {
    static: [
      "tickets:create",
      "tickets:edit",
      "tickets:delete",
      "tickets:assignees",
      "entities:create",
      "entities:edit",
      "entities:assignees",
      "entities:delete",
      "entity_tasks:create",
      "entity_tasks:edit",
      "entity_tasks:delete",
      "entity_tasks:start",
      "entity_tasks:finish",
      "entity_tasks:assignees",
      "orders:view",
      "orders:assignees",
      "orders:finance",
      "order_tasks:create",
      "order_tasks:edit",
      "order_tasks:delete",
      "order_tasks:assignees",
      "order_tasks:activities",
      "dashboard:view",
      "dashboard:gantt",
      "tasks:assignees",
      "projects:complete",
    ],
    dynamic: {
      "documents:delete": ({ userId, ownerId }) => {
        if (!userId || !ownerId) return false;
        return userId === ownerId;
      },
      "tasks:due_by": ({ userId, ownerId }) => {
        if (!userId || !ownerId) return false;
        return userId === ownerId;
      },
      "tasks:description": ({ userId, ownerId }) => {
        if (!userId || !ownerId) return false;
        return userId === ownerId;
      }
    }
  },
  manager: {
    static: [
      "tickets:create",
      "tickets:edit",
      "tickets:delete",
      "tickets:assignees",
      "entities:create",
      "entities:edit",
      "entities:assignees",
      "entities:delete",
      "entity_tasks:create",
      "entity_tasks:edit",
      "entity_tasks:delete",
      "entity_tasks:start",
      "entity_tasks:finish",
      "entity_tasks:assignees",
      "orders:view",
      "orders:assignees",
      "orders:finance",
      "orders:dueBy",
      "order_tasks:create",
      "order_tasks:edit",
      "order_tasks:delete",
      "order_tasks:assignees",
      "order_tasks:activities",
      "dashboard:view",
      "dashboard:gantt",
      "dashboard:statistic",
      "tasks:assignees",
      "projects:complete",
    ],
    dynamic: {
      "documents:delete": ({ userId, ownerId }) => {
        if (!userId || !ownerId) return false;
        return userId === ownerId;
      },
      "tasks:due_by": ({ userId, ownerId }) => {
        if (!userId || !ownerId) return false;
        return userId === ownerId;
      },
      "tasks:description": ({ userId, ownerId }) => {
        if (!userId || !ownerId) return false;
        return userId === ownerId;
      },
    }
  },

  administrator: {
    static: [
      "tickets:create",
      "tickets:edit",
      "tickets:delete",
      "tickets:assignees",
      "tickets:due_by",
      "entities:create",
      "entities:edit",
      "entities:assignees",
      "entities:delete",
      "entity_tasks:create",
      "entity_tasks:edit",
      "entity_tasks:delete",
      "entity_tasks:start",
      "entity_tasks:finish",
      "entity_tasks:assignees",
      "orders:view",
      "orders:assignees",
      "orders:finance",
      "orders:personal",
      "orders:dueBy",
      "order_tasks:create",
      "order_tasks:edit",
      "order_tasks:delete",
      "order_tasks:assignees",
      "order_tasks:activities",
      "supportMessages:edit",
      "customers:view",
      "dashboard:view",
      "dashboard:gantt",
      "dashboard:statistic",
      "documents:delete",
      "profile:finance",
      "projects:delete",
      "projects:complete",
      "tasks:due_by",
      "tasks:description",
      "tasks:assignees",
      "knowledgeBase:create",
      "sections:create",
      "sections:edit",
      "sections:delete",
      "articles:create",
      "articles:edit",
      "articles:delete"
    ]
  }
};

export default rules;