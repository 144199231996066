// @flow
import React from 'react';
import { Link } from "react-router-dom";
import { Result } from 'antd';

const  NotAuthorized = () =>
<Result
    status="403"
    title="403"
    subTitle="Вы не авторизованы для этой операции!"
    extra={<Link to="/">Домой</Link>}
  />

export default NotAuthorized;