// @flow
import React, {Component} from "react";
import {Link, withRouter} from "react-router-dom";
import {Badge, Button, Col, Icon, Input, PageHeader, Pagination, Row, Table, Tag, Tooltip, Typography,} from "antd";
import Can from "../../../rules/Can";
import Presentation from "../../../components/Presentation";

import PropTypes from "prop-types";
import AssigneesCell from "../../../components/AssigneesCell";
import ExpandDescription from "./ExpandDescription";
import NoticeIcon from "../../../components/NoticeIcon";
import Rating from "../../../components/Rating";

import moment from "moment";
import "moment/locale/ru";
import Filter from "./Filter";

const Search = Input.Search;
const { Paragraph } = Typography;

function statuses(status) {
  switch (status) {
    case "pending":
      return "error";
    case "processing":
      return "processing";
    case "closed":
      return "success";
    default:
      return "processing";
  }
}

function serviceTag(service) {
  return (
    <Tag key={service.id} color="#108ee9">
      {service.name}
    </Tag>
  );
}

class TicketsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      isFilterVisible: false,
    };
  }

  onFilterToggle = () => {
    this.setState({ isFilterVisible: !this.state.isFilterVisible });
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  onDelete = () => {
    this.props.onDelete(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  onChangeTable = (pagination, filters, sorter) => {
    let direction = "desc";
    if (sorter.order === "descend") {
      direction = "desc";
    } else if (sorter.order === "ascend") {
      direction = "asc";
    }
    this.props.onSortBy(sorter.field, direction);
  };

  onItemClick = (item) => {
    this.props.history.push(`/tickets/${item.parent_id}/edit`);
  };

  onClearNotices = () => {
    this.props.onClearNotices();
  };

  renderExpandedRow = (record) => {
    return (
      <ExpandDescription
        created_at={record.created_at}
        due_by={record.due_by}
        status_locale={record.status_locale}
        priority={record.ticket_priority}
        incident_locale={record.incident_locale}
        description={record.description}
      />
    );
  };

  render() {
    const { selectedRowKeys, isFilterVisible } = this.state;
    const {
      loading,
      currentUser,
      tickets,
      meta,
      fetchParams,
      isNoticesLoading,
      notices,
      onSearch,
      onChangePage,
      onChangeLimit,
      // filters
      onClearFilters,
      onChangeFilterStatus,
      onChangeFilterReplaced,
      onChangeFilterOrdered,
      onChangeFilterPeriod,
      onChangeFilterFinishPeriod,
      onChangeFilterCreatedPeriod,
      onChangeFilterUser,
      onChangeFilterUsers,
      onChangeFilterCustomer,
      onChangeFilterMembers,
      onChangeFilterPriorities,
      onChangeFilterCategories,
      onChangeFilterServices,
      onChangeFilterRated,
      downloadReport,
    } = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: "Статус Приоритет Замена",
        dataIndex: "status",
        render: (status, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <Badge
                status={statuses(record.status.id)}
                text={record.status.name}
              />
            </span>
            <span style={{ opacity: "0.7", fontSize: "12px" }}>
              {record.ticket_priority ? record.ticket_priority.name : null}
            </span>
            <span style={{ opacity: "0.7", fontSize: "12px" }}>
              {record.is_replaced ? "замена" : null}
            </span>
          </div>
        ),
        key: "status",
        align: "center",
        width: "10%",
      },
      {
        title: "Номер",
        dataIndex: "number",
        render: (number, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <Link to={`/tickets/${record.id}/edit`}>{number}</Link>
            </span>
            <span>
              <Rating data={record.rates} />
            </span>
          </div>
        ),
        key: "number",
        align: "center",
        width: "10%",
      },
      {
        title: "План",
        dataIndex: "due_by",
        render: (due_by) => (
          <span style={{ fontSize: 12 }}>{moment(due_by).format("LLL")}</span>
        ),
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "due_by",
        align: "center",
        width: "10%",
      },
      {
        title: "Выполнение",
        dataIndex: "finish",
        render: (finish) =>
          finish ? (
            <span style={{ fontSize: 12 }}>{moment(finish).format("LLL")}</span>
          ) : null,
        key: "finish",
        align: "center",
        width: "10%",
      },
      {
        title: "Клиент / Объект",
        dataIndex: "customer",
        render: (customer, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              {record.is_organisation ? (
                record.company ? record.company.name : null
              ) : (
                <Presentation
                  currentUser={currentUser}
                  rule={"customers:view"}
                  route={
                    customer ? `/customers/${record.customer.id}/edit` : null
                  }
                  title={
                    record.customer
                      ? record.customer.name
                      : record.company
                      ? record.company.name
                      : null
                  }
                />
              )}
            </span>
            <span>{record.entity ? record.entity.name : null}</span>
            <span style={{ opacity: "0.7" }}>
              {record.entity ? record.entity.address : null}
            </span>
          </div>
        ),
        align: "center",
        width: "20%",
      },
      {
        title: "Тема / Раздел",
        dataIndex: "subject",
        render: (subject, record) => (
          <div style={{display: "flex", flexDirection: "column"}}>
            <span>
              <Paragraph ellipsis={{rows: 3, expandable: true}}>
                {subject}
              </Paragraph>
            </span>
            <span>
                {record.category
                ? serviceTag(record.category)
                : record.entity_service
                  ? serviceTag(record.entity_service)
                  : null}
            </span>
          </div>
        ),
        align: "left",
        width: "20%",
      },
      {
        title: "Ответственный",
        dataIndex: "assignees",
        render: (assignees, record) => <AssigneesCell assignees={assignees} />,
        align: "center",
        width: "15%",
      },
      {
        title: "",
        dataIndex: "unread_comment_count",
        render: (unread_comment_count, record) => (
          <Badge count={unread_comment_count}
                 title={'Непрочитанные комментарии'}
                 style={{boxShadow: "none"}}/>
        ),
        align: "center",
        width: "5%",
      },
    ];

    let mainButton = hasSelected ? (
      <Button key="1" type="danger" ghost onClick={this.onDelete}>
        Удалить
      </Button>
    ) : (
      <Link
        key="2"
        className={"ant-btn ant-btn-primary ant-btn-background-ghost"}
        to={{ pathname: `/tickets/new` }}
      >
        Создать
      </Link>
    );

    let excelButton = (
      <Can
        key={'excel-button'}
        role={currentUser.role}
        perform="orders:finance"
        yes={() => (
          <Tooltip placement="bottom" title={"Выгрузить в Excel"}>
            <Button key="excelButton" type="dashed" onClick={downloadReport}>
              <Icon type="file-excel" style={{ color: "green" }} />
              Выгрузить
            </Button>
          </Tooltip>
        )}
        no={() => null}
      />
    );

    let filterButton = (
      <Button
        type={
          fetchParams.startFinishDate ||
          fetchParams.startCreatedDate ||
          fetchParams.startDate ||
          fetchParams.status ||
          fetchParams.replaced_status ||
          fetchParams.users ||
          fetchParams.customer ||
          fetchParams.members ||
          fetchParams.priorities ||
          fetchParams.categories ||
          fetchParams.is_rated !== null ||
          fetchParams.services
            ? "primary"
            : "ghost"
        }
        key="3"
        icon="filter"
        onClick={this.onFilterToggle}
      />
    );

    const unread = notices.filter(function (item) {
      return item.read == false;
    });

    const noticeIcon = (
      <NoticeIcon
        key="5"
        count={unread.length}
        onItemClick={this.onItemClick}
        list={notices}
        showClear
        onClear={this.onClearNotices}
        emptyText={"новых комментариев нет"}
      />
    );

    const faqButton = (
      <Button
        type={"ghost"}
        key="7"
        icon="question"
        onClick={this.props.onFaqToggle}
      />
    );

    const totalSelected = hasSelected
      ? `, Всего (выбрано): ${selectedRowKeys.length}`
      : "";
    const totalInfo = `Всего: ${meta.total_count}${totalSelected}`;

    return (
      <PageHeader
        className="page-header-with-notices"
        onBack={() => window.history.back()}
        title={`Заявки`}
        subTitle={
          fetchParams.startDate && fetchParams.endDate
            ? `c ${fetchParams.startDate} по ${fetchParams.endDate}`
            : null
        }
        extra={[
          faqButton,
          noticeIcon,
          <Search
            allowClear
            key="4"
            placeholder="поиск..."
            value={fetchParams.search}
            onChange={onSearch}
            style={{ width: 250 }}
          />,
          mainButton,
          excelButton,
          filterButton,
        ]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Can
                role={currentUser.role}
                perform="tickets:delete"
                yes={() => (
                  <Table
                    // bordered
                    rowSelection={rowSelection}
                    size={"middle"}
                    loading={loading}
                    pagination={false}
                    columns={columns}
                    dataSource={tickets}
                    expandedRowRender={(record) =>
                      this.renderExpandedRow(record)
                    }
                    onChange={this.onChangeTable}
                    rowKey="id"
                  />
                )}
                no={() => (
                  <Table
                    // bordered
                    size={"middle"}
                    loading={loading}
                    pagination={false}
                    columns={columns}
                    dataSource={tickets}
                    expandedRowRender={(record) =>
                      this.renderExpandedRow(record)
                    }
                    onChange={this.onChangeTable}
                    rowKey="id"
                  />
                )}
              />
            </Col>
            <Col
              span={24}
              style={{
                paddingTop: "15px",
                paddingBottom: "15px",
                textAlign: "right",
              }}
            >
              <Pagination
                showSizeChanger
                pageSize={fetchParams.limit}
                current={meta.current_page}
                onChange={onChangePage}
                onShowSizeChange={onChangeLimit}
                showTotal={(total) => totalInfo}
                total={meta.total_count}
              />
            </Col>
          </Row>
        }
      >
        <Filter
          currentUser={currentUser}
          visible={isFilterVisible}
          status={fetchParams.status}
          isReplaced={fetchParams.is_replaced}
          isOrdered={fetchParams.is_ordered}
          isRated={fetchParams.is_rated}
          startDate={fetchParams.startDate}
          endDate={fetchParams.endDate}
          startFinishDate={fetchParams.startFinishDate}
          endFinishDate={fetchParams.endFinishDate}
          startCreatedDate={fetchParams.startCreatedDate}
          endCreatedDate={fetchParams.endCreatedDate}
          user={fetchParams.user}
          users={fetchParams.users}
          customer={fetchParams.customer}
          members={fetchParams.members}
          priorities={fetchParams.priorities}
          categories={fetchParams.categories}
          services={fetchParams.services}
          onClose={this.onFilterToggle}
          onClearFilters={onClearFilters}
          onChangeStatus={onChangeFilterStatus}
          onChangeReplaced={onChangeFilterReplaced}
          onChangeOrdered={onChangeFilterOrdered}
          onChangePeriod={onChangeFilterPeriod}
          onChangeFinishPeriod={onChangeFilterFinishPeriod}
          onChangeCreatedPeriod={onChangeFilterCreatedPeriod}
          onChangeUser={onChangeFilterUser}
          onChangeUsers={onChangeFilterUsers}
          onChangeCustomer={onChangeFilterCustomer}
          onChangeMembers={onChangeFilterMembers}
          onChangePriorities={onChangeFilterPriorities}
          onChangeCategories={onChangeFilterCategories}
          onChangeServices={onChangeFilterServices}
          onChangeRated={onChangeFilterRated}
          downloadReport={downloadReport}
        />
      </PageHeader>
    );
  }
}

TicketsList.propTypes = {
  tickets: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default withRouter(TicketsList);
