import update from "immutability-helper";

const initialState = {
  supportMessageTypes: [],
  supportMessageType: { user: null, admin: null, assignees: [] },
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1,
  },
  fetchParams: {
    search: null,
    sort: null,
    direction: null,
    page: 1,
    limit: 15,
    kinds: null,
    users: null,
  },
  errors: false,
  isLoading: true,
  isAssigneesLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "FETCH_SUPPORT_MESSAGE_TYPES_REQUEST":
      return {
        ...state,
        isLoading: true,
      };

    case "FETCH_SUPPORT_MESSAGE_TYPES":
      return {
        ...state,
        supportMessageTypes: action.response.data,
        meta: action.response.meta,
        isLoading: false,
      };

    case "FETCH_SUPPORT_MESSAGE_TYPE_REQUEST":
      return {
        ...state,
        isLoading: true,
      };

    case "FETCH_SUPPORT_MESSAGE_TYPE":
      return {
        ...state,
        supportMessageType: action.response.data,
        isLoading: false,
      };

    case "REQUEST_SUPPORT_MESSAGE_TYPE_SEARCH":
      return update(state, {
        fetchParams: { search: { $set: action.value }, page: { $set: 1 } },
      });

    case "REQUEST_SUPPORT_MESSAGE_TYPE_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } },
      });

    case "REQUEST_SUPPORT_MESSAGE_TYPE_CHANGE_LIMIT":
      return update(state, {
        fetchParams: { limit: { $set: action.value }, page: { $set: 1 } },
      });

    case "SUPPORT_MESSAGE_TYPES_SORT_BY":
      return update(state, {
        fetchParams: {
          sort: { $set: action.field },
          direction: { $set: action.direction },
        },
      });

    // assignees
    case "FETCH_SUPPORT_MESSAGE_TYPE_ASSIGNEES_REQUEST":
      return {
        ...state,
        isAssigneesLoading: true,
      };

    case "FETCH_SUPPORT_MESSAGE_TYPE_ASSIGNEES":
      return update(state, {
        supportMessageType: { assignees: { $set: action.response.data } },
        isAssigneesLoading: { $set: false },
      });

    case "FETCH_SUPPORT_MESSAGE_TYPE_ASSIGNEES_FAILURE":
      return {
        ...state,
        errors: true,
        isAssigneesLoading: false,
      };

    case "POST_SUPPORT_MESSAGE_TYPE_ASSIGNEE_REQUEST":
      return {
        ...state,
        errors: false,
        isAssigneesLoading: true,
      };
    case "POST_SUPPORT_MESSAGE_TYPE_ASSIGNEE":
      return {
        ...state,
        errors: false,
        isAssigneesLoading: false,
      };

    case "POST_SUPPORT_MESSAGE_TYPE_ASSIGNEE_FAILURE":
      return {
        ...state,
        errors: true,
        isAssigneesLoading: false,
      };

    // settings
    case "PATCH_SUPPORT_MESSAGE_TYPE_SETTING_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };

    case "PATCH_SUPPORT_MESSAGE_TYPE_SETTING":
      return update(state, {
        supportMessageType: { settings: { $set: action.response.data } },
        isLoading: { $set: false },
      });

    case "PATCH_SUPPORT_MESSAGE_TYPE_SETTING_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    // filters
    case "SUPPORT_MESSAGE_TYPE_CLEAR_FILTERS":
      return update(state, {
        fetchParams: {
          status: { $set: null },
          users: { $set: null },
          members: { $set: null },
        },
      });

    case "SUPPORT_MESSAGE_TYPE_FILTER_KINDS":
      return update(state, {
        fetchParams: {
          kinds: { $set: action.values.length > 0 ? action.values : null },
          page: { $set: 1 },
        },
      });

    default:
      return state;
  }
}
