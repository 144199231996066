// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Card,
  Form,
  Select,
  Checkbox,
  Upload,
  Icon,
  DatePicker,
} from "antd";
import moment from "moment";
import "moment/locale/ru";
import Rating from "./Rating";

const FormItem = Form.Item;
const Option = Select.Option;

class Statuses extends Component {
  onUpload = ({ onSuccess, onError, file }) => {
    this.props.onUploadAvatar(file);
  };

  render() {
    const {
      isNew,
      avatar,
      isUploadingAvatar,
      is_active,
      start,
      finish,
      role,
      rates,
      getFieldDecorator,
      onChangeActive,
      onChangeRole,
      onChangeStart,
      onChangeFinish,
    } = this.props;

    const uploadButton = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexFlow: "column",
          minHeight: "140px",
          minWidth: "140px",
        }}
      >
        <Icon type={isUploadingAvatar ? "loading" : "plus"} />
        <div>Загрузить картинку</div>
      </div>
    );

    return (
      <Card style={{ borderColor: "#1890ff" }}>
        <Row gutter={16}>
          <Col span={24}>
            <FormItem>
              {getFieldDecorator("is_active", {
                initialValue: is_active,
                valuePropName: "checked",
                onChange: onChangeActive,
              })(<Checkbox>активен</Checkbox>)}
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem label={"Роль"}>
              {getFieldDecorator("role", {
                initialValue: role,
                onChange: onChangeRole,
                rules: [
                  {
                    required: true,
                    message: "Введите роль!",
                  },
                ],
              })(
                <Select
                  style={{ width: "100%" }}
                  placeholder="Роль"
                  optionFilterProp="children"
                  filterOption={false}
                >
                  <Option value={"manager"}>руководитель</Option>
                  <Option value={"supervisor"}>менеджер</Option>
                  <Option value={"engineer"}>инженер</Option>
                  <Option value={"simple"}>пользователь</Option>
                  <Option value={"administrator"}>администратор</Option>
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <FormItem label={"Дата приема"}>
              {getFieldDecorator("start", {
                onChange: onChangeStart,
                initialValue: start ? moment(start) : null,
              })(
                <DatePicker
                  format={"L"}
                  placeholder="Дата приема"
                  showTime
                  className="ant-btn-width-100"
                />
              )}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label={"Дата увольнения"}>
              {getFieldDecorator("finish", {
                onChange: onChangeFinish,
                initialValue: finish ? moment(finish) : null,
              })(
                <DatePicker
                  format={"L"}
                  placeholder="Дата увольнения"
                  showTime
                  className="ant-btn-width-100"
                />
              )}
            </FormItem>
          </Col>
        </Row>
        {!isNew ? (
          <Row gutter={16}>
            <Col span={24}>
              <Upload
                name="url"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                customRequest={this.onUpload}
              >
                {avatar && avatar.standart ? (
                  <img height={"140"} src={avatar.standart} alt="avatar" />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Col>
          </Row>
        ) : null}
        <Row gutter={16}>
          <Col span={24}>
            <Rating rates={rates} />
          </Col>
        </Row>
      </Card>
    );
  }
}
Statuses.propTypes = {
  getFieldDecorator: PropTypes.func,
};

export default Statuses;
