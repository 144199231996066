// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../../actions/orders";
import TaskActions from "../../../actions/tasks";
import LoadingSpin from "../../../components/LoadingSpin";
import OrderForm from "./Form";
import {encodeBase64} from "../../../util/attachments";

class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: false,
      forTaskId: null,
    };
  }

  componentWillMount = () => {
    // console.log("componentWillMount");
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.fetchOrder(this.props.match.params.id)).then(() => {
        this.props.dispatch(TaskActions.fetchTaskActions({taskOwners: [3]}));
      });
    } else {
      let forTaskId = this.props.location.state?.forTaskId ?? null
      if (forTaskId === null) {
        this.setState({ isNew: true }, () => {
          this.props.dispatch(Actions.onNew());
        });
      } else {
        this.setState({ isNew: true, forTaskId: forTaskId }, () => {
          this.props.dispatch(Actions.onNewForTask(forTaskId));
        });
      }
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.location.key !== prevProps.location.key) {
      if (this.props.match.params.id) {
        this.setState({ isNew: false });
      }
    }
  }

  onSave = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.onUpdate(this.props.match.params.id));
    } else {
      let forTaskId = this.props.location.state?.forTaskId ?? null
      if (forTaskId === null) {
        this.props.dispatch(Actions.onCreate()).then(() => {
          if (!this.props.errors) {
            this.props.history.push(`/orders`);
          }
        });
      } else {
        this.props.dispatch(Actions.onCreateForTask(forTaskId)).then(() => {
          if (!this.props.errors) {
            this.props.history.push(`/orders`);
          }
        });
      }
    }
  };

  onClose = () => {
    this.props.history.push(`/orders`);
  };

  // general
  onChangeDueBy = (value) => {
    this.props.dispatch(Actions.onChangeDueBy(value));
  };

  onChangeNumberInt = (e) => {
    this.props.dispatch(Actions.onChangeNumberInt(e.target.value));
  };

  onChangeNumber = (e) => {
    this.props.dispatch(Actions.onChangeNumber(e.target.value));
  };

  onChangeCustomer = (value) => {
    this.props.dispatch(Actions.onChangeCustomer(value));
  };

  onChangeEntity = (value) => {
    this.props.dispatch(Actions.onChangeEntity(value));
  };
  onChangeEntityService = (value) => {
    this.props.dispatch(Actions.onChangeEntityService(value));
  };

  onChangeAmount = (value) => {
    this.props.dispatch(Actions.onChangeAmount(value));
  };

  onChangeSubject = (e) => {
    this.props.dispatch(Actions.onChangeSubject(e.target.value));
  };

  onChangeDescription = (e) => {
    this.props.dispatch(Actions.onChangeDescription(e.target.value));
  };

  onChangeIsOrganisation = (e) => {
    this.props.dispatch(Actions.onChangeIsOrganisation(e.target.checked));
  };
  

  onChangeIsSupplier = (e) => {
    this.props.dispatch(Actions.onChangeIsSupplier(e.target.checked));
  };

  onChangePriority = (value) => {
    this.props.dispatch(Actions.onChangePriority(value));
  };

  onChangeCompany = (value) => {
    this.props.dispatch(Actions.onChangeCompany(value));
  };

  // comments
  onCreateComment = (comment, mentioned) => {
    this.props.dispatch(Actions.onCreateComment(this.props.match.params.id, comment, mentioned))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchOrderComments(this.props.match.params.id)
          );
        }
      });
  };

  // proposals
  onDeleteProposal = (id) => {
    this.props
      .dispatch(Actions.onDeleteProposal(this.props.match.params.id, id))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchProposals(this.props.match.params.id)
          );
        }
      });
  };

  onUploadProposal = (file) => {
    this.props
      .dispatch(Actions.onUploadProposal(this.props.match.params.id, file))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchProposals(this.props.match.params.id)
          );
        }
      });
  };

  // attachments
  onDeleteAttachment = (id) => {
    this.props
      .dispatch(Actions.onDeleteAttachment(this.props.match.params.id, id))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAttachments(this.props.match.params.id)
          );
        }
      });
  };

  onUploadAttachment = (file) => {
    this.props
      .dispatch(Actions.onUploadAttachment(this.props.match.params.id, file))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAttachments(this.props.match.params.id)
          );
        }
      });
  };

  onUploadCommentAttachment = (commentId, file) => {
    encodeBase64(file, (fileUrl) =>
      this.props
        .dispatch(Actions.onUploadAttachment(
          this.props.match.params.id,
          {
            id: file.uid,
            name: file.name,
            content_type: file.type,
            attachment_type: 1,
            file_size: file.size,
            attachment: fileUrl,
          },
          commentId))
        .then(() => {
          if (!this.props.errors) {
            this.props.dispatch(
              Actions.fetchAttachments(this.props.match.params.id)
            );
          }
        })
    );
  };

  // assignees
  onCreateAssignee = (value, type) => {
    this.props
      .dispatch(
        Actions.onCreateAssignee(this.props.match.params.id, value, type)
      )
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAssignees(this.props.match.params.id)
          );
        }
      });
  };

  onDeleteAssignee = (id) => {
    this.props
      .dispatch(Actions.onDeleteAssignee(this.props.match.params.id, id))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAssignees(this.props.match.params.id)
          );
        }
      });
  };

  // order_tasks
  onCreateOrderTask = (item) => {
    this.props
      .dispatch(Actions.onCreateOrderTask(this.props.match.params.id, item))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(Actions.fetchOrderTasks(this.props.match.params.id));
        }
      });
  };

  // schedules
  fetchSchedules = () => {
    this.props.dispatch(Actions.fetchSchedules(this.props.match.params.id));
  };

  onSaveSchedule = (item) => {
    if (item.id) {
      this.props
        .dispatch(Actions.onUpdateSchedule(this.props.match.params.id, item))
        .then(() => {
          if (!this.props.errors) {
            this.props.dispatch(
              Actions.fetchSchedules(this.props.match.params.id)
            );
          }
        });
    } else {
      this.props
        .dispatch(Actions.onCreateSchedule(this.props.match.params.id, item))
        .then(() => {
          if (!this.props.errors) {
            this.props.dispatch(
              Actions.fetchSchedules(this.props.match.params.id)
            );
          }
        });
    }
  };

  onDeleteSchedules = (ids) => {
    ids.map((id) => {
      this.props.dispatch(Actions.onDeleteSchedule(id)).then(() => {
        this.props.dispatch(Actions.fetchSchedules(this.props.match.params.id));
      });
    });
  };

  // finance
  onChangeProductPrice = (value) => {
    this.props.dispatch(Actions.onChangeProductPrice(value));
  };

  onChangeServicePrice = (value) => {
    this.props.dispatch(Actions.onChangeServicePrice(value));
  };

  onChangePenalty = (value) => {
    this.props.dispatch(Actions.onChangePenalty(value));
  };

  onChangePayment = (value) => {
    this.props.dispatch(Actions.onChangePayment(value));
  };

  // status
  onChangeStatus = (value) => {
    this.props.dispatch(
      Actions.onChangeStatus(this.props.match.params.id, value.id)
    );
  };

  fetchOrderActivities = () => {
    this.props.dispatch(
      Actions.fetchOrderActivities(this.props.match.params.id)
    );
  };

  // tasks
  fetchTasks = () => {
    this.props.dispatch(Actions.fetchTasks(this.props.match.params.id));
  };

  // actions
  onOpenTaskForm = (action) => {
    const { order } = this.props;
    this.props.dispatch(TaskActions.fetchNew(action.id, order.id));
  };

  render() {
    const {
      currentUser,
      isLoading,
      errors,
      isAssigneesLoading,
      isLoadingProposals,
      isAttachmentLoading,
      // order_tasks
      isOrderTasksLoading,
      isSchedulesLoading,
      isCommentsLoading,
      order,
      orderActivities,
      isActivitiesLoading,
      // tasks
      isTasksLoading,
      tasks,
      // actions
      actions,
      isTaskActionsLoading,
    } = this.props;

    return isLoading || errors ? (
      <LoadingSpin />
    ) : (
      <OrderForm
        isNew={this.state.isNew}
        forTaskId={this.state.forTaskId}
        currentUser={currentUser}
        order={order}
        onSave={this.onSave}
        onClose={this.onClose}
        // general
        onChangeDueBy={this.onChangeDueBy}
        onChangePriority={this.onChangePriority}
        onChangeNumberInt={this.onChangeNumberInt}
        onChangeNumber={this.onChangeNumber}
        onChangeCustomer={this.onChangeCustomer}
        onChangeEntity={this.onChangeEntity}
        onChangeEntityService={this.onChangeEntityService}
        onChangeAmount={this.onChangeAmount}
        onChangeSubject={this.onChangeSubject}
        onChangeDescription={this.onChangeDescription}
        onChangeIsOrganisation={this.onChangeIsOrganisation}
        onChangeIsSupplier={this.onChangeIsSupplier}
        onChangeCompany={this.onChangeCompany}
        // comments
        isCommentsLoading={isCommentsLoading}
        onCreateComment={this.onCreateComment}
        // statuses
        isAssigneesLoading={isAssigneesLoading}
        onDeleteAssignee={this.onDeleteAssignee}
        // proposals
        isLoadingProposals={isLoadingProposals}
        onDeleteProposal={this.onDeleteProposal}
        onUploadProposal={this.onUploadProposal}
        // attachments
        isAttachmentLoading={isAttachmentLoading}
        onDeleteAttachment={this.onDeleteAttachment}
        onUploadAttachment={this.onUploadAttachment}
        onUploadCommentAttachment={this.onUploadCommentAttachment}
        onDeleteCommentAttachment={this.onDeleteAttachment}
        // assignees
        onCreateAssignee={this.onCreateAssignee}
        onDeleteAssignee={this.onDeleteAssignee}
        // order_tasks
        isOrderTasksLoading={isOrderTasksLoading}
        onCreateOrderTask={this.onCreateOrderTask}
        // schedules
        isSchedulesLoading={isSchedulesLoading}
        fetchSchedules={this.fetchSchedules}
        onSaveSchedule={this.onSaveSchedule}
        onDeleteSchedules={this.onDeleteSchedules}
        // finance
        onChangeProductPrice={this.onChangeProductPrice}
        onChangeServicePrice={this.onChangeServicePrice}
        onChangePenalty={this.onChangePenalty}
        onChangePayment={this.onChangePayment}
        // status
        onChangeStatus={this.onChangeStatus}
        // activities
        orderActivities={orderActivities}
        isActivitiesLoading={isActivitiesLoading}
        fetchOrderActivities={this.fetchOrderActivities}
        // tasks
        isTasksLoading={isTasksLoading}
        tasks={tasks}
        fetchTasks={this.fetchTasks}
        // actions
        actions={actions}
        isTaskActionsLoading={isTaskActionsLoading}
        onOpenTaskForm={this.onOpenTaskForm}
      />
    );
  }
}

Order.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  order: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  isLoading: state.orders.isLoading,
  errors: state.orders.errors,
  order: state.orders.order,
  isLoadingProposals: state.orders.isLoadingProposals,
  isAttachmentLoading: state.orders.isAttachmentLoading,
  isAssigneesLoading: state.orders.isAssigneesLoading,
  // order_tasks
  isOrderTasksLoading: state.orders.isOrderTasksLoading,
  isSchedulesLoading: state.orders.isSchedulesLoading,
  isCommentsLoading: state.orders.isCommentsLoading,
  orderActivities: state.orders.orderActivities,
  isActivitiesLoading: state.orders.isActivitiesLoading,
  // tasks
  isTasksLoading: state.orders.isTasksLoading,
  tasks: state.orders.tasks,
  // actions
  actions: state.tasks.actions,
  isTaskActionsLoading: state.tasks.isLoading,
});

export default connect(mapStateToProps)(withRouter(Order));
