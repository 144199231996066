// @flow
import React, { Component, lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../../actions/supportMessageTypes";
import LoadingSpin from "../../../components/LoadingSpin";

const Form = lazy(() => import("./Form"));
const renderLoader = () => <LoadingSpin />;

class SupportMessageType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: false,
    };
  }

  componentWillMount = () => {
    // console.log(this.props.location);
    if (this.props.match.params.id) {
      this.props.dispatch(
        Actions.fetchSupportMessageType(this.props.match.params.id)
      );
    } else {
      this.setState({ isNew: true }, () => {
        this.props.dispatch(Actions.onNew());
      });
    }
  };

  onSave = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.onUpdate(this.props.match.params.id));
    } else {
      this.props.dispatch(Actions.onCreate()).then(() => {
        if (!this.props.errors) {
          this.props.history.push(
            `/support_message_types/${this.props.supportMessage.id}/edit`
          );
        }
      });
    }
  };

  onClose = () => {
    this.props.history.push(`/support_message_types`);
  };

  // assignees
  onCreateAssignee = (value, type) => {
    this.props
      .dispatch(
        Actions.onCreateAssignee(this.props.match.params.id, value, type)
      )
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAssignees(this.props.match.params.id)
          );
        }
      });
  };

  onDeleteAssignee = (id) => {
    this.props
      .dispatch(Actions.onDeleteAssignee(this.props.match.params.id, id))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAssignees(this.props.match.params.id)
          );
        }
      });
  };


  // settings
  onUpdateSetting = (id, field, e) => {
    // console.log(id, field, e.target.checked)
    this.props.dispatch(Actions.onUpdateSetting(this.props.match.params.id, id, field, e.target.checked));
  };

  render() {
    const { isLoading, isAssigneesLoading, supportMessageType } = this.props;
    return (
      <Suspense fallback={renderLoader()}>
        <Form
          isNew={this.state.isNew}
          isLoading={isLoading}
          supportMessageType={supportMessageType}
          onSave={this.onSave}
          onClose={this.onClose}
          // assignees
          isAssigneesLoading={isAssigneesLoading}
          onCreateAssignee={this.onCreateAssignee}
          onDeleteAssignee={this.onDeleteAssignee}
          // settings
          onUpdateSetting={this.onUpdateSetting}
        />
      </Suspense>
    );
  }
}

SupportMessageType.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  supportMessage: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isLoading: state.supportMessageTypes.isLoading,
  isAssigneesLoading: state.supportMessageTypes.isAssigneesLoading,
  errors: state.supportMessageTypes.errors,
  supportMessageType: state.supportMessageTypes.supportMessageType,
});

export default connect(mapStateToProps)(withRouter(SupportMessageType));
