import React, { Component } from "react";
import { Row, Col, Form, Modal, Rate, Button, Input } from "antd";

const FormItem = Form.Item;
const { TextArea } = Input;


class CommentForm extends React.Component {

  static defaultProps = {
    placeholder: 'Введите комментарий'
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onSave();
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      visible,
      comment,
      placeholder,
      onChangeComment,
      onCancel,
    } = this.props;

    return (
      <Modal
        destroyOnClose
        title="Комментарий"
        visible={visible}
        onOk={this.onSubmit}
        onCancel={onCancel}
        footer={[
          <Button key="back" onClick={onCancel}>
            Отменить
          </Button>,
          <Button
            disabled={comment.length > 3 ? false : true}
            key="submit"
            type="primary"
            onClick={this.onSubmit}
          >
            ОК
          </Button>,
        ]}
      >
        <Form layout={"vertical"}>
          <Row type="flex" justify="center" align="middle">
            <Col span={24}>
              <FormItem label={"Комментарий"}>
                {getFieldDecorator("comment", {
                  onChange: onChangeComment,
                  initialValue: comment,
                })(<TextArea placeholder={placeholder} autoSize={{ minRows: 6, maxRows: 8 }} />)}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(CommentForm);
