// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table, Button, PageHeader, Badge, Tag } from "antd";
import { Pagination } from "antd";
import { Row, Col, Input } from "antd";
import Filter from "./Filter";

import PropTypes from "prop-types";

import moment from "moment";
import "moment/locale/ru";

function optionsTag(record) {
  let options = [];
  if (record.has_timing) {
    options.push(
      <Tag key={"has_timing"} color="#108ee9">
        тайминг
      </Tag>
    );
  }

  if (record.has_todos) {
    options.push(
      <Tag key={"has_todos"} color="#108ee9">
        список
      </Tag>
    );
  }

  if (record.has_attachments) {
    options.push(
      <Tag key={"has_attachments"} color="#108ee9">
        файлы
      </Tag>
    );
  }

  if (record.has_comments) {
    options.push(
      <Tag key={"has_comments"} color="#108ee9">
        комментарии
      </Tag>
    );
  }

  if (record.has_supervisor_assignees) {
    options.push(
      <Tag key={"has_supervisor_assignees"} color="#108ee9">
        супервайзоры
      </Tag>
    );
  }

  if (record.has_user_assignees) {
    options.push(
      <Tag key={"has_user_assignees"} color="#108ee9">
        исполнители
      </Tag>
    );
  }

  if (record.has_member_assignees) {
    options.push(
      <Tag key={"has_member_assignees"} color="#108ee9">
        отвественные заказчика
      </Tag>
    );
  }

  return options;
}

function departmentTag(department) {
  return (
    <Tag key={department.id} color="#108ee9">
      {department.name}
    </Tag>
  );
}

class TaskTemplatesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      isFilterVisible: false,
    };
  }

  onFilterToggle = () => {
    this.setState({ isFilterVisible: !this.state.isFilterVisible });
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  onDelete = () => {
    this.props.onDelete(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  onChangeTable = (pagination, filters, sorter) => {
    let direction = "desc";
    if (sorter.order === "descend") {
      direction = "desc";
    } else if (sorter.order === "ascend") {
      direction = "asc";
    }
    this.props.onSortBy(sorter.field, direction);
  };

  render() {
    const { selectedRowKeys, isFilterVisible } = this.state;
    const {
      loading,
      taskTemplates,
      meta,
      fetchParams,
      onSearch,
      onChangePage,
      onChangeLimit,
      // filters
      onClearFilters,
      onChangeFilterTaskOwner,
      onChangeFilterTaskType,
    } = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: "",
        dataIndex: "is_active",
        render: (is_active, record) => (
          <span>
            <Badge
              status={is_active ? "success" : "error"}
              className={"large"}
            />
          </span>
        ),
        key: "is_active",
        width: "5%",
        align: "center",
      },
      {
        title: "Наименование",
        dataIndex: "name",
        render: (name, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <Link to={`/task_templates/${record.id}/edit`}>{name}</Link>
            </span>
            <span style={{ fontSize: 12 }}>{record.description}</span>
          </div>
        ),
        width: "25%",
        align: "center",
      },
      {
        title: "Владелец / Тип",
        dataIndex: "task_owner_locale",
        render: (task_owner_locale, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{task_owner_locale}</span>
            <span style={{ fontSize: 12 }}>{record.task_type_locale}</span>
          </div>
        ),
        key: "task_owner_locale",
        width: "15%",
        align: "center",
      },
      {
        title: "Опции",
        dataIndex: "options",
        render: (options, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{optionsTag(record)}</span>
          </div>
        ),
        key: "options",
        width: "15%",
        align: "center",
      },
      {
        title: "Подразделения",
        dataIndex: "departments",
        render: (departments, record) => {
          return departments.map((item) => {
            return departmentTag(item);
          });
        },
        key: "departments",
        width: "20%",
        align: "center",
      },
      {
        title: "Дата создания",
        dataIndex: "created_at",
        render: (created_at) => moment(created_at).format("L"),
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "created_at",
        align: "center",
        width: "10%",
      },
      {
        title: "Создан",
        dataIndex: "user",
        render: (user, record) => (
          <span style={{ opacity: "0.7" }}>{user ? user.name : null}</span>
        ),

        align: "center",
        width: "10%",
      },
    ];

    let filterButton = (
      <Button
        type={
          fetchParams.task_owner || fetchParams.task_type ? "primary" : "ghost"
        }
        key="3"
        icon="filter"
        onClick={this.onFilterToggle}
      />
    );

    let mainButton = hasSelected ? (
      <Button key="1" type="danger" ghost onClick={this.onDelete}>
        Удалить
      </Button>
    ) : (
      <Link
        key="2"
        className={"ant-btn ant-btn-primary ant-btn-background-ghost"}
        to={{ pathname: `/task_templates/new` }}
      >
        Создать
      </Link>
    );

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Шаблоны задач`}
        extra={[mainButton, filterButton]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Table
                bordered
                rowSelection={rowSelection}
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={taskTemplates}
                onChange={this.onChangeTable}
                rowKey="id"
              />
            </Col>
            <Col
              span={24}
              style={{
                paddingTop: "15px",
                paddingBottom: "15px",
                textAlign: "right",
              }}
            >
              <Pagination
                showSizeChanger
                pageSize={fetchParams.limit}
                current={meta.current_page}
                onChange={onChangePage}
                onShowSizeChange={onChangeLimit}
                total={meta.total_count}
              />
            </Col>
          </Row>
        }
      >
        <Filter
          visible={isFilterVisible}
          taskOwner={fetchParams.task_owner}
          taskType={fetchParams.task_type}
          onClose={this.onFilterToggle}
          onClearFilters={onClearFilters}
          onChangeTaskOwner={onChangeFilterTaskOwner}
          onChangeTaskType={onChangeFilterTaskType}
        />
      </PageHeader>
    );
  }
}

TaskTemplatesList.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default TaskTemplatesList;
