// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table } from "antd";
import "moment/locale/ru";
import EditableInputCell from "../../../components/EditableCells/EditableInputCell";
import EditableSelectCell from "../../../components/EditableCells/EditableSelectCell";

const openHoursTypes = [{ value: true, name: "да" }, { value: false, name: "нет" }];

class Priorities extends Component {
  render() {
    const { priorities, onChangeResponseTime, onChangeUseOpenHours } = this.props;

    const columns = [
      {
        title: "Уровень",
        dataIndex: "name",
        width: "50%",
        align: "left"
      },
      {
        title: "Продолжительность, часы",
        dataIndex: "response_time",
        render: (response_time, record) => {
          return (
            <EditableInputCell
              recordId={record.id}
              value={response_time}
              precision={0}
              onChange={onChangeResponseTime}
            />
          );
        },
        width: "30%",
        align: "right"
      },
      {
        title: "Использовать расписание",
        dataIndex: "use_open_hours",
        render: (use_open_hours, record, index) => {
          return (
            <EditableSelectCell
              recordId={record.id}
              items={openHoursTypes}
              value={use_open_hours}
              onChange={onChangeUseOpenHours}
            />
          );
        },
        width: "20%",
        align: "center"
      },
    ];

    return (
      <Table
        rowClassName={() => "editable-row"}
        size={"middle"}
        bordered
        pagination={false}
        columns={columns}
        dataSource={priorities}
        rowKey="id"
      />
    );
  }
}
Priorities.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object)
};

export default Priorities;
