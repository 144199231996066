// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, Form, Card, Tabs, Menu, Dropdown } from "antd";
import { v4 as uuidv4 } from "uuid";
import Statuses from "./Statuses";
import EntityServices from "./EntityServices";
import EntityTasks from "./EntityTasks";
import Tickets from "./Tickets";
import Orders from "./Orders";
import Projects from "./Projects";
import General from "./General";
import GeneralInfo from "./GeneralInfo";
import Gallery from "./Gallery";
import OpeningHours from "./OpeningHours";
import Parameters from "./Parameters";
import Can from "../../../rules/Can";
import Activities from "./Activities";
import Documents from "../Documents";
import ExternalDocuments from "../ExternalDocuments";
import Notes from "./Notes";
import ExtCodes from "./ExtCodes";

const { TabPane } = Tabs;

class EntityForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabKey: "1",
      isAddingExtCode: false,
      extCode: null,
    };
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onSave(values);
    });
  };

  callback = (key) => {
    if (key == "9") {
      this.props.fetchActivities();
    }
    this.setState({ tabKey: key });
  };

  onChangeTab = (key) => {
    if (key == "4" || key == "5") {
      this.props.fetchAttachments();
    }

    if (key == "21") {
      this.props.fetchEntityTasks();
    }

    if (key == "22") {
      this.props.fetchTickets();
    }

    if (key == "23") {
      this.props.fetchOrders();
    }

    if (key == "24") {
      this.props.fetchProjects();
    }
  };

  // actions
  onAction = (actionId) => {
    const { actions, onOpenTaskForm } = this.props;
    let idx = actions.findIndex(function (o) {
      return o.id == actionId;
    });
    if (idx === -1) {
      return null;
    }
    onOpenTaskForm(actions[idx]);
  };

  ActionMenu = (actions) => {
    return (
      <Menu>
        {actions.map((action) => {
          return (
            <Menu.Item
              key={action.id}
              onClick={this.onAction.bind(this, action.id)}
            >
              {action.name}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  // extCodes
  onOpenAddingExtCode = () => {
    this.setState({ isAddingExtCode: true });
  };

  onCloseAddingExtCode = () => {
    this.setState({ isAddingExtCode: false });
  };

  onChangeExtCode = (e) => {
    this.setState({ extCode: e.target.value });
  };

  onAddExtCode = () => {
    const { extCode } = this.state;
    this.props.onAddExtCode({
      id: uuidv4(),
      code: extCode,
      is_exist: false,
      _destroy: false,
    });
    this.setState({ isAddingExtCode: false });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { tabKey, isAddingExtCode, extCode } = this.state;
    const {
      isNew,
      isLoading,
      currentUser,
      entity,
      onClose,
      // general
      onChangeStart,
      onChangeFinish,
      onChangeIsOrganisation,
      onChangeCustomer,
      onChangeName,
      onChangeEntityType,
      onChangePostalCode,
      onChangeCity,
      onChangeStreet,
      onChangeBuilding,
      onChangeExtCode,
      onChangeLat,
      onChangeLon,
      onChangeTodoTemplates,
      // assignees
      isAssigneesLoading,
      onCreateAssignee,
      onDeleteAssignee,
      //entity_services
      onDeleteEntityServices,
      onSaveEntityService,
      // attachments
      isAttachmentLoading,
      onSaveAttachment,
      onDeleteAttachment,
      onUploadAttachment,
      // entity_photos
      isLoadingImages,
      isUploadingImage,
      uploadPhoto,
      onDeletePhoto,
      // entity_schemas
      onDeleteEntitySchema,
      onUploadEntitySchema,
      // opening_hours
      onSaveOpeningHours,
      //parameters
      onChangeSquare,
      onChangeRoom,
      onChangeEquipment,
      onChangeComplexity,
      // activities
      entityActivities,
      isActivitiesLoading,
      // entity_tasks
      isEntityTasksLoading,
      entityTasks,
      // tickets
      isTicketsLoading,
      tickets,
      startCreatedDate,
      endCreatedDate,
      categories,
      onChangePeriodTickets,
      onChangeCategories,
      // orders
      isOrdersLoading,
      orders,
      // projects
      isProjectsLoading,
      projects,
      // notes
      isEntityNotesLoading,
      onCreateNote,
      // extCodes
      onDeleteExtCode,
      // actions
      actions,
    } = this.props;

    const entityServices = entity.entity_services.filter(function (item) {
      return !item._destroy;
    });

    let organisationAssignees = [];
    let supervisorAssignees = [];
    let customerAssignees = [];

    if (entity.assignees) {
      organisationAssignees = isLoading
        ? []
        : entity.assignees.filter(function (item) {
            return item.assignee_type == "organisation";
          });
    }

    if (entity.assignees) {
      supervisorAssignees = entity.assignees.filter(function (item) {
        return item.assignee_type == "supervisor";
      });
    }

    if (entity.assignees) {
      customerAssignees = entity.assignees.filter(function (item) {
        return item.assignee_type == "customer";
      });
    }

    const internalAttachments = entity.attachments.filter(function (item) {
      return item.storage_type == "internal";
    });

    const externalAttachments = entity.attachments.filter(function (item) {
      return item.storage_type == "external";
    });

    // actions
    let actionsButton = null;

    if (actions.length > 0) {
      actionsButton = (
        <Dropdown key={99} overlay={this.ActionMenu(actions)}>
          <Button icon={"more"} style={{ marginLeft: "8px" }} />
        </Dropdown>
      );
    }

    const operations =
      tabKey == "extCodes" ? (
        <Button key="extCodesButton" onClick={this.onOpenAddingExtCode}>
          Добавить
        </Button>
      ) : null;

    const extCodes = entity.entity_extcodes.filter(function (item) {
      return !item._destroy;
    });

    return (
      <Form layout={"vertical"} onSubmit={this.onSubmit}>
        <Card
          loading={isLoading}
          title={
            <h2 style={{ marginBottom: 0 }}>{`Объект #${
              isNew ? "новый" : entity.name
            }`}</h2>
          }
          extra={[
            <Button onClick={onClose}>Закрыть</Button>,
            <Can
              role={currentUser.role}
              perform="entities:edit"
              yes={() => (
                <Button
                  onClick={this.onSubmit}
                  type="primary"
                  ghost
                  style={{ marginLeft: "8px" }}
                >
                  Сохранить
                </Button>
              )}
              no={() => null}
            />,
            actionsButton,
          ]}
        >
          <Col xs={24} sm={24} md={24} lg={13} xl={13}>
            <Tabs
              defaultActiveKey="1"
              onChange={this.callback}
              tabBarExtraContent={operations}
            >
              <TabPane tab="Основное" key="1">
                <Row>
                  <Can
                    role={currentUser.role}
                    perform="entities:edit"
                    yes={() => (
                      <General
                        getFieldDecorator={getFieldDecorator}
                        entity={entity}
                        // general
                        onChangeStart={onChangeStart}
                        onChangeFinish={onChangeFinish}
                        onChangeCustomer={onChangeCustomer}
                        onChangeName={onChangeName}
                        onChangeEntityType={onChangeEntityType}
                        onChangePostalCode={onChangePostalCode}
                        onChangeCity={onChangeCity}
                        onChangeStreet={onChangeStreet}
                        onChangeBuilding={onChangeBuilding}
                        onChangeExtCode={onChangeExtCode}
                        onChangeLat={onChangeLat}
                        onChangeLon={onChangeLon}
                        onChangeTodoTemplates={onChangeTodoTemplates}
                      />
                    )}
                    no={() => <GeneralInfo entity={entity} />}
                  />
                </Row>
              </TabPane>

              {isNew ? null : (
                <TabPane tab="Расписание" key="6">
                  <OpeningHours
                    openingHours={entity.opening_hours}
                    onSaveOpeningHours={onSaveOpeningHours}
                  />
                </TabPane>
              )}
              {isNew ? null : (
                <TabPane tab="Параметры" key="7">
                  <Parameters
                    currentUser={currentUser}
                    getFieldDecorator={getFieldDecorator}
                    square={entity.square}
                    onChangeSquare={onChangeSquare}
                    room={entity.room}
                    onChangeRoom={onChangeRoom}
                    equipment={entity.equipment}
                    onChangeEquipment={onChangeEquipment}
                    complexity={entity.complexity}
                    onChangeComplexity={onChangeComplexity}
                  />
                </TabPane>
              )}

              <TabPane tab="Внешние коды" key="extCodes">
                <ExtCodes
                  extCodes={extCodes}
                  extCode={extCode}
                  isAddingExtCode={isAddingExtCode}
                  onAddExtCode={this.onAddExtCode}
                  onDeleteExtCode={onDeleteExtCode}
                  onCloseAddingExtCode={this.onCloseAddingExtCode}
                  onChangeExtCode={this.onChangeExtCode}
                />
              </TabPane>
            </Tabs>
          </Col>
          <Col xs={0} sm={0} md={1} lg={1} xl={1} />
          <Col xs={24} sm={24} md={24} lg={10} xl={10}>
            <Tabs defaultActiveKey="1" onChange={this.callback}>
              <TabPane tab="Статусы" key="8">
                <Statuses
                  currentUser={currentUser}
                  getFieldDecorator={getFieldDecorator}
                  // assignees
                  onChangeIsOrganisation={onChangeIsOrganisation}
                  isOrganisation={entity.is_organisation}
                  customer={entity.customer}
                  organisationAssignees={organisationAssignees}
                  customerAssignees={customerAssignees}
                  supervisorAssignees={supervisorAssignees}
                  isAssigneesLoading={isAssigneesLoading}
                  onDeleteAssignee={onDeleteAssignee}
                  onCreateAssignee={onCreateAssignee}
                />
              </TabPane>
              <TabPane tab="Лог изменений" key="9">
                <Activities
                  activities={entityActivities}
                  isActivitiesLoading={isActivitiesLoading}
                />
              </TabPane>
            </Tabs>
          </Col>
          <Col span={24}>
            <Tabs defaultActiveKey="11" onChange={this.onChangeTab}>
              <TabPane tab="Разделы" key="11">
                <EntityServices
                  currentUser={currentUser}
                  entity={entity}
                  entityServices={entityServices}
                  onDeleteEntityServices={onDeleteEntityServices}
                  onSaveEntityService={onSaveEntityService}
                />
              </TabPane>
              {isNew ? null : (
                <TabPane tab="Изображения" key="2">
                  {isLoadingImages ? null : (
                    <Gallery
                      isLoadingImages={isLoadingImages}
                      isUploadingImage={isUploadingImage}
                      images={entity.entity_photos}
                      uploadPhoto={uploadPhoto}
                      onDeletePhoto={onDeletePhoto}
                    />
                  )}
                </TabPane>
              )}
              {isNew ? null : (
                <TabPane tab="Схемы" key="3">
                  {isLoadingImages ? null : (
                    <Gallery
                      isLoadingImages={isLoadingImages}
                      isUploadingImage={isUploadingImage}
                      images={entity.entity_schemas}
                      uploadPhoto={onUploadEntitySchema}
                      onDeletePhoto={onDeleteEntitySchema}
                    />
                  )}
                </TabPane>
              )}
              {isNew ? null : (
                <TabPane tab="Файлы" key="4">
                  {isLoadingImages ? null : (
                    <Documents
                      currentUser={currentUser}
                      isLoading={isAttachmentLoading}
                      attachments={internalAttachments}
                      onUpload={onUploadAttachment}
                      onDelete={onDeleteAttachment}
                    />
                  )}
                </TabPane>
              )}
              {isNew ? null : (
                <TabPane tab="Ссылки" key="5">
                  {isLoadingImages ? null : (
                    <ExternalDocuments
                      currentUser={currentUser}
                      isLoading={isAttachmentLoading}
                      attachments={externalAttachments}
                      onSave={onSaveAttachment}
                      onUpload={onUploadAttachment}
                      onDelete={onDeleteAttachment}
                    />
                  )}
                </TabPane>
              )}
              {isNew ? null : (
                <TabPane tab="Примечания" key="6">
                  <Notes
                    currentUser={currentUser}
                    isLoading={isEntityNotesLoading}
                    notes={entity.entity_notes}
                    onCreateNote={onCreateNote}
                  />
                </TabPane>
              )}
              <TabPane tab="Регламент" key="21">
                <EntityTasks
                  isLoading={isEntityTasksLoading}
                  entityTasks={entityTasks}
                />
              </TabPane>
              <TabPane tab="Заявки" key="22">
                <Tickets
                  isLoading={isTicketsLoading}
                  tickets={tickets}
                  startCreatedDate={startCreatedDate}
                  endCreatedDate={endCreatedDate}
                  categories={categories}
                  onChangePeriodTickets={onChangePeriodTickets}
                  onChangeCategories={onChangeCategories}
                />
              </TabPane>
              <TabPane tab="Заказы" key="23">
                <Orders isLoading={isOrdersLoading} orders={orders} />
              </TabPane>
              <TabPane tab="Проекты" key="24">
                <Projects isLoading={isProjectsLoading} projects={projects} />
              </TabPane>
            </Tabs>
          </Col>
        </Card>
      </Form>
    );
  }
}

EntityForm.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  entity: PropTypes.object,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
  router: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  form: PropTypes.object,
  fetchSuggestions: PropTypes.func,
  onChangeDate: PropTypes.func,
  onChangeUser: PropTypes.func,
  onChangeAgreement: PropTypes.func,
  onChangeAgreementDate: PropTypes.func,
  onChangeAdditional: PropTypes.func,
  onChangeComment: PropTypes.func,
  onChangeNotice: PropTypes.func,
  onChangePaymentType: PropTypes.func,
  onChangePaymentStatus: PropTypes.func,
  onChangeShippingStatus: PropTypes.func,
  onChangeShippingMethod: PropTypes.func,
  onAddVariant: PropTypes.func,
  onRemoveVariant: PropTypes.func,
  onChangeQuantity: PropTypes.func,
  onChangePrice: PropTypes.func,
};

export default Form.create()(EntityForm);
