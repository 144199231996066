// @flow
import React, { Component } from "react";
import { Row, Col, Modal, Form, InputNumber } from "antd";

import "moment/locale/ru";

import SelectFetch from "../../../../components/SelectFetch";

const FormItem = Form.Item;

class Employee extends React.Component {
  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onSave(values);
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      visible,
      onCancel,
      employee,
      date,
      departmentId,
      onChangeSalary,
      onChangeUser,
    } = this.props;
    return (
      <Modal
        title={"Добавить в список"}
        destroyOnClose
        visible={visible}
        onOk={this.onSubmit}
        onCancel={onCancel}
      >
        <Form layout={"vertical"}>
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <FormItem label={"Сотрудник"}>
                {getFieldDecorator("user", {
                  initialValue: employee.user ? employee.user : null,
                  rules: [
                    {
                      required: true,
                      message: "Пожалуйста, раздел!",
                    },
                  ],
                })(
                  <SelectFetch
                    showSearch
                    showSubtitle
                    placeholder="Сотрудник"
                    onChange={onChangeUser}
                    selected={employee.user ? employee.user : null}
                    url={`/users/search/?all=true&department_id=${departmentId}&finish=${date}`}
                  />
                )}
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem label={"Базовый оклад"}>
                {getFieldDecorator("salary", {
                  onChange: onChangeSalary,
                  initialValue: employee.salary,
                  rules: [
                    {
                      required: true,
                      message: "Введите базовый оклвд!",
                    },
                  ],
                })(<InputNumber style={{width: "100%"}} />)}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(Employee);
