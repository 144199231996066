// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Form, InputNumber } from "antd";
import Can from "../../../rules/Can";

const FormItem = Form.Item;

class Finance extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      currentUser,
      getFieldDecorator,
      productPrice,
      servicePrice,
      penalty,
      payment,
      onChangeProductPrice,
      onChangeServicePrice,
      onChangePenalty,
      onChangePayment
    } = this.props;

    return (
      <React.Fragment>
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col span={12}>
            <FormItem label={"Стоимость товара"}>
              {getFieldDecorator("productPrice", {
                onChange: onChangeProductPrice,
                initialValue: productPrice
              })(<InputNumber min={0} style={{ width: "100%" }} />)}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label={"Стоимость услуг"}>
              {getFieldDecorator("servicePrice", {
                onChange: onChangeServicePrice,
                initialValue: servicePrice
              })(<InputNumber min={0} style={{ width: "100%" }} />)}
            </FormItem>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

Finance.propTypes = {
  order: PropTypes.object,
  total: PropTypes.number,
  discount: PropTypes.object,
  shippingCost: PropTypes.number,
  amountDiscountCode: PropTypes.number,
  onRemoveVariant: PropTypes.func
};
export default Finance;
