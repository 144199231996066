// @flow
import React, { Component, Fragment } from "react";
import { Row, Col } from "antd";
import {
  Chart,
  registerShape,
  Annotation,
  Axis,
  Tooltip,
  Interval,
  Interaction,
  Coordinate,
} from "bizcharts";

import accounting from "accounting";

class ProjectPie extends Component {
  render() {
    const { currentUser, fetchParams, data } = this.props;

    const total = Number(data.processing_amount);

    const totals = [
      {
        type: "Согласованные в работе",
        percent:
          data.proposal_approved_amount > 0
            ? (Number(data.proposal_approved_amount) / total) * 100
            : 0,
        amount: Number(data.proposal_approved_amount),
      },
      {
        type: "Ожидают согласования",
        percent:
          data.proposal_pending_amount > 0
            ? (Number(data.proposal_pending_amount) / total) * 100
            : 0,
        amount: Number(data.proposal_pending_amount),
      },
      {
        type: "Ожидают принятия",
        percent:
          data.job_approving_amount > 0
            ? (Number(data.job_approving_amount) / total) * 100
            : 0,
        amount: Number(data.job_approving_amount),
      },
    ];

    const cols = {
      percent: {
        formatter: (val) => {
          val = Math.round(val) + "%";
          return val;
        },
      },
    };

    return (
      <Row gutter={1}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Chart data={totals} scale={cols} height={300} autoFit>
            <Coordinate type="theta" radius={0.8} innerRadius={0.75} />
            <Axis visible={false} />
            <Tooltip showTitle={false} />
            <Interval
              adjust="stack"
              position="percent"
              color="type"
              shape="sliceShape"
              label={[
                "amount",
                {
                  content: (totals) => {
                    return `${totals.type}: ${accounting.formatNumber(
                      totals.amount,
                      0,
                      " "
                    )}`;
                  },
                },
              ]}
            />
            <Annotation.Text
              position={["50%", "50%"]}
              content={`${accounting.formatNumber(total, 0, " ")}`}
              style={{
                lineHeight: "140px",
                fontSize: "25",
                fill: "#262626",
                textAlign: "center",
              }}
            />
            <Interaction type="element-single-selected" />
          </Chart>
        </Col>
      </Row>
    );
  }
}

export default ProjectPie;
