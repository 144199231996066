// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../../actions/fieldSets";
import { Spin } from "antd";
import Form from "./Form";

class FieldSet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: false
    };
  }

  componentWillMount = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.fetchFieldSet(this.props.match.params.id));
    } else {
      this.setState({ isNew: true }, () => {
        this.props.dispatch(Actions.onNew());
      });
    }
  };

  onSave = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.onUpdate(this.props.match.params.id));
    } else {
      this.props.dispatch(Actions.onCreate()).then(() => {
        if (!this.props.errors) {
          this.props.history.push(`/field_sets/${this.props.fieldSet.id}/edit`);
        }
      });
    }
  };

  onClose = () => {
    this.props.history.push(`/field_sets`);
  };


  // general
  onChangeName = e => {
    this.props.dispatch(Actions.onChangeName(e.target.value));
  };

  onChangePresentation = e => {
    this.props.dispatch(Actions.onChangePresentation(e.target.value));
  };

  onChangeOwnerType = (value) => {
    this.props.dispatch(Actions.onChangeOwnerType(value));
  };

  onChangeFieldSetType = (value) => {
    this.props.dispatch(Actions.onChangeFieldSetType(value));
  };

  // options
  onSaveOption = (item) => {
    const { fieldSet } = this.props;
    let idx = fieldSet.options.findIndex(function (o) {
      return o.id == item.id;
    });
    if (idx === -1) {
      this.props.dispatch(Actions.onCreateOption(item));
    } else {
      this.props.dispatch(Actions.onUpdateOption(idx, item));
    }
  };

  onDeleteOptions = (ids) => {
    const { fieldSet } = this.props;
    ids.map((id) => {
      let idx = fieldSet.options.findIndex(function (o) {
        return o.id == id;
      });
      if (idx === -1) {
        return null;
      }
      if (fieldSet.options[idx].is_exist) {
        this.props.dispatch(Actions.onDeleteOption(idx));
      } else {
        this.props.dispatch(Actions.onDestroyOption(idx));
      }
    });
  };

  // sidebar
  onChangeIsActive = (e) => {
    // console.log(e.target.checked)
    this.props.dispatch(Actions.onChangeIsActive(e.target.checked));
  };

  onChangeIsRequired = (e) => {
    this.props.dispatch(Actions.onChangeIsRequired(e.target.checked));
  };


  render() {
    const { isNew } = this.state;
    const { isLoading, fieldSet } = this.props;
    return isLoading ? (
      <Spin />
    ) : (
      <Form
        isNew={isNew}
        fieldSet={fieldSet}
        onSave={this.onSave}
        onClose={this.onClose}
        // general
        onChangeName={this.onChangeName}
        onChangePresentation={this.onChangePresentation}
        onChangeOwnerType={this.onChangeOwnerType}
        onChangeFieldSetType={this.onChangeFieldSetType}
        onSaveOption={this.onSaveOption}
        onDeleteOptions={this.onDeleteOptions}
        // sidebar
        onChangeIsActive={this.onChangeIsActive}
        onChangeIsRequired={this.onChangeIsRequired}
      />
    );
  }
}

FieldSet.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  fieldSet: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool
};

const mapStateToProps = state => ({
  isLoading: state.fieldSets.isLoading,
  errors: state.fieldSets.errors,
  fieldSet: state.fieldSets.fieldSet
});

export default connect(mapStateToProps)(withRouter(FieldSet));
