// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import Actions from "../../../actions/supportMessageTypes";
import List from "./List";

class SupportMessageTypes extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.fetchSupportMessageTypes());
  };

  onSearch = (e) => {
    this.props
      .dispatch(Actions.onSearch(e.target.value))
      .then(() => this.props.dispatch(Actions.fetchSupportMessageTypes()));
  };

  onChangePage = (value) => {
    this.props
      .dispatch(Actions.onChangePage(value))
      .then(() => this.props.dispatch(Actions.fetchSupportMessageTypes()));
  };

  onChangeLimit = (current, size) => {
    this.props
      .dispatch(Actions.onChangeLimit(size))
      .then(() => this.props.dispatch(Actions.fetchSupportMessageTypes()));
  };

  onSortBy = (field, direction) => {
    this.props
      .dispatch(Actions.onSortBy(field, direction))
      .then(() => this.props.dispatch(Actions.fetchSupportMessageTypes()));
  };

  

  render() {
    const { currentUser, isLoading, supportMessageTypes, meta, fetchParams } =
      this.props;
    return (
      <List
        currentUser={currentUser}
        loading={isLoading}
        supportMessageTypes={supportMessageTypes}
        meta={meta}
        fetchParams={fetchParams}
        onDelete={this.onDelete}
        onSearch={this.onSearch}
        onChangePage={this.onChangePage}
        onChangeLimit={this.onChangeLimit}
        onSortBy={this.onSortBy}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  isLoading: state.supportMessageTypes.isLoading,
  supportMessageTypes: state.supportMessageTypes.supportMessageTypes,
  meta: state.supportMessageTypes.meta,
  fetchParams: state.supportMessageTypes.fetchParams,
});

export default connect(mapStateToProps)(SupportMessageTypes);
