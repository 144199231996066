import api from "../api";

const Actions = {};

Actions.fetchPayrollSchemas = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_PAYROLL_SCHEMAS_REQUEST" });
    const params = getState().payrollSchemas.fetchParams;
    api
      .fetch("/payroll_schemas", params)
      .then(response => {
        dispatch({ type: "FETCH_PAYROLL_SCHEMAS", response });
      })
      .catch((error) => {
        api.handleError(error, "Схемы начислений");
        dispatch({ type: "FETCH_PAYROLL_SCHEMAS_FAILURE" });
      });
  };
};

Actions.fetchPayrollSchema = (id = null) => {
  return dispatch => {
    dispatch({ type: "FETCH_PAYROLL_SCHEMA_REQUEST" });
    api
      .fetch(`/payroll_schemas/${id}`)
      .then(response => {
        dispatch({ type: "FETCH_PAYROLL_SCHEMA", response });
      })
      .catch((error) => {
        api.handleError(error, "Схема начислений");
        dispatch({ type: "FETCH_PAYROLL_SCHEMA_FAILURE" });
      });
  };
};

Actions.onNew = () => {
  return dispatch => {
    dispatch({
      type: "PAYROLL_SCHEMA_NEW"
    });
  };
};

Actions.onUpdate = id => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_PAYROLL_SCHEMA_REQUEST" });
    const payrollSchema = getState().payrollSchemas.payrollSchema;
    const payroll_schema_values = payrollSchema.payroll_schema_values.map(item => {
      return {
        id: item.is_exist ? item.id : null,
        is_auto: item.is_auto,
        name: item.name,
        schema_value_type: item.schema_value_type,
        weight: item.weight,
        value: item.value,
        from: item.from,
        to: item.to,
        _destroy: item._destroy,
      };
    });

    const data = {
      payroll_schema: {
        name: payrollSchema.name,
        description: payrollSchema.description,
        orders: payrollSchema.orders,
        payroll_schema_values_attributes: payroll_schema_values
      }
    };

    api
      .patch(`/payroll_schemas/${id}`, data)
      .then(response => {
        dispatch({ type: "PATCH_PAYROLL_SCHEMA", response });
        api.openNotification("success", "Схема начислений", "Успешно обновлена!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Схема начислений", error);
          });
        }
        dispatch({ type: "PATCH_PAYROLL_SCHEMA_FAILURE", response });
      });
  };
};

Actions.onCreate = () => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_PAYROLL_SCHEMA" });
    const payrollSchema = getState().payrollSchemas.payrollSchema;
    const payroll_schema_values = payrollSchema.payroll_schema_values.map(item => {
      return {
        id: item.is_exist ? item.id : null,
        is_auto: item.is_auto,
        name: item.name,
        schema_value_type: item.schema_value_type,
        weight: item.weight,
        value: item.value,
        from: item.from,
        to: item.to,
        _destroy: item._destroy,
      };
    });

    const data = {
      payroll_schema: {
        name: payrollSchema.name,
        description: payrollSchema.description,
        orders: payrollSchema.orders,
        payroll_schema_values_attributes: payroll_schema_values
      }
    };

    return api
      .post(`/payroll_schemas`, data)
      .then(response => {
        dispatch({ type: "POST_PAYROLL_SCHEMA_SUCCEED", response });
        api.openNotification("success", "Схема начислений", "Успешно создана!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Схема начислений", error);
          });
        }
        dispatch({ type: "POST_PAYROLL_SCHEMA_FAILURE", response });
      });
  };
};

Actions.onDelete = id => {
  return dispatch => {
    dispatch({ type: "DELETE_PAYROLL_SCHEMA_REQUEST" });
    return api
      .delete(`/payroll_schemas/${id}`)
      .then(response => {
        api.openNotification("success", "Схема начислений", "Успешно удалена!");
        dispatch({ type: "DELETE_PAYROLL_SCHEMA", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Схема начислений", error);
          });
        }
        dispatch({
          type: "DELETE_PAYROLL_SCHEMA_FAILURE"
        });
      });
  };
};

// header
Actions.onChangeName = value => {
  return dispatch => {
    dispatch({
      type: "PAYROLL_SCHEMA_CHANGE_NAME",
      value: value
    });
  };
};

Actions.onChangeDescription = value => {
  return dispatch => {
    dispatch({
      type: "PAYROLL_SCHEMA_CHANGE_DESCRIPTION",
      value: value
    });
  };
};

Actions.onChangeOrders = value => {
  return dispatch => {
    dispatch({
      type: "PAYROLL_SCHEMA_CHANGE_ORDERS",
      value: value
    });
  };
};

// payroll_schema_values
Actions.onAddPayrollSchemaValue = item => {
  return dispatch => {
    dispatch({
      type: "PAYROLL_SCHEMA_ADD_VALUE",
      item: item
    });
  };
};

Actions.onDeleteSchemaValue = idx => {
  return dispatch => {
    dispatch({
      type: "PAYROLL_SCHEMA_VALUE_DELETE",
      idx: idx
    });
  };
};

Actions.onDestroySchemaValue = idx => {
  return dispatch => {
    dispatch({
      type: "PAYROLL_SCHEMA_VALUE_DESTROY",
      idx: idx
    });
  };
};

Actions.onChangeSchemaValueName = (id, value) => {
  return dispatch => {
    dispatch({
      type: "PAYROLL_SCHEMA_CHANGE_VALUE_NAME",
      id: id,
      value: value
    });
  };
};

Actions.onChangeSchemaValueWeight = (id, value) => {
  return dispatch => {
    dispatch({
      type: "PAYROLL_SCHEMA_CHANGE_VALUE_WEIGHT",
      id: id,
      value: value
    });
  };
};

Actions.onChangeSchemaValue = (id, value) => {
  return dispatch => {
    dispatch({
      type: "PAYROLL_SCHEMA_CHANGE_VALUE",
      id: id,
      value: value
    });
  };
};

Actions.onChangeSchemaValueFrom = (id, value) => {
  return dispatch => {
    dispatch({
      type: "PAYROLL_SCHEMA_CHANGE_VALUE_FROM",
      id: id,
      value: value
    });
  };
};

Actions.onChangeSchemaValueTo = (id, value) => {
  return dispatch => {
    dispatch({
      type: "PAYROLL_SCHEMA_CHANGE_VALUE_TO",
      id: id,
      value: value
    });
  };
};



export default Actions;
