import api from "../api";

const Actions = {};

Actions.fetchTodoTemplates = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_TODO_TEMPLATES_REQUEST" });
    const params = getState().todoTemplates.fetchParams;
    api
      .fetch("/todo_templates", params)
      .then((response) => {
        dispatch({ type: "FETCH_TODO_TEMPLATES", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_TODO_TEMPLATES_FAILURE", response });
      });
  };
};

Actions.fetchTodoTemplate = (id = null) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_TODO_TEMPLATE_REQUEST" });
    api
      .fetch(`/todo_templates/${id}`)
      .then((response) => {
        dispatch({ type: "FETCH_TODO_TEMPLATE", response });
      })
      .catch((error) => {
        api.handleError(error, "Шаблон чек-листа");
        dispatch({ type: "FETCH_TODO_TEMPLATE_FAILURE" });
      });
  };
};

Actions.onNew = () => {
  return (dispatch, getState) => {
    dispatch({
      type: "TODO_TEMPLATE_NEW",
    });
  };
};

Actions.onUpdate = (id) => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_TODO_TEMPLATE_REQUEST" });
    const todoTemplate = getState().todoTemplates.todoTemplate;
    const todo_template_items = todoTemplate.todo_template_items.map((item) => {
      return {
        id: item.is_exist ? item.id : null,
        position: item.position,
        todo_item_id: item.todo_item ? item.todo_item.id : null,
        _destroy: item._destroy,
      };
    });

    const serviceIds = todoTemplate.services
      ?.map((it) => it.id)
      ?.map((it) => parseInt(it) || null)
      ?.filter((it) => it !== null);

    const data = {
      todo_template: {
        is_active: todoTemplate.is_active,
        name: todoTemplate.name,
        description: todoTemplate.description,
        todo_type: todoTemplate.todo_type,
        todo_template_items_attributes: todo_template_items,
        service_ids: serviceIds,
      },
    };

    api
      .patch(`/todo_templates/${id}`, data)
      .then((response) => {
        dispatch({ type: "PATCH_TODO_TEMPLATE", response });
        api.openNotification(
          "success",
          "Шаблон чек-листа",
          "Успешно обновлен!"
        );
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Шаблон чек-листа", error);
          });
        }
        dispatch({ type: "PATCH_TODO_TEMPLATE_FAILURE", response });
      });
  };
};

Actions.onCreate = () => {
  return (dispatch, getState) => {
    dispatch({ type: "TODO_TEMPLATE_REQUEST" });
    const todoTemplate = getState().todoTemplates.todoTemplate;
    const todo_template_items = todoTemplate.todo_template_items.map((item) => {
      return {
        id: item.is_exist ? item.id : null,
        position: item.position,
        todo_item_id: item.todo_item ? item.todo_item.id : null,
        _destroy: item._destroy,
      };
    });

    const serviceIds = todoTemplate.services
      ?.map((it) => it.id)
      ?.map((it) => parseInt(it) || null)
      ?.filter((it) => it !== null);

    const data = {
      todo_template: {
        is_active: todoTemplate.is_active,
        name: todoTemplate.name,
        description: todoTemplate.description,
        todo_type: todoTemplate.todo_type,
        todo_template_items_attributes: todo_template_items,
        service_ids: serviceIds,
      },
    };
    return api
      .post(`/todo_templates`, data)
      .then((response) => {
        dispatch({ type: "TODO_TEMPLATE_SUCCEED", response });
        api.openNotification("success", "Шаблон чек-листа", "Успешно создан!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Шаблон чек-листа", error);
          });
        }
        dispatch({ type: "TODO_TEMPLATE_FAILURE", response });
      });
  };
};

Actions.onDelete = (id) => {
  return (dispatch) => {
    dispatch({ type: "DELETE_TODO_TEMPLATE_REQUEST" });
    return api
      .delete(`/todo_templates/${id}`)
      .then((response) => {
        api.openNotification("success", "Шаблон чек-листа", "Успешно удалено!");
        dispatch({ type: "DELETE_TODO_TEMPLATE", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Шаблон чек-листа", error);
          });
        }
        dispatch({
          type: "DELETE_TODO_TEMPLATE_FAILURE",
        });
      });
  };
};

Actions.onChangePage = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_TODO_TEMPLATE_CHANGE_PAGE",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeLimit = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_TODO_TEMPLATE_CHANGE_LIMIT",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onSortBy = (field, direction) => {
  return (dispatch) => {
    dispatch({
      type: "TODO_TEMPLATES_SORT_BY",
      field: field,
      direction: direction,
    });
    return Promise.resolve();
  };
};

Actions.onChangeActive = (value) => {
  return (dispatch) => {
    dispatch({
      type: "TODO_TEMPLATE_CHANGE_ACTIVE",
      value: value,
    });
  };
};

Actions.onChangeName = (value) => {
  return (dispatch) => {
    dispatch({
      type: "TODO_TEMPLATE_CHANGE_NAME",
      value: value,
    });
  };
};

Actions.onChangeDescription = (value) => {
  return (dispatch) => {
    dispatch({
      type: "TODO_TEMPLATE_CHANGE_DESCRIPTION",
      value: value,
    });
  };
};

Actions.onChangeTodoType = (value) => {
  return (dispatch) => {
    dispatch({
      type: "TODO_TEMPLATE_CHANGE_TODO_TYPE",
      value: value,
    });
  };
};

Actions.onChangeServices = (value) => {
  return (dispatch) => {
    dispatch({
      type: "TODO_TEMPLATE_CHANGE_SERVICES",
      value: value,
    });
  };
};

// todos
Actions.onAddTodo = (value) => {
  return (dispatch) => {
    dispatch({
      type: "TODO_TEMPLATE_ADD_TODO",
      value: value,
    });
  };
};

Actions.onDeleteTodo = (id) => {
  return (dispatch) => {
    dispatch({
      type: "TODO_TEMPLATE_DESTROY_TODO",
      id: id,
    });
  };
};

// todo_items
Actions.onCreateTodoItem = () => {
  return (dispatch, getState) => {
    dispatch({ type: "TODO_ITEM_REQUEST" });
    const todoItem = getState().todoTemplates.todoItem;

    const data = {
      todo_item: {
        is_active: todoItem.is_active,
        todo_type: todoItem.todo_type,
        name: todoItem.name,
      },
    };
    return api
      .post(`/todo_items`, data)
      .then((response) => {
        dispatch({ type: "TODO_ITEM_SUCCEED", response });
        api.openNotification("success", "Элемент", "Успешно создан!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Шаблон чек-листа", error);
          });
        }
        dispatch({ type: "TODO_ITEM_FAILURE", response });
      });
  };
};

Actions.onNewTodoItem = (value) => {
  return (dispatch) => {
    dispatch({
      type: "TODO_ITEM_NEW",
      value: value
    });
  };
};

Actions.onCancelTodoItem = () => {
  return (dispatch) => {
    dispatch({
      type: "TODO_ITEM_CANCEL",
    });
  };
};

Actions.onChangeTodoItemName = (value) => {
  return (dispatch) => {
    dispatch({
      type: "TODO_ITEM_CHANGE_NAME",
      value: value,
    });
  };
};

export default Actions;
