// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Checkbox, Divider, Form, Input } from "antd";
import SelectFetch from "../../../components/SelectFetch";

const FormItem = Form.Item;

class Settings extends Component {
  render() {
    const {
      getFieldDecorator,
      customerId,
      settings,
      onChangeTicketLogs,
      onChangeTaskLogs,
      onChangeIndoundEmails,
      onChangeInboundDomain,
      onChangeInboundDomainTest,
      onChangeInboundEmail,
      onChangeInboundEmailTest,
      onChangeInboundEmailMember,
      onChangeUseAutoRate,
      onChangeAllowAllOrderTasks,
      onChangeCompletionCommentRequired,
    } = this.props;

    return (
      <React.Fragment>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Divider>Настройки логов</Divider>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Checkbox
              checked={settings.is_ticket_logs}
              onChange={onChangeTicketLogs}
            >
              Заявки
            </Checkbox>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Checkbox
              checked={settings.is_task_logs}
              onChange={onChangeTaskLogs}
            >
              Задачи
            </Checkbox>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Divider>Inbound emails</Divider>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem>
              {getFieldDecorator("isInboundEmails", {
                onChange: onChangeIndoundEmails,
                valuePropName: "checked",
                initialValue: settings.is_inbound_emails,
              })(<Checkbox>Inbound emails</Checkbox>)}
            </FormItem>
          </Col>
          <Col md={{ span: 12 }} xs={{ span: 12 }}>
            <FormItem label={"Домен для входящих"}>
              {getFieldDecorator("inbound_domain", {
                rules: [
                  {
                    required: settings.is_inbound_emails,
                    message: "Введите домен!",
                  },
                ],
                onChange: onChangeInboundDomain,
                initialValue: settings.inbound_domain
                  ? settings.inbound_domain
                  : null,
              })(<Input disabled={!settings.is_inbound_emails} />)}
            </FormItem>
          </Col>
          <Col md={{ span: 12 }} xs={{ span: 12 }}>
            <FormItem label={"Домен для входящих (тест)"}>
              {getFieldDecorator("inbound_domain_test", {
                onChange: onChangeInboundDomainTest,
                initialValue: settings.inbound_domain_test
                  ? settings.inbound_domain_test
                  : null,
              })(<Input disabled={!settings.is_inbound_emails} />)}
            </FormItem>
          </Col>
          <Col md={{ span: 12 }} xs={{ span: 12 }}>
            <FormItem label={"Email для исходящих"}>
              {getFieldDecorator("inbound_email", {
                rules: [
                  {
                    required: settings.is_inbound_emails,
                    message: "Введите email!",
                  },
                ],
                onChange: onChangeInboundEmail,
                initialValue: settings.inbound_email
                  ? settings.inbound_email
                  : null,
              })(<Input type="email" disabled={!settings.is_inbound_emails} />)}
            </FormItem>
          </Col>
          <Col md={{ span: 12 }} xs={{ span: 12 }}>
            <FormItem label={"Email для исходящих (тест)"}>
              {getFieldDecorator("inbound_email_test", {
                onChange: onChangeInboundEmailTest,
                initialValue: settings.inbound_email_test
                  ? settings.inbound_email_test
                  : null,
              })(<Input type="email" disabled={!settings.is_inbound_emails} />)}
            </FormItem>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormItem label={"Пользователь"}>
              {getFieldDecorator("inbound_email_member", {
                initialValue: settings.inbound_email_member
                ? settings.inbound_email_member
                : null,
              })(
                <SelectFetch
                  showSearch
                  placeholder="Пользователь"
                  onChange={onChangeInboundEmailMember}
                  selected={settings.inbound_email_member
                    ? settings.inbound_email_member
                    : null}
                  url={customerId
                  ? `/members/search/?customer_id=${customerId}`
                  : `/members/search/?`}
                />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Divider>Прочее</Divider>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem>
              {getFieldDecorator("use_auto_rate", {
                onChange: onChangeUseAutoRate,
                valuePropName: "checked",
                initialValue: settings.use_auto_rate,
              })(<Checkbox>Авторейтинг</Checkbox>)}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem>
              {getFieldDecorator("allow_all_order_tasks", {
                onChange: onChangeAllowAllOrderTasks,
                valuePropName: "checked",
                initialValue: settings.allow_all_order_tasks,
              })(<Checkbox>Все задачи по заказам</Checkbox>)}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem>
              {getFieldDecorator("completion_comment_required", {
                onChange: onChangeCompletionCommentRequired,
                valuePropName: "checked",
                initialValue: settings.completion_comment_required,
              })(<Checkbox>Комментарий о выполнении обязателен</Checkbox>)}
            </FormItem>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
Settings.propTypes = {
  settings: PropTypes.object,
};

export default Settings;
