// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Actions from "../../../../actions/payrolls";
import PayrollsList from "./List";

class Payrolls extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.fetchPayrolls());
  };

  onDelete = ids => {
    ids.map(id => {
      this.props.dispatch(Actions.onDelete(id)).then(() => {
        this.props.dispatch(Actions.fetchPayrolls());
      });
    });
  };


  onChangePage = value => {
    this.props
      .dispatch(Actions.onChangePage(value))
      .then(() => this.props.dispatch(Actions.fetchPayrolls()));
  };

  onChangeLimit = (current, size) => {
    this.props
      .dispatch(Actions.onChangeLimit(size))
      .then(() => this.props.dispatch(Actions.fetchPayrolls()));
  };


  onSortBy = (field, direction) => {
    this.props
      .dispatch(Actions.onSortBy(field, direction))
      .then(() => this.props.dispatch(Actions.fetchPayrolls()));
  };

  render() {
    const { isLoading, payrolls, meta, fetchParams } = this.props;
    return (
      <PayrollsList
        loading={isLoading}
        payrolls={payrolls}
        meta={meta}
        fetchParams={fetchParams}
        onDelete={this.onDelete}
        onChangePage={this.onChangePage}
        onChangeLimit={this.onChangeLimit}
        onSortBy={this.onSortBy}
      />
    );
  }
}

Payrolls.propTypes = {
  payrolls: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  fetchParams: PropTypes.object,
  meta: PropTypes.object,
  dispatch: PropTypes.func,
  onDelete: PropTypes.func
};

const mapStateToProps = state => ({
  isLoading: state.payrolls.isLoading,
  payrolls: state.payrolls.payrolls,
  meta: state.payrolls.meta,
  fetchParams: state.payrolls.fetchParams
});

export default connect(mapStateToProps)(Payrolls);
