// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Form, Card, Steps, Button, message } from "antd";
import moment from "moment";
import "moment/locale/ru";
import Header from "./Header";

const { Step } = Steps;

class Content extends Component {
  render() {
    const {
      payroll,
      onClose,
      onCreate,
      // header
      onChangePeriod,
      onChangeDepartments,
      onChangeDescription,
    } = this.props;
    const { getFieldDecorator } = this.props.form;

    const isSubmitDisable =
      payroll.startDate == null ||
      payroll.endDate == null ||
      payroll.description == null ||
      payroll.departments.length == 0;

    return (
      <Form layout={"vertical"}>
        <Card
          title={<h2 style={{ marginBottom: 0 }}>{`Начисления по подразделениям`}</h2>}
          extra={[
            <Button onClick={onClose} style={{marginRight: 5}}>Закрыть</Button>,
            <Button
              disabled={isSubmitDisable}
              type="primary"
              onClick={onCreate}
            >
              Готово
            </Button>,
          ]}
        >
          <Row gutter={16} style={{ paddingTop: 15 }}>
            <Header
              payroll={payroll}
              getFieldDecorator={getFieldDecorator}
              onChangePeriod={onChangePeriod}
              onChangeDepartments={onChangeDepartments}
              onChangeDescription={onChangeDescription}
            />
          </Row>
        </Card>
      </Form>
    );
  }
}

Content.propTypes = {
  entity: PropTypes.object,
};

export default Form.create()(Content);
