// @flow
import React, { Component } from "react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Drawer,
  Form,
  Icon,
  Modal,
  Popover,
  Radio,
  Row,
  Select,
  Statistic,
  Table,
  Tabs,
  Typography
} from "antd";
import LoadingSpin from "../../../components/LoadingSpin";
import moment from "moment";
import "moment/locale/ru";
import Actions from "../../../actions/analytics";
import { compose } from "redux";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import SelectMultiFetch from "../../../components/SelectMultiFetch";
import SelectFetch from "../../../components/SelectFetch";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";

const {RangePicker, WeekPicker, MonthPicker} = DatePicker;
const {Option} = Select;
const {TabPane} = Tabs;

const statColorForNewTickets = '#0095ff';
const statColorForCompletedOnTimeTickets = '#32ab00';
const statColorForCompletedPastDueTickets = '#ff8800';
const statColorForCancelledTickets = '#919191';

function generateQuarterReferenceDates() {
  const now = moment.utc();

  return [
    moment.utc(now).startOf('quarter'),
    moment.utc(now).add(-1, 'quarters').startOf('quarter'),
    moment.utc(now).add(-2, 'quarters').startOf('quarter'),
    moment.utc(now).add(-3, 'quarters').startOf('quarter'),
    moment.utc(now).add(-4, 'quarters').startOf('quarter'),
    moment.utc(now).add(-5, 'quarters').startOf('quarter'),
    moment.utc(now).add(-6, 'quarters').startOf('quarter'),
    moment.utc(now).add(-7, 'quarters').startOf('quarter'),
  ];
}

function getPeriodDates(period, value) {
  if (value === null) {
    return [null, null];
  }

  switch (period) {
    case 'day':
      return [
        moment.utc(value).startOf('day'),
        moment.utc(value).endOf('day')
      ];
    case 'week':
      return [
        moment.utc(value).startOf('week'),
        moment.utc(value).endOf('week')
      ];
    case 'month':
      return [
        moment.utc(value).startOf('month'),
        moment.utc(value).endOf('month')
      ];
    case 'quarter':
      return [
        moment.utc(value).startOf('quarter'),
        moment.utc(value).endOf('quarter')
      ];
    case 'custom':
      return [
        moment.utc(value[0]).startOf('day'),
        moment.utc(value[1]).endOf('day')
      ];
    default:
      return [null, null];
  }
}

function getCorrespondingPeriodDates(period, periodFrom, periodTo) {
  if (periodFrom === null || periodTo === null) {
    return [null, null];
  }

  switch (period) {
    case 'day':
      return [
        moment.utc(periodFrom).add(-1, 'days'),
        moment.utc(periodTo).add(-1, 'days')
      ];
    case 'week':
      return [
        moment.utc(periodFrom).add(-1, 'weeks'),
        moment.utc(periodTo).add(-1, 'weeks')
      ];
    case 'month':
      return [
        moment.utc(periodFrom).add(-1, 'months'),
        moment.utc(periodTo).add(-1, 'months')
      ];
    case 'quarter':
      return [
        moment.utc(periodFrom).add(-1, 'quarters'),
        moment.utc(periodTo).add(-1, 'quarters')
      ];
    case 'custom':
      let diff = periodTo.diff(periodFrom, 'days');
      return [
        moment.utc(periodFrom).add(-(diff + 1), 'days'),
        moment.utc(periodTo).add(-(diff + 1), 'days')
      ];
    default:
      return [null, null];
  }
}

function renderStatDiffCard(title, thisValue, thatValue, increaseIsGood, onClick) {
  const ratio =
    thisValue === 0 ?
      0 :
      thatValue === 0 ?
        1 :
        thisValue / thatValue;
  const value = ratio * 100;
  const precision = (0 < value && value < 1) ? 2 : 0;

  let color = '#000000';
  if (0 <= value && value < 1) {
    color = increaseIsGood ? '#bb0000' : '#3f8600';
  } else if (1 < value) {
    color = increaseIsGood ? '#3f8600' : '#bb0000';
  }
  const valueStyle = {
    color: color,
  };

  let icon = null;
  if (0 <= value && value < 1) {
    icon = increaseIsGood ? <Icon type={'fall'}/> : <Icon type={'rise'}/>;
  } else if (1 < value) {
    icon = increaseIsGood ? <Icon type={'rise'}/> : <Icon type={'fall'}/>;
  }
  const prefix = icon;

  return (
    <Card
      bordered={true}
      onClick={onClick}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}>
        <Typography
          style={{
            fontSize: '18px',
            fontWeight: 'bold',
          }}>
          {title}
        </Typography>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'baseline',
            }}>
            <div
              style={{
                paddingRight: '4px',
              }}>
              <Statistic
                value={thisValue}/>
            </div>
            <Statistic
              prefix={'/'}
              value={thatValue}
              valueStyle={{
                fontSize: '18px',
                color: '#888888',
              }}/>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'baseline',
            }}>
            <Statistic
              value={
                isFinite(value) ?
                  Math.abs(value) :
                  100
              }
              valueStyle={valueStyle}
              precision={precision}
              prefix={prefix}
              suffix={'%'}/>
          </div>
        </div>
      </div>
    </Card>
  );
}

function renderTicketsForStatType(loading = false, data = []) {
  const columns = [
    {
      key: "number",
      title: "#",
      dataIndex: "number",
      render: (number, record) => (
        <Link to={`/tickets/${record.id}/edit`} target="_blank">
          {number}
        </Link>
      ),
      align: "center",
      width: "15%",
    },
    {
      key: "created_at",
      title: "Дата создания",
      dataIndex: "created_at",
      render: (created_at) => moment(created_at).format("LLL"),
      align: "center",
      width: "20%",
    },
    {
      key: "subject",
      title: "Тема",
      dataIndex: "subject",
      render: (subject, record) => (
        <div style={{display: "flex", flexDirection: "column"}}>
          <span>{subject}</span>
        </div>
      ),
      align: "center",
      width: "65%",
    },
  ];

  return (
    <Table
      loading={loading}
      size={"small"}
      rowKey="id"
      rowClassName={"progress-row"}
      dataSource={data}
      columns={columns}
      pagination={false}
      scroll={{
        y: 340
      }}
    />
  );
}

class Analytic extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ticketStatDiffFilterVisible: false,

      ticketStatDistributionChartType: 'bar',
      ticketStatDistributionForNewTicketsVisible: true,
      ticketStatDistributionForCompletedOnTimeTicketsVisible: true,
      ticketStatDistributionForCompletedPastDueTicketsVisible: true,
      ticketStatDistributionForCancelledTicketsVisible: true,
      ticketsForStatTypeVisible: false,
    };
  }

  componentWillMount = () => {
    this.props.dispatch(Actions.fetchTicketStatDiff());
    this.props.dispatch(Actions.fetchTicketStatDistribution());
  };

  onToggleTicketStatDiffFilter = () => {
    this.setState({
      ticketStatDiffFilterVisible: !this.state.ticketStatDiffFilterVisible
    });
  };

  onChangeTicketStatDiffFetchFilterReplacementType = (value) => {
    this.props.dispatch(Actions.setTicketStatDiffFetchFilterReplacementType(value))
      .then(() =>
        this.props.dispatch(Actions.fetchTicketStatDiff()));
  };

  onChangeTicketStatDiffFetchFilterCustomer = (value) => {
    this.props.dispatch(Actions.setTicketStatDiffFetchFilterCustomer(value))
      .then(() =>
        this.props.dispatch(Actions.fetchTicketStatDiff()));
  };

  onChangeTicketStatDiffFetchFilterEntity = (value) => {
    this.props.dispatch(Actions.setTicketStatDiffFetchFilterEntity(value))
      .then(() =>
        this.props.dispatch(Actions.fetchTicketStatDiff()));
  };

  onChangeTicketStatDiffFetchFilterServices = (values) => {
    this.props.dispatch(Actions.setTicketStatDiffFetchFilterServices(values))
      .then(() =>
        this.props.dispatch(Actions.fetchTicketStatDiff()));
  };

  onChangeTicketStatDiffFetchPeriodType = (value) => {
    this.props.dispatch(Actions.setTicketStatDiffFetchPeriodType(value))
      .then(() =>
        this.props.dispatch(Actions.fetchTicketStatDiff()));
  };

  onChangeTicketStatDiffThisFetchPeriodDates = (period, value) => {
    if (value === null) {
      return;
    }

    const [periodFrom, periodTo] = getPeriodDates(period, value);
    const [correspondingPeriodFrom, correspondingPeriodTo] =
      getCorrespondingPeriodDates(period, periodFrom, periodTo);

    this.props.dispatch(
      Actions.setTicketStatDiffThisFetchPeriodDates(periodFrom, periodTo))
      .then(() => this.props.dispatch(
        Actions.setTicketStatDiffThatFetchPeriodDates(correspondingPeriodFrom, correspondingPeriodTo)))
      .then(() => this.props.dispatch(
        Actions.fetchTicketStatDiff()));
  };

  onChangeTicketStatDiffThatFetchPeriodDates = (period, value) => {
    if (value === null) {
      return;
    }

    const [periodFrom, periodTo] = getPeriodDates(period, value);

    this.props.dispatch(
      Actions.setTicketStatDiffThatFetchPeriodDates(periodFrom, periodTo))
      .then(() => this.props.dispatch(
        Actions.fetchTicketStatDiff()));
  };

  onToggleTicketStatDistributionForStatType = (statType) => {
    if (statType === null) {
      return;
    }

    switch (statType) {
      case 'new_ticket_count':
        this.setState({
          ticketStatDistributionForNewTicketsVisible: !this.state.ticketStatDistributionForNewTicketsVisible
        });
        break;
      case 'completed_on_time_ticket_count':
        this.setState({
          ticketStatDistributionForCompletedOnTimeTicketsVisible: !this.state.ticketStatDistributionForCompletedOnTimeTicketsVisible
        });
        break;
      case 'completed_past_due_ticket_count':
        this.setState({
          ticketStatDistributionForCompletedPastDueTicketsVisible: !this.state.ticketStatDistributionForCompletedPastDueTicketsVisible
        });
        break;
      case 'cancelled_ticket_count':
        this.setState({
          ticketStatDistributionForCancelledTicketsVisible: !this.state.ticketStatDistributionForCancelledTicketsVisible
        });
        break;
    }
  };

  onChangeTicketStatDistributionFetchResolution = (value) => {
    this.props.dispatch(Actions.setTicketStatDistributionFetchResolution(value))
      .then(() =>
        this.props.dispatch(Actions.fetchTicketStatDistribution()));
  };

  onChangeTicketStatDistributionFetchDates = (period, value) => {
    if (value === null) {
      return;
    }

    this.props.dispatch(Actions.setTicketStatDistributionFetchDates(
      moment.utc(value[0]).startOf('day'),
      moment.utc(value[1]).endOf('day')))
      .then(() =>
        this.props.dispatch(Actions.fetchTicketStatDistribution()));
  };

  onChangeTicketsForStatTypeFetchParams = (statType, dateFrom, dateTo) => {
    this.props.dispatch(Actions.setTicketsForStatTypeFetchStatType(statType))
      .then(() =>
        this.props.dispatch(Actions.setTicketsForStatTypeFetchDates(dateFrom, dateTo)))
      .then(() =>
        this.props.dispatch(Actions.fetchTicketsForStatType()));
  };

  onChangeTicketsForStatTypeFetchStatType = (statType) => {
    this.props.dispatch(Actions.setTicketsForStatTypeFetchStatType(statType))
      .then(() =>
        this.props.dispatch(Actions.fetchTicketsForStatType()));
  };

  onFetchTicketsForStatType = () => {
    this.props.dispatch(Actions.fetchTicketsForStatType());
  };

  onDownloadTicketsForStatType = () => {
    this.props.dispatch(Actions.downloadTicketsForStatType());
  };

  onSetTicketsForStatTypeFetchDates = (dateFrom, dateTo) => {
    this.setState({
      ticketsForStatTypeFetchDateFrom: dateFrom,
      ticketsForStatTypeFetchDateTo: dateTo,
    });
  };

  onShowTicketsForStatTypeModal = () => {
    this.setState({
      ticketsForStatTypeVisible: true,
    });
  };

  onHideTicketsForStatTypeModal = () => {
    this.setState({
      ticketsForStatTypeVisible: false,
    });
  }

  render() {
    const {
      ticketStatDiffFilterVisible,
      ticketStatDistributionChartType,
      ticketStatDistributionForNewTicketsVisible,
      ticketStatDistributionForCompletedOnTimeTicketsVisible,
      ticketStatDistributionForCompletedPastDueTicketsVisible,
      ticketStatDistributionForCancelledTicketsVisible,
      ticketsForStatTypeVisible,
    } = this.state;
    const {
      ticketStatDiffFetchFilters,
      ticketStatDiffFetchPeriodType,
      ticketStatDiffFetchPeriodDates,
      ticketStatDiffLoading,
      ticketStatDiff,

      ticketStatDistributionFetchFilters,
      ticketStatDistributionFetchResolution,
      ticketStatDistributionFetchDates,
      ticketStatDistributionLoading,
      ticketStatDistribution,

      ticketsForStatTypeFetchStatType,
      ticketsForStatTypeFetchDateFrom,
      ticketsForStatTypeFetchDateTo,
      ticketsForStatTypeLoading,
      ticketsForStatType,
    } = this.props;

    const quarterReferenceDates = generateQuarterReferenceDates();

    return (
      <Row>
        <Drawer
          title="Фильтры"
          visible={ticketStatDiffFilterVisible}
          placement={"right"}
          width={540}
          closable={false}
          onClose={this.onToggleTicketStatDiffFilter}>
          <Row>
            <Col>
              <Form.Item
                label={'Замена'}>
                <Select
                  placeholder={'Замена'}
                  style={{
                    width: '100%',
                  }}
                  allowClear={true}
                  onChange={this.onChangeTicketStatDiffFetchFilterReplacementType}>
                  <Option value={'with'}>С заменой</Option>
                  <Option value={'without'}>Без замены</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                label={'Клиент'}>
                <SelectFetch
                  showSearch
                  placeholder={'Клиент'}
                  onChange={this.onChangeTicketStatDiffFetchFilterCustomer}
                  selected={ticketStatDiffFetchFilters.customer}
                  url={"/customers/search/?"}/>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                label={'Объект'}>
                <SelectFetch
                  showSearch
                  placeholder={'Объект'}
                  onChange={this.onChangeTicketStatDiffFetchFilterEntity}
                  selected={ticketStatDiffFetchFilters.entity}
                  url={
                    ticketStatDiffFetchFilters.customer ?
                      `/entities/search/?customer_id=${ticketStatDiffFetchFilters.customer.id}` :
                      `/entities/search`
                  }/>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                label={'Разделы'}>
                <SelectMultiFetch
                  showSearch
                  placeholder={'Разделы'}
                  selected={ticketStatDiffFetchFilters.services}
                  onChange={this.onChangeTicketStatDiffFetchFilterServices}
                  url={`/services/search/?`}/>
              </Form.Item>
            </Col>
          </Row>
        </Drawer>

        <Card
          title={'Заявки'}>
          <Row
            type={'flex'}
            justify={'space-between'}
            style={{
              marginBottom: '8px',
            }}>
            <Col>
              <Radio.Group
                value={ticketStatDiffFetchPeriodType}
                optionType={'button'}
                buttonStyle={'solid'}
                onChange={(event) => this.onChangeTicketStatDiffFetchPeriodType(event.target.value)}>
                <Radio.Button value="day">День</Radio.Button>
                <Radio.Button value="week">Неделя</Radio.Button>
                <Radio.Button value="month">Месяц</Radio.Button>
                <Radio.Button value="quarter">Квартал</Radio.Button>
                <Radio.Button value="custom">Период</Radio.Button>
              </Radio.Group>

              <Button
                key="ticket-count-diff-filter-button"
                type={
                  !!ticketStatDiffFetchFilters.replacementType ||
                  !!ticketStatDiffFetchFilters.customer ||
                  !!ticketStatDiffFetchFilters.entity ||
                  !!ticketStatDiffFetchFilters.services?.length ?
                    'primary' :
                    'ghost'
                }
                icon={'filter'}
                onClick={this.onToggleTicketStatDiffFilter}
                style={{
                  marginLeft: 8,
                }}/>
            </Col>
          </Row>
          <Row
            style={{
              marginBottom: '8px',
            }}>
            <Col
              span={24}>
              {
                ticketStatDiffFetchPeriodType === 'day' ?
                  <>
                    <Row
                      type={'flex'}
                      align={'middle'}
                      style={{
                        marginBottom: '8px',
                      }}>
                      <Col
                        span={2}>
                        <Typography>
                          {'Текущий день'}
                        </Typography>
                      </Col>
                      <Col>
                        <DatePicker
                          value={ticketStatDiffFetchPeriodDates.thisPeriodFrom}
                          onChange={(date) => this.onChangeTicketStatDiffThisFetchPeriodDates('day', date)}/>
                      </Col>
                    </Row>
                    <Row
                      type={'flex'}
                      align={'middle'}>
                      <Col
                        span={2}>
                        <Typography>
                          {'Сравнить с'}
                        </Typography>
                      </Col>
                      <Col>
                        <DatePicker
                          disabled={true}
                          value={ticketStatDiffFetchPeriodDates.thatPeriodFrom}
                          onChange={(date) => this.onChangeTicketStatDiffThatFetchPeriodDates('day', date)}/>
                      </Col>
                    </Row>
                  </> :
                  ticketStatDiffFetchPeriodType === 'week' ?
                    <>
                      <Row
                        type={'flex'}
                        align={'middle'}
                        style={{
                          marginBottom: '8px',
                        }}>
                        <Col
                          span={2}>
                          <Typography>
                            {'Текущая неделя'}
                          </Typography>
                        </Col>
                        <Col>
                          <WeekPicker
                            value={ticketStatDiffFetchPeriodDates.thisPeriodFrom}
                            onChange={(date) => this.onChangeTicketStatDiffThisFetchPeriodDates('week', date)}/>
                        </Col>
                      </Row>
                      <Row
                        type={'flex'}
                        align={'middle'}>
                        <Col
                          span={2}>
                          <Typography>
                            {'Сравнить с'}
                          </Typography>
                        </Col>
                        <Col>
                          <WeekPicker
                            disabled={true}
                            value={ticketStatDiffFetchPeriodDates.thatPeriodFrom}
                            onChange={(date) => this.onChangeTicketStatDiffThatFetchPeriodDates('week', date)}/>
                        </Col>
                      </Row>
                    </> :
                    ticketStatDiffFetchPeriodType === 'month' ?
                      <>
                        <Row
                          type={'flex'}
                          align={'middle'}
                          style={{
                            marginBottom: '8px',
                          }}>
                          <Col
                            span={2}>
                            <Typography>
                              {'Текущий месяц'}
                            </Typography>
                          </Col>
                          <Col>
                            <MonthPicker
                              value={ticketStatDiffFetchPeriodDates.thisPeriodFrom}
                              onChange={(date) => this.onChangeTicketStatDiffThisFetchPeriodDates('month', date)}/>
                          </Col>
                        </Row>
                        <Row
                          type={'flex'}
                          align={'middle'}>
                          <Col
                            span={2}>
                            <Typography>
                              {'Сравнить с'}
                            </Typography>
                          </Col>
                          <Col>
                            <MonthPicker
                              disabled={true}
                              value={ticketStatDiffFetchPeriodDates.thatPeriodFrom}
                              onChange={(date) => this.onChangeTicketStatDiffThatFetchPeriodDates('month', date)}/>
                          </Col>
                        </Row>
                      </> :
                      ticketStatDiffFetchPeriodType === 'quarter' ?
                        <>
                          <Row
                            type={'flex'}
                            align={'middle'}
                            style={{
                              marginBottom: '8px',
                            }}>
                            <Col
                              span={2}>
                              <Typography>
                                {'Текущий квартал'}
                              </Typography>
                            </Col>
                            <Col>
                              <Select
                                style={{
                                  width: '205px',
                                }}
                                value={ticketStatDiffFetchPeriodDates.thisPeriodFrom.format('YYYY.MM.DD')}
                                onChange={(value) => this.onChangeTicketStatDiffThisFetchPeriodDates('quarter', value)}>
                                {quarterReferenceDates.map((date) => (
                                  <Option
                                    key={`${date.year()}Q${date.quarter()}`}
                                    value={date.format('YYYY.MM.DD')}>
                                    {`${date.year()}-Кв.${date.quarter()}`}
                                  </Option>
                                ))}
                              </Select>
                            </Col>
                          </Row>
                          <Row
                            type={'flex'}
                            align={'middle'}>
                            <Col span={2}>
                              <Typography>
                                {'Сравнить с'}
                              </Typography>
                            </Col>
                            <Col>
                              <Select
                                disabled={true}
                                style={{
                                  width: '205px',
                                }}
                                value={ticketStatDiffFetchPeriodDates.thatPeriodFrom.format('YYYY.MM.DD')}
                                onChange={(value) => this.onChangeTicketStatDiffThatFetchPeriodDates('quarter', value)}>
                                {quarterReferenceDates.map((date) => (
                                  <Option
                                    key={`${date.year()}Q${date.quarter()}`}
                                    value={date.format('YYYY.MM.DD')}>
                                    {`${date.year()}-Кв.${date.quarter()}`}
                                  </Option>
                                ))}
                              </Select>
                            </Col>
                          </Row>
                        </> :
                        ticketStatDiffFetchPeriodType === 'custom' ?
                          <>
                            <Row
                              type={'flex'}
                              align={'middle'}
                              style={{
                                marginBottom: '8px',
                              }}>
                              <Col
                                span={2}>
                                <Typography>
                                  {'Текущий период'}
                                </Typography>
                              </Col>
                              <Col>
                                <RangePicker
                                  value={[
                                    ticketStatDiffFetchPeriodDates.thisPeriodFrom,
                                    ticketStatDiffFetchPeriodDates.thisPeriodTo
                                  ]}
                                  onChange={(dates) => this.onChangeTicketStatDiffThisFetchPeriodDates('custom', dates)}/>
                              </Col>
                            </Row>
                            <Row
                              type={'flex'}
                              align={'middle'}>
                              <Col
                                span={2}>
                                <Typography>
                                  {'Сравнить с'}
                                </Typography>
                              </Col>
                              <Col>
                                <RangePicker
                                  disabled={true}
                                  value={[
                                    ticketStatDiffFetchPeriodDates.thatPeriodFrom,
                                    ticketStatDiffFetchPeriodDates.thatPeriodTo
                                  ]}
                                  onChange={(dates) => this.onChangeTicketStatDiffThatFetchPeriodDates('custom', dates)}/>
                              </Col>
                            </Row>
                          </> :
                          null
              }
            </Col>
          </Row>

          {ticketStatDiffLoading ?
            <LoadingSpin/> :
            <Row gutter={16}>
              <Col span={6}>
                <Popover
                  trigger={'click'}
                  placement={'bottom'}
                  title={
                    <Row
                      type={'flex'}
                      justify={'end'}>
                      <Button
                        key="excelButton"
                        type={'dashed'}
                        size={'small'}
                        onClick={() =>
                          this.onDownloadTicketsForStatType(
                            'new',
                            ticketStatDiffFetchPeriodDates.thisPeriodFrom,
                            ticketStatDiffFetchPeriodDates.thisPeriodTo)
                        }>
                        <Icon
                          type={'file-excel'}
                          style={{
                            color: 'green'
                          }}/>
                        {'Выгрузить'}
                      </Button>
                    </Row>
                  }
                  content={
                    <Row
                      style={{
                        width: 600,
                      }}>
                      <Col>
                        {
                          renderTicketsForStatType(
                            ticketsForStatTypeLoading,
                            ticketsForStatType)
                        }
                      </Col>
                    </Row>
                  }>
                  {
                    renderStatDiffCard(
                      'Новые',
                      ticketStatDiff.new_ticket_count?.this_period,
                      ticketStatDiff.new_ticket_count?.that_period,
                      true,
                      () =>
                        this.onChangeTicketsForStatTypeFetchParams(
                          'new',
                          ticketStatDiffFetchPeriodDates.thisPeriodFrom,
                          ticketStatDiffFetchPeriodDates.thisPeriodTo))
                  }
                </Popover>
              </Col>
              <Col span={6}>
                <Popover
                  trigger={'click'}
                  placement={'bottom'}
                  title={
                    <Row
                      type={'flex'}
                      justify={'end'}>
                      <Button
                        key="excelButton"
                        type={'dashed'}
                        size={'small'}
                        onClick={() =>
                          this.onDownloadTicketsForStatType(
                            'completed_on_time',
                            ticketStatDiffFetchPeriodDates.thisPeriodFrom,
                            ticketStatDiffFetchPeriodDates.thisPeriodTo)
                        }>
                        <Icon
                          type={'file-excel'}
                          style={{
                            color: 'green'
                          }}/>
                        {'Выгрузить'}
                      </Button>
                    </Row>
                  }
                  content={
                    <Row
                      style={{
                        width: 600,
                      }}>
                      <Col>
                        {renderTicketsForStatType(
                          ticketsForStatTypeLoading,
                          ticketsForStatType)
                        }
                      </Col>
                    </Row>
                  }>
                  {
                    renderStatDiffCard(
                      'Выполненные в срок',
                      ticketStatDiff.completed_on_time_ticket_count?.this_period,
                      ticketStatDiff.completed_on_time_ticket_count?.that_period,
                      true,
                      () =>
                        this.onChangeTicketsForStatTypeFetchParams(
                          'completed_on_time',
                          ticketStatDiffFetchPeriodDates.thisPeriodFrom,
                          ticketStatDiffFetchPeriodDates.thisPeriodTo))
                  }
                </Popover>
              </Col>
              <Col span={6}>
                <Popover
                  trigger={'click'}
                  placement={'bottom'}
                  title={
                    <Row
                      type={'flex'}
                      justify={'end'}>
                      <Button
                        key="excelButton"
                        type={'dashed'}
                        size={'small'}
                        onClick={() =>
                          this.onDownloadTicketsForStatType(
                            'completed_past_due',
                            ticketStatDiffFetchPeriodDates.thisPeriodFrom,
                            ticketStatDiffFetchPeriodDates.thisPeriodTo)
                        }>
                        <Icon
                          type={'file-excel'}
                          style={{
                            color: 'green'
                          }}/>
                        {'Выгрузить'}
                      </Button>
                    </Row>
                  }
                  content={
                    <Row
                      style={{
                        width: 600,
                      }}>
                      <Col>
                        {
                          renderTicketsForStatType(
                            ticketsForStatTypeLoading,
                            ticketsForStatType)
                        }
                      </Col>
                    </Row>
                  }>
                  {
                    renderStatDiffCard(
                      'Выполненные не в срок',
                      ticketStatDiff.completed_past_due_ticket_count?.this_period,
                      ticketStatDiff.completed_past_due_ticket_count?.that_period,
                      false,
                      () =>
                        this.onChangeTicketsForStatTypeFetchParams(
                          'completed_past_due',
                          ticketStatDiffFetchPeriodDates.thisPeriodFrom,
                          ticketStatDiffFetchPeriodDates.thisPeriodTo))
                  }
                </Popover>
              </Col>
              <Col span={6}>
                <Popover
                  trigger={'click'}
                  placement={'bottom'}
                  title={
                    <Row
                      type={'flex'}
                      justify={'end'}>
                      <Button
                        key="excelButton"
                        type={'dashed'}
                        size={'small'}
                        onClick={() =>
                          this.onDownloadTicketsForStatType(
                            'cancelled',
                            ticketStatDiffFetchPeriodDates.thisPeriodFrom,
                            ticketStatDiffFetchPeriodDates.thisPeriodTo)
                        }>
                        <Icon
                          type={'file-excel'}
                          style={{
                            color: 'green'
                          }}/>
                        {'Выгрузить'}
                      </Button>
                    </Row>
                  }
                  content={
                    <Row
                      style={{
                        width: 600,
                      }}>
                      <Col>
                        {
                          renderTicketsForStatType(
                            ticketsForStatTypeLoading,
                            ticketsForStatType)
                        }
                      </Col>
                    </Row>
                  }>
                  {
                    renderStatDiffCard(
                      'Отмененные',
                      ticketStatDiff.cancelled_ticket_count?.this_period,
                      ticketStatDiff.cancelled_ticket_count?.that_period,
                      true,
                      () =>
                        this.onChangeTicketsForStatTypeFetchParams(
                          'cancelled',
                          ticketStatDiffFetchPeriodDates.thisPeriodFrom,
                          ticketStatDiffFetchPeriodDates.thisPeriodTo))
                  }
                </Popover>
              </Col>
            </Row>
          }
        </Card>

        <Drawer
          title="Фильтры"
          visible={ticketStatDiffFilterVisible}
          placement={"right"}
          width={540}
          closable={false}
          onClose={this.onToggleTicketStatDiffFilter}>
          <Row>
            <Col>
              <Form.Item
                label={'Замена'}>
                <Select
                  placeholder={'Замена'}
                  style={{
                    width: '100%',
                  }}
                  allowClear={true}
                  onChange={this.onChangeTicketStatDiffFetchFilterReplacementType}>
                  <Option value={'with'}>С заменой</Option>
                  <Option value={'without'}>Без замены</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                label={'Клиент'}>
                <SelectFetch
                  showSearch
                  placeholder={'Клиент'}
                  onChange={this.onChangeTicketStatDiffFetchFilterCustomer}
                  selected={ticketStatDiffFetchFilters.customer}
                  url={"/customers/search/?"}/>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                label={'Объект'}>
                <SelectFetch
                  showSearch
                  placeholder={'Объект'}
                  onChange={this.onChangeTicketStatDiffFetchFilterEntity}
                  selected={ticketStatDiffFetchFilters.entity}
                  url={
                    ticketStatDiffFetchFilters.customer ?
                      `/entities/search/?customer_id=${ticketStatDiffFetchFilters.customer.id}` :
                      `/entities/search`
                  }/>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                label={'Разделы'}>
                <SelectMultiFetch
                  showSearch
                  placeholder={'Разделы'}
                  selected={ticketStatDiffFetchFilters.services}
                  onChange={this.onChangeTicketStatDiffFetchFilterServices}
                  url={`/services/search/?`}/>
              </Form.Item>
            </Col>
          </Row>
        </Drawer>

        <Modal
          visible={ticketsForStatTypeVisible}
          width={'800px'}
          footer={[
            <Button
              key="excelButton"
              onClick={
                () => {
                  this.onDownloadTicketsForStatType(
                    'new',
                    ticketStatDiffFetchPeriodDates.thisPeriodFrom,
                    ticketStatDiffFetchPeriodDates.thisPeriodTo);
                }
              }>
              <Icon
                type={'file-excel'}
                style={{
                  color: 'green'
                }}/>
              {'Выгрузить'}
            </Button>
          ]}
          onCancel={this.onHideTicketsForStatTypeModal}>
          <Tabs
            activeKey={ticketsForStatTypeFetchStatType}
            tabPosition={'left'}
            onChange={(key) => this.onChangeTicketsForStatTypeFetchStatType(key)}>
            <TabPane
              key={'new'}
              tab={'Новые'}
              disabled={!ticketStatDistributionForNewTicketsVisible}>
              {
                renderTicketsForStatType(
                  ticketsForStatTypeLoading,
                  ticketsForStatType)
              }
            </TabPane>
            <TabPane
              key={'completed_on_time'}
              tab={'Выполненные в срок'}
              disabled={!ticketStatDistributionForCompletedOnTimeTicketsVisible}>
              {
                renderTicketsForStatType(
                  ticketsForStatTypeLoading,
                  ticketsForStatType)
              }
            </TabPane>
            <TabPane
              key={'completed_past_due'}
              tab={'Выполненные не в срок'}
              disabled={!ticketStatDistributionForCompletedPastDueTicketsVisible}>
              {
                renderTicketsForStatType(
                  ticketsForStatTypeLoading,
                  ticketsForStatType)
              }
            </TabPane>
            <TabPane
              key={'cancelled'}
              tab={'Отмененные'}
              disabled={!ticketStatDistributionForCancelledTicketsVisible}>
              {
                renderTicketsForStatType(
                  ticketsForStatTypeLoading,
                  ticketsForStatType)
              }
            </TabPane>
          </Tabs>
        </Modal>

        <Card
          title={'Динамика заявок'}>
          <Row
            type={'flex'}
            justify={'space-between'}
            style={{
              marginBottom: '8px',
            }}>
            <Col>
              <RangePicker
                value={[
                  ticketStatDistributionFetchDates.analyzeFrom,
                  ticketStatDistributionFetchDates.analyzeTo
                ]}
                ranges={{
                  'Сегодня': [
                    moment.utc().startOf('day'),
                    moment.utc().endOf('day')
                  ],
                  'Текущая неделя': [
                    moment.utc().startOf('week'),
                    moment.utc().endOf('day'),
                  ],
                  'Текущий месяц': [
                    moment.utc().startOf('month'),
                    moment.utc().endOf('day'),
                  ],
                }}
                onChange={(dates) => this.onChangeTicketStatDistributionFetchDates('day', dates)}/>


              <Button
                key="ticket-count-diff-filter-button"
                type={
                  !!ticketStatDiffFetchFilters.replacementType ||
                  !!ticketStatDiffFetchFilters.customer ||
                  !!ticketStatDiffFetchFilters.entity ||
                  !!ticketStatDiffFetchFilters.services?.length ?
                    'primary' :
                    'ghost'
                }
                icon={'filter'}
                onClick={this.onToggleTicketStatDiffFilter}
                style={{
                  marginLeft: 8,
                }}/>
            </Col>
          </Row>
          <Row
            type={'flex'}
            justify={'start'}
            style={{
              marginBottom: '8px',
            }}>
            <Col
              style={{
                marginRight: '16px',
              }}>
              <Typography>
                {'Группировать по'}
              </Typography>
            </Col>
            <Col>
              <Radio.Group
                value={ticketStatDistributionFetchResolution}
                onChange={(event) => this.onChangeTicketStatDistributionFetchResolution(event.target.value)}>
                <Radio value={'day'}>{'Дням'}</Radio>
                <Radio value={'week'}>{'Неделям'}</Radio>
                <Radio value={'month'}>{'Месяцам'}</Radio>
                <Radio value={'quarter'}>{'Кварталам'}</Radio>
              </Radio.Group>
            </Col>
          </Row>

          {
            ticketStatDistributionLoading ?
              <LoadingSpin/> :
              <>
                <Row>
                  <Col span={18}>
                    <ResponsiveContainer
                      height={400}>
                      <BarChart
                        data={ticketStatDistribution}
                        onClick={
                          (chartState) => {
                            if (!chartState) {
                              return;
                            }

                            const activePayload = chartState.activePayload;
                            if (!activePayload) {
                              return;
                            }

                            const chartItem = activePayload.find((it) => it !== undefined)
                            if (!chartItem) {
                              return;
                            }

                            const statType =
                              chartItem.dataKey === 'new_ticket_count' ? 'new' :
                                chartItem.dataKey === 'completed_on_time_ticket_count' ? 'completed_on_time' :
                                  chartItem.dataKey === 'completed_past_due_ticket_count' ? 'completed_past_due' :
                                    chartItem.dataKey === 'cancelled_ticket_count' ? 'cancelled' :
                                      null;
                            if (!statType) {
                              return;
                            }

                            this.onChangeTicketsForStatTypeFetchParams(
                              statType,
                              moment.utc(chartItem.payload.interval_from),
                              moment.utc(chartItem.payload.interval_to));

                            this.onShowTicketsForStatTypeModal();
                          }
                        }>
                        <XAxis
                          dataKey={'interval_from'}
                          tickFormatter={(it) => moment.utc(it).format('LL')}/>
                        <YAxis
                          allowDecimals={false}/>
                        <Tooltip
                          labelFormatter={
                            (it, activePayload) => {
                              if (!activePayload) {
                                return;
                              }

                              const chartItem = activePayload.find((it) => it !== undefined)
                              if (!chartItem) {
                                return;
                              }

                              const intervalFrom = chartItem.payload.interval_from;
                              const intervalTo = chartItem.payload.interval_to;

                              switch (ticketStatDistributionFetchResolution) {
                                case 'day':
                                  return moment.utc(it).format('LL');
                                case 'week':
                                case 'month':
                                case 'quarter':
                                  return `${moment.utc(intervalFrom).format('LL')} .. ${moment.utc(intervalTo).format('LL')}`;
                                default:
                                  return moment.utc(it).format('LL');
                              }
                            }
                          }/>
                        <Legend
                          layout={'vertical'}
                          verticalAlign={'top'}
                          align={'right'}
                          iconType={'square'}
                          content={
                            ({payload}) => (
                              <Row
                                style={{
                                  marginLeft: '16px',
                                }}>
                                <Col>
                                  <Row>
                                    <Col>
                                      <Checkbox
                                        style={{
                                          color: statColorForNewTickets,
                                        }}
                                        checked={ticketStatDistributionForNewTicketsVisible}
                                        onClick={() => this.onToggleTicketStatDistributionForStatType('new_ticket_count')}>
                                        {'Новые заявки'}
                                      </Checkbox>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <Checkbox
                                        style={{
                                          color: statColorForCompletedOnTimeTickets,
                                        }}
                                        checked={ticketStatDistributionForCompletedOnTimeTicketsVisible}
                                        onClick={() => this.onToggleTicketStatDistributionForStatType('completed_on_time_ticket_count')}>
                                        {'Выполненные в срок'}
                                      </Checkbox>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <Checkbox
                                        style={{
                                          color: statColorForCompletedPastDueTickets,
                                        }}
                                        checked={ticketStatDistributionForCompletedPastDueTicketsVisible}
                                        onClick={() => this.onToggleTicketStatDistributionForStatType('completed_past_due_ticket_count')}>
                                        {'Выполненные не в срок'}
                                      </Checkbox>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <Checkbox
                                        style={{
                                          color: statColorForCancelledTickets,
                                        }}
                                        checked={ticketStatDistributionForCancelledTicketsVisible}
                                        onClick={() => this.onToggleTicketStatDistributionForStatType('cancelled_ticket_count')}>
                                        {'Отмененные'}
                                      </Checkbox>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            )
                          }/>
                        {
                          ticketStatDistributionForNewTicketsVisible ?
                            <Bar
                              name={'Новые заявки'}
                              dataKey={'new_ticket_count'}
                              fill={statColorForNewTickets}/> :
                            null
                        }
                        {
                          ticketStatDistributionForCompletedOnTimeTicketsVisible ?
                            <Bar
                              name={'Выполненные в срок'}
                              dataKey={'completed_on_time_ticket_count'}
                              fill={statColorForCompletedOnTimeTickets}/> :
                            null
                        }
                        {
                          ticketStatDistributionForCompletedPastDueTicketsVisible ?
                            <Bar
                              name={'Выполненные не в срок'}
                              dataKey={'completed_past_due_ticket_count'}
                              fill={statColorForCompletedPastDueTickets}/> :
                            null
                        }
                        {
                          ticketStatDistributionForCancelledTicketsVisible ?
                            <Bar
                              name={'Отмененные'}
                              dataKey={'cancelled_ticket_count'}
                              fill={statColorForCancelledTickets}/> :
                            null
                        }
                      </BarChart>
                    </ResponsiveContainer>
                  </Col>
                </Row>
                <Row>
                  <Col span={18}>
                    <ResponsiveContainer
                      height={400}>
                      <LineChart
                        data={ticketStatDistribution}
                        onClick={
                          (chartState) => {
                            if (!chartState) {
                              return;
                            }

                            const activePayload = chartState.activePayload;
                            if (!activePayload) {
                              return;
                            }

                            const chartItem = activePayload.find((it) => it !== undefined)
                            if (!chartItem) {
                              return;
                            }

                            const statType =
                              chartItem.dataKey === 'new_ticket_count' ? 'new' :
                                chartItem.dataKey === 'completed_on_time_ticket_count' ? 'completed_on_time' :
                                  chartItem.dataKey === 'completed_past_due_ticket_count' ? 'completed_past_due' :
                                    chartItem.dataKey === 'cancelled_ticket_count' ? 'cancelled' :
                                      null;
                            if (!statType) {
                              return;
                            }

                            this.onChangeTicketsForStatTypeFetchParams(
                              statType,
                              moment.utc(chartItem.payload.interval_from),
                              moment.utc(chartItem.payload.interval_to));

                            this.onShowTicketsForStatTypeModal();
                          }
                        }>
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis
                          dataKey={'interval_from'}
                          tickFormatter={(it) => moment.utc(it).format('LL')}/>
                        <YAxis
                          allowDecimals={false}/>
                        <Tooltip
                          labelFormatter={
                            (it, activePayload) => {
                              if (!activePayload) {
                                return;
                              }

                              const chartItem = activePayload.find((it) => it !== undefined)
                              if (!chartItem) {
                                return;
                              }

                              const intervalFrom = chartItem.payload.interval_from;
                              const intervalTo = chartItem.payload.interval_to;

                              switch (ticketStatDistributionFetchResolution) {
                                case 'day':
                                  return moment.utc(it).format('LL');
                                case 'week':
                                case 'month':
                                case 'quarter':
                                  return `${moment.utc(intervalFrom).format('LL')} .. ${moment.utc(intervalTo).format('LL')}`;
                                default:
                                  return moment.utc(it).format('LL');
                              }
                            }
                          }/>
                        <Legend
                          layout={'vertical'}
                          verticalAlign={'top'}
                          align={'right'}
                          iconType={'square'}
                          content={
                            ({payload}) => (
                              <Row
                                style={{
                                  marginLeft: '16px',
                                }}>
                                <Col>
                                  <Row>
                                    <Col>
                                      <Checkbox
                                        style={{
                                          color: statColorForNewTickets,
                                        }}
                                        checked={ticketStatDistributionForNewTicketsVisible}
                                        onClick={() => this.onToggleTicketStatDistributionForStatType('new_ticket_count')}>
                                        {'Новые заявки'}
                                      </Checkbox>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <Checkbox
                                        style={{
                                          color: statColorForCompletedOnTimeTickets,
                                        }}
                                        checked={ticketStatDistributionForCompletedOnTimeTicketsVisible}
                                        onClick={() => this.onToggleTicketStatDistributionForStatType('completed_on_time_ticket_count')}>
                                        {'Выполненные в срок'}
                                      </Checkbox>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <Checkbox
                                        style={{
                                          color: statColorForCompletedPastDueTickets,
                                        }}
                                        checked={ticketStatDistributionForCompletedPastDueTicketsVisible}
                                        onClick={() => this.onToggleTicketStatDistributionForStatType('completed_past_due_ticket_count')}>
                                        {'Выполненные не в срок'}
                                      </Checkbox>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <Checkbox
                                        style={{
                                          color: statColorForCancelledTickets,
                                        }}
                                        checked={ticketStatDistributionForCancelledTicketsVisible}
                                        onClick={() => this.onToggleTicketStatDistributionForStatType('cancelled_ticket_count')}>
                                        {'Отмененные'}
                                      </Checkbox>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            )
                          }/>
                        {
                          ticketStatDistributionForNewTicketsVisible ?
                            <Line
                              name={'Новые заявки'}
                              type="monotone"
                              dataKey={'new_ticket_count'}
                              stroke={statColorForNewTickets}/> :
                            null
                        }
                        {
                          ticketStatDistributionForCompletedOnTimeTicketsVisible ?
                            <Line
                              name={'Выполненные в срок'}
                              type="monotone"
                              dataKey={'completed_on_time_ticket_count'}
                              stroke={statColorForCompletedOnTimeTickets}/> :
                            null
                        }
                        {
                          ticketStatDistributionForCompletedPastDueTicketsVisible ?
                            <Line
                              name={'Выполненные не в срок'}
                              type="monotone"
                              dataKey={'completed_past_due_ticket_count'}
                              stroke={statColorForCompletedPastDueTickets}/> :
                            null
                        }
                        {
                          ticketStatDistributionForCancelledTicketsVisible ?
                            <Line
                              name={'Отмененные'}
                              type="monotone"
                              dataKey={'cancelled_ticket_count'}
                              stroke={statColorForCancelledTickets}/> :
                            null
                        }
                      </LineChart>
                    </ResponsiveContainer>
                  </Col>
                </Row>
              </>
          }
        </Card>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  ticketStatDiffFetchPeriodType: state.analytics.ticketStatDiffFetchPeriodType,
  ticketStatDiffFetchPeriodDates: state.analytics.ticketStatDiffFetchPeriodDates,
  ticketStatDiffFetchFilters: state.analytics.ticketStatDiffFetchFilters,
  ticketStatDiffLoading: state.analytics.ticketStatDiffLoading,
  ticketStatDiff: state.analytics.ticketStatDiff,

  ticketStatDistributionFetchResolution: state.analytics.ticketStatDistributionFetchResolution,
  ticketStatDistributionFetchDates: state.analytics.ticketStatDistributionFetchDates,
  ticketStatDistributionFetchFilters: state.analytics.ticketStatDistributionFetchFilters,
  ticketStatDistributionLoading: state.analytics.ticketStatDistributionLoading,
  ticketStatDistribution: state.analytics.ticketStatDistribution,

  ticketsForStatTypeFetchStatType: state.analytics.ticketsForStatTypeFetchStatType,
  ticketsForStatTypeFetchDateFrom: state.analytics.ticketsForStatTypeFetchDateFrom,
  ticketsForStatTypeFetchDateTo: state.analytics.ticketsForStatTypeFetchDateTo,
  ticketsForStatTypeLoading: state.analytics.ticketsForStatTypeLoading,
  ticketsForStatType: state.analytics.ticketsForStatType,
});

export default compose(
  withRouter,
  connect(mapStateToProps)
)(Analytic);
