// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, List, Input, Divider, Tooltip, Button } from "antd";

class ExtCodes extends Component {
  render() {
    const {
      extCodes,
      extCode,
      isAddingExtCode,
      onAddExtCode,
      onDeleteExtCode,
      onCloseAddingExtCode,
      onChangeExtCode
    } = this.props;

    return (
      <React.Fragment>
        {isAddingExtCode ? (
          <Row gutter={10} type="flex" justify="center">
            <Col span={24}>
              <Input value={extCode} onChange={onChangeExtCode} />
            </Col>
            <Col span={24} style={{ paddingTop: "10px" }}>
              <Row gutter={10} type="flex" justify="center">
                <Button type="danger" ghost onClick={onCloseAddingExtCode}>
                  Отменить
                </Button>

                <Button
                  disabled={extCode ? false : true}
                  type="primary"
                  ghost
                  style={{ marginLeft: "10px" }}
                  onClick={onAddExtCode}
                >
                  Сохранить
                </Button>
              </Row>
            </Col>
          </Row>
        ) : null}
        <List
          itemLayout="horizontal"
          dataSource={extCodes}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Button
                  type="dashed"
                  shape="circle"
                  icon="close"
                  onClick={onDeleteExtCode.bind(this, item.id)}
                />,
              ]}
            >
              <List.Item.Meta title={item.code} />
            </List.Item>
          )}
        />
      </React.Fragment>
    );
  }
}
ExtCodes.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object),
};

export default ExtCodes;
