// @flow
import React, { Component, Fragment } from "react";
import { Row, Col, Button, Card, Statistic, Icon } from "antd";
import Pie from "./Pie";
import StatisticCards from "./StatisticCards";

import moment from "moment";
import "moment/locale/ru";

class Tasks extends Component {
  render() {
    const {
      currentUser,
      fetchParams,
      isStatisticLoading,
      projectsStatistic,
      projectSeries,
    } = this.props;

    return (
      <React.Fragment>
        <StatisticCards data={projectsStatistic} />
        <Card title="Проекты" loading={isStatisticLoading}>
          <Pie data={projectsStatistic} />
        </Card>
      </React.Fragment>
    );
  }
}

export default Tasks;
