// @flow
import React, { Component, Fragment } from "react";
import { Row, Col, Button, Card, Statistic, Icon } from "antd";

import {
  Chart,
  Interval,
  Axis,
  Coordinate,
  Label,
  Legend,
  View,
  Guide,
  Shape,
  Facet,
  Util,
} from "bizcharts";

import moment from "moment";
import "moment/locale/ru";

class InTime extends Component {
  render() {
    const { isLoading, data } = this.props;

    let result = [];
    if (!isLoading) {
      if (data.tickets.length > 0) {
        result.push(
          { name: "Заявки", type: "Просрочены", value: data.tickets[0].overdue },
          { name: "Заявки", type: "Вовремя", value: data.tickets[0].intime },
          { name: "Заявки", type: "В работе", value: data.tickets[0].progress }
        );
      }

      if (data.entity_tasks.length > 0) {
        result.push(
          {
            name: "Регламент",
            type: "Просрочены",
            value: data.entity_tasks[0].overdue,
          },
          {
            name: "Регламент",
            type: "Вовремя",
            value: data.entity_tasks[0].intime,
          },
          {
            name: "Регламент",
            type: "В работе",
            value: data.entity_tasks[0].progress,
          }
        );
      }

      if (data.tasks.length > 0) {
        result.push(
          { name: "Задачи", type: "Просрочены", value: data.tasks[0].overdue},
          { name: "Задачи", type: "Вовремя", value: data.tasks[0].intime },
          { name: "Задачи", type: "В работе", value: data.tasks[0].progress }
        );
      }

      if (data.project_stages.length > 0) {
        result.push(
          { name: "Этапы проектов", type: "Просрочены", value: data.project_stages[0].overdue},
          { name: "Этапы проектов", type: "Вовремя", value: data.project_stages[0].intime },
          { name: "Этапы проектов", type: "В работе", value: data.project_stages[0].progress }
        );
      }

      if (data.order_tasks.length > 0) {
        result.push(
          { name: "Задачи (заказы)", type: "Просрочены", value: data.order_tasks[0].overdue},
          { name: "Задачи (заказы)", type: "Вовремя", value: data.order_tasks[0].intime },
          { name: "Задачи (заказы)", type: "В работе", value: data.order_tasks[0].progress }
        );
      }
    }

    

    if (result.length == 0) {
      result.push(
        { type: "Заявки", status: "В работе", value: 0 },
        { type: "Регламент", status: "В работе", value: 0 },
        { type: "Задачи", status: "В работе", value: 0 },
        { type: "Этапы проектов", status: "В работе", value: 0 }
      );
    }

    return (
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Card loading={isLoading}>
            {isLoading ? null : (
              <Chart height={400} data={result} autoFit>
                <Coordinate transpose />
                <Axis
                  name="name"
                  label={{
                    offset: 12,
                  }}
                />
                <Interval
                  adjust={[{ type: "stack" }]}
                  position="name*value"
                  color={"type"}
                  label={[
                    "value",
                    {
                      position: "middle",
                      offset: 0,
                      style: { fill: "#fff" },
                      layout: { type: "limit-in-shape" },
                    },
                  ]}
                />
              </Chart>
            )}
          </Card>
        </Col>
      </Row>
    );
  }
}

export default InTime;
