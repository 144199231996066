import React, { Component } from "react";
import { Row, Col, Form, Modal, Button, Input } from "antd";
import { connect } from "react-redux";
import Actions from "../../../actions/todoTemplates";

const FormItem = Form.Item;

class FormTodoItem extends React.Component {
  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.dispatch(Actions.onCreateTodoItem());
    });
  };

  onCancel = (e) => {
    this.props.dispatch(Actions.onCancelTodoItem());
  };

  onChangeTodoItemName = (e) => {
    this.props.dispatch(Actions.onChangeTodoItemName(e.target.value));
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { isTodoItemVisible, todoItem } = this.props;
    return (
      <Modal
        destroyOnClose
        title="Новый элемент"
        visible={isTodoItemVisible}
        onOk={this.onSubmit}
        onCancel={this.onCancel}
      >
        <Form layout={"vertical"}>
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <FormItem label={"Наименование"}>
                {getFieldDecorator("todoItemName", {
                  onChange: this.onChangeTodoItemName,
                  initialValue: todoItem.name ? todoItem.name : null,
                  rules: [
                    {
                      required: true,
                      message: "Введите наименование!",
                    },
                  ],
                })(<Input />)}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  isTodoItemVisible: state.todoTemplates.isTodoItemVisible,
  errors: state.todoTemplates.errors,
  todoItem: state.todoTemplates.todoItem,
});

export default connect(mapStateToProps)(Form.create()(FormTodoItem));
