// @flow
import React, {Component} from "react";
import PropTypes from "prop-types";
import {Avatar, Button, Col, Comment, Form, Mentions, Modal, Row,} from "antd";
import "moment/locale/ru";
import api from "../../../api";

const { Option } = Mentions;

class CommentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: "",

      users: [],
      mentioned_users: [],
      members: [],
      mentioned_members: [],
    };
  };

  onChangeComment = (value) => {
    this.setState({
      comment: value,
    });
  };

  fetchMentions = async (value) => {
    let {
      customer,
    } = this.props;

    const responseMembers = await api.fetch(`/members/search/?`, {
      customer_id: customer?.id,
      q: value,
    });

    let responseUsers = await api.fetch(`/users/search/?`, {
      q: value,
    });

    this.setState({
      fetching: false,
      users: responseUsers.data,
      members: responseMembers.data,
    });
  };

  addMention = (option, prefix) => {
    let {
      users,
      members,
    } = this.state;

    let mentioned_user = users.find((user) => user.email === option.value);
    if (mentioned_user) {
      this.setState({
        mentioned_users: [...this.state.mentioned_users, mentioned_user],
      });
    }

    let mentioned_member = members.find((member) => member.email === option.value);
    if (mentioned_member) {
      this.setState({
        mentioned_members: [...this.state.mentioned_members, mentioned_member],
      });
    }
  };

  onSubmit = () => {
    const {
      errors,
      comment,
      mentioned_users,
      mentioned_members,
    } = this.state;

    if (comment.length > 3) {
      this.props.onSubmitCommentAllModal(
        comment,
        {
          users: mentioned_users,
          members: mentioned_members,
        });

      if (!errors) {
        this.setState({
          comment: "",
          mentioned_users: [],
          mentioned_members: [],
        });
      }
    }
  };

  render() {
    const {
      comment,
      users,
      members,
    } = this.state;

    const {
      isCommentAllModalOpen,
      onCancel,
    } = this.props;

    return (
      <Form layout={"vertical"}>
        <Modal
          visible={isCommentAllModalOpen}
          title={"Добавить комментарий для выбранных записей"}
          okText={"Добавить комментарий"}
          onOk={this.onSubmit}
          onCancel={onCancel}
        >
          <Row>
            <Col span={24}>
              <Form.Item>
                <Mentions
                  rows="3"
                  placeholder="используйте @ для выбора пользователя"
                  onChange={this.onChangeComment}
                  onSearch={this.fetchMentions}
                  onSelect={this.addMention}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(option.label.toLowerCase()) >= 0
                  }
                  value={comment}
                >
                  {users.map((user) => {
                    return (
                      <Option key={user.id} value={user.email} label={user.name}>
                        <div>
                          <Avatar size="small" style={{ background: "#10899e" }}>
                            {user.name[0]}
                          </Avatar>{" "}
                          {user.name}
                        </div>
                      </Option>
                    );
                  })}
                  {members.map((member) => {
                    return (
                      <Option key={member.id} value={member.email} label={member.name}>
                        <div>
                          <Avatar size="small" style={{background: "#10899e"}}>
                            {member.name[0]}
                          </Avatar>{" "}
                          {member.name}
                        </div>
                      </Option>
                    );
                  })}
                </Mentions>
              </Form.Item>
            </Col>
          </Row>
        </Modal>
      </Form>
    );
  }
}

CommentForm.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  entityTask: PropTypes.object,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
  router: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  form: PropTypes.object,
};

export default Form.create()(CommentForm);
