// @flow
import React, { Component, Fragment } from "react";
import { Row, Col, DatePicker, Select } from "antd";
import SelectMultiFetch from "../../../components/SelectMultiFetch";
import Tasks from "./Tasks/Tasks";
import Projects from "./Projects/Projects";
import Orders from "./Orders/Orders";
import Tickets from "./Tickets/Tickets";
import EntityTasks from "./EntityTasks/EntityTasks";
import InTime from "./InTime/InTime";
import Info from "./Info/Info";
import Can from "../../../rules/Can";

import moment from "moment";
import "moment/locale/ru";

const { RangePicker } = DatePicker;
const Option = Select.Option;

class Statistic extends Component {
  componentWillMount = () => {
    this.props.fetchStatistic();
  };

  render() {
    const {
      currentUser,
      fetchParams,
      isTasksLoading,
      isStatisticLoading,
      period,
      onChangeDateRange,
      onChangePeriod,
      tasks,
      onChangeTaskFilterCreatedPeriod,
      onChangeTaskFilterDueByPeriod,
      onChangeTaskFilterUsers,
      countOverdueTasks,
      onChangeTasksAssigneeType,
      tasksStatistic,
      taskSeries,
      fetchTasks,
      fetchTasksParams,
      fetchStatisticParams,
      onChangeStatisticDateRange,
      onChangeStatisticPeriod,
      onChangeStatisticDepartments,
      // projects
      projectSeries,
      projectsStatistic,
      // orders
      ordersStatistic,
      orderCustomers,
      onChangeOrdersCustomer,
      // tickets
      ticketSeries,
      isTicketsStatisticLoading,
      ticketsStatistic,
      ticketCustomers,
      onChangeTicketsCustomer,
      // entity_tasks
      entityTaskSeries,
      // intime_statistic
      isIntimeStatisticLoading,
      intimeStatistic,
      // services
      services,
    } = this.props;

    const dateFormat = "DD.MM.YYYY";

    return (
      <React.Fragment>
        <Can
          role={currentUser.role}
          perform="dashboard:statistic"
          yes={() => (
            <Row
              gutter={{ md: 8, lg: 24, xl: 48 }}
              style={{ paddingBottom: 10 }}
            >
              <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                <RangePicker
                  style={{ width: "100%" }}
                  value={[
                    fetchStatisticParams.startDate
                      ? moment(fetchStatisticParams.startDate, dateFormat)
                      : null,
                    fetchStatisticParams.endDate
                      ? moment(fetchStatisticParams.endDate, dateFormat)
                      : null,
                  ]}
                  format={dateFormat}
                  onChange={onChangeStatisticDateRange}
                />
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <SelectMultiFetch
                  showSearch
                  placeholder="Подразделение"
                  onChange={onChangeStatisticDepartments}
                  selected={
                    fetchStatisticParams.departments
                      ? fetchStatisticParams.departments
                      : []
                  }
                  url={"/departments/search/?"}
                />
              </Col>
              {/* <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <Select
              style={{ width: "100%" }}
              value={period}
              placeholder="Период"
              optionFilterProp="children"
              filterOption={false}
              onChange={onChangeStatisticPeriod}
            >
              <Option value={0}>произвольный период</Option>
              <Option value={1}>этот месяц</Option>
              <Option value={2}>этот квартал</Option>
              <Option value={3}>этот год</Option>
              <Option value={4}>прошлый месяц</Option>
              <Option value={5}>прошлый квартал</Option>
              <Option value={6}>прошлый год</Option>
            </Select>
          </Col> */}
            </Row>
          )}
          no={() => null}
        />

        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Info />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Tasks
              isTasksLoading={isTasksLoading}
              isStatisticLoading={isStatisticLoading}
              tasks={tasks}
              countOverdueTasks={countOverdueTasks}
              onChangeTasksAssigneeType={onChangeTasksAssigneeType}
              tasksStatistic={tasksStatistic}
              taskSeries={taskSeries}
              fetchTasks={fetchTasks}
              fetchTasksParams={fetchTasksParams}
              onChangeTaskFilterCreatedPeriod={onChangeTaskFilterCreatedPeriod}
              onChangeTaskFilterDueByPeriod={onChangeTaskFilterDueByPeriod}
              onChangeTaskFilterUsers={onChangeTaskFilterUsers}
            />
          </Col>
        </Row>
        <Can
          role={currentUser.role}
          perform="tickets:delete"
          yes={() => (
            <Row gutter={10}>
              {services.orders ? (
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Orders
                    currentUser={currentUser}
                    isStatisticLoading={isStatisticLoading}
                    ordersStatistic={ordersStatistic}
                    fetchParams={fetchStatisticParams}
                    orderCustomers={orderCustomers}
                    onChangeOrdersCustomer={onChangeOrdersCustomer}
                  />
                </Col>
              ) : null}
              {/* {services.projects ? (
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Projects
                    isStatisticLoading={isStatisticLoading}
                    projectsStatistic={projectsStatistic}
                    projectSeries={projectSeries}
                  />
                </Col>
              ) : null} */}
              {services.tickets ? (
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Tickets
                    currentUser={currentUser}
                    isStatisticLoading={isStatisticLoading}
                    fetchParams={fetchStatisticParams}
                    ticketSeries={ticketSeries}
                    isTicketsStatisticLoading={isTicketsStatisticLoading}
                    ticketsStatistic={ticketsStatistic}
                    ticketCustomers={ticketCustomers}
                    onChangeTicketsCustomer={onChangeTicketsCustomer}
                  />
                </Col>
              ) : null}
              {services.entity_tasks ? (
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <EntityTasks
                    isStatisticLoading={isStatisticLoading}
                    entityTaskSeries={entityTaskSeries}
                  />
                </Col>
              ) : null}
              {/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <InTime
                  isLoading={isIntimeStatisticLoading}
                  data={intimeStatistic}
                />
              </Col> */}
            </Row>
          )}
          no={() => null}
        />
      </React.Fragment>
    );
  }
}

export default Statistic;
