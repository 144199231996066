// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, Form, Card } from "antd";
import Header from "./Header";
import PayrollSchemaValues from "./PayrollSchemaValues";

class PayrollForm extends Component {
  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onSave(values);
    });
  };

  render() {
    const {
      isLoading,
      isNew,
      payrollSchema,
      onClose,
      // header
      onChangeName,
      onChangeDescription,
      onChangeOrders,
      // payroll_schema_values
      onAddPayrollSchemaValue,
      onDeleteSchemaValue,
      onChangeSchemaValueName,
      onChangeSchemaValueWeight,
      onChangeSchemaValue,
      onChangeSchemaValueFrom,
      onChangeSchemaValueTo
    } = this.props;
    const { getFieldDecorator } = this.props.form;

    const payrollSchemaValues = payrollSchema.payroll_schema_values.filter(function(item) {
      return !item._destroy;
    });

    return (
      <Card
        loading={isLoading}
        title={
          <h2 style={{ marginBottom: 0 }}>{`Схема начислений #${
            isNew ? "новая" : `${payrollSchema.id}`
          }`}</h2>
        }
        extra={
          <div>
            <Button onClick={onClose}>Закрыть</Button>
            <Button
              onClick={this.onSubmit}
              type="primary"
              ghost
              style={{ marginLeft: "8px" }}
            >
              Сохранить
            </Button>
          </div>
        }
      >
        <Form layout={"vertical"}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Row>
              <Header
                getFieldDecorator={getFieldDecorator}
                payrollSchema={payrollSchema}
                onChangeName={onChangeName}
                onChangeDescription={onChangeDescription}
                onChangeOrders={onChangeOrders}
              />
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <PayrollSchemaValues
              getFieldDecorator={getFieldDecorator}
              payrollSchemaValues={payrollSchemaValues}
              onAddPayrollSchemaValue={onAddPayrollSchemaValue}
              onDeleteSchemaValue={onDeleteSchemaValue}
              onChangeSchemaValueName={onChangeSchemaValueName}
              onChangeSchemaValueWeight={onChangeSchemaValueWeight}
              onChangeSchemaValue={onChangeSchemaValue}
              onChangeSchemaValueFrom={onChangeSchemaValueFrom}
              onChangeSchemaValueTo={onChangeSchemaValueTo}
            />
          </Col>
        </Form>
      </Card>
    );
  }
}

PayrollForm.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  payrollSchema: PropTypes.object,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
  router: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  form: PropTypes.object,
};

export default Form.create()(PayrollForm);
