// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import Actions from "../../../actions/entityTasks";
import FaqActions from "../../../actions/faqs";
import List from "./List";

class EntityTasks extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.onSetFilters()).then(() => {
      this.props.dispatch(Actions.fetchEntityTasks());
    });
  };

  onDelete = (ids) => {
    ids.map((id) => {
      this.props.dispatch(Actions.onDelete(id)).then(() => {
        this.props.dispatch(Actions.fetchEntityTasks());
      });
    });
  };

  onSearch = (e) => {
    this.props
      .dispatch(Actions.onSearch(e.target.value))
      .then(() => this.props.dispatch(Actions.fetchEntityTasks()));
  };

  onChangePage = (value) => {
    this.props
      .dispatch(Actions.onChangePage(value))
      .then(() => this.props.dispatch(Actions.fetchEntityTasks()))
      .then(() => {
        localStorage.setItem(
          "entityTasksFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeLimit = (current, size) => {
    this.props
      .dispatch(Actions.onChangeLimit(size))
      .then(() => this.props.dispatch(Actions.fetchEntityTasks()))
      .then(() => {
        localStorage.setItem(
          "entityTasksFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onSortBy = (field, direction) => {
    this.props
      .dispatch(Actions.onSortBy(field, direction))
      .then(() => this.props.dispatch(Actions.fetchEntityTasks()))
      .then(() => {
        localStorage.setItem(
          "entityTasksFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  // filters
  onClearFilters = () => {
    this.props.dispatch(Actions.onClearFilters()).then(() => {
      localStorage.setItem(
        "entityTasksFilters",
        JSON.stringify(this.props.fetchParams)
      );
    });
  };

  onChangeFilterStatus = (value) => {
    this.props
      .dispatch(Actions.onChangeFilterStatus(value))
      .then(() => this.props.dispatch(Actions.fetchEntityTasks()))
      .then(() => {
        localStorage.setItem(
          "entityTasksFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeFilterPeriod = (value, dateString) => {
    this.props
      .dispatch(Actions.onChangeFilterPeriod(dateString[0], dateString[1]))
      .then(() => this.props.dispatch(Actions.fetchEntityTasks()))
      .then(() => {
        localStorage.setItem(
          "entityTasksFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  downloadReport = () => {
    let params = this.props.fetchParams;
    Actions.downloadReport(
      params,
      "entity_tasks.xlsx",
      "entity_tasks.xlsx"
    );
  };

  onChangeFilterCustomer = (value) => {
    this.props
      .dispatch(Actions.onChangeFilterCustomer(value))
      .then(() => this.props.dispatch(Actions.fetchEntityTasks()))
      .then(() => {
        localStorage.setItem(
          "entityTasksFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeFilterUsers = (values) => {
    this.props
      .dispatch(Actions.onChangeFilterUsers(values))
      .then(() => this.props.dispatch(Actions.fetchEntityTasks()))
      .then(() => {
        localStorage.setItem(
          "entityTasksFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeFilterServices = (values) => {
    // console.log(values)
    this.props
      .dispatch(Actions.onChangeFilterServices(values))
      .then(() => this.props.dispatch(Actions.fetchEntityTasks()))
      .then(() => {
        localStorage.setItem(
          "entityTasksFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  // notices
  onClearNotices = () => {
    this.props.dispatch(Actions.onClearNotices());
  };

  // faqs
  onFaqToggle = () => {
    this.props.dispatch(FaqActions.onDrawerToggle()).then(() => {
      this.props.dispatch(FaqActions.fetchFaqs(3, 1));
    });
  };

  createCommentAll = (ids, comment, mentioned) => {
    ids.forEach((id) => {
      this.props.dispatch(Actions.onCreateComment(id, comment, mentioned));
    });
  };

  render() {
    const {
      currentUser,
      isLoading,
      entityTasks,
      meta,
      fetchParams,
      notices,
    } = this.props;
    return (
      <List
        currentUser={currentUser}
        loading={isLoading}
        entityTasks={entityTasks}
        meta={meta}
        fetchParams={fetchParams}
        notices={notices}
        onDelete={this.onDelete}
        onSearch={this.onSearch}
        onChangePage={this.onChangePage}
        onChangeLimit={this.onChangeLimit}
        onSortBy={this.onSortBy}
        // filters
        onClearFilters={this.onClearFilters}
        onChangeFilterStatus={this.onChangeFilterStatus}
        onChangeFilterPeriod={this.onChangeFilterPeriod}
        onChangeFilterUsers={this.onChangeFilterUsers}
        onChangeFilterServices={this.onChangeFilterServices}
        onChangeFilterCustomer={this.onChangeFilterCustomer}
        // notices
        onClearNotices={this.onClearNotices}
        // faqs
        onFaqToggle={this.onFaqToggle}
        downloadReport={this.downloadReport}
        // comments
        createCommentAll={this.createCommentAll}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  isLoading: state.entityTasks.isLoading,
  entityTasks: state.entityTasks.entityTasks,
  meta: state.entityTasks.meta,
  fetchParams: state.entityTasks.fetchParams,
  notices: state.entityTasks.notices,
});

export default connect(mapStateToProps)(EntityTasks);
