// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Button, Table, Card, PageHeader, Input } from "antd";
import PropTypes from "prop-types";
import Filter from "./Filter";

const Search = Input.Search;

class ServicesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      isFilterVisible: false,
    };
  }

  onFilterToggle = () => {
    this.setState({ isFilterVisible: !this.state.isFilterVisible });
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  onDelete = () => {
    this.props.onDelete(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  onEditService = (id) => {
    this.context.router.history.push(`/services/${id}`);
  };

  onChangeCategory = (value, selectedOptions) => {
    this.props.onChangeCategory({
      service_category_id: value[value.length - 1],
      service_ids: [this.state.selectedRowKeys],
    });
    this.setState({ selectedRowKeys: [] });
  };

  render() {
    const { selectedRowKeys, isFilterVisible } = this.state;
    const {
      isLoading,
      services,
      params,
      onSearch,
      onDelete,
      // filters
      fetchParams,
      isAvailable,
      customers,
      onChangeFilterIsAvailable,
      onChangeFilterCustomers,
    } = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: "Наименование",
        dataIndex: "name",
        render: (name, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <Link
                key={record.id}
                to={`/categories/${record.category_id}/services/${record.id}/edit`}
              >
                {name}
              </Link>
            </span>
          </div>
        ),
        width: "100%",
      },
    ];

    let mainButton = hasSelected ? (
      <Button key="1" type="danger" ghost onClick={this.onDelete}>
        Удалить
      </Button>
    ) : (
      <Link
        key="2"
        className={"ant-btn ant-btn-primary ant-btn-background-ghost"}
        to={{ pathname: `/categories/${params.categoryID}/services/new` }}
      >
        Добавить
      </Link>
    );

    let filterButton = (
      <Button
        type={
          fetchParams.isAvailable || fetchParams.customers ? "primary" : "ghost"
        }
        key="filterButton"
        icon="filter"
        onClick={this.onFilterToggle}
      />
    );

    return (
      <Col xs={24} sm={24} md={24} lg={17} xl={17}>
        <PageHeader
          extra={[
            <Search
              allowClear
              key="4"
              placeholder="поиск..."
              value={fetchParams.search}
              onChange={onSearch}
              style={{ width: 250 }}
            />,
            mainButton,
            filterButton,
          ]}
          footer={
            <Row gutter={{ md: 8, lg: 24, xl: 48 }} style={{paddingBottom: 10}}>
              <Col span={24}>
                <Table
                  bordered
                  rowSelection={rowSelection}
                  size={"middle"}
                  loading={isLoading}
                  pagination={false}
                  columns={columns}
                  dataSource={services}
                  rowKey="id"
                />
              </Col>
            </Row>
          }
        ></PageHeader>
        <Filter
          visible={isFilterVisible}
          isAvailable={isAvailable}
          customers={customers}
          onClose={this.onFilterToggle}
          onChangeIsAvailable={onChangeFilterIsAvailable}
          onChangeCustomers={onChangeFilterCustomers}
        />
      </Col>
    );
  }
}

ServicesList.propTypes = {
  services: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
  onSearch: PropTypes.func,
  onChangePage: PropTypes.func,
  onDelete: PropTypes.func,
};

export default ServicesList;
