// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Col,
  Row,
  DatePicker,
  Select,
  Form,
  Menu,
  Dropdown,
  Drawer,
  Icon
} from "antd";
import moment from "moment";
import "moment/locale/ru";
import SelectMultiFetch from "../../../components/SelectMultiFetch";

const { RangePicker } = DatePicker;
const { Option, OptGroup } = Select;
const FormItem = Form.Item;

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noAssignees: false,
    };
  }

  // componentWillUnmount() {
  //   this.props.onClearFilters();
  // }

  onChangeUsers = (values) => {
    this.props.onChangeUsers(values);
  };

  onChangeMembers = (values) => {
    this.props.onChangeMembers(values);
  };

  onChangeOrderUsers = (values) => {
    this.props.onChangeOrderUsers(values);
  };

  onChangeMenu = ({ key }) => {
    if (key == 1) {
      this.setState({ noAssignees: false }, () => {
        this.props.onChangeNoAssignees(false);
      });
    } else {
      this.setState({ noAssignees: true }, () => {
        this.props.onChangeNoAssignees(true);
      });
    }
  };

  render() {
    const { noAssignees } = this.state;
    const {
      users,
      members,
      orderUsers,
      visible,
      taskTypes,
      status,
      startDate,
      endDate,
      onClose,
      onChangeFilterTaskType,
      onChangeStatus,
      onChangePeriod,
    } = this.props;
    const { getFieldDecorator } = this.props.form;

    const menu = (
      <Menu onClick={this.onChangeMenu}>
        <Menu.Item key="0">Без ответственных</Menu.Item>
        <Menu.Item key="1">Ответственные</Menu.Item>
      </Menu>
    );

    const assigneesLabel = (
      <Dropdown overlay={menu}>
        <a className="ant-dropdown-link">
          {noAssignees ? "Без ответственных" : "Ответственные"}{" "}
          <Icon type="down" />
        </a>
      </Dropdown>
    );

    return (
      <Drawer
        width={540}
        visible={visible}
        title="Фильтры"
        placement={"right"}
        closable={false}
        onClose={onClose}
      >
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Период (план)"}>
              {getFieldDecorator("start", {
                onChange: onChangePeriod,
                initialValue: [
                  startDate ? moment(startDate, "DD.MM.YYYY") : null,
                  endDate ? moment(endDate, "DD.MM.YYYY") : null,
                ],
              })(
                <RangePicker
                  showTime={false}
                  format={"DD.MM.YYYY"}
                  placeholder={["начало", "окончание"]}
                  style={{ width: "100%" }}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Тип"}>
              {getFieldDecorator("taskTypes", {
                onChange: onChangeFilterTaskType,
                initialValue: taskTypes ? taskTypes : [],
              })(
                <Select
                  mode={"multiple"}
                  style={{ width: "100%" }}
                  placeholder="Тип"
                  optionFilterProp="children"
                  filterOption={false}
                >
                  <Option value={1}>выезд на обследование</Option>
                  <Option value={2}>создание КП</Option>
                  <Option value={3}>согласование КП</Option>
                  <Option value={4}>закупка оборудования</Option>
                  <Option value={5}>выполнение работ</Option>
                  <Option value={7}>внесение изменений в инф. системы</Option>
                  <Option value={6}>принятие работ</Option>
                </Select>
              )}
            </FormItem>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Статус"}>
              {getFieldDecorator("status", {
                onChange: onChangeStatus,
                initialValue: status,
              })(
                <Select
                  style={{ width: "100%" }}
                  placeholder="Статус"
                  optionFilterProp="children"
                  filterOption={false}
                >
                  <Option key={null} value={null}>
                    все
                  </Option>
                  <OptGroup label="статусы">
                    <Option key={"status"} value={"pending"}>
                      ожидание
                    </Option>
                    <Option key={"status"} value={"processing"}>
                      выполняется
                    </Option>
                    <Option key={"status"} value={"finished"}>
                      выполнено
                    </Option>
                    <Option key={"status"} value={"canceled"}>
                      отменено
                    </Option>
                  </OptGroup>
                  <OptGroup label="согласования КП">
                    <Option
                      key={"proposal_approving_status"}
                      value={"proposal_approving_pending"}
                    >
                      ожидание
                    </Option>
                    <Option
                      key={"proposal_approving_status"}
                      value={"proposal_approving_failed"}
                    >
                      не согласовано
                    </Option>
                    <Option
                      key={"proposal_approving_status"}
                      value={"proposal_approving_finished"}
                    >
                      согласовано
                    </Option>
                    <Option
                      key={"proposal_approving_status"}
                      value={"proposal_approving_canceled"}
                    >
                      отменено
                    </Option>
                  </OptGroup>
                  <OptGroup label="закупка оборудования">
                    <Option key={"purchase_status"} value={"purchase_pending"}>
                      ожидание
                    </Option>
                    <Option
                      key={"purchase_status"}
                      value={"purchase_processing"}
                    >
                      в закупке
                    </Option>
                    <Option
                      key={"purchase_status"}
                      value={"purchase_purchased"}
                    >
                      в наличии
                    </Option>
                    <Option key={"purchase_status"} value={"purchase_finished"}>
                      доставлено на объект
                    </Option>
                    <Option key={"purchase_status"} value={"purchase_canceled"}>
                      отменено
                    </Option>
                  </OptGroup>
                  <OptGroup label="принятие работ">
                    <Option
                      key={"job_approving_status"}
                      value={"job_approving_pending"}
                    >
                      ожидание
                    </Option>
                    <Option
                      key={"job_approving_status"}
                      value={"job_approving_accepted"}
                    >
                      принято
                    </Option>
                    <Option
                      key={"job_approving_status"}
                      value={"job_approving_canceled"}
                    >
                      отклонено с замечаниями
                    </Option>
                  </OptGroup>
                </Select>
              )}
            </FormItem>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={assigneesLabel}>
              {getFieldDecorator("users", {
                initialValue: users ? users : null,
              })(
                <SelectMultiFetch
                  disabled={noAssignees}
                  showSearch
                  placeholder="Ответственные"
                  onChange={this.onChangeUsers}
                  selected={users ? users : []}
                  url={"/users/search/?all='true'"}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Ответственные клиента"}>
              {getFieldDecorator("members", {
                initialValue: members ? members : null,
              })(
                <SelectMultiFetch
                  showSearch
                  placeholder="Ответственные клиента"
                  onChange={this.onChangeMembers}
                  selected={members ? members : []}
                  url={"/members/search/?"}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Ответственные (по заказу)"}>
              {getFieldDecorator("orderUsers", {
                initialValue: orderUsers ? orderUsers : null,
              })(
                <SelectMultiFetch
                  showSearch
                  placeholder="Ответственные (по заказу)"
                  onChange={this.onChangeOrderUsers}
                  selected={orderUsers ? orderUsers : []}
                  url={"/users/search/?all='true'"}
                />
              )}
            </FormItem>
          </Col>
        </Row>
      </Drawer>
    );
  }
}

Filter.propTypes = {
  entity: PropTypes.object,
};

export default Form.create()(Filter);
