// @flow
import React, { Component, Fragment } from "react";
import { Row, Col, Card, Statistic } from "antd";
import Details from "./Details";

class Cards extends Component {
  render() {
    const { currentUser, data, startDate, endDate, ticketCustomers } = this.props;

    return (
      <Row gutter={1}>
        <Col span={4}>
          <Card>
            <Statistic
              title={
                <Details
                  title="Всего заявок"
                  type={"total"}
                  startDate={startDate}
                  endDate={endDate}
                  ticketCustomers={ticketCustomers}
                  currentUser={currentUser}
                />
              }
              value={data.total}
              precision={0}
              valueStyle={{ color: "#3f8600" }}
              // prefix={<Icon type="arrow-up" />}
              suffix="шт."
            />
          </Card>
        </Col>
        <Col span={4}>
          <Card>
            <Statistic
              title={
                <Details
                  title="Просроченные"
                  type={"overdue"}
                  startDate={startDate}
                  endDate={endDate}
                  ticketCustomers={ticketCustomers}
                  currentUser={currentUser}
                />
              }
              value={data.overdue}
              precision={0}
              valueStyle={{ color: "#3f8600" }}
              // prefix={<Icon type="arrow-up" />}
              suffix="шт."
            />
          </Card>
        </Col>
        <Col span={4}>
          <Card>
            <Statistic
              title={
                <Details
                  title="На согласовании ДА"
                  type={"approving"}
                  startDate={startDate}
                  endDate={endDate}
                  ticketCustomers={ticketCustomers}
                  currentUser={currentUser}
                />
              }
              value={data.approving}
              precision={0}
              valueStyle={{ color: "#3f8600" }}
              // prefix={<Icon type="arrow-up" />}
              suffix="шт."
            />
          </Card>
        </Col>
        <Col span={4}>
          <Card>
            <Statistic
              title={
                <Details
                  title="Риск просрочки"
                  type={"overdue_risk"}
                  startDate={startDate}
                  endDate={endDate}
                  ticketCustomers={ticketCustomers}
                  currentUser={currentUser}
                />
              }
              value={data.overdue_risk}
              precision={0}
              valueStyle={{ color: "#3f8600" }}
              // prefix={<Icon type="arrow-up" />}
              suffix="шт."
            />
          </Card>
        </Col>
        <Col span={4}>
          <Card>
            <Statistic
              title={
                <Details
                  title="С заменой"
                  type={"replaced_progress"}
                  startDate={startDate}
                  endDate={endDate}
                  ticketCustomers={ticketCustomers}
                  currentUser={currentUser}
                />
              }
              value={data.replaced_progress}
              precision={0}
              valueStyle={{ color: "#3f8600" }}
              // prefix={<Icon type="arrow-up" />}
              suffix="шт."
            />
          </Card>
        </Col>
        <Col span={4}>
          <Card>
            <Statistic
              title={
                <Details
                  title="Без замены"
                  type={"progress"}
                  startDate={startDate}
                  endDate={endDate}
                  ticketCustomers={ticketCustomers}
                  currentUser={currentUser}
                />
              }
              value={data.progress}
              precision={0}
              valueStyle={{ color: "#3f8600" }}
              // prefix={<Icon type="arrow-up" />}
              suffix="шт."
            />
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Cards;
