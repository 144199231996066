
export function encodeBase64(file, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(file);
}

export function getIconTypeForContentType(contentType) {
  switch (contentType) {
    case "application/pdf":
      return "file-pdf";
    case "image/png":
      return "file-image";
    case "image/jpeg":
      return "file-image";
    case "application/msword":
      return "file-word";
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return "file-word";
    case "application/vnd.ms-excel":
      return "file-excel";
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return "file-excel";
    case "message/rfc822":
      return "mail";
    default:
      return "file-unknown";
  }
}

export function getIconColorForContentType(contentType) {
  switch (contentType) {
    case "application/pdf":
      return "red";
    case "image/png":
      return "brown";
    case "image/jpeg":
      return "brown";
    case "application/msword":
      return "cornflowerblue";
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return "cornflowerblue";
    case "application/vnd.ms-excel":
      return "green";
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return "green";
    default:
      return "brown";
  }
}