// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import update from "immutability-helper";
import {
  Table,
  Button,
  PageHeader,
  Pagination,
  Row,
  Col,
  Input,
  Checkbox,
} from "antd";

import PropTypes from "prop-types";
import moment from "moment";
import "moment/locale/ru";
import ModalForm from "../Form";

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      isModalVisible: false,
      supplier: {},
      expandedRowKeys: [],
    };
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  // supplier
  onAddSupplier = () => {
    this.setState(
      {
        supplier: {
          company: null,
        },
      },
      () => {
        this.setState({ isModalVisible: true });
      }
    );
  };

  onCancel = () => {
    this.setState({ isModalVisible: false });
  };

  onChangeCompany = (value) => {
    this.setState({
      supplier: update(this.state.supplier, {
        company: { $set: value },
      }),
    });
  };

  onSaveSupplier = () => {
    this.setState({ isModalVisible: false }, () => {
      this.props.onSaveSupplier(this.state.supplier);
    });
  };

  render() {
    const { isModalVisible, supplier, selectedRowKeys } = this.state;
    const { loading, suppliers, meta, fetchParams, onChangePage, onChangeIsActive } =
      this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: "Активировано",
        dataIndex: "is_active",
        render: (is_active, record) => (
          <span>
            <Checkbox
            checked={is_active}
            onChange={onChangeIsActive.bind(this, record.id)}
          />
            {/* <Badge status={is_active ? "success" : "error"} /> */}
          </span>
        ),
        key: "is_active",
        align: "center",
        width: "10%",
      },
      {
        title: "Подрядчик",
        dataIndex: "company",
        render: (company, record) => (
          <Link to={`/suppliers/${record.id}/edit`}>{company.name}</Link>
        ),
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "company",
        align: "center",
        width: "90%",
      },
    ];

    let mainButton = hasSelected ? (
      <Button key="1" type="danger" ghost onClick={this.onDelete}>
        Удалить
      </Button>
    ) : (
      <Button key="2" type="primary" ghost onClick={this.onAddSupplier}>
        Добавить
      </Button>
    );

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={"Подрядчики"}
        extra={[mainButton]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Table
                bordered
                rowSelection={rowSelection}
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={suppliers}
                rowKey="id"
              />
            </Col>
            <Col
              span={24}
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
              <Pagination
                showSizeChanger
                pageSize={fetchParams.limit}
                current={meta.current_page}
                onChange={onChangePage}
                // onShowSizeChange={this.onChangeFetchParamsLimit}
                total={meta.total_count}
              />
            </Col>
            <ModalForm
              visible={isModalVisible}
              supplier={supplier}
              onCancel={this.onCancel}
              onSave={this.onSaveSupplier}
              onChangeCompany={this.onChangeCompany}
            />
          </Row>
        }
      />
    );
  }
}

List.propTypes = {
  payments: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default List;
