// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, Row, Col, Form, Descriptions, InputNumber } from "antd";
import Assignees from "../../../components/Assignees";
import Documents from "../../../components/Documents";
import moment from "moment";
import "moment/locale/ru";
import SelectFetch from "../../../components/SelectFetch";

const FormItem = Form.Item;

class Sidebar extends Component {
  render() {
    const {
      isNew,
      getFieldDecorator,
      currentUser,
      due_by,
      planDays,
      start,
      finish,
      onChangeDueBy,
      onChangePlanDays,
      // statuses
      taskId,
      status,
      statusUrl,
      statusPostUrl,
      onChangeStatus,
      // assignees
      isAssigneesLoading,
      organisationAssignees,
      customer,
      customerAssignees,
      company,
      companyAssignees,
      onCreateAssignee,
      onDeleteAssignee,
      // attachments
      isAttachmentLoading,
      attachments,
      onDeleteAttachment,
      onUploadAttachment,
    } = this.props;

    return (
      <Card style={{ borderColor: "#1890ff" }}>
        <Row gutter={10}>
          <Col span={24}>
            <FormItem label={"Статус"}>
              {getFieldDecorator("status", {
                initialValue: status ? status : null,
              })(
                <SelectFetch
                  placeholder="Статус"
                  onChange={onChangeStatus}
                  selected={status ? status : null}
                  url={`/order_tasks/${taskId}/${statusUrl}`}
                />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={24}>
            <Descriptions bordered>
              <Descriptions.Item label="Плановые дата и время" span={3}>
                {due_by ? moment(due_by).format("LLL") : null}
              </Descriptions.Item>
              <Descriptions.Item label="Начало выполнения работ" span={3}>
                {start ? moment(start).format("lll") : "не начались"}
              </Descriptions.Item>
              <Descriptions.Item label="Окончание выполнения работ" span={3}>
                {finish ? moment(finish).format("lll") : "не закончены"}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col span={24}>
            <Assignees
              isReadOnly={true}
              isAssigneesLoading={isAssigneesLoading}
              assigneeType="organisation"
              assignees={organisationAssignees}
              onCreateAssignee={onCreateAssignee}
              onDeleteAssignee={onDeleteAssignee}
            />
          </Col>
          {customer ? (
            <Col span={24}>
              <Assignees
                title={"От заказчика"}
                isReadOnly={true}
                isAssigneesLoading={isAssigneesLoading}
                assigneeType="customer"
                assignees={customerAssignees}
                onCreateAssignee={onCreateAssignee}
                onDeleteAssignee={onDeleteAssignee}
              />
            </Col>
          ) : null}
          {company ? (
            <Col span={24}>
              <Assignees
                title={"От подрядчика"}
                isReadOnly={false}
                isAssigneesLoading={isAssigneesLoading}
                assigneeType="company"
                assignees={companyAssignees}
                onCreateAssignee={onCreateAssignee}
                onDeleteAssignee={onDeleteAssignee}
              />
            </Col>
          ) : null}
        </Row>
        <Row gutter={10}>
          {isNew ? null : (
            <Documents
              currentUser={currentUser}
              isUploading={isAttachmentLoading}
              attachments={attachments}
              onUploadAttachment={onUploadAttachment}
              onDeleteAttachment={onDeleteAttachment}
            />
          )}
        </Row>
      </Card>
    );
  }
}
Sidebar.propTypes = {
  customer: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  onAdressEdit: PropTypes.func,
  onChangePaymentType: PropTypes.func,
  onChangePaymentStatus: PropTypes.func,
  onChangeShippingStatus: PropTypes.func,
  onChangeShippingMethod: PropTypes.func,
};

export default Sidebar;
