// @flow
import React, { Component } from "react";
import {
  Row,
  Col,
  Modal,
  Select,
  Checkbox,
  Form,
  InputNumber,
  DatePicker,
} from "antd";

import moment from "moment";
import "moment/locale/ru";

import SelectFetch from "../../../components/SelectFetch";
import SelectMultiFetch from "../../../components/SelectMultiFetch";

const FormItem = Form.Item;
const Option = Select.Option;

class EntityServiceModal extends React.Component {
  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onSave(values);
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      visible,
      onCancel,
      entityService,
      onChangeEntityService,
      onChangeEntityServiceDuration,
      onChangeEntityServicePeriodicity,
      onChangeEntityServiceStart,
      onChangeEntityServiceIsSupplier,
      onChangeEntityServiceCompany,
      onChangeEntityServiceUsers,
      onChangeEntityServicEemployees,
    } = this.props;
    return (
      <Modal
        title={entityService.service ? entityService.service.name : "Новый"}
        destroyOnClose
        visible={visible}
        onOk={this.onSubmit}
        onCancel={onCancel}
      >
        <Form layout={"vertical"}>
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <FormItem label={"Раздел"}>
                {getFieldDecorator("service", {
                  initialValue: entityService.service
                    ? entityService.service
                    : null,
                  rules: [
                    {
                      required: true,
                      message: "Пожалуйста, раздел!",
                    },
                  ],
                })(
                  <SelectFetch
                    showSearch
                    showSubtitle
                    placeholder="Раздел"
                    onChange={onChangeEntityService}
                    selected={
                      entityService.service ? entityService.service : null
                    }
                    url={"/services/search/?"}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <FormItem label={"Следующая дата регламента"}>
                {getFieldDecorator("start", {
                  onChange: onChangeEntityServiceStart,
                  initialValue: moment(
                    entityService.start ? entityService.start : null
                  ),
                  rules: [
                    {
                      required: true,
                      message: "Пожалуйста, введите дату регламента!",
                    },
                  ],
                })(
                  <DatePicker
                    format={"LLL"}
                    className="ant-btn-width-100"
                    showTime
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={12}>
              <FormItem label={"Периодичность"}>
                {getFieldDecorator("periodicity", {
                  initialValue: entityService.periodicity,
                  onChange: onChangeEntityServicePeriodicity,
                  rules: [
                    {
                      required: true,
                      message: "Введите приоритет!",
                    },
                  ],
                })(
                  <Select
                    placeholder="Периодичность"
                    optionFilterProp="children"
                    filterOption={false}
                  >
                    <Option value={0}>нет</Option>
                    <Option value={1}>месяц</Option>
                    <Option value={3}>квартал</Option>
                    <Option value={6}>полугодие</Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label={"Продолжительность, мин."}>
                {getFieldDecorator("duration", {
                  onChange: onChangeEntityServiceDuration,
                  initialValue: entityService.duration,
                })(
                  <InputNumber min={0} max={1000} style={{ width: "100%" }} />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={10}>
          <Col span={12}>
            <FormItem>
              {getFieldDecorator("is_supplier", {
                onChange: onChangeEntityServiceIsSupplier,
                valuePropName: "checked",
                initialValue: entityService.is_supplier,
              })(<Checkbox>Подрядные работы</Checkbox>)}
            </FormItem>
          </Col>
        </Row>
        
          {entityService.is_supplier ? (
            <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <FormItem label={"Подрядчик"}>
                {getFieldDecorator("company", {
                  initialValue: entityService.company
                    ? entityService.company
                    : null,
                  rules: [
                    {
                      required: true,
                      message: "Введите, подрядчика!",
                    },
                  ],
                })(
                  <SelectFetch
                    showSearch
                    showSubtitle
                    placeholder="Подрядчик"
                    onChange={onChangeEntityServiceCompany}
                    selected={
                      entityService.company ? entityService.company : null
                    }
                    url={"/companies/search/?"}
                  />
                )}
              </FormItem>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <FormItem label={"Ответственные"}>
                  {getFieldDecorator("employees", {
                    initialValue: entityService.employees
                      ? entityService.employees
                      : [],
                  })(
                    <SelectMultiFetch
                      disabled={entityService.company ? false : true}
                      showSearch
                      placeholder="Ответственные"
                      onChange={onChangeEntityServicEemployees}
                      selected={
                        entityService.employees ? entityService.employees : []
                      }
                      url={entityService.company ? `/employees/search/?company_id=${entityService.company.id}` : null}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
          ) : (
            <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <FormItem label={"Ответственные"}>
                  {getFieldDecorator("users", {
                    initialValue: entityService.users
                      ? entityService.users
                      : [],
                  })(
                    <SelectMultiFetch
                      showSearch
                      placeholder="Ответственные"
                      onChange={onChangeEntityServiceUsers}
                      selected={entityService.users ? entityService.users : []}
                      url={"/users/search/?"}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
          )}
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(EntityServiceModal);
