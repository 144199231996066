// @flow
import React, { Component, Fragment } from "react";
import { Row, Col } from "antd";
import {
  Chart,
  registerShape,
  Geom,
  Axis,
  Tooltip,
  Interval,
  Interaction,
  Coordinate,
} from "bizcharts";

import accounting from "accounting";


class TaskPie extends Component {
  render() {
    const { currentUser, fetchParams, data } = this.props;

    const total = Number(data.total)

    const totals = [
      {
        type: "Новые",
        value: data.pending > 0 ? (Number(data.pending) / total) * 100 : 0,
      },
      {
        type: "В работе",
        value: data.processing > 0 ? (Number(data.processing) / total) * 100 : 0,
      },
      {
        type: "Выполнены",
        value: data.finished > 0 ? (Number(data.finished) / total) * 100 : 0,
      },
      {
        type: "Отменены",
        value: data.canceled > 0 ? (Number(data.canceled) / total) * 100 : 0,
      },
    ];

    const cols = {
      value: {
        formatter: (val) => {
          val = Math.round(val) + "%";
          return val;
        },
      },
    };

    return (
      <Row gutter={1}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Chart data={totals} scale={cols} height={300} autoFit>
            <Coordinate type="theta" radius={0.8} innerRadius={0.75} />
            <Axis visible={false} />
            <Tooltip showTitle={false} />
            <Interval
              adjust="stack"
              position="value"
              color="type"
              shape="sliceShape"
            />
            <Interaction type="element-single-selected" />
          </Chart>
        </Col>
      </Row>
    );
  }
}

export default TaskPie;
