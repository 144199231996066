// @flow
import React, { Component, Fragment } from "react";
import { Row, Col, Button, Card, Statistic, Icon } from "antd";

import moment from "moment";
import "moment/locale/ru";

class StatisticCards extends Component {
  render() {
    const { currentUser, fetchParams, data } = this.props;

    return (
      <Row gutter={1}>
          <Col span={6}>
          <Card>
            <Statistic
              title="Всего"
              value={data.total}
              precision={0}
              valueStyle={{ color: "#1777d1" }}
              // prefix={<Icon type="arrow-up" />}
              suffix="шт."
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Выполнено"
              value={data.finished}
              precision={0}
              valueStyle={{ color: "#1777d1" }}
              // prefix={<Icon type="arrow-up" />}
              suffix="шт."
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="В работе"
              value={data.processing}
              precision={0}
              valueStyle={{ color: "#3f8600" }}
              // prefix={<Icon type="arrow-up" />}
              suffix="шт."
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Просрочено"
              value={data.overdue}
              precision={0}
              valueStyle={{ color: "#cf1322" }}
              // prefix={<Icon type="arrow-down" />}
              suffix="шт."
            />
          </Card>
        </Col>
      </Row>
    );
  }
}

export default StatisticCards;
