// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Form, Input, InputNumber } from "antd";
import SelectFetch from "../../../../components/SelectFetch";

const FormItem = Form.Item;

class Actions extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      getFieldDecorator,
      taskTemplateId,
      actionTemplate,
      actionTemplateHours,
      actionTemplateSubject,
      onChangeActionTemplate,
      onChangeActionTemplateHours,
      onChangeActionTemplateSubject,
    } = this.props;

    return (
      <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <FormItem label={"При выполнении, создавать по шаблону"}>
            {getFieldDecorator("actionTemplate", {
              initialValue: actionTemplate ? actionTemplate : null,
            })(
              <SelectFetch
                showSearch
                placeholder="Шаблон"
                onChange={onChangeActionTemplate}
                selected={actionTemplate ? actionTemplate : null}
                url={`/task_templates/search/?current_template=${taskTemplateId}`}
              />
            )}
          </FormItem>
        </Col>
        {actionTemplate ? (
          <React.Fragment>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <FormItem label={"Тема задачи"}>
                {getFieldDecorator("name", {
                  onChange: onChangeActionTemplateSubject,
                  initialValue: actionTemplateSubject,
                  rules: [
                    {
                      required: true,
                      message: "Введите тему!",
                    },
                  ],
                })(<Input />)}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label={"Продолжительность, часы"}>
                {getFieldDecorator("duration", {
                  onChange: onChangeActionTemplateHours,
                  initialValue: actionTemplateHours,
                })(
                  <InputNumber min={0} max={1000} style={{ width: "100%" }} />
                )}
              </FormItem>
            </Col>
          </React.Fragment>
        ) : null}
      </Row>
    );
  }
}

Actions.propTypes = {
  activities: PropTypes.arrayOf(PropTypes.object),
};
export default Actions;
