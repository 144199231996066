// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Tag, Button, List, Avatar } from "antd";
import { Row, Col } from "antd";

import moment from "moment";
import "moment/locale/ru";

class Entities extends Component {
  render() {
    const { entities, onAddItem } = this.props;

    return (
      <div>
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col span={24}>
            <List
              itemLayout="horizontal"
              dataSource={entities}
              renderItem={item => (
                <List.Item
                  extra={
                    <span style={{ color: "rgba(0, 0, 0, 0.45)" }}>{`${moment(
                      item.start
                    ).format("l")} - ${moment(item.finish).format("l")}`}</span>
                  }
                >
                  <List.Item.Meta
                    title={
                      <Link to={`/entities/${item.id}/edit`}>{item.name}</Link>
                    }
                    description={item.address}
                  />
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

{
  /* {item.entity_services.map(entity_service => {
                    return (
                      <Tag color="#108ee9">{entity_service.service.name}</Tag>
                    );
                  })} */
}

Entities.propTypes = {
  orderEntities: PropTypes.arrayOf(PropTypes.object),
  total: PropTypes.number,
  discount: PropTypes.object,
  shippingCost: PropTypes.number,
  amountDiscountCode: PropTypes.number,
  onRemoveVariant: PropTypes.func
};
export default Entities;
