// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Form, DatePicker } from "antd";
import SelectFetch from "../../../../components/SelectFetch";
import moment from "moment";
import "moment/locale/ru";

const FormItem = Form.Item;

class Header extends Component {
  render() {
    const {
      getFieldDecorator,
      employeePayroll,
      onChangeDate,
      onChangeDepartment,
      onChangePayrollSchema
    } = this.props;

    const dateFormat = "DD.MM.YYYY";

    return (
      <React.Fragment>
        <Row gutter={16} style={{ paddingBottom: 15 }}>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <FormItem label={"Дата"}>
              {getFieldDecorator("date", {
                onChange: onChangeDate,
                initialValue: employeePayroll.date
                  ? moment(employeePayroll.date)
                  : null,
                rules: [
                  {
                    required: true,
                    message: "Ведите дату!"
                  }
                ]
              })(<DatePicker style={{ width: "100%" }} format={dateFormat} />)}
            </FormItem>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <FormItem label={"Подразделение"}>
              {getFieldDecorator("department", {
                initialValue: employeePayroll.department
                  ? employeePayroll.department
                  : null,
                rules: [
                  {
                    required: true,
                    message: "Ведите подразделение!"
                  }
                ]
              })(
                <SelectFetch
                  showSearch
                  placeholder="Подразделение"
                  onChange={onChangeDepartment}
                  selected={
                    employeePayroll.department
                      ? employeePayroll.department
                      : null
                  }
                  url={"/departments/search/?"}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <FormItem label={"Схема начислений"}>
              {getFieldDecorator("payroll_schema", {
                initialValue: employeePayroll.payroll_schema
                  ? employeePayroll.payroll_schema
                  : null,
                rules: [
                  {
                    required: true,
                    message: "Ведите cхему начислений!"
                  }
                ]
              })(
                <SelectFetch
                  showSearch
                  placeholder="Схема начислений"
                  onChange={onChangePayrollSchema}
                  selected={
                    employeePayroll.payroll_schema
                      ? employeePayroll.payroll_schema
                      : null
                  }
                  url={"/payroll_schemas/search/?"}
                />
              )}
            </FormItem>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

Header.propTypes = {};

export default Header;
