// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  List,
  Select,
  Form,
  Divider,
  Avatar,
  Input,
  InputNumber,
  Checkbox,
  Icon,
} from "antd";
import moment from "moment";
import "moment/locale/ru";
import _ from "lodash/array";
import SelectFetch from "../../../components/SelectFetch";
import Documents from "../../../components/Base64Documents";
import Assignees from "../../../components/Assignees";
import User from "../../../components/User";
import Member from "../../../components/Member";
import Can from "../../../rules/Can";

import Uploads from "../../../components/Uploads";

const FormItem = Form.Item;

const Option = Select.Option;

class Statuses extends Component {
  render() {
    const {
      isNew,
      currentUser,
      ticketId,
      isUploading,
      getFieldDecorator,
      isReplaced,
      isTicketReplaceAvailable,
      isTicketAmountAvailable,
      isOrdered,
      isOrganisation,
      isSupplier,
      dueBy,
      user,
      member,
      attachments,
      status,
      ticketPriority,
      incident,
      amount,
      number,
      order,
      onChangeNumber,
      onChangeStatus,
      onChangePiority,
      onChangeIncident,
      onChangeAmount,
      onUploadAttachment,
      onDeleteAttachment,
      onChangeReplaced,
      onChangeOrdered,
      onChangeOrder,
      onChangeIsOrganisation,
      onChangeIsSupplier,
      // preUpload
      tempAttachments,
      onTempUpload,
      onDeleteTempAttachment,
      // assignees
      isAssigneesLoading,
      customer,
      company,
      organisationAssignees,
      supervisorAssignees,
      customerAssignees,
      companyAssignees,
      onDeleteAssignee,
      onCreateAssignee,
    } = this.props;

    const orderLabel = order ? (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <span>Заказ</span>
        <span>
          <Link target={"_blank"} to={`/orders/${order.id}/edit`}>
            открыть в новом окне
          </Link>
        </span>
      </div>
    ) : (
      "Заказ"
    );

    const ticketPrioritiesUrl =  isOrganisation ? '/ticket_priorities/search/?' : `/ticket_priorities/search/?${
      customer ? `customer_id=${customer.id}` : null
    }`

    return (
      <React.Fragment>
        {isOrdered ? (
          <Row gutter={10}>
            <Col span={24}>
              <FormItem label={orderLabel}>
                {getFieldDecorator("order", {
                  initialValue: order ? order : null,
                })(
                  <SelectFetch
                    showSearch
                    placeholder="Заказ"
                    onChange={onChangeOrder}
                    selected={order ? order : null}
                    url={`/orders/search/?`}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
        ) : null}
        {!isNew ? (
          <Row gutter={10}>
            {isTicketReplaceAvailable ? (
              <Col span={12}>
                <FormItem>
                  {getFieldDecorator("isReplaced", {
                    onChange: onChangeReplaced,
                    valuePropName: "checked",
                    initialValue: isReplaced,
                  })(<Checkbox>Требуется замена</Checkbox>)}
                </FormItem>
              </Col>
            ) : null}
            <Col span={12}>
              <FormItem>
                {getFieldDecorator("isOrdered", {
                  onChange: onChangeOrdered,
                  valuePropName: "checked",
                  initialValue: isOrdered,
                })(<Checkbox>Перенесено в заказ</Checkbox>)}
              </FormItem>
            </Col>
          </Row>
        ) : null}
        <Row gutter={10}>
          <Col span={12}>
            <FormItem>
              {getFieldDecorator("isOrganisation", {
                onChange: onChangeIsOrganisation,
                valuePropName: "checked",
                initialValue: isOrganisation,
              })(<Checkbox>Собственный</Checkbox>)}
            </FormItem>
          </Col>

          <Col span={12}>
            <FormItem>
              {getFieldDecorator("isSupplier", {
                onChange: onChangeIsSupplier,
                valuePropName: "checked",
                initialValue: isSupplier,
              })(<Checkbox>Подряд</Checkbox>)}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={10}>
          {!isNew ? (
            <Col span={12}>
              <FormItem label={"Номер"}>
                {getFieldDecorator("number", {
                  onChange: onChangeNumber,
                  initialValue: number,
                })(<Input />)}
              </FormItem>
            </Col>
          ) : null}

          {isNew ? null : (
            <Col span={12}>
              <FormItem label={"Статус"}>
                {getFieldDecorator("status", {
                  initialValue: status ? status : null,
                })(
                  <SelectFetch
                    placeholder="Статус"
                    onChange={onChangeStatus}
                    selected={status ? status : null}
                    url={`/tickets/${ticketId}/statuses`}
                  />
                )}
              </FormItem>
            </Col>
          )}
          <Col span={12}>
            <FormItem label={"Приоритет"}>
              {getFieldDecorator("ticketPriority", {
                initialValue: ticketPriority ? ticketPriority : null,
                rules: [
                  {
                    required: true,
                    message: "Введите приоритет!",
                  },
                ],
              })(
                <SelectFetch
                  showSearch
                  placeholder="Приоритет"
                  onChange={onChangePiority}
                  selected={ticketPriority ? ticketPriority : null}
                  url={ticketPrioritiesUrl}
                />
              )}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label={"Тип инцидента"}>
              {getFieldDecorator("incident", {
                initialValue: incident,
                onChange: onChangeIncident,
                rules: [
                  {
                    required: true,
                    message: "Введите тип инцидента!",
                  },
                ],
              })(
                <Select
                  placeholder="Тип инцидента"
                  optionFilterProp="children"
                  filterOption={false}
                >
                  <Option value={"by_ticket"}>выезд по заявке</Option>
                  <Option value={"by_schedule"}>выезд по регламенту</Option>
                  <Option value={"by_service"}>сервис</Option>
                </Select>
              )}
            </FormItem>
          </Col>
          {(isReplaced || isTicketAmountAvailable) ? (
            <Col span={12}>
              <FormItem label={"Сумма"}>
                {getFieldDecorator("amount", {
                  onChange: onChangeAmount,
                  initialValue: amount,
                  rules: [
                    {
                      required: isReplaced ? true : false,
                      message: "Пожалуйста, введите сумму!",
                    },
                  ],
                })(<InputNumber style={{ width: "100%" }} />)}
              </FormItem>
            </Col>
          ) : null}
        </Row>

        <Divider>Инициатор заявки</Divider>
        {user ? (
          <User user={user} />
        ) : member ? (
          <Member member={member} />
        ) : null}

        <Row gutter={10}>
          <Col span={24}>
            <Can
              role={currentUser.role}
              perform={"tickets:assignees"}
              yes={() => (
                <Assignees
                  title={"Наблюдатели"}
                  isReadOnly={isNew ? true : false}
                  isAssigneesLoading={isAssigneesLoading}
                  assigneeType="supervisor"
                  customer={customer}
                  assignees={supervisorAssignees}
                  onCreateAssignee={onCreateAssignee}
                  onDeleteAssignee={onDeleteAssignee}
                />
              )}
              no={() => (
                <Assignees
                  title={"Наблюдатели"}
                  isReadOnly={true}
                  isAssigneesLoading={isAssigneesLoading}
                  assigneeType="supervisor"
                  customer={customer}
                  assignees={supervisorAssignees}
                  onCreateAssignee={onCreateAssignee}
                  onDeleteAssignee={onDeleteAssignee}
                />
              )}
            />
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={24}>
            <Can
              role={currentUser.role}
              perform={"tickets:assignees"}
              yes={() => (
                <Assignees
                  isReadOnly={isNew ? true : false}
                  isAssigneesLoading={isAssigneesLoading}
                  assigneeType="organisation"
                  customer={customer}
                  assignees={organisationAssignees}
                  onCreateAssignee={onCreateAssignee}
                  onDeleteAssignee={onDeleteAssignee}
                />
              )}
              no={() => (
                <Assignees
                  isReadOnly={true}
                  isAssigneesLoading={isAssigneesLoading}
                  assigneeType="organisation"
                  customer={customer}
                  assignees={organisationAssignees}
                  onCreateAssignee={onCreateAssignee}
                  onDeleteAssignee={onDeleteAssignee}
                />
              )}
            />
          </Col>
        </Row>
        {isSupplier ? (
          <Row gutter={10}>
            <Col span={24}>
              <Can
                role={currentUser.role}
                perform={"tickets:assignees"}
                yes={() => (
                  <Assignees
                    title={"От подрядчика"}
                    isReadOnly={isNew ? true : false}
                    isAssigneesLoading={isAssigneesLoading}
                    assigneeType="company"
                    company={company}
                    assignees={companyAssignees}
                    onCreateAssignee={onCreateAssignee}
                    onDeleteAssignee={onDeleteAssignee}
                  />
                )}
                no={() => (
                  <Assignees
                    title={"От подрядчика"}
                    isReadOnly={true}
                    isAssigneesLoading={isAssigneesLoading}
                    assigneeType="company"
                    company={company}
                    assignees={companyAssignees}
                    onCreateAssignee={onCreateAssignee}
                    onDeleteAssignee={onDeleteAssignee}
                  />
                )}
              />
            </Col>
          </Row>
        ) : null}
        {customer ? (
          <Row gutter={10}>
            <Col span={24}>
              <Can
                role={currentUser.role}
                perform={"tickets:assignees"}
                yes={() => (
                  <Assignees
                    title={"От заказчика"}
                    isReadOnly={isNew ? true : false}
                    isAssigneesLoading={isAssigneesLoading}
                    assigneeType="customer"
                    customer={customer}
                    assignees={customerAssignees}
                    onCreateAssignee={onCreateAssignee}
                    onDeleteAssignee={onDeleteAssignee}
                  />
                )}
                no={() => (
                  <Assignees
                    title={"От заказчика"}
                    isReadOnly={true}
                    isAssigneesLoading={isAssigneesLoading}
                    assigneeType="customer"
                    customer={customer}
                    assignees={customerAssignees}
                    onCreateAssignee={onCreateAssignee}
                    onDeleteAssignee={onDeleteAssignee}
                  />
                )}
              />
            </Col>
          </Row>
        ) : null}

        {isTicketReplaceAvailable ? (
          <Row gutter={10}>
            <Col span={24}>
              {isNew ? (
                <Uploads
                  title={"Дефектовочный акт"}
                  currentUser={currentUser}
                  onUpload={onTempUpload}
                  isUploading={isUploading}
                  attachments={tempAttachments}
                  onDeleteAttachment={onDeleteTempAttachment}
                />
              ) : isReplaced ? (
                <Documents
                  title={"Дефектовочный акт"}
                  currentUser={currentUser}
                  attachmentType={2}
                  onUpload={onUploadAttachment}
                  isUploading={isUploading}
                  attachments={attachments}
                  onDeleteAttachment={onDeleteAttachment}
                />
              ) : null}
            </Col>
          </Row>
        ) : null}
      </React.Fragment>
    );
  }
}
Statuses.propTypes = {
  getFieldDecorator: PropTypes.func,
};

export default Statuses;
