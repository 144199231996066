// @flow
import React, { Component, Fragment } from "react";
import { Row, Col, Button, Card, Drawer } from "antd";
import Faqs from "./Faqs";

class Sidebar extends Component {
  render() {
    const {
      isDrawerVisible,
      onDrawerToggle,
      isFaqsLoading,
      isFaqLoading,
      fetchFaq,
      faqs,
      faq,
    } = this.props;

    return (
      <Drawer
        title="Помощь"
        width={520}
        closable={false}
        onClose={onDrawerToggle}
        visible={isDrawerVisible}
      >
        <Faqs
          loading={isFaqsLoading}
          isFaqLoading={isFaqLoading}
          faqs={faqs}
          fetchFaq={fetchFaq}
          faq={faq}
        />
      </Drawer>
    );
  }
}

export default Sidebar;
