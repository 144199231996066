import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Progress, Table } from "antd";
import moment from "moment";
import "moment/locale/ru";

function info(progress) {
  const percent = Number(
    ((progress.current / progress.steps) * 100).toFixed(0)
  );
  return <Progress percent={percent} size="small" />;
}

export default function ProgressInfo({
  data = null,
  customer = null,
  entity = null,
  order = null
}) {
  const columns = [
    {
      title: "Выполнение",
      dataIndex: "progress",
      render: (progress, record) => info(record.progress),
      key: "status",
      align: "center",
      width: "30%"
    },
    {
      title: "Начало (план)",
      dataIndex: "start_time",
      render: start_time =>
        start_time ? moment(start_time).format("LLL") : null,
      key: "finish",
      align: "center",
      width: "35%"
    },
    {
      title: "Окончание (план)",
      dataIndex: "end_time",
      render: end_time => (end_time ? moment(end_time).format("LLL") : null),
      key: "end_time",
      align: "center",
      width: "35%"
    }
  ];

  return (
    <React.Fragment>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Table
            rowClassName={"progress-row"}
            size={"small"}
            loading={false}
            // showHeader={false}
            columns={columns}
            dataSource={data}
            pagination={false}
            rowKey="id"
          />
        </Col>
      </Row>
      <Row style={{ paddingTop: 15 }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          {order ? (
            <p style={{ fontSize: 12 }}>
              Заказ:
              <Link
                to={`/orders/${order.id}/edit`}
                target="_blank"
              >{` #${order.number}`}</Link>
            </p>
          ) : null}

          <p style={{ fontSize: 12 }}>
            {customer ? `Клиент: ${customer.name}` : null}
          </p>
          <p style={{ fontSize: 12 }}>
            {entity ? `Объект: ${entity.name}, ${entity.address}` : null}
          </p>
        </Col>
      </Row>
    </React.Fragment>
  );
}
