// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Tabs, Button, Tooltip } from "antd";
import EntityTaskItems from "./EntityTaskItems";
import Todos from "./Todos";

const { TabPane } = Tabs;

class TasksTodos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 1,
    };
  }

  callback = (key) => {
    this.setState({ tabIndex: key });
  };

  fillEntityTaskTodos = () => {
    this.props.fillEntityTaskTodos();
  };

  render() {
    const { tabIndex } = this.state;
    const {
      isTodosLoading,
      isTodoLoading,
      isExistTodoTemplate,
      fillEntityTaskTodos,
      entityTaskItems,
      entityTaskTodos,
      onEntityTaskTodoCompleted,
      onEntityTaskTodoSaveComment
    } = this.props;

    // const completed = entityTaskTodos.filter(function (item) {
    //   return item.completed_at;
    // }).length;

    let todos = []
    let completedTodos = []

    entityTaskTodos.map((element) => {
      element.entity_task_todo_items.map((item) => {
        todos.push(item)
      })
    });

    entityTaskTodos.map((element) => {
      element.entity_task_todo_items.filter((todoTtem) => todoTtem.completed_at != null ).map((item) => {
        completedTodos.push(item)
      })
    });


    return (
      <Row gutter={10}>
        <Col span={24}>
          <Tabs
            defaultActiveKey="1"
            onChange={this.callback}
            tabBarExtraContent={
              tabIndex == "2" && isExistTodoTemplate && todos.length == 0 ? (
                <Tooltip placement="bottom" title={"Заполнить по шаблону"}>
                  <Button onClick={fillEntityTaskTodos} icon="plus" />
                </Tooltip>
              ) : null
            }
          >
            <TabPane tab="Регламент" key="1">
              <EntityTaskItems entityTaskItems={entityTaskItems} />
            </TabPane>
            <TabPane tab={`Чек-лист (${completedTodos.length}/${todos.length})`} key="2">
              <Todos
                isTodosLoading={isTodosLoading}
                isTodoLoading={isTodoLoading}
                entityTaskTodos={entityTaskTodos}
                onCompleted={onEntityTaskTodoCompleted}
                onSaveComment={onEntityTaskTodoSaveComment}
              />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    );
  }
}

TasksTodos.propTypes = {
  entityTask: PropTypes.object,
  onChangeDate: PropTypes.func,
  onChangeAssignee: PropTypes.func,
  onChangeAgreement: PropTypes.func,
  onChangeAgreementDate: PropTypes.func,
  onChangeAdditional: PropTypes.func,
  onChangeComment: PropTypes.func,
  onChangeNotice: PropTypes.func,
};

export default TasksTodos;
