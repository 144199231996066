import update from "immutability-helper";
import moment from "moment";
moment.locale("ru");

const initialState = {
  smsNotifications: [],
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1,
  },
  fetchParams: {
    search: null,
    startDate: null,
    endDate: null,
    sort: null,
    direction: null,
    page: 1,
    limit: 10,
    types: null,
    users: null,
    members: null,
  },
  errors: false,
  isLoading: true,
};

export default function (state = initialState, action) {
  let idx = -1;
  let localFetchParams = null;
  switch (action.type) {
    case "FETCH_SMS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };

    case "FETCH_SMS":
      return update(state, {
        smsNotifications: { $set: action.response.data },
        meta: { $set: action.response.meta },
        isLoading: { $set: false },
      });

    case "FETCH_SMS_FAILURE":
      return {
        ...state,
        isLoading: false,
      };

    case "FETCH_SMS_STATUS_REQUEST":
      return {
        ...state,
        errors: false,
      };

    case "FETCH_SMS_STATUS":
      idx = state.smsNotifications.findIndex(function (o) {
        return o.id == action.id;
      });
      if (idx === -1) {
        return null;
      }
      return update(state, {
        smsNotifications: { [idx]: { $set: action.response.data } },
        errors: { $set: false },
      });

    case "FETCH_SMS_STATUS_FAILURE":
      return {
        ...state,
        errors: true,
      };

    default:
      return state;
  }
}
