import React from "react";
import { Table } from "antd";

export default function Members({ loading = false, data = [] }) {
  return <Details loading={loading} data={data} />;
}

function Details({ loading = false, data = null }) {
  const columns = [
    {
      title: "Имя",
      dataIndex: "name",
      width: "70%",
    },
    {
      title: "Оценок",
      dataIndex: "count",
      align: "right",
      width: "30%",
    },
  ];

  return (
    <Table
      title={() => "Топ 5 оценивающих от заказчика"}
      columns={columns}
      dataSource={data}
      size="small"
      pagination={false}
      rowKey="id"
    />
  );
}
