// @flow
import React, { Component } from "react";
import {
  Row,
  Col,
  Modal,
  Select,
  Button,
  Form,
  TimePicker,
  Divider
} from "antd";

import moment from "moment";
import "moment/locale/ru";

const format = "HH:mm";

const FormItem = Form.Item;
const Option = Select.Option;

class OpeningHourModal extends React.Component {
  onSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onSave(values);
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      visible,
      onCancel,
      records,
      onChangeOpens,
      onChangeCloses
    } = this.props;

    const mondays = records.filter(records => records.day == 1);
    const tuesdays = records.filter(records => records.day == 2);
    const wednesdays = records.filter(records => records.day == 3);
    const thursdays = records.filter(records => records.day == 4);
    const fridays = records.filter(records => records.day == 5);
    const saturdays = records.filter(records => records.day == 6);
    const sundays = records.filter(records => records.day == 7);

    return (
      <Modal
        width={880}
        title={"Расписание"}
        destroyOnClose
        visible={visible}
        onOk={this.onSubmit}
        onCancel={onCancel}
      >
        <Form layout={"vertical"}>
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={11}>
              <Divider>Понедельник</Divider>

              {mondays.map(monday => {
                return (
                  <Row key={monday.id} gutter={{ md: 8, lg: 24, xl: 48 }}>
                    <Col span={12}>
                      <TimePicker
                        allowClear
                        style={{ width: "100%" }}
                        value={
                          monday.opens ? moment(monday.opens, format) : null
                        }
                        format={format}
                        onChange={onChangeOpens.bind(this, monday.id)}
                      />
                    </Col>
                    <Col span={12}>
                      <TimePicker
                        allowClear
                        style={{ width: "100%" }}
                        value={
                          monday.closes ? moment(monday.closes, format) : null
                        }
                        format={format}
                        onChange={onChangeCloses.bind(this, monday.id)}
                      />
                    </Col>
                  </Row>
                );
              })}

              <Divider>Вторник</Divider>

              {tuesdays.map(tuesday => {
                return (
                  <Row key={tuesday.id} gutter={{ md: 8, lg: 24, xl: 48 }}>
                    <Col span={12}>
                      <TimePicker
                        allowClear
                        style={{ width: "100%" }}
                        value={
                          tuesday.opens ? moment(tuesday.opens, format) : null
                        }
                        format={format}
                        onChange={onChangeOpens.bind(this, tuesday.id)}
                      />
                    </Col>
                    <Col span={12}>
                      <TimePicker
                        allowClear
                        style={{ width: "100%" }}
                        value={
                          tuesday.closes ? moment(tuesday.closes, format) : null
                        }
                        format={format}
                        onChange={onChangeCloses.bind(this, tuesday.id)}
                      />
                    </Col>
                  </Row>
                );
              })}

              <Divider>Среда</Divider>

              {wednesdays.map(wednesday => {
                return (
                  <Row key={wednesday.id} gutter={{ md: 8, lg: 24, xl: 48 }}>
                    <Col span={12}>
                      <TimePicker
                        allowClear
                        style={{ width: "100%" }}
                        value={
                          wednesday.opens
                            ? moment(wednesday.opens, format)
                            : null
                        }
                        format={format}
                        onChange={onChangeOpens.bind(this, wednesday.id)}
                      />
                    </Col>
                    <Col span={12}>
                      <TimePicker
                        allowClear
                        style={{ width: "100%" }}
                        value={
                          wednesday.closes
                            ? moment(wednesday.closes, format)
                            : null
                        }
                        format={format}
                        onChange={onChangeCloses.bind(this, wednesday.id)}
                      />
                    </Col>
                  </Row>
                );
              })}
            </Col>
            <Col span={2}></Col>
            <Col span={11}>
              <Divider>Четверг</Divider>
              {thursdays.map(thursday => {
                return (
                  <Row key={thursday.id} gutter={{ md: 8, lg: 24, xl: 48 }}>
                    <Col span={12}>
                      <TimePicker
                        allowClear
                        style={{ width: "100%" }}
                        value={
                          thursday.opens ? moment(thursday.opens, format) : null
                        }
                        format={format}
                        onChange={onChangeOpens.bind(this, thursday.id)}
                      />
                    </Col>
                    <Col span={12}>
                      <TimePicker
                        allowClear
                        style={{ width: "100%" }}
                        value={
                          thursday.closes
                            ? moment(thursday.closes, format)
                            : null
                        }
                        format={format}
                        onChange={onChangeCloses.bind(this, thursday.id)}
                      />
                    </Col>
                  </Row>
                );
              })}
              <Divider>Пятница</Divider>
              {fridays.map(friday => {
                return (
                  <Row key={friday.id} gutter={{ md: 8, lg: 24, xl: 48 }}>
                    <Col span={12}>
                      <TimePicker
                        allowClear
                        style={{ width: "100%" }}
                        value={
                          friday.opens ? moment(friday.opens, format) : null
                        }
                        format={format}
                        onChange={onChangeOpens.bind(this, friday.id)}
                      />
                    </Col>
                    <Col span={12}>
                      <TimePicker
                        allowClear
                        style={{ width: "100%" }}
                        value={
                          friday.closes ? moment(friday.closes, format) : null
                        }
                        format={format}
                        onChange={onChangeCloses.bind(this, friday.id)}
                      />
                    </Col>
                  </Row>
                );
              })}
              <Divider>Суббота</Divider>
              {saturdays.map(saturday => {
                return (
                  <Row key={saturday.id} gutter={{ md: 8, lg: 24, xl: 48 }}>
                    <Col span={12}>
                      <TimePicker
                        allowClear
                        style={{ width: "100%" }}
                        value={
                          saturday.opens ? moment(saturday.opens, format) : null
                        }
                        format={format}
                        onChange={onChangeOpens.bind(this, saturday.id)}
                      />
                    </Col>
                    <Col span={12}>
                      <TimePicker
                        allowClear
                        style={{ width: "100%" }}
                        value={
                          saturday.closes
                            ? moment(saturday.closes, format)
                            : null
                        }
                        format={format}
                        onChange={onChangeCloses.bind(this, saturday.id)}
                      />
                    </Col>
                  </Row>
                );
              })}
              <Divider>Воскресенье</Divider>
              {sundays.map(sunday => {
                return (
                  <Row key={sunday.id} gutter={{ md: 8, lg: 24, xl: 48 }}>
                    <Col span={12}>
                      <TimePicker
                        allowClear
                        style={{ width: "100%" }}
                        value={
                          sunday.opens ? moment(sunday.opens, format) : null
                        }
                        format={format}
                        onChange={onChangeOpens.bind(this, sunday.id)}
                      />
                    </Col>
                    <Col span={12}>
                      <TimePicker
                        allowClear
                        style={{ width: "100%" }}
                        value={
                          sunday.closes ? moment(sunday.closes, format) : null
                        }
                        format={format}
                        onChange={onChangeCloses.bind(this, sunday.id)}
                      />
                    </Col>
                  </Row>
                );
              })}
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(OpeningHourModal);
