// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import TaskActions from "../../../actions/tasks";
import Actions from "../../../actions/entities";
import { Spin } from "antd";
import EntityForm from "./Form";

class Entity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: false,
      startCreatedDate: null,
      endCreatedDate: null,
      categories: null,
    };
  }

  componentWillMount = () => {
    if (this.props.match.params.id) {
      this.props
        .dispatch(Actions.fetchEntity(this.props.match.params.id))
        .then(() => {
          this.props.dispatch(
            TaskActions.fetchTaskActions({ taskOwners: [6] })
          );
        });
    } else {
      this.setState({ isNew: true }, () => {
        if (this.props.location.state) {
          this.props.dispatch(
            Actions.onNew(this.props.location.state.customer)
          );
        } else {
          this.props.dispatch(Actions.onNew());
        }
      });
    }
  };

  onSave = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.onUpdate(this.props.match.params.id));
    } else {
      this.props.dispatch(Actions.onCreate()).then(() => {
        if (!this.props.errors) {
          this.props.history.push(`/entities/${this.props.entity.id}/edit`);
        }
      });
    }
  };

  onClose = () => {
    this.props.history.push(`/entities`);
  };

  onDeleteEntityServices = (ids) => {
    ids.map((id) => {
      let idx = this.props.entity.entity_services.findIndex(function (o) {
        return o.id == id;
      });
      if (idx === -1) {
        return null;
      }
      if (this.props.entity.entity_services[idx].is_exist) {
        this.props.dispatch(Actions.onDeleteEntityService(idx));
      } else {
        this.props.dispatch(Actions.onDestroyEntityService(idx));
      }
    });
  };

  // general
  onChangeStart = (value) => {
    this.props.dispatch(Actions.onChangeStart(value));
  };
  onChangeFinish = (value) => {
    this.props.dispatch(Actions.onChangeFinish(value));
  };
  onChangeIsOrganisation = (e) => {
    this.props.dispatch(Actions.onChangeIsOrganisation(e.target.checked));
  };
  onChangeCustomer = (value) => {
    this.props.dispatch(Actions.onChangeCustomer(value));
  };
  onChangeName = (e) => {
    this.props.dispatch(Actions.onChangeName(e.target.value));
  };
  onChangeEntityType = (value) => {
    this.props.dispatch(Actions.onChangeEntityType(value));
  };
  onChangePostalCode = (e) => {
    this.props.dispatch(Actions.onChangePostalCode(e.target.value));
  };
  onChangeCity = (value) => {
    this.props.dispatch(Actions.onChangeCity(value));
  };
  onChangeStreet = (e) => {
    this.props.dispatch(Actions.onChangeStreet(e.target.value));
  };
  onChangeBuilding = (e) => {
    this.props.dispatch(Actions.onChangeBuilding(e.target.value));
  };
  onChangeExtCode = (e) => {
    this.props.dispatch(Actions.onChangeExtCode(e.target.value));
  };
  onChangeTodoTemplates = (values) => {
    this.props.dispatch(Actions.onChangeTodoTemplates(values));
  };

  //entity_services
  onSaveEntityService = (item) => {
    let idx = this.props.entity.entity_services.findIndex(function (o) {
      return o.id == item.id;
    });
    if (idx === -1) {
      this.props.dispatch(Actions.onAddEntityService(item));
    } else {
      this.props.dispatch(Actions.onSaveEntityService(idx, item));
    }
  };

  // attachments
  fetchAttachments = () => {
    this.props.dispatch(Actions.fetchAttachments(this.props.match.params.id));
  };

  onSaveAttachment = (data) => {
    // console.log(data)
    this.props.dispatch(
      Actions.onSaveAttachment(this.props.match.params.id, data)
    );
  };

  onDeleteAttachment = (id) => {
    this.props
      .dispatch(Actions.onDeleteAttachment(this.props.match.params.id, id))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAttachments(this.props.match.params.id)
          );
        }
      });
  };

  onUploadAttachment = (file) => {
    this.props
      .dispatch(Actions.onUploadAttachment(this.props.match.params.id, file))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAttachments(this.props.match.params.id)
          );
        }
      });
  };

  // entity_photos
  uploadPhoto = (file) => {
    this.props.dispatch(Actions.uploadPhoto(this.props.entity.id, file));
  };

  onDeletePhoto = (item) => {
    this.props.dispatch(Actions.onDeletePhoto(this.props.entity.id, item.uid));
  };

  // entity_schemas
  onDeleteEntitySchema = (item) => {
    this.props.dispatch(
      Actions.onDeleteEntitySchema(this.props.entity.id, item.uid)
    );
  };

  onUploadEntitySchema = (file) => {
    this.props.dispatch(
      Actions.onUploadEntitySchema(this.props.entity.id, file)
    );
  };

  // assignees
  onCreateAssignee = (value, type) => {
    this.props
      .dispatch(
        Actions.onCreateAssignee(this.props.match.params.id, value, type)
      )
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAssignees(this.props.match.params.id)
          );
        }
      });
  };

  onDeleteAssignee = (id) => {
    this.props
      .dispatch(Actions.onDeleteAssignee(this.props.match.params.id, id))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAssignees(this.props.match.params.id)
          );
        }
      });
  };

  // opening_hours
  onSaveOpeningHours = (records) => {
    if (this.props.match.params.id) {
      this.props.dispatch(
        Actions.onSaveOpeningHours(this.props.match.params.id, records)
      );
    }
  };

  // extCodes
  onAddExtCode = (item) => {
    this.props.dispatch(Actions.onAddExtCode(item));
  };

  onDeleteExtCode = (id) => {
    const { entity } = this.props;

    let idx = entity.entity_extcodes.findIndex(function (o) {
      return o.id == id;
    });
    if (idx === -1) {
      return null;
    }
    if (entity.entity_extcodes[idx].is_exist) {
      this.props.dispatch(Actions.onDeleteExtCode(idx));
    } else {
      this.props.dispatch(Actions.onDestroyExtCode(idx));
    }
  };

  //parameters
  onChangeSquare = (value) => {
    this.props.dispatch(Actions.onChangeSquare(value));
  };

  onChangeRoom = (value) => {
    this.props.dispatch(Actions.onChangeRoom(value));
  };

  onChangeEquipment = (value) => {
    this.props.dispatch(Actions.onChangeEquipment(value));
  };

  onChangeComplexity = (value) => {
    this.props.dispatch(Actions.onChangeComplexity(value));
  };

  // activities
  fetchActivities = () => {
    this.props.dispatch(Actions.fetchActivities(this.props.match.params.id));
  };

  // entity_tasks
  fetchEntityTasks = () => {
    this.props.dispatch(Actions.fetchEntityTasks(this.props.entity.id));
  };

  // tickets
  onChangeCategories = (values) => {
    let categoryIds = [];
    const { startCreatedDate, endCreatedDate } = this.state;
    this.setState({ categories: values }, () => {
      if (values) {
        categoryIds = values.map((category) => {
          if (category && category.id) {
            return category.id;
          }
        });
      }
      this.props.dispatch(
        Actions.fetchTickets(
          this.props.entity.id,
          startCreatedDate,
          endCreatedDate,
          categoryIds.length > 0 ? categoryIds : null
        )
      );
    });
  };

  onChangePeriodTickets = (value, dateString) => {
    const { categories } = this.state;
    let categoryIds = [];

    this.setState(
      { startCreatedDate: dateString[0], endCreatedDate: dateString[1] },
      () => {
        if (categories) {
          categoryIds = categories.map((category) => {
            if (category && category.id) {
              return category.id;
            }
          });
        }
        this.props.dispatch(
          Actions.fetchTickets(
            this.props.entity.id,
            dateString[0],
            dateString[1],
            categoryIds
          )
        );
      }
    );
  };

  fetchTickets = () => {
    const { startCreatedDate, endCreatedDate, categories } = this.state;
    let categoryIds = [];

    if (categories) {
      categoryIds = categories.map((category) => {
        if (category && category.id) {
          return category.id;
        }
      });
    }

    this.props.dispatch(
      Actions.fetchTickets(
        this.props.entity.id,
        startCreatedDate,
        endCreatedDate,
        categoryIds
      )
    );
  };

  // orders
  fetchOrders = () => {
    this.props.dispatch(Actions.fetchOrders(this.props.entity.id));
  };

  // projects
  fetchProjects = () => {
    this.props.dispatch(Actions.fetchProjects(this.props.entity.id));
  };

  // notes
  onCreateNote = (value) => {
    this.props
      .dispatch(Actions.onCreateNote(this.props.match.params.id, value))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchEntityNotes(this.props.match.params.id)
          );
        }
      });
  };

  // geo
  onChangeLat = (value) => {
    this.props.dispatch(Actions.onChangeLat(value));
  };

  onChangeLon = (value) => {
    this.props.dispatch(Actions.onChangeLon(value));
  };

  // actions
  onOpenTaskForm = (action) => {
    const { entity } = this.props;
    this.props.dispatch(TaskActions.fetchNew(action.id, entity.id));
  };

  render() {
    const { startCreatedDate, endCreatedDate, categories } = this.state;
    const {
      currentUser,
      isLoading,
      isAssigneesLoading,
      isLoadingImages,
      isUploadingImage,
      entity,
      entityActivities,
      isActivitiesLoading,
      isAttachmentLoading,
      // entity_tasks
      isEntityTasksLoading,
      entityTasks,
      // tickets
      isTicketsLoading,
      tickets,
      // orders
      isOrdersLoading,
      orders,
      // projects
      isProjectsLoading,
      projects,
      // notes
      isEntityNotesLoading,
      // actions
      actions,
      isTaskActionsLoading,
    } = this.props;
    return isLoading ? (
      <Spin />
    ) : (
      <EntityForm
        isNew={this.state.isNew}
        currentUser={currentUser}
        entity={entity}
        onSave={this.onSave}
        onClose={this.onClose}
        // general
        onChangeStart={this.onChangeStart}
        onChangeFinish={this.onChangeFinish}
        onChangeIsOrganisation={this.onChangeIsOrganisation}
        onChangeCustomer={this.onChangeCustomer}
        onChangeName={this.onChangeName}
        onChangeEntityType={this.onChangeEntityType}
        onChangePostalCode={this.onChangePostalCode}
        onChangeCity={this.onChangeCity}
        onChangeStreet={this.onChangeStreet}
        onChangeBuilding={this.onChangeBuilding}
        onChangeExtCode={this.onChangeExtCode}
        onChangeLat={this.onChangeLat}
        onChangeLon={this.onChangeLon}
        onChangeTodoTemplates={this.onChangeTodoTemplates}
        // statuses
        isAssigneesLoading={isAssigneesLoading}
        onDeleteAssignee={this.onDeleteAssignee}
        //entity_services
        onDeleteEntityServices={this.onDeleteEntityServices}
        onSaveEntityService={this.onSaveEntityService}
        // attachments
        isAttachmentLoading={isAttachmentLoading}
        onSaveAttachment={this.onSaveAttachment}
        fetchAttachments={this.fetchAttachments}
        onDeleteAttachment={this.onDeleteAttachment}
        onUploadAttachment={this.onUploadAttachment}
        // entity_photos
        isLoadingImages={isLoadingImages}
        isUploadingImage={isUploadingImage}
        uploadPhoto={this.uploadPhoto}
        onDeletePhoto={this.onDeletePhoto}
        // entity_schemas
        onDeleteEntitySchema={this.onDeleteEntitySchema}
        onUploadEntitySchema={this.onUploadEntitySchema}
        // assignees
        onCreateAssignee={this.onCreateAssignee}
        onDeleteAssignee={this.onDeleteAssignee}
        // opening_hours
        onSaveOpeningHours={this.onSaveOpeningHours}
        // extCodes
        onAddExtCode={this.onAddExtCode}
        onDeleteExtCode={this.onDeleteExtCode}
        //parameters
        onChangeSquare={this.onChangeSquare}
        onChangeRoom={this.onChangeRoom}
        onChangeEquipment={this.onChangeEquipment}
        onChangeComplexity={this.onChangeComplexity}
        // activities
        entityActivities={entityActivities}
        isActivitiesLoading={isActivitiesLoading}
        fetchActivities={this.fetchActivities}
        // entity_tasks
        isEntityTasksLoading={isEntityTasksLoading}
        entityTasks={entityTasks}
        fetchEntityTasks={this.fetchEntityTasks}
        // tickets
        isTicketsLoading={isTicketsLoading}
        tickets={tickets}
        fetchTickets={this.fetchTickets}
        startCreatedDate={startCreatedDate}
        endCreatedDate={endCreatedDate}
        categories={categories}
        onChangePeriodTickets={this.onChangePeriodTickets}
        onChangeCategories={this.onChangeCategories}
        // orders
        isOrdersLoading={isOrdersLoading}
        orders={orders}
        fetchOrders={this.fetchOrders}
        // projects
        isProjectsLoading={isProjectsLoading}
        projects={projects}
        fetchProjects={this.fetchProjects}
        // notes
        isEntityNotesLoading={isEntityNotesLoading}
        onCreateNote={this.onCreateNote}
        // actions
        actions={actions}
        isTaskActionsLoading={isTaskActionsLoading}
        onOpenTaskForm={this.onOpenTaskForm}
      />
    );
  }
}

Entity.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  entity: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  isLoading: state.entities.isLoading,
  errors: state.entities.errors,
  entity: state.entities.entity,
  isAttachmentLoading: state.entities.isAttachmentLoading,
  isLoadingImages: state.entities.isLoadingImages,
  isUploadingImage: state.entities.isUploadingImage,
  isAssigneesLoading: state.entities.isAssigneesLoading,
  entityActivities: state.entities.entityActivities,
  isActivitiesLoading: state.entities.isActivitiesLoading,
  // entity_tasks
  isEntityTasksLoading: state.entities.isEntityTasksLoading,
  entityTasks: state.entities.entityTasks,
  // tickets
  isTicketsLoading: state.entities.isTicketsLoading,
  tickets: state.entities.tickets,
  // orders
  isOrdersLoading: state.entities.isOrdersLoading,
  orders: state.entities.orders,
  // projects
  isProjectsLoading: state.entities.isProjectsLoading,
  projects: state.entities.projects,
  // notes
  isEntityNotesLoading: state.entities.isEntityNotesLoading,
  // actions
  actions: state.tasks.actions,
  isTaskActionsLoading: state.tasks.isLoading,
});

export default connect(mapStateToProps)(withRouter(Entity));
