// @flow
import React, { Component, Fragment } from "react";
import { Row, Col } from "antd";
import { LineChart } from "bizcharts";

import accounting from "accounting";
import moment from "moment";
import "moment/locale/ru";

class TaskLine extends Component {
  render() {
    const { currentUser, fetchParams, data } = this.props;

    let series = [];

    data.map((item) => {
      series.push(
        {
          period: moment(item.period).format("DD.MM"),
          status: "В работе",
          quantity: Number(item.processing),
        },
        {
          period: moment(item.period).format("DD.MM"),
          status: "Выполнены",
          quantity: Number(item.finished),
        },
        {
            period: moment(item.period).format("DD.MM"),
            status: "Новые",
            quantity: Number(item.pending),
          }
      );
    });

   

    return (
      <Row gutter={1}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <LineChart
            data={series}
            title={{
              visible: true,
              text: "Задачи",
            }}
            description={{
              visible: true,
              text: "по статусам выполнения (по дате создания)",
            }}
            smooth
            point={{
              shape: "hollow-circle",
              visible: true,
              style: () => {},
            }}
            xField="period"
            yField="quantity"
            seriesField="status"
          />
        </Col>
      </Row>
    );
  }
}

export default TaskLine;
