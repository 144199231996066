// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table, Rate } from "antd";

class Rating extends Component {
  render() {
    const { rates } = this.props;

    const columns = [
      {
        title: "Рейтинг",
        dataIndex: "rate",
        render: (rate, record) => (
          <Rate disabled allowHalf defaultValue={parseFloat(rate)} />
        ),
        width: "70%",
        align: "left",
      },
      {
        title: "Кол-во оценок",
        dataIndex: "quantity",
        width: "30%",
        align: "right",
      },
    ];

    return (
      <Table
        bordered
        size={"small"}
        pagination={false}
        columns={columns}
        dataSource={rates}
        rowKey="rate"
      />
    );
  }
}

export default Rating;
