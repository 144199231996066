import api from "../api";

const Actions = {};

Actions.fetchCustomers = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_CUSTOMERS_REQUEST" });
    const params = getState().customers.fetchParams;
    api
      .fetch("/customers", params)
      .then(response => {
        dispatch({ type: "FETCH_CUSTOMERS", response });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({ type: "FETCH_CUSTOMERS_FAILURE", response });
      });
  };
};

Actions.fetchCustomer = (id = null) => {
  return dispatch => {
    dispatch({ type: "FETCH_CUSTOMER_REQUEST" });
    api
      .fetch(`/customers/${id}`)
      .then(response => {
        dispatch({ type: "FETCH_CUSTOMER", response });
      })
      .catch(error => {
        api.handleError(error, "Клиент");
        dispatch({ type: "FETCH_CUSTOMER_FAILURE" });
      });
  };
};

Actions.onNew = () => {
  return dispatch => {
    dispatch({
      type: "CUSTOMER_NEW"
    });
  };
};

Actions.onUpdate = id => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_CUSTOMER_REQUEST" });
    const customer = getState().customers.customer;
    const ticket_priorities = customer.ticket_priorities.map(item => {
      return {
        id: item.id,
        name: item.name,
        sort: item.sort,
        response_time: item.response_time,
        use_open_hours: item.use_open_hours,
        _destroy: false
      };
    });

    const data = {
      customer: {
        name: customer.name,
        details: customer.details,
        website: customer.website,
        postal_code: customer.postal_code,
        city_id: customer.city ? customer.city.id : null,
        street: customer.street,
        building: customer.building,
        settings: customer.settings,
        ticket_priorities_attributes: ticket_priorities
      }
    };
    api
      .patch(`/customers/${id}`, data)
      .then(response => {
        dispatch({ type: "PATCH_CUSTOMER", response });
        api.openNotification("success", "Клиент", "Успешно обновлен!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Клиент", error);
          });
        }
        dispatch({ type: "PATCH_CUSTOMER_FAILURE", response });
      });
  };
};

Actions.onCreate = () => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_CUSTOMER_REQUEST" });
    const customer = getState().customers.customer;
    const data = {
      customer: {
        name: customer.name,
        details: customer.details,
        website: customer.website,
        postal_code: customer.postal_code,
        city_id: customer.city ? customer.city.id : null,
        street: customer.street,
        building: customer.building
      }
    };
    return api
      .post(`/customers`, data)
      .then(response => {
        dispatch({ type: "POST_CUSTOMER", response });
        api.openNotification("success", "Клиент системы", "Успешно создан!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Клиент системы", error);
          });
        }
        dispatch({ type: "POST_CUSTOMER_FAILURE", response });
      });
  };
};

Actions.onDelete = id => {
  return dispatch => {
    dispatch({ type: "DELETE_CUSTOMER_REQUEST" });
    return api
      .delete(`/customers/${id}`)
      .then(response => {
        api.openNotification("success", "Клиент", "Успешно удален!");
        dispatch({ type: "DELETE_CUSTOMER", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Клиент", error);
          });
        }
        dispatch({
          type: "DELETE_CUSTOMER_FAILURE"
        });
      });
  };
};

Actions.onUploadLogo = (customerId, file) => {
  return dispatch => {
    dispatch({
      type: "CUSTOMER_LOGO_UPLOAD"
    });

    const formData = new FormData();
    formData.append("file", file);
    return api
      .patchFormData(`/customers/${customerId}/logo`, formData)
      .then(response => {
        api.openNotification("success", "Клиент", "Лого успешно загружено!");
        dispatch({ type: "CUSTOMER_LOGO_UPLOAD_SUCCEED", response });
      })
      .catch(response => {
        dispatch({
          type: "CUSTOMER_LOGO_UPLOAD_FAILED"
        });
        dispatch({
          type: "ADD_NOTIFICATION",
          message: { label: response.error, type: "error" }
        });
      });
  };
};

// contacts
Actions.onCreateContact = (customerID, contact) => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_CUSTOMER_CONTACT_REQUEST" });

    const formData = new FormData();
    formData.append(`customer_contact[name]`, contact.name);
    formData.append(`customer_contact[title]`, contact.title);
    formData.append(`customer_contact[birthday]`, contact.birthday);
    formData.append(`customer_contact[phone]`, contact.phone);
    formData.append(`customer_contact[email]`, contact.email);

    if (contact.url && contact.is_modified) {
      formData.append("customer_contact[avatar]", contact.url);
    }

    return api
      .postFormData(`/customers/${customerID}/customer_contacts`, formData)
      .then(response => {
        dispatch({ type: "POST_CUSTOMER_CONTACT_SUCCEED", response });
        api.openNotification("success", "Контакт", "Успешно создан!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Контакт", error);
          });
        }
        dispatch({ type: "POST_CUSTOMER_CONTACT_FAILURE", response });
      });
  };
};

Actions.onUpdateContact = (customerID, contact) => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_CUSTOMER_CONTACT_REQUEST" });

    const formData = new FormData();
    formData.append(`customer_contact[name]`, contact.name);
    formData.append(`customer_contact[title]`, contact.title);
    formData.append(`customer_contact[birthday]`, contact.birthday);
    formData.append(`customer_contact[phone]`, contact.phone);
    formData.append(`customer_contact[email]`, contact.email);

    if (contact.url && contact.is_modified) {
      formData.append("customer_contact[avatar]", contact.url);
    }

    return api
      .patchFormData(
        `/customers/${customerID}/customer_contacts/${contact.id}`,
        formData
      )
      .then(response => {
        dispatch({ type: "PATCH_CUSTOMER_CONTACT_SUCCEED", response });
        api.openNotification("success", "Контакт", "Успешно обновлен!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Контакт", error);
          });
        }
        dispatch({ type: "PATCH_CUSTOMER_CONTACT_FAILURE", response });
      });
  };
};

Actions.onDeleteContact = (customerID, id) => {
  return dispatch => {
    dispatch({
      type: "CUSTOMER_CONTACT_DELETE_REQUEST"
    });
    return api
      .delete(`/customers/${customerID}/customer_contacts/${id}`)
      .then(response => {
        api.openNotification("success", "Контакт", "Успешно удален!");
        dispatch({ type: "CUSTOMER_CONTACT_DELETE_SUCCEED", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Контакт", error);
          });
        }
        dispatch({ type: "CUSTOMER_CONTACT_DELETE_FAILED" });
      });
  };
};

Actions.onSearch = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_CUSTOMER_SEARCH",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangePage = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_CUSTOMERS_CHANGE_PAGE",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangeLimit = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_CUSTOMERS_CHANGE_LIMIT",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onClearFilters = value => {
  return dispatch => {
    dispatch({
      type: "CUSTOMER_CLEAR_FILTERS",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onSortBy = (field, direction) => {
  return dispatch => {
    dispatch({
      type: "CUSTOMERS_SORT_BY",
      field: field,
      direction: direction
    });
    return Promise.resolve();
  };
};

Actions.onChangeName = value => {
  return dispatch => {
    dispatch({
      type: "CUSTOMER_CHANGE_NAME",
      value: value
    });
  };
};

Actions.onChangeWebsite = value => {
  return dispatch => {
    dispatch({
      type: "CUSTOMER_CHANGE_WEBSITE",
      value: value
    });
  };
};

Actions.onChangePostalCode = value => {
  return dispatch => {
    dispatch({
      type: "CUSTOMER_CHANGE_POSTAL_CODE",
      value: value
    });
  };
};
Actions.onChangeCity = value => {
  return dispatch => {
    dispatch({
      type: "CUSTOMER_CHANGE_CITY",
      value: value
    });
  };
};
Actions.onChangeStreet = value => {
  return dispatch => {
    dispatch({
      type: "CUSTOMER_CHANGE_STREET",
      value: value
    });
  };
};
Actions.onChangeBuilding = value => {
  return dispatch => {
    dispatch({
      type: "CUSTOMER_CHANGE_BUILDING",
      value: value
    });
  };
};

// priorities
Actions.onChangeResponseTime = (id, value) => {
  return dispatch => {
    dispatch({
      type: "CUSTOMER_CHANGE_TICKET_PRIORITY_RESPONSE_TIME",
      id: id,
      value: value
    });
  };
};

Actions.onChangeUseOpenHours = (id, value) => {
  return dispatch => {
    dispatch({
      type: "CUSTOMER_CHANGE_TICKET_PRIORITY_USE_OPEN_HOURS",
      id: id,
      value: value
    });
  };
};
// settings
Actions.onChangeTicketLogs = (value) => {
  return (dispatch) => {
    dispatch({
      type: "CUSTOMER_CHANGE_TICKET_LOGS",
      value: value,
    });
  };
};

Actions.onChangeTaskLogs = (value) => {
  return (dispatch) => {
    dispatch({
      type: "CUSTOMER_CHANGE_TASK_LOGS",
      value: value,
    });
  };
};

Actions.onChangeIndoundEmails = (value) => {
  return (dispatch) => {
    dispatch({
      type: "CUSTOMER_CHANGE_INBOUND_EMAILS",
      value: value,
    });
  };
};

Actions.onChangeInboundDomain = (value) => {
  return (dispatch) => {
    dispatch({
      type: "CUSTOMER_CHANGE_INBOUND_DOMAIN",
      value: value,
    });
  };
};

Actions.onChangeInboundDomainTest = (value) => {
  return (dispatch) => {
    dispatch({
      type: "CUSTOMER_CHANGE_INBOUND_DOMAIN_TEST",
      value: value,
    });
  };
};

Actions.onChangeInboundEmail = (value) => {
  return (dispatch) => {
    dispatch({
      type: "CUSTOMER_CHANGE_INBOUND_EMAIL",
      value: value,
    });
  };
};

Actions.onChangeInboundEmailTest = (value) => {
  return (dispatch) => {
    dispatch({
      type: "CUSTOMER_CHANGE_INBOUND_EMAIL_TEST",
      value: value,
    });
  };
};

Actions.onChangeInboundEmailMember = (value) => {
  return (dispatch) => {
    dispatch({
      type: "CUSTOMER_CHANGE_INBOUND_EMAIL_MEMBER",
      value: value,
    });
  };
};

Actions.onChangeUseAutoRate = (value) => {
  return (dispatch) => {
    dispatch({
      type: "CUSTOMER_CHANGE_USE_AUTO_RATE",
      value: value,
    });
  };
};

Actions.onChangeAllowAllOrderTasks = (value) => {
  return (dispatch) => {
    dispatch({
      type: "CUSTOMER_CHANGE_ALLOW_ALL_ORDER_TASKS",
      value: value,
    });
  };
};

Actions.onChangeCompletionCommentRequired = (value) => {
  return (dispatch) => {
    dispatch({
      type: "CUSTOMER_CHANGE_COMPLETION_COMMENT_REQUIRED",
      value: value,
    });
  };
};

export default Actions;
