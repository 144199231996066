// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import update from "immutability-helper";
import {
  List,
  Form,
  Button,
  Avatar,
  Divider,
  Tooltip
} from "antd";

import ContactModal from "./ContactModal";



class Contacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      contact: {}
    };
  }

  // modal
  onOpenContact = item => {
    this.setState({ contact: item }, () => {
      this.setState({ isVisible: true });
    });
  };

  onAddContact = () => {
    this.setState(
      {
        contact: {
          id: null,
          name: null,
          avatar: null,
          url: null,
          title: null,
          birthday: null,
          phone: null,
          email: null,
          is_modified: true
        }
      },
      () => {
        this.setState({ isVisible: true });
      }
    );
  };

  onSaveContact = () => {
    this.setState({ isVisible: false }, () => {
      this.props.onSaveContact(this.state.contact);
    });
  };

  onChangeName = e => {
    this.setState({
      contact: update(this.state.contact, {
        name: { $set: e.target.value }
      })
    });
  };

  onChangeAvatar = image => {
    this.setState({
      contact: update(this.state.contact, {
        url: { $set: image },
        avatar: { $set: image },
        is_modified: { $set: true }
      })
    });
  };

  onChangeTitle = e => {
    this.setState({
      contact: update(this.state.contact, {
        title: { $set: e.target.value }
      })
    });
  };
  onChangeBirthday = value => {
    this.setState({
      contact: update(this.state.contact, {
        birthday: { $set: value }
      })
    });
  };
  onChangePhone = e => {
    this.setState({
      contact: update(this.state.contact, {
        phone: { $set: e.target.value }
      })
    });
  };
  onChangeEmail = e => {
    this.setState({
      contact: update(this.state.contact, {
        email: { $set: e.target.value }
      })
    });
  };

  onCancel = () => {
    this.setState({ isVisible: false });
  };

  onOpenAddingAssignee = () => {};

  render() {
    const { isVisible, contact } = this.state;
    const { isNew, isContactsLoading, contacts, onDeleteContact } = this.props;

    return (
      <React.Fragment>
        <Divider>
          Контакты{" "}
          {isNew ? null : (
            <Tooltip placement="bottom" title={"Добавить контакт"}>
              <Button
                type="dashed"
                shape="circle"
                icon="plus"
                onClick={this.onAddContact}
              />
            </Tooltip>
          )}
        </Divider>
        <List
          loading={isContactsLoading}
          itemLayout="horizontal"
          dataSource={contacts}
          renderItem={item => (
            <List.Item
              actions={[
                <Button
                  type="dashed"
                  shape="circle"
                  icon="close"
                  onClick={onDeleteContact.bind(this, item.id)}
                />
              ]}
            >
              <List.Item.Meta
                avatar={<Avatar src={item.avatar ? item.avatar : null} />}
                title={
                  <a onClick={this.onOpenContact.bind(this, item)}>
                    {item.name}
                  </a>
                }
                description={`${item.title}`}
              />
              <div>{item.phone ? item.phone : ""}</div>
            </List.Item>
          )}
        />
        <ContactModal
          visible={isVisible}
          contact={contact}
          onSave={this.onSaveContact}
          onCancel={this.onCancel}
          onChangeName={this.onChangeName}
          onChangeAvatar={this.onChangeAvatar}
          onChangeTitle={this.onChangeTitle}
          onChangeBirthday={this.onChangeBirthday}
          onChangePhone={this.onChangePhone}
          onChangeEmail={this.onChangeEmail}
        />
      </React.Fragment>
    );
  }
}
Contacts.propTypes = {
  contacts: PropTypes.arrayOf(PropTypes.object)
};

export default Contacts;
