// @flow
import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import Actions from "../../../actions/orders";
import FaqActions from "../../../actions/faqs";
import OrdersList from "./List";

class Orders extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.onSetFilters()).then(() => {
      this.props.dispatch(Actions.fetchOrders());
    });
  };

  onDelete = ids => {
    ids.map(id => {
      this.props.dispatch(Actions.onDelete(id)).then(() => {
        this.props.dispatch(Actions.fetchOrders());
      });
    });
  };

  onSearch = e => {
    this.props
      .dispatch(Actions.onSearch(e.target.value))
      .then(() => this.props.dispatch(Actions.fetchOrders()));
  };

  onChangePage = value => {
    this.props.dispatch(Actions.onChangePage(value)).then(() =>
      this.props.dispatch(Actions.fetchOrders()).then(() => {
        localStorage.setItem(
          "ordersFilters",
          JSON.stringify(this.props.fetchParams)
        );
      })
    );
  };

  onChangeLimit = (current, size) => {
    this.props.dispatch(Actions.onChangeLimit(size)).then(() =>
      this.props.dispatch(Actions.fetchOrders()).then(() => {
        localStorage.setItem(
          "ordersFilters",
          JSON.stringify(this.props.fetchParams)
        );
      })
    );
  };

  onSortBy = (field, direction) => {
    this.props.dispatch(Actions.onSortBy(field, direction)).then(() =>
      this.props.dispatch(Actions.fetchOrders()).then(() => {
        localStorage.setItem(
          "ordersFilters",
          JSON.stringify(this.props.fetchParams)
        );
      })
    );
  };

  // filters
  onClearFilters = () => {
    this.props.dispatch(Actions.onClearFilters()).then(() =>
      this.props.dispatch(Actions.fetchOrders()).then(() => {
        localStorage.setItem(
          "ordersFilters",
          JSON.stringify(this.props.fetchParams)
        );
      })
    );
  };

  onChangeFilterStatus = values => {
    this.props.dispatch(Actions.onChangeFilterStatus(values)).then(() => {
      this.props.dispatch(Actions.fetchOrders()).then(() => {
        localStorage.setItem(
          "ordersFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
    });
  };

  onChangeFilterOrderTaskStatus = (value, {key}) => {
    this.props.dispatch(Actions.onChangeFilterOrderTaskStatus(key, value)).then(() =>
      this.props.dispatch(Actions.fetchOrders()).then(() => {
        localStorage.setItem(
          "ordersFilters",
          JSON.stringify(this.props.fetchParams)
        );
      })
    );
  };

  onChangeFilterOrdered = (value) => {
    this.props
      .dispatch(Actions.onChangeFilterOrdered(value))
      .then(() => this.props.dispatch(Actions.fetchOrders()))
      .then(() => {
        localStorage.setItem(
          "ordersFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeFilterPeriod = (value, dateString) => {
    this.props
      .dispatch(Actions.onChangeFilterPeriod(dateString[0], dateString[1]))
      .then(() =>
        this.props.dispatch(Actions.fetchOrders()).then(() => {
          localStorage.setItem(
            "ordersFilters",
            JSON.stringify(this.props.fetchParams)
          );
        })
      );
  };

  onChangeFilterPeriodFinish = (value, dateString) => {
    this.props
      .dispatch(Actions.onChangeFilterPeriodFinish(dateString[0], dateString[1]))
      .then(() =>
        this.props.dispatch(Actions.fetchOrders()).then(() => {
          localStorage.setItem(
            "ordersFilters",
            JSON.stringify(this.props.fetchParams)
          );
        })
      );
  };

  onChangeFilterUsers = values => {
    let users = [];
    values.map(value => {
      users.push({ id: String(value.id), name: String(value.name) });
    });
    // console.log(values)
    this.props.dispatch(Actions.onChangeFilterUsers(users)).then(() =>
      this.props.dispatch(Actions.fetchOrders()).then(() => {
        localStorage.setItem(
          "ordersFilters",
          JSON.stringify(this.props.fetchParams)
        );
      })
    );
  };

  onChangeFilterCustomer = (value) => {
    this.props
      .dispatch(Actions.onChangeFilterCustomer(value))
      .then(() => this.props.dispatch(Actions.fetchOrders()))
      .then(() => {
        localStorage.setItem(
          "ordersFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeFilterMembers = values => {
    this.props.dispatch(Actions.onChangeFilterMembers(values)).then(() =>
      this.props.dispatch(Actions.fetchOrders()).then(() => {
        localStorage.setItem(
          "ordersFilters",
          JSON.stringify(this.props.fetchParams)
        );
      })
    );
  };

  onChangeFilterServices = (values) => {
    // console.log(values)
    this.props
      .dispatch(Actions.onChangeFilterServices(values))
      .then(() => this.props.dispatch(Actions.fetchOrders()))
      .then(() => {
        localStorage.setItem(
          "ordersFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  downloadReport = () => {
    let params = this.props.fetchParams;
    
    Actions.downloadReport(
      params,
      "orders.xlsx",
      params.startDate
        ? `orders ${params.startDate}-${params.endDate}.xlsx`
        : "orders.xlsx"
    );
  };

  // notices
  onClearNotices = () => {
    this.props.dispatch(Actions.onClearNotices());
  };

  // faqs
  onFaqToggle = () => {
    this.props.dispatch(FaqActions.onDrawerToggle()).then(() => {
      this.props.dispatch(FaqActions.fetchFaqs(20, 1))
    });
  };

  render() {
    const {
      currentUser,
      isLoading,
      orders,
      meta,
      fetchParams,
      notices
    } = this.props;
    return (
      <OrdersList
        currentUser={currentUser}
        loading={isLoading}
        orders={orders}
        meta={meta}
        notices={notices}
        fetchParams={fetchParams}
        onDelete={this.onDelete}
        onSearch={this.onSearch}
        onChangePage={this.onChangePage}
        onChangeLimit={this.onChangeLimit}
        onSortBy={this.onSortBy}
        // filters
        onClearFilters={this.onClearFilters}
        onChangeFilterStatus={this.onChangeFilterStatus}
        onChangeFilterOrderTaskStatus={this.onChangeFilterOrderTaskStatus}
        onChangeFilterOrdered={this.onChangeFilterOrdered}
        onChangeFilterPeriod={this.onChangeFilterPeriod}
        onChangeFilterPeriodFinish={this.onChangeFilterPeriodFinish}
        onChangeFilterUsers={this.onChangeFilterUsers}
        onChangeFilterCustomer={this.onChangeFilterCustomer}
        onChangeFilterMembers={this.onChangeFilterMembers}
        onChangeFilterServices={this.onChangeFilterServices}
        downloadReport={this.downloadReport}
        // notices
        onClearNotices={this.onClearNotices}
        // faqs
        onFaqToggle={this.onFaqToggle}
      />
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.session.currentUser,
  isLoading: state.orders.isLoading,
  orders: state.orders.orders,
  meta: state.orders.meta,
  fetchParams: state.orders.fetchParams,
  notices: state.orders.notices
});

export default connect(mapStateToProps)(Orders);
