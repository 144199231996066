// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, Row, Col, Form, Select } from "antd";
import moment from "moment";
import "moment/locale/ru";

const FormItem = Form.Item;
const Option = Select.Option;

class Sidebar extends Component {
  render() {
    const {
      getFieldDecorator,
      currentUser,
      postAvailable,
      onChangePostAvailable,
      postUserRoles,
      onChangePostUserRoles
    } = this.props;

    return (
      <Card style={{ borderColor: "#1890ff" }}>
        <Row gutter={10}>
          <Col span={24}>
            <FormItem label={"Опубликовать для"}>
              {getFieldDecorator("postAvailable", {
                initialValue: postAvailable,
                onChange: onChangePostAvailable,
                rules: [
                  {
                    required: true,
                    message: "Введите для кого создан пост!"
                  }
                ]
              })(
                <Select
                  placeholder="Опубликовать для"
                  optionFilterProp="children"
                  filterOption={false}
                >
                  <Option value={"for_all"}>всех</Option>
                  <Option value={"users"}>пользователей</Option>
                  <Option value={"members"}>пользователей клиента</Option>
                </Select>
              )}
            </FormItem>
          </Col>
          {postAvailable == "users" ? (
            <Col span={24}>
              <FormItem label={"Роли"}>
                {getFieldDecorator("postUserRoles", {
                  initialValue: postUserRoles,
                  onChange: onChangePostUserRoles,
                  rules: [
                    {
                      required: true,
                      message: "Введите для кого создан пост!"
                    }
                  ]
                })(
                  <Select
                    mode={"multiple"}
                    placeholder="Роли"
                    optionFilterProp="children"
                    filterOption={false}
                  >
                    <Option value={1}>администратор</Option>
                    <Option value={2}>руководитель</Option>
                    <Option value={3}>инженер</Option>
                  </Select>
                )}
              </FormItem>
            </Col>
          ) : null}
        </Row>
      </Card>
    );
  }
}
Sidebar.propTypes = {
  customer: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  onAdressEdit: PropTypes.func,
  onChangePaymentType: PropTypes.func,
  onChangePaymentStatus: PropTypes.func,
  onChangeShippingStatus: PropTypes.func,
  onChangeShippingMethod: PropTypes.func
};

export default Sidebar;
