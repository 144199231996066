// @flow
import React, {Component} from "react";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Actions from "../../../actions/orderTasks";
import LoadingSpin from "../../../components/LoadingSpin";
import Form from "./Form";
import {encodeBase64} from "../../../util/attachments";
import moment from "moment";

class OrderTask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: false,
    };
  }

  componentWillMount = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.fetchOrderTask(this.props.match.params.id))
        .then(() =>
          this.props.dispatch(Actions.fetchOrderTaskTodos(this.props.match.params.id)));
    } else {
      this.setState({isNew: true}, () => {
        this.props.dispatch(Actions.onNew());
      });
    }
  };

  onClose = () => {
    this.props.history.push(`/order_tasks`);
  };

  onChangeSubject = (value) => {
    this.props.dispatch(
      Actions.onChangeSubject(this.props.match.params.id, value)
    );
  };

  onChangeDescription = (value) => {
    this.props.dispatch(
      Actions.onChangeDescription(this.props.match.params.id, value)
    );
  };

  onChangeDueFrom = (value) => {
    this.props.dispatch(
      Actions.onChangeDueFrom(this.props.match.params.id, value)
    );
  };

  onChangeDueBy = (value) => {
    this.props.dispatch(
      Actions.onChangeDueBy(this.props.match.params.id, value)
    );
  };

  onChangePlanDays = (value) => {
    this.props.dispatch(
      Actions.onChangePlanDays(this.props.match.params.id, value)
    );
  };

  // comments
  onCreateComment = (comment, mentioned) => {
    this.props.dispatch(Actions.onCreateComment(this.props.match.params.id, comment, mentioned))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchOrderTaskComments(this.props.match.params.id)
          );
        }
      });
  };

  // assignees
  onCreateAssignee = (value, type) => {
    this.props
      .dispatch(
        Actions.onCreateAssignee(this.props.match.params.id, value, type)
      )
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAssignees(this.props.match.params.id)
          );
        }
      });
  };

  onDeleteAssignee = (id) => {
    this.props
      .dispatch(Actions.onDeleteAssignee(this.props.match.params.id, id))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAssignees(this.props.match.params.id)
          );
        }
      });
  };

  // attachments
  onDeleteAttachment = (id) => {
    this.props
      .dispatch(Actions.onDeleteAttachment(this.props.match.params.id, id))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAttachments(this.props.match.params.id)
          );
        }
      });
  };

  onUploadAttachment = (file) => {
    encodeBase64(file, (fileUrl) =>
      this.props
        .dispatch(Actions.onUploadAttachment(
          this.props.match.params.id,
          {
            id: file.uid,
            name: file.name,
            content_type: file.type,
            file_size: file.size,
            attachment: fileUrl,
          }))
        .then(() => {
          if (!this.props.errors) {
            this.props.dispatch(
              Actions.fetchAttachments(this.props.match.params.id)
            );
          }
        })
    );
  };

  onUploadCommentAttachment = (commentId, file) => {
    encodeBase64(file, (fileUrl) =>
      this.props
        .dispatch(Actions.onUploadAttachment(
          this.props.match.params.id,
          {
            id: file.uid,
            name: file.name,
            content_type: file.type,
            file_size: file.size,
            attachment: fileUrl,
          },
          commentId))
        .then(() => {
          if (!this.props.errors) {
            this.props.dispatch(
              Actions.fetchAttachments(this.props.match.params.id)
            );
          }
        })
    );
  };

  // activities
  fetchTasksActivities = () => {
    this.props.dispatch(
      Actions.fetchTasksActivities(this.props.match.params.id)
    );
  };

  // statuses
  onChangeStatus = (value, comment) => {
    const {orderTask} = this.props;
    const statusPostUrl = orderTask.status_post_url ? orderTask.status_post_url : 'status'
    this.props
      .dispatch(
        Actions.onChangeStatus(this.props.match.params.id, value.id, statusPostUrl, comment)
      )
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchOrderTaskComments(this.props.match.params.id)
          );
        }
      });
  };

  onChangeServices = (selectedServices) => {
    this.props.dispatch(Actions.createOrderTaskTodos(this.props.match.params.id, selectedServices))
      .then(() => {
        if (this.props.errors) {
          return
        }

        this.props.dispatch(
          Actions.fetchOrderTaskTodos(this.props.match.params.id));
      })
  };

  // todos
  onSaveTodoItemComment = (todoId, todoIdx, todoItemId, todoItemIdx, comment) => {
    this.props.dispatch(
      Actions.updateOrderTaskTodoItemComment(todoId, todoIdx, todoItemId, todoItemIdx, comment));
  };

  onCompleteTodoItem = (todoId, todoIdx, todoItemId, todoItemIdx, completed) => {
    this.props.dispatch(
      Actions.updateOrderTaskTodoItemCompletedAt(todoId, todoIdx, todoItemId, todoItemIdx, completed ? moment() : null));
  }

  render() {
    const {
      currentUser,
      isLoading,
      errors,
      isActivitiesLoading,
      isAttachmentLoading,
      isAssigneesLoading,
      orderTask,
      taskActivities,
    } = this.props;

    const services = orderTask.order_task_todos?.map((it) => it.service) || [];

    return isLoading ? (
      <LoadingSpin/>
    ) : (
      <Form
        isNew={this.state.isNew}
        currentUser={currentUser}
        orderTask={orderTask}
        onClose={this.onClose}
        onChangeSubject={this.onChangeSubject}
        onChangeDescription={this.onChangeDescription}
        onChangeDueFrom={this.onChangeDueFrom}
        onChangeDueBy={this.onChangeDueBy}
        onChangePlanDays={this.onChangePlanDays}
        services={services}
        onChangeServices={this.onChangeServices}
        // comments
        onCreateComment={this.onCreateComment}
        // assignees
        isAssigneesLoading={isAssigneesLoading}
        onCreateAssignee={this.onCreateAssignee}
        onDeleteAssignee={this.onDeleteAssignee}
        // attachments
        isAttachmentLoading={isAttachmentLoading}
        onDeleteAttachment={this.onDeleteAttachment}
        onUploadAttachment={this.onUploadAttachment}
        onUploadCommentAttachment={this.onUploadCommentAttachment}
        onDeleteCommentAttachment={this.onDeleteAttachment}
        // activities
        isActivitiesLoading={isActivitiesLoading}
        taskActivities={taskActivities}
        fetchTasksActivities={this.fetchTasksActivities}
        // statuses
        onChangeStatus={this.onChangeStatus}
        // todos
        onSaveTodoItemComment={this.onSaveTodoItemComment}
        onCompleteTodoItem={this.onCompleteTodoItem}
      />
    );
  }
}

OrderTask.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  orderTask: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  isLoading: state.orderTasks.isLoading,
  errors: state.orderTasks.errors,
  orderTask: state.orderTasks.orderTask,
  taskActivities: state.orderTasks.taskActivities,
  isActivitiesLoading: state.orderTasks.isActivitiesLoading,
  isAttachmentLoading: state.orderTasks.isAttachmentLoading,
});

export default connect(mapStateToProps)(withRouter(OrderTask));
