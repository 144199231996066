import update from "immutability-helper";

const initialState = {
  services: {
    tasks: false,
    orders: false,
    tickets: false,
    payrolls: false,
    projects: false,
    entity_tasks: false,
  },
  errors: false,
  isLoading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "FETCH_SETTINGS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_SETTINGS":
      return {
        ...state,
        services: action.response.data,
        isLoading: false,
      };
    case "FETCH_SETTINGS_FAILURE":
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}
