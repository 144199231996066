// @flow
import React, { Component, Fragment } from "react";
import { Row, Col } from "antd";
import { Chart, Interval, Tooltip, Legend } from "bizcharts";

import accounting from "accounting";
import moment from "moment";
import "moment/locale/ru";

class ProjectBar extends Component {
  render() {
    const { currentUser, fetchParams, data } = this.props;

    // let series = [];
    let series = [];

    data.map((item) => {
      series.push({
        period: moment(item.period).format("DD.MM"),
        amount: Number(item.amount),
      });
    });

    // const series = [
    //   { year: "1", "сумма": 38 },
    //   { year: "2", "сумма": 52 },
    //   { year: "3", "сумма": 61 },
    //   { year: "4", "сумма": 45 },
    //   { year: "5", "сумма": 48 },
    //   { year: "6", "сумма": 38 },
    //   { year: "7", "сумма": 38 },
    //   { year: "8", "сумма": 38 },
    // ];

    return (
      <Row gutter={1}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Chart
            title="Проекты"
            title={{
              visible: true,
              text: "Проекты",
            }}
            height={400}
            autoFit
            data={series}
            interactions={["active-region"]}
            padding={[30, 30, 30, 50]}
          >
            <Interval position="period*amount" />
            <Legend position="bottom" />
            <Tooltip shared />
          </Chart>
        </Col>
      </Row>
    );
  }
}

export default ProjectBar;
