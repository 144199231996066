// @flow
import React, {Component} from "react";
import {connect} from "react-redux";
import {compose} from "redux";
import {Link, withRouter} from "react-router-dom";
import Actions from "../../../../actions/sections";
import {Button, Card, Modal, List, Icon, Tag, Row, Col, Descriptions} from "antd";
import {DeleteOutline} from "@ant-design/icons";
import Can from "../../../../rules/Can";

const {confirm} = Modal;

class Sections extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.fetchSections());
  };

  onDelete = (id) => {
    let props = this.props;
    confirm({
      title: "Раздел",
      content: "Удалить раздел и все статьи раздела?",
      onOk() {
        props.dispatch(Actions.deleteSection(id)).then(() => {
          props.dispatch(Actions.fetchSections());
        });
      },
      onCancel() {
      },
    });
  };

  // onChangePage = value => {
  //   this.props
  //     .dispatch(Actions.onChangePage(value))
  //     .then(() => this.props.dispatch(Actions.fetchFaqs()));
  // };
  //
  // onChangeLimit = (current, size) => {
  //   this.props
  //     .dispatch(Actions.onChangeLimit(size))
  //     .then(() => this.props.dispatch(Actions.fetchFaqs()));
  // };
  //
  // onSortBy = (field, direction) => {
  //   this.props
  //     .dispatch(Actions.onSortBy(field, direction))
  //     .then(() => this.props.dispatch(Actions.fetchFaqs()));
  // };

  render() {
    const {
      currentUser,
      isLoading,
      fetchParams,
      sections
    } = this.props;

    let renderSectionArticles = (section) => {
      if (!section.articles) {
        return []
      }

      return section.articles.slice(0, 4).map((article) => {
        return (
          <Row
            key={article.id}
            hoverable={"false"}
          >
            <Link
              to={`/sections/${section.id}/articles/${article.id}`}>
              <h3>{`${article.title}`}</h3>
            </Link>
          </Row>
        );
      });
    };

    let renderSections = sections.map((section, index) => {
      return (
        <Row
          key={section.id}>
          <Col span={24}>
            <Card
              title={
                <>
                  <Row>
                    <Link to={`/sections/${section.id}`}>
                      <h2>{`${section.title}`}</h2>
                    </Link>
                    {`(Всего статей: ${section.articles.length})`}
                  </Row>
                </>
              }
              extra={
                [
                  <Can
                    key={"1"}
                    role={currentUser.role}
                    perform={"sections:delete"}
                    yes={() =>
                      <Button
                        type="danger"
                        ghost
                        onClick={() => this.onDelete(section.id)}>
                        <Icon
                          type={"delete"}/>
                        {" Удалить"}
                      </Button>
                    }
                    no={() => null}
                  />
                ]
              }
            >
              {renderSectionArticles(section)}
              {section.articles.length > 4 ?
                <Row>
                  <h3>
                    <Link to={`/sections/${section.id}`}>
                      {`Все статьи`}
                    </Link>
                  </h3>
                </Row> :
                null
              }
            </Card>
          </Col>
        </Row>
      );
    });

    return (
      <>
        <Can
          role={currentUser.role}
          perform={"sections:create"}
          yes={() =>
            <Card>
              <Link
                to={{pathname: `/sections/new`}}
                className={"ant-btn"}
              >
                {"Новый раздел"}
              </Link>
            </Card>
          }
          no={() => null}
        />
        {renderSections}
      </>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.session.currentUser,
  isLoading: state.sections.isLoading,
  fetchParams: state.sections.fetchParams,
  meta: state.sections.meta,
  sections: state.sections.sections
});

export default compose(
  withRouter,
  connect(mapStateToProps)
)(Sections);
