// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Col, Row, Form, Card, Input, DatePicker } from "antd";
import moment from "moment";
import "moment/locale/ru";
import SelectMultiFetch from "../../../../components/SelectMultiFetch";

const FormItem = Form.Item;
const { TextArea } = Input;
const { RangePicker } = DatePicker;

class General extends Component {
  render() {
    const {
      getFieldDecorator,
      payroll,
      onChangePeriod,
      onChangeDepartments,
      onChangeDescription,
    } = this.props;

    const dateFormat = "DD.MM.YYYY";

    return (
      <React.Fragment>
        <Row gutter={16}>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <FormItem label={"Период"}>
              {getFieldDecorator("period", {
                onChange: onChangePeriod,
                initialValue: [
                  payroll.startDate ? moment(payroll.startDate) : null,
                  payroll.endDate ? moment(payroll.endDate) : null,
                ],
                rules: [
                  {
                    required: true,
                    message: "Ведите период!",
                  },
                ],
              })(
                <RangePicker
                  style={{ width: "100%" }}
                  format={dateFormat}
                  placeholder={["начало", "окончание"]}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={18} xl={18}>
            <FormItem label={"Подразделения"}>
              {getFieldDecorator("departments", {
                initialValue: payroll.departments ? payroll.departments : [],
                rules: [
                  {
                    required: true,
                    message: "Пожалуйста, введите подразделения!",
                  },
                ],
              })(
                <SelectMultiFetch
                  showSearch
                  showSubtitle={true}
                  placeholder="Подразделения"
                  onChange={onChangeDepartments}
                  selected={payroll.departments ? payroll.departments : []}
                  url={`/departments/search/?`}
                />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <FormItem label={"Описание"}>
              {getFieldDecorator("Описание", {
                onChange: onChangeDescription,
                initialValue: payroll.description,
              })(<TextArea autoSize={{ minRows: 2, maxRows: 6 }} />)}
            </FormItem>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

General.propTypes = {
  entity: PropTypes.object,
};

export default General;
