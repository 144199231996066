// @flow
import React, { Component } from "react";
import { Modal, Row, Col, Form, Input } from "antd";
import PropTypes from "prop-types";

const FormItem = Form.Item;


class ModalForm extends Component {
  onSave = () => {
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }

      this.props.onSave();
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const {
      projectType,
      visible,
      onCancel,
      onChangeName,
    } = this.props;

    return (
      <Modal
        title={`Этап #${projectType.id ? projectType.name : "новый"} `}
        destroyOnClose
        visible={visible}
        width={800}
        onOk={this.onSave}
        onCancel={onCancel}
      >
        <Form layout={"vertical"}>
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <FormItem label={"Наименование"}>
                {getFieldDecorator("name", {
                  onChange: onChangeName,
                  initialValue: projectType.name,
                  rules: [
                    {
                      required: true,
                      message: "Введите наименование!"
                    }
                  ]
                })(<Input placeholder="введите наименование" />)}
              </FormItem>
            </Col>
            
            
          </Row>
        </Form>
      </Modal>
    );
  }
}

ModalForm.propTypes = {
  projectType: PropTypes.object
};

export default Form.create()(ModalForm);
