// @flow
import React, { Component } from "react";
import { Popover, Table, Button, Tooltip } from "antd";
import moment from "moment";
import "moment/locale/ru";
import api from "../../api";

export default class EntityOpeningHours extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      fetching: true,
    };
  }

  fetchOpeningHours = async (id) => {
    // console.log(id)
    const response = await api.fetch(`/entities/${id}/entity_opening_hours`);
    const data = response.data;
    this.setState({ data, fetching: false });
  };

  render() {
    const { fetching, data } = this.state;
    const { title, entityId, tooltip, icon } = this.props;
    return (
      <Popover
        placement="right"
        content={<Hours loading={fetching} data={data} />}
        title="Расписание"
        trigger="click"
      >
        {icon ? (
          <Tooltip placement="top" title={tooltip}>
            <Button
              type="primary"
              shape="circle"
              icon="bank"
              onClick={this.fetchOpeningHours.bind(this, entityId)}
            />
          </Tooltip>
        ) : (
          <a onClick={this.fetchOpeningHours.bind(this, entityId)}>{title}</a>
        )}
      </Popover>
    );
  }
}

function Hours({ loading = true, data = null }) {
  const columns = [
    {
      title: "День",
      dataIndex: "day",
      render: (day) => moment().isoWeekday(day).format("dddd"),
      key: "name",
      align: "left",
      width: "40%",
    },
    {
      title: "C",
      dataIndex: "opens",
      key: "opens",
      align: "center",
      width: "30%",
    },
    {
      title: "По",
      dataIndex: "closes",
      key: "closes",
      align: "center",
      width: "30%",
    },
  ];

  return (
    <div style={{ width: 590 }}>
      <Table
        loading={loading}
        rowClassName={"progress-row"}
        size={"small"}
        // showHeader={false}
        columns={columns}
        dataSource={data}
        pagination={false}
        rowKey="id"
      />
    </div>
  );
}
