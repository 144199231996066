import api from "../api";

const Actions = {};

Actions.fetchProjectStages = () => {
  return (dispatch, getState) => {
    const params = getState().projectStages.fetchParams;
    dispatch({ type: "FETCH_PROJECT_STAGES_REQUEST" });
    api
      .fetch("/project_stages", params)
      .then(response => {
        // console.log(response)
        dispatch({ type: "FETCH_PROJECT_STAGES", response });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({ type: "FETCH_PROJECT_STAGES_FAILURE", response });
      });
  };
};

Actions.fetchProjectStage = id => {
  return dispatch => {
    dispatch({ type: "FETCH_PROJECT_STAGE_REQUEST" });
    return api
      .fetch(`/project_stages/${id}`)
      .then(response => {
        dispatch({ type: "FETCH_PROJECT_STAGE", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Этап проекта", error);
          });
        }
        dispatch({ type: "FETCH_PROJECT_STAGE_FAILURE", response });
      });
  };
};

Actions.onNew = () => {
  return dispatch => {
    dispatch({ type: "PROJECT_STAGE_ADD" });
  };
};

Actions.onUpdate = id => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_PROJECT_STAGE_REQUEST" });
    const projectStage = getState().projectStages.projectStage;

    const data = {
      project_stage: {
        name: projectStage.name,
        description: projectStage.description,
        position: projectStage.position
      }
    };

    return api
      .patch(`/project_stages/${id}`, data)
      .then(response => {
        dispatch({ type: "PATCH_PROJECT_STAGE", response });
        api.openNotification("success", "Этап проекта", "Успешно обновлен!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Этап проекта", error);
          });
        }
        dispatch({ type: "PATCH_PROJECT_STAGE_FAILURE", response });
      });
  };
};

Actions.onCreate = () => {
  // console.log(ids)
  return (dispatch, getState) => {
    dispatch({ type: "POST_PROJECT_STAGE_REQUEST" });
    const projectStage = getState().projectStages.projectStage;

    const data = {
      project_stage: {
        name: projectStage.name,
        description: projectStage.description,
        position: projectStage.position
      }
    };

    return api
      .post(`/project_stages`, data)
      .then(response => {
        dispatch({ type: "POST_PROJECT_STAGE", response });
        api.openNotification("success", "Этап проекта", "Успешно создан!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Этап проекта", error);
          });
        }
        dispatch({ type: "POST_PROJECT_STAGE_FAILURE", response });
      });
  };
};

Actions.onDelete = id => {
  return dispatch => {
    dispatch({ type: "DELETE_PROJECT_STAGE_REQUEST" });
    return api
      .delete(`/project_stages/${id}`)
      .then(response => {
        api.openNotification("success", "Этап проекта", "Успешно удален!");
        dispatch({ type: "DELETE_PROJECT_STAGE", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Этап проекта", error);
          });
        }
        dispatch({
          type: "DELETE_PROJECT_STAGE_FAILURE"
        });
      });
  };
};

Actions.onToggleVisible = () => {
  return dispatch => {
    dispatch({
      type: "PROJECT_STAGE_TOGGLE_VISIBLE"
    });
  };
};

Actions.onSearch = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_PROJECT_STAGE_SEARCH",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangePage = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_PROJECT_STAGE_CHANGE_PAGE",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangeName = value => {
  return dispatch => {
    dispatch({
      type: "PROJECT_STAGE_CHANGE_NAME",
      value: value
    });
  };
};

Actions.onChangeDescription = value => {
  return dispatch => {
    dispatch({
      type: "PROJECT_STAGE_CHANGE_DESCRIPTION",
      value: value
    });
  };
};

Actions.onChangePosition = value => {
  return dispatch => {
    dispatch({
      type: "PROJECT_STAGE_CHANGE_POSITION",
      value: value
    });
  };
};



export default Actions;
