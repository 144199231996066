import update from "immutability-helper";
import moment from "moment";
import "moment/locale/ru";

const initialState = {
  isLoading: true,
  fetchParams: {},
  meta: {},
  errors: false,
  sections: [],
  section: {},
  permissions: []
};

export default function (state = initialState, action) {
  let permissionIndex = -1;
  switch (action.type) {
    case "FETCH_SECTIONS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_SECTIONS":
      return {
        ...state,
        isLoading: false,
        sections: action.response.data,
      };
    case "FETCH_SECTION_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_SECTION":
      return {
        ...state,
        isLoading: false,
        section: action.response.data,
        permissions: action.response.data.permissions
      };
    case "NEW_SECTION":
      return {
        ...state,
        isLoading: false,
        errors: false,
        section: {},
        permissions: [
          {enabled: true, role: 'administrator'},
          {enabled: true, role: 'manager'},
          {enabled: true, role: 'engineer'},
          {enabled: true, role: 'supervisor'},
          {enabled: true, role: 'simple'}
        ],
      };
    case "CHANGE_SECTION_TITLE":
      return update(state, {
        section: {title: {$set: action.value}},
      });
    case "CHANGE_SECTION_PERMISSION_FOR_ADMINISTRATOR":
      permissionIndex = state.permissions.findIndex((it) => it.role === 'administrator');
      if (permissionIndex === -1) {
        return {
          ...state,
          permissions: [...state.permissions, {enabled: action.value, role: 'administrator'}]
        }
      }

      return update(state, {
        permissions: {
          [permissionIndex]: {
            enabled: {
              $set: action.value
            }
          }
        },
      });
    case "CHANGE_SECTION_PERMISSION_FOR_MANAGER":
      permissionIndex = state.permissions.findIndex((it) => it.role === 'manager');
      if (permissionIndex === -1) {
        return {
          ...state,
          permissions: [...state.permissions, {enabled: action.value, role: 'manager'}]
        }
      }

      return update(state, {
        permissions: {
          [permissionIndex]: {
            enabled: {
              $set: action.value
            }
          }
        },
      });
    case "CHANGE_SECTION_PERMISSION_FOR_ENGINEER":
      permissionIndex = state.permissions.findIndex((it) => it.role === 'engineer');
      if (permissionIndex === -1) {
        return {
          ...state,
          permissions: [...state.permissions, {enabled: action.value, role: 'engineer'}]
        }
      }

      return update(state, {
        permissions: {
          [permissionIndex]: {
            enabled: {
              $set: action.value
            }
          }
        },
      });
    case "CHANGE_SECTION_PERMISSION_FOR_SUPERVISOR":
      permissionIndex = state.permissions.findIndex((it) => it.role === 'supervisor');
      if (permissionIndex === -1) {
        return {
          ...state,
          permissions: [...state.permissions, {enabled: action.value, role: 'supervisor'}]
        }
      }

      return update(state, {
        permissions: {
          [permissionIndex]: {
            enabled: {
              $set: action.value
            }
          }
        },
      });
    case "CHANGE_SECTION_PERMISSION_FOR_SIMPLE":
      permissionIndex = state.permissions.findIndex((it) => it.role === 'simple');
      if (permissionIndex === -1) {
        return {
          ...state,
          permissions: [...state.permissions, {enabled: action.value, role: 'simple'}]
        }
      }

      return update(state, {
        permissions: {
          [permissionIndex]: {
            enabled: {
              $set: action.value
            }
          }
        },
      });
    case "POST_SECTION_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };
    case "POST_SECTION_SUCCEED":
      return {
        ...state,
        isLoading: false,
        section: action.response.data,
        permissions: action.response.data.permissions,
        errors: false,
      };
    case "POST_SECTION_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };
    case "PATCH_SECTION_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };

    case "PATCH_SECTION_SUCCEED":
      return {
        ...state,
        isLoading: false,
        section: action.response.data,
        permissions: action.response.data.permissions,
        errors: false,
      };

    case "PATCH_SECTION_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };
    case "DELETE_SECTION_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };
    case "DELETE_SECTION":
      return {
        ...state,
        isLoading: false,
        errors: false,
      };
    case "DELETE_SECTION_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };
    default:
      return state;
  }
}
