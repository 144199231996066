// @flow
import React, {Component} from "react";
import PropTypes from "prop-types";
import {Row, Col, Button, Form, Card} from "antd";
import {FormInstance} from 'antd/lib/form';
import Header from "./Header";
import Sidebar from "./Sidebar";
import moment from "moment";
import "moment/locale/ru";

class SectionForm extends Component {
  constructor(props) {
    super(props);
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }

      this.props.onSave(values);
    });
  };

  render() {
    const {
      errors,
      isLoading,
      section,
      permissions,
      onSave,
      onClose,
      onChangeTitle,
      // sidebar
      onChangePermissionForAdministrator,
      onChangePermissionForManager,
      onChangePermissionForEngineer,
      onChangePermissionForSupervisor,
      onChangePermissionForSimple,
    } = this.props;

    const {getFieldDecorator} = this.props.form;

    return (
      <Form
        layout={"vertical"}>
        <Card
          loading={isLoading}
          extra={[
            <Button
              key={"1"}
              onClick={onClose}>
              Закрыть
            </Button>,
            <Button
              key={"2"}
              onClick={this.onSubmit}
              type="primary"
              ghost
              style={{ marginLeft: "8px" }}
            >
              Сохранить
            </Button>
          ]}>
          <Row gutter={{md: 8, lg: 24, xl: 48}}>
            <Col span={16}>
              <Header
                title={section.title}
                onChangeTitle={onChangeTitle}
                getFieldDecorator={getFieldDecorator}
              />
            </Col>
            <Col span={8}>
              <Sidebar
                permissions={permissions}
                onChangePermissionForAdministrator={onChangePermissionForAdministrator}
                onChangePermissionForManager={onChangePermissionForManager}
                onChangePermissionForEngineer={onChangePermissionForEngineer}
                onChangePermissionForSupervisor={onChangePermissionForSupervisor}
                onChangePermissionForSimple={onChangePermissionForSimple}
                getFieldDecorator={getFieldDecorator}
              />
            </Col>
          </Row>
        </Card>
      </Form>
    );
  }
}

SectionForm.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  section: PropTypes.object,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
  router: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  form: PropTypes.object,
};

export default Form.create()(SectionForm);
