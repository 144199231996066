// @flow
import React, {Component} from "react";
import PropTypes from "prop-types";
import {Button, Col, Input, List, Row} from "antd";
import {v4 as uuidv4} from "uuid";

class ExtCodes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddingNewExtCode: false,
      newExtCode: null,
    };
  }

  onAppendNewExtCode = () => {
    this.setState({
      isAddingNewExtCode: true
    });
  };

  onChangeNewExtCode = (e) => {
    this.setState({
      newExtCode: e.target.value
    });
  };

  onSubmitNewExtCode = () => {
    this.props.onAppendExtCode({
      id: uuidv4(),
      code: this.state.newExtCode,
      is_exist: false,
      _destroy: false,
    });

    this.setState({
      isAddingNewExtCode: false,
      newExtCode: null
    });
  };

  onCancelNewExtCode = () => {
    this.setState({
      isAddingNewExtCode: false,
      newExtCode: null
    });
  };

  render() {
    const {
      extCodes,
      onDeleteExtCode,
    } = this.props;

    const {
      isAddingNewExtCode,
      newExtCode
    } = this.state;

    return (
      <React.Fragment>
        <List
          itemLayout="horizontal"
          dataSource={extCodes}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Button
                  type="dashed"
                  shape="circle"
                  icon="close"
                  onClick={onDeleteExtCode.bind(this, item.id)}
                />,
              ]}
            >
              <List.Item.Meta title={item.code} />
            </List.Item>
          )}/>
        {isAddingNewExtCode ?
          <Row gutter={10} type="flex" justify="center">
            <Col span={24}>
              <Input value={newExtCode}
                     onChange={this.onChangeNewExtCode}/>
            </Col>
            <Col span={24} style={{ paddingTop: "10px" }}>
              <Row gutter={10} type="flex" justify="center">
                <Button type="danger"
                        ghost
                        onClick={this.onCancelNewExtCode}>
                  Отменить
                </Button>

                <Button
                  disabled={!newExtCode}
                  type="primary"
                  ghost
                  style={{ marginLeft: "10px" }}
                  onClick={this.onSubmitNewExtCode}>
                  Добавить
                </Button>
              </Row>
            </Col>
          </Row> :
          <Button key="addExtcodeButton"
                  onClick={this.onAppendNewExtCode}>
            Добавить
          </Button>
        }
      </React.Fragment>
    );
  }
}

ExtCodes.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object),
};

export default ExtCodes;
