// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, Form, Card } from "antd";
import Header from "./Header";
import Sidebar from "./Sidebar";
import moment from "moment";
import "moment/locale/ru";

class TaskTemplate extends Component {
  constructor(props) {
    super(props);
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onSave(values);
    });
  };

  onPublish = (e) => {
    e.preventDefault();
    this.props.onPublish(true);
  };

  onUnPublish = (e) => {
    e.preventDefault();
    this.props.onPublish(false);
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      isNew,
      errors,
      isLoading,
      currentUser,
      taskTemplate,
      onClose,
      onChangeActive,
      onChangeVisibility,
      onChangeCustomer,
      onChangeName,
      onChangeDescription,
      onChangeOwner,
      onChangeHasTiming,
      onChangeHasTodos,
      onChangeHasAttachments,
      onChangeHasComments,
      onChangeHasSupervisorAssignees,
      onChangeHasUserAssignees,
      onChangeHasMemberAssignees,
      onChangeHasAmount,
      onChangeHasFields,
      onChangeDepartments,
      onChangeCustomers,
      onChangeTodoTemplates,
      onChangeAssigneeFillType,
      onChangeSupervisorFillType,
      onChangeMemberFillType,
      onChangeAssignees,
      onChangeSupervisors,
      onChangeMembers,
      // field_sets
      onSaveFieldSet,
      onDeleteFieldSets,
      // actions
      onChangeActionTemplate,
      onChangeActionTemplateHours,
      onChangeActionTemplateSubject,
    } = this.props;

    return (
      <Form layout={"vertical"}>
        <Card
          loading={isLoading}
          title={
            <h2 style={{ marginBottom: 0 }}>{`Шаблон задач #${
              isNew ? "новый" : taskTemplate.id
            } от ${moment(taskTemplate.created_at).format("LLL")}`}</h2>
          }
          extra={[
            <Button key={1} onClick={onClose}>
              Закрыть
            </Button>,
            <Button
              key={2}
              onClick={this.onSubmit}
              type="primary"
              ghost
              style={{ marginLeft: "8px" }}
            >
              Сохранить
            </Button>,
          ]}
        >
          <Col span={14}>
            <Header
              getFieldDecorator={getFieldDecorator}
              taskTemplate={taskTemplate}
              onChangeCustomer={onChangeCustomer}
              onChangeName={onChangeName}
              onChangeVisibility={onChangeVisibility}
              onChangeDescription={onChangeDescription}
              onChangeOwner={onChangeOwner}
              onChangeDepartments={onChangeDepartments}
              onChangeCustomers={onChangeCustomers}
              onChangeAssignees={onChangeAssignees}
              onChangeSupervisors={onChangeSupervisors}
              onChangeMembers={onChangeMembers}
              // field_sets
              onSaveFieldSet={onSaveFieldSet}
              onDeleteFieldSets={onDeleteFieldSets}
              // actions
              onChangeActionTemplate={onChangeActionTemplate}
              onChangeActionTemplateHours={onChangeActionTemplateHours}
              onChangeActionTemplateSubject={onChangeActionTemplateSubject}
            />
          </Col>
          <Col xs={0} sm={0} md={1} lg={1} xl={1} />
          <Col xs={24} sm={24} md={24} lg={9} xl={9}>
            <Sidebar
              getFieldDecorator={getFieldDecorator}
              taskTemplate={taskTemplate}
              onChangeActive={onChangeActive}
              onChangeHasTiming={onChangeHasTiming}
              onChangeHasTodos={onChangeHasTodos}
              onChangeHasAttachments={onChangeHasAttachments}
              onChangeHasComments={onChangeHasComments}
              onChangeHasSupervisorAssignees={onChangeHasSupervisorAssignees}
              onChangeHasUserAssignees={onChangeHasUserAssignees}
              onChangeHasMemberAssignees={onChangeHasMemberAssignees}
              onChangeHasAmount={onChangeHasAmount}
              onChangeHasFields={onChangeHasFields}
              onChangeTodoTemplates={onChangeTodoTemplates}
              onChangeAssigneeFillType={onChangeAssigneeFillType}
              onChangeSupervisorFillType={onChangeSupervisorFillType}
              onChangeMemberFillType={onChangeMemberFillType}
            />
          </Col>
        </Card>
      </Form>
    );
  }
}

TaskTemplate.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  taskTemplate: PropTypes.object,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
  router: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  form: PropTypes.object,
};

export default Form.create()(TaskTemplate);
