// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import Actions from "../../../actions/entityTypes";
import List from "./List";

class EntityTypes extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.fetchEntityTypes());
  };

  onDelete = ids => {
    ids.map(id => {
      this.props.dispatch(Actions.onDelete(id)).then(() => {
        this.props.dispatch(Actions.fetchEntityTypes());
      });
    });
  };

  fetchEntityType = (id) => {
    this.props.dispatch(Actions.fetchEntityType(id));
  }

  onNew = () => {
    this.props.dispatch(Actions.onNew());
  }

  onChangePage = value => {
    this.props
      .dispatch(Actions.onChangePage(value))
      .then(() => this.props.dispatch(Actions.fetchEntityTypes()));
  };

  onSearch = e => {
    this.props
      .dispatch(Actions.onSearch(e.target.value))
      .then(() => this.props.dispatch(Actions.fetchEntityTypes()));
  };

  render() {
    const { isLoading, isModalVisible, entityTypes, meta, fetchParams } = this.props;
    return (
      <List
        loading={isLoading}
        isModalVisible={isModalVisible}
        entityTypes={entityTypes}
        meta={meta}
        fetchParams={fetchParams}
        onDelete={this.onDelete}
        onSearch={this.onSearch}
        onChangePage={this.onChangePage}
        fetchEntityType={this.fetchEntityType}
        onNew={this.onNew}
      />
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.entityTypes.isLoading,
  isModalVisible: state.entityTypes.isModalVisible,
  entityTypes: state.entityTypes.entityTypes,
  meta: state.entityTypes.meta,
  fetchParams: state.entityTypes.fetchParams
});

export default connect(mapStateToProps)(EntityTypes);
