// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, Timeline, Icon, Button } from "antd";

import moment from "moment";
import "moment/locale/ru";

class Activities extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { activities, isActivitiesLoading } = this.props;

    return (
      <div>
        <Card loading={isActivitiesLoading} size="small" title="Активность">
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Timeline>
                {activities.map(item => {
                  let name = null
                  if (item.user) {
                    name = item.user.name
                  }

                  if (item.member) {
                    name = item.member.name
                  }

                  if (item.employee) {
                    name = item.employee.name
                  }
                  return (
                    <Timeline.Item
                      color={item.event_type > 2 ? "green" : "blue"}
                      key={item.id}
                      dot={
                        <Icon
                          type="history"
                          style={{ fontSize: "20px" }}
                        />
                      }
                    >
                      <p style={{ opacity: "0.7" }}>
                        {moment(item.created_at).format("LLL")} -{" "}
                        {name ? name : null}
                      </p>
                      <p>{`${item.description}`} {item.to_value ? `(${item.to_value})` : null} {item.from_value ? `(${item.from_value})` : null}</p>
                    </Timeline.Item>
                  );
                })}
              </Timeline>
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}

Activities.propTypes = {
  activities: PropTypes.arrayOf(PropTypes.object)
};
export default Activities;
