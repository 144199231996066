// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table, DatePicker, Select, Button, Typography, Statistic, Icon } from "antd";
import { Pagination } from "antd";
import { Row, Col, PageHeader } from "antd";
import _ from "lodash/array";

import PropTypes from "prop-types";
import accounting from "accounting";

import moment from "moment";
import "moment/locale/ru";

const { Title } = Typography;
const { RangePicker } = DatePicker;
const Option = Select.Option;

class DataList extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  render() {
    const {
      loading,
      report,
      fetchParams,
      period,
      onChangeDateRange,
      onChangePeriod
    } = this.props;

    // render: (effectiveness, record) => `${Number(record.closed / record.total * 100).toFixed(2)}%`,

    const dateFormat = "DD.MM.YYYY";

    const tickets = report.tickets || [];
    const tickets_replaced = report.tickets_replaced || [];

    const plan_tickets = (tickets.length > 0 && tickets_replaced.length > 0) ? Number(tickets[0].intime) + Number(tickets_replaced[0].intime) : 0
    const total_tickets  = (tickets.length > 0 && tickets_replaced.length > 0) ? Number(tickets[0].total) + Number(tickets_replaced[0].total) : 0
    const sla            = (plan_tickets / total_tickets * 100).toFixed(2)

    const columns = [
      {
        title: "Количество",
        dataIndex: "total",
        align: "center",
        width: "25%"
      },
      {
        title: "Количество выполненных в срок",
        dataIndex: "intime",
        render: (intime, record) => intime,
        align: "center",
        width: "25%"
      },
      {
        title: "Количество не выполненных в срок",
        dataIndex: "overdue",
        render: (overdue, record) => overdue,
        align: "center",
        width: "25%"
      },
      {
        title: "SLA эффективности",
        dataIndex: "unlosed",
        render: (unlosed, record) => `${record.total > 0 ? Number(record.intime / record.total * 100).toFixed(2) : 0}%`,
        align: "center",
        width: "25%"
      },
    ];


    

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`SLA эффективности за период c ${fetchParams.startDate} по ${fetchParams.endDate}`}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }} style={{ paddingBottom: 15 }}>
            <Col span={24}>
              <Table
              title={() => 'Заявки без замены оборудования'}
                bordered
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={tickets}
                rowKey="id"
              />
            </Col>
            <Col span={24}>
              <Table
                title={() => 'Заявки с заменой оборудования'}
                bordered
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={tickets_replaced}
                rowKey="id"
              />
            </Col>
          </Row>
        }
      >
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <RangePicker
              style={{ width: "100%" }}
              value={[
                moment(fetchParams.startDate, dateFormat),
                moment(fetchParams.endDate, dateFormat)
              ]}
              format={dateFormat}
              onChange={onChangeDateRange}
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <Select
              style={{ width: "100%" }}
              value={period}
              placeholder="Период"
              optionFilterProp="children"
              filterOption={false}
              onChange={onChangePeriod}
            >
              <Option value={0}>произвольный период</Option>
              <Option value={1}>этот месяц</Option>
              <Option value={2}>этот квартал</Option>
              <Option value={3}>этот год</Option>
              <Option value={4}>прошлый месяц</Option>
              <Option value={5}>прошлый квартал</Option>
              <Option value={6}>прошлый год</Option>
            </Select>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{textAlign: "right"}}>
          <Statistic
            title="SLA эффективности"
            value={sla > 0 ? sla : 0}
            precision={2}
            valueStyle={{ color: '#cf1322' }}
            // prefix={<Icon type="arrow-down" />}
            suffix="%"
          />
          </Col>
        </Row>
      </PageHeader>
    );
  }
}

DataList.propTypes = {
  payments: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object
};

export default DataList;
