import update from "immutability-helper";

const initialState = {
  supportMessages: [],
  supportMessage: { user: null, comments: [], attachments: [], assignees: [] },
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1,
  },
  fetchParams: {
    search: null,
    sort: null,
    direction: null,
    page: 1,
    limit: 15,
    users: null,
    members: null,
    statuses: null,
    support_message_types: null,
  },
  errors: false,
  isLoading: true,
  isCommentsLoading: false,
  isAssigneesLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "FETCH_SUPPORT_MESSAGES_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_SUPPORT_MESSAGES":
      return {
        ...state,
        supportMessages: action.response.data,
        meta: action.response.meta,
        isLoading: false,
      };
    case "FETCH_SUPPORT_MESSAGE_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_SUPPORT_MESSAGE":
      return {
        ...state,
        supportMessage: action.response.data,
        isLoading: false,
      };
    case "PATCH_SUPPORT_MESSAGE_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };
    case "PATCH_SUPPORT_MESSAGE":
      return {
        ...state,
        supportMessage: action.response.data,
        isLoading: false,
        errors: false,
      };

    case "PATCH_SUPPORT_MESSAGE_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "POST_SUPPORT_MESSAGE_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "POST_SUPPORT_MESSAGE":
      return {
        ...state,
        errors: false,
        supportMessage: action.response.data,
        isLoading: false,
      };

    case "POST_SUPPORT_MESSAGE_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "DELETE_SUPPORT_MESSAGE_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };
    case "DELETE_SUPPORT_MESSAGE":
      return {
        ...state,
        isLoading: false,
        errors: false,
      };
    case "DELETE_SUPPORT_MESSAGE_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "REQUEST_SUPPORT_MESSAGE_SEARCH":
      return update(state, {
        fetchParams: { search: { $set: action.value }, page: { $set: 1 } },
      });

    case "REQUEST_SUPPORT_MESSAGE_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } },
      });

    case "REQUEST_SUPPORT_MESSAGE_CHANGE_LIMIT":
      return update(state, {
        fetchParams: { limit: { $set: action.value }, page: { $set: 1 } },
      });

    case "SUPPORT_MESSAGES_SORT_BY":
      return update(state, {
        fetchParams: {
          sort: { $set: action.field },
          direction: { $set: action.direction },
        },
      });

    case "SUPPORT_MESSAGE_CHANGE_SUBJECT":
      return update(state, {
        supportMessage: { subject: { $set: action.value } },
      });

    case "SUPPORT_MESSAGE_CHANGE_DESCRIPTION":
      return update(state, {
        supportMessage: { description: { $set: action.value } },
      });

    case "SUPPORT_MESSAGE_CHANGE_STATUS":
      return update(state, {
        supportMessage: { status: { $set: action.value } },
      });

    case "SUPPORT_MESSAGE_CHANGE_SUPPORT_MESSAGE_TYPE":
      return update(state, {
        supportMessage: { support_message_type: { $set: action.value } },
      });

    // comments
    case "FETCH_SUPPORT_MESSAGE_COMMENTS_REQUEST":
      return {
        ...state,
        isCommentsLoading: true,
      };
    case "FETCH_SUPPORT_MESSAGE_COMMENTS":
      return update(state, {
        supportMessage: { comments: { $set: action.response.data } },
        isCommentsLoading: { $set: false },
      });

    case "FETCH_SUPPORT_MESSAGE_COMMENTS_FAILURE":
      return {
        ...state,
        errors: true,
        isCommentsLoading: false,
      };

    case "POST_SUPPORT_MESSAGE_COMMENT_REQUEST":
      return {
        ...state,
        errors: false,
        isCommentsLoading: true,
      };
    case "POST_SUPPORT_MESSAGE_COMMENT":
      return {
        ...state,
        errors: false,
        isCommentsLoading: false,
      };

    case "POST_SUPPORT_MESSAGE_COMMENT_FAILURE":
      return {
        ...state,
        errors: true,
        isCommentsLoading: false,
      };

    // assignees
    case "FETCH_SUPPORT_MESSAGE_ASSIGNEES_REQUEST":
      return {
        ...state,
        isAssigneesLoading: true,
      };
    case "FETCH_SUPPORT_MESSAGE_ASSIGNEES":
      return update(state, {
        supportMessage: { assignees: { $set: action.response.data } },
        isAssigneesLoading: { $set: false },
      });

    case "FETCH_SUPPORT_MESSAGE_ASSIGNEES_FAILURE":
      return {
        ...state,
        errors: true,
        isAssigneesLoading: false,
      };

    case "POST_SUPPORT_MESSAGE_ASSIGNEE_REQUEST":
      return {
        ...state,
        errors: false,
        isAssigneesLoading: true,
      };
    case "POST_SUPPORT_MESSAGE_ASSIGNEE":
      return {
        ...state,
        errors: false,
        isAssigneesLoading: false,
      };

    case "POST_SUPPORT_MESSAGE_ASSIGNEE_FAILURE":
      return {
        ...state,
        errors: true,
        isAssigneesLoading: false,
      };

    // filters
    case "SUPPORT_MESSAGE_CLEAR_FILTERS":
      return update(state, {
        fetchParams: {
          status: { $set: null },
          users: { $set: null },
          members: { $set: null },
        },
      });

    case "SUPPORT_MESSAGE_FILTER_STATUSES":
      return update(state, {
        fetchParams: {
          statuses: { $set: action.values.length > 0 ? action.values : null },
          page: { $set: 1 },
        },
      });

    case "SUPPORT_MESSAGE_FILTER_TYPES":
      return update(state, {
        fetchParams: {
          support_message_types: {
            $set: action.values.length > 0 ? action.values : null,
          },
          page: { $set: 1 },
        },
      });

    case "SUPPORT_MESSAGE_FILTER_USERS":
      return update(state, {
        fetchParams: {
          users: { $set: action.values.length > 0 ? action.values : null },
          members: { $set: null },
          page: { $set: 1 },
        },
      });

    case "SUPPORT_MESSAGE_FILTER_MEMBERS":
      return update(state, {
        fetchParams: {
          users: { $set: null },
          members: { $set: action.values.length > 0 ? action.values : null },
          page: { $set: 1 },
        },
      });

    default:
      return state;
  }
}
