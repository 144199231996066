// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../../../actions/employeePayrolls";
import LoadingSpin from "../../../../components/LoadingSpin";
import Form from "./Form";

import moment from "moment";
import "moment/locale/ru";

class Payroll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: false
    };
  }

  componentWillMount = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(
        Actions.fetchEmployeePayroll(this.props.match.params.id)
      );
    } else {
      this.setState({ isNew: true }, () => {
        this.props.dispatch(Actions.onNew());
      });
    }
  };

  onClose = () => {
    this.props.history.push(`/finances/employee_payrolls`);
  };

  // payroll_items
  onFillInEmployees = () => {
    this.props.dispatch(Actions.onCreate()).then(() => {
      if (!this.props.errors) {
        this.props.history.push(`/finances/employee_payrolls/${this.props.employeePayroll.id}/edit`);
      }
    });
  };

  onReFillInEmployees = () => {
      this.props.dispatch(Actions.onReFillInEmployees());
  };

  onChangeDate = value => {
    const { isNew } = this.state;
    if (isNew) {
      this.props.dispatch(Actions.onChangeDate(value));
    } else {
      this.props.dispatch(Actions.onUpdate({date: value}))
    }
  };

  onChangeDepartment = value => {
    this.props.dispatch(Actions.onChangeDepartment(value));
  };

  onUpdateDepartmentAndRefillIn = value => {
    this.props.dispatch(Actions.onUpdate({department_id: value.id})).then(() => {
      this.props.dispatch(Actions.onReFillInEmployees());
    })
  };

  onChangePayrollSchema = value => {
    const { isNew } = this.state;
    if (isNew) {
      this.props.dispatch(Actions.onChangePayrollSchema(value));
    } else {
      this.props.dispatch(Actions.onUpdate({payroll_schema_id: value.id}))
    }
  };

  onDeleteEemployees = (ids) => {
    ids.map(id => {
      this.props.dispatch(Actions.onDeleteEemployees(this.props.employeePayroll.id, id))
    });
  };

  onChangeSalary = (id, value) => {
    this.props.dispatch(Actions.onUpdateEmployeePayrollItem(id, { salary: value }));
  };

  onCreateEmployee = (item) => {
    this.props.dispatch(Actions.onCreateEmployee(item));
  }

  render() {
    const { isLoading, errors, employeePayroll, isLoadindPayrollItems } = this.props;
    return isLoading || errors ? (
      <LoadingSpin />
    ) : (
      <Form
        isLoading={isLoading}
        isNew={this.state.isNew}
        onClose={this.onClose}
        employeePayroll={employeePayroll}
        onFillInEmployees={this.onFillInEmployees}
        onChangeDate={this.onChangeDate}
        onChangeDepartment={this.onChangeDepartment}
        onUpdateDepartmentAndRefillIn={this.onUpdateDepartmentAndRefillIn}
        onChangePayrollSchema={this.onChangePayrollSchema}
        // employee_payroll_items
        isLoadindPayrollItems={isLoadindPayrollItems}
        onDeleteEemployees={this.onDeleteEemployees}
        onReFillInEmployees={this.onReFillInEmployees}
        onChangeSalary={this.onChangeSalary}
        onCreateEmployee={this.onCreateEmployee}
      />
    );
  }
}

Payroll.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  payroll: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool
};

const mapStateToProps = state => ({
  isLoading: state.employeePayrolls.isLoading,
  errors: state.employeePayrolls.errors,
  employeePayroll: state.employeePayrolls.employeePayroll,
  isLoadindPayrollItems: state.employeePayrolls.isLoadindPayrollItems
});

export default connect(mapStateToProps)(withRouter(Payroll));
