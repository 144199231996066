// @flow
import React, { Component, lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../../actions/projects";
import TaskActions from "../../../actions/tasks";
import LoadingSpin from "../../../components/LoadingSpin";
import {encodeBase64} from "../../../util/attachments";

const ProjectForm = lazy(() => import("./Form"));
const renderLoader = () => <LoadingSpin />;

class Project extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: false,
    };
  }

  componentWillMount = () => {
    if (this.props.match.params.id) {
      this.props
        .dispatch(Actions.fetchProject(this.props.match.params.id))
        .then(() => {
          this.props.dispatch(
            TaskActions.fetchTaskActions({ taskOwners: [1, 2] })
          );
        });
    } else {
      this.setState({ isNew: true }, () => {
        this.props.dispatch(Actions.onNew());
      });
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.location.key !== prevProps.location.key) {
      if (this.props.match.params.id) {
        this.setState({ isNew: false });
      }
    }
  }

  onSave = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.onUpdate(this.props.match.params.id));
    } else {
      this.props.dispatch(Actions.onCreate()).then(() => {
        if (!this.props.errors) {
          this.props.history.push(`/projects/${this.props.project.id}/edit`);
        }
      });
    }
  };

  onClose = () => {
    this.props.history.push(`/projects`);
  };

  // header
  onChangeNumber = (e) => {
    this.props.dispatch(Actions.onChangeNumber(e.target.value));
  };

  onChangeCustomer = (value) => {
    this.props.dispatch(Actions.onChangeCustomer(value));
  };

  onChangeEntity = (value) => {
    this.props.dispatch(Actions.onChangeEntity(value));
  };

  onChangeProjectType = (value) => {
    this.props.dispatch(Actions.onChangeProjectType(value));
  };

  onChangeAmount = (value) => {
    this.props.dispatch(Actions.onChangeAmount(value));
  };

  onChangeDescription = (e) => {
    this.props.dispatch(Actions.onChangeDescription(e.target.value));
  };

  onChangeStatus = (value) => {
    this.props.dispatch(Actions.onChangeStatus(value));
  };

  // comments
  onCreateComment = (value) => {
    this.props
      .dispatch(Actions.onCreateComment(this.props.match.params.id, value))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchProjectComments(this.props.match.params.id)
          );
        }
      });
  };

  // attachments
  onDeleteAttachment = (id) => {
    this.props
      .dispatch(Actions.onDeleteAttachment(this.props.match.params.id, id))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAttachments(this.props.match.params.id)
          );
        }
      });
  };

  onUploadAttachment = (file) => {
    encodeBase64(file, (fileUrl) =>
      this.props
        .dispatch(Actions.onUploadAttachment(
          this.props.match.params.id,
          {
            id: file.uid,
            name: file.name,
            content_type: file.type,
            file_size: file.size,
            attachment: fileUrl,
          }))
        .then(() => {
          if (!this.props.errors) {
            this.props.dispatch(
              Actions.fetchAttachments(this.props.match.params.id)
            );
          }
        })
    );
  };

  onUploadCommentAttachment = (commentId, file) => {
    encodeBase64(file, (fileUrl) =>
      this.props
        .dispatch(Actions.onUploadAttachment(
          this.props.match.params.id,
          {
            id: file.uid,
            name: file.name,
            content_type: file.type,
            file_size: file.size,
            attachment: fileUrl,
          },
          commentId))
        .then(() => {
          if (!this.props.errors) {
            this.props.dispatch(
              Actions.fetchAttachments(this.props.match.params.id)
            );
          }
        })
    );
  };

  // activities
  fetchActivities = () => {
    this.props.dispatch(Actions.fetchActivities(this.props.match.params.id));
  };

  // assignees
  onCreateAssignee = (value, type) => {
    this.props
      .dispatch(
        Actions.onCreateAssignee(this.props.match.params.id, value, type)
      )
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAssignees(this.props.match.params.id)
          );
        }
      });
  };

  onDeleteAssignee = (id) => {
    this.props
      .dispatch(Actions.onDeleteAssignee(this.props.match.params.id, id))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAssignees(this.props.match.params.id)
          );
        }
      });
  };

  // stages
  onSaveStage = (item) => {
    if (item.id) {
      this.props
        .dispatch(
          Actions.onUpdateProjectStage(this.props.match.params.id, item)
        )
        .then(() => {
          if (!this.props.errors) {
            this.props.dispatch(
              Actions.fetchProjectStages(this.props.match.params.id)
            );
          }
        });
    } else {
      this.props
        .dispatch(
          Actions.onCreateProjectStage(this.props.match.params.id, item)
        )
        .then(() => {
          if (!this.props.errors) {
            this.props.dispatch(
              Actions.fetchProjectStages(this.props.match.params.id)
            );
          }
        });
    }
  };

  onDeleteStages = (ids) => {
    this.props
      .dispatch(Actions.onDeleteProjectStages(this.props.match.params.id, ids))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchProjectStages(this.props.match.params.id)
          );
        }
      });
  };

  // actions
  onOpenTaskForm = (action, id) => {
    const { project } = this.props;
    this.props.dispatch(TaskActions.fetchNew(action.id, id));
  };

  // tasks
  fetchTasks = () => {
    this.props.dispatch(Actions.fetchTasks(this.props.match.params.id));
  };

  render() {
    const {
      currentUser,
      isLoading,
      errors,
      isAssigneesLoading,
      isAttachmentLoading,
      isCommentsLoading,
      project,
      isStagesLoading,
      // tasks
      isTasksLoading,
      tasks,
      // actions
      actions,
      isTaskActionsLoading,
      // activities
      activities,
      isActivitiesLoading,
    } = this.props;
    return (
      <Suspense fallback={renderLoader()}>
        <ProjectForm
          isNew={this.state.isNew}
          currentUser={currentUser}
          project={project}
          onSave={this.onSave}
          onClose={this.onClose}
          // header
          onChangeNumber={this.onChangeNumber}
          onChangeCustomer={this.onChangeCustomer}
          onChangeEntity={this.onChangeEntity}
          onChangeProjectType={this.onChangeProjectType}
          onChangeAmount={this.onChangeAmount}
          onChangeDescription={this.onChangeDescription}
          onChangeStatus={this.onChangeStatus}
          // comments
          isCommentsLoading={isCommentsLoading}
          onCreateComment={this.onCreateComment}
          // attachments
          isAttachmentLoading={isAttachmentLoading}
          onDeleteAttachment={this.onDeleteAttachment}
          onUploadAttachment={this.onUploadAttachment}
          onUploadCommentAttachment={this.onUploadCommentAttachment}
          onDeleteCommentAttachment={this.onDeleteAttachment}
          // assignees
          isAssigneesLoading={isAssigneesLoading}
          onDeleteAssignee={this.onDeleteAssignee}
          onCreateAssignee={this.onCreateAssignee}
          // activities
          activities={activities}
          isActivitiesLoading={isActivitiesLoading}
          fetchActivities={this.fetchActivities}
          // stages
          isStagesLoading={isStagesLoading}
          onSaveStage={this.onSaveStage}
          onDeleteStages={this.onDeleteStages}
          // tasks
          isTasksLoading={isTasksLoading}
          tasks={tasks}
          fetchTasks={this.fetchTasks}
          // actions
          actions={actions}
          isTaskActionsLoading={isTaskActionsLoading}
          onOpenTaskForm={this.onOpenTaskForm}
        />
      </Suspense>
    );
  }
}

Project.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  project: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  isLoading: state.projects.isLoading,
  errors: state.projects.errors,
  project: state.projects.project,
  isLoadingProposals: state.projects.isLoadingProposals,
  isAttachmentLoading: state.projects.isAttachmentLoading,
  isAssigneesLoading: state.projects.isAssigneesLoading,
  isSchedulesLoading: state.projects.isSchedulesLoading,
  isCommentsLoading: state.projects.isCommentsLoading,
  isStagesLoading: state.projects.isStagesLoading,
  // tasks
  isTasksLoading: state.projects.isTasksLoading,
  tasks: state.projects.tasks,
  // actions
  actions: state.tasks.actions,
  isTaskActionsLoading: state.tasks.isLoading,
  // activities
  activities: state.projects.activities,
  isActivitiesLoading: state.projects.isActivitiesLoading,
});

export default connect(mapStateToProps)(withRouter(Project));
