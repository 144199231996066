// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../../actions/members";
import { Spin } from "antd";
import Form from "./Form";

class Member extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: false
    };
  }

  componentWillMount = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(
        Actions.fetchMember(this.props.match.params.id)
      );
    } else {
      this.setState({ isNew: true }, () => {
        this.props.dispatch(Actions.onNew());
      });
    }
  };

  onSave = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.onUpdate(this.props.match.params.id));
    } else {
      this.props.dispatch(Actions.onCreate()).then(() => {
        if (!this.props.errors) {
          this.props.history.push(
            `/members/${this.props.member.id}/edit`
          );
        }
      });
    }
  };

  onClose = () => {
    this.props.history.push(`/members`);
  };

  onChangeActive = e => {
    this.props.dispatch(Actions.onChangeActive(e.target.checked));
  };

  onChangeRole = value => {
    this.props.dispatch(Actions.onChangeRole(value));
  };

  onChangeName = e => {
    this.props.dispatch(Actions.onChangeName(e.target.value));
  };

  onChangeCustomer = value => {
    this.props.dispatch(Actions.onChangeCustomer(value));
  };

  onChangeEmail = e => {
    this.props.dispatch(Actions.onChangeEmail(e.target.value));
  };

  onChangeTitle = e => {
    this.props.dispatch(Actions.onChangeTitle(e.target.value));
  };

  onChangePhone = e => {
    this.props.dispatch(Actions.onChangePhone(e.target.value));
  };

  onUploadAvatar = file => {
    this.props.dispatch(Actions.onUploadAvatar(this.props.member.id, file));
  };

  // change password
  onUpdatePassword = () => {
    this.props.dispatch(Actions.onUpdatePassword());
  };

  onChangePassword = e => {
    this.props.dispatch(Actions.onChangePassword(e.target.value));
  };

  onChangePasswordConfirmation = e => {
    this.props.dispatch(Actions.onChangePasswordConfirmation(e.target.value));
  };



  render() {
    const { isLoading, isUploadingAvatar, member } = this.props;
    return isLoading ? (
      <Spin />
    ) : (
        <Form
          isNew={this.state.isNew}
          member={member}
          onSave={this.onSave}
          onClose={this.onClose}
          isUploadingAvatar={isUploadingAvatar}
          onUploadAvatar={this.onUploadAvatar}
          onChangeActive={this.onChangeActive}
          onChangeRole={this.onChangeRole}
          onChangeName={this.onChangeName}
          onChangeCustomer={this.onChangeCustomer}
          onChangeEmail={this.onChangeEmail}
          onChangeTitle={this.onChangeTitle}
          onChangePhone={this.onChangePhone}
          onUpdatePassword={this.onUpdatePassword}
          onChangePassword={this.onChangePassword}
          onChangePasswordConfirmation={this.onChangePasswordConfirmation}
        />
      );
  }
}

Member.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  member: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool
};

const mapStateToProps = state => ({
  isLoading: state.members.isLoading,
  isUploadingAvatar: state.members.isUploadingAvatar,
  errors: state.members.errors,
  member: state.members.member
});

export default connect(mapStateToProps)(withRouter(Member));
