// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, Form, Card, Tabs, Menu, Dropdown } from "antd";
import Statuses from "./Statuses";
import General from "./General";
import moment from "moment";
import "moment/locale/ru";
import Pane from "./Pane";
import Activities from "./Activities";
import Tasks from "../../../components/Tasks";

const { TabPane } = Tabs;

class OrderForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assignee: null,
    };
  }

  onSubmit = (e) => {
    const { order, isNew } = this.props;
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      if (
        !isNew &&
        parseFloat(order.amount) !=
          parseFloat(order.product_price) + parseFloat(order.service_price)
      ) {
        // console.log(values.productPrice)
        this.props.form.setFields({
          amount: {
            value: values.amount,
            errors: [new Error("Сумма не равна стоимости услуг и товара!")],
          },
        });
        return;
      }
      this.props.onSave(values);
    });
  };

  callback = (key) => {
    if (key == "2") {
      this.props.fetchOrderActivities();
    }

    if (key == "4") {
      this.props.fetchTasks();
    }
  };

  // actions
  onAction = (actionId) => {
    const { order, actions, onOpenTaskForm } = this.props;
    let idx = actions.findIndex(function (o) {
      return o.id == actionId;
    });
    if (idx === -1) {
      return null;
    }
    onOpenTaskForm(actions[idx]);
  };

  ActionMenu = (actions) => {
    return (
      <Menu>
        {actions.map((action) => {
          return (
            <Menu.Item
              key={action.id}
              onClick={this.onAction.bind(this, action.id)}
            >
              {action.name}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      isNew,
      forTaskId,
      isLoading,
      errors,
      currentUser,
      order,
      onClose,
      // general
      onChangeDueBy,
      onChangePriority,
      onChangeNumberInt,
      onChangeNumber,
      onChangeCustomer,
      onChangeEntity,
      onChangeEntityService,
      onChangeAmount,
      onChangeSubject,
      onChangeDescription,
      onChangeIsOrganisation,
      onChangeIsSupplier,
      onChangeCompany,
      // comments
      isCommentsLoading,
      onCreateComment,
      // assignees
      isAssigneesLoading,
      onCreateAssignee,
      onDeleteAssignee,
      // proposals
      isLoadingProposals,
      onDeleteProposal,
      onUploadProposal,
      // attachments
      isAttachmentLoading,
      onDeleteAttachment,
      onUploadAttachment,
      onUploadCommentAttachment,
      onDeleteCommentAttachment,
      // order_tasks
      isOrderTasksLoading,
      onCreateOrderTask,
      // schedules
      isSchedulesLoading,
      fetchSchedules,
      onSaveSchedule,
      onDeleteSchedules,
      // finance
      onChangeProductPrice,
      onChangeServicePrice,
      onChangePenalty,
      onChangePayment,
      // status
      onChangeStatus,
      // activities
      orderActivities,
      isActivitiesLoading,
      // tasks
      isTasksLoading,
      tasks,
      // actions
      actions,
      isTaskActionsLoading,
      onOpenTaskForm,
    } = this.props;

    const organisationAssignees = order.assignees.filter(function (item) {
      return item.assignee_type == "organisation";
    });

    const customerAssignees = order.assignees.filter(function (item) {
      return item.assignee_type == "customer";
    });

    const companyAssignees = order.assignees.filter(function (item) {
      return item.assignee_type == "company";
    });

    const commentAttachments = (order.attachments ?? []).filter((attachment) => !!attachment.order_comment_id)

    // actions
    let actionsButton = null;

    if (actions.length > 0) {
      actionsButton = (
        <Dropdown key={99} overlay={this.ActionMenu(actions)}>
          <Button icon={"more"} style={{ marginLeft: "8px" }} />
        </Dropdown>
      );
    }

    let orderNumber = isNew ?
      forTaskId === null ? "новый" : `новый (для задачи #${forTaskId})` :
      order.number;
    let orderDate = order.created_at === null ?
      moment().format("LLL") :
      moment(order.created_at).format("LLL")

    return (
      <Form layout={"vertical"} onSubmit={this.onSubmit}>
        <Card
          loading={isLoading}
          title={
            <h2 style={{ marginBottom: 0 }}>
              {`Заказ #${orderNumber} от ${orderDate}`}
            </h2>
          }
          extra={[
            <Button onClick={onClose}>Закрыть</Button>,
            <Button
              onClick={this.onSubmit}
              type="primary"
              ghost
              style={{ marginLeft: "8px" }}
            >
              Сохранить
            </Button>,
            actionsButton,
          ]}
        >
          <Col xs={24} sm={24} md={24} lg={14} xl={14}>
            <Row>
              <General
                isNew={isNew}
                getFieldDecorator={getFieldDecorator}
                order={order}
                // general
                onChangeDueBy={onChangeDueBy}
                onChangePriority={onChangePriority}
                onChangeCustomer={onChangeCustomer}
                onChangeCompany={onChangeCompany}
                onChangeEntity={onChangeEntity}
                onChangeEntityService={onChangeEntityService}
                onChangeAmount={onChangeAmount}
                onChangeSubject={onChangeSubject}
                onChangeDescription={onChangeDescription}
              />
            </Row>
            <Row>
              <Col span={24}>
                {isNew ? null : (
                  <Pane
                    order={order}
                    currentUser={currentUser}
                    // comments
                    getFieldDecorator={getFieldDecorator}
                    isCommentsLoading={isCommentsLoading}
                    errors={errors}
                    onCreateComment={onCreateComment}
                    comments={order.comments}
                    // order_tasks
                    isOrderTasksLoading={isOrderTasksLoading}
                    errors={errors}
                    onCreateOrderTask={onCreateOrderTask}
                    orderTasks={order.order_tasks}
                    company={order.company}
                    isOrganisation={order.is_organisation}
                    isSupplier={order.is_supplier}
                    // schedules
                    isSchedulesLoading={isSchedulesLoading}
                    fetchSchedules={fetchSchedules}
                    errors={errors}
                    onSaveSchedule={onSaveSchedule}
                    onDeleteSchedules={onDeleteSchedules}
                    schedules={order.schedules}
                    // finance
                    productPrice={order.product_price}
                    servicePrice={order.service_price}
                    penalty={order.penalty}
                    payment={order.payment}
                    onChangeProductPrice={onChangeProductPrice}
                    onChangeServicePrice={onChangeServicePrice}
                    onChangePenalty={onChangePenalty}
                    onChangePayment={onChangePayment}
                    // attachments
                    isAttachmentLoading={isAttachmentLoading}
                    attachments={order.attachments}
                    onDeleteAttachment={onDeleteAttachment}
                    onUploadAttachment={onUploadAttachment}
                    commentAttachments={commentAttachments}
                    onUploadCommentAttachment={onUploadCommentAttachment}
                    onDeleteCommentAttachment={onDeleteCommentAttachment}
                  />
                )}
              </Col>
            </Row>
          </Col>

          <Col xs={0} sm={0} md={1} lg={1} xl={1} />
          <Col xs={24} sm={24} md={24} lg={9} xl={9}>
            <Tabs defaultActiveKey="1" onChange={this.callback}>
              <TabPane tab="Статусы" key="1">
                <Statuses
                  isNew={isNew}
                  getFieldDecorator={getFieldDecorator}
                  currentUser={currentUser}
                  orderId={order.id}
                  user={order.user}
                  member={order.member}
                  customer={order.customer}
                  company={order.company}
                  ticket={order.ticket}
                  // numbers
                  number_int={order.number_int}
                  number={order.number}
                  onChangeNumberInt={onChangeNumberInt}
                  onChangeNumber={onChangeNumber}
                  // supplier
                  isOrganisation={order.is_organisation}
                  isSupplier={order.is_supplier}
                  onChangeIsOrganisation={onChangeIsOrganisation}
                  onChangeIsSupplier={onChangeIsSupplier}
                  // assignees
                  organisationAssignees={organisationAssignees}
                  customerAssignees={customerAssignees}
                  companyAssignees={companyAssignees}
                  isAssigneesLoading={isAssigneesLoading}
                  onDeleteAssignee={onDeleteAssignee}
                  onCreateAssignee={onCreateAssignee}
                  // proposals
                  isLoadingProposals={isLoadingProposals}
                  attachments={order.proposals}
                  onUploadProposal={onUploadProposal}
                  onDeleteProposal={onDeleteProposal}
                  // due_by
                  dueBy={order.due_by}
                  onChangeDueBy={onChangeDueBy}
                  // priority
                  priority={order.priority}
                  onChangePriority={onChangePriority}
                  // statuses
                  status={order.status}
                  onChangeStatus={onChangeStatus}
                />
              </TabPane>
              <TabPane tab="Лог изменений" key="2">
                <Activities
                  orderActivities={orderActivities}
                  isActivitiesLoading={isActivitiesLoading}
                />
              </TabPane>
              {isNew ? null : (
                <TabPane tab="Задачи" key="4">
                  <Tasks
                    isLoading={isTasksLoading}
                    tasks={tasks}
                  />
                </TabPane>
              )}
            </Tabs>
          </Col>
        </Card>
      </Form>
    );
  }
}

OrderForm.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  order: PropTypes.object,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
  router: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  form: PropTypes.object,
  fetchSuggestions: PropTypes.func,
  onChangeDate: PropTypes.func,
  onChangeUser: PropTypes.func,
  onChangeAgreement: PropTypes.func,
  onChangeAgreementDate: PropTypes.func,
  onChangeAdditional: PropTypes.func,
  onChangeComment: PropTypes.func,
  onChangeNotice: PropTypes.func,
  onChangePaymentType: PropTypes.func,
  onChangePaymentStatus: PropTypes.func,
  onChangeShippingStatus: PropTypes.func,
  onChangeShippingMethod: PropTypes.func,
  onAddVariant: PropTypes.func,
  onRemoveVariant: PropTypes.func,
  onChangeQuantity: PropTypes.func,
  onChangePrice: PropTypes.func,
};

export default Form.create()(OrderForm);
