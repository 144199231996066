// @flow
import React, {Component} from "react";
import PropTypes from "prop-types";
import {Form, Input, Col, Row} from "antd";

class Header extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      title,
      onChangeTitle,
      getFieldDecorator
    } = this.props;

    return (
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label={"Заголовок"}>
            {getFieldDecorator("title", {
              initialValue: title,
              onChange: onChangeTitle,
              rules: [
                {
                  required: true,
                  message: "Введите заголовок!"
                }
              ]
            })(<Input/>)}
          </Form.Item>
        </Col>
        <Col span={24}>
        </Col>
      </Row>
    );
  }
}

Header.propTypes = {
  entity: PropTypes.object,
  onChangeDate: PropTypes.func,
  onChangeAssignee: PropTypes.func,
  onChangeAgreement: PropTypes.func,
  onChangeAgreementDate: PropTypes.func,
  onChangeAdditional: PropTypes.func,
  onChangeComment: PropTypes.func,
  onChangeNotice: PropTypes.func,
};

export default Header;
