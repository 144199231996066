// @flow
import React, { Component } from "react";
import {
  Row,
  Col,
  Modal,
  Select,
  Button,
  Form,
  Input,
  DatePicker,
  List,
  Avatar,
  Divider,
  Tooltip
} from "antd";
import moment from "moment";
import "moment/locale/ru";
import _ from "lodash/array";

const FormItem = Form.Item;
const Option = Select.Option;

class SchedulModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddingAssignee: false
    };
  }

  onSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onSave(values);
    });
  };


  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      visible,
      onCancel,
      schedule,
      onChangeScheduleType,
      onChangeName,
      onChangeStart,
      onChangeFinish,
    } = this.props;
    return (
      <Modal
        title={schedule.id ? schedule.name : "Новый этап"}
        // width={600}
        destroyOnClose
        visible={visible}
        onOk={this.onSubmit}
        onCancel={onCancel}
      >
        <Form layout={"vertical"}>
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <FormItem label={"Тип"}>
                {getFieldDecorator("schedule_type", {
                  initialValue: schedule.schedule_type,
                  onChange: onChangeScheduleType,
                  rules: [
                    {
                      required: true,
                      message: "Введите тип!"
                    }
                  ]
                })(
                  <Select
                    placeholder="Тип"
                    optionFilterProp="children"
                    filterOption={false}
                  >
                    <Option value={"examination"}>выезд на обследование</Option>
                    <Option value={"proposal_processing"}>создание КП</Option>
                    <Option value={"proposal_approving"}>согласование КП</Option>
                    <Option value={"purchasing"}>закупка оборудования</Option>
                    <Option value={"job_processing"}>выполнение работ</Option>
                    <Option value={"job_approving"}>принятие работ</Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label={"Начало"}>
                {getFieldDecorator("start", {
                  onChange: onChangeStart,
                  initialValue: moment(schedule.start ? schedule.start : null),
                  rules: [
                    {
                      required: true,
                      message: "Введите дату начала!"
                    }
                  ]
                })(
                  <DatePicker
                    format={"LLL"}
                    className="ant-btn-width-100"
                    showTime
                  />
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label={"Окончание"}>
                {getFieldDecorator("finish", {
                  onChange: onChangeFinish,
                  initialValue: moment(schedule.finish ? schedule.finish : null),
                  rules: [
                    {
                      required: true,
                      message: "Введите дату окончания!"
                    }
                  ]
                })(
                  <DatePicker
                    format={"LLL"}
                    className="ant-btn-width-100"
                    showTime
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <FormItem label={"Наименование этапа"}>
                {getFieldDecorator("name", {
                  onChange: onChangeName,
                  initialValue: schedule.name,
                  rules: [
                    {
                      required: true,
                      message: "Пожалуйста, введите тему!"
                    }
                  ]
                })(<Input />)}
              </FormItem>
            </Col>
          </Row>
         
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(SchedulModal);
