// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import update from "immutability-helper";
import { Row, Col, Table, Button } from "antd";
import "moment/locale/ru";
import accounting from "accounting";
import Penalty from "./Penalty";

class Penalties extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      isVisible: false,
      penalty: {},
    };
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  onDelete = () => {
    this.props.onDeletePenalties(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  // penalty
  onOpenPenalty = (item) => {
    this.setState({ penalty: item }, () => {
      this.setState({ isVisible: true });
    });
  };

  onAddPenalty = () => {
    this.setState(
      {
        penalty: {
          id: null,
          user: null,
          penalty: 0,
          description: null
        },
      },
      () => {
        this.setState({ isVisible: true });
      }
    );
  };

  onSavePenalty = () => {
    this.setState({ isVisible: false }, () => {
      this.props.onSavePenalty(this.state.penalty);
    });
  };

  onCancel = () => {
    this.setState({ isVisible: false });
  };

  onChangeDescription = (e) => {
    this.setState({
      penalty: update(this.state.penalty, {
        description: { $set: e.target.value },
      }),
    });
  };

  onChangePenalty = (value) => {
    this.setState({
      penalty: update(this.state.penalty, {
        penalty: { $set: value },
      }),
    });
  };

  onChangeUser = (value) => {
    this.setState({
      penalty: update(this.state.penalty, {
        user: { $set: value },
      }),
    });
  };

  render() {
    const { selectedRowKeys, isVisible, penalty } = this.state;
    const { isNew, isLoadind, department, payrollPenalties } = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    let mainButton = hasSelected ? (
      <Button key="1" type="danger" ghost onClick={this.onDelete}>
        Удалить
      </Button>
    ) : (
      <Button key="2" type="primary" ghost onClick={this.onAddPenalty}>
        Добавить
      </Button>
    );

    const columns = [
      {
        title: "Имя",
        dataIndex: "user",
        render: (user, record) => (
          <a onClick={this.onOpenPenalty.bind(this, record)}>
            {user ? user.name : null}
          </a>
        ),
        key: "user",
        width: "20%",
        align: "left",
      },
      {
        title: "Описание",
        dataIndex: "description",
        key: "description",
        align: "center",
        width: "60%",
      },
      {
        title: "Штраф",
        dataIndex: "penalty",
        render: (penalty) => accounting.formatNumber(penalty, 0, " "),
        key: "penalty",
        width: "20%",
        align: "center",
      },
    ];

    return (
      <React.Fragment>
        {isNew ? null : (
          <Row gutter={{ md: 8, lg: 24, xl: 48 }} style={{ paddingBottom: 15 }}>
            <Col span={24}>{mainButton}</Col>
          </Row>
        )}
        <Row gutter={{ md: 8, lg: 24, xl: 48 }} style={{ paddingBottom: 15 }}>
          <Col span={24}>
            <Table
              bordered
              size={"small"}
              rowSelection={rowSelection}
              loading={isLoadind}
              pagination={false}
              columns={columns}
              dataSource={payrollPenalties}
              rowKey="id"
            />
          </Col>
        </Row>
        <Penalty
          visible={isVisible}
          user={penalty.user}
          penalty={penalty.penalty}
          description={penalty.description}
          departmentId={department ? department.id : null}
          onSave={this.onSavePenalty}
          onCancel={this.onCancel}
          onChangeDescription={this.onChangeDescription}
          onChangePenalty={this.onChangePenalty}
          onChangeUser={this.onChangeUser}
        />
      </React.Fragment>
    );
  }
}
Penalties.propTypes = {
  getFieldDecorator: PropTypes.func,
};

export default Penalties;
