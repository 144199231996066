// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../../actions/customers";
import { Spin } from "antd";
import Form from "./Form";
import moment from "moment";
import "moment/locale/ru";

class Customer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: false,
    };
  }

  componentWillMount = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.fetchCustomer(this.props.match.params.id));
    } else {
      this.setState({ isNew: true }, () => {
        this.props.dispatch(Actions.onNew());
      });
    }
  };

  onSave = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.onUpdate(this.props.match.params.id));
    } else {
      this.props.dispatch(Actions.onCreate()).then(() => {
        if (!this.props.errors) {
          this.props.history.push(`/customers/${this.props.customer.id}/edit`);
        }
      });
    }
  };

  onClose = () => {
    this.props.history.push(`/customers`);
  };

  // general
  onUploadLogo = (file) => {
    this.props.dispatch(Actions.onUploadLogo(this.props.customer.id, file));
  };

  onChangeName = (e) => {
    this.props.dispatch(Actions.onChangeName(e.target.value));
  };
  onChangeWebsite = (e) => {
    this.props.dispatch(Actions.onChangeWebsite(e.target.value));
  };
  onChangePostalCode = (e) => {
    this.props.dispatch(Actions.onChangePostalCode(e.target.value));
  };
  onChangeCity = (value) => {
    this.props.dispatch(Actions.onChangeCity(value));
  };
  onChangeStreet = (e) => {
    this.props.dispatch(Actions.onChangeStreet(e.target.value));
  };
  onChangeBuilding = (e) => {
    this.props.dispatch(Actions.onChangeBuilding(e.target.value));
  };

  // details
  onChangeInn = () => {};
  onChangeKpp = () => {};
  onChangeOgrn = () => {};

  // contacts
  onSaveContact = (contact) => {
    if (contact.id) {
      this.props.dispatch(
        Actions.onUpdateContact(this.props.match.params.id, contact)
      );
    } else {
      this.props
        .dispatch(Actions.onCreateContact(this.props.match.params.id, contact))
        .then(() => {
          // if (!this.props.errors) {
          //   this.props.history.push(`/customers/${this.props.customer.id}/edit`);
          // }
        });
    }
  };
  onDeleteContact = (id) => {
    this.props.dispatch(
      Actions.onDeleteContact(this.props.match.params.id, id)
    );
  };

  // priorities
  onChangeResponseTime = (id, value) => {
    this.props.dispatch(Actions.onChangeResponseTime(id, value));
  };

  onChangeUseOpenHours = (id, { value }) => {
    this.props.dispatch(Actions.onChangeUseOpenHours(id, value));
  };

  // settings
  onChangeTicketLogs = (e) => {
    this.props.dispatch(Actions.onChangeTicketLogs(e.target.value));
  };

  onChangeTaskLogs = (e) => {
    this.props.dispatch(Actions.onChangeTaskLogs(e.target.value));
  };

  onChangeIndoundEmails = (e) => {
    this.props.dispatch(Actions.onChangeIndoundEmails(e.target.value));
  };

  onChangeInboundDomain = (e) => {
    this.props.dispatch(Actions.onChangeInboundDomain(e.target.value));
  };

  onChangeInboundDomainTest = (e) => {
    this.props.dispatch(Actions.onChangeInboundDomainTest(e.target.value));
  };

  onChangeInboundEmail = (e) => {
    this.props.dispatch(Actions.onChangeInboundEmail(e.target.value));
  };

  onChangeInboundEmailTest = (e) => {
    this.props.dispatch(Actions.onChangeInboundEmailTest(e.target.value));
  };

  onChangeInboundEmailMember = (value) => {
    this.props.dispatch(Actions.onChangeInboundEmailMember(value));
  };

  onChangeUseAutoRate = (e) => {
    this.props.dispatch(Actions.onChangeUseAutoRate(e.target.checked));
  };

  onChangeAllowAllOrderTasks = (e) => {
    this.props.dispatch(Actions.onChangeAllowAllOrderTasks(e.target.checked));
  };

  onChangeCompletionCommentRequired = (e) => {
    this.props.dispatch(Actions.onChangeCompletionCommentRequired(e.target.checked));
  };

  render() {
    const {
      isLoading,
      isUploadingLogo,
      isContactsLoading,
      customer,
    } = this.props;
    return isLoading ? (
      <Spin />
    ) : (
      <Form
        isNew={this.state.isNew}
        customer={customer}
        onSave={this.onSave}
        onClose={this.onClose}
        // general
        isUploadingLogo={isUploadingLogo}
        onUploadLogo={this.onUploadLogo}
        onChangeName={this.onChangeName}
        onChangeWebsite={this.onChangeWebsite}
        onChangePostalCode={this.onChangePostalCode}
        onChangeCity={this.onChangeCity}
        onChangeStreet={this.onChangeStreet}
        onChangeBuilding={this.onChangeBuilding}
        // details
        onChangeInn={this.onChangeInn}
        onChangeKpp={this.onChangeKpp}
        onChangeOgrn={this.onChangeOgrn}
        // contacts
        isContactsLoading={isContactsLoading}
        onSaveContact={this.onSaveContact}
        onDeleteContact={this.onDeleteContact}
        // priorities
        onChangeResponseTime={this.onChangeResponseTime}
        onChangeUseOpenHours={this.onChangeUseOpenHours}
        // settings
        onChangeTicketLogs={this.onChangeTicketLogs}
        onChangeTaskLogs={this.onChangeTaskLogs}
        onChangeIndoundEmails={this.onChangeIndoundEmails}
        onChangeInboundDomain={this.onChangeInboundDomain}
        onChangeInboundDomainTest={this.onChangeInboundDomainTest}
        onChangeInboundEmail={this.onChangeInboundEmail}
        onChangeInboundEmailTest={this.onChangeInboundEmailTest}
        onChangeInboundEmailMember={this.onChangeInboundEmailMember}
        onChangeUseAutoRate={this.onChangeUseAutoRate}
        onChangeAllowAllOrderTasks={this.onChangeAllowAllOrderTasks}
        onChangeCompletionCommentRequired={this.onChangeCompletionCommentRequired}
      />
    );
  }
}

Customer.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  customer: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isLoading: state.customers.isLoading,
  isUploadingLogo: state.customers.isUploadingLogo,
  isContactsLoading: state.customers.isContactsLoading,
  errors: state.customers.errors,
  customer: state.customers.customer,
});

export default connect(mapStateToProps)(withRouter(Customer));
