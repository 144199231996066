import api from "../api";

const Actions = {};

Actions.fetchPayrolls = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_PAYROLLS_REQUEST" });
    const params = getState().payrolls.fetchParams;
    api
      .fetch("/payrolls", params)
      .then((response) => {
        dispatch({ type: "FETCH_PAYROLLS", response });
      })
      .catch((error) => {
        api.handleError(error, "Начисления");
        dispatch({ type: "FETCH_PAYROLLS_FAILURE" });
      });
  };
};

Actions.fetchPayroll = (id = null) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_PAYROLL_REQUEST" });
    api
      .fetch(`/payrolls/${id}`)
      .then((response) => {
        dispatch({ type: "FETCH_PAYROLL", response });
      })
      .catch((error) => {
        api.handleError(error, "Начисление");
        dispatch({ type: "FETCH_PAYROLL_FAILURE" });
      });
  };
};

Actions.fetchPayrollEmploees = (id = null) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_PAYROLL_EMPLOEES_REQUEST" });
    api
      .fetch(`/payrolls/${id}/emploees`)
      .then((response) => {
        dispatch({ type: "FETCH_PAYROLL_EMPLOEES", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Начисление", error);
          });
        }
        dispatch({ type: "FETCH_PAYROLL_EMPLOEES_FAILURE", response });
      });
  };
};

Actions.onNew = () => {
  return (dispatch) => {
    dispatch({
      type: "PAYROLL_NEW",
    });
  };
};

Actions.onDelete = (id) => {
  return (dispatch) => {
    dispatch({ type: "DELETE_PAYROLL_REQUEST" });
    return api
      .delete(`/payrolls/${id}`)
      .then((response) => {
        api.openNotification("success", "Начисление", "Успешно удалено!");
        dispatch({ type: "DELETE_PAYROLL", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Начисление", error);
          });
        }
        dispatch({
          type: "DELETE_PAYROLL_FAILURE",
        });
      });
  };
};

Actions.onFillInPayrollItems = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FILL_IN_PAYROLL_ITEMS_REQUEST" });
    const payroll = getState().payrolls.payroll;
    const params = {
      payroll: {
        start_at: payroll.start_at,
        end_at: payroll.end_at,
        department_id: payroll.department ? payroll.department.id : null,
        description: payroll.description,
      },
    };
    return api
      .post(`/payrolls`, params)
      .then((response) => {
        api.openNotification("success", "Начисление", "Успешно заполнено!");
        dispatch({ type: "FILL_IN_PAYROLL_ITEMS", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Начисление", error);
          });
        }
        dispatch({
          type: "FILL_IN_PAYROLL_ITEMS_FAILURE",
        });
      });
  };
};

Actions.onReFillInPayrollItems = (id) => {
  return (dispatch, getState) => {
    dispatch({ type: "REFILL_IN_PAYROLL_ITEMS_REQUEST" });
    const payroll = getState().payrolls.payroll;
    const params = {
      payroll: {
        start_at: payroll.start_at,
        end_at: payroll.end_at,
        department_id: payroll.department ? payroll.department.id : null,
        description: payroll.description,
      },
    };
    return api
      .patch(`/payrolls/${id}/fillin`, params)
      .then((response) => {
        api.openNotification("success", "Начисление", "Успешно заполнено!");
        dispatch({ type: "REFILL_IN_PAYROLL_ITEMS", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Начисление", error);
          });
        }
        dispatch({
          type: "REFILL_IN_PAYROLL_ITEMS_FAILURE",
        });
      });
  };
};

Actions.onUpdateDescription = (id, value) => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_PAYROLL_REQUEST" });
    const params = {
      payroll: { description: value },
    };
    return api
      .patch(`/payrolls/${id}`, params)
      .then((response) => {
        api.openNotification("success", "Начисление", "Описание изменено!");
        dispatch({ type: "PATCH_PAYROLL", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Начисление", error);
          });
        }
        dispatch({
          type: "PATCH_PAYROLL_FAILURE",
        });
      });
  };
};

Actions.onUpdatePeriod = (id, startAt, endAt) => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_PAYROLL_REQUEST" });
    const params = {
      payroll: { start_at: startAt, end_at: endAt },
    };
    return api
      .patch(`/payrolls/${id}`, params)
      .then((response) => {
        api.openNotification("success", "Начисление", "Период изменен!");
        dispatch({ type: "PATCH_PAYROLL", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Начисление", error);
          });
        }
        dispatch({
          type: "PATCH_PAYROLL_FAILURE",
        });
      });
  };
};

Actions.onUpdateActive = (id, value) => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_PAYROLL_ACTIVE_REQUEST" });
    const params = {
      payroll: { is_active: value },
    };
    return api
      .patch(`/payrolls/${id}`, params)
      .then((response) => {
        api.openNotification("success", "Начисление", "Активность изменена!");
        dispatch({ type: "PATCH_ACTIVE_PAYROLL", value: value });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Начисление", error);
          });
        }
        dispatch({
          type: "PATCH_ACTIVE_PAYROLL_FAILURE",
        });
      });
  };
};

Actions.onSearch = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_PAYROLL_SEARCH",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangePage = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_PAYROLL_CHANGE_PAGE",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeLimit = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_PAYROLL_CHANGE_LIMIT",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterActive = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_PAYROLLS_FILTER_ACTIVE",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onSortBy = (field, direction) => {
  return (dispatch) => {
    dispatch({
      type: "PAYROLLS_SORT_BY",
      field: field,
      direction: direction,
    });
    return Promise.resolve();
  };
};

Actions.onChangeActive = (value) => {
  return (dispatch) => {
    dispatch({
      type: "PAYROLL_CHANGE_ACTIVE",
      value: value,
    });
  };
};

Actions.onChangePeriod = (start, end) => {
  return (dispatch) => {
    dispatch({
      type: "PAYROLL_CHANGE_PERIOD",
      start: start,
      end: end,
    });
  };
};

Actions.onChangeDepartment = (value) => {
  return (dispatch) => {
    dispatch({
      type: "PAYROLL_CHANGE_DEPARTMENT",
      value: value,
    });
  };
};

Actions.onChangeDescription = (value) => {
  return (dispatch) => {
    dispatch({
      type: "PAYROLL_CHANGE_DESCRIPTION",
      value: value,
    });
  };
};

// penalties
Actions.fetchPayrollIPenalties = (id = null) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_PAYROLL_PENALTIES_REQUEST" });
    api
      .fetch(`/payrolls/${id}/payroll_penalties`)
      .then((response) => {
        dispatch({ type: "FETCH_PAYROLL_PENALTIES", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Начисление", error);
          });
        }
        dispatch({ type: "FETCH_PAYROLL_PENALTIES_FAILURE", response });
      });
  };
};

Actions.onCreatePenalty = (payrollId, data) => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_PAYROLL_PENALTY_REQUEST" });
    const params = {
      payroll_penalty: {
        user_id: data.user ? data.user.id : null,
        penalty: data.penalty,
        description: data.description,
      },
    };
    return api
      .post(`/payrolls/${payrollId}/payroll_penalties`, params)
      .then((response) => {
        dispatch({ type: "POST_PAYROLL_PENALTY_SUCCEED", response });
        api.openNotification("success", "Штраф", "Успешно создан!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Штраф", error);
          });
        }
        dispatch({ type: "POST_PAYROLL_PENALTY_FAILURE", response });
      });
  };
};

Actions.onSavePenalty = (payrollId, data) => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_PAYROLL_PENALTY_REQUEST" });
    const params = {
      payroll_penalty: {
        user_id: data.user ? data.user.id : null,
        penalty: data.penalty,
        description: data.description,
      },
    };
    return api
      .patch(`/payrolls/${payrollId}/payroll_penalties/${data.id}`, params)
      .then((response) => {
        dispatch({ type: "PATCH_PAYROLL_PENALTY_SUCCEED", response });
        api.openNotification("success", "Штраф", "Успешно обновлен!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Штраф", error);
          });
        }
        dispatch({ type: "PATCH_PAYROLL_PENALTY_FAILURE", response });
      });
  };
};

Actions.onDeletePenalties = (payrollId, ids) => {
  return (dispatch) => {
    dispatch({ type: "DELETE_PAYROLL_PENALTIES_REQUEST" });
    return api
      .patch(`/payrolls/${payrollId}/payroll_penalties/delete`, {
        payroll_penalty: { ids: ids },
      })
      .then((response) => {
        api.openNotification("success", "Штраф", "Строки успешно удалены!");
        dispatch({ type: "DELETE_PAYROLL_PENALTIES_SUCCEED", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Штраф", error);
          });
        }
        dispatch({
          type: "DELETE_PAYROLL_PENALTIES_FAILURE",
        });
      });
  };
};

// payrollItems
Actions.fetchPayrollItems = (id = null) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_PAYROLL_ITEMS_REQUEST" });
    api
      .fetch(`/payrolls/${id}/payroll_items`)
      .then((response) => {
        dispatch({ type: "FETCH_PAYROLL_ITEMS", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Начисления", error);
          });
        }
        dispatch({ type: "FETCH_PAYROLL_ITEMS_FAILURE", response });
      });
  };
};

Actions.onCreatePayrollItem = (payrollId, data) => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_PAYROLL_ITEM_REQUEST" });
    const params = {
      payroll_item: {
        user_id: data.user ? data.user.id : null,
        salary: data.salary,
        wage: data.wage,
        description: data.description,
        // payroll_schema_id: data.payroll_schema ? data.payroll_schema.id : null,
      },
    };
    return api
      .post(`/payrolls/${payrollId}/payroll_items`, params)
      .then((response) => {
        dispatch({ type: "POST_PAYROLL_ITEM_SUCCEED", response });
        api.openNotification("success", "Начисления", "Успешно создан!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Начисления", error);
          });
        }
        dispatch({ type: "POST_PAYROLL_ITEM_FAILURE", response });
      });
  };
};

Actions.onSavePayrollItem = (payrollId, data) => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_PAYROLL_ITEM_REQUEST" });
    const params = {
      payroll_item: {
        user_id: data.user ? data.user.id : null,
        salary: data.salary,
        wage: data.wage,
        description: data.description,
        payroll_schema_id: data.payroll_schema ? data.payroll_schema.id : null,
      },
    };
    return api
      .patch(`/payrolls/${payrollId}/payroll_items/${data.id}`, params)
      .then((response) => {
        dispatch({ type: "PATCH_PAYROLL_ITEM_SUCCEED", response });
        api.openNotification("success", "Начисления", "Успешно обновлен!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Начисления", error);
          });
        }
        dispatch({ type: "PATCH_PAYROLL_ITEM_FAILURE", response });
      });
  };
};

Actions.onDeletePayrollItems = (payrollId, ids) => {
  return (dispatch) => {
    dispatch({ type: "DELETE_PAYROLL_ITEMS_REQUEST" });
    return api
      .patch(`/payrolls/${payrollId}/payroll_items/delete`, {
        payroll_item: { ids: ids },
      })
      .then((response) => {
        api.openNotification(
          "success",
          "Начисления",
          "Строки успешно удалены!"
        );
        dispatch({ type: "DELETE_PAYROLL_ITEMS_SUCCEED", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Штраф", error);
          });
        }
        dispatch({
          type: "DELETE_PAYROLL_ITEMS_FAILURE",
        });
      });
  };
};

Actions.onEmployeeDetails = (payrollId, userId, startAt, endAt) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_PAYROLL_EMPLOYEE_DETAILS_REQUEST" });
    const params = {
      user_id: userId,
      startAt: startAt,
      endAt: endAt,
    };
    api
      .fetch(`/payrolls/${payrollId}/employee_details`, params)
      .then((response) => {
        dispatch({ type: "FETCH_PAYROLL_EMPLOYEE_DETAILS", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Статус", error);
          });
        }
        dispatch({ type: "FETCH_PAYROLL_EMPLOYEE_DETAILS_FAILURE", response });
      });
  };
};

// payrolls
Actions.onCreatePayrolls = (payroll) => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_PAYROLLS_REQUEST" });
    let departments = [];
    if (payroll.departments) {
      departments = payroll.departments.map((department) => {
        if (department && department.id) {
          return department.id;
        }
      });
    }
    const params = {
      startDate: payroll.startDate,
      endDate: payroll.endDate,
      departments: departments,
      description: payroll.description,
    };

    return api
      .post(`/payrolls/steps`, params)
      .then((response) => {
        dispatch({ type: "POST_PAYROLLS_SUCCEED", response });
        api.openNotification("success", "Начисления", "Успешно созданы!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Начисления", error);
          });
        }
        dispatch({ type: "POST_PAYROLLS_FAILURE", response });
      });
  };
};

export default Actions;
