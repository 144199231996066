// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../../../actions/payrollSchemas";
import LoadingSpin from "../../../../components/LoadingSpin";
import Form from "./Form";

import moment from "moment";
import "moment/locale/ru";

class Payroll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: false,
    };
  }

  componentWillMount = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(
        Actions.fetchPayrollSchema(this.props.match.params.id)
      );
    } else {
      this.setState({ isNew: true }, () => {
        this.props.dispatch(Actions.onNew());
      });
    }
  };

  onSave = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.onUpdate(this.props.match.params.id));
    } else {
      this.props.dispatch(Actions.onCreate()).then(() => {
        if (!this.props.errors) {
          this.props.history.push(
            `/finances/payroll_schemas/${this.props.payrollSchema.id}/edit`
          );
        }
      });
    }
  };

  onClose = () => {
    this.props.history.push(`/finances/payroll_schemas`);
  };

  // header
  onChangeName = (e) => {
    this.props.dispatch(Actions.onChangeName(e.target.value));
  };

  onChangeDescription = (e) => {
    this.props.dispatch(Actions.onChangeDescription(e.target.value));
  };

  onChangeOrders = (value) => {
    this.props.dispatch(Actions.onChangeOrders(value));
  };

  // payroll_schema_values
  onAddPayrollSchemaValue = (item) => {
    this.props.dispatch(Actions.onAddPayrollSchemaValue(item));
  }

  onDeleteSchemaValue = (id) => {
    let idx = this.props.payrollSchema.payroll_schema_values.findIndex(
      function (o) {
        return o.id == id;
      }
    );
    if (idx === -1) {
      return null;
    }
    if (this.props.payrollSchema.payroll_schema_values[idx].is_exist) {
      this.props.dispatch(Actions.onDeleteSchemaValue(idx));
    } else {
      this.props.dispatch(Actions.onDestroySchemaValue(idx));
    }
  };

  onChangeSchemaValueName = (id, e) => {
    this.props.dispatch(Actions.onChangeSchemaValueName(id, e.target.value));
  };

  onChangeSchemaValueWeight = (id, value) => {
    this.props.dispatch(Actions.onChangeSchemaValueWeight(id, value));
  };

  onChangeSchemaValue = (id, value) => {
    this.props.dispatch(Actions.onChangeSchemaValue(id, value));
  };

  onChangeSchemaValueFrom = (id, value) => {
    this.props.dispatch(Actions.onChangeSchemaValueFrom(id, value));
  };

  onChangeSchemaValueTo = (id, value) => {
    this.props.dispatch(Actions.onChangeSchemaValueTo(id, value));
  };

  render() {
    const {
      isLoading,
      isLoadindPayrollItems,
      errors,
      payrollSchema,
    } = this.props;
    return isLoading || errors ? (
      <LoadingSpin />
    ) : (
      <Form
        isLoading={isLoading}
        isLoadindPayrollItems={isLoadindPayrollItems}
        isNew={this.state.isNew}
        payrollSchema={payrollSchema}
        onSave={this.onSave}
        onClose={this.onClose}
        // header
        onChangeName={this.onChangeName}
        onChangeDescription={this.onChangeDescription}
        onChangeOrders={this.onChangeOrders}
        // payroll_schema_values
        onAddPayrollSchemaValue={this.onAddPayrollSchemaValue}
        onDeleteSchemaValue={this.onDeleteSchemaValue}
        onChangeSchemaValueName={this.onChangeSchemaValueName}
        onChangeSchemaValueWeight={this.onChangeSchemaValueWeight}
        onChangeSchemaValue={this.onChangeSchemaValue}
        onChangeSchemaValueFrom={this.onChangeSchemaValueFrom}
        onChangeSchemaValueTo={this.onChangeSchemaValueTo}
      />
    );
  }
}

Payroll.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  payroll: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isLoading: state.payrollSchemas.isLoading,
  isLoadindPayrollItems: state.payrollSchemas.isLoadindPayrollItems,
  errors: state.payrollSchemas.errors,
  payrollSchema: state.payrollSchemas.payrollSchema,
});

export default connect(mapStateToProps)(withRouter(Payroll));
