// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, Form, Card, Tabs, Switch, Statistic } from "antd";
import Header from "./Header";
import PayrollItems from "./PayrollItems";
import Penalties from "./Penalties";
import Employees from "./Employees";

const { TabPane } = Tabs;

class PayrollForm extends Component {
  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onFillInPayrollItems(values);
      }
    });
  };

  callback = (key) => {
    if (key == "1") {
      this.props.fetchPayrollEmploees();
    }

    if (key == "2") {
      this.props.fetchPayrollItems();
    }

    if (key == "3") {
      this.props.fetchPayrollIPenalties();
    }
  };

  render() {
    const {
      isLoading,
      isLoadindPayrollItems,
      isNew,
      payroll,
      onClose,
      onFillInPayrollItems,
      onChangeActive,
      onChangePeriod,
      onChangeDepartment,
      onChangeDescription,
      onSavePayrollItem,
      onDeletePayrollItems,
      // penalties
      onSavePenalty,
      onDeletePenalties,
      // employee_details
      isLoadindEmployeeDetails,
      employeeDetails,
      onEmployeeDetails,
    } = this.props;
    const { getFieldDecorator } = this.props.form;

    const total = payroll.employees.reduce(
      (sum, record) =>
        sum +
        Number(record.salary) +
        Number(record.wage) -
        Number(record.penalty),
      0
    );

    return (
      <Card
        loading={isLoading}
        title={
          <h2 style={{ marginBottom: 0 }}>{`Начисление #${
            isNew ? "новое" : `${payroll.id}`
          }`}</h2>
        }
        extra={
          <div>
            <Button onClick={onClose}>Закрыть</Button>
            <Button
              onClick={this.onSubmit}
              type="primary"
              ghost
              style={{ marginLeft: "8px" }}
            >
              Заполнить
            </Button>
            {isNew ? null : (
              <Switch
                style={{ marginLeft: "8px" }}
                size="small"
                checked={payroll.is_active}
                onChange={onChangeActive}
              />
            )}
          </div>
        }
      >
        <Form layout={"vertical"}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Row>
              <Header
                isNew={isNew}
                getFieldDecorator={getFieldDecorator}
                payroll={payroll}
                onFillInPayrollItems={onFillInPayrollItems}
                onChangePeriod={onChangePeriod}
                onChangeDepartment={onChangeDepartment}
                onChangeDescription={onChangeDescription}
              />
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Tabs
              defaultActiveKey="1"
              onChange={this.callback}
              tabBarExtraContent={
                <Row gutter={16}>
                  <Col span={12}>
                    {/* <Statistic
                      key={"1"}
                      style={{ padding: 0 }}
                      // title="Day Level"
                      prefix={"Всего: "}
                      groupSeparator=" "
                      value={total}
                      precision={2}
                      valueStyle={{ color: "#1777d1" }}
                      // suffix="р."
                    /> */}
                  </Col>
                  <Col span={24}>
                    <Statistic
                      key={"2"}
                      style={{ padding: 0 }}
                      // title="Day Level"
                      // prefix={"Всего: "}
                      groupSeparator=" "
                      value={total}
                      precision={2}
                      valueStyle={{ color: "#1777d1" }}
                      // suffix="р."
                    />
                  </Col>
                </Row>
              }
            >
              <TabPane tab="Сотрудники" key="1">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Employees
                    isLoadind={isLoadindPayrollItems}
                    employeesItems={payroll.employees}
                    // employee_details
                    isLoadindEmployeeDetails={isLoadindEmployeeDetails}
                    employeeDetails={employeeDetails}
                    onEmployeeDetails={onEmployeeDetails}
                  />
                </Col>
              </TabPane>
              <TabPane tab="Начисления" key="2">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <PayrollItems
                    isNew={isNew}
                    isLoadind={isLoadindPayrollItems}
                    department={payroll.department}
                    payrollItems={payroll.payroll_items}
                    onSavePayrollItem={onSavePayrollItem}
                    onDeletePayrollItems={onDeletePayrollItems}
                  />
                </Col>
              </TabPane>
              <TabPane tab="Штрафы" key="3">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Penalties
                    isNew={isNew}
                    isLoadind={isLoadindPayrollItems}
                    department={payroll.department}
                    payrollPenalties={
                      payroll.payroll_penalties.length > 0
                        ? payroll.payroll_penalties
                        : []
                    }
                    onSavePenalty={onSavePenalty}
                    onDeletePenalties={onDeletePenalties}
                  />
                </Col>
              </TabPane>
            </Tabs>
          </Col>
        </Form>
      </Card>
    );
  }
}

PayrollForm.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  payroll: PropTypes.object,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
  router: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  form: PropTypes.object,
};

export default Form.create()(PayrollForm);
