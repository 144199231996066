// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Table, Button } from "antd";

import moment from "moment";
import "moment/locale/ru";

class WorkTime extends Component {
  constructor(props) {
    super(props);
  }

  onStart = () => {
    this.props.onCreateWorkTime();
  };

  onFinish = () => {
    this.props.onUpdateWorkTime();
  };

  render() {
    const { isReplaced, status, ticketWorkTimes, isLoading } = this.props;

    const columns = [
      {
        title: "Начало",
        dataIndex: "start",
        render: (start) => (start ? moment(start).format("LLL") : null),
        align: "center",
        width: "30%",
      },
      {
        title: "Окончание",
        dataIndex: "finish",
        render: (finish) => (finish ? moment(finish).format("LLL") : null),
        align: "center",
        width: "30%",
      },
      {
        title: "Исполнитель",
        dataIndex: "user",
        render: (user) => (user ? user.name : null),
        align: "center",
        width: "40%",
      },
    ];

    let hours = 0;

    let isStart = true;
    if (ticketWorkTimes.length > 0) {
      isStart = ticketWorkTimes[0].finish ? true : false;
    } else {
      isStart = true;
    }

    const duration = ticketWorkTimes.reduce(
        (sum, record) => sum + Number(record.duration),
        0
      );

    let mainButton = isStart ? (
      <Button key="1" type="primary" ghost onClick={this.onStart}>
        Возобновить
      </Button>
    ) : (
      <Button key="2" type="danger" ghost onClick={this.onFinish}>
        Приостановить
      </Button>
    );

    let isMainButtonVisible = false

    if (status.id == "working") {
        isMainButtonVisible = true
    }

    return (
      <React.Fragment>
        <Row gutter={{ md: 8, lg: 24, xl: 48 }} style={{ paddingBottom: 10 }}>
          <Col span={12}>{isMainButtonVisible ? mainButton : null}</Col>
          <Col span={12} style={{textAlign: "right"}}>{duration > 0 ? moment.duration(duration / 3600, 'hours').humanize() : null}</Col>
        </Row>
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col span={24}>
            <Table
              size={"small"}
              loading={isLoading}
              pagination={false}
              columns={columns}
              dataSource={ticketWorkTimes}
              rowKey="id"
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

WorkTime.propTypes = {
  ticketWorkTime: PropTypes.arrayOf(PropTypes.object),
};
export default WorkTime;
