import update from "immutability-helper";
const initialState = {
  fieldSets: [],
  fieldSet: {options: []},
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1,
  },
  fetchParams: {
    search: null,
    sort: null,
    direction: null,
    page: 1,
    limit: 15,
  },
  isLoading: true,
  errors: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    // fieldSets
    case "FETCH_FIELD_SETS_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };
    case "FETCH_FIELD_SETS":
      return {
        ...state,
        fieldSets: action.response.data,
        isLoading: false,
        errors: false,
      };

    case "FETCH_FIELD_SETS_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "FETCH_FIELD_SET_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };

    case "FETCH_FIELD_SET":
      return {
        ...state,
        fieldSet: action.response.data,
        isLoading: false,
        errors: false,
      };

    case "FETCH_FIELD_SET_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "PATCH_FIELD_SET_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };
    case "PATCH_FIELD_SET":
      return {
        ...state,
        fieldSet: action.response.data,
        isLoading: false,
        errors: false,
      };

    case "PATCH_FIELD_SET_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "POST_FIELD_SET_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "POST_FIELD_SET":
      return {
        ...state,
        errors: false,
        fieldSet: action.response.data,
        isLoading: false,
      };

    case "POST_FIELD_SET_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "DELETE_FIELD_SET_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };

    case "DELETE_FIELD_SET":
      return {
        ...state,
        isLoading: false,
        errors: false,
      };

    case "DELETE_FIELD_SET_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

      case "FIELD_SET_NEW":
      return {
        ...state,
        errors: false,
        fieldSet: {
          is_active: false,
          owner_type: null,
          field_set_type: null,
          options: [],
        },
        isLoading: false,
      };

    case "REQUEST_FIELD_SET_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } },
      });

    case "REQUEST_FIELD_SET_CHANGE_LIMIT":
      return update(state, {
        fetchParams: { limit: { $set: action.value }, page: { $set: 1 } },
      });

    case "FIELD_SETS_SORT_BY":
      return update(state, {
        fetchParams: {
          sort: { $set: action.field },
          direction: { $set: action.direction },
        },
      });

    // form
    case "FIELD_SET_CHANGE_NAME":
      return update(state, {
        fieldSet: { name: { $set: action.value } },
      });

    case "FIELD_SET_CHANGE_PRESENTATION":
      return update(state, {
        fieldSet: { presentation: { $set: action.value } },
      });

    case "FIELD_SET_CHANGE_OWNER_TYPE":
      return update(state, {
        fieldSet: { owner_type: { $set: action.value } },
      });

    case "FIELD_SET_CHANGE_FIELD_SET_TYPE":
      return update(state, {
        fieldSet: { field_set_type: { $set: action.value } },
      });

    // sidebar
    case "FIELD_SET_CHANGE_IS_ACTIVE":
      return update(state, {
        fieldSet: { is_active: { $set: action.value } },
      });

    case "FIELD_SET_CHANGE_IS_REQUIRED":
      return update(state, {
        fieldSet: { is_required: { $set: action.value } },
      });

    // options
    case "FIELD_SET_OPTON_CREATE":
      return update(state, {
        fieldSet: { options: { $push: [action.item] } },
      });

    case "FIELD_SET_OPTON_UPDATE":
      return update(state, {
        fieldSet: { options: { [action.idx]: { $set: action.item } } },
      });

    case "FIELD_SET_OPTON_DELETE":
      return update(state, {
        fieldSet: {
          options: {
            [action.idx]: { _destroy: { $set: true } },
          },
        },
      });

    case "FIELD_SET_OPTON_DESTROY":
      return update(state, {
        fieldSet: {
          options: { $splice: [[action.idx, 1]] },
        },
      });

    default:
      return state;
  }
}
