import update from "immutability-helper";

const initialState = {
  todoTemplates: [],
  todoTemplate: {
    todo_template_items: [],
    services: [],
  },
  todoItem: {},
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1,
  },
  fetchParams: {
    sort: null,
    direction: null,
    page: 1,
    limit: 15,
  },
  errors: false,
  isLoading: true,
  isTodoItemVisible: false,
};

export default function (state = initialState, action) {
  let idx = -1;
  switch (action.type) {
    case "FETCH_TODO_TEMPLATES_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_TODO_TEMPLATES":
      return {
        ...state,
        todoTemplates: action.response.data,
        meta: action.response.meta,
        isLoading: false,
      };
    case "FETCH_TODO_TEMPLATE_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_TODO_TEMPLATE":
      return {
        ...state,
        todoTemplate: action.response.data,
        isLoading: false,
      };
    case "PATCH_TODO_TEMPLATE_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };
    case "PATCH_TODO_TEMPLATE":
      return {
        ...state,
        todoTemplate: action.response.data,
        isLoading: false,
        errors: false,
      };

    case "PATCH_TODO_TEMPLATE_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "TODO_TEMPLATE_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "TODO_TEMPLATE_SUCCEED":
      return {
        ...state,
        errors: false,
        todoTemplate: action.response.data,
        isLoading: false,
      };

    case "TODO_TEMPLATE_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "DELETE_TODO_TEMPLATE_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };
    case "DELETE_TODO_TEMPLATE":
      return {
        ...state,
        isLoading: false,
        errors: false,
      };
    case "DELETE_TODO_TEMPLATE_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    // pagination
    case "REQUEST_TODO_TEMPLATE_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } },
      });

    case "REQUEST_TODO_TEMPLATE_CHANGE_LIMIT":
      return update(state, {
        fetchParams: { limit: { $set: action.value }, page: { $set: 1 } },
      });

    case "TODO_TEMPLATES_SORT_BY":
      return update(state, {
        fetchParams: {
          sort: { $set: action.field },
          direction: { $set: action.direction },
        },
      });

    case "TODO_TEMPLATE_NEW":
      return {
        ...state,
        errors: false,
        todoTemplate: {
          is_active: false,
          name: null,
          description: null,
          todo_type: 1,
          todo_template_items: [],
        },
        isLoading: false,
      };

    case "TODO_TEMPLATE_CHANGE_ACTIVE":
      return update(state, {
        todoTemplate: { is_active: { $set: !state.todoTemplate.is_active } },
      });

    case "TODO_TEMPLATE_CHANGE_NAME":
      return update(state, {
        todoTemplate: { name: { $set: action.value } },
      });

    case "TODO_TEMPLATE_CHANGE_DESCRIPTION":
      return update(state, {
        todoTemplate: { description: { $set: action.value } },
      });

    case "TODO_TEMPLATE_CHANGE_TODO_TYPE":
      // const items = state.todoTemplate.todo_template_items.map((item, i) => {
      //   return update(state.todoTemplate.todo_template_items, {
      //     [i]: { _destroy: { $set: true } } ,

      //   });
      // });
      // console.log(items)
      return update(state, {
        todoTemplate: {
          todo_type: { $set: action.value },
          // todo_template_items: { $set: items[0] },
        },
      });

    case "TODO_TEMPLATE_CHANGE_SERVICES":
      return update(state, {
        todoTemplate: {
          services: { $set: action.value },
        },
      });

    // todos
    case "TODO_TEMPLATE_ADD_TODO":
      return update(state, {
        todoTemplate: { todo_template_items: { $push: [action.value] } },
      });

    case "TODO_TEMPLATE_DESTROY_TODO":
      idx = state.todoTemplate.todo_template_items.findIndex(function (o) {
        return o.id == action.id;
      });
      if (idx === -1) {
        return null;
      }

      if (state.todoTemplate.todo_template_items[idx].is_exist) {
        return update(state, {
          todoTemplate: {
            todo_template_items: { [idx]: { _destroy: { $set: true } } },
          },
        });
      } else {
        return update(state, {
          todoTemplate: {
            todo_template_items: { $splice: [[idx, 1]] },
          },
        });
      }

    // todo_items
    case "TODO_ITEM_REQUEST":
      return {
        ...state,
        errors: false,
      };
    case "TODO_ITEM_SUCCEED":
      return {
        ...state,
        errors: false,
        isTodoItemVisible: false,
      };

    case "TODO_ITEM_FAILURE":
      return {
        ...state,
        errors: true,
      };

    case "TODO_ITEM_NEW":
      return {
        ...state,
        todoItem: {
          is_active: true,
          todo_type: action.value,
          name: null,
        },
        isTodoItemVisible: true,
      };

    case "TODO_ITEM_CANCEL":
      return {
        ...state,
        todoItem: {
          name: null,
        },
        isTodoItemVisible: false,
      };

    case "TODO_ITEM_CHANGE_NAME":
      return update(state, {
        todoItem: { name: { $set: action.value } },
      });

    default:
      return state;
  }
}
