import update from "immutability-helper";
import moment from "moment";

const initialState = {
  tickets: [],
  ticket: {
    user: {},
    assignees: [],
    comments: [],
    attachments: [],
    services: [],
  },
  ticket_activities: [],
  tasks: [],
  ticket_work_times: [],
  notices: [],
  actions: [],
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1,
  },
  fetchParams: {
    startDate: moment().startOf("month").format("DD.MM.YYYY"),
    endDate: moment().endOf("month").format("DD.MM.YYYY"),
    startFinishDate: null,
    endFinishDate: null,
    startCreatedDate: null,
    endCreatedDate: null,
    search: null,
    sort: null,
    direction: null,
    page: 1,
    limit: 15,
    status: null,
    is_replaced: null,
    is_ordered: null,
    is_rated: null,
    user: null,
    users: null,
    customer: null,
    members: null,
    priorities: null,
    categories: null,
    services: null,
  },
  errors: false,
  isLoading: true,
  isCommentsLoading: false,
  isAssigneesLoading: false,
  isUploading: false,
  isActivitiesLoading: true,
  isTasksLoading: true,
  isReplacementTicket: false,
  isWorkTimesLoading: true,
};

function loadLocalFilters(fetchParams) {
  let localFetchParams = null;
  const localFilters = localStorage.getItem("ticketsFilters");
  if (localFilters) {
    localFetchParams = JSON.parse(localFilters);
  } else {
    localStorage.setItem("ticketsFilters", JSON.stringify(fetchParams));
  }

  return localFetchParams;
}

export default function (state = initialState, action) {
  let localFetchParams = null;
  switch (action.type) {
    case "FETCH_TICKETS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_TICKETS":
      return {
        ...state,
        tickets: action.response.data,
        meta: action.response.meta,
        notices: action.response.notices,
        isLoading: false,
      };
    case "FETCH_TICKET_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_TICKET":
      return {
        ...state,
        ticket: action.response.data,
        isLoading: false,
      };

    case "FETCH_TICKET_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "PATCH_TICKET_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "PATCH_TICKET":
      return {
        ...state,
        errors: false,
        ticket: action.response.data,
        isLoading: false,
      };

    case "PATCH_TICKET_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "POST_TICKET_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "POST_TICKET":
      return {
        ...state,
        errors: false,
        ticket: action.response.data,
        isLoading: false,
      };
    case "POST_TICKET_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "NEW_TICKET_FOR_TASK_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "NEW_TICKET_FOR_TASK_SUCCESS":
      return {
        ...state,
        ticket: action.response.data,
        isLoading: false,
      };
    case "NEW_TICKET_FOR_TASK_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "CREATE_TICKET_FOR_TASK_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "CREATE_TICKET_FOR_TASK_SUCCESS":
      return {
        ...state,
        errors: false,
        ticket: action.response.data,
        isLoading: false,
      };
    case "CREATE_TICKET_FOR_TASK_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "DELETE_TICKET_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "DELETE_TICKET":
      return {
        ...state,
        errors: false,
        isLoading: false,
      };
    case "DELETE_TICKET_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "TICKET_NEW":
      return {
        ...state,
        errors: false,
        ticket: {
          status: "pending",
          due_by: null,
          customer: null,
          ticket_priority: null,
          user: action.user,
          assignees: [],
          comments: [],
          attachments: [],
          services: null,
        },
        isLoading: false,
      };

    case "REQUEST_TICKET_SEARCH":
      return update(state, {
        fetchParams: { search: { $set: action.value }, page: { $set: 1 } },
      });

    // pagination
    case "REQUEST_TICKET_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } },
      });

    case "REQUEST_TICKET_CHANGE_LIMIT":
      return update(state, {
        fetchParams: { limit: { $set: action.value }, page: { $set: 1 } },
      });

    case "REQUEST_TICKET_FILTER_ASSIGNEE":
      return update(state, {
        fetchParams: { assignee: { $set: action.value } },
      });

    case "REQUEST_TICKET_FILTER_STATUS":
      return update(state, {
        fetchParams: { status: { $set: action.value } },
      });

    case "TICKET_CLEAR_FILTERS":
      return update(state, {
        fetchParams: {
          status: { $set: null },
        },
      });

    case "TICKETS_SORT_BY":
      return update(state, {
        fetchParams: {
          sort: { $set: action.field },
          direction: { $set: action.direction },
        },
      });

    case "TICKET_SHOW_MODAL_FORM":
      return update(state, {
        isVisibleModalForm: { $set: !state.isVisibleModalForm },
        ticket: {
          customer: { $set: null },
        },
      });

    case "TICKET_CHANGE_MODAL_FORM_VISIBLE":
      return update(state, {
        isVisibleModalForm: { $set: !state.isVisibleModalForm },
      });

    // General
    case "TICKET_CHANGE_NUMBER":
      return update(state, {
        ticket: { number: { $set: action.value } },
      });

    case "TICKET_CHANGE_CUSTOMER":
      return update(state, {
        ticket: {
          customer: { $set: action.value },
          entity: { $set: null },
          entity_service: { $set: null },
          ticket_priority: { $set: null },
          address: { $set: null },
          services: { $set: [] },
        },
      });

    case "TICKET_CHANGE_CUSTOMER_ENTITY":
      return update(state, {
        ticket: {
          entity: { $set: action.value },
          category: { $set: null },
          services: { $set: [] },
          entity_service: { $set: null },
          due_by: { $set: null },
          address: { $set: null },
        },
      });

    case "TICKET_CHANGE_ENTITY_SERVICE":
      return update(state, {
        ticket: {
          entity_service: { $set: action.value },
          services: { $set: [] },
          category: { $set: action.value.extra },
        },
      });

    case "TICKET_CHANGE_CATEGORY":
      return update(state, {
        ticket: { category: { $set: action.value }, services: { $set: [] } },
      });

    case "TICKET_CHANGE_SERVICES":
      return update(state, {
        ticket: { services: { $set: action.values } },
      });

    case "TICKET_CHANGE_STATUS":
      return update(state, {
        ticket: { status: { $set: action.value } },
      });

    case "TICKET_CHANGE_PIORITY":
      return update(state, {
        ticket: {
          ticket_priority: { $set: action.value },
          due_by: { $set: null },
        },
      });

    case "TICKET_CHANGE_INCIDENT":
      return update(state, {
        ticket: { incident: { $set: action.value } },
      });

    case "TICKET_CHANGE_AMOUNT":
      return update(state, {
        ticket: { amount: { $set: action.value } },
      });

    case "TICKET_CHANGE_SUBJECT":
      return update(state, {
        ticket: { subject: { $set: action.value } },
      });

    case "TICKET_CHANGE_DESCRIPTION":
      return update(state, {
        ticket: { description: { $set: action.value } },
      });

    case "TICKET_CHANGE_DUE_BY":
      return update(state, {
        ticket: { due_by: { $set: action.value } },
      });

    case "TICKET_CHANGE_ORDER":
      return update(state, {
        ticket: { order: { $set: action.value } },
      });

    case "TICKET_CHANGE_IS_ORGANISATION":
      return update(state, {
        ticket: {
          is_organisation: { $set: action.value },
          entity: { $set: null },
          entity_service: { $set: null },
          services: { $set: null },
          customer: { $set: null },
          company: { $set: null },
          ticket_priority: { $set: null },
        },
      });

    case "TICKET_CHANGE_IS_SUPPLIER":
      return update(state, {
        ticket: { is_supplier: { $set: action.value } },
      });

    case "TICKET_CHANGE_COMPANY":
      return update(state, {
        ticket: { company: { $set: action.value } },
      });

    // comments
    case "FETCH_TICKET_COMMENTS_REQUEST":
      return {
        ...state,
        isCommentsLoading: true,
      };
    case "FETCH_TICKET_COMMENTS":
      return update(state, {
        ticket: { comments: { $set: action.response.data } },
        isCommentsLoading: { $set: false },
      });

    case "FETCH_TICKET_COMMENTS_FAILURE":
      return {
        ...state,
        errors: true,
        isCommentsLoading: false,
      };

    case "POST_TICKET_COMMENT_REQUEST":
      return {
        ...state,
        errors: false,
        isCommentsLoading: true,
      };
    case "POST_TICKET_COMMENT":
      return {
        ...state,
        errors: false,
        isCommentsLoading: false,
      };

    case "POST_TICKET_COMMENT_FAILURE":
      return {
        ...state,
        errors: true,
        isCommentsLoading: false,
      };

    // attachments
    case "FETCH_ATTACHMENTS":
      return update(state, {
        ticket: { attachments: { $set: action.response.data } },
        isUploading: { $set: false },
      });
    case "FETCH_ATTACHMENT_REQUEST":
      return {
        ...state,
        isUploading: true,
      };

    case "TICKET_ATTACHMENT_UPLOAD":
      return update(state, {
        isUploading: { $set: true },
      });

    case "TICKET_ATTACHMENT_UPLOAD_SUCCEED":
      return update(state, {
        // ticket: { attachments: { $set: action.response.data } },
        errors: { $set: false },
        isUploading: { $set: false },
      });

    case "TICKET_ATTACHMENT_UPLOAD_FAILED":
      return update(state, {
        // errors: { $set: true },
        isUploading: { $set: false },
      });

    case "ATTACHMENT_DELETE_REQUEST":
      return {
        ...state,
        errors: false,
        isUploading: true,
      };
    case "ATTACHMENT_DELETE_SUCCEED":
      return {
        ...state,
        errors: false,
        isUploading: false,
      };
    case "ATTACHMENT_DELETE_FAILED":
      return {
        ...state,
        errors: true,
        isUploading: false,
      };

    // statuses
    case "PATCH_TICKET_REQUEST_STATUS":
      return {
        ...state,
        errors: false,
      };
    case "PATCH_TICKET_STATUS":
      return update(state, {
        ticket: {
          status: { $set: action.response.data.status },
          due_by: { $set: action.response.data.due_by },
        },
      });

    case "PATCH_TICKET_STATUS_FAILURE":
      return {
        ...state,
        errors: true,
      };

    // replaced
    case "PATCH_TICKET_REQUEST_REPLACED":
      return {
        ...state,
        errors: false,
      };

    case "PATCH_TICKET_REPLACED":
      return update(state, {
        ticket: {
          status: { $set: action.response.data },
          is_replaced: { $set: action.value },
        },
      });

    case "PATCH_TICKET_REPLACED_FAILURE":
      return {
        ...state,
        errors: true,
      };

    // ordered
    case "PATCH_TICKET_REQUEST_ORDERED":
      return {
        ...state,
        errors: false,
      };

    case "PATCH_TICKET_ORDERED":
      return update(state, {
        ticket: {
          status: { $set: action.response.data },
          is_ordered: { $set: action.value },
          order: { $set: action.value ? state.ticket.order : null },
        },
      });

    case "PATCH_TICKET_ORDERED_FAILURE":
      return {
        ...state,
        errors: true,
      };

    // activities
    case "FETCH_TICKET_ACTIVITIES_REQUEST":
      return {
        ...state,
        isActivitiesLoading: true,
      };
    case "FETCH_TICKET_ACTIVITIES":
      return update(state, {
        ticket_activities: { $set: action.response.data },
        isActivitiesLoading: { $set: false },
        errors: { $set: false },
      });

    case "FETCH_TICKET_ACTIVITIES_FAILURE":
      return {
        ...state,
        errors: true,
        isActivitiesLoading: false,
      };

    // tasks
    case "FETCH_TICKET_TASKS_REQUEST":
      return {
        ...state,
        isTasksLoading: true,
      };
    case "FETCH_TICKET_TASKS":
      return update(state, {
        tasks: { $set: action.response.data },
        isTasksLoading: { $set: false },
        errors: { $set: false },
      });

    case "FETCH_TICKET_TASKS_FAILURE":
      return {
        ...state,
        errors: true,
        isTasksLoading: false,
      };

    // ticket_work_times
    case "FETCH_TICKET_WORK_TIME_REQUEST":
      return {
        ...state,
        isWorkTimesLoading: true,
      };
    case "FETCH_TICKET_WORK_TIME":
      return update(state, {
        ticket_work_times: { $set: action.response.data },
        isWorkTimesLoading: { $set: false },
        errors: { $set: false },
      });

    case "FETCH_TICKET_WORK_TIME_FAILURE":
      return {
        ...state,
        errors: true,
        isWorkTimesLoading: false,
      };

    case "POST_TICKET_WORK_TIME_REQUEST":
      return {
        ...state,
        errors: false,
        isWorkTimesLoading: true,
      };
    case "POST_TICKET_WORK_TIME":
      return update(state, {
        ticket_work_times: { $set: action.response.data },
        isWorkTimesLoading: { $set: false },
        errors: { $set: false },
      });

    case "POST_TICKET_WORK_TIME_FAILURE":
      return {
        ...state,
        errors: true,
        isWorkTimesLoading: false,
      };

    case "PATCH_TICKET_WORK_TIME_REQUEST":
      return {
        ...state,
        errors: false,
        isWorkTimesLoading: true,
      };
    case "PATCH_TICKET_WORK_TIME":
      return update(state, {
        ticket_work_times: { $set: action.response.data },
        isWorkTimesLoading: { $set: false },
        errors: { $set: false },
      });

    case "PATCH_TICKET_WORK_TIME_FAILURE":
      return {
        ...state,
        errors: true,
        isWorkTimesLoading: false,
      };

    // assignees
    case "FETCH_ASSIGNEES_REQUEST":
      return {
        ...state,
        isAssigneesLoading: true,
      };
    case "FETCH_ASSIGNEES":
      return update(state, {
        ticket: { assignees: { $set: action.response.data } },
        isAssigneesLoading: { $set: false },
      });

    case "FETCH_ASSIGNEES_FAILURE":
      return {
        ...state,
        errors: true,
        isAssigneesLoading: false,
      };

    case "POST_ASSIGNEE_REQUEST":
      return {
        ...state,
        errors: false,
        isAssigneesLoading: true,
      };
    case "POST_ASSIGNEE":
      return {
        ...state,
        errors: false,
        isAssigneesLoading: false,
      };

    case "POST_ASSIGNEE_FAILURE":
      return {
        ...state,
        errors: true,
        isAssigneesLoading: false,
      };

    // filters
    case "TICKETS_SET_FILTERS":
      localFetchParams = loadLocalFilters(state.fetchParams);
      if (localFetchParams) {
        localFetchParams.search = null;
        return {
          ...state,
          fetchParams: localFetchParams,
        };
      } else {
        return {
          ...state,
        };
      }

    case "TICKETS_CLEAR_FILTERS":
      return update(state, {
        fetchParams: {
          status: { $set: null },
          replaced_status: { $set: null },
          user: { $set: null },
          users: { $set: null },
          members: { $set: null },
          page: { $set: 1 },
        },
      });

    case "TICKETS_FILTER_STATUS":
      return update(state, {
        fetchParams: {
          status: { $set: action.values.length > 0 ? action.values : null },
          page: { $set: 1 },
        },
      });

    case "TICKETS_FILTER_REPLACED":
      return update(state, {
        fetchParams: {
          is_replaced: {
            $set: action.value,
          },
          page: { $set: 1 },
        },
      });

    case "TICKETS_FILTER_ORDERED":
      return update(state, {
        fetchParams: {
          is_ordered: {
            $set: action.value,
          },
          page: { $set: 1 },
        },
      });

    case "TICKETS_FILTER_PERIOD":
      return update(state, {
        fetchParams: {
          startDate: { $set: action.startDate },
          endDate: { $set: action.endDate },
          page: { $set: 1 },
        },
      });

    case "TICKETS_FILTER_FINISH_PERIOD":
      return update(state, {
        fetchParams: {
          startFinishDate: { $set: action.startDate },
          endFinishDate: { $set: action.endDate },
          page: { $set: 1 },
        },
      });

    case "TICKETS_FILTER_CREATED_PERIOD":
      return update(state, {
        fetchParams: {
          startCreatedDate: { $set: action.startDate },
          endCreatedDate: { $set: action.endDate },
          page: { $set: 1 },
        },
      });

    case "TICKETS_FILTER_USER":
      return update(state, {
        fetchParams: {
          user: { $set: action.values.length > 0 ? action.values : null },
          page: { $set: 1 },
        },
      });

    case "TICKETS_FILTER_USERS":
      return update(state, {
        fetchParams: {
          users: { $set: action.values.length > 0 ? action.values : null },
          page: { $set: 1 },
        },
      });

    case "TICKETS_FILTER_CUSTOMER":
      return update(state, {
        fetchParams: {
          customer: { $set: action.value },
          page: { $set: 1 },
        },
      });

    case "TICKETS_FILTER_MEMBERS":
      return update(state, {
        fetchParams: {
          members: { $set: action.values.length > 0 ? action.values : null },
          page: { $set: 1 },
        },
      });

    case "TICKETS_FILTER_PRIORITIES":
      return update(state, {
        fetchParams: {
          priorities: { $set: action.values.length > 0 ? action.values : null },
          page: { $set: 1 },
        },
      });

    case "TICKETS_FILTER_CATEGORIES":
      return update(state, {
        fetchParams: {
          categories: { $set: action.values.length > 0 ? action.values : null },
          page: { $set: 1 },
        },
      });

    case "TICKETS_FILTER_SERVICES":
      return update(state, {
        fetchParams: {
          services: { $set: action.values.length > 0 ? action.values : null },
          page: { $set: 1 },
        },
      });

    case "TICKETS_FILTER_RATED":
      return update(state, {
        fetchParams: {
          is_rated: {
            $set: action.value,
          },
          page: { $set: 1 },
        },
      });

    // notices
    case "POST_TICKET_MARK_ALL_AS_READ_REQUEST":
      return {
        ...state,
        errors: false,
      };
    case "POST_TICKET_MARK_ALL_AS_READ":
      return {
        ...state,
        errors: false,
        notices: action.response.data,
      };

    case "POST_TICKET_MARK_ALL_AS_READ_FAILURE":
      return {
        ...state,
        errors: true,
      };

    default:
      return state;
  }
}
