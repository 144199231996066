// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, Form, Card } from "antd";
import Header from "./Header";
import Statuses from "./Statuses";

class UserForm extends Component {
  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSave(values);
      }
    });
  };

  render() {
    const {
      onClose,
      isNew,
      isLoading,
      isUploadingAvatar,
      onUploadAvatar,
      user,
      onChangeActive,
      onChangeStart,
      onChangeFinish,
      onChangeRole,
      onChangeName,
      onChangeDepartment,
      onChangeEmail,
      onChangePhone,
      onChangeTitle,
      onChangePassword,
      onChangePasswordConfirmation,
      onUpdatePassword,
      // settings
      onChangeTickets,
    } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Card
        loading={isLoading}
        title={
          <h2 style={{ marginBottom: 0 }}>{`Пользователь #${
            isNew ? " новый" : `${user.name}`
          }`}</h2>
        }
        extra={
          <div>
            <Button onClick={onClose}>Закрыть</Button>
            <Button
              onClick={this.onSubmit}
              type="primary"
              ghost
              style={{ marginLeft: "8px" }}
            >
              Сохранить
            </Button>
          </div>
        }
      >
        <Form layout={"vertical"}>
          <Col xs={24} sm={24} md={24} lg={14} xl={14}>
            <Row>
              <Header
                isNew={isNew}
                name={user.name}
                department={user.department}
                title={user.title}
                phone={user.phone}
                email={user.email}
                password={user.password}
                password_confirmation={user.password_confirmation}
                getFieldDecorator={getFieldDecorator}
                onChangeName={onChangeName}
                onChangeDepartment={onChangeDepartment}
                onChangeEmail={onChangeEmail}
                onChangePhone={onChangePhone}
                onChangeTitle={onChangeTitle}
                onChangePassword={onChangePassword}
                onChangePasswordConfirmation={onChangePasswordConfirmation}
                onUpdatePassword={onUpdatePassword}
                // settings
                settings={user.user_profile.settings}
                onChangeTickets={onChangeTickets}
              />
            </Row>
          </Col>
          <Col xs={0} sm={0} md={1} lg={1} xl={1} />
          <Col xs={24} sm={24} md={24} lg={9} xl={9}>
            <Row>
              <Statuses
                isNew={isNew}
                avatar={user.avatar}
                isUploadingAvatar={isUploadingAvatar}
                onUploadAvatar={onUploadAvatar}
                is_active={user.is_active}
                start={user.start}
                finish={user.finish}
                role={user.role}
                rates={user.rates}
                getFieldDecorator={getFieldDecorator}
                onChangeActive={onChangeActive}
                onChangeRole={onChangeRole}
                onChangeStart={onChangeStart}
                onChangeFinish={onChangeFinish}
              />
            </Row>
          </Col>
        </Form>
      </Card>
    );
  }
}

UserForm.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  user: PropTypes.object,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
  router: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  form: PropTypes.object,
  onChangeName: PropTypes.func,
};

export default Form.create()(UserForm);
