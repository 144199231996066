/// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../actions/session";

import Dashboard from "../Dashboard";
import MainLayout from "../Layout";
import NotFound from "../../components/NotFound";
import NotAuthorized from "../../components/ NotAuthorized";
import Login from "../Login";
import Recovery from "../Recovery";
import Reset from "../Reset";
import Confirm from "../Confirm";
import Profile from "../Profile";

import Tickets from "../Tickets/List";
import Ticket from "../Tickets/Form";

import Customers from "../Customers/List";
import Customer from "../Customers/Form";

import Suppliers from "../Suppliers/List";

import Entities from "../Entities/List";
import Entity from "../Entities/Form";
import EntityTypes from "../EntityTypes/List";
import EntitiesMap from "../Entities/EntitiesMap";

import EntityTasks from "../EntityTasks/List";
import EntityTask from "../EntityTasks/Form";

import Organisation from "../Organisation";

import Categories from "../Services/Categories";
import Services from "../Services/List";
import Service from "../Services/Form";

import Orders from "../Orders/List";
import Order from "../Orders/Form";

import OrderTasks from "../OrderTasks/List";
import OrderTask from "../OrderTasks/Form";

import ProjectStages from "../ProjectStages/List";
import ProjectTypes from "../ProjectTypes/List";

import Projects from "../Projects/List";
import Project from "../Projects/Form";
import ProjectSteps from "../Projects/Steps";

import ProjectTasks from "../Projects/Tasks";

import Users from "../Users/List";
import User from "../Users/Form";

import Members from "../Members/List";
import Member from "../Members/Form";

import SupportMessages from "../SupportMessages/List";
import SupportMessage from "../SupportMessages/Form";
import SupportMessageView from "../SupportMessages/View";

import SupportMessageTypes from "../SupportMessageTypes/List";
import SupportMessageType from "../SupportMessageTypes/Form";

import Sms from "../Sms/List";

import Posts from "../Posts/List";
import Post from "../Posts/Form";

import Notices from "../Notices/List";
import Faqs from "../Faqs/List";
import News from "../News/List";

import KnowledgeBase from "../KnowledgeBase";
import Sections from "../KnowledgeBase/Sections/List";
import SectionForm from "../KnowledgeBase/Sections/Form";
import SectionView from "../KnowledgeBase/Sections/View";
import Articles from "../KnowledgeBase/Sections/Articles/List";
import ArticleForm from "../KnowledgeBase/Sections/Articles/Form";
import ArticleView from "../KnowledgeBase/Sections/Articles/View";
import ContentTags from "../ContentTags/List";

// reports
import SLA from "../Reports/SLA";
import SLAE from "../Reports/SLAE";
import OE from "../Reports/OE";
import Plan from "../Reports/Plan";
import Daily from "../Reports/Daily";
import TasksReport from "../Reports/Tasks";
import Employees from "../Reports/Employees";
import EmployeeRates from "../Reports/EmployeeRates";
import Purchases from "../Reports/Purchases";

// finances
import Payrolls from "../Finances/Payrolls/List";
import Payroll from "../Finances/Payrolls/Form";
import PayrollSteps from "../Finances/Payrolls/Steps";

import PayrollSchemas from "../Finances/PayrollSchemas/List";
import PayrollSchema from "../Finances/PayrollSchemas/Form";

import EmployeePayrolls from "../Finances/EmployeePayrolls/List";
import EmployeePayroll from "../Finances/EmployeePayrolls/Form";

// task_templates
import TaskTemplates from "../TaskTemplates/List";
import TaskTemplate from "../TaskTemplates/Form";

// tasks
import Tasks from "../Tasks/List";
import Task from "../Tasks/Form";

// todo_templates
import TodoTemplates from "../TodoTemplates/List";
import TodoTemplate from "../TodoTemplates/Form";

// field_sets
import FieldSets from "../FieldSets/List";
import FieldSet from "../FieldSets/Form";

class App extends Component {
  componentDidMount() {
    const token = localStorage.getItem("token");
    if (token) {
      this.props.dispatch(Actions.authenticate());
    } else {
      this.props.dispatch(Actions.unauthenticate());
    }
  }

  renderRoutes = (isAuthenticated) => {
    const PrivateRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/auth/login",
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );

    return (
      <Router>
        <Switch>
          <Route path="/auth/login" component={Login} />
          <Route path="/auth/recovery" component={Recovery} />
          <Route path="/auth/reset/:token" component={Reset} />
          <Route path="/confirm/:token" component={Confirm} />
          <MainLayout>
            <Switch>
              <PrivateRoute
                isAuthenticated
                path="/"
                exact
                component={Dashboard}
              />

              <PrivateRoute
                isAuthenticated
                path="/organisation"
                component={Organisation}
              />

              <PrivateRoute
                isAuthenticated
                path="/profile"
                component={Profile}
              />

              <PrivateRoute
                isAuthenticated
                path="/tickets"
                exact
                component={Tickets}
              />

              <PrivateRoute
                isAuthenticated
                path="/tickets/new"
                exact
                component={Ticket}
              />
              <PrivateRoute
                isAuthenticated
                path="/tickets/:id/edit"
                exact
                component={Ticket}
              />

              <PrivateRoute
                isAuthenticated
                path="/customers"
                exact
                component={Customers}
              />

              <PrivateRoute
                isAuthenticated
                path="/customers/new"
                exact
                component={Customer}
              />
              <PrivateRoute
                isAuthenticated
                path="/customers/:id/edit"
                exact
                component={Customer}
              />

              <PrivateRoute
                isAuthenticated
                path="/suppliers"
                exact
                component={Suppliers}
              />

              <PrivateRoute
                isAuthenticated
                path="/entities/map"
                exact
                component={EntitiesMap}
              />

              <PrivateRoute
                isAuthenticated
                path="/entities"
                exact
                component={Entities}
              />

              <PrivateRoute
                isAuthenticated
                path="/entities/new"
                exact
                component={Entity}
              />
              <PrivateRoute
                isAuthenticated
                path="/entities/:id/edit"
                exact
                component={Entity}
              />

              <PrivateRoute
                isAuthenticated
                path="/entity_types"
                exact
                component={EntityTypes}
              />

              <PrivateRoute
                isAuthenticated
                path="/entity_tasks"
                exact
                component={EntityTasks}
              />

              <PrivateRoute
                isAuthenticated
                path="/entity_tasks/new"
                exact
                component={EntityTask}
              />

              <PrivateRoute
                isAuthenticated
                path="/entity_tasks/:id/edit"
                exact
                component={EntityTask}
              />

              <PrivateRoute
                isAuthenticated
                path="/orders"
                exact
                component={Orders}
              />

              <PrivateRoute
                isAuthenticated
                path="/orders/new"
                exact
                component={Order}
              />

              <PrivateRoute
                isAuthenticated
                path="/orders/:id/edit"
                exact
                component={Order}
              />

              <PrivateRoute
                isAuthenticated
                path="/order_tasks"
                exact
                component={OrderTasks}
              />

              <PrivateRoute
                isAuthenticated
                path="/order_tasks/new"
                exact
                component={OrderTask}
              />

              <PrivateRoute
                isAuthenticated
                path="/order_tasks/:id/edit"
                exact
                component={OrderTask}
              />

              <PrivateRoute
                isAuthenticated
                path="/project_stages"
                exact
                component={ProjectStages}
              />

              <PrivateRoute
                isAuthenticated
                path="/project_types"
                exact
                component={ProjectTypes}
              />

              <PrivateRoute
                isAuthenticated
                path="/projects"
                exact
                component={Projects}
              />

              <PrivateRoute
                isAuthenticated
                path="/projects/new"
                exact
                component={ProjectSteps}
              />

              <PrivateRoute
                isAuthenticated
                path="/projects/:id/edit"
                exact
                component={Project}
              />

              <PrivateRoute
                isAuthenticated
                path="/project_tasks"
                exact
                component={ProjectTasks}
              />

              <Route
                path="/categories"
                render={({ match: { url } }) => (
                  <>
                    <Route path={`${url}/:categoryID`} component={Categories} />
                    <Route
                      path={`${url}/:categoryID/services`}
                      exact
                      component={Services}
                    />
                    <Route
                      path={`${url}/:categoryID/services/new`}
                      component={Service}
                    />
                    <Route
                      path={`${url}/:categoryID/services/:id/edit`}
                      component={Service}
                    />
                  </>
                )}
              />

              <PrivateRoute
                isAuthenticated
                path="/users"
                exact
                component={Users}
              />

              <PrivateRoute
                isAuthenticated
                path="/users/new"
                exact
                component={User}
              />

              <PrivateRoute
                isAuthenticated
                path="/users/:id/edit"
                exact
                component={User}
              />

              <PrivateRoute
                isAuthenticated
                path="/members"
                exact
                component={Members}
              />

              <PrivateRoute
                isAuthenticated
                path="/members/new"
                exact
                component={Member}
              />

              <PrivateRoute
                isAuthenticated
                path="/members/:id/edit"
                exact
                component={Member}
              />

              <PrivateRoute
                isAuthenticated
                path="/support_message_types"
                exact
                component={SupportMessageTypes}
              />

              <PrivateRoute
                isAuthenticated
                path="/support_message_types/new"
                exact
                component={SupportMessageType}
              />

              <PrivateRoute
                isAuthenticated
                path="/support_message_types/:id/edit"
                exact
                component={SupportMessageType}
              />

              <PrivateRoute
                isAuthenticated
                path="/support_messages"
                exact
                component={SupportMessages}
              />

              <PrivateRoute
                isAuthenticated
                path="/support_messages/new"
                exact
                component={SupportMessage}
              />

              <PrivateRoute
                isAuthenticated
                path="/support_messages/:id/edit"
                exact
                component={SupportMessage}
              />

              <PrivateRoute
                isAuthenticated
                path="/support_messages/:id"
                exact
                component={SupportMessageView}
              />

              <PrivateRoute
                isAuthenticated
                path="/posts"
                exact
                component={Posts}
              />

              <PrivateRoute
                isAuthenticated
                path="/posts/new"
                exact
                component={Post}
              />

              <PrivateRoute
                isAuthenticated
                path="/posts/:id/edit"
                exact
                component={Post}
              />

              <PrivateRoute
                isAuthenticated
                path="/task_templates"
                exact
                component={TaskTemplates}
              />

              <PrivateRoute
                isAuthenticated
                path="/task_templates/new"
                exact
                component={TaskTemplate}
              />

              <PrivateRoute
                isAuthenticated
                path="/task_templates/:id/edit"
                exact
                component={TaskTemplate}
              />

              <PrivateRoute
                isAuthenticated
                path="/tasks"
                exact
                component={Tasks}
              />

              <PrivateRoute
                isAuthenticated
                path="/tasks/:id/edit"
                exact
                component={Task}
              />

              <PrivateRoute
                isAuthenticated
                path="/todo_templates"
                exact
                component={TodoTemplates}
              />

              <PrivateRoute
                isAuthenticated
                path="/todo_templates/new"
                exact
                component={TodoTemplate}
              />

              <PrivateRoute
                isAuthenticated
                path="/todo_templates/:id/edit"
                exact
                component={TodoTemplate}
              />

              <PrivateRoute
                isAuthenticated
                path="/field_sets"
                exact
                component={FieldSets}
              />

              <PrivateRoute
                isAuthenticated
                path="/field_sets/new"
                exact
                component={FieldSet}
              />

              <PrivateRoute
                isAuthenticated
                path="/field_sets/:id/edit"
                exact
                component={FieldSet}
              />

              <PrivateRoute
                isAuthenticated
                path="/faqs"
                exact
                component={Faqs}
              />

              <PrivateRoute
                isAuthenticated
                path="/knowledge_base"
                exact
                component={KnowledgeBase}
              />

              <PrivateRoute
                isAuthenticated
                path="/sections"
                exact
                component={Sections}
              />

              <PrivateRoute
                isAuthenticated
                path="/sections/new"
                exact
                component={SectionForm}
              />

              <PrivateRoute
                isAuthenticated
                path="/sections/:id"
                exact
                component={SectionView}
              />

              <PrivateRoute
                isAuthenticated
                path="/sections/:id/edit"
                exact
                component={SectionForm}
              />

              <PrivateRoute
                isAuthenticated
                path="/sections/:sectionId/articles"
                exact
                component={Articles}
              />

              <PrivateRoute
                isAuthenticated
                path="/sections/:sectionId/articles/new"
                exact
                component={ArticleForm}
              />

              <PrivateRoute
                isAuthenticated
                path="/sections/:sectionId/articles/:id"
                exact
                component={ArticleView}
              />

              <PrivateRoute
                isAuthenticated
                path="/sections/:sectionId/articles/:id/edit"
                exact
                component={ArticleForm}
              />

              <PrivateRoute
                isAuthenticated
                path="/content_tags"
                exact
                component={ContentTags}
              />

              <PrivateRoute
                isAuthenticated
                path="/news"
                exact
                component={News}
              />

              <PrivateRoute
                isAuthenticated
                path="/notices"
                exact
                component={Notices}
              />

              <PrivateRoute isAuthenticated path="/sms" exact component={Sms} />

              <Route
                path="/reports"
                render={({ match: { url } }) => (
                  <>
                    <PrivateRoute
                      isAuthenticated
                      path={`${url}/sla`}
                      component={SLA}
                    />
                    <PrivateRoute
                      isAuthenticated
                      path={`${url}/slae`}
                      component={SLAE}
                    />
                    <PrivateRoute
                      isAuthenticated
                      path={`${url}/oe`}
                      component={OE}
                    />
                    <PrivateRoute
                      isAuthenticated
                      path={`${url}/plan`}
                      component={Plan}
                    />

                    <PrivateRoute
                      isAuthenticated
                      path={`${url}/daily`}
                      component={Daily}
                    />

                    <PrivateRoute
                      isAuthenticated
                      path={`${url}/tasks`}
                      component={TasksReport}
                    />

                    <PrivateRoute
                      isAuthenticated
                      path={`${url}/employees`}
                      component={Employees}
                    />

                    <PrivateRoute
                      isAuthenticated
                      path={`${url}/employee_rates`}
                      component={EmployeeRates}
                    />
                    <PrivateRoute
                      isAuthenticated
                      path={`${url}/purchases`}
                      component={Purchases}
                    />
                  </>
                )}
              />

              <Route
                path="/finances"
                render={({ match: { url } }) => (
                  <>
                    <PrivateRoute
                      isAuthenticated
                      path={`${url}/payrolls`}
                      exact
                      component={Payrolls}
                    />
                    <PrivateRoute
                      isAuthenticated
                      path={`${url}/payrolls/new`}
                      exact
                      component={Payroll}
                    />

                    <PrivateRoute
                      isAuthenticated
                      path={`${url}/payrolls/:id/edit`}
                      exact
                      component={Payroll}
                    />

                    <PrivateRoute
                      isAuthenticated
                      path={`${url}/payrolls/steps`}
                      exact
                      component={PayrollSteps}
                    />

                    <PrivateRoute
                      isAuthenticated
                      path={`${url}/payroll_schemas`}
                      exact
                      component={PayrollSchemas}
                    />

                    <PrivateRoute
                      isAuthenticated
                      path={`${url}/payroll_schemas/new`}
                      exact
                      component={PayrollSchema}
                    />

                    <PrivateRoute
                      isAuthenticated
                      path={`${url}/payroll_schemas/:id/edit`}
                      exact
                      component={PayrollSchema}
                    />
                    <PrivateRoute
                      isAuthenticated
                      path={`${url}/employee_payrolls`}
                      exact
                      component={EmployeePayrolls}
                    />

                    <PrivateRoute
                      isAuthenticated
                      path={`${url}/employee_payrolls/new`}
                      exact
                      component={EmployeePayroll}
                    />

                    <PrivateRoute
                      isAuthenticated
                      path={`${url}/employee_payrolls/:id/edit`}
                      exact
                      component={EmployeePayroll}
                    />
                  </>
                )}
              />
              <Route path={`/notauthorized`} component={NotAuthorized} />
              <Route component={NotFound} />
            </Switch>
          </MainLayout>
        </Switch>
      </Router>
    );
  };

  render() {
    return (
      <div>
        {this.props.isLoading
          ? null
          : this.renderRoutes(this.props.isAuthenticated)}
      </div>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func,
  isAuthenticated: PropTypes.bool.isRequired,
  currentUser: PropTypes.object,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.session.isAuthenticated,
  isLoading: state.session.isLoading,
});
export default connect(mapStateToProps)(App);
