// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table, Button, PageHeader } from "antd";
import { Pagination } from "antd";
import { Row, Col, Input, Tooltip, Icon } from "antd";
import Presentation from "../../../components/Presentation";
import Can from "../../../rules/Can";

import PropTypes from "prop-types";

import moment from "moment";
import "moment/locale/ru";
import Filter from "./Filter";

const Search = Input.Search;

class CustomersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      isFilterVisible: false,
    };
  }

  onFilterToggle = () => {
    this.setState({ isFilterVisible: !this.state.isFilterVisible });
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  onDelete = () => {
    this.props.onDelete(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  onChangeTable = (pagination, filters, sorter) => {
    let direction = "desc";
    if (sorter.order === "descend") {
      direction = "desc";
    } else if (sorter.order === "ascend") {
      direction = "asc";
    }
    this.props.onSortBy(sorter.field, direction);
  };

  render() {
    const { selectedRowKeys, isFilterVisible } = this.state;
    const {
      loading,
      currentUser,
      customers,
      meta,
      fetchParams,
      onSearch,
      onChangePage,
      onChangeLimit,
      // filters
      onClearFilters,
      onChangeFilterUsers,
      onChangeFilterMembers,
      onChangeFilterRegions,
      onChangeFilterCustomer,
      onChangeFilterEntityType,
      onChangeFilterActive,
      downloadReport,
    } = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: "Дата принятия",
        dataIndex: "start",
        render: (start) => moment(start).format("L"),
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "start",
        align: "center",
        width: "15%",
      },
      {
        title: "Дата окончания",
        dataIndex: "finish",
        render: (finish) => moment(finish).format("L"),
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "finish",
        align: "center",
        width: "15%",
      },
      {
        title: "Наименование",
        dataIndex: "name",
        render: (name, record) => (
          <Link to={`/entities/${record.id}/edit`}>{record.name}</Link>
        ),
        align: "center",
        width: "20%",
      },
      {
        title: "Клиент",
        dataIndex: "customer",
        render: (customer, record) =>
          customer ? (
            <Presentation
              currentUser={currentUser}
              rule={"customers:view"}
              route={`/customers/${customer.id}/edit`}
              title={customer.name}
            />
          ) : null,
        sorter: true,
        sortDirections: ["ascend", "descend"],
        align: "center",
        width: "25%",
      },
      {
        title: "Адрес",
        dataIndex: "address",
        align: "center",
        width: "25%",
      },
    ];

    const faqButton = (
      <Button
        type={"ghost"}
        key="7"
        icon="question"
        style={{ marginRight: 10 }}
        onClick={this.props.onFaqToggle}
      />
    );

    let mainButton = hasSelected ? (
      <Button key="1" type="danger" ghost onClick={this.onDelete}>
        Удалить
      </Button>
    ) : (
      <Link
        key="2"
        className={"ant-btn ant-btn-primary ant-btn-background-ghost"}
        to={{ pathname: `/entities/new` }}
      >
        Создать
      </Link>
    );

    let excelButton = (
      <Tooltip placement="bottom" title={"Выгрузить в Excel"}>
        <Button key="excelButton" type="dashed" onClick={downloadReport}>
          <Icon type="file-excel" style={{ color: "green" }} />
          Выгрузить
        </Button>
      </Tooltip>
    );

    let filterButton = (
      <Button
        type={
          fetchParams.customer ||
          fetchParams.entityType ||
          fetchParams.active ||
          fetchParams.users ||
          fetchParams.members ||
          fetchParams.regions
            ? "primary"
            : "ghost"
        }
        key="4"
        icon="filter"
        onClick={this.onFilterToggle}
      />
    );

    let mapButton = (
      <Link
        key="101"
        className={"ant-btn ant-btn-primary ant-btn-background-ghost"}
        to={{ pathname: `/entities/map` }}
      >
        Карта
      </Link>
    );

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Объекты`}
        extra={[
          mapButton,
          faqButton,
          <Search
            allowClear
            key="3"
            placeholder="поиск..."
            value={fetchParams.search}
            onChange={onSearch}
            style={{ width: 250 }}
          />,
          mainButton,
          excelButton,
          filterButton,
        ]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Can
                role={currentUser.role}
                perform="tickets:delete"
                yes={() => (
                  <Table
                    bordered
                    rowSelection={rowSelection}
                    size={"middle"}
                    loading={loading}
                    pagination={false}
                    columns={columns}
                    dataSource={customers}
                    onChange={this.onChangeTable}
                    rowKey="id"
                  />
                )}
                no={() => (
                  <Table
                    bordered
                    size={"middle"}
                    loading={loading}
                    pagination={false}
                    columns={columns}
                    dataSource={customers}
                    onChange={this.onChangeTable}
                    rowKey="id"
                  />
                )}
              />
            </Col>
            <Col
              span={24}
              style={{
                paddingTop: "15px",
                paddingBottom: "15px",
                textAlign: "right",
              }}
            >
              <Pagination
                showSizeChanger
                pageSize={fetchParams.limit}
                current={meta.current_page}
                onChange={onChangePage}
                onShowSizeChange={onChangeLimit}
                showTotal={(total) => `Всего: ${meta.total_count}`}
                total={meta.total_count}
              />
            </Col>
          </Row>
        }
      >
        <Filter
          currentUser={currentUser}
          visible={isFilterVisible}
          users={fetchParams.users}
          members={fetchParams.members}
          regions={fetchParams.regions}
          customer={fetchParams.customer}
          entityType={fetchParams.entityType}
          active={fetchParams.active}
          onClose={this.onFilterToggle}
          onClearFilters={onClearFilters}
          onChangeUsers={onChangeFilterUsers}
          onChangeMembers={onChangeFilterMembers}
          onChangeRegions={onChangeFilterRegions}
          onChangeCustomer={onChangeFilterCustomer}
          onChangeEntityType={onChangeFilterEntityType}
          onChangeActive={onChangeFilterActive}
          downloadReport={downloadReport}
        />
      </PageHeader>
    );
  }
}

CustomersList.propTypes = {
  customers: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default CustomersList;
