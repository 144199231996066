// @flow
import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import Actions from "../../../actions/projects";
import FaqActions from "../../../actions/faqs";
import ProjectsList from "./List";

class Projects extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.onSetFilters()).then(() => {
      this.props.dispatch(Actions.fetchProjects());
    });
  };

  onDelete = (ids) => {
    ids.map((id) => {
      this.props.dispatch(Actions.onDelete(id)).then(() => {
        this.props.dispatch(Actions.fetchProjects());
      });
    });
  };

  onSearch = (e) => {
    this.props
      .dispatch(Actions.onSearch(e.target.value))
      .then(() => this.props.dispatch(Actions.fetchProjects()));
  };

  onChangePage = (value) => {
    this.props.dispatch(Actions.onChangePage(value)).then(() =>
      this.props.dispatch(Actions.fetchProjects()).then(() => {
        localStorage.setItem(
          "projectsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      })
    );
  };

  onChangeLimit = (current, size) => {
    this.props.dispatch(Actions.onChangeLimit(size)).then(() =>
      this.props.dispatch(Actions.fetchProjects()).then(() => {
        localStorage.setItem(
          "projectsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      })
    );
  };

  onSortBy = (field, direction) => {
    this.props.dispatch(Actions.onSortBy(field, direction)).then(() =>
      this.props.dispatch(Actions.fetchProjects()).then(() => {
        localStorage.setItem(
          "projectsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      })
    );
  };

  // filters
  onClearFilters = () => {
    this.props.dispatch(Actions.onClearFilters()).then(() =>
      this.props.dispatch(Actions.fetchProjects()).then(() => {
        localStorage.setItem(
          "projectsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      })
    );
  };

  onChangeFilterStatus = (values) => {
    this.props.dispatch(Actions.onChangeFilterStatus(values)).then(() => {
      this.props.dispatch(Actions.fetchProjects()).then(() => {
        localStorage.setItem(
          "projectsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
    });
  };

  onChangeFilterPeriod = (value, dateString) => {
    this.props
      .dispatch(Actions.onChangeFilterPeriod(dateString[0], dateString[1]))
      .then(() =>
        this.props.dispatch(Actions.fetchProjects()).then(() => {
          localStorage.setItem(
            "projectsFilters",
            JSON.stringify(this.props.fetchParams)
          );
        })
      );
  };

  onChangeFilterPeriodFinish = (value, dateString) => {
    this.props
      .dispatch(Actions.onChangeFilterPeriodFinish(dateString[0], dateString[1]))
      .then(() =>
        this.props.dispatch(Actions.fetchProjects()).then(() => {
          localStorage.setItem(
            "projectsFilters",
            JSON.stringify(this.props.fetchParams)
          );
        })
      );
  };

  onChangeFilterUsers = (values) => {
    let users = [];
    values.map((value) => {
      users.push({ id: String(value.id), name: String(value.name) });
    });
    // console.log(values)
    this.props.dispatch(Actions.onChangeFilterUsers(users)).then(() =>
      this.props.dispatch(Actions.fetchProjects()).then(() => {
        localStorage.setItem(
          "projectsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      })
    );
  };

  onChangeFilterMembers = (values) => {
    this.props.dispatch(Actions.onChangeFilterMembers(values)).then(() =>
      this.props.dispatch(Actions.fetchProjects()).then(() => {
        localStorage.setItem(
          "projectsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      })
    );
  };

  onChangeFilterCustomer = (value) => {
    this.props
      .dispatch(Actions.onChangeFilterCustomer(value))
      .then(() => this.props.dispatch(Actions.fetchProjects()));
  };

  downloadReport = () => {
    let params = this.props.fetchParams;
    
    params.customer = params.customer ? params.customer.id : null;
    Actions.downloadReport(
      params,
      "projects.xlsx",
      params.startDate
        ? `projects ${params.startDate}-${params.endDate}.xlsx`
        : "projects.xlsx"
    );
  };

  // notices
  onClearNotices = () => {
    this.props.dispatch(Actions.onClearNotices());
  };

  // faqs
  onFaqToggle = () => {
    this.props.dispatch(FaqActions.onDrawerToggle()).then(() => {
      this.props.dispatch(FaqActions.fetchFaqs(4, 1))
    });
  };

  render() {
    const {
      currentUser,
      isLoading,
      projects,
      meta,
      fetchParams,
      notices
    } = this.props;
    return (
      <ProjectsList
        currentUser={currentUser}
        loading={isLoading}
        projects={projects}
        meta={meta}
        fetchParams={fetchParams}
        onDelete={this.onDelete}
        onSearch={this.onSearch}
        onChangePage={this.onChangePage}
        onChangeLimit={this.onChangeLimit}
        onSortBy={this.onSortBy}
        // filters
        onClearFilters={this.onClearFilters}
        onChangeFilterStatus={this.onChangeFilterStatus}
        onChangeFilterPeriod={this.onChangeFilterPeriod}
        onChangeFilterPeriodFinish={this.onChangeFilterPeriodFinish}
        onChangeFilterUsers={this.onChangeFilterUsers}
        onChangeFilterMembers={this.onChangeFilterMembers}
        onChangeFilterCustomer={this.onChangeFilterCustomer}
        downloadReport={this.downloadReport}
        // notices
        notices={notices}
        onClearNotices={this.onClearNotices}
        // faqs
        onFaqToggle={this.onFaqToggle}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  isLoading: state.projects.isLoading,
  projects: state.projects.projects,
  meta: state.projects.meta,
  fetchParams: state.projects.fetchParams,
  notices: state.projects.notices
});

export default connect(mapStateToProps)(Projects);
