// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../../../actions/payrolls";
import LoadingSpin from "../../../../components/LoadingSpin";
import Form from "./Form";

import moment from "moment";
import "moment/locale/ru";

class Payroll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: false,
    };
  }

  componentWillMount = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.fetchPayroll(this.props.match.params.id));
    } else {
      this.setState({ isNew: true }, () => {
        this.props.dispatch(Actions.onNew());
      });
    }
  };

  onClose = () => {
    this.props.history.push(`/finances/payrolls`);
  };

  onChangeActive = (value) => {
    this.props.dispatch(Actions.onUpdateActive(this.props.match.params.id, value));
  };

  onChangePeriod = (dates, dateString) => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.onUpdatePeriod(this.props.match.params.id, dateString[0], dateString[1]));
    } else {
      this.props.dispatch(Actions.onChangePeriod(dateString[0], dateString[1]));
    }
  };

  onChangeDepartment = (value) => {
    this.props.dispatch(Actions.onChangeDepartment(value));
  };

  onChangeDescription = (e) => {
    if (this.props.match.params.id) {
      if (e.target.value.length > 0) {
        this.props.dispatch(Actions.onUpdateDescription(this.props.match.params.id, e.target.value));
      }
    } else {
      this.props.dispatch(Actions.onChangeDescription(e.target.value));
    }
  };

  // payroll_items
  onFillInPayrollItems = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(
        Actions.onReFillInPayrollItems(this.props.match.params.id)
      );
    } else {
      this.props.dispatch(Actions.onFillInPayrollItems()).then(() => {
        if (!this.props.errors) {
          this.props.history.push(
            `/finances/payrolls/${this.props.payroll.id}/edit`
          );
        }
      });
    }
  };

  onSavePayrollItem = (item) => {
    if (item.id) {
      this.props.dispatch(
        Actions.onSavePayrollItem(this.props.match.params.id, item)
      );
    } else {
      this.props.dispatch(
        Actions.onCreatePayrollItem(this.props.match.params.id, item)
      );
    }
  };

  onDeletePayrollItems = (ids) => {
    this.props.dispatch(
      Actions.onDeletePayrollItems(this.props.match.params.id, ids)
    );
  };

  fetchPayrollEmploees = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(
        Actions.fetchPayrollEmploees(this.props.match.params.id)
      );
    }
  };

  fetchPayrollItems = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(
        Actions.fetchPayrollItems(this.props.match.params.id)
      );
    }
  };

  // penalties
  fetchPayrollIPenalties = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(
        Actions.fetchPayrollIPenalties(this.props.match.params.id)
      );
    }
  };

  onSavePenalty = (item) => {
    if (item.id) {
      this.props.dispatch(
        Actions.onSavePenalty(this.props.match.params.id, item)
      );
    } else {
      this.props.dispatch(
        Actions.onCreatePenalty(this.props.match.params.id, item)
      );
    }
  };

  onDeletePenalties = (ids) => {
    this.props.dispatch(
      Actions.onDeletePenalties(this.props.match.params.id, ids)
    );
  };

  // order employee_details
  onEmployeeDetails = (userId) => {
    const { payroll } = this.props;
    this.props.dispatch(Actions.onEmployeeDetails(this.props.match.params.id, userId, payroll.start_at, payroll.end_at));
  };

  render() {
    const {
      isLoading,
      isLoadindPayrollItems,
      errors,
      payroll,
      isLoadindEmployeeDetails,
      employeeDetails,
    } = this.props;
    return isLoading || errors ? (
      <LoadingSpin />
    ) : (
      <Form
        isLoading={isLoading}
        isLoadindPayrollItems={isLoadindPayrollItems}
        isNew={this.state.isNew}
        payroll={payroll}
        onClose={this.onClose}
        onChangeActive={this.onChangeActive}
        onChangePeriod={this.onChangePeriod}
        onChangeDepartment={this.onChangeDepartment}
        onChangeDescription={this.onChangeDescription}
        // payroll_items
        fetchPayrollEmploees={this.fetchPayrollEmploees}
        fetchPayrollItems={this.fetchPayrollItems}
        fetchPayrollIPenalties={this.fetchPayrollIPenalties}
        onFillInPayrollItems={this.onFillInPayrollItems}
        onSavePayrollItem={this.onSavePayrollItem}
        onDeletePayrollItems={this.onDeletePayrollItems}
        // penalies
        onSavePenalty={this.onSavePenalty}
        onDeletePenalties={this.onDeletePenalties}
        // employee_details
        isLoadindEmployeeDetails={isLoadindEmployeeDetails}
        employeeDetails={employeeDetails}
        onEmployeeDetails={this.onEmployeeDetails}
      />
    );
  }
}

Payroll.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  payroll: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isLoading: state.payrolls.isLoading,
  isLoadindPayrollItems: state.payrolls.isLoadindPayrollItems,
  errors: state.payrolls.errors,
  payroll: state.payrolls.payroll,
  // employee_details
  isLoadindEmployeeDetails: state.payrolls.isLoadindEmployeeDetails,
  employeeDetails: state.payrolls.employee_details,
});

export default connect(mapStateToProps)(withRouter(Payroll));
