// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Col, Row, Form, Card } from "antd";
import Assignees from "../../../components/Assignees";

import SelectFetch from "../../../components/SelectFetch";

const FormItem = Form.Item;

class AssigneesStep extends Component {
  render() {
    const {
      getFieldDecorator,
      customer,
      organisationAssignees,
      customerAssignees,
      onSaveAssignee,
      onDeleteAssignee,
    } = this.props;
    return (
      <React.Fragment>
        <Row gutter={10}>
          <Col span={24}>
            <Assignees
              isReadOnly={false}
              title={"Ответственные по проекту"}
              isAssigneesLoading={false}
              assigneeType="organisation"
              customer={customer}
              assignees={organisationAssignees}
              onCreateAssignee={onSaveAssignee}
              onDeleteAssignee={onDeleteAssignee}
            />
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={24}>
            <Assignees
              title={"От заказчика"}
              isReadOnly={false}
              isAssigneesLoading={false}
              assigneeType="customer"
              customer={customer}
              assignees={customerAssignees}
              onCreateAssignee={onSaveAssignee}
              onDeleteAssignee={onDeleteAssignee}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

AssigneesStep.propTypes = {
  entity: PropTypes.object,
};

export default AssigneesStep;
