// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../../actions/entityTypes";
import ModalForm from "./Form";

class EntityType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tempIcon: null,
    };
  }

  onSave = () => {
    const { tempIcon } = this.state;
    if (this.props.entityType.id) {
      this.props
        .dispatch(Actions.onUpdate(this.props.entityType.id))
        .then(() => {
          this.props.dispatch(Actions.fetchEntityTypes());
        });
    } else {
      this.props.dispatch(Actions.onCreate(tempIcon)).then(() => {
        this.props.dispatch(Actions.fetchEntityTypes());
      });
    }
  };

  onCancel = () => {
    this.props.dispatch(Actions.onToggleVisible());
  };

  onChangeName = (e) => {
    this.props.dispatch(Actions.onChangeName(e.target.value));
  };

  onChangeIcon = (value) => {
    // console.log(value)
    this.props.dispatch(Actions.onChangeIcon(value));
  };

  render() {
    const {
      isLoading,
      entityType,
      isModalVisible,
    } = this.props;
    return (
      <ModalForm
        destroyOnClose
        isLoading={isLoading}
        visible={isModalVisible}
        entityType={entityType}
        onSave={this.onSave}
        onCancel={this.onCancel}
        onChangeName={this.onChangeName}
        onChangeIcon={this.onChangeIcon}
      />
    );
  }
}

EntityType.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  entityType: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isLoading: state.entityTypes.isLoading,
  isModalVisible: state.entityTypes.isModalVisible,
  errors: state.entityTypes.errors,
  entityType: state.entityTypes.entityType,
});

export default connect(mapStateToProps)(withRouter(EntityType));
