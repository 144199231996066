// @flow
import React, { Component } from "react";
import {
  Row,
  Col,
  Modal,
  Select,
  Button,
  Form,
  Input,
  InputNumber,
  DatePicker,
  List,
  Avatar,
  Divider,
  Tooltip,
} from "antd";
import moment from "moment";
import "moment/locale/ru";
import _ from "lodash/array";
import SelectFetch from "../../../components/SelectFetch";

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

class TaskModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddingAssignee: false,
      isAddingCustomerAssignee: false,
      assignee: null,
      customerAssignee: null,
      companyAssignee: null,
    };
  }

  onSubmit = (e) => {
    const { task } = this.props;
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      if (task.task_type == 2 && task.plan_days == 0) {
        // console.log(values.productPrice)
        this.props.form.setFields({
          planDays: {
            value: values.planDays,
            errors: [new Error("Задайте количество дней!")],
          },
        });
        return;
      }
      this.props.onSave(values);
    });
  };

  // assignee
  onOpenAddingAssignee = () => {
    this.setState({ isAddingAssignee: true });
  };

  onCloseAddingAssignee = () => {
    this.setState({ isAddingAssignee: false });
  };

  onOpenAddingCustomerAssignee = () => {
    this.setState({ isAddingCustomerAssignee: true });
  };

  onCloseAddingCustomerAssignee = () => {
    this.setState({ isAddingCustomerAssignee: false });
  };

  onOpenAddingCompanyAssignee = () => {
    this.setState({ isAddingCompanyAssignee: true });
  };

  onCloseAddingCompanyAssignee = () => {
    this.setState({ isAddingCompanyAssignee: false });
  };

  onSelectAssignee = (value) => {
    const assignee = {
      assignee_type: "organisation",
      id: value.id,
      name: value.name,
      extra: value.extra,
    };
    this.setState({ assignee: assignee });
  };

  onSelectCustomerAssignee = (value) => {
    const assignee = {
      assignee_type: "customer",
      id: value.id,
      name: value.name,
      extra: value.extra,
    };
    this.setState({ customerAssignee: assignee });
  };

  onSelectCompanyAssignee = (value) => {
    const assignee = {
      assignee_type: "company",
      id: value.id,
      name: value.name,
      extra: value.extra,
    };
    this.setState({ companyAssignee: assignee });
  };

  onChangeAssignees = () => {
    const { assignee } = this.state;
    const idx = _.findIndex(this.props.task.assignees, function (o) {
      return o.id == assignee.id && o.assignee_type == "organisation";
    });
    if (assignee && idx == -1) {
      this.props.onAddAssignee(this.state.assignee);
      this.setState({ isAddingAssignee: false, assignee: null });
    }
  };

  onChangeCustomerAssignees = () => {
    const { customerAssignee } = this.state;
    const idx = _.findIndex(this.props.task.assignees, function (o) {
      return o.id == customerAssignee.id && o.assignee_type == "customer";
    });
    if (customerAssignee && idx == -1) {
      this.props.onAddAssignee(this.state.customerAssignee);
      this.setState({
        isAddingCustomerAssignee: false,
        customerAssignee: null,
      });
    }
  };

  onChangeCompanyAssignees = () => {
    const { companyAssignee } = this.state;
    const idx = _.findIndex(this.props.task.assignees, function (o) {
      return o.id == companyAssignee.id && o.assignee_type == "company";
    });
    if (companyAssignee && idx == -1) {
      this.props.onAddAssignee(this.state.companyAssignee);
      this.setState({
        isAddingCompanyAssignee: false,
        companyAssignee: null,
      });
    }
  };

  render() {
    const {
      isAddingAssignee,
      isAddingCustomerAssignee,
      assignee,
      customerAssignee,
    } = this.state;
    const {
      visible,
      onCancel,
      task,
      isOrganisation,
      isSupplier,
      onChangeTaskType,
      onChangeSubject,
      onChangeDescription,
      onChangeDueFrom,
      onChangeDueBy,
      onChangePlanDays,
      onDeleteAssignee,
    } = this.props;
    const { getFieldDecorator } = this.props.form;

    const organisationAssignees = task.assignees.filter(function (item) {
      return item.assignee_type == "organisation";
    });

    const customerAssignees = task.assignees.filter(function (item) {
      return item.assignee_type == "customer";
    });

    const companyAssignees = task.assignees.filter(function (item) {
      return item.assignee_type == "company";
    });

    return (
      <Modal
        title={task.service ? task.service.name : "Новая задача"}
        width={600}
        destroyOnClose
        visible={visible}
        onOk={this.onSubmit}
        onCancel={onCancel}
      >
        <Form layout={"vertical"}>
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <FormItem label={"Тип"}>
                {getFieldDecorator("task_type", {
                  initialValue: task.task_type,
                  onChange: onChangeTaskType,
                  rules: [
                    {
                      required: true,
                      message: "Введите тип!",
                    },
                  ],
                })(
                  <Select
                    placeholder="Тип"
                    optionFilterProp="children"
                    filterOption={false}
                  >
                    <Option value={1}>выезд на обследование</Option>
                    <Option value={2}>создание КП</Option>
                    <Option value={3}>согласование КП</Option>
                    <Option value={4}>закупка оборудования</Option>
                    <Option value={5}>выполнение работ</Option>
                    <Option value={7}>внесение изменений в инф. системы</Option>
                    <Option value={6}>принятие работ</Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label={"Начало (план)"}>
                {getFieldDecorator("due_from", {
                  onChange: onChangeDueFrom,
                  initialValue: moment(task.due_from ? task.due_from : null),
                  rules: [
                    {
                      required: true,
                      message: "Введите дату начала!",
                    },
                  ],
                })(
                  <DatePicker
                    format={"LLL"}
                    className="ant-btn-width-100"
                    showTime
                  />
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label={"Окончание (план)"}>
                {getFieldDecorator("due_by", {
                  onChange: onChangeDueBy,
                  initialValue: moment(task.due_by ? task.due_by : null),
                  rules: [
                    {
                      required: true,
                      message: "Введите дату окончания!",
                    },
                  ],
                })(
                  <DatePicker
                    format={"LLL"}
                    className="ant-btn-width-100"
                    showTime
                  />
                )}
              </FormItem>
            </Col>
            {task.task_type == 2 ? (
              <Col span={12}>
                <FormItem label={"Дней на выполнение"}>
                  {getFieldDecorator("planDays", {
                    onChange: onChangePlanDays,
                    initialValue: task.plan_days,
                    rules: [
                      {
                        required: true,
                        message: "Введите количество дней!",
                      },
                    ],
                  })(
                    <InputNumber min={0} max={1000} style={{ width: "100%" }} />
                  )}
                </FormItem>
              </Col>
            ) : null}
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <FormItem label={"Тема"}>
                {getFieldDecorator("subject", {
                  onChange: onChangeSubject,
                  initialValue: task.subject,
                  rules: [
                    {
                      required: true,
                      message: "Пожалуйста, введите тему!",
                    },
                  ],
                })(<TextArea autoSize={{ minRows: 1, maxRows: 6 }} />)}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <FormItem label={"Описание"}>
                {getFieldDecorator("Описание", {
                  onChange: onChangeDescription,
                  initialValue: task.description,
                })(<TextArea autoSize={{ minRows: 2, maxRows: 6 }} />)}
              </FormItem>
            </Col>
          </Row>

          <Divider>
            Ответственные{" "}
            <Tooltip placement="bottom" title={"Добавить ответственного"}>
              <Button
                type="dashed"
                shape="circle"
                icon="plus"
                onClick={this.onOpenAddingAssignee}
              />
            </Tooltip>
          </Divider>
          {isAddingAssignee ? (
            <Row gutter={10} type="flex" justify="center">
              <Col span={24}>
                <SelectFetch
                  showSearch
                  placeholder="Клиент"
                  onChange={this.onSelectAssignee}
                  selected={assignee ? assignee : null}
                  url={"/users/search/?"}
                />
              </Col>
              <Col span={24} style={{ paddingTop: "10px" }}>
                <Row gutter={10} type="flex" justify="center">
                  <Button
                    type="danger"
                    ghost
                    onClick={this.onCloseAddingAssignee}
                  >
                    Отменить
                  </Button>

                  <Button
                    type="primary"
                    ghost
                    style={{ marginLeft: "10px" }}
                    onClick={this.onChangeAssignees}
                  >
                    Добавить
                  </Button>
                </Row>
              </Col>
            </Row>
          ) : null}
          <List
            itemLayout="horizontal"
            dataSource={organisationAssignees}
            renderItem={(item, i) => (
              <List.Item
                actions={[
                  <Button
                    type="dashed"
                    shape="circle"
                    icon="close"
                    onClick={onDeleteAssignee.bind(this, i)}
                  />,
                ]}
              >
                <List.Item.Meta
                  avatar={
                    <Avatar
                      src={item.extra.avatar ? item.extra.avatar.thumb : null}
                    />
                  }
                  title={item.name}
                  description={`${item.extra.title}`}
                />
              </List.Item>
            )}
          />
          {isOrganisation ? null : (
            <Row>
              <Divider>
                От заказчика{" "}
                <Tooltip placement="bottom" title={"Добавить ответственного"}>
                  <Button
                    type="dashed"
                    shape="circle"
                    icon="plus"
                    onClick={this.onOpenAddingCustomerAssignee}
                  />
                </Tooltip>
              </Divider>
              {isAddingCustomerAssignee ? (
                <Row gutter={10} type="flex" justify="center">
                  <Col span={24}>
                    <SelectFetch
                      showSearch
                      placeholder="Клиент"
                      onChange={this.onSelectCustomerAssignee}
                      selected={customerAssignee ? customerAssignee : null}
                      url={"/members/search/?"}
                    />
                  </Col>
                  <Col span={24} style={{ paddingTop: "10px" }}>
                    <Row gutter={10} type="flex" justify="center">
                      <Button
                        type="danger"
                        ghost
                        onClick={this.onCloseAddingCustomerAssignee}
                      >
                        Отменить
                      </Button>

                      <Button
                        type="primary"
                        ghost
                        style={{ marginLeft: "10px" }}
                        onClick={this.onChangeCustomerAssignees}
                      >
                        Добавить
                      </Button>
                    </Row>
                  </Col>
                </Row>
              ) : null}
              <List
                itemLayout="horizontal"
                dataSource={customerAssignees}
                renderItem={(item, i) => (
                  <List.Item
                    actions={[
                      <Button
                        type="dashed"
                        shape="circle"
                        icon="close"
                        onClick={onDeleteAssignee.bind(this, i)}
                      />,
                    ]}
                  >
                    <List.Item.Meta
                      avatar={
                        <Avatar
                          src={
                            item.extra.avatar ? item.extra.avatar.thumb : null
                          }
                        />
                      }
                      title={item.name}
                      description={`${item.extra.title}`}
                    />
                  </List.Item>
                )}
              />
            </Row>
          )}
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(TaskModal);
