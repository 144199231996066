// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Button,
  Form,
  Card,
  Tabs,
  Divider,
  Badge,
  Menu,
  Dropdown,
} from "antd";

import CommentForm from "../../../components/CommentForm";
import Tasks from "../../../components/Tasks";

import Statuses from "./Statuses";
import General from "./General";
import TasksTodos from "./TasksTodos";
import Comments from "./Comments";
import Activities from "./Activities";
import moment from "moment";
import "moment/locale/ru";

const { TabPane } = Tabs;

function statuses(status) {
  switch (status) {
    case "pending":
      return "error";
    case "processing":
      return "processing";
    case "finished":
      return "success";
    default:
      return "default";
  }
}

class EntityTaskForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: "",
      isCommentFormVisible: false,
    };
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }

      if (this.props.entityTask.status == "pending") {
        this.props.onChangeStatus(values);
      } else {
        this.props.dispatch(this.setState({ isCommentFormVisible: true }));
      }

      // this.props.onChangeStatus(values);
    });
  };

  callback = (key) => {
    if (key == "2") {
      this.props.fetchEntityTaskActivities();
    }

    if (key == "3") {
      this.props.fetchTasks();
    }
  };

  // actions
  onAction = (actionId) => {
    const { entityTask, actions, onOpenTaskForm } = this.props;
    let idx = actions.findIndex(function (o) {
      return o.id == actionId;
    });
    if (idx === -1) {
      return null;
    }
    onOpenTaskForm(actions[idx], entityTask.id);
  };

  // comment
  onSaveComment = () => {
    const { status, comment } = this.state;
    this.props.onChangeStatus(comment);
    this.setState({ isCommentFormVisible: false });
  };

  onCancelComment = () => {
    this.setState({ comment: "" }, () => {
      this.setState({ isCommentFormVisible: false, status: null });
    });
  };

  onChangeComment = (e) => {
    this.setState({
      comment: e.target.value,
    });
  };

  ActionMenu = (actions) => {
    return (
      <Menu>
        {actions.map((action) => {
          return (
            <Menu.Item
              key={action.id}
              onClick={this.onAction.bind(this, action.id)}
            >
              {action.name}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  render() {
    const { comment, isCommentFormVisible } = this.state;
    const { getFieldDecorator } = this.props.form;
    const {
      isNew,
      errors,
      currentUser,
      isLoading,
      isAssigneesLoading,
      isCommentsLoading,
      entityTask,
      onClose,
      onChangePlan,
      // comments
      onCreateComment,
      // assignees
      onCreateAssignee,
      onDeleteAssignee,
      // attachments
      isUploading,
      onDeleteAttachment,
      onUploadAttachment,
      onUploadCommentAttachment,
      onDeleteCommentAttachment,
      // activities
      entityTaskActivities,
      isActivitiesLoading,
      // tasks
      isTasksLoading,
      tasks,
      // actions
      actions,
      // todos
      isTodosLoading,
      isTodoLoading,
      fetchEntityTaskTodos,
      fillEntityTaskTodos,
      onEntityTaskTodoCompleted,
      onEntityTaskTodoSaveComment,
    } = this.props;

    const isBetween = moment(entityTask.plan).isBetween(
      moment().startOf("month"),
      moment().endOf("month")
    );

    let organisationAssignees = [];
    let supervisorAssignees = [];
    let customerAssignees = [];
    let companyAssignees = [];

    if (entityTask.assignees) {
      organisationAssignees = isLoading
        ? []
        : entityTask.assignees.filter(function (item) {
            return item.assignee_type == "organisation";
          });
    }

    if (entityTask.assignees) {
      supervisorAssignees = entityTask.assignees.filter(function (item) {
        return item.assignee_type == "supervisor";
      });
    }

    if (entityTask.assignees) {
      customerAssignees = entityTask.assignees.filter(function (item) {
        return item.assignee_type == "customer";
      });
    }

    if (entityTask.assignees) {
      companyAssignees = entityTask.assignees.filter(function (item) {
        return item.assignee_type == "company";
      });
    }

    const commentAttachments = (entityTask.attachments ?? [])
      .filter((attachment) => !!attachment.entity_task_comment_id);

    const executionButton =
      (entityTask.status == "pending" || entityTask.status == "processing") &&
      isBetween ? (
        <Button
          key={2}
          onClick={this.onSubmit}
          type={entityTask.status == "pending" ? "danger" : "primary"}
          style={{ marginLeft: "8px" }}
        >
          {entityTask.status == "pending"
            ? "Начать выполнение"
            : "Закончить выполнение"}
        </Button>
      ) : null;

    // actions
    let actionsButton = null;

    if (actions.length > 0) {
      actionsButton = (
        <Dropdown key={99} overlay={this.ActionMenu(actions)}>
          <Button icon={"more"} style={{ marginLeft: "8px" }} />
        </Dropdown>
      );
    }

    return (
      <Form layout={"vertical"} onSubmit={this.onSubmit}>
        <Card
          loading={isLoading}
          title={
            <div>
              <h2 style={{ marginBottom: 0 }}>{`Регламент #${
                isNew ? "новый" : entityTask.id
              }`}</h2>
              <span style={{ fontSize: "12px" }}>
                <Badge
                  status={statuses(entityTask.status)}
                  text={entityTask.status_locale}
                />
              </span>
            </div>
          }
          extra={[
            <Button key={1} onClick={onClose}>
              Закрыть
            </Button>,
            executionButton,
            actionsButton,
          ]}
        >
          <Col xs={24} sm={24} md={24} lg={13} xl={13}>
            <General currentUser={currentUser} entityTask={entityTask} />

            <Row>
              <TasksTodos
                entityTaskItems={entityTask.entity_task_items}
                isTodosLoading={isTodosLoading}
                isTodoLoading={isTodoLoading}
                isExistTodoTemplate={entityTask.entity.is_exist_todo_template}
                entityTaskTodos={entityTask.entity_task_todos}
                fetchEntityTaskTodos={fetchEntityTaskTodos}
                fillEntityTaskTodos={fillEntityTaskTodos}
                onEntityTaskTodoCompleted={onEntityTaskTodoCompleted}
                onEntityTaskTodoSaveComment={onEntityTaskTodoSaveComment}
              />
            </Row>
            <Divider>Комментарии</Divider>
            <Row>
              {isNew ? null : (
                <Comments
                  currentUser={currentUser}
                  getFieldDecorator={getFieldDecorator}
                  isCommentsLoading={isCommentsLoading}
                  errors={errors}
                  onCreateComment={onCreateComment}
                  comments={entityTask.comments}
                  commentAttachments={commentAttachments}
                  onUploadCommentAttachment={onUploadCommentAttachment}
                  onDeleteCommentAttachment={onDeleteCommentAttachment}
                />
              )}
            </Row>
          </Col>
          <Col xs={0} sm={0} md={0} lg={1} xl={1} />
          <Col xs={24} sm={24} md={24} lg={10} xl={10}>
            <Tabs defaultActiveKey="1" onChange={this.callback}>
              <TabPane tab="Статусы" key="1">
                <Statuses
                  isNew={isNew}
                  currentUser={currentUser}
                  isOrganisation={entityTask.is_organisation}
                  company={entityTask.company}
                  plan={entityTask.plan}
                  start={entityTask.start}
                  finish={entityTask.finish}
                  isAssigneesLoading={isAssigneesLoading}
                  getFieldDecorator={getFieldDecorator}
                  onChangePlan={onChangePlan}
                  // assignees
                  organisationAssignees={organisationAssignees}
                  supervisorAssignees={supervisorAssignees}
                  customerAssignees={customerAssignees}
                  companyAssignees={companyAssignees}
                  onCreateAssignee={onCreateAssignee}
                  onDeleteAssignee={onDeleteAssignee}
                  // attachments
                  isUploading={isUploading}
                  attachments={entityTask.attachments}
                  onDeleteAttachment={onDeleteAttachment}
                  onUploadAttachment={onUploadAttachment}
                />
              </TabPane>
              <TabPane tab="Лог изменений" key="2">
                <Activities
                  entityTaskActivities={entityTaskActivities}
                  isActivitiesLoading={isActivitiesLoading}
                />
              </TabPane>

              <TabPane tab="Задачи" key="3">
                <Tasks isLoading={isTasksLoading} tasks={tasks} />
              </TabPane>
            </Tabs>
          </Col>
        </Card>
        <CommentForm
          visible={isCommentFormVisible}
          comment={comment}
          onSave={this.onSaveComment}
          onCancel={this.onCancelComment}
          onChangeComment={this.onChangeComment}
        />
      </Form>
    );
  }
}

EntityTaskForm.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  entityTask: PropTypes.object,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
  router: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  form: PropTypes.object,
};

export default Form.create()(EntityTaskForm);
