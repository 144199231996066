// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Input, InputNumber, Col, Row } from "antd";

const FormItem = Form.Item;
const { TextArea } = Input;

class General extends Component {
  constructor(props, context) {
    super(props, context);
  }
  render() {
    const {
      supportMessage,
      getFieldDecorator,
      onChangeSubject,
      onChangeDescription,
    } = this.props;

    return (
      <div>
        <Row gutter={16}>
          <Col span={24}>
            <FormItem label={"Тема"}>
              {getFieldDecorator("subject", {
                onChange: onChangeSubject,
                initialValue: supportMessage.subject,
                rules: [
                  {
                    required: true,
                    message: "Пожалуйста, введите тему!"
                  }
                ]
              })(<TextArea autoSize={{ minRows: 1, maxRows: 6 }} />)}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <FormItem label={"Описание"}>
              {getFieldDecorator("Описание", {
                onChange: onChangeDescription,
                initialValue: supportMessage.description
              })(<TextArea autoSize={{ minRows: 2, maxRows: 6 }} />)}
            </FormItem>
          </Col>
        </Row>
      </div>
    );
  }
}

General.propTypes = {
  entity: PropTypes.object
};

export default General;
