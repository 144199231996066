// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table, Button, PageHeader, Badge, Select, Icon } from "antd";
import { Pagination } from "antd";
import { Row, Col, Input } from "antd";

import PropTypes from "prop-types";

import moment from "moment";
import "moment/locale/ru";

class PostsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: []
    };
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  onDelete = () => {
    this.props.onDelete(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  onChangeTable = (pagination, filters, sorter) => {
    let direction = "desc";
    if (sorter.order === "descend") {
      direction = "desc";
    } else if (sorter.order === "ascend") {
      direction = "asc";
    }
    this.props.onSortBy(sorter.field, direction);
  };

  render() {
    const { selectedRowKeys } = this.state;
    const {
      loading,
      currentUser,
      posts,
      meta,
      fetchParams,
      onSearch,
      onChangePage,
      onChangeLimit,
      onSortBy
    } = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    };

    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: "#",
        dataIndex: "id",
        render: (id, record) => (
          <Link to={`/posts/${record.id}/edit`}>{id}</Link>
        ),
        width: "5%",
        align: "center"
      },
      {
        title: "Тип",
        dataIndex: "post_type_locale",
        key: "post_type_locale",
        width: "10%",
        align: "center"
      },
      {
        title: "Дата создания",
        dataIndex: "created_at",
        render: created_at => moment(created_at).format("L"),
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "created_at",
        align: "center",
        width: "15%"
      },
      {
        title: "Дата публикации",
        dataIndex: "published_at",
        render: published_at => (published_at ? moment(published_at).format("L") : null),
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "published_at",
        align: "center",
        width: "15%"
      },
      {
        title: "Заголовок",
        dataIndex: "title",
        render: (title, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{title}</span>
          </div>
        ),
        align: "left",
        width: "40%"
      },

      {
        title: "Автор",
        dataIndex: "user",
        render: (user, record) => (
          <span style={{ opacity: "0.7" }}>{user ? user.name : null}</span>
        ),

        align: "center",
        width: "15%"
      }
    ];

    let mainButton = hasSelected ? (
        <Button key="1" type="danger" ghost onClick={this.onDelete}>
          Удалить
        </Button>
      ) : (
        <Link
          key="2"
          className={"ant-btn ant-btn-primary ant-btn-background-ghost"}
          to={{ pathname: `/posts/new` }}
        >
          Создать
        </Link>
      );

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Новости`}
        extra={[mainButton]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Table
                bordered
                rowSelection={rowSelection}
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={posts}
                onChange={this.onChangeTable}
                rowKey="id"
              />
            </Col>
            <Col
              span={24}
              style={{ paddingTop: "15px", paddingBottom: "15px", textAlign: "right" }}
            >
              <Pagination
                showSizeChanger
                pageSize={fetchParams.limit}
                current={meta.current_page}
                onChange={onChangePage}
                onShowSizeChange={onChangeLimit}
                showTotal={total => `Всего: ${meta.total_count}`}
                total={meta.total_count}
              />
            </Col>
          </Row>
        }
      />
    );
  }
}

PostsList.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object
};

export default PostsList;
