import React from "react";
import { withRouter } from "react-router-dom";
import { Layout } from "antd";
import { connect } from "react-redux";
import update from "immutability-helper";
import Actions from "../../actions/session";
import SettingsActions from "../../actions/settings";
import NoticesActions from "../../actions/notices";
import SupportMessageActions from "../../actions/supportMessages";
import DashboardActions from "../../actions/dashboard";
import FaqActions from "../../actions/faqs";
import TaskActions from "../../actions/tasks";
import MainHeader from "./MainHeader";
import Sidebar from "./Sidebar";
import News from "./News";
import SupportMessage from "./SupportMessage";
import Task from "../Tasks/FormModal";
import MenuRoutes from "./MenuRoutes";
import HelpMenu from "./HelpMenu";
const { Sider, Content } = Layout;

class MainLayout extends React.Component {
  constructor() {
    super();
    this.state = {
      isSidebarCollapsed: true,
      menuCollapsed: true,
      isHelpMenuVisible: false,
      isSupportMessageVisible: false,
      isSupportMessageUploading: false,
      message: {}
    };
  }

  componentWillMount = () => {
    this.props.dispatch(SettingsActions.fetchSettings()).then(() => {
      this.props.dispatch(NoticesActions.fetchDashboardNotices());
    });
  };

  toggle = () => {
    this.setState(
      {
        isSidebarCollapsed: !this.state.isSidebarCollapsed,
      },
      () => {
        localStorage.setItem("isSidebarCollapsed", this.state.isSidebarCollapsed);
      }
    );
  };

  onLogout = () => {
    this.props.dispatch(Actions.logout());
  };

  onSaveFeedback = (message) => {
    this.props.dispatch(SupportMessageActions.onCreate(message));
  };

  fetchPost = (id) => {
    this.props.dispatch(DashboardActions.fetchPost(id));
  };

  onDrawerToggle = () => {
    this.props.dispatch(DashboardActions.onDrawerToggle());
  };

  onCancelTaskForm = () => {
    this.props.dispatch(TaskActions.onCancelModalForm());
  };

  // faqs
  fetchFaq = (id) => {
    this.props.dispatch(FaqActions.fetchFaq(id));
  };

  onFaqToggle = () => {
    this.props.dispatch(FaqActions.onDrawerToggle());
  };

  // helpmenu
  onShowHelpMenu = () => {
    this.setState({
      isHelpMenuVisible: true,
    });
  };

  onOkHelpMenu = (e) => {
    this.setState({
      isHelpMenuVisible: false,
    });
  };

  onCancelHelpMenu = (e) => {
    this.setState({
      isHelpMenuVisible: false,
    });
  };

  onOpenHelp = (e) => {
    this.setState({
      isHelpMenuVisible: false,
    },
    () => {
      this.props.history.push(`/faqs`);
    });
  };

  onOpenSupport = (e) => {
    this.setState({
      isHelpMenuVisible: false,
    },
    () => {
      this.props.history.push(`/support_messages`);
    });
  };

  onOpenNews = (e) => {
    this.setState({
      isHelpMenuVisible: false,
    },
    () => {
      this.props.history.push(`/news`);
    });
  };

  onOpenSupportMessage = (e) => {
    this.setState({
      isHelpMenuVisible: false,
    }, () => {
      this.onOpenFeedback()
    });
  };

  // support_message
  onOpenFeedback = () => {
    // console.log("onOpenFeedback")
    this.setState(
      {
        message: {
          support_message_type: null,
          url: window.location,
          attachments: []
        }
      },
      () => {
        this.setState({ isSupportMessageVisible: true });
      }
    );
  };

  onCancelFeedback = () => {
    this.setState({ isSupportMessageVisible: false });
  };

  onSaveFeedback = () => {
    this.setState({ isSupportMessageVisible: false }, () => {
      this.props.dispatch(SupportMessageActions.onCreate(this.state.message));
    });
  };

  onChangeFeedbackType = value => {
    this.setState({
      message: update(this.state.message, {
        support_message_type: { $set: value }
      })
    });
  };

  onChangeSubject = e => {
    this.setState({
      message: update(this.state.message, {
        subject: { $set: e.target.value }
      })
    });
  };

  onChangeDescription = e => {
    this.setState({
      message: update(this.state.message, {
        description: { $set: e.target.value }
      })
    });
  };

  onUploadAttachment = imageUrl => {
    // console.log(imageUrl)
    this.setState({
      message: update(this.state.message, {
        attachments: { $push: [imageUrl.file] }
      })
    });
  };

  onDeleteAttachment = idx => {
    this.setState({
      message: update(this.state.message, {
        attachments: { $splice: [[idx, 1]] }
      })
    });
  };

  render() {
    const {isHelpMenuVisible, isSupportMessageVisible, isSupportMessageUploading, message} = this.state;
    const {
      currentUser,
      supportMessages,
      entityTasks,
      orderTasks,
      count,
      // posts
      isDrawerVisible,
      isPostsLoading,
      post,
      posts,
      // services
      isLoadingServices,
      services,
      // faqs
      isFaqVisible,
      isFaqsLoading,
      isFaqLoading,
      faq,
      faqs,
    } = this.props;
    return (
      <Layout>
        {/* <Sider style={{
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0,
          }} collapsible collapsed={this.state.isSidebarCollapsed} onCollapse={this.onCollapse}> */}
        <Sider
          style={{
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0,
          }}
          trigger={null}
          collapsible
          collapsed={this.state.isSidebarCollapsed}
        >
          <div className="logo" />
          {isLoadingServices ? null : (
            <MenuRoutes
              role={currentUser.role}
              collapsed={this.state.isSidebarCollapsed.toString()}
              services={services}
            />
          )}
        </Sider>
        <div
          className="ant-layout-sider-help"
          style={this.state.isSidebarCollapsed ? { width: 80 } : { width: 200 }}
        >
          <i
            aria-label="icon: question-circle"
            className="anticon anticon-question-circle"
            onClick={this.onShowHelpMenu}
          >
            <svg
              viewBox="64 64 896 896"
              focusable="false"
              className=""
              data-icon="question-circle"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
              <path d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0 1 30.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1 0 80 0 40 40 0 1 0-80 0z"></path>
            </svg>
          </i>
        </div>
        <Layout
          style={{
            minHeight: "100vh",
            overflowX: "hidden",
            marginLeft: this.state.isSidebarCollapsed ? 80 : 200,
          }}
        >
          <MainHeader
            isSidebarCollapsed={this.state.isSidebarCollapsed}
            currentUser={this.props.currentUser}
            onToggleSideBar={this.toggle}
            onLogout={this.onLogout}
            supportMessages={supportMessages}
            entityTasks={entityTasks}
            orderTasks={orderTasks}
            count={count}
            // messages
            supportMessagesErrors={this.props.supportMessagesErrors}
            onSaveFeedback={this.onSaveFeedback}
          />
          <Content
            style={{
              margin: "80px 16px 16px 16px",
              // padding: 24,
              // background: "#fff",
              minHeight: 280,
            }}
          >
            {this.props.children}
          </Content>
          <News
            isDrawerVisible={isDrawerVisible}
            onDrawerToggle={this.onDrawerToggle}
            isPostsLoading={isPostsLoading}
            fetchPost={this.fetchPost}
            post={post}
            posts={posts}
          />
          <Sidebar
            isDrawerVisible={isFaqVisible}
            onDrawerToggle={this.onFaqToggle}
            isFaqsLoading={isFaqsLoading}
            isFaqLoading={isFaqLoading}
            fetchFaq={this.fetchFaq}
            faq={faq}
            faqs={faqs}
          />
          <HelpMenu
            visible={isHelpMenuVisible}
            onSubmit={this.onOkHelpMenu}
            onCancel={this.onCancelHelpMenu}
            onOpenHelp={this.onOpenHelp}
            onOpenSupport={this.onOpenSupport}
            onOpenNews={this.onOpenNews}
            onOpenSupportMessage={this.onOpenSupportMessage}
          />
          <SupportMessage
            visible={isSupportMessageVisible}
            message={message}
            onSave={this.onSaveFeedback}
            onCancel={this.onCancelFeedback}
            onChangeFeedbackType={this.onChangeFeedbackType}
            onChangeSubject={this.onChangeSubject}
            onChangeDescription={this.onChangeDescription}
            // attachments
            isUploading={isSupportMessageUploading}
            onUploadAttachment={this.onUploadAttachment}
            onDeleteAttachment={this.onDeleteAttachment}
          />
        </Layout>
        <Task
          // visible={isTaskVisible}
          // onCancel={this.onCancelTaskForm}
          // task={post}
          />
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.session.isLoading,
  currentUser: state.session.currentUser,
  services: state.settings.services,
  isLoadingServices: state.settings.isLoading,
  errors: state.session.errors,
  supportMessages: state.notices.supportMessages,
  entityTasks: state.notices.entityTasks,
  orderTasks: state.notices.orderTasks,
  count: state.notices.count,
  supportMessagesErrors: state.supportMessages.errors,
  isDrawerVisible: state.dashboard.isDrawerVisible,
  isPostsLoading: state.dashboard.isPostsLoading,
  post: state.dashboard.post,
  posts: state.dashboard.posts,
  task: state.tasks.task,
  isTaskVisible: state.tasks.isVisible,
  // faqs
  isFaqVisible: state.faqs.isDrawerVisible,
  isFaqsLoading: state.faqs.isFaqsLoading,
  isFaqLoading: state.faqs.isFaqLoading,
  faq: state.faqs.faq,
  faqs: state.faqs.faqs,
});

export default connect(mapStateToProps)(withRouter(MainLayout));
