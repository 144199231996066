// @flow
import React, {Component} from "react";
import {Link, withRouter} from "react-router-dom";
import {
  Badge,
  Button,
  Col,
  Dropdown,
  Icon,
  Input,
  Menu,
  PageHeader,
  Pagination,
  Row,
  Table,
  Tooltip,
  Typography
} from "antd";

import PropTypes from "prop-types";
import AssigneesCell from "../../../components/AssigneesCell";
import canCheck from "../../../rules/canCheck";
import NoticeIcon from "../../../components/NoticeIcon";

import moment from "moment";
import "moment/locale/ru";
import Filter from "./Filter";
import Can from "../../../rules/Can";
import UserInfo from "../../../components/UserInfo";

const Search = Input.Search;
const { Paragraph } = Typography;

class TasksList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      isFilterVisible: false,
    };
  }

  onFilterToggle = () => {
    this.setState({ isFilterVisible: !this.state.isFilterVisible });
  };

  onSelectChange = (selectedRowKeys, records) => {
    // console.log(selectedRowKeys, e)
    this.setState({ selectedRowKeys });
  };

  onDelete = () => {
    this.props.onDelete(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  onChangeTable = (pagination, filters, sorter) => {
    let direction = "desc";
    if (sorter.order === "descend") {
      direction = "desc";
    } else if (sorter.order === "ascend") {
      direction = "asc";
    }
    this.props.onSortBy(sorter.field, direction);
  };

  onClearNotices = () => {
    this.props.onClearNotices();
  };

  onItemClick = (item) => {
    this.props.history.push(`/tasks/${item.parent_id}/edit`);
  };

  // actions
  onAction = (actionId) => {
    const { actions, onOpenTaskForm } = this.props;
    let idx = actions.findIndex(function (o) {
      return o.id == actionId;
    });
    if (idx === -1) {
      return null;
    }
    onOpenTaskForm(actions[idx]);
  };

  ActionMenu = (actions) => {
    return (
      <Menu>
        {actions.map((action) => {
          return (
            <Menu.Item
              key={action.id}
              onClick={this.onAction.bind(this, action.id)}
            >
              {action.name}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  render() {
    const { selectedRowKeys, isFilterVisible } = this.state;
    const {
      currentUser,
      loading,
      tasks,
      meta,
      fetchParams,
      onSearch,
      onChangePage,
      onChangeLimit,
      onSortBy,
      // timing
      onStartTiming,
      onPauseTiming,
      // filters
      onClearFilters,
      onChangeFilterStatus,
      onChangeFilterTemplates,
      onChangeFilterTaskUsers,
      onChangeFilterUsers,
      onChangeFilterSupervisorUsers,
      onChangeFilterCreatedPeriod,
      onChangeFilterDueByPeriod,
      onChangeFilterFieldSets,
      downloadReport,
      // notices
      unread,
      notices,
      // actions
      actions,
      isTaskActionsLoading,
    } = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    const totalSelected = hasSelected
      ? `, Всего (выбрано): ${selectedRowKeys.length}`
      : "";
    const totalInfo = `Всего: ${meta.total_count}${totalSelected}`;

    const columns = [
      {
        title: "Статус",
        dataIndex: "status_locale",
        key: "status_locale",
        align: "center",
        width: "10%",
      },
      {
        title: "#",
        dataIndex: "actions",
        render: (actions, record) =>
          record.has_timing ? (
            <Tooltip
              placement="bottom"
              title={record.start ? "Начать работы" : "Приостановить работы"}
            >
              {record.status == "processing" ? (
                record.start ? (
                  <Button
                    icon={"play-circle"}
                    type="primary"
                    onClick={onStartTiming.bind(this, record.id)}
                  />
                ) : (
                  <Button
                    icon={"pause-circle"}
                    type="danger"
                    onClick={onPauseTiming.bind(this, record.id)}
                  />
                )
              ) : null}
            </Tooltip>
          ) : null,
        width: "5%",
        align: "center",
      },
      {
        title: "Дата создания",
        dataIndex: "created_at",
        render: (created_at) => (
          <span style={{ fontSize: 12 }}>
            {moment(created_at).format("LLL")}
          </span>
        ),
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "created_at",
        align: "center",
        width: "8%",
      },
      {
        title: "Выполнить до",
        dataIndex: "due_by",
        render: (due_by) => (
          <span style={{ fontSize: 12 }}>{moment(due_by).format("LLL")}</span>
        ),
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "due_by",
        align: "center",
        width: "8%",
      },
      {
        title: "Номер",
        dataIndex: "details",
        render: (details, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <Link to={`${record.parent}`} target="_blank">{details}</Link>
            </span>
          </div>),
        key: "details",
        align: "center",
        width: "9%",
      },
      {
        title: "Тип",
        dataIndex: "task_template",
        render: (task_template, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{record.task_owner_locale}</span>
            <span style={{ fontSize: 12 }}>{record.name}</span>
          </div>
        ),
        align: "center",
        width: "10%",
      },
      {
        title: "Тема / Описание",
        dataIndex: "subject",
        render: (subject, record) => (
          <div style={{display: "flex", flexDirection: "column"}}>
            <span>
              <Link to={`/tasks/${record.id}/edit`}>{subject}</Link>
            </span>
            <span style={{fontSize: 12}}>
              <Paragraph ellipsis={{rows: 3, expandable: true}}>
                {record.description}
              </Paragraph>
            </span>
          </div>
        ),
        key: "subject",
        align: "center",
        width: "25%",
      },
      {
        title: "Инициатор",
        dataIndex: "user",
        render: (initiator, record) => (
          initiator ?
            <span>
              <UserInfo userId={initiator.id}
                        title={initiator.name}
                        titleStyle={{fontWeight: 'bold'}}/>
            </span> :
            null
        ),
        align: "center",
        width: "10%",
      },
      {
        title: "Ответственный",
        dataIndex: "assignees",
        render: (assignees, record) => (
            <AssigneesCell initiator={record.user}
                           assignees={assignees} />
        ),
        align: "center",
        width: "10%",
      },
      {
        title: "",
        dataIndex: "unread_comment_count",
        render: (unread_comment_count, record) => (
          <Badge count={unread_comment_count}
                 title={'Непрочитанные комментарии'}
                 style={{boxShadow: "none"}}/>
        ),
        align: "center",
        width: "5%",
      },
    ];

    const faqButton = (
      <Button
        type={"ghost"}
        key="7"
        icon="question"
        onClick={this.props.onFaqToggle}
      />
    );

    let actionsButton = null;

    if (actions.length > 0) {
      actionsButton = (
        <Dropdown key={"action-menu"} overlay={this.ActionMenu(actions)}>
          <Button>Создать</Button>
        </Dropdown>
      );
    }

    let mainButton = hasSelected ? (
      <Button key="2" type="danger" ghost onClick={this.onDelete}>
        Удалить
      </Button>
    ) : (
      actionsButton
    );

    let canDelete = canCheck(currentUser, "projects:delete");

    const noticeIcon = (
      <NoticeIcon
        key="5"
        count={notices.length}
        onItemClick={this.onItemClick}
        list={notices}
        showClear
        onClear={this.onClearNotices}
        emptyText={"новых комментариев нет"}
      />
    );

    const search = (
      <Search
        allowClear
        key="search"
        placeholder="поиск..."
        value={fetchParams.search}
        onChange={onSearch}
        style={{ width: 250 }}
      />
    );

    let excelButton = (
      <Can key={"1"}
        role={currentUser.role}
        perform="orders:finance"
        yes={() => (
          <Tooltip key={"excelButtonTooltip"}
            placement="bottom" title={"Выгрузить в Excel"}>
          <Button key="excelButton" type="dashed" onClick={downloadReport}>
            <Icon type="file-excel" style={{ color: "green" }} />
            Выгрузить
          </Button>
          </Tooltip>
        )}
        no={() => null}
      />
    );

    let filterButton = (
      <Tooltip key={"filterButtonTooltip"}
        placement="bottom" title={"Фильтры"}>
        <Button
          type={
            fetchParams.startDate ||
            fetchParams.startFinishDate ||
            fetchParams.status ||
            fetchParams.templates ||
            fetchParams.taskUsers ||
            fetchParams.users ||
            fetchParams.supervisorUsers
              ? "primary"
              : "ghost"
          }
          key="filterButton"
          icon="filter"
          onClick={this.onFilterToggle}
        />
      </Tooltip>
    );

    return (
      <PageHeader
        className="page-header-with-notices"
        onBack={() => window.history.back()}
        title={`Задачи`}
        subTitle={
          fetchParams.startDate && fetchParams.endDate
            ? `c ${fetchParams.startDate} по ${fetchParams.endDate}`
            : null
        }
        extra={[faqButton, noticeIcon, search, mainButton, excelButton, filterButton]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Table
                rowSelection={canDelete ? rowSelection : null}
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={tasks}
                onChange={this.onChangeTable}
                rowKey="id"
              />
            </Col>
            <Col
              span={24}
              style={{
                paddingTop: "15px",
                paddingBottom: "15px",
                textAlign: "right",
              }}
            >
              <Pagination
                showSizeChanger
                pageSize={fetchParams.limit}
                current={meta.current_page}
                onChange={onChangePage}
                onShowSizeChange={onChangeLimit}
                showTotal={(total) => totalInfo}
                total={meta.total_count}
              />
            </Col>
          </Row>
        }
      >
        <Filter
          currentUser={currentUser}
          visible={isFilterVisible}
          status={fetchParams.status}
          taskUsers={fetchParams.owners}
          users={fetchParams.users}
          supervisorUsers={fetchParams.supervisors}
          startCreatedDate={fetchParams.startCreatedDate}
          endCreatedDate={fetchParams.endCreatedDate}
          startDate={fetchParams.startDate}
          endDate={fetchParams.endDate}
          templates={fetchParams.templates}
          fieldSets={fetchParams.fieldSets}
          onClose={this.onFilterToggle}
          onClearFilters={onClearFilters}
          onChangeStatus={onChangeFilterStatus}
          onChangeTemplates={onChangeFilterTemplates}
          onChangeTaskUsers={onChangeFilterTaskUsers}
          onChangeUsers={onChangeFilterUsers}
          onChangeSupervisorUsers={onChangeFilterSupervisorUsers}
          onChangeCreatedPeriod={onChangeFilterCreatedPeriod}
          onChangeDueByPeriod={onChangeFilterDueByPeriod}
          onChangeFieldSets={onChangeFilterFieldSets}
          downloadReport={downloadReport}
        />
      </PageHeader>
    );
  }
}

TasksList.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default withRouter(TasksList);
