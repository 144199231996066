import update from "immutability-helper";
import moment from "moment";
import "moment/locale/ru";

const initialState = {
  calendarEvents: {
    entity_tasks: [],
    order_tasks: [],
    tickets: [],
    tasks: [],
    count_overdue_tasks: null
  },
  ganttEvents: { groups: [], tasks: [] },
  ganttTotals: { total: 0, processing: 0, finished: 0, noassignees: 0 },
  post: { id: null, published_at: null, title: null, body: null },
  posts: [],
  postCount: 0,
  selectedEvent: null,
  tasks: [],
  countOverdueTasks: 0,
  taskSeries: [],
  projectSeries: [],
  ticketSeries: [],
  entityTaskSeries: [],
  tasksStatistic: {
    total: 0,
    pending: 0,
    processing: 0,
    finished: 0,
    canceled: 0,
    intime: 0,
    overdue: 0,
  },
  projectsStatistic: {
    total: 0,
    pending: 0,
    processing: 0,
    finished: 0,
    canceled: 0,
    intime: 0,
  },
  ordersStatistic: {
    total: 0,
    pending: 0,
    processing: 0,
    finished: 0,
    canceled: 0,
    intime: 0,
  },
  ticketsStatistic: {},
  ticketsBarSeries: {},
  intimeStatistic: {tickets: [], entity_tasks: [], tasks: [] },
  fetchParams: {
    startDate: null,
    endDate: null,
    entityTasks: true,
    orderTasks: true,
    tickets: true,
    tasks: true,
    users: null,
    taskTypes: null,
    taskStates: null,
  },
  fetchTasksParams: {
    startDate: moment().startOf("month").add(-1, "months").format("DD.MM.YYYY"),
    endDate: moment().format("DD.MM.YYYY"),
    startCreatedDate: null,
    endCreatedDate: null,
    startDueByDate: null,
    endDueByDate: null,
    assigneeType: "current",
    users: null,
    page: 1,
    limit: 15,
  },
  fetchStatisticParams: {
    startDate: moment().subtract(3, "months").format("DD.MM.YYYY"),
    endDate: moment().format("DD.MM.YYYY"),
    departments: null,
  },
  isDrawerVisible: false,
  isPostLoading: false,
  isPostsLoading: false,
  isCalendarLoading: false,
  isGanttLoading: false,
  isEventLoading: false,
  isStatisticLoading: true,
  isOrderStatisticLoading: true,
  isTicketsStatisticLoading: true,
  isTicketsBarSeriesLoading: true,
  isTasksLoading: true,
  isIntimeStatisticLoading: true,
  errors: false,
};

function loadLocalDashboardTaskFilters(fetchParams) {
  let taskFilters = localStorage.getItem("dashboardTaskFilters");
  if (taskFilters) {
    return JSON.parse(taskFilters);
  }
}

export default function (state = initialState, action) {
  let localFetchParams = null;

  switch (action.type) {
    // posts
    case "FETCH_POSTS_REQUEST":
      return {
        ...state,
        isPostsLoading: true,
        errors: false,
      };
    case "FETCH_POSTS":
      return {
        ...state,
        posts: action.response.data,
        postCount: action.response.postCount,
        isPostsLoading: false,
        errors: false,
      };

    case "FETCH_POSTS_FAILURE":
      return {
        ...state,
        isPostsLoading: false,
        errors: true,
      };

    case "FETCH_POST_REQUEST":
      return {
        ...state,
        isPostLoading: true,
        errors: false,
      };
    case "FETCH_POST":
      return {
        ...state,
        post: action.response.data,
        isPostLoading: false,
        errors: false,
      };

    case "FETCH_POST_FAILURE":
      return {
        ...state,
        isPostLoading: false,
        errors: true,
      };

    // calendar
    case "FETCH_CALENDAR_EVENTS_REQUEST":
      return {
        ...state,
        isCalendarLoading: true,
        errors: false,
      };
    case "FETCH_CALENDAR_EVENTS":
      return {
        ...state,
        calendarEvents: action.response.data,
        isCalendarLoading: false,
        errors: false,
      };

    case "FETCH_CALENDAR_EVENTS":
      return {
        ...state,
        calendarEvents: { entity_tasks: [], order_tasks: [], tickets: [] },
        isCalendarLoading: false,
        errors: true,
      };

    // gantt
    case "FETCH_GANTT_EVENTS_REQUEST":
      return {
        ...state,
        isGanttLoading: true,
        errors: false,
      };
    case "FETCH_GANTT_EVENTS":
      return {
        ...state,
        ganttEvents: action.response.data,
        ganttTotals: action.response.totals,
        isGanttLoading: false,
        errors: false,
      };

    case "FETCH_GANTT_EVENTS_FAILURE":
      return {
        ...state,
        ganttEvents: { groups: [], order_tasks: [] },
        ganttTotals: { total: 0, processing: 0, finished: 0, noassignees: 0 },
        isGanttLoading: false,
        errors: true,
      };

    case "FETCH_EVENT_REQUEST":
      return {
        ...state,
        isEventLoading: true,
        errors: false,
      };
    case "FETCH_EVENT":
      return {
        ...state,
        selectedEvent: action.response.data,
        isEventLoading: false,
        errors: false,
      };

    case "FETCH_EVENT_FAILURE":
      return {
        ...state,
        selectedEvent: null,
        isEventLoading: false,
        errors: true,
      };

    case "DASHBOARD_CHANGE_DATE":
      return update(state, {
        calendarEvents: { entity_tasks: [], order_tasks: [], tickets: [] },
        fetchParams: {
          startDate: { $set: action.startDate },
          endDate: { $set: action.endDate },
        },
      });

    case "DASHBOARD_CHANGE_EVENT_TYPE":
      return update(state, {
        calendarEvents: {
          entity_tasks: [],
          order_tasks: [],
          tickets: [],
          tasks: []
        },
        fetchParams: {
          [action.value]: { $set: !state.fetchParams[action.value] },
        },
      });

    case "DASHBOARD_CHANGE_USERS":
      return update(state, {
        fetchParams: {
          users: { $set: action.values },
        },
      });

    case "DASHBOARD_CHANGE_TASK_TYPES":
      return update(state, {
        fetchParams: {
          taskTypes: { $set: action.values },
        },
      });

    case "DASHBOARD_CHANGE_TASK_STATES":
      return update(state, {
        fetchParams: {
          taskStates: { $set: action.values },
        },
      });

    case "DASHBOARD_CALENDAR_CHANGE_TASK_ASSIGNEE_TYPE":
      return update(state, {
        fetchParams: {
          assigneeType: { $set: action.value },
        },
      });

    // statistic
    case "FETCH_DASHBOARD_TASKS_REQUEST":
      return {
        ...state,
        isTasksLoading: true,
        errors: false,
      };
    case "FETCH_DASHBOARD_TASKS":
      return {
        ...state,
        tasks: action.response.tasks,
        countOverdueTasks: action.response.count_overdue_tasks,
        isTasksLoading: false,
        errors: false,
      };

    case "FETCH_DASHBOARD_TASKS_FAILURE":
      return {
        ...state,
        tasks: [],
        isTasksLoading: false,
        errors: true,
      };

    case "SET_DASHBOARD_TASK_FILTERS":
      localFetchParams = loadLocalDashboardTaskFilters(state.fetchTasksParams);
      if (localFetchParams) {
        localFetchParams.search = null;
        return {
          ...state,
          fetchTasksParams: localFetchParams,
        };
      } else {
        return {
          ...state,
        };
      }

    case "CHANGE_DASHBOARD_TASK_FILTER_CREATED_PERIOD":
      return update(state, {
        fetchTasksParams: {
          startCreatedDate: { $set: action.startDate },
          endCreatedDate: { $set: action.endDate },
          page: { $set: 1 },
        },
      });

    case "CHANGE_DASHBOARD_TASK_FILTER_DUE_BY_PERIOD":
      return update(state, {
        fetchTasksParams: {
          startDueByDate: { $set: action.startDate },
          endDueByDate: { $set: action.endDate },
          page: { $set: 1 },
        },
      });

    case "CHANGE_DASHBOARD_TASK_FILTER_USERS":
      return update(state, {
        fetchTasksParams: {
          users: { $set: action.values.length > 0 ? action.values : null },
          page: { $set: 1 },
        },
      });

    case "FETCH_DASHBOARD_STATISTIC_REQUEST":
      return {
        ...state,
        isStatisticLoading: true,
        errors: false,
      };
    case "FETCH_DASHBOARD_STATISTIC":
      return {
        ...state,
        tasksStatistic: action.response.statistic,
        taskSeries: action.response.series,
        projectsStatistic: action.response.project_statistic,
        projectSeries: action.response.project_series,
        ticketSeries: action.response.ticket_series,
        entityTaskSeries: action.response.entity_tasks_series,
        isStatisticLoading: false,
        errors: false,
      };

    case "FETCH_DASHBOARD_STATISTIC_FAILURE":
      return {
        ...state,
        isStatisticLoading: false,
        errors: true,
      };

    case "FETCH_DASHBOARD_ORDERS_STATISTIC_REQUEST":
      return {
        ...state,
        isOrderStatisticLoading: true,
        errors: false,
      };

    case "FETCH_DASHBOARD_ORDERS_STATISTIC":
      return {
        ...state,
        ordersStatistic: action.response.statistic,
        isOrderStatisticLoading: false,
        errors: false,
      };

    case "FETCH_DASHBOARD_ORDERS_STATISTIC_FAILURE":
      return {
        ...state,
        isOrderStatisticLoading: false,
        errors: true,
      };

      case "FETCH_DASHBOARD_TICKETS_STATISTIC_REQUEST":
      return {
        ...state,
        isTicketsStatisticLoading: true,
        errors: false,
      };

    case "FETCH_DASHBOARD_TICKETS_STATISTIC":
      return {
        ...state,
        ticketsStatistic: action.response.data,
        isTicketsStatisticLoading: false,
        errors: false,
      };

    case "FETCH_DASHBOARD_TICKETS_STATISTIC_FAILURE":
      return {
        ...state,
        isTicketsStatisticLoading: false,
        errors: true,
      };

      case "FETCH_DASHBOARD_TICKETS_BAR_SERIES_REQUEST":
      return {
        ...state,
        isTicketsBarSeriesLoading: true,
        errors: false,
      };

    case "FETCH_DASHBOARD_TICKETS_BAR_SERIES":
      return {
        ...state,
        ticketsBarSeries: action.response.data,
        isTicketsBarSeriesLoading: false,
        errors: false,
      };

    case "FETCH_DASHBOARD_TICKETS_BAR_SERIES_FAILURE":
      return {
        ...state,
        isTicketsBarSeriesLoading: false,
        errors: true,
      };

      case "FETCH_DASHBOARD_INTIME_STATISTIC_REQUEST":
      return {
        ...state,
        isIntimeStatisticLoading: true,
        errors: false,
      };

    case "FETCH_DASHBOARD_INTIME_STATISTIC":
      return {
        ...state,
        intimeStatistic: action.response.data,
        isIntimeStatisticLoading: false,
        errors: false,
      };

    case "FETCH_DASHBOARD_INTIME_STATISTIC_FAILURE":
      return {
        ...state,
        isIntimeStatisticLoading: false,
        errors: true,
      };

    case "DASHBOARD_CHANGE_STATISTIC_DATE_RANGE":
      return update(state, {
        fetchStatisticParams: {
          startDate: { $set: action.startDate },
          endDate: { $set: action.endDate },
        },
      });

    case "DASHBOARD_CHANGE_STATISTIC_DEPARTMENTS":
      return update(state, {
        fetchStatisticParams: {
          departments: {
            $set: action.values.length > 0 ? action.values : null,
          },
        },
      });

    case "DASHBOARD_CHANGE_TASKS_ASSIGNEE_TYPE":
      return update(state, {
        fetchTasksParams: {
          assigneeType: { $set: action.value },
        },
      });

    case "DRAWER_TOGGLE":
      return {
        ...state,
        isDrawerVisible: !state.isDrawerVisible,
      };
    default:
      return state;
  }
}
