// @flow
import React, { Component } from "react";
import {
  Row,
  Col,
  Popover,
  Table,
  Button,
  Tooltip,
  Icon,
} from "antd";
import { Link } from "react-router-dom";
import { saveAs } from "file-saver";
import moment from "moment";
import "moment/locale/ru";
import api from "../../../../api";
import Can from "../../../../rules/Can";

export default class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      fetching: true,
    };
  }

  fetchOrders = async (type) => {
    const { startDate, endDate, ticketCustomers } = this.props;

    let customers = [];

    if (ticketCustomers) {
      customers = ticketCustomers.map((customer) => {
        if (customer && customer.id) {
          return customer.id;
        }
      });
    }

    const response = await api.fetch(`/dashboard/tickets_details`, {
      type: type,
      startDate: startDate,
      endDate: endDate,
      customers: customers
    });
    const data = response.data;
    this.setState({ orders: data, fetching: false });
  };

  downloadReport = async (type, filename) => {
    const { startDate, endDate, ticketCustomers } = this.props;

    let customers = [];

    if (ticketCustomers) {
      customers = ticketCustomers.map((customer) => {
        if (customer && customer.id) {
          return customer.id;
        }
      });
    }

    await api.fetchBlob(`/dashboard/tickets_details.xlsx`, {
      type: type,
      startDate: startDate,
      endDate: endDate,
      customers: customers
    }).then((response) => {
      saveAs(response, `${'tickets.xlsx'}`);
    })
    // const data = response.data;
    this.setState({ fetching: false });
  };

  render() {
    const { orders, fetching } = this.state;
    const { title, type, currentUser } = this.props;

    const content = (
      <div style={{ width: 560 }}>
        <Orders loading={fetching} data={orders} />
      </div>
    );

    const excelButton = (
      <Can
        role={currentUser.role}
        perform="orders:finance"
        yes={() => (
          <Tooltip placement="bottom" title={"Выгрузить в Excel"}>
            <Button size={'small'} key="excelButton" type="dashed" onClick={this.downloadReport.bind(this, type)}>
              <Icon type="file-excel" style={{ color: "green" }} />
              Выгрузить
            </Button>
          </Tooltip>
        )}
        no={() => null}
      />
    );

    const popoverTitle = <Row type="flex" justify="space-between">
      <span>{title}</span>
      <span>{excelButton}</span>
    </Row>

    return (
      <Popover
        placement="right"
        content={content}
        title={popoverTitle}
        trigger="click"
      >
        <span
          style={{ cursor: "pointer" }}
          onClick={this.fetchOrders.bind(this, type)}
        >
          {title}
        </span>
      </Popover>
    );
  }
}

function Orders({ loading = true, data = [] }) {
  const columns = [
    {
      title: "#",
      dataIndex: "number",
      render: (number, record) => (
        <Link to={`/tickets/${record.id}/edit`} target="_blank">
          {number}
        </Link>
      ),

      key: "number",
      align: "center",
      width: "15%",
    },
    {
      title: "Дата создания",
      dataIndex: "created_at",
      render: (created_at) => moment(created_at).format("LLL"),
      key: "created_at",
      align: "center",
      width: "20%",
    },
    {
      title: "Тема",
      dataIndex: "subject",
      render: (subject, record) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span>{subject}</span>
        </div>
      ),
      key: "subject",
      align: "center",
      width: "65%",
    },
  ];

  return (
    <Row style={{ width: 560 }}>
      <Col span={24}>
        <Table
          rowClassName={"progress-row"}
          size={"small"}
          loading={loading}
          // title={() => "Заказы в работе"}
          columns={columns}
          dataSource={data}
          pagination={false}
          rowKey="id"
          scroll={{ y: 340 }}
        />
      </Col>
    </Row>
  );
}
