import api from "../api";

const Actions = {};

Actions.fetchProjectTypes = () => {
  return (dispatch, getState) => {
    const params = getState().projectTypes.fetchParams;
    dispatch({ type: "FETCH_PROJECT_TYPES_REQUEST" });
    api
      .fetch("/project_types", params)
      .then(response => {
        // console.log(response)
        dispatch({ type: "FETCH_PROJECT_TYPES", response });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({ type: "FETCH_PROJECT_TYPES_FAILURE", response });
      });
  };
};

Actions.fetchProjectType = id => {
  return dispatch => {
    dispatch({ type: "FETCH_PROJECT_TYPE_REQUEST" });
    return api
      .fetch(`/project_types/${id}`)
      .then(response => {
        dispatch({ type: "FETCH_PROJECT_TYPE", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Этап проекта", error);
          });
        }
        dispatch({ type: "FETCH_PROJECT_TYPE_FAILURE", response });
      });
  };
};

Actions.onNew = () => {
  return dispatch => {
    dispatch({ type: "PROJECT_TYPE_ADD" });
  };
};

Actions.onUpdate = id => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_PROJECT_TYPE_REQUEST" });
    const projectType = getState().projectTypes.projectType;

    const data = {
      project_type: {
        name: projectType.name
      }
    };

    return api
      .patch(`/project_types/${id}`, data)
      .then(response => {
        dispatch({ type: "PATCH_PROJECT_TYPE", response });
        api.openNotification("success", "Этап проекта", "Успешно обновлен!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Этап проекта", error);
          });
        }
        dispatch({ type: "PATCH_PROJECT_TYPE_FAILURE", response });
      });
  };
};

Actions.onCreate = () => {
  // console.log(ids)
  return (dispatch, getState) => {
    dispatch({ type: "POST_PROJECT_TYPE_REQUEST" });
    const projectType = getState().projectTypes.projectType;

    const data = {
      project_type: {
        name: projectType.name
      }
    };

    return api
      .post(`/project_types`, data)
      .then(response => {
        dispatch({ type: "POST_PROJECT_TYPE", response });
        api.openNotification("success", "Этап проекта", "Успешно создан!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Этап проекта", error);
          });
        }
        dispatch({ type: "POST_PROJECT_TYPE_FAILURE", response });
      });
  };
};

Actions.onDelete = id => {
  return dispatch => {
    dispatch({ type: "DELETE_PROJECT_TYPE_REQUEST" });
    return api
      .delete(`/project_types/${id}`)
      .then(response => {
        api.openNotification("success", "Этап проекта", "Успешно удален!");
        dispatch({ type: "DELETE_PROJECT_TYPE", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Этап проекта", error);
          });
        }
        dispatch({
          type: "DELETE_PROJECT_TYPE_FAILURE"
        });
      });
  };
};

Actions.onToggleVisible = () => {
  return dispatch => {
    dispatch({
      type: "PROJECT_TYPE_TOGGLE_VISIBLE"
    });
  };
};

Actions.onSearch = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_PROJECT_TYPE_SEARCH",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangePage = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_PROJECT_TYPE_CHANGE_PAGE",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangeName = value => {
  return dispatch => {
    dispatch({
      type: "PROJECT_TYPE_CHANGE_NAME",
      value: value
    });
  };
};





export default Actions;
