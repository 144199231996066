// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import update from "immutability-helper";
import { connect } from "react-redux";
import Actions from "../../../../actions/payrolls";
import Form from "./Form";

class PayrollSteps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      payroll: {
        startDate: null,
        endDate: null,
        description: null,
        departments: [],
      },
    };
  }

  onCreate = () => {
    const { payroll } = this.state;
    this.props.dispatch(Actions.onCreatePayrolls(payroll)).then(() => {
      if (!this.props.errors) {
        this.props.history.push(`/finances/payrolls`);
      }
    });
  };

  onClose = () => {
    this.props.history.push(`/finances/payrolls`);
  };

  // header
  onChangePeriod = (dates, dateString) => {
    this.setState({
      payroll: update(this.state.payroll, {
        startDate: { $set: dateString[0] },
        endDate: { $set: dateString[1] },
      }),
    });
    this.props.dispatch(Actions.onChangePeriod(dateString[0], dateString[1]));
  };

  onChangeDepartments = (values) => {
    this.setState({
      payroll: update(this.state.payroll, {
        departments: { $set: values },
      }),
    });
  };

  onChangeDescription = (e) => {
    this.setState({
      payroll: update(this.state.payroll, {
        description: { $set: e.target.value },
      }),
    });
  };

  render() {
    const { payroll } = this.state;
    const { isLoading, errors } = this.props;
    return (
      <Form
        payroll={payroll}
        isLoading={isLoading}
        onClose={this.onClose}
        onCreate={this.onCreate}
        // header
        onChangePeriod={this.onChangePeriod}
        onChangeDepartments={this.onChangeDepartments}
        onChangeDescription={this.onChangeDescription}
      />
    );
  }
}

PayrollSteps.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  payroll: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  isLoading: state.payrolls.isLoadingPayrolls,
  errors: state.payrolls.errors,
});

export default connect(mapStateToProps)(withRouter(PayrollSteps));
