import api from "../api";

const Actions = {};

Actions.fetchEmployeePayrolls = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_EMPLOYEE_EMPLOYEE_PAYROLLS_REQUEST" });
    const params = getState().employeePayrolls.fetchParams;
    api
      .fetch("/employee_payrolls", params)
      .then((response) => {
        dispatch({ type: "FETCH_EMPLOYEE_PAYROLLS", response });
      })
      .catch((error) => {
        api.handleError(error, "Базовые начисления");
        dispatch({ type: "FETCH_EMPLOYEE_PAYROLLS_FAILURE" });
      });
  };
};

Actions.fetchEmployeePayroll = (id = null) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_EMPLOYEE_PAYROLL_REQUEST" });
    api
      .fetch(`/employee_payrolls/${id}`)
      .then((response) => {
        dispatch({ type: "FETCH_EMPLOYEE_PAYROLL", response });
      })
      .catch((error) => {
        api.handleError(error, "Базовое начисление");
        dispatch({ type: "FETCH_EMPLOYEE_PAYROLL_FAILURE" });
      });
  };
};

Actions.onNew = () => {
  return (dispatch) => {
    dispatch({
      type: "EMPLOYEE_PAYROLL_NEW",
    });
  };
};

Actions.onDelete = (id) => {
  return (dispatch) => {
    dispatch({ type: "DELETE_EMPLOYEE_PAYROLL_REQUEST" });
    return api
      .delete(`/employee_payrolls/${id}`)
      .then((response) => {
        api.openNotification(
          "success",
          "Базовое начисление",
          "Успешно удалено!"
        );
        dispatch({ type: "DELETE_EMPLOYEE_PAYROLL", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Базовое начисление", error);
          });
        }
        dispatch({
          type: "DELETE_EMPLOYEE_PAYROLL_FAILURE",
        });
      });
  };
};

Actions.onCreate = () => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_EMPLOYEE_PAYROLL_REQUEST" });
    const employeePayroll = getState().employeePayrolls.employeePayroll;
    const data = {
      employee_payroll: {
        date: employeePayroll.date,
        department_id: employeePayroll.department
          ? employeePayroll.department.id
          : null,
        payroll_schema_id: employeePayroll.payroll_schema
          ? employeePayroll.payroll_schema.id
          : null,
      },
    };

    return api
      .post(`/employee_payrolls`, data)
      .then((response) => {
        dispatch({ type: "POST_EMPLOYEE_PAYROLL", response });
        api.openNotification(
          "success",
          "Базовое начисление",
          "Успешно создано!"
        );
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Базовое начисление", error);
          });
        }
        dispatch({ type: "POST_EMPLOYEE_PAYROLL_FAILURE", response });
      });
  };
};

Actions.onUpdate = (data) => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_EMPLOYEE_PAYROLL_REQUEST" });
    const employeePayroll = getState().employeePayrolls.employeePayroll;
    const params = { employee_payroll: data };

    return api
      .patch(`/employee_payrolls/${employeePayroll.id}`, params)
      .then((response) => {
        dispatch({ type: "PATCH_EMPLOYEE_PAYROLL", response });
        api.openNotification(
          "success",
          "Базовое начисление",
          "Успешно обновлено!"
        );
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Базовое начисление", error);
          });
        }
        dispatch({ type: "PATCH_EMPLOYEE_PAYROLL_FAILURE", response });
      });
  };
};

Actions.onSearch = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_EMPLOYEE_PAYROLL_SEARCH",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangePage = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_EMPLOYEE_PAYROLL_CHANGE_PAGE",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeLimit = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_EMPLOYEE_PAYROLL_CHANGE_LIMIT",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterActive = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_EMPLOYEE_PAYROLLS_FILTER_ACTIVE",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onSortBy = (field, direction) => {
  return (dispatch) => {
    dispatch({
      type: "EMPLOYEE_PAYROLLS_SORT_BY",
      field: field,
      direction: direction,
    });
    return Promise.resolve();
  };
};

Actions.onChangeActive = (value) => {
  return (dispatch) => {
    dispatch({
      type: "EMPLOYEE_PAYROLL_CHANGE_ACTIVE",
      value: value,
    });
  };
};

Actions.onChangeDate = (value) => {
  return (dispatch) => {
    dispatch({
      type: "EMPLOYEE_PAYROLL_CHANGE_DATE",
      value: value,
    });
  };
};

Actions.onChangeDepartment = (value) => {
  return (dispatch) => {
    dispatch({
      type: "EMPLOYEE_PAYROLL_CHANGE_DEPARTMENT",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangePayrollSchema = (value) => {
  return (dispatch) => {
    dispatch({
      type: "EMPLOYEE_PAYROLL_CHANGE_PAYROLL_SCHEMA",
      value: value,
    });
  };
};

// employee_payroll_items
Actions.onReFillInEmployees = () => {
  return (dispatch, getState) => {
    dispatch({ type: "REFILL_IN_EMPLOYEE_PAYROLL_ITEMS_REQUEST" });
    const employeePayroll = getState().employeePayrolls.employeePayroll;
    const data = {
      employee_payroll: {
        date: employeePayroll.date,
        department_id: employeePayroll.department
          ? employeePayroll.department.id
          : null,
        payroll_schema_id: employeePayroll.payroll_schema
          ? employeePayroll.payroll_schema.id
          : null,
      },
    };

    return api
      .patch(`/employee_payrolls/${employeePayroll.id}/fillin`, data)
      .then((response) => {
        dispatch({ type: "REFILL_IN_EMPLOYEE_PAYROLL_ITEMS", response });
        api.openNotification(
          "success",
          "Базовое начисление",
          "Успешно заполено!"
        );
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Базовое начисление", error);
          });
        }
        dispatch({
          type: "REFILL_IN_EMPLOYEE_PAYROLL_ITEMS_FAILURE",
          response,
        });
      });
  };
};

Actions.onUpdateEmployeePayrollItem = (id, data) => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_EMPLOYEE_PAYROLL_ITEM_REQUEST" });
    const employeePayroll = getState().employeePayrolls.employeePayroll;
    const params = { employee_payroll_item: data };

    api
      .patch(
        `/employee_payrolls/${employeePayroll.id}/employee_payroll_items/${id}`,
        params
      )
      .then((response) => {
        dispatch({ type: "PATCH_EMPLOYEE_PAYROLL_ITEM", response });
        api.openNotification("success", "Строка", "Успешно обновлена!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Строка", error);
          });
        }
        dispatch({ type: "PATCH_EMPLOYEE_PAYROLL_ITEM_FAILURE", response });
      });
  };
};

Actions.onDeleteEemployees = (employeePayrollId, id) => {
  return (dispatch) => {
    dispatch({ type: "DELETE_EMPLOYEE_PAYROLL_ITEM_REQUEST" });
    return api
      .delete(
        `/employee_payrolls/${employeePayrollId}/employee_payroll_items/${id}`
      )
      .then((response) => {
        api.openNotification("success", "Строка", "Успешно удалена!");
        dispatch({ type: "DELETE_EMPLOYEE_PAYROLL_ITEM", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Строка", error);
          });
        }
        dispatch({
          type: "DELETE_EMPLOYEE_PAYROLL_ITEM_FAILURE",
        });
      });
  };
};

Actions.onCreateEmployee = (data) => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_EMPLOYEE_PAYROLL_ITEM_REQUEST" });
    const employeePayroll = getState().employeePayrolls.employeePayroll;
    const params = {
      employee_payroll_item: {
        user_id: data.user ? data.user.id : null,
        salary: data.salary,
      },
    };

    api
      .post(
        `/employee_payrolls/${employeePayroll.id}/employee_payroll_items`,
        params
      )
      .then((response) => {
        dispatch({ type: "POST_EMPLOYEE_PAYROLL_ITEM", response });
        api.openNotification("success", "Строка", "Успешно добавлена!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Строка", error);
          });
        }
        dispatch({ type: "POST_EMPLOYEE_PAYROLL_ITEM_FAILURE", response });
      });
  };
};

export default Actions;
