// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Actions from "../../../../actions/employeePayrolls";
import List from "./List";

class EmployeePayrolls extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.fetchEmployeePayrolls());
  };

  onDelete = ids => {
    ids.map(id => {
      this.props.dispatch(Actions.onDelete(id)).then(() => {
        this.props.dispatch(Actions.fetchEmployeePayrolls());
      });
    });
  };


  onChangePage = value => {
    this.props
      .dispatch(Actions.onChangePage(value))
      .then(() => this.props.dispatch(Actions.fetchEmployeePayrolls()));
  };

  onChangeLimit = (current, size) => {
    this.props
      .dispatch(Actions.onChangeLimit(size))
      .then(() => this.props.dispatch(Actions.fetchEmployeePayrolls()));
  };


  onSortBy = (field, direction) => {
    this.props
      .dispatch(Actions.onSortBy(field, direction))
      .then(() => this.props.dispatch(Actions.fetchEmployeePayrolls()));
  };

  render() {
    const { isLoading, employeePayrolls, meta, fetchParams } = this.props;
    return (
      <List
        loading={isLoading}
        employeePayrolls={employeePayrolls}
        meta={meta}
        fetchParams={fetchParams}
        onDelete={this.onDelete}
        onChangePage={this.onChangePage}
        onChangeLimit={this.onChangeLimit}
        onSortBy={this.onSortBy}
      />
    );
  }
}

EmployeePayrolls.propTypes = {
  employeePayrolls: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  fetchParams: PropTypes.object,
  meta: PropTypes.object,
  dispatch: PropTypes.func,
  onDelete: PropTypes.func
};

const mapStateToProps = state => ({
  isLoading: state.employeePayrolls.isLoading,
  employeePayrolls: state.employeePayrolls.employeePayrolls,
  meta: state.employeePayrolls.meta,
  fetchParams: state.employeePayrolls.fetchParams
});

export default connect(mapStateToProps)(EmployeePayrolls);
