// @flow
import React, { Component } from "react";
import { Row, Col, Modal, Form, Input, InputNumber } from "antd";

import "moment/locale/ru";

import SelectFetch from "../../../../components/SelectFetch";

const FormItem = Form.Item;

class PayrollItem extends React.Component {
  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onSave(values);
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      visible,
      onCancel,
      user,
      salary,
      wage,
      payrollSchema,
      description,
      departmentId,
      onChangeUser,
      onChangeSalary,
      onChangeWage,
      onChangeDescription
    } = this.props;
    return (
      <Modal
        title={"Добавить в список"}
        destroyOnClose
        visible={visible}
        onOk={this.onSubmit}
        onCancel={onCancel}
      >
        <Form layout={"vertical"}>
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <FormItem label={"Сотрудник"}>
                {getFieldDecorator("user", {
                  initialValue: user ? user : null,
                  rules: [
                    {
                      required: true,
                      message: "Пожалуйста, раздел!",
                    },
                  ],
                })(
                  <SelectFetch
                    showSearch
                    showSubtitle
                    placeholder="Сотрудник"
                    onChange={onChangeUser}
                    selected={user ? user : null}
                    url={`/users/search/?department_id=${departmentId}`}
                  />
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label={"Базовое начисление"}>
                {getFieldDecorator("salary", {
                  onChange: onChangeSalary,
                  initialValue: salary,
                })(<InputNumber disabled={true}  style={{width: "100%"}} />)}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label={"Бонусы"}>
                {getFieldDecorator("wage", {
                  onChange: onChangeWage,
                  initialValue: wage,
                })(<InputNumber disabled={payrollSchema ? true : false}  style={{width: "100%"}} />)}
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem label={"Описание"}>
                {getFieldDecorator("description", {
                  onChange: onChangeDescription,
                  initialValue: description,
                  rules: [
                    {
                      required: true,
                      message: "Введите описание!",
                    },
                  ],
                })(<Input />)}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(PayrollItem);
