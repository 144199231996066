// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import update from "immutability-helper";
import Actions from "../../../actions/reports";
import DataList from "./DataList";

import moment from "moment";
import "moment/locale/ru";

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchParams: {
        startDate: moment().startOf("month").format("DD.MM.YYYY HH:mm"),
        endDate: moment().endOf("day").format("DD.MM.YYYY HH:mm"),
        role: 3,
        customers: null,
        departments: null,
        page: 1,
        limit: 100,
      },
      period: 0,
      height: 0,
    };
    this.updateHeight = this.updateHeight.bind(this);
  }

  componentWillMount = () => {
    // console.log("Test")
    this.props.dispatch(Actions.fetchDaily(this.state.fetchParams));
    this.updateHeight();
    window.addEventListener("resize", this.updateHeight);
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateHeight);
  }

  updateHeight() {
    this.setState({ height: window.innerHeight });
  }

  onChangeDateRange = (dates) => {
    this.state.fetchParams.startDate = moment(dates[0]).format(
      "DD.MM.YYYY HH:mm"
    );
    this.state.fetchParams.endDate = moment(dates[1]).format(
      "DD.MM.YYYY HH:mm"
    );
    this.setState(
      { fetchParams: this.state.fetchParams, period: 0 },
      function () {
        this.props.dispatch(Actions.fetchDaily(this.state.fetchParams));
      }
    );
  };

  onChangeRole = (value) => {
    this.setState(
      {
        fetchParams: update(this.state.fetchParams, {
          role: { $set: value },
        }),
      },
      () => {
        this.props.dispatch(Actions.fetchDaily(this.state.fetchParams));
      }
    );
  };

  onChangeCustomers = (values) => {
    const customers = values.map((value) => {
      return value.id;
    });

    this.setState(
      {
        fetchParams: update(this.state.fetchParams, {
          customers: { $set: customers.length > 0 ? customers : null },
        }),
      },
      () => {
        this.props.dispatch(Actions.fetchDaily(this.state.fetchParams));
      }
    );
  };

  onChangeDepartments = (values) => {
    const departments = values.map((value) => {
      return value.id;
    });

    this.setState(
      {
        fetchParams: update(this.state.fetchParams, {
          departments: { $set: departments.length > 0 ? departments : null },
        }),
      },
      () => {
        this.props.dispatch(Actions.fetchDaily(this.state.fetchParams));
      }
    );
  };

  onDetails = (user_id, type) => {
    const { fetchParams } = this.state;
    this.props.dispatch(
      Actions.fetchDailyDetails(
        user_id,
        type,
        fetchParams.startDate,
        fetchParams.endDate
      )
    );
  };

  render() {
    const {
      isLoading,
      tickets,
      meta,
      totals,
      isLoadingDetails,
      details,
    } = this.props;
    const { fetchParams, period, height } = this.state;
    return (
      <DataList
        height={height}
        loading={isLoading}
        tickets={tickets}
        meta={meta}
        totals={totals}
        fetchParams={fetchParams}
        period={period}
        onChangeDateRange={this.onChangeDateRange}
        onChangeRole={this.onChangeRole}
        onChangeCustomers={this.onChangeCustomers}
        onChangeDepartments={this.onChangeDepartments}
        // details
        isLoadingDetails={isLoadingDetails}
        details={details}
        onDetails={this.onDetails}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.reports.isLoading,
  tickets: state.reports.tickets,
  meta: state.reports.meta,
  totals: state.reports.totals,
  // details
  isLoadingDetails: state.reports.isLoadingDetails,
  details: state.reports.details,
});

export default connect(mapStateToProps)(Report);
