import update from "immutability-helper";

const initialState = {
  payrolls: [],
  payroll: { employees: [], payroll_items: [], payroll_penalties: [] },
  employee_details: [],
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1,
  },
  fetchParams: {
    is_active: null,
    search: null,
    sort: null,
    direction: null,
    page: 1,
    limit: 15,
  },
  errors: false,
  isLoading: true,
  isLoadindPayrollItems: false,
  isLoadindEmployeeDetails: false,
  isLoadingPayrolls: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "FETCH_PAYROLLS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_PAYROLLS":
      return {
        ...state,
        payrolls: action.response.data,
        meta: action.response.meta,
        isLoading: false,
      };
    case "FETCH_PAYROLLS_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "FETCH_PAYROLL_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_PAYROLL":
      return {
        ...state,
        payroll: action.response.data,
        isLoading: false,
      };

    case "FETCH_PAYROLL_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "FETCH_PAYROLL_EMPLOEES_REQUEST":
      return {
        ...state,
        isLoadindPayrollItems: true,
      };

    case "FETCH_PAYROLL_EMPLOEES":
      return update(state, {
        payroll: {
          employees: {
            $set: action.response.data,
          },
        },
        isLoadindPayrollItems: { $set: false },
        errors: { $set: false },
      });

    case "FETCH_PAYROLL_EMPLOEES_FAILURE":
      return {
        ...state,
        errors: true,
        isLoadindPayrollItems: false,
      };

    case "FETCH_PAYROLL_ITEMS_REQUEST":
      return {
        ...state,
        isLoadindPayrollItems: true,
      };

    case "FETCH_PAYROLL_ITEMS":
      return update(state, {
        payroll: {
          payroll_items: {
            $set: action.response.data,
          },
        },
        isLoadindPayrollItems: { $set: false },
        errors: { $set: false },
      });

    case "FETCH_PAYROLL_ITEMS_FAILURE":
      return {
        ...state,
        errors: true,
        isLoadindPayrollItems: false,
      };

    case "FETCH_PAYROLL_PENALTIES_REQUEST":
      return {
        ...state,
        isLoadindPayrollItems: true,
      };

    case "FETCH_PAYROLL_PENALTIES":
      return update(state, {
        payroll: {
          payroll_penalties: {
            $set: action.response.data,
          },
        },
        isLoadindPayrollItems: { $set: false },
        errors: { $set: false },
      });

    case "FETCH_PAYROLL_PENALTIES_FAILURE":
      return {
        ...state,
        errors: true,
        isLoadindPayrollItems: false,
      };

    case "FILL_IN_PAYROLL_ITEMS_REQUEST":
      return {
        ...state,
        isLoadindPayrollItems: true,
        errors: false,
      };
    case "FILL_IN_PAYROLL_ITEMS":
      return {
        ...state,
        payroll: action.response.data,
        isLoadindPayrollItems: false,
        errors: false,
      };

    case "FILL_IN_PAYROLL_ITEMS_FAILURE":
      return {
        ...state,
        isLoadindPayrollItems: false,
        errors: true,
      };

    case "REFILL_IN_PAYROLL_ITEMS_REQUEST":
      return {
        ...state,
        isLoadindPayrollItems: true,
        errors: false,
      };
    case "REFILL_IN_PAYROLL_ITEMS":
      return {
        ...state,
        payroll: action.response.data,
        isLoadindPayrollItems: false,
        errors: false,
      };

    case "REFILL_IN_PAYROLL_ITEMS_FAILURE":
      return {
        ...state,
        isLoadindPayrollItems: false,
        errors: true,
      };

    case "DELETE_PAYROLL_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };
    case "DELETE_PAYROLL":
      return {
        ...state,
        payrolls: action.response.data,
        isLoading: false,
        errors: false,
      };
    case "DELETE_PAYROLL_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "PAYROLL_NEW":
      return {
        ...state,
        errors: false,
        payroll: {
          employees: [],
          payroll_penalties: [],
          is_active: true,
        },
        isLoading: false,
      };

    case "REQUEST_PAYROLL_SEARCH":
      return update(state, {
        fetchParams: { search: { $set: action.value }, page: { $set: 1 } },
      });

    case "REQUEST_PAYROLL_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } },
      });

    case "REQUEST_PAYROLL_CHANGE_LIMIT":
      return update(state, {
        fetchParams: { limit: { $set: action.value }, page: { $set: 1 } },
      });

    case "PAYROLLS_SORT_BY":
      return update(state, {
        fetchParams: {
          sort: { $set: action.field },
          direction: { $set: action.direction },
        },
      });

    case "PAYROLL_CHANGE_ACTIVE":
      return update(state, {
        payroll: { is_active: { $set: action.value } },
      });

    case "PAYROLL_CHANGE_PERIOD":
      return update(state, {
        payroll: {
          start_at: { $set: action.start },
          end_at: { $set: action.end },
        },
      });

    case "PAYROLL_CHANGE_DEPARTMENT":
      return update(state, {
        payroll: { department: { $set: action.value } },
      });

    case "PAYROLL_CHANGE_DESCRIPTION":
      return update(state, {
        payroll: { description: { $set: action.value } },
      });

    case "PATCH_PAYROLL_ACTIVE_REQUEST":
      return {
        ...state,
        errors: false,
      };
    case "PATCH_ACTIVE_PAYROLL":
      return update(state, {
        payroll: { is_active: { $set: action.value } },
      });

    case "PATCH_ACTIVE_PAYROLL_FAILURE":
      return {
        ...state,
        errors: true,
      };

    // penalties
    case "POST_PAYROLL_PENALTY_REQUEST":
      return {
        ...state,
        isLoadindPayrollItems: true,
        errors: false,
      };
    case "POST_PAYROLL_PENALTY_SUCCEED":
      return update(state, {
        payroll: {
          payroll_penalties: {
            $set: action.response.data,
          },
        },
        isLoadindPayrollItems: { $set: false },
        errors: { $set: false },
      });

    case "POST_PAYROLL_PENALTY_FAILURE":
      return {
        ...state,
        isLoadindPayrollItems: false,
        errors: true,
      };

    case "PATCH_PAYROLL_PENALTY_REQUEST":
      return {
        ...state,
        errors: false,
        isLoadindPayrollItems: true,
      };

    case "PATCH_PAYROLL_PENALTY_SUCCEED":
      return update(state, {
        payroll: {
          payroll_penalties: {
            $set: action.response.data,
          },
        },
        isLoadindPayrollItems: { $set: false },
        errors: { $set: false },
      });

    case "PATCH_PAYROLL_PENALTY_FAILURE":
      return {
        ...state,
        errors: true,
        isLoadindPayrollItems: false,
      };

    case "DELETE_PAYROLL_PENALTIES_REQUEST":
      return {
        ...state,
        isLoadindPayrollItems: true,
        errors: false,
      };

    case "DELETE_PAYROLL_PENALTIES_SUCCEED":
      return update(state, {
        payroll: {
          payroll_penalties: {
            $set: action.response.data,
          },
        },
        isLoadindPayrollItems: { $set: false },
        errors: { $set: false },
      });

    case "DELETE_PAYROLL_PENALTIES_FAILURE":
      return {
        ...state,
        isLoadindPayrollItems: false,
        errors: true,
      };

    // payrollItems
    case "POST_PAYROLL_ITEM_REQUEST":
      return {
        ...state,
        isLoadindPayrollItems: true,
        errors: false,
      };
    case "POST_PAYROLL_ITEM_SUCCEED":
      return update(state, {
        payroll: {
          payroll_items: {
            $set: action.response.data,
          },
        },
        isLoadindPayrollItems: { $set: false },
        errors: { $set: false },
      });

    case "POST_PAYROLL_ITEM_FAILURE":
      return {
        ...state,
        isLoadindPayrollItems: false,
        errors: true,
      };

    case "PATCH_PAYROLL_ITEM_REQUEST":
      return {
        ...state,
        errors: false,
        isLoadindPayrollItems: true,
      };

    case "PATCH_PAYROLL_ITEM_SUCCEED":
      return update(state, {
        payroll: {
          payroll_items: {
            $set: action.response.data,
          },
        },
        isLoadindPayrollItems: { $set: false },
        errors: { $set: false },
      });

    case "PATCH_PAYROLL_ITEM_FAILURE":
      return {
        ...state,
        errors: true,
        isLoadindPayrollItems: false,
      };

    case "DELETE_PAYROLL_ITEMS_REQUEST":
      return {
        ...state,
        isLoadindPayrollItems: true,
        errors: false,
      };

    case "DELETE_PAYROLL_ITEMS_SUCCEED":
      return update(state, {
        payroll: {
          payroll_items: {
            $set: action.response.data,
          },
        },
        isLoadindPayrollItems: { $set: false },
        errors: { $set: false },
      });

    case "DELETE_PAYROLL_ITEMS_FAILURE":
      return {
        ...state,
        isLoadindPayrollItems: false,
        errors: true,
      };

    case "FETCH_PAYROLL_EMPLOYEE_DETAILS_REQUEST":
      return {
        ...state,
        isLoadindEmployeeDetails: true,
      };
    case "FETCH_PAYROLL_EMPLOYEE_DETAILS":
      return {
        ...state,
        employee_details: action.response.data,
        isLoadindEmployeeDetails: false,
      };

    case "FETCH_PAYROLL_EMPLOYEE_DETAILS_FAILURE":
      return {
        ...state,
        errors: true,
        isLoadindEmployeeDetails: false,
      };

    // payrolls
    case "POST_PAYROLLS_REQUEST":
      return {
        ...state,
        isLoadingPayrolls: true,
        errors: false,
      };
    case "POST_PAYROLLS_SUCCEED":
      return update(state, {
        isLoadingPayrolls: { $set: false },
        errors: { $set: false },
      });

    case "POST_PAYROLLS_FAILURE":
      return {
        ...state,
        isLoadingPayrolls: false,
        errors: true,
      };

    default:
      return state;
  }
}
