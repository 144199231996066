// @flow
import React, { Component } from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Form, Icon, Input, Button } from "antd";
import Actions from "../../actions/session";

const FormItem = Form.Item;

class Confirm extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  onSubmit = (e) => {
    const token = this.props.match.params.token;
    const {
      history: { push },
    } = this.props;
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.dispatch(Actions.confirm(values, token, push));
      }
    });
  };

  render() {
    const { submitting } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="loginpagewrap">
        <div className="loginWrap">
          <Row gutter={16} type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <h2 style={{textAlign: "center"}}>Задайте пароль</h2>
            </Col>
          </Row>
          <Row gutter={16}>
            <Form onSubmit={this.onSubmit}>
              <FormItem>
                {getFieldDecorator("password", {
                  rules: [{ required: true, message: "Введите пароль!" }],
                })(
                  <Input
                    prefix={
                      <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    type="password"
                    placeholder="пароль"
                  />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator("password_confirmation", {
                  rules: [
                    { required: true, message: "Введите пароль еще раз!" },
                  ],
                })(
                  <Input
                    prefix={
                      <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    type="password"
                    placeholder="подтверждение пароля"
                  />
                )}
              </FormItem>
              <FormItem>
                <Button
                  type="submit"
                  disabled={submitting}
                  htmlType="submit"
                  className="login-form-button"
                >
                  {submitting ? "Сохранение..." : "Сохранить"}
                </Button>
              </FormItem>
            </Form>
          </Row>
        </div>
      </div>
    );
  }
}

Confirm.propTypes = {
  dispatch: PropTypes.func,
};

const mapStateToProps = (state) => ({
  errors: state.session.errors,
});

export default connect(mapStateToProps)(withRouter(Form.create()(Confirm)));
