// @flow
import React, { Component } from "react";
import { Modal, Row, Col, Form, Input, Icon, Select } from "antd";
import PropTypes from "prop-types";

const FormItem = Form.Item;
const { Option } = Select;

class ModalForm extends Component {
  onSave = () => {
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }

      this.props.onSave();
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const {
      entityType,
      visible,
      onCancel,
      onChangeName,
      onChangeIcon,
    } = this.props;

    return (
      <Modal
        title={`Тип #${entityType.id ? entityType.name : "новый"} `}
        destroyOnClose
        visible={visible}
        width={800}
        onOk={this.onSave}
        onCancel={onCancel}
      >
        <Form layout={"vertical"}>
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={16}>
              <FormItem label={"Наименование"}>
                {getFieldDecorator("name", {
                  onChange: onChangeName,
                  initialValue: entityType.name,
                  rules: [
                    {
                      required: true,
                      message: "Введите наименование!",
                    },
                  ],
                })(<Input placeholder="введите наименование" />)}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label={"Иконка"}>
                {getFieldDecorator("icon", {
                  onChange: onChangeIcon,
                  initialValue: entityType.icon,
                })(
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Иконка"
                    optionLabelProp="label"
                  >
                    <Option value="bank" label="bank">
                      <span style={{ marginRight: 10 }}>
                        <Icon type="bank" />
                      </span>
                      bank
                    </Option>
                    <Option value="cloud" label="cloud">
                      <span style={{ marginRight: 10 }}>
                        <Icon type="cloud" />
                      </span>
                      cloud
                    </Option>
                    <Option value="database" label="database">
                      <span style={{ marginRight: 10 }}>
                        <Icon type="database" />
                      </span>
                      database
                    </Option>
                    <Option value="environment" label="environment">
                      <span style={{ marginRight: 10 }}>
                        <Icon type="environment" />
                      </span>
                      environment
                    </Option>

                    <Option value="fire" label="fire">
                      <span style={{ marginRight: 10 }}>
                        <Icon type="fire" />
                      </span>
                      fire
                    </Option>
                    <Option value="home" label="home">
                      <span style={{ marginRight: 10 }}>
                        <Icon type="home" />
                      </span>
                      home
                    </Option>
                    <Option value="shopping" label="shopping">
                      <span style={{ marginRight: 10 }}>
                        <Icon type="shopping" />
                      </span>
                      shopping
                    </Option>
                    <Option value="safety" label="safety">
                      <span style={{ marginRight: 10 }}>
                        <Icon type="safety" />
                      </span>
                      safety
                    </Option>
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

ModalForm.propTypes = {
  entityType: PropTypes.object,
};

export default Form.create()(ModalForm);
