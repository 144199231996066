// @flow
import React, {Component} from "react";
import PropTypes from "prop-types";
import {Card, Row, Col, Form, Select, Switch, Checkbox} from "antd";
import moment from "moment";
import "moment/locale/ru";
import api from "../../../../api";

const {Option, OptGroup} = Select;

class Sidebar extends Component {
  render() {
    const {
      permissions,
      onChangePermissionForAdministrator,
      onChangePermissionForManager,
      onChangePermissionForEngineer,
      onChangePermissionForSupervisor,
      onChangePermissionForSimple,
      getFieldDecorator
    } = this.props;

    let permissionForAdministrator = permissions.find((it) => it.role === 'administrator')
    let permissionForManager = permissions.find((it) => it.role === 'manager')
    let permissionForEngineer = permissions.find((it) => it.role === 'engineer')
    let permissionForSupervisor = permissions.find((it) => it.role === 'supervisor')
    let permissionForSimple = permissions.find((it) => it.role === 'simple')

    return (
      <Card style={{borderColor: "#1890ff"}}>
        <Row gutter={10}>
          <Col span={24}>
            {getFieldDecorator("permissionForAdministrator", {
              valuePropName: "checked",
              initialValue: permissionForAdministrator?.enabled,
              onChange: onChangePermissionForAdministrator,
            })(<Checkbox disabled={true}>Администратор</Checkbox>)}
          </Col>
          <Col span={24}>
            {getFieldDecorator("permissionForManager", {
              valuePropName: "checked",
              initialValue: permissionForManager?.enabled,
              onChange: onChangePermissionForManager,
            })(<Checkbox>Руководитель</Checkbox>)}
          </Col>
          <Col span={24}>
            {getFieldDecorator("permissionForEngineer", {
              valuePropName: "checked",
              initialValue: permissionForEngineer?.enabled,
              onChange: onChangePermissionForEngineer,
            })(<Checkbox>Инженер</Checkbox>)}
          </Col>
          <Col span={24}>
            {getFieldDecorator("permissionForSupervisor", {
              valuePropName: "checked",
              initialValue: permissionForSupervisor?.enabled,
              onChange: onChangePermissionForSupervisor,
            })(<Checkbox>Менеджер</Checkbox>)}
          </Col>
          <Col span={24}>
            {getFieldDecorator("permissionForSimple", {
              valuePropName: "checked",
              initialValue: permissionForSimple?.enabled,
              onChange: onChangePermissionForSimple,
            })(<Checkbox>Пользователь</Checkbox>)}
          </Col>
        </Row>
      </Card>
    );
  }
}

Sidebar.propTypes = {
  customer: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  onAdressEdit: PropTypes.func,
  onChangePaymentType: PropTypes.func,
  onChangePaymentStatus: PropTypes.func,
  onChangeShippingStatus: PropTypes.func,
  onChangeShippingMethod: PropTypes.func,
};

export default Sidebar;
