import update from "immutability-helper";

const initialState = {
  users: [],
  user: {user_profile: { settings: {}}},
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1,
  },
  fetchParams: {
    is_active: true,
    search: null,
    sort: null,
    direction: null,
    page: 1,
    limit: 15,
  },
  errors: false,
  isLoading: true,
  isUploadingAvatar: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "FETCH_USERS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_USERS":
      return {
        ...state,
        users: action.response.data,
        meta: action.response.meta,
        isLoading: false,
      };
    case "FETCH_USERS_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "FETCH_USER_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_USER":
      return {
        ...state,
        user: action.response.data,
        isLoading: false,
      };

    case "FETCH_USER_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };
    case "PATCH_USER_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };
    case "PATCH_USER":
      return {
        ...state,
        user: action.response.data,
        isLoading: false,
        errors: false,
      };

    case "PATCH_USER_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "POST_USER_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "POST_USER":
      return {
        ...state,
        errors: false,
        user: action.response.data,
        isLoading: false,
      };

    case "POST_USER_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "DELETE_USER_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };
    case "DELETE_USER":
      return {
        ...state,
        users: action.response.data,
        isLoading: false,
        errors: false,
      };
    case "DELETE_USER_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "USER_NEW":
      return {
        ...state,
        errors: false,
        user: {
          is_active: true,
          role: "manager",
          user_profile: { settings: { tickets: { all: false } } },
        },
        isLoading: false,
      };

    case "PATCH_PASSWORD_USER_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "PATCH_PASSWORD_USER":
      return {
        ...state,
        errors: false,
        isLoading: false,
      };
    case "PATCH_PASSWORD_USER_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "REQUEST_USER_SEARCH":
      return update(state, {
        fetchParams: { search: { $set: action.value }, page: { $set: 1 } },
      });

    case "REQUEST_USER_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } },
      });

    case "REQUEST_USER_CHANGE_LIMIT":
      return update(state, {
        fetchParams: { limit: { $set: action.value }, page: { $set: 1 } },
      });

    case "REQUEST_USERS_FILTER_ACTIVE":
      return update(state, {
        fetchParams: { is_active: { $set: action.value } },
      });

    case "USERS_SORT_BY":
      return update(state, {
        fetchParams: {
          sort: { $set: action.field },
          direction: { $set: action.direction },
        },
      });

    case "USER_CHANGE_ACTIVE":
      return update(state, {
        user: { is_active: { $set: action.value } },
      });

    case "USER_CHANGE_START":
      return update(state, {
        user: { start: { $set: action.value } },
      });

    case "USER_CHANGE_FINISH":
      return update(state, {
        user: { finish: { $set: action.value } },
      });

    case "USER_CHANGE_ROLE":
      return update(state, {
        user: { role: { $set: action.value } },
      });

    case "USER_CHANGE_NAME":
      return update(state, {
        user: { name: { $set: action.value } },
      });

    case "USER_CHANGE_DEPARTMENT":
      return update(state, {
        user: { department: { $set: action.value } },
      });

    case "USER_CHANGE_EMAIL":
      return update(state, {
        user: { email: { $set: action.value } },
      });

    case "USER_CHANGE_TITLE":
      return update(state, {
        user: { title: { $set: action.value } },
      });

    case "USER_CHANGE_PHONE":
      return update(state, {
        user: { phone: { $set: action.value } },
      });

    case "USER_CHANGE_PASSWORD":
      return update(state, {
        user: { password: { $set: action.value } },
      });

    case "USER_CHANGE_PASSWORD_CONFIRMATION":
      return update(state, {
        user: { password_confirmation: { $set: action.value } },
      });

    // avatar
    case "USER_AVATAR_UPLOAD":
      return update(state, {
        isUploadingAvatar: { $set: true },
      });

    case "USER_AVATAR_UPLOAD_SUCCEED":
      return update(state, {
        user: { avatar: { $set: action.response.data.avatar } },
        errors: { $set: false },
        isUploadingAvatar: { $set: false },
      });

    case "USER_AVATAR_UPLOAD_FAILED":
      return update(state, {
        errors: { $set: true },
        isUploadingAvatar: { $set: false },
      });

    case "USER_CHANGE_SETTINGS_TICKETS":
      return update(state, {
        user: {
          user_profile: {
            settings: {
              tickets: {
                all: {
                  $set: action.value,
                },
              },
            },
          },
        },
      });

    default:
      return state;
  }
}
