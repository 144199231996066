// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../../actions/projectTypes";
import ModalForm from "./Form";

class ProjectType extends Component {
  onSave = () => {
    if (this.props.projectType.id) {
      this.props
        .dispatch(Actions.onUpdate(this.props.projectType.id))
        .then(() => {
          this.props.dispatch(Actions.fetchProjectTypes());
        });
    } else {
      this.props.dispatch(Actions.onCreate()).then(() => {
        this.props.dispatch(Actions.fetchProjectTypes());
      });
    }
  };

  onCancel = () => {
    this.props.dispatch(Actions.onToggleVisible());
  };

  onChangeName = e => {
    this.props.dispatch(Actions.onChangeName(e.target.value));
  };



  render() {
    const { isLoading, projectType, isModalVisible } = this.props;
    return (
      <ModalForm
        destroyOnClose
        isLoading={isLoading}
        visible={isModalVisible}
        projectType={projectType}
        onSave={this.onSave}
        onCancel={this.onCancel}
        onChangeName={this.onChangeName}
      />
    );
  }
}

ProjectType.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  projectType: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool
};

const mapStateToProps = state => ({
  isLoading: state.projectTypes.isLoading,
  isModalVisible: state.projectTypes.isModalVisible,
  errors: state.projectTypes.errors,
  projectType: state.projectTypes.projectType
});

export default connect(mapStateToProps)(withRouter(ProjectType));
