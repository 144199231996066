// @flow
import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import Actions from "../../actions/dashboard";
import Can from "../../rules/Can";
import Container from "./Container";
import moment from "moment";
import "moment/locale/ru";
import {compose} from "redux";
import {withRouter} from "react-router-dom";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderCustomers: null,
      ticketCustomers: null,
    };
  }

  componentWillMount = () => {
    this.props.dispatch(Actions.setDashboardTaskFilters())
      .then(() => this.props.dispatch(Actions.fetchTasks()))
  };

  onDrawerToggle = () => {
    this.props.dispatch(Actions.onDrawerToggle()).then(() => {
      if (this.props.isDrawerVisible) {
        this.props.dispatch(Actions.fetchPosts());
      }
    });
  };

  onSelectEvent = (id, eventType) => {
    this.props.dispatch(Actions.fetchEvent(id, eventType));
  };
  // calendar
  fetchCalendarEvents = (params) => {
    this.props.dispatch(Actions.fetchCalendarEvents());
  };

  onChangeEventType = (e) => {
    this.props.dispatch(Actions.onChangeEventType(e.target.value))
      .then(() => this.props.dispatch(Actions.fetchCalendarEvents()));
  };

  onCalendarNavigate = (day, view) => {
    let startDate = moment(day).startOf("month").format("YYYY-MM-DD");
    let endDate = moment(day).endOf("month").format("YYYY-MM-DD");
    this.setState({listView: false}, () => {
      this.props
        .dispatch(Actions.onChangeDate(startDate, endDate))
        .then(() => this.props.dispatch(Actions.fetchCalendarEvents()));
    });
  };

  onChangeUsers = (values) => {
    this.props
      .dispatch(Actions.onChangeUsers(values))
      .then(() => this.props.dispatch(Actions.fetchCalendarEvents()));
  };

  onChangeTaskTypes = (values) => {
    this.props.dispatch(Actions.onChangeTaskTypes(values))
      .then(() => this.props.dispatch(Actions.fetchCalendarEvents()));
  };

  onChangeTaskStates = (values) => {
    this.props.dispatch(Actions.onChangeTaskStates(values))
      .then(() => this.props.dispatch(Actions.fetchCalendarEvents()));
  };

  onChangeCalendarTaskAssigneeType = (value) => {
    this.props.dispatch(Actions.onChangeCalendarTaskAssigneeType(value))
      .then(() => this.props.dispatch(Actions.fetchCalendarEvents()));
  };

  // gantt
  fetchGanttEvents = (params) => {
    this.props.dispatch(Actions.fetchGanttEvents(params));
  };

  // customer
  onChangeOrdersCustomer = (values) => {
    this.setState({orderCustomers: values}, () => {
      this.props.dispatch(Actions.fetchOrders(values))
    });
  };

  onChangeTicketsCustomer = (values) => {
    this.setState({ticketCustomers: values}, () => {
      this.props.dispatch(Actions.ticketsStatistic(values))
    });
  };

  // statistic
  fetchTasks = () => {
    this.props.dispatch(Actions.fetchTasks());
  };

  onChangeTaskFilterCreatedPeriod = (value, dateStrings) => {
    this.props.dispatch(Actions.changeDashboardTaskFilterCreatedPeriod(dateStrings[0], dateStrings[1]))
      .then(() => this.props.dispatch(Actions.fetchTasks()))
      .then(() => localStorage.setItem("dashboardTaskFilters", JSON.stringify(this.props.fetchTasksParams)));
  };

  onChangeTaskFilterDueByPeriod = (value, dateStrings) => {
    this.props.dispatch(Actions.changeDashboardTaskFilterDueByPeriod(dateStrings[0], dateStrings[1]))
      .then(() => this.props.dispatch(Actions.fetchTasks()))
      .then(() => localStorage.setItem("dashboardTaskFilters", JSON.stringify(this.props.fetchTasksParams)));
  };

  onChangeTaskFilterUsers = (values) => {
    let users = values.map((value) => {
      return {
        id: String(value.id),
        name: String(value.name)
      }
    });

    this.props.dispatch(Actions.changeDashboardTaskFilterUsers(users))
      .then(() => this.props.dispatch(Actions.fetchTasks()))
      .then(() => localStorage.setItem("dashboardTaskFilters", JSON.stringify(this.props.fetchTasksParams)));
  };

  fetchStatistic = (params) => {
    this.props.dispatch(Actions.fetchStatistic(params)).then(() => {
      this.props.dispatch(Actions.fetchOrders(params));
      this.props.dispatch(Actions.ticketsStatistic(params));
      this.props.dispatch(Actions.ticketsBarSeries(params));
      this.props.dispatch(Actions.fetchIntimeStatistic());
    });
  };

  onChangeTasksAssigneeType = (value) => {
    this.props.dispatch(Actions.onChangeTasksAssigneeType(value)).then(() => {
      this.props.dispatch(Actions.fetchTasks());
    });
  };

  onChangeStatisticDateRange = (dates) => {
    const {orderCustomers, ticketCustomers} = this.state;
    const startDate = moment(dates[0]).format("L");
    const endDate = moment(dates[1]).format("L");

    this.props
      .dispatch(Actions.onChangeStatisticDateRange(startDate, endDate))
      .then(() => {
        this.props.dispatch(Actions.fetchStatistic()).then(() => {
          this.props.dispatch(Actions.fetchOrders(orderCustomers));
          this.props.dispatch(Actions.ticketsStatistic(ticketCustomers));
          this.props.dispatch(Actions.ticketsBarSeries());
          this.props.dispatch(Actions.fetchIntimeStatistic());
        });
      });
  };

  onChangeStatisticDepartments = (values) => {
    const {orderCustomers, ticketCustomers} = this.state;
    this.props
      .dispatch(Actions.onChangeStatisticDepartments(values))
      .then(() => {
        this.props.dispatch(Actions.fetchStatistic()).then(() => {
          this.props.dispatch(Actions.fetchOrders(orderCustomers));
          this.props.dispatch(Actions.ticketsStatistic(ticketCustomers));
          this.props.dispatch(Actions.ticketsBarSeries());
          this.props.dispatch(Actions.fetchIntimeStatistic());
        });
      });
  };

  render() {
    const {orderCustomers, ticketCustomers} = this.state;
    const {
      history,
      currentUser,
      // calendar
      isCalendarLoading,
      calendarEvents,
      fetchCalendarEvents,
      // gantt
      isGanttLoading,
      ganttEvents,
      ganttTotals,
      isEventLoading,
      selectedEvent,
      fetchParams,
      // statistic
      fetchStatisticParams,
      isStatisticLoading,
      isTasksLoading,
      tasks,
      countOverdueTasks,
      tasksStatistic,
      taskSeries,
      fetchTasksParams,
      // projects
      projectSeries,
      projectsStatistic,
      // orders
      ordersStatistic,
      // tickets
      ticketSeries,
      isTicketsStatisticLoading,
      ticketsStatistic,
      isTicketsBarSeriesLoading,
      ticketsBarSeries,
      // entity_tasks
      entityTaskSeries,
      // intime_statistic
      isIntimeStatisticLoading,
      intimeStatistic,
      // services
      services,
    } = this.props;
    return (
      <Fragment>
        <Can
          role={currentUser.role}
          perform="dashboard:view"
          yes={() => (
            <Container
              history={history}
              currentUser={currentUser}
              onDrawerToggle={this.onDrawerToggle}
              // statistic
              fetchStatisticParams={fetchStatisticParams}
              // calendar
              onCalendarNavigate={this.onCalendarNavigate}
              isCalendarLoading={isCalendarLoading}
              calendarEvents={calendarEvents}
              fetchCalendarEvents={fetchCalendarEvents}
              // gantt
              isGanttLoading={isGanttLoading}
              ganttEvents={ganttEvents}
              ganttTotals={ganttTotals}
              fetchGanttEvents={this.fetchGanttEvents}
              // others
              onChangeEventType={this.onChangeEventType}
              onSelectEvent={this.onSelectEvent}
              isEventLoading={isEventLoading}
              selectedEvent={selectedEvent}
              onChangeUsers={this.onChangeUsers}
              onChangeTaskTypes={this.onChangeTaskTypes}
              onChangeTaskStates={this.onChangeTaskStates}
              onChangeCalendarTaskAssigneeType={this.onChangeCalendarTaskAssigneeType}
              fetchParams={fetchParams}
              // statistic
              fetchTasks={this.fetchTasks}
              onChangeTaskFilterCreatedPeriod={this.onChangeTaskFilterCreatedPeriod}
              onChangeTaskFilterDueByPeriod={this.onChangeTaskFilterDueByPeriod}
              onChangeTaskFilterUsers={this.onChangeTaskFilterUsers}
              fetchStatistic={this.fetchStatistic}
              isStatisticLoading={isStatisticLoading}
              isTasksLoading={isTasksLoading}
              tasks={tasks}
              countOverdueTasks={countOverdueTasks}
              onChangeTasksAssigneeType={this.onChangeTasksAssigneeType}
              tasksStatistic={tasksStatistic}
              taskSeries={taskSeries}
              fetchTasksParams={fetchTasksParams}
              onChangeStatisticDateRange={this.onChangeStatisticDateRange}
              onChangeStatisticPeriod={this.onChangeStatisticPeriod}
              onChangeStatisticDepartments={this.onChangeStatisticDepartments}
              // projects
              projectSeries={projectSeries}
              projectsStatistic={projectsStatistic}
              // orders
              ordersStatistic={ordersStatistic}
              orderCustomers={orderCustomers}
              onChangeOrdersCustomer={this.onChangeOrdersCustomer}
              // tickets
              ticketSeries={ticketSeries}
              isTicketsStatisticLoading={isTicketsStatisticLoading}
              ticketsStatistic={ticketsStatistic}
              isTicketsBarSeriesLoading={isTicketsBarSeriesLoading}
              ticketsBarSeries={ticketsBarSeries}
              ticketCustomers={ticketCustomers}
              onChangeTicketsCustomer={this.onChangeTicketsCustomer}
              // entity_tasks
              entityTaskSeries={entityTaskSeries}
              // intime_statistic
              isIntimeStatisticLoading={isIntimeStatisticLoading}
              intimeStatistic={intimeStatistic}
              // services
              services={services}
            />
          )}
          no={() => null}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  isDrawerVisible: state.dashboard.isDrawerVisible,
  isCalendarLoading: state.dashboard.isCalendarLoading,
  calendarEvents: state.dashboard.calendarEvents,
  // gantt
  isGanttLoading: state.dashboard.isGanttLoading,
  ganttEvents: state.dashboard.ganttEvents,
  ganttTotals: state.dashboard.ganttTotals,
  isEventLoading: state.dashboard.isEventLoading,
  selectedEvent: state.dashboard.selectedEvent,
  fetchParams: state.dashboard.fetchParams,
  // statistic
  fetchStatisticParams: state.dashboard.fetchStatisticParams,
  isStatisticLoading: state.dashboard.isStatisticLoading,
  isTasksLoading: state.dashboard.isTasksLoading,
  tasks: state.dashboard.tasks,
  countOverdueTasks: state.dashboard.countOverdueTasks,
  tasksStatistic: state.dashboard.tasksStatistic,
  taskSeries: state.dashboard.taskSeries,
  fetchTasksParams: state.dashboard.fetchTasksParams,
  // projects
  projectSeries: state.dashboard.projectSeries,
  projectsStatistic: state.dashboard.projectsStatistic,
  // orders
  ordersStatistic: state.dashboard.ordersStatistic,
  // tickets
  ticketSeries: state.dashboard.ticketSeries,
  isTicketsStatisticLoading: state.dashboard.isTicketsStatisticLoading,
  ticketsStatistic: state.dashboard.ticketsStatistic,
  isTicketsBarSeriesLoading: state.dashboard.isTicketsBarSeriesLoading,
  ticketsBarSeries: state.dashboard.ticketsBarSeries,
  // entity_tasks
  entityTaskSeries: state.dashboard.entityTaskSeries,
  // intime_statistic
  isIntimeStatisticLoading: state.dashboard.isIntimeStatisticLoading,
  intimeStatistic: state.dashboard.intimeStatistic,
  // services
  services: state.settings.services,
});

export default compose(
  withRouter,
  connect(mapStateToProps)
)(Dashboard);
