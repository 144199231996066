// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Card,
  Form,
  Select,
  Input,
  Descriptions,
  DatePicker,
  Checkbox,
} from "antd";
import { Link } from "react-router-dom";
import Assignees from "../../../components/Assignees";
import canCheck from "../../../rules/canCheck";
import User from "./User";
import Documents from "../../../components/Base64Documents";
import SelectFetch from "../../../components/SelectFetch";

import moment from "moment";
import "moment/locale/ru";

const FormItem = Form.Item;
const Option = Select.Option;
class Statuses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddingAssignee: false,
      assignee: null,
    };
  }

  onUpload = ({ onSuccess, onError, file }) => {
    this.props.onUploadProposal(file);
  };

  render() {
    const {
      isNew,
      currentUser,
      orderId,
      getFieldDecorator,
      user,
      member,
      customer,
      company,
      ticket,
      organisationAssignees,
      customerAssignees,
      companyAssignees,
      isAssigneesLoading,
      onCreateAssignee,
      onDeleteAssignee,
      // numbers
      number_int,
      number,
      priority,
      onChangeNumberInt,
      onChangeNumber,
      // supplier
      isOrganisation,
      isSupplier,
      onChangeIsOrganisation,
      onChangeIsSupplier,
      onChangePriority,
      // proposals
      isLoadingProposals,
      attachments,
      onUploadProposal,
      onDeleteProposal,
      // due_by
      dueBy,
      onChangeDueBy,
      // status
      status,
      onChangeStatus,
    } = this.props;

    let canEditDueBy = canCheck(currentUser, "orders:dueBy", {
      userId: currentUser.user_id,
      ownerId: user ? user.id : null,
    });

    return (
      <Card style={{ borderColor: "#1890ff" }}>
        {ticket ? (
          <Row gutter={10} style={{ paddingBottom: 10 }}>
            <Col span={24}>
              <Link to={`/tickets/${ticket.id}/edit`} target="_blank">
                {`Перенесено из заявки ${ticket.name}`}
              </Link>
            </Col>
          </Row>
        ) : null}
        <Row gutter={10}>
          <Col span={12}>
            <FormItem>
              {getFieldDecorator("isOrganisation", {
                onChange: onChangeIsOrganisation,
                valuePropName: "checked",
                initialValue: isOrganisation,
              })(<Checkbox>Собственный</Checkbox>)}
            </FormItem>
          </Col>

          <Col span={12}>
            <FormItem>
              {getFieldDecorator("isSupplier", {
                onChange: onChangeIsSupplier,
                valuePropName: "checked",
                initialValue: isSupplier,
              })(<Checkbox>Подряд</Checkbox>)}
            </FormItem>
          </Col>
        </Row>
        {canEditDueBy ? (
          <Row gutter={10}>
            <Col span={24}>
              <FormItem label={"Плановые дата и время"}>
                {getFieldDecorator("dueBy", {
                  onChange: onChangeDueBy,
                  initialValue: dueBy ? moment(dueBy) : null,
                  // rules: [
                  //   {
                  //     required: true,
                  //     message: "Введите дату и время!",
                  //   },
                  // ],
                })(
                  <DatePicker
                    format={"LLL"}
                    placeholder="Плановые дата и время"
                    showTime
                    className="ant-btn-width-100"
                  />
                )}
              </FormItem>
            </Col>
          </Row>
        ) : (
          <Row gutter={10} style={{ paddingBottom: 10 }}>
            <Col span={24}>
              <Descriptions bordered size="small">
                <Descriptions.Item label="Плановые дата и время">
                  {dueBy ? moment(dueBy).format("LLL") : "не задана"}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        )}

        <Row gutter={10}>
          <Col span={24}>
            <FormItem label={"Приоритет"}>
              {getFieldDecorator("priority", {
                onChange: onChangePriority,
                initialValue: priority
              })(
                <Select
                  optionFilterProp="children"
                  filterOption={false}
                  placeholder="Приоритет"
                >
                  <Option value={"low"}>Низкий</Option>
                  <Option value={"medium"}>Средний</Option>
                  <Option value={"high"}>Высокий</Option>
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>

        {isNew ? null : (
          <Row gutter={10}>
            <Col span={24}>
              <FormItem label={"Статус"}>
                {getFieldDecorator("status", {
                  initialValue: status ? status : null,
                  rules: [
                    {
                      required: true,
                      message: "Введите статус!",
                    },
                  ],
                })(
                  <SelectFetch
                    placeholder="Статус"
                    onChange={onChangeStatus}
                    selected={status ? status : null}
                    url={`/orders/${orderId}/statuses`}
                  />
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label={"Номер"}>
                {getFieldDecorator("number", {
                  onChange: onChangeNumber,
                  initialValue: number,
                })(<Input />)}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label={"Номер КП"}>
                {getFieldDecorator("number_int", {
                  onChange: onChangeNumberInt,
                  initialValue: number_int,
                })(<Input />)}
              </FormItem>
            </Col>
          </Row>
        )}
        <Row gutter={10}>
          <Col span={24}>
            <User user={user ? user : member} />
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={24}>
            <Assignees
              isReadOnly={
                isNew || !canCheck(currentUser, "orders:assignees")
                  ? true
                  : false
              }
              title={"Ответственные по заказу"}
              isAssigneesLoading={isAssigneesLoading}
              assigneeType="organisation"
              customer={customer}
              assignees={organisationAssignees}
              onCreateAssignee={onCreateAssignee}
              onDeleteAssignee={onDeleteAssignee}
            />
          </Col>
        </Row>
        {customer ? (
          <Row gutter={10}>
            <Col span={24}>
              <Assignees
                title={"От заказчика"}
                isReadOnly={
                  isNew || !canCheck(currentUser, "orders:assignees")
                    ? true
                    : false
                }
                isAssigneesLoading={isAssigneesLoading}
                assigneeType="customer"
                customer={customer}
                assignees={customerAssignees}
                onCreateAssignee={onCreateAssignee}
                onDeleteAssignee={onDeleteAssignee}
              />
            </Col>
          </Row>
        ) : null}
        {isSupplier ? (
          <Row gutter={10}>
            <Col span={24}>
              <Assignees
                title={"От подрядчика"}
                isReadOnly={
                  isNew || !canCheck(currentUser, "orders:assignees")
                    ? true
                    : false
                }
                isAssigneesLoading={isAssigneesLoading}
                assigneeType="company"
                company={company}
                assignees={companyAssignees}
                onCreateAssignee={onCreateAssignee}
                onDeleteAssignee={onDeleteAssignee}
              />
            </Col>
          </Row>
        ) : null}
        <Row gutter={10}>
          {isNew ? null : (
            <Documents
              title={"Коммерческое предложение"}
              currentUser={currentUser}
              attachmentType={null}
              isUploading={isLoadingProposals}
              attachments={attachments}
              onUpload={onUploadProposal}
              onDeleteAttachment={onDeleteProposal}
            />
          )}
        </Row>
      </Card>
    );
  }
}
Statuses.propTypes = {
  entity: PropTypes.object,
  getFieldDecorator: PropTypes.func,
};

export default Statuses;
