import update from "immutability-helper";

const initialState = {
  members: [],
  member: {},
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1
  },
  fetchParams: {
    is_active: true,
    customer_id: null,
    search: null,
    sort: null,
    direction: null,
    page: 1,
    limit: 15
  },
  errors: false,
  isLoading: true,
  isUploadingAvatar: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "FETCH_MEMBERS_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "FETCH_MEMBERS":
      return {
        ...state,
        members: action.response.data,
        meta: action.response.meta,
        isLoading: false
      };
    case "FETCH_MEMBER_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "FETCH_MEMBER":
      return {
        ...state,
        member: action.response.data,
        isLoading: false
      };
    case "PATCH_MEMBER_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false
      };
    case "PATCH_MEMBER":
      return {
        ...state,
        member: action.response.data,
        isLoading: false,
        errors: false
      };

    case "PATCH_MEMBER_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true
      };

    case "POST_MEMBER_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true
      };
    case "POST_MEMBER":
      return {
        ...state,
        errors: false,
        member: action.response.data,
        isLoading: false
      };

    case "POST_MEMBER_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false
      };

    case "DELETE_MEMBER_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false
      };
    case "DELETE_MEMBER":
      return {
        ...state,
        members: action.response.data,
        isLoading: false,
        errors: false
      };
    case "DELETE_MEMBER_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true
      };

    case "MEMBER_NEW":
      return {
        ...state,
        errors: false,
        member: {
          is_active: true,
          role: "manager"
        },
        isLoading: false
      };

    case "PATCH_PASSWORD_MEMBER_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true
      };
    case "PATCH_PASSWORD_MEMBER":
      return {
        ...state,
        errors: false,
        isLoading: false
      };
    case "PATCH_PASSWORD_MEMBER_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false
      };

    case "REQUEST_MEMBER_SEARCH":
      return update(state, {
        fetchParams: { search: { $set: action.value }, page: { $set: 1 } }
      });

    case "REQUEST_MEMBER_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } }
      });

    case "REQUEST_MEMBER_CHANGE_LIMIT":
      return update(state, {
        fetchParams: { limit: { $set: action.value }, page: { $set: 1 } }
      });

    case "REQUEST_MEMBERS_FILTER_ACTIVE":
      return update(state, {
        fetchParams: { is_active: { $set: action.value } }
      });

      case "REQUEST_MEMBERS_FILTER_CUSTOMER":
      return update(state, {
        fetchParams: { customer_id: { $set: action.value } }
      });

    case "MEMBERS_SORT_BY":
      return update(state, {
        fetchParams: {
          sort: { $set: action.field },
          direction: { $set: action.direction }
        }
      });

    case "MEMBER_CHANGE_ACTIVE":
      return update(state, {
        member: { is_active: { $set: action.value } }
      });

    case "MEMBER_CHANGE_ROLE":
      return update(state, {
        member: { role: { $set: action.value } }
      });

    case "MEMBER_CHANGE_NAME":
      return update(state, {
        member: { name: { $set: action.value } }
      });

    case "MEMBER_CHANGE_CUSTOMER":
      return update(state, {
        member: { customer: { $set: action.value } }
      });

    case "MEMBER_CHANGE_EMAIL":
      return update(state, {
        member: { email: { $set: action.value } }
      });

    case "MEMBER_CHANGE_TITLE":
      return update(state, {
        member: { title: { $set: action.value } }
      });

    case "MEMBER_CHANGE_PHONE":
      return update(state, {
        member: { phone: { $set: action.value } }
      });

    case "MEMBER_CHANGE_PASSWORD":
      return update(state, {
        member: { password: { $set: action.value } }
      });

    case "MEMBER_CHANGE_PASSWORD_CONFIRMATION":
      return update(state, {
        member: { password_confirmation: { $set: action.value } }
      });

    // avatar
    case "MEMBER_AVATAR_UPLOAD":
      return update(state, {
        isUploadingAvatar: { $set: true }
      });

    case "MEMBER_AVATAR_UPLOAD_SUCCEED":
      return update(state, {
        member: { avatar: { $set: action.response.data.avatar } },
        errors: { $set: false },
        isUploadingAvatar: { $set: false }
      });

    case "MEMBER_AVATAR_UPLOAD_FAILED":
      return update(state, {
        errors: { $set: true },
        isUploadingAvatar: { $set: false }
      });

    default:
      return state;
  }
}
