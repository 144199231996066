// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Input, InputNumber, Col, Row, DatePicker } from "antd";
import SelectFetch from "../../../components/SelectFetch";
import SelectMultiFetch from "../../../components/SelectMultiFetch";
import moment from "moment";
import "moment/locale/ru";

const FormItem = Form.Item;
const { TextArea } = Input;

class General extends Component {
  constructor(props, context) {
    super(props, context);
  }
  render() {
    const {
      entity,
      getFieldDecorator,
      onChangeStart,
      onChangeFinish,
      onChangeCustomer,
      onChangeName,
      onChangeEntityType,
      onChangePostalCode,
      onChangeCity,
      onChangeStreet,
      onChangeBuilding,
      onChangeExtCode,
      onChangeLat,
      onChangeLon,
      onChangeTodoTemplates,
    } = this.props;

    return (
      <div>
        <Row gutter={16}>
          <Col md={{ span: entity.is_organisation ? 24 : 16 }} xs={{ span: entity.is_organisation ? 24 : 16 }}>
            <FormItem label={"Наименование"}>
              {getFieldDecorator("name", {
                onChange: onChangeName,
                initialValue: entity.name,
                rules: [
                  {
                    required: true,
                    message: "Введите наименование!",
                  },
                ],
              })(<Input />)}
            </FormItem>
          </Col>
          {entity.is_organisation ? null : (
            <Col md={{ span: 8 }} xs={{ span: 8 }}>
              <FormItem label={"Клиент"}>
                {getFieldDecorator("customer", {
                  initialValue: entity.customer ? entity.customer : null,
                  rules: [
                    {
                      required: true,
                      message: "Пожалуйста, введите заказчика!",
                    },
                  ],
                })(
                  <SelectFetch
                    showSearch
                    placeholder="Клиент"
                    onChange={onChangeCustomer}
                    selected={entity.customer ? entity.customer : null}
                    url={"/customers/search/?"}
                  />
                )}
              </FormItem>
            </Col>
          )}
        </Row>
        <Row gutter={10}>
          <Col span={9}>
            <FormItem label={"Дата принятия"}>
              {getFieldDecorator("start", {
                onChange: onChangeStart,
                initialValue: moment(entity.start),
                rules: [
                  {
                    required: true,
                    message: "Введите дату!",
                  },
                ],
              })(
                <DatePicker
                  format={"LLL"}
                  showTime
                  className="ant-btn-width-100"
                />
              )}
            </FormItem>
          </Col>
          <Col span={9}>
            <FormItem label={"Дата окончания"}>
              {getFieldDecorator("finish", {
                onChange: onChangeFinish,
                initialValue: moment(entity.finish),
                rules: [
                  {
                    required: true,
                    message: "Введите дату!",
                  },
                ],
              })(
                <DatePicker
                  format={"LLL"}
                  showTime
                  className="ant-btn-width-100"
                />
              )}
            </FormItem>
          </Col>
          <Col md={{ span: 6 }} xs={{ span: 6 }}>
            <FormItem label={"Тип"}>
              {getFieldDecorator("entity_type", {
                initialValue: entity.entity_type ? entity.entity_type : null,
                rules: [
                  {
                    required: true,
                    message: "Введите тип!",
                  },
                ],
              })(
                <SelectFetch
                  showSearch
                  placeholder="Тип"
                  onChange={onChangeEntityType}
                  selected={entity.entity_type ? entity.entity_type : null}
                  url={"/entity_types/search/?"}
                />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col md={{ span: 6 }} xs={{ span: 6 }}>
            <FormItem label={"Индекс"}>
              {getFieldDecorator("postal_code", {
                onChange: onChangePostalCode,
                initialValue: entity.postal_code,
              })(<Input />)}
            </FormItem>
          </Col>
          <Col md={{ span: 18 }} xs={{ span: 18 }}>
            <FormItem label={"Город"}>
              {getFieldDecorator("city", {
                initialValue: entity.city ? entity.city : null,
                rules: [
                  {
                    required: true,
                    message: "Пожалуйста, город!",
                  },
                ],
              })(
                <SelectFetch
                  showSearch
                  showSubtitle
                  placeholder="Город"
                  onChange={onChangeCity}
                  selected={entity.city ? entity.city : null}
                  url={"/cities/search/?"}
                />
              )}
            </FormItem>
          </Col>
          <Col md={{ span: 12 }} xs={{ span: 12 }}>
            <FormItem label={"Улица"}>
              {getFieldDecorator("street", {
                onChange: onChangeStreet,
                initialValue: entity.street,
                rules: [
                  {
                    required: true,
                    message: "Введите улицу!",
                  },
                ],
              })(<Input />)}
            </FormItem>
          </Col>
          <Col md={{ span: 12 }} xs={{ span: 12 }}>
            <FormItem label={"Дом"}>
              {getFieldDecorator("building", {
                onChange: onChangeBuilding,
                initialValue: entity.building,
                rules: [
                  {
                    required: true,
                    message: "Введите дом!",
                  },
                ],
              })(<Input />)}
            </FormItem>
          </Col>
          <Col md={{ span: 6 }} xs={{ span: 6 }}>
            <FormItem label={"Широта"}>
              {getFieldDecorator("lat", {
                onChange: onChangeLat,
                initialValue: entity.lat,
              })(<InputNumber style={{ width: "100%" }} />)}
            </FormItem>
          </Col>
          <Col md={{ span: 6 }} xs={{ span: 6 }}>
            <FormItem label={"Долгота"}>
              {getFieldDecorator("lon", {
                onChange: onChangeLon,
                initialValue: entity.lon,
              })(<InputNumber style={{ width: "100%" }} />)}
            </FormItem>
          </Col>
          {/* <Col md={{ span: 12 }} xs={{ span: 12 }}>
            <FormItem label={"Внешний код"}>
              {getFieldDecorator("ext_code", {
                onChange: onChangeExtCode,
                initialValue: entity.ext_code,
              })(<Input />)}
            </FormItem>
          </Col> */}
          <Col md={{ span: 24 }} xs={{ span: 24 }}>
            <FormItem label={"Шаблоны чек-листов"}>
              {getFieldDecorator("todo_templates", {
                initialValue: entity.todo_templates
                  ? entity.todo_templates
                  : [],
              })(
                <SelectMultiFetch
                  showSearch
                  placeholder="Шаблоны чек-листов"
                  onChange={onChangeTodoTemplates}
                  selected={entity.todo_templates ? entity.todo_templates : []}
                  url={"/todo_templates/search/?todo_type=1"}
                />
              )}
            </FormItem>
          </Col>
        </Row>
      </div>
    );
  }
}

General.propTypes = {
  entity: PropTypes.object,
  onChangeDate: PropTypes.func,
  onChangeAssignee: PropTypes.func,
  onChangeAgreement: PropTypes.func,
  onChangeAgreementDate: PropTypes.func,
  onChangeAdditional: PropTypes.func,
  onChangeComment: PropTypes.func,
  onChangeNotice: PropTypes.func,
};

export default General;
