import React from "react";
import { Button, Col, Form, Input, Modal, Row } from "antd";

const FormItem = Form.Item;
const {TextArea} = Input;


class CommentForm extends React.Component {

  static defaultProps = {
    placeholder: 'Введите комментарий'
  };

  onSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((error, values) => {
      if (error) {
        return;
      }

      this.props.onSave();
    });
  };

  render() {
    const {getFieldDecorator} = this.props.form;
    const {
      visible,
      commentFailureReason,
      commentFixDescription,
      onChangeCommentFailureReason,
      onChangeCommentFixDescription,
      onCancel,
    } = this.props;

    return (
      <Modal
        destroyOnClose
        title="Комментарий"
        visible={visible}
        onOk={this.onSubmit}
        onCancel={onCancel}
        footer={[
          <Button key="back" onClick={onCancel}>
            Отменить
          </Button>,
          <Button
            key="submit"
            disabled={
              !commentFailureReason || commentFailureReason?.length < 3 ||
              !commentFixDescription || commentFixDescription?.length < 3
            }
            type="primary"
            onClick={this.onSubmit}
          >
            ОК
          </Button>,
        ]}
      >
        <Form layout={"vertical"}>
          <Row type="flex" justify="center" align="middle">
            <Col span={24}>
              <FormItem label={'Причина возникновения неисправности?'}>
                {
                  getFieldDecorator('commentFailureReason', {
                    onChange: onChangeCommentFailureReason,
                    initialValue: commentFailureReason,
                    rules: [
                      {
                        required: true,
                        message: "Введите причину",
                      },
                    ],
                  })(
                    <TextArea
                      autoSize={{minRows: 4, maxRows: 8}}/>
                  )
                }
              </FormItem>
            </Col>
          </Row>
          <Row type="flex" justify="center" align="middle">
            <Col span={24}>
              <FormItem label={'Что выполнено для устранения неисправности?'}>
                {
                  getFieldDecorator('commentFixDescription', {
                    onChange: onChangeCommentFixDescription,
                    initialValue: commentFixDescription,
                    rules: [
                      {
                        required: true,
                        message: "Введите описание",
                      },
                    ],
                  })(
                    <TextArea
                      autoSize={{minRows: 6, maxRows: 8}}/>
                  )
                }
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(CommentForm);
