// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import {Table, Button, PageHeader, Typography, Tooltip, Icon, Badge} from "antd";
import { Pagination } from "antd";
import { Row, Col, Input } from "antd";

import PropTypes from "prop-types";
import AssigneesCell from "../../../components/AssigneesCell";
import accounting from "accounting";
import ProjectProgress from "../../../components/ProjectProgress";
import Can from "../../../rules/Can";
import canCheck from "../../../rules/canCheck";

import NoticeIcon from "../../../components/NoticeIcon";

import moment from "moment";
import "moment/locale/ru";
import Filter from "./Filter";
import Tasks from "../Tasks";

const Search = Input.Search;
const { Paragraph } = Typography;

class ProjectsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      expandedRowKeys: [],
      selectedTotalAmount: 0,
      isFilterVisible: false,
    };
  }

  onExpand = (expanded, record) => {
    if (expanded) {
      this.setState({ expandedRowKeys: [record.id] });
    }
  };

  renderExpandedRow = (record) => {
    if (record.id) {
      return <Tasks taskOwner={1} ownerId={record.id} />;
    }
  };

  onFilterToggle = () => {
    this.setState({ isFilterVisible: !this.state.isFilterVisible });
  };

  onSelectChange = (selectedRowKeys, records) => {
    const selectedTotalAmount = records.reduce(
      (sum, record) => sum + Number(record.amount),
      0
    );
    // console.log(selectedRowKeys, e)
    this.setState({ selectedRowKeys, selectedTotalAmount });
  };

  onDelete = () => {
    this.props.onDelete(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  onChangeTable = (pagination, filters, sorter) => {
    let direction = "desc";
    if (sorter.order === "descend") {
      direction = "desc";
    } else if (sorter.order === "ascend") {
      direction = "asc";
    }
    this.props.onSortBy(sorter.field, direction);
  };

  onItemClick = (item) => {
    this.props.history.push(`/projects/${item.parent_id}/edit`);
  };

  onClearNotices = () => {
    this.props.onClearNotices();
  };


  render() {
    const {
      selectedRowKeys,
      selectedTotalAmount,
      isFilterVisible,
    } = this.state;
    const {
      currentUser,
      loading,
      projects,
      meta,
      fetchParams,
      notices,
      onSearch,
      onChangePage,
      onChangeLimit,
      onSortBy,
      // filters
      onClearFilters,
      onChangeFilterStatus,
      onChangeFilterPeriod,
      onChangeFilterPeriodFinish,
      onChangeFilterUsers,
      onChangeFilterMembers,
      onChangeFilterCustomer,
      downloadReport,
    } = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: "Договор / Статус",
        dataIndex: "number",
        render: (number, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <Link to={`/projects/${record.id}/edit`}>{number}</Link>
            </span>
            <span style={{ fontSize: 12 }}>
            <ProjectProgress
                title={record.status_locale}
                projectId={record.id}
              />
            </span>
          </div>
        ),
        key: "number",
        align: "center",
        width: "10%",
      },
      {
        title: "Дата создания",
        dataIndex: "created_at",
        render: (created_at) => moment(created_at).format("L"),
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "created_at",
        align: "center",
        width: "10%",
      },
      {
        title: "Дата принятия",
        dataIndex: "finish",
        render: (finish, record) => finish ? moment(finish).format("L") : null,
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "finish",
        align: "center",
        width: "10%",
      },
      {
        title: "Сумма",
        dataIndex: "amount",
        render: (amount) => accounting.formatNumber(amount, 0, " "),
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "amount",
        align: "center",
        width: "10%",
      },
      {
        title: "Затраты (ф/п)",
        dataIndex: "cost",
        render: (cost, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{accounting.formatNumber(cost, 0, " ")}</span>
            <span>{accounting.formatNumber(record.cost_plan, 0, " ")}</span>
          </div>
        ),
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "cost",
        align: "center",
        width: "10%",
      },
      {
        title: "Клиент / Объект",
        dataIndex: "customer",
        render: (customer, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <Link to={`/customers/${customer.id}/edit`}>{customer.name}</Link>
            </span>
            <span style={{ opacity: "0.7" }}>
              {record.entity ? record.entity.name : null}
            </span>
            <span style={{ opacity: "0.7" }}>
              {record.entity ? record.entity.address : null}
            </span>
          </div>
        ),
        align: "center",
        width: "15%",
      },
      {
        title: "Тип / Описание",
        dataIndex: "project_type",
        render: (project_type, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{project_type ? project_type.name : null}</span>
            <span style={{ opacity: "0.7" }}>
              {" "}
              <Paragraph ellipsis={{ rows: 3, expandable: true }}>
                {record.description}
              </Paragraph>
            </span>
          </div>
        ),
        key: "project_type",
        align: "center",
        width: "15%",
      },
      {
        title: "Ответственный",
        dataIndex: "assignees",
        render: (assignees, record) => <AssigneesCell assignees={assignees} />,
        align: "center",
        width: "15%",
      },
      {
        title: "",
        dataIndex: "unread_comment_count",
        render: (unread_comment_count, record) => (
          <Badge count={unread_comment_count}
                 title={'Непрочитанные комментарии'}
                 style={{boxShadow: "none"}}/>
        ),
        align: "center",
        width: "5%",
      },
    ];

    const faqButton = (
      <Button
        type={"ghost"}
        key="7"
        icon="question"
        onClick={this.props.onFaqToggle}
      />
    );

    let mainButton = hasSelected ? (
      <Button key="2" type="danger" ghost onClick={this.onDelete}>
        Удалить
      </Button>
    ) : (
      <Link
        key="3"
        className={"ant-btn ant-btn-primary ant-btn-background-ghost"}
        to={{ pathname: `/projects/new` }}
      >
        Создать
      </Link>
    );

    let excelButton = (
      <Can
        role={currentUser.role}
        perform="orders:finance"
        yes={() => (
          <Tooltip placement="bottom" title={"Выгрузить в Excel"}>
          <Button key="excelButton" type="dashed" onClick={downloadReport}>
            <Icon type="file-excel" style={{ color: "green" }} />
            Выгрузить
          </Button>
          </Tooltip>
        )}
        no={() => null}
      />
    );

    let filterButton = (
      <Button
        type={
          fetchParams.startDate ||
          fetchParams.starFinish ||
          fetchParams.status ||
          fetchParams.users ||
          fetchParams.members
            ? "primary"
            : "ghost"
        }
        key="4"
        icon="filter"
        onClick={this.onFilterToggle}
      />
    );

    const unread = notices.filter(function (item) {
      return item.read == false;
    });

    const noticeIcon = (
      <NoticeIcon
        key="5"
        count={unread.length}
        onItemClick={this.onItemClick}
        list={notices}
        showClear
        onClear={this.onClearNotices}
        emptyText={"новых комментариев нет"}
      />
    );

    const totalSelected = hasSelected
      ? `, Всего (выбрано): ${
          selectedRowKeys.length
        }, Сумма (выбрано): ${accounting.formatNumber(
          selectedTotalAmount,
          0,
          " "
        )}`
      : "";
    const totalInfo = `Всего: ${
      meta.total_count
    }, Сумма: ${accounting.formatNumber(
      meta.total_amount,
      0,
      " "
    )} ${totalSelected}`;

    let canDelete = canCheck(currentUser, "projects:delete");

    return (
      <PageHeader
        className="page-header-with-notices"
        onBack={() => window.history.back()}
        title={`Проекты`}
        subTitle={
          fetchParams.startDate && fetchParams.endDate
            ? `c ${fetchParams.startDate} по ${fetchParams.endDate}`
            : null
        }
        extra={[
          faqButton,
          noticeIcon,
          <Search
            allowClear
            key="1"
            placeholder="поиск..."
            value={fetchParams.search}
            onChange={onSearch}
            style={{ width: 250 }}
          />,
          mainButton,
          excelButton,
          filterButton,
        ]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Table
                // bprojected
                rowSelection={canDelete ? rowSelection : null}
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={projects}
                onChange={this.onChangeTable}
                rowKey="id"
                onExpand={this.onExpand}
                expandedRowRender={(record, index, indent, expaned) =>
                  expaned ? this.renderExpandedRow(record) : null
                }
              />
            </Col>
            <Col
              span={24}
              style={{
                paddingTop: "15px",
                paddingBottom: "15px",
                textAlign: "right",
              }}
            >
              <Pagination
                showSizeChanger
                pageSize={fetchParams.limit}
                current={meta.current_page}
                onChange={onChangePage}
                onShowSizeChange={onChangeLimit}
                showTotal={(total) => totalInfo}
                total={meta.total_count}
              />
            </Col>
          </Row>
        }
      >
        <Filter
          currentUser={currentUser}
          visible={isFilterVisible}
          status={fetchParams.status}
          startDate={fetchParams.startDate}
          endDate={fetchParams.endDate}
          starFinish={fetchParams.starFinish}
          endFinish={fetchParams.endFinish}
          users={fetchParams.users}
          members={fetchParams.members}
          customer={fetchParams.customer}
          onClose={this.onFilterToggle}
          onClearFilters={onClearFilters}
          onChangeStatus={onChangeFilterStatus}
          onChangePeriod={onChangeFilterPeriod}
          onChangePeriodFinish={onChangeFilterPeriodFinish}
          onChangeUsers={onChangeFilterUsers}
          onChangeMembers={onChangeFilterMembers}
          onChangeCustomer={onChangeFilterCustomer}
          downloadReport={downloadReport}
        />
      </PageHeader>
    );
  }
}

ProjectsList.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default withRouter(ProjectsList);
