// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Col, Row, Select, Form, Drawer } from "antd";

import SelectMultiFetch from "../../../components/SelectMultiFetch";


const { Option, OptGroup } = Select;
const FormItem = Form.Item;

class Filter extends Component {
  
  render() {
    const {
      visible,
      isAvailable,
      customers,
      onClose,
      onChangeIsAvailable,
      onChangeCustomers,
    } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Drawer
        width={540}
        visible={visible}
        title="Фильтры"
        placement={"right"}
        closable={false}
        onClose={onClose}
      >
        <Row gutter={{ md: 8, lg: 24, xl: 24 }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Клиент"}>
              {getFieldDecorator("user", {
                initialValue: customers ? customers : [],
              })(
                <SelectMultiFetch
                  showSearch
                  placeholder="Клиент"
                  onChange={onChangeCustomers}
                  selected={customers ? customers : []}
                  url={"/customers/search/?'"}
                />
              )}
            </FormItem>
          </Col>

          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormItem label={"Доступность"}>
              {getFieldDecorator("isAvailable", {
                onChange: onChangeIsAvailable,
                initialValue: isAvailable,
              })(
                <Select
                  style={{ width: "100%" }}
                  placeholder="Доступность"
                  optionFilterProp="children"
                  filterOption={false}
                >
                  <Option value={null}>все</Option>
                  <Option value={true}>доступные</Option>
                  <Option value={false}>скрытые</Option>
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>
      </Drawer>
    );
  }
}

Filter.propTypes = {
  entity: PropTypes.object,
};

export default Form.create()(Filter);
