// @flow
import React, {Component} from "react";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {compose} from "redux";
import Actions from "../../../../../actions/articles";
import {PageHeader, Button, Spin} from "antd";
import Form from "./Form";

class Article extends Component {
  constructor(props) {
    super(props);

    this.state = {
      creating: false,
    };
  }

  componentWillMount = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.fetchArticle(this.props.match.params.sectionId, this.props.match.params.id));
    } else {
      this.setState({creating: true}, () => {
        this.props.dispatch(Actions.newArticle());
      });
    }
  };

  onSave = () => {
    if (this.props.match.params.sectionId && this.props.match.params.id) {
      this.props.dispatch(Actions.updateArticle(this.props.match.params.sectionId, this.props.match.params.id));
    } else if (this.props.match.params.sectionId) {
      this.props.dispatch(Actions.createArticle(this.props.match.params.sectionId)).then(() => {
        if (!this.props.errors) {
          this.props.history.push(`/sections/${this.props.section.id}/articles/${this.props.article.id}/edit`);
        }
      });
    }
  };

  onClose = () => {
    this.props.history.push(`/sections/${this.props.match.params.sectionId}`);
  };

  // general

  onChangeTitle = (e) => {
    this.props.dispatch(Actions.changeArticleTitle(e.target.value));
  };

  onChangeBody = (content) => {
    this.props.dispatch(Actions.changeArticleBody(content));
  };

  onChangeTags = (values) => {
    this.props.dispatch(Actions.changeArticleTags(values));
  };

  // sidebar

  onChangePublished = (value) => {
    this.props.dispatch(Actions.changeArticlePublished(value));
  };

  onChangePermissionForAdministrator = (value) => {
    this.props.dispatch(Actions.onChangeArticlePermissionForAdministrator(value.target.checked));
  };

  onChangePermissionForManager = (value) => {
    this.props.dispatch(Actions.onChangeArticlePermissionForManager(value.target.checked));
  };

  onChangePermissionForEngineer = (value) => {
    this.props.dispatch(Actions.onChangeArticlePermissionForEngineer(value.target.checked));
  };

  onChangePermissionForSupervisor = (value) => {
    this.props.dispatch(Actions.onChangeArticlePermissionForSupervisor(value.target.checked));
  };

  onChangePermissionForSimple = (value) => {
    this.props.dispatch(Actions.onChangeArticlePermissionForSimple(value.target.checked));
  };

  render() {
    const {creating} = this.state;
    const {
      isLoading,
      article,
      permissions
    } = this.props;

    return isLoading ? (
      <Spin/>
    ) : (
      <PageHeader
        onBack={() => window.history.back()}
        title={creating ? `Новая статья` : article.title}
      >
        <Form
          article={article}
          permissions={permissions}
          onSave={this.onSave}
          onClose={this.onClose}
          onChangeTitle={this.onChangeTitle}
          onChangeBody={this.onChangeBody}
          onChangeTags={this.onChangeTags}
          // sidebar
          onChangePublished={this.onChangePublished}
          onChangePermissionForAdministrator={this.onChangePermissionForAdministrator}
          onChangePermissionForManager={this.onChangePermissionForManager}
          onChangePermissionForEngineer={this.onChangePermissionForEngineer}
          onChangePermissionForSupervisor={this.onChangePermissionForSupervisor}
          onChangePermissionForSimple={this.onChangePermissionForSimple}
        />
      </PageHeader>
    );
  }
}

Article.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  section: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isLoading: state.articles.isLoading,
  errors: state.articles.errors,
  section: state.sections.section,
  article: state.articles.article,
  permissions: state.articles.permissions,
});

export default compose(
  withRouter,
  connect(mapStateToProps)
)(Article);
