import api from "../api";

const Actions = {};

Actions.fetchSmsNotifications = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_SMS_REQUEST" });
    const params = getState().notices.fetchParams;

    return api
      .fetch("/smsnotifications", params)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_SMS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_SMS_FAILURE", response });
      });
  };
};

Actions.onChangePage = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_SMS_CHANGE_PAGE",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeLimit = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_SMS_CHANGE_LIMIT",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onSortBy = (field, direction) => {
  return (dispatch) => {
    dispatch({
      type: "SMS_SORT_BY",
      field: field,
      direction: direction,
    });
    return Promise.resolve();
  };
};

Actions.onFetchStatus = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_SMS_STATUS_REQUEST" });

    return api
      .fetch(`/smsnotifications/${id}/status`)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_SMS_STATUS", response, id });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_SMS_STATUS_FAILURE", response });
      });
  };
};

export default Actions;
