// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  InputNumber,
  Dropdown,
  Menu,
  Button,
  Icon,
  Tooltip,
} from "antd";
import "moment/locale/ru";
import accounting from "accounting";

const FormItem = Form.Item;

function schemaValueType(type) {
  switch (type) {
    case "1":
      return "по заявкам";
    case "2":
      return "по регламенту";
    case "3":
      return "по заказам";
    case "4":
      return "удовлетворенности клиента";
    case "5":
      return "вовлеченности";
    default:
      return "";
  }
}

class PayrollSchemaValues extends Component {
  onDelete = (id) => {
    this.props.onDeleteSchemaValue(id);
  };

  handleMenuClick = ({ key }) => {
    const data = {
      id: Date.now(),
      is_auto: true,
      name: schemaValueType(key),
      schema_value_type: key,
      weight: 0,
      value: 0,
      execution: 0,
      is_exist: false,
      _destroy: false,
    };
    this.props.onAddPayrollSchemaValue(data);
  };

  render() {
    const {
      getFieldDecorator,
      payrollSchemaValues,
      onChangeSchemaValueName,
      onChangeSchemaValueWeight,
      onChangeSchemaValue,
      onChangeSchemaValueFrom,
      onChangeSchemaValueTo,
    } = this.props;

    const menu = (
      <Menu onClick={this.handleMenuClick}>
        <Menu.Item key="1">по заявкам</Menu.Item>
        <Menu.Item key="2">по регламенту</Menu.Item>
        <Menu.Item key="3">по заказам</Menu.Item>
        <Menu.Item key="4">удовлетворенности клиента</Menu.Item>
        <Menu.Item key="5">вовлеченности</Menu.Item>
      </Menu>
    );

    const menuButton = (
      <Dropdown overlay={menu}>
        <Button>
          добавить <Icon type="down" />
        </Button>
      </Dropdown>
    );

    return (
      <Row gutter={{ md: 8, lg: 24, xl: 48 }} style={{ paddingBottom: 15 }}>
        <Col span={18}>
          <Card size="small" title="Показатели" extra={menuButton}>
            {payrollSchemaValues.map((payrollSchemaValue) => {
              return (
                // console.log(payrollSchemaValue.id)
                <Row
                  key={payrollSchemaValue.id}
                  gutter={16}
                  style={{ paddingBottom: 15 }}
                >
                  <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                    <FormItem label={"Наименование"}>
                      <Input
                        disabled
                        value={payrollSchemaValue.name}
                        onChange={onChangeSchemaValueName.bind(
                          this,
                          payrollSchemaValue.id
                        )}
                      />
                    </FormItem>
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={4} xl={4}>
                    <FormItem label={"Вес"}>
                      <InputNumber
                        min={0}
                        style={{ width: "100%" }}
                        value={payrollSchemaValue.weight}
                        onChange={onChangeSchemaValueWeight.bind(
                          this,
                          payrollSchemaValue.id
                        )}
                      />
                    </FormItem>
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={4} xl={4}>
                    <FormItem label={"Сумма бонуса"}>
                      <InputNumber
                        min={0}
                        style={{ width: "100%" }}
                        value={payrollSchemaValue.value}
                        onChange={onChangeSchemaValue.bind(
                          this,
                          payrollSchemaValue.id
                        )}
                      />
                    </FormItem>
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={3} xl={3}>
                    <FormItem label={"%, от"}>
                      <InputNumber
                        min={0}
                        style={{ width: "100%" }}
                        value={payrollSchemaValue.from}
                        onChange={onChangeSchemaValueFrom.bind(
                          this,
                          payrollSchemaValue.id
                        )}
                      />
                    </FormItem>
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={3} xl={3}>
                    <FormItem label={"%, до"}>
                      <InputNumber
                        min={0}
                        style={{ width: "100%" }}
                        value={payrollSchemaValue.to}
                        onChange={onChangeSchemaValueTo.bind(
                          this,
                          payrollSchemaValue.id
                        )}
                      />
                    </FormItem>
                  </Col>
                  <Col
                    xs={6}
                    sm={6}
                    md={6}
                    lg={2}
                    xl={2}
                    style={{ textAlign: "right" }}
                  >
                    <Tooltip placement="bottom" title={"Удалить показатель"}>
                      <Button
                        type="primary"
                        icon="close"
                        style={{ marginTop: 29 }}
                        onClick={this.onDelete.bind(
                          this,
                          payrollSchemaValue.id
                        )}
                      />
                    </Tooltip>
                  </Col>
                </Row>
              );
            })}
          </Card>
        </Col>
      </Row>
    );
  }
}
PayrollSchemaValues.propTypes = {
  getFieldDecorator: PropTypes.func,
};

export default PayrollSchemaValues;
