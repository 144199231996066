import api from "../api";
import { saveAs } from "file-saver";

const Actions = {};

Actions.fetchTasks = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_TASKS_REQUEST" });
    let owners = [];
    let users = [];
    let supervisors = [];
    let templates = [];
    let params = getState().tasks.fetchParams;

    if (params.owners) {
      owners = params.owners.map((user) => {
        if (user && user.id) {
          return user.id;
        }
      });
    }

    if (params.users) {
      users = params.users.map((user) => {
        if (user && user.id) {
          return user.id;
        }
      });
    }
    if (params.supervisors) {
      supervisors = params.supervisors.map((user) => {
        if (user && user.id) {
          return user.id;
        }
      });
    }

    if (params.templates) {
      templates = params.templates.map((template) => {
        if (template && template.id) {
          return template.id;
        }
      });
    }

    const data = {
      startDate: params.startDate,
      endDate: params.endDate,
      startCreatedDate: params.startCreatedDate,
      endCreatedDate: params.endCreatedDate,
      search: params.search,
      sort: params.sort,
      direction: params.direction,
      page: params.page,
      limit: params.limit,
      status: params.status,
      owners: owners,
      users: users,
      supervisors: supervisors,
      templates: templates,
    };

    return api
      .fetch("/tasks", data)
      .then((response) => {
        dispatch({ type: "FETCH_TASKS", response });
      })
      .catch((error) => {
        api.handleError(error, "Задачи", true);
        dispatch({ type: "FETCH_TASKS_FAILURE" });
      });
  };
};

Actions.fetchTaskActions = (data = null) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_TASK_ACTIONS_REQUEST" });
    const taskOwners = data.taskOwners.map((owner) => {
      return owner;
    });
    const params = { taskOwners: taskOwners };
    api
      .fetch(`/tasks/task_actions`, params)
      .then((response) => {
        dispatch({ type: "FETCH_TASK_ACTIONS", response });
      })
      .catch((error) => {
        api.handleError(error, "Шаблоны задач");
        dispatch({ type: "FETCH_TASK_ACTIONS_FAILURE" });
      });
  };
};

Actions.fetchTask = (id = null) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_TASK_REQUEST" });
    api
      .fetch(`/tasks/${id}`)
      .then((response) => {
        dispatch({ type: "FETCH_TASK", response });
      })
      .catch((error) => {
        api.handleError(error, "Задача");
        dispatch({ type: "FETCH_TASK_FAILURE" });
      });
  };
};

Actions.fetchNew = (taskTemplateId, ownerId = null) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_TASK_NEW_REQUEST" });
    const params = { task_template: taskTemplateId, owner_id: ownerId };
    return api
      .fetch(`/tasks/newtask`, params)
      .then((response) => {
        dispatch({ type: "FETCH_TASK_NEW", response });
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.errors) {
            error.response.data.errors.map((error) => {
              return api.openNotification("error", "Задача", error);
            });
          } else {
            return api.openNotification(
              "error",
              "Ошибка",
              "Обратитесь к администратору!"
            );
          }
        }
        dispatch({ type: "FETCH_TASK_NEW_FAILURE", response: error.response });
      });
  };
};

Actions.onNew = () => {
  return (dispatch, getState) => {
    const currentUser = getState().session.currentUser;
    dispatch({
      type: "TASK_NEW",
      user: currentUser,
    });
  };
};

Actions.onUpdate = (id) => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_TASK_REQUEST" });
    const task = getState().tasks.task;
    const fields = task.fields.map((field) => {
      return {
        id: field.field_set_value_id,
        field_set_id: field.field_set_id,
        field_set_type: field.field_set_type,
        value: field.value,
      };
    });
    const data = {
      task: {
        task_template_id: task.task_template_id,
        customer_id: task.customer ? task.customer.id : null,
        company_id: task.company ? task.company.id : null,
        status: task.status,
        due_by: task.due_by,
        owner_id: task.owner_id,
        owner_name: task.owner_name,
        // owner_url: task.owner_url,
        subject: task.subject,
        description: task.description,
        amount: task.amount,
        field_set_values_attributes: fields,
      },
    };

    return api
      .patch(`/tasks/${id}`, data)
      .then((response) => {
        dispatch({ type: "PATCH_TASK", response });
        api.openNotification("success", "Задача", "Успешно обновлена!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Задача", error);
          });
        }
        dispatch({ type: "PATCH_TASK_FAILURE", response });
      });
  };
};

Actions.onCreate = () => {
  return (dispatch, getState) => {
    dispatch({ type: "TASK_REQUEST" });
    const task = getState().tasks.task;

    const assignees = task.assignees.map((assignee) => {
      return {
        assignee_type: assignee.assignee_type,
        user_id:
          assignee.assignee_type == "organisation" ||
          assignee.assignee_type == "supervisor"
            ? assignee.user.id
            : null,
        member_id:
          assignee.assignee_type == "organisation" ||
          assignee.assignee_type == "supervisor"
            ? null
            : assignee.member.id,
      };
    });

    const fields = task.fields.map((field) => {
      return {
        field_set_id: field.field_set_id,
        field_set_type: field.field_set_type,
        value: field.value,
      };
    });

    const data = {
      task: {
        task_template_id: task.task_template_id,
        customer_id: task.customer ? task.customer.id : null,
        company_id: task.company ? task.company.id : null,
        status: task.status,
        due_by: task.due_by,
        owner_id: task.owner_id,
        owner_name: task.owner_name,
        subject: task.subject,
        description: task.description,
        task_assignees_attributes: assignees,
        amount: task.amount,
        field_set_values_attributes: fields,
      },
    };
    return api
      .post(`/tasks`, data)
      .then((response) => {
        dispatch({ type: "TASK_SUCCEED", response });
        api.openNotification("success", "Задача", "Успешно создана!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Задача", error);
          });
        }
        dispatch({ type: "TASK_FAILURE", response });
      });
  };
};

Actions.onDelete = (id) => {
  return (dispatch) => {
    dispatch({ type: "DELETE_TASK_REQUEST" });
    return api
      .delete(`/tasks/${id}`)
      .then((response) => {
        api.openNotification("success", "Задача", "Успешно удалено!");
        dispatch({ type: "DELETE_TASK", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Задача", error);
          });
        }
        dispatch({
          type: "DELETE_TASK_FAILURE",
        });
      });
  };
};

// assignees
Actions.fetchAssignees = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ASSIGNEES_REQUEST" });
    api
      .fetch(`/tasks/${id}/task_assignees`)
      .then((response) => {
        dispatch({ type: "FETCH_ASSIGNEES", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_ASSIGNEES_FAILURE", response });
      });
  };
};

Actions.onCreateAssignee = (id, assignee, type) => {
  return (dispatch) => {
    dispatch({ type: "POST_ASSIGNEE_REQUEST" });
    return api
      .post(`/tasks/${id}/task_assignees`, {
        task_assignee: {
          assignee_type: type,
          user_id:
            type == "organisation" || type == "supervisor" ? assignee.id : null,
          member_id:
            type == "organisation" || type == "supervisor" ? null : assignee.id,
        },
      })
      .then((response) => {
        dispatch({ type: "POST_ASSIGNEE", response });
        api.openNotification("success", "Ответственный", "Успешно добавлен!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Ответственный", error);
          });
        }
        dispatch({ type: "POST_ASSIGNEE_FAILURE", response });
      });
  };
};

Actions.onDeleteAssignee = (task_id, id) => {
  return (dispatch) => {
    dispatch({
      type: "ASSIGNEE_DELETE_REQUEST",
    });
    return api
      .delete(`/tasks/${task_id}/task_assignees/${id}`)
      .then((response) => {
        api.openNotification("success", "Ответственный", "Успешно удален!");
        dispatch({ type: "ASSIGNEE_DELETE_SUCCEED", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Ответственный", error);
          });
        }
        dispatch({ type: "ASSIGNEE_DELETE_FAILED" });
      });
  };
};

// activities
Actions.fetchActivities = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_TASK_ACTIVITIES_REQUEST" });
    return api
      .fetch(`/tasks/${id}/task_activities`)
      .then((response) => {
        dispatch({ type: "FETCH_TASK_ACTIVITIES", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Задача", error);
          });
        }
        dispatch({
          type: "FETCH_TASK_ACTIVITIES_FAILURE",
        });
      });
  };
};

// comments
Actions.fetchComments = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_TASK_COMMENTS_REQUEST" });
    api
      .fetch(`/tasks/${id}/task_comments`)
      .then((response) => {
        dispatch({ type: "FETCH_TASK_COMMENTS", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Статус", error);
          });
        }
        dispatch({ type: "FETCH_TASK_COMMENTS_FAILURE", response });
      });
  };
};

Actions.onCreateComment = (id, comment, mentioned) => {
  return (dispatch) => {
    dispatch({ type: "POST_TASK_COMMENT_REQUEST" });

    const mentioned_user_ids = mentioned.users
      ?.map((it) => it.id)
      ?.map((it) => parseInt(it) || null)
      ?.filter((it) => it !== null);
    const mentioned_member_ids = mentioned.members
      ?.map((it) => it.id)
      ?.map((it) => parseInt(it) || null)
      ?.filter((it) => it !== null);
    const mentioned_emails = mentioned.emails
      ?.map((it) => it.value || null)
      ?.filter((it) => it !== null && it.trim() !== '');

    return api
      .post(`/tasks/${id}/task_comments`, {
        task_comment: {
          comment: comment,
          mentioned_user_ids: mentioned_user_ids,
          mentioned_member_ids: mentioned_member_ids,
          mentioned_emails: mentioned_emails,
        },
      })
      .then((response) => {
        dispatch({ type: "POST_TASK_COMMENT", response });
        api.openNotification("success", "Комментарий", "Успешно создан!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Комментарий", error);
          });
        }
        dispatch({ type: "POST_TASK_COMMENT_FAILURE", response });
      });
  };
};

// attachments
Actions.fetchAttachments = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_TASK_ATTACHMENTS_REQUEST" });
    api
      .fetch(`/tasks/${id}/task_attachments`)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_TASK_ATTACHMENTS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_TASK_ATTACHMENTS_FAILURE", response });
      });
  };
};

Actions.onUploadAttachment = (id, file, commentId) => {
  return (dispatch) => {
    dispatch({
      type: "ATTACHMENT_UPLOAD",
    });

    const data = {
      task_comment_id: commentId,
      name: file.name,
      content_type: file.content_type,
      attachment: file.attachment,
    };

    return api
      .post(`/tasks/${id}/task_attachments`, data)
      .then((response) => {
        api.openNotification("success", "Файл", "Успешно загружен!");
        dispatch({ type: "ATTACHMENT_UPLOAD_SUCCEED", response });
      })
      .catch((response) => {
        dispatch({
          type: "ATTACHMENT_UPLOAD_FAILED",
        });
        dispatch({
          type: "ADD_NOTIFICATION",
          message: { label: response.error, type: "error" },
        });
      });
  };
};

Actions.onDeleteAttachment = (task_id, id) => {
  return (dispatch) => {
    dispatch({
      type: "ATTACHMENT_DELETE_REQUEST",
    });
    return api
      .delete(`/tasks/${task_id}/task_attachments/${id}`)
      .then((response) => {
        api.openNotification("success", "Файл", "Успешно удален!");
        dispatch({ type: "ATTACHMENT_DELETE_SUCCEED", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Файл", error);
          });
        }
        dispatch({ type: "ATTACHMENT_DELETE_FAILED" });
      });
  };
};

// task_timing
Actions.fetchTimings = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_TASK_TIMINGS_REQUEST" });
    return api
      .fetch(`/tasks/${id}/task_timings`)
      .then((response) => {
        dispatch({ type: "FETCH_TASK_TIMINGS", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Заявка", error);
          });
        }
        dispatch({
          type: "FETCH_TASK_TIMINGS_FAILURE",
        });
      });
  };
};

Actions.onCreateTiming = (id, time) => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_TASK_TIMING_REQUEST" });
    return api
      .post(`/tasks/${id}/task_timings`, {
        task_timing: { start: time },
      })
      .then((response) => {
        dispatch({ type: "POST_TASK_TIMING", response });
        api.openNotification("success", "Время работы", "Начало!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Время работы", error);
          });
        }
        dispatch({ type: "POST_TASK_TIMING_FAILURE", response });
      });
  };
};

Actions.onUpdateTiming = (taskId, id, time) => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_TASK_TIMING_REQUEST" });
    return api
      .patch(`/tasks/${taskId}/task_timings/${id}`, {
        task_timing: { finish: time },
      })
      .then((response) => {
        dispatch({ type: "PATCH_TASK_TIMING", response });
        api.openNotification("success", "Время работы", "Завершение!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Время работы", error);
          });
        }
        dispatch({ type: "PATCH_TASK_TIMING_FAILURE", response });
      });
  };
};

Actions.onStartTiming = (id, time) => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_TASK_START_TIMING_REQUEST" });
    api
      .post(`/tasks/${id}/start`, {
        start: time,
      })
      .then((response) => {
        dispatch({ type: "POST_TASK_START_TIMING", response, id });
        api.openNotification("success", "Время работы", "Начало!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Время работы", error);
          });
        }
        dispatch({ type: "POST_TASK_START_TIMING_FAILURE", response });
      });
  };
};

Actions.onPauseTiming = (id, time) => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_TASK_PAUSE_TIMING_REQUEST" });
    return api
      .patch(`/tasks/${id}/pause`, {
        finish: time,
      })
      .then((response) => {
        dispatch({ type: "PATCH_TASK_PAUSE_TIMING", response, id });
        api.openNotification("success", "Время работы", "Завершение!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Время работы", error);
          });
        }
        dispatch({ type: "PATCH_TASK_PAUSE_TIMING_FAILURE", response });
      });
  };
};

Actions.onChangePage = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_TASK_CHANGE_PAGE",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onSearch = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_TASK_SEARCH",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeLimit = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_TASK_CHANGE_LIMIT",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onSortBy = (field, direction) => {
  return (dispatch) => {
    dispatch({
      type: "TASKS_SORT_BY",
      field: field,
      direction: direction,
    });
    return Promise.resolve();
  };
};

Actions.onOpenModalForm = (template, id, customer = null, assignees = []) => {
  return (dispatch) => {
    dispatch({
      type: "TASK_OPEN_MODAL_FORM",
      template: template,
      id: id,
      customer: customer,
      assignees: assignees,
    });
    return Promise.resolve();
  };
};

Actions.onCancelModalForm = () => {
  return (dispatch) => {
    dispatch({
      type: "TASK_CANCEL_MODAL_FORM",
    });
  };
};

Actions.onCloseModalForm = () => {
  return (dispatch) => {
    dispatch({
      type: "TASK_CLOSE_MODAL_FORM",
    });
  };
};

Actions.onChangeStatus = (value) => {
  return (dispatch) => {
    dispatch({
      type: "TASK_CHANGE_STATUS",
      value: value,
    });
  };
};

Actions.onChangeDueBy = (value) => {
  return (dispatch) => {
    dispatch({
      type: "TASK_CHANGE_DUE_BY",
      value: value,
    });
  };
};

Actions.onChangeSubject = (value) => {
  return (dispatch) => {
    dispatch({
      type: "TASK_CHANGE_SUBJECT",
      value: value,
    });
  };
};

Actions.onChangeDescription = (value) => {
  return (dispatch) => {
    dispatch({
      type: "TASK_CHANGE_DESCRIPTION",
      value: value,
    });
  };
};

Actions.onChangeCustomer = (value) => {
  return (dispatch) => {
    dispatch({
      type: "TASK_CHANGE_CUSTOMER",
      value: value,
    });
  };
};

Actions.onChangeCompany = (value) => {
  return (dispatch) => {
    dispatch({
      type: "TASK_CHANGE_COMPANY",
      value: value,
    });
  };
};

Actions.onChangeAmount = (value) => {
  return (dispatch) => {
    dispatch({
      type: "TASK_CHANGE_AMOUNT",
      value: value,
    });
  };
};

Actions.onAddAssignee = (value) => {
  return (dispatch) => {
    dispatch({
      type: "TASK_ADD_ASSIGNEE",
      value: value,
    });
  };
};

Actions.onDestroyAssignee = (id) => {
  return (dispatch) => {
    dispatch({
      type: "TASK_DESTROY_ASSIGNEE",
      id: id,
    });
  };
};

Actions.onTaskTodoCompleted = (taskTodoId, id, taskTodoIdx, idx, value) => {
  return (dispatch) => {
    dispatch({ type: "POST_TASK_TODO_COMPLETED_REQUEST" });
    const data = { task_todo_item: { completed_at: value } };
    return api
      .patch(`/task_todos/${taskTodoId}/task_todo_items/${id}`, data)
      .then((response) => {
        dispatch({
          type: "POST_TASK_TODO_COMPLETED",
          response: response,
          taskTodoIdx: taskTodoIdx,
          idx: idx,
        });
        api.openNotification("success", "Чек-лист", "Обновлен!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Чек-лист", error);
          });
        }
        dispatch({ type: "POST_TASK_TODO_COMPLETED_FAILURE", response });
      });
  };
};

// filters
Actions.onSetFilters = () => {
  return (dispatch) => {
    dispatch({
      type: "TASKS_SET_FILTERS",
    });
    return Promise.resolve();
  };
};

Actions.onClearFilters = () => {
  return (dispatch) => {
    dispatch({
      type: "TASKS_CLEAR_FILTERS",
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterCreatedPeriod = (startDate, endDate) => {
  return (dispatch) => {
    dispatch({
      type: "TASKS_FILTER_CREATED_PERIOD",
      startDate: startDate,
      endDate: endDate,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterDueByPeriod = (startDate, endDate) => {
  return (dispatch) => {
    dispatch({
      type: "TASKS_FILTER_DUE_BY_PERIOD",
      startDate: startDate,
      endDate: endDate,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterStatus = (values) => {
  return (dispatch) => {
    dispatch({
      type: "TASKS_FILTER_STATUS",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterTemplates = (values) => {
  return (dispatch) => {
    dispatch({
      type: "TASKS_FILTER_TEMPLATES",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterTaskUsers = (values) => {
  return (dispatch) => {
    dispatch({
      type: "TASKS_FILTER_TASK_USERS",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterUsers = (values) => {
  return (dispatch) => {
    dispatch({
      type: "TASKS_FILTER_USERS",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterSupervisorUsers = (values) => {
  return (dispatch) => {
    dispatch({
      type: "TASKS_FILTER_SUPERVISOR_USERS",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterFieldSets = (values) => {
  return (dispatch) => {
    dispatch({
      type: "TASKS_FILTER_FIELD_SETS",
      values: values,
    });
    return Promise.resolve();
  };
};

// notices
Actions.onReceivedNotice = (value) => {
  return (dispatch) => {
    dispatch({
      type: "TASK_NOTICE_RECEIVED",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onClearNotices = () => {
  return (dispatch) => {
    dispatch({ type: "POST_TASK_MARK_ALL_AS_READ_REQUEST" });

    return api
      .post(`/task_comment_marks/mark_all_as_read`)
      .then((response) => {
        api.openNotification(
          "success",
          "Задачи",
          "Все комментарии отмечены как прочитанные!"
        );
        dispatch({ type: "POST_TASK_MARK_ALL_AS_READ", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Задачи", error);
          });
        }
        dispatch({
          type: "POST_TASK_MARK_ALL_AS_READ_FAILURE",
        });
      });
  };
};

// fields
Actions.onChangeField = (name, value) => {
  return (dispatch) => {
    dispatch({
      type: "TASK_CHANGE_FIELD",
      name: name,
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.downloadReport = (params, url = "", filename = "") => {
  let owners = [];
  let users = [];
  let supervisors = [];
  let templates = [];
  let fieldSets = [];

  if (params.owners) {
    owners = params.owners.map((user) => {
      if (user && user.id) {
        return user.id;
      }
    });
  }

  if (params.users) {
    users = params.users.map((user) => {
      if (user && user.id) {
        return user.id;
      }
    });
  }
  if (params.supervisors) {
    supervisors = params.supervisors.map((user) => {
      if (user && user.id) {
        return user.id;
      }
    });
  }

  if (params.templates) {
    templates = params.templates.map((template) => {
      if (template && template.id) {
        return template.id;
      }
    });
  }

  if (params.fieldSets) {
    fieldSets = params.fieldSets.map((fieldSet) => {
      if (fieldSet && fieldSet.id) {
        return fieldSet.id;
      }
    });
  }

  const data = {
    startDate: params.startDate,
    endDate: params.endDate,
    startCreatedDate: params.startCreatedDate,
    endCreatedDate: params.endCreatedDate,
    search: params.search,
    sort: params.sort,
    direction: params.direction,
    page: params.page,
    limit: params.limit,
    status: params.status,
    owners: owners,
    users: users,
    supervisors: supervisors,
    templates: templates,
    fieldSets: fieldSets,
  };

  api
    .fetchBlob(`/${url}`, data)
    .then((response) => {
      //console.log(response)
      saveAs(response, `${filename}`);
    })
    .catch((response) => {
      console.log(response.error);
    });
};

export default Actions;
