// @flow
import React, {Component} from "react";
import PropTypes from "prop-types";
import {Button, Checkbox, Col, Collapse, Form, Input, List, Modal, Row, Tooltip} from "antd";
import moment from "moment";
import update from "immutability-helper";

const FormItem = Form.Item;
const {Panel} = Collapse;
const {TextArea} = Input;

class Todos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showCommentInputModal: false,
      todo: {},
      todoIdx: null,
      todoItem: {},
      todoItemIdx: null,
      comment: null,
    };
  }

  onOpenComment = (todo, todoIdx, todoItem, todoItemIdx) => {
    this.setState({
      showCommentInputModal: true,
      todo: todo,
      todoIdx: todoIdx,
      todoItem: todoItem,
      todoItemIdx: todoItemIdx,
    });
  };

  onChangeComment = (event) => {
    this.setState({
      comment: event.target.value,
    });
  };

  onSaveComment = () => {
    const {
      todo,
      todoIdx,
      todoItem,
      todoItemIdx,
      comment,
    } = this.state;

    this.setState({
      showCommentInputModal: false,
    }, () => {
      this.props.onSaveComment(
        todo.id,
        todoIdx,
        todoItem.id,
        todoItemIdx,
        comment)
    });
  };

  onCloseComment = () => {
    this.setState({
      showCommentInputModal: false,
    });
  };


  render() {
    const {
      orderTaskTodos,
      onCompleted,
    } = this.props;
    const {
      getFieldDecorator,
    } = this.props.form;
    const {
      showCommentInputModal,
      todoItem,
    } = this.state;

    return (
      <React.Fragment>
        <Collapse>
          {orderTaskTodos.map((orderTaskTodo, orderTaskTodoIdx) => {
            const orderTaskTodoItems = orderTaskTodo.order_task_todo_items;
            const totalTodoItemsCount = orderTaskTodoItems.length;
            const completedTodoItemsCount = orderTaskTodoItems
              .filter((it) => it.completed_at !== null)
              .length

            return (
              <Panel
                key={orderTaskTodo.id}
                header={
                  `${orderTaskTodo.service.name} (${completedTodoItemsCount}/${totalTodoItemsCount})`
                }>
                <List
                  size="small"
                  dataSource={orderTaskTodoItems}
                  renderItem={(orderTaskTodoItem, orderTaskTodoItemIdx) => {
                    let author = null;

                    if (orderTaskTodoItem.user) {
                      author = orderTaskTodoItem.user.name;
                    }

                    if (orderTaskTodoItem.member) {
                      author = orderTaskTodoItem.member.name;
                    }

                    if (orderTaskTodoItem.employee) {
                      author = orderTaskTodoItem.employee.name;
                    }

                    return (
                      <List.Item
                        actions={[
                          <Checkbox
                            checked={!!orderTaskTodoItem.completed_at}
                            onChange={(event) => onCompleted(
                              orderTaskTodo.id,
                              orderTaskTodoIdx,
                              orderTaskTodoItem.id,
                              orderTaskTodoItemIdx,
                              event.target.checked
                            )}
                          />,
                        ]}
                      >
                        <List.Item.Meta
                          avatar={
                            <Tooltip placement="rightTop" title={"Комментарий"}>
                              <Button
                                onClick={() => this.onOpenComment(
                                  orderTaskTodo,
                                  orderTaskTodoIdx,
                                  orderTaskTodoItem,
                                  orderTaskTodoItemIdx
                                )}
                                type="dashed"
                                shape="circle"
                                icon="form"
                              />
                            </Tooltip>
                          }
                          title={orderTaskTodoItem.todo_item.name}
                          description={
                            <React.Fragment>
                    <span>
                      {orderTaskTodoItem.completed_at
                        ? `${author}, ${moment(orderTaskTodoItem.completed_at).format("LLL")}`
                        : null}
                    </span>
                              <br/>
                              <span>{orderTaskTodoItem.comment}</span>
                            </React.Fragment>
                          }
                        />
                      </List.Item>
                    );
                  }}
                />
              </Panel>
            );
          })}
        </Collapse>
        <Modal
          destroyOnClose
          title={todoItem.todo_item ? todoItem.todo_item.name : null}
          visible={showCommentInputModal}
          onOk={this.onSaveComment}
          onCancel={this.onCloseComment}>
          <Form layout={"vertical"}>
            <Row gutter={{md: 8, lg: 24, xl: 48}}>
              <Col span={24}>
                <FormItem label={"Комментарий"}>
                  {getFieldDecorator("comment", {
                    onChange: this.onChangeComment,
                    initialValue: todoItem.comment ? todoItem.comment : null,
                    rules: [
                      {
                        required: true,
                        message: "Введите комментарий!",
                      },
                    ],
                  })(<TextArea rows={4}/>)}
                </FormItem>
              </Col>
            </Row>
          </Form>
        </Modal>
      </React.Fragment>
    );
  }
}

Todos.propTypes = {
  entityTask: PropTypes.object,
  onChangeDate: PropTypes.func,
  onChangeAssignee: PropTypes.func,
  onChangeAgreement: PropTypes.func,
  onChangeAgreementDate: PropTypes.func,
  onChangeAdditional: PropTypes.func,
  onChangeComment: PropTypes.func,
  onChangeNotice: PropTypes.func,
};

export default (Form.create()(Todos));
