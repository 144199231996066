// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import update from "immutability-helper";
import { connect } from "react-redux";
import Actions from "../../../actions/projects";
import Content from "./Content";

class ProjectSteps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      project: {
        status: 1,
        customer: null,
        entity: null,
        number: null,
        project_stages: [],
        assignees: [],
      },
    };
  }

  nextStep = () => {
    const { current } = this.state;
    this.setState({ current: current + 1 });
  };

  prevStep = () => {
    const current = this.state.current - 1;
    this.setState({ current });
  };

  onCreate = () => {
    const { project } = this.state;
    this.props
      .dispatch(
        Actions.onCreate(project)
      )
      .then(() => {
        if (!this.props.errors) {
          this.props.history.push(`/projects`);
        }
      });

  };

  onClose = () => {
    this.props.history.push(`/projects`);
  };

  // header
  onChangeCustomer = (value) => {
    this.setState({
      project: update(this.state.project, {
        customer: { $set: value },
      }),
    });
  };

  onChangeEntity = (value) => {
    this.setState({
      project: update(this.state.project, {
        entity: { $set: value },
      }),
    });
  };

  onChangeProjectType = (value) => {
    this.setState({
      project: update(this.state.project, {
        project_type: { $set: value },
      }),
    });
  };

  onChangeNumber = (e) => {
    this.setState({
      project: update(this.state.project, {
        number: { $set: e.target.value },
      }),
    });
  };

  onChangeDescription = (e) => {
    this.setState({
      project: update(this.state.project, {
        description: { $set: e.target.value },
      }),
    });
  };

  onChangeAmount = (value) => {
    this.setState({
      project: update(this.state.project, {
        amount: { $set: value },
      }),
    });
  };

  // stages
  onSaveStage = (item) => {
    const { project } = this.state;
    let idx = project.project_stages.findIndex(function (o) {
      return o.id == item.id;
    });

    if (idx != -1) {
      this.setState({
        project: update(this.state.project, {
          project_stages: { [idx]: { $set: item } },
        }),
      });
    } else {
      this.setState({
        project: update(this.state.project, {
          project_stages: {
            $push: [item],
          },
        }),
      });
    }
  };

  onDeleteStages = (values) => {
    const { project } = this.state;

    values.map((value) => {
      let idx = project.project_stages.findIndex(function (o) {
        return o.id == value.id;
      });

      console.log(idx);

      if (idx != -1) {
        this.setState({
          stage: update(this.state.project, {
            project_stages: { $splice: [[idx, 1]] },
          }),
        });
      }
    });
  };

  // assignees
  onSaveAssignee = (value, type) => {
    if (type == "organisation") {
      this.setState({
        project: update(this.state.project, {
          assignees: {
            $push: [
              {
                id: value.id,
                assignee_type: type,
                user: {
                  id: value.id,
                  name: value.name,
                  title: value.extra.title,
                  department: value.extra.department,
                  email: value.extra.email,
                  avatar: value.extra.avatar,
                },
              },
            ],
          },
        }),
      });
    } else {
      this.setState({
        project: update(this.state.project, {
          assignees: {
            $push: [
              {
                id: value.id,
                assignee_type: type,
                member: {
                  id: value.id,
                  name: value.name,
                  title: value.extra.title,
                  department: value.extra.department,
                  email: value.extra.email,
                  avatar: value.extra.avatar,
                },
              },
            ],
          },
        }),
      });
    }
  };

  onDeleteAssignee = (id) => {};

  render() {
    const { current, project } = this.state;
    const { currentUser, isLoading, errors } = this.props;
    return (
      <Content
        current={current}
        nextStep={this.nextStep}
        prevStep={this.prevStep}
        project={project}
        onCreate={this.onCreate}
        // header
        onChangeCustomer={this.onChangeCustomer}
        onChangeEntity={this.onChangeEntity}
        onChangeProjectType={this.onChangeProjectType}
        onChangeNumber={this.onChangeNumber}
        onChangeDescription={this.onChangeDescription}
        onChangeAmount={this.onChangeAmount}
        // stages
        onSaveStage={this.onSaveStage}
        onDeleteStages={this.onDeleteStages}
        // assignees
        onSaveAssignee={this.onSaveAssignee}
        onDeleteAssignee={this.onDeleteAssignee}
      />
    );
  }
}

ProjectSteps.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  project: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  isLoading: state.projects.isLoading,
  errors: state.projects.errors,
  project: state.projects.project,
});

export default connect(mapStateToProps)(withRouter(ProjectSteps));
