import React from "react";
import { Row, Col, Popover, Table, Typography } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/ru";
import accounting from "accounting";

const { Paragraph } = Typography;

export default function TaskDetails({
  loading = false,
  name = null,
  data = [],
  onChange = null,
}) {
  return (
    <Popover
      placement="right"
      content={<Details loading={loading} data={data} />}
      title={`Расшифровка (${data.length})`}
      trigger="click"
      onVisibleChange={onChange}
    >
      <span style={{ cursor: "pointer" }}>{name}</span>
    </Popover>
  );
}

function Details({ loading = false, data = null }) {
  const columns = [
    {
      title: "Задача",
      dataIndex: "number",
      render: (number, record) => (
        <Link to={`/tasks/${record.id}/edit`} target="_blank">
          {record.id}
        </Link>
      ),

      key: "number",
      align: "center",
      width: "10%",
    },
    {
      title: "Дата создания",
      dataIndex: "created_at",
      render: (created_at) => moment(created_at).format("LLL"),
      key: "created_at",
      align: "center",
      width: "20%",
    },
    {
      title: "Выполнить до",
      dataIndex: "due_by",
      render: (due_by) => moment(due_by).format("LLL"),
      key: "due_by",
      align: "center",
      width: "20%",
    },
    {
      title: "Тема / Описание",
      dataIndex: "subject",
      render: (subject, record) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span>
          {subject}
          </span>
          <span style={{ fontSize: 12 }}>
            <Paragraph ellipsis={{ rows: 3, expandable: true }}>
              {record.description}
            </Paragraph>
          </span>
        </div>
      ),
      key: "subject",
      align: "center",
      width: "30%",
    },
    {
      title: "Шаблон",
      dataIndex: "template_name",
      key: "template_name",
      align: "center",
      width: "25%",
    },
  ];

  return (
    <div style={{ width: 680 }}>
      <Table
        rowClassName={"progress-row"}
        size={"small"}
        loading={loading}
        // showHeader={false}
        columns={columns}
        dataSource={data}
        pagination={false}
        rowKey="id"
        scroll={{ y: 340 }}
      />
    </div>
  );
}
