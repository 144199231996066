// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Col, Row, Descriptions, Divider } from "antd";
import { Typography } from "antd";
import Presentation from "../../../components/Presentation";
import Can from "../../../rules/Can";

const { Paragraph } = Typography;

class General extends Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    const {
      currentUser,
      entity,
      order,
      customer,
      subject,
      description,
      onChangeSubject,
      onChangeDescription,
    } = this.props;

    return (
      <React.Fragment>
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col span={24}>
            <Descriptions bordered>
              <Descriptions.Item label="Заказ" span={3}>
                {order ? (
                  <span>
                    <Presentation
                      currentUser={currentUser}
                      rule={"orders:view"}
                      route={`/orders/${order.id}/edit`}
                      title={`Заказ #${order.number}`}
                    />
                  </span>
                ) : null}
              </Descriptions.Item>
              <Descriptions.Item label="Объект" span={3}>
                {order ? (
                  <span>
                    <Link to={`/entities/${entity.id}/edit`}>
                      {entity.name}
                    </Link>
                  </span>
                ) : null}
              </Descriptions.Item>
              <Descriptions.Item label="Адрес" span={3}>
                {entity.address}
              </Descriptions.Item>
              <Descriptions.Item label="Клиент" span={3}>
                <Presentation
                  currentUser={currentUser}
                  rule={"customers:view"}
                  route={customer ? `/customers/${customer.id}/edit` : null }
                  title={customer ? customer.name : null}
                />
              </Descriptions.Item>
              <Descriptions.Item label="Телефоны" span={3}>
                {customer ? customer.phones : null}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Divider>Тема</Divider>
            <Can
              role={currentUser.role}
              perform="entity_tasks:edit"
              yes={() => (
                <Paragraph editable={{ onChange: onChangeSubject }}>
                  {subject}
                </Paragraph>
              )}
              no={() => (subject ? subject : null)}
            />
          </Col>
          <Col span={24}>
            <Divider>Описание</Divider>
            <Can
              role={currentUser.role}
              perform="entity_tasks:edit"
              yes={() => (
                <Paragraph editable={{ onChange: onChangeDescription }}>
                  {description}
                </Paragraph>
              )}
              no={() => (description ? description : null)}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

General.propTypes = {
  orderTask: PropTypes.object,
  onChangeDate: PropTypes.func,
  onChangeAssignee: PropTypes.func,
  onChangeAgreement: PropTypes.func,
  onChangeAgreementDate: PropTypes.func,
  onChangeAdditional: PropTypes.func,
  onChangeComment: PropTypes.func,
  onChangeNotice: PropTypes.func,
};

export default General;
