// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, Form, Card } from "antd";
import Header from "./Header";
import Sidebar from "./Sidebar";
import moment from "moment";
import "moment/locale/ru";

class TaskTemplate extends Component {
  constructor(props) {
    super(props);
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onSave(values);
    });
  };

  onPublish = (e) => {
    e.preventDefault();
    this.props.onPublish(true);
  };

  onUnPublish = (e) => {
    e.preventDefault();
    this.props.onPublish(false);
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      isNew,
      errors,
      isLoading,
      currentUser,
      todoTemplate,
      onClose,
      onChangeActive,
      onChangeName,
      onChangeDescription,
      onChangeTodoType,
      onChangeServices,
      // todos
      onAddTodo,
      onDeleteTodo,
      // todo_items
      onNewTodoItem,
    } = this.props;

    return (
      <Form layout={"vertical"}>
        <Card
          loading={isLoading}
          title={
            <h2 style={{ marginBottom: 0 }}>{`Шаблон чек-листа #${
              isNew ? "новый" : todoTemplate.id
            } от ${moment(todoTemplate.created_at).format("LLL")}`}</h2>
          }
          extra={[
            <Button key={1} onClick={onClose}>
              Закрыть
            </Button>,
            <Button
              key={2}
              onClick={this.onSubmit}
              type="primary"
              ghost
              style={{ marginLeft: "8px" }}
            >
              Сохранить
            </Button>,
          ]}
        >
          <Col xs={24} sm={24} md={24} lg={13} xl={13}>
            <Header
              getFieldDecorator={getFieldDecorator}
              todoTemplate={todoTemplate}
              onChangeName={onChangeName}
              onChangeDescription={onChangeDescription}
              onChangeTodoType={onChangeTodoType}
              onChangeServices={onChangeServices}
            />
          </Col>
          <Col xs={0} sm={0} md={1} lg={1} xl={1} />
          <Col xs={24} sm={24} md={24} lg={10} xl={10}>
            <Sidebar
              getFieldDecorator={getFieldDecorator}
              todoTemplate={todoTemplate}
              onChangeActive={onChangeActive}
              onAddTodo={onAddTodo}
              onDeleteTodo={onDeleteTodo}
              // todo_items
              onNewTodoItem={onNewTodoItem}
            />
          </Col>
        </Card>
      </Form>
    );
  }
}

TaskTemplate.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  todoTemplate: PropTypes.object,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
  router: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  form: PropTypes.object,
};

export default Form.create()(TaskTemplate);
