import React from "react";
import { Row, Col, Button, Card, Tag, Checkbox, Divider } from "antd";
import moment from "moment";
import "moment/locale/ru";
import {Link} from "react-router-dom";

export default function Info({ loading = false, event = null }) {
  return (
    <Col xs={24} sm={24} md={24} lg={6} xl={6}>
      <Card loading={loading}>
        <Divider>
          {event?.type === 'tasks' ?
            <Link to={`/${event.type}/${event.id}/edit`}>{event.title}</Link> :
            event.title}
        </Divider>
        <Row>
          <Col span={24}>{event.entity ? event.entity.name : null}</Col>
        </Row>
        <Row>
          <Col span={24}>{event.subject}</Col>
        </Row>
      </Card>
    </Col>
  );
}
