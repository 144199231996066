import update from "immutability-helper";
import moment from "moment";
moment.locale("ru");

const initialState = {
  supportMessages: [],
  entityTasks: [],
  orderTasks: [],
  archiveNotices: [],
  notices: [],
  count: 0,
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1,
  },
  fetchParams: {
    startDate: null,
    endDate: null,
    sort: null,
    direction: null,
    page: 1,
    limit: 10,
    types: null,
    users: null,
    members: null,
  },
  errors: false,
  isLoading: true,
};

function loadLocalFilters(fetchParams) {
  let localFetchParams = null;
  const noticesFilters = localStorage.getItem("noticesFilters");
  if (noticesFilters) {
    localFetchParams = JSON.parse(noticesFilters);
  } else {
    localStorage.setItem("noticesFilters", JSON.stringify(fetchParams));
  }

  return localFetchParams;
}

export default function (state = initialState, action) {
  let localFetchParams = null;
  switch (action.type) {
    case "FETCH_NOTICES_REQUEST":
      return {
        ...state,
        isLoading: true,
      };

    case "FETCH_NOTICES":
      return update(state, {
        archiveNotices: { $set: action.response.data },
        meta: { $set: action.response.meta },
        isLoading: { $set: false },
      });

    case "FETCH_NOTICES_FAILURE":
      return {
        ...state,
        isLoading: false,
      };

    // dashboard
    case "DASHBOARD_NOTICES_FETCH_REQUEST":
      return {
        ...state,
        isLoading: true,
      };

    case "DASHBOARD_NOTICES_FETCH":
      return update(state, {
        supportMessages: { $set: action.response.data.support_messages },
        entityTasks: { $set: action.response.data.entity_tasks },
        orderTasks: { $set: action.response.data.order_tasks },
        isLoading: { $set: false },
      });

    case "DASHBOARD_NOTICES_FETCH_FAILURE":
      return {
        ...state,
        isLoading: false,
      };

    // context
    case "FETCH_NOTICES_REQUEST":
      return {
        ...state,
        notices: [],
        isLoading: true,
      };

    case "FETCH_NOTICES_SUCCEED":
      return update(state, {
        notices: { $set: action.response.data },
        isLoading: { $set: false },
      });

    case "FETCH_NOTICES_FAILURE":
      return {
        ...state,
        isLoading: false,
      };

    // notices
    case "POST_NOTICES_CLEAR_REQUEST":
      return {
        ...state,
        errors: false,
      };
    case "POST_NOTICES_CLEAR":
      return {
        ...state,
        errors: false,
        notices: action.response.data,
      };

    case "POST_NOTICES_CLEAR_FAILURE":
      return {
        ...state,
        errors: true,
      };

    case "REQUEST_NOTICES_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } },
      });

    case "REQUEST_NOTICES_CHANGE_LIMIT":
      return update(state, {
        fetchParams: { limit: { $set: action.value }, page: { $set: 1 } },
      });

    case "NOTICES_SORT_BY":
      return update(state, {
        fetchParams: {
          sort: { $set: action.field },
          direction: { $set: action.direction },
        },
      });

      // filters
    case "NOTICES_CLEAR_FILTERS":
      localStorage.removeItem("ordersFilters");

      return update(state, {
        fetchParams: {
          status: { $set: null },
          users: { $set: null },
          members: { $set: null },
        },
      });

    case "NOTICES_SET_FILTERS":
      localFetchParams = loadLocalFilters(state.fetchParams);
      if (localFetchParams) {
        localFetchParams.search = null;
        return {
          ...state,
          fetchParams: localFetchParams,
        };
      } else {
        return {
          ...state,
        };
      }

    case "NOTICES_FILTER_PERIOD":
      return update(state, {
        fetchParams: {
          startDate: { $set: action.startDate },
          endDate: { $set: action.endDate },
          page: { $set: 1 },
        },
      });

    case "NOTICES_FILTER_TYPES":
      return update(state, {
        fetchParams: {
          types: { $set: action.values.length > 0 ? action.values : null },
          page: { $set: 1 },
        },
      });

    case "NOTICES_FILTER_USERS":
      return update(state, {
        fetchParams: {
          users: { $set: action.values.length > 0 ? action.values : null },
          page: { $set: 1 },
        },
      });

    case "NOTICES_FILTER_MEMBERS":
      return update(state, {
        fetchParams: {
          members: { $set: action.values.length > 0 ? action.values : null },
          page: { $set: 1 },
        },
      });

    default:
      return state;
  }
}
