import api from "../api";
import { saveAs } from "file-saver";

const Actions = {};

Actions.fetchEntities = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_ENTITIES_REQUEST" });
    let users = [];
    let members = [];
    let regions = [];
    const params = getState().entities.fetchParams;

    if (params.users) {
      users = params.users.map((user) => {
        if (user && user.id) {
          return user.id;
        }
      });
    }
    if (params.members) {
      members = params.members.map((member) => {
        if (member && member.id) {
          return member.id;
        }
      });
    }

    if (params.regions) {
      regions = params.regions.map((region) => {
        if (region && region.id) {
          return region.id;
        }
      });
    }

    const data = {
      search: params.search,
      sort: params.sort,
      direction: params.direction,
      page: params.page,
      limit: params.limit,
      regions: regions,
      customer: params.customer ? params.customer.id : null,
      entityType: params.entityType ? params.entityType.id : null,
      users: users,
      members: members,
      active: params.active,
    };

    return api
      .fetch("/entities", data)
      .then((response) => {
        dispatch({ type: "FETCH_ENTITIES", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_ENTITIES_FAILURE", response });
      });
  };
};

Actions.fetchEntity = (id = null) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ENTITY_REQUEST" });
    return api
      .fetch(`/entities/${id}`)
      .then((response) => {
        dispatch({ type: "FETCH_ENTITY", response });
      })
      .catch((error) => {
        api.handleError(error, "Объект");
        dispatch({ type: "FETCH_ENTITY_FAILURE" });
      });
  };
};

Actions.onNew = (customer = null) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITY_NEW",
      customer: customer,
    });
  };
};

Actions.onUpdate = (id) => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_ENTITY_REQUEST" });
    const entity = getState().entities.entity;

    const entity_services_attributes = entity.entity_services.map((item) => {
      let users = [];
      let employees = [];
      item.users.map((user) => {
        return users.push(user.id);
      });

      item.employees.map((employee) => {
        return employees.push(employee.id);
      });

      return {
        id: item.is_exist ? item.id : null,
        service_id: item.service ? item.service.id : null,
        periodicity: item.periodicity,
        duration: item.duration,
        start: item.start,
        is_supplier: item.is_supplier,
        company_id: item.company ? item.company.id : null,
        user_ids: users,
        employee_ids: employees,
        _destroy: item._destroy,
      };
    });

    const entity_extcodes_attributes = entity.entity_extcodes.map((item) => {
      return {
        id: item.is_exist ? item.id : null,
        code: item.code,
        _destroy: item._destroy,
      };
    });

    let todoTemplates = [];
    entity.todo_templates.map((todo_template) => {
      return todoTemplates.push(todo_template.id);
    });

    const data = {
      entity: {
        is_available: entity.is_available,
        is_organisation: entity.is_organisation,
        customer_id: entity.customer ? entity.customer.id : null,
        entity_type_id: entity.entity_type ? entity.entity_type.id : null,
        start: entity.start,
        finish: entity.finish,
        name: entity.name,
        postal_code: entity.postal_code,
        state_name: entity.state_name,
        city_id: entity.city ? entity.city.id : null,
        street: entity.street,
        building: entity.building,
        square: entity.square,
        room: entity.room,
        equipment: entity.equipment,
        complexity: entity.complexity,
        lat: entity.lat,
        lon: entity.lon,
        ext_code: entity.ext_code,
        todo_template_ids: todoTemplates,
        entity_services_attributes: entity_services_attributes,
        entity_extcodes_attributes: entity_extcodes_attributes,
      },
    };

    api
      .patch(`/entities/${id}`, data)
      .then((response) => {
        dispatch({ type: "PATCH_ENTITY", response });
        api.openNotification("success", "Объект", "Успешно обновлен!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Объект", error);
          });
        }
        dispatch({ type: "PATCH_ENTITY_FAILURE", response });
      });
  };
};

Actions.onCreate = () => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_ENTITY_REQUEST" });
    const entity = getState().entities.entity;
    const entity_services_attributes = entity.entity_services.map((item) => {
      let users = [];
      let employees = [];
      item.users.map((user) => {
        return users.push(user.id);
      });

      item.employees.map((employee) => {
        return employees.push(employee.id);
      });

      return {
        id: item.is_exist ? item.id : null,
        service_id: item.service ? item.service.id : null,
        periodicity: item.periodicity,
        duration: item.duration,
        start: item.start,
        is_supplier: item.is_supplier,
        company_id: item.company ? item.company.id : null,
        user_ids: users,
        employee_ids: employees,
        _destroy: item._destroy,
      };
    });

    const entity_extcodes_attributes = entity.entity_extcodes.map((item) => {
      return {
        id: item.is_exist ? item.id : null,
        code: item.code,
        _destroy: item._destroy,
      };
    });

    let todoTemplates = [];
    entity.todo_templates.map((todo_template) => {
      return todoTemplates.push(todo_template.id);
    });

    const data = {
      entity: {
        is_available: entity.is_available,
        is_organisation: entity.is_organisation,
        customer_id: entity.customer ? entity.customer.id : null,
        entity_type_id: entity.entity_type ? entity.entity_type.id : null,
        start: entity.start,
        finish: entity.finish,
        name: entity.name,
        postal_code: entity.postal_code,
        state_name: entity.state_name,
        city_id: entity.city ? entity.city.id : null,
        street: entity.street,
        building: entity.building,
        square: entity.square,
        room: entity.room,
        equipment: entity.equipment,
        complexity: entity.complexity,
        lat: entity.lat,
        lon: entity.lon,
        ext_code: entity.ext_code,
        todo_template_ids: todoTemplates,
        entity_services_attributes: entity_services_attributes,
        entity_extcodes_attributes: entity_extcodes_attributes,
      },
    };

    return api
      .post(`/entities`, data)
      .then((response) => {
        dispatch({ type: "POST_ENTITY", response });
        api.openNotification("success", "Объект", "Успешно создан!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Объект", error);
          });
        }
        dispatch({ type: "POST_ENTITY_FAILURE", response });
      });
  };
};

Actions.onDelete = (id) => {
  return (dispatch) => {
    dispatch({ type: "DELETE_ENTITY_REQUEST" });
    return api
      .delete(`/entities/${id}`)
      .then((response) => {
        api.openNotification("success", "Объект", "Успешно удален!");
        dispatch({ type: "DELETE_ENTITY", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Объект", error);
          });
        }
        dispatch({
          type: "DELETE_ENTITY_FAILURE",
        });
      });
  };
};

// attachments
Actions.fetchAttachments = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ATTACHMENT_ENTITY_REQUEST" });
    api
      .fetch(`/entities/${id}/entity_attachments`)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_ENTITY_ATTACHMENTS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_ENTITY_ATTACHMENTS_FAILURE", response });
      });
  };
};

Actions.onUploadAttachment = (id, file, storage_type) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITY_ATTACHMENT_UPLOAD",
    });
    // console.log(file);
    const formData = new FormData();

    formData.append("content_type", file.type);
    formData.append("attachment", file);
    formData.append("storage_type", "internal");
    return api
      .postFormData(`/entities/${id}/entity_attachments`, formData)
      .then((response) => {
        api.openNotification("success", "Файл", "Успешно загружен!");
        dispatch({ type: "ENTITY_ATTACHMENT_UPLOAD_SUCCEED", response });
      })
      .catch((response) => {
        dispatch({
          type: "ENTITY_ATTACHMENT_UPLOAD_FAILED",
        });
        dispatch({
          type: "ADD_NOTIFICATION",
          message: { label: response.error, type: "error" },
        });
      });
  };
};

Actions.onSaveAttachment = (id, data) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITY_ATTACHMENT_UPLOAD",
    });

    return api
      .post(`/entities/${id}/entity_attachments`, data)
      .then((response) => {
        api.openNotification("success", "ссылка", "Успешно создана!");
        dispatch({ type: "ENTITY_ATTACHMENT_UPLOAD_SUCCEED", response });
      })
      .catch((response) => {
        dispatch({
          type: "ENTITY_ATTACHMENT_UPLOAD_FAILED",
        });
        dispatch({
          type: "ADD_NOTIFICATION",
          message: { label: response.error, type: "error" },
        });
      });
  };
};

Actions.onDeleteAttachment = (entity_task_id, id) => {
  return (dispatch) => {
    dispatch({
      type: "ATTACHMENT_DELETE_REQUEST",
    });
    return api
      .delete(`/entities/${entity_task_id}/entity_attachments/${id}`)
      .then((response) => {
        api.openNotification("success", "Файл", "Успешно удален!");
        dispatch({ type: "ATTACHMENT_DELETE_SUCCEED", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Файл", error);
          });
        }
        dispatch({ type: "ATTACHMENT_DELETE_FAILED" });
      });
  };
};

// entity_photos
Actions.uploadPhoto = (entityId, file) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITY_PHOTO_UPLOAD",
    });

    const formData = new FormData();
    formData.append("file", file);
    return api
      .postFormData(`/entities/${entityId}/entity_photos`, formData)
      .then((response) => {
        api.openNotification(
          "success",
          "Объект",
          "Изображение успешно загружено!"
        );
        dispatch({ type: "ENTITY_PHOTO_UPLOAD_SUCCEED", response });
      })
      .catch((response) => {
        dispatch({
          type: "ENTITY_PHOTO_UPLOAD_FAILED",
        });
        dispatch({
          type: "ADD_NOTIFICATION",
          message: { label: response.error, type: "error" },
        });
      });
  };
};

Actions.fetchEntityImages = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ENTITY_PHOTOS_REQUEST" });
    api
      .fetch(`/entities/${id}/entity_photos`)
      .then((response) => {
        dispatch({ type: "FETCH_ENTITY_PHOTOS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_ENTITY_PHOTOS_FAILURE", response });
      });
  };
};

Actions.onDeletePhoto = (id, uid) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITY_PHOTO_DELETE_REQUEST",
    });
    return api
      .delete(`/entities/${id}/entity_photos/${uid}`)
      .then((response) => {
        api.openNotification(
          "success",
          "Объект",
          "Изображение успешно удалено!"
        );
        dispatch({ type: "ENTITY_PHOTO_DELETE_SUCCEED", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Объект", error);
          });
        }
        dispatch({ type: "ENTITY_PHOTO_DELETE_FAILED" });
      });
  };
};

// activities
Actions.fetchActivities = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ENTITY_ACTIVITIES_REQUEST" });
    return api
      .fetch(`/entities/${id}/entity_activities`)
      .then((response) => {
        dispatch({ type: "FETCH_ENTITY_ACTIVITIES", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Объект", error);
          });
        }
        dispatch({
          type: "FETCH_ENTITY_ACTIVITIES_FAILURE",
        });
      });
  };
};

// assignees
Actions.fetchAssignees = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ASSIGNEES_REQUEST" });
    api
      .fetch(`/entities/${id}/entity_assignees`)
      .then((response) => {
        dispatch({ type: "FETCH_ASSIGNEES", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_ASSIGNEES_FAILURE", response });
      });
  };
};

Actions.onCreateAssignee = (id, assignee, type) => {
  return (dispatch) => {
    dispatch({ type: "POST_ASSIGNEE_REQUEST" });
    return api
      .post(`/entities/${id}/entity_assignees`, {
        entity_assignee: {
          assignee_type: type,
          user_id:
            type == "organisation" || type == "supervisor" ? assignee.id : null,
          member_id:
            type == "organisation" || type == "supervisor" ? null : assignee.id,
        },
      })
      .then((response) => {
        dispatch({ type: "POST_ASSIGNEE", response });
        api.openNotification("success", "Ответственный", "Успешно добавлен!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Ответственный", error);
          });
        }
        dispatch({ type: "POST_ASSIGNEE_FAILURE", response });
      });
  };
};

Actions.onDeleteAssignee = (entity_id, id) => {
  return (dispatch) => {
    dispatch({
      type: "ASSIGNEE_DELETE_REQUEST",
    });
    return api
      .delete(`/entities/${entity_id}/entity_assignees/${id}`)
      .then((response) => {
        api.openNotification("success", "Ответственный", "Успешно удален!");
        dispatch({ type: "ASSIGNEE_DELETE_SUCCEED", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Ответственный", error);
          });
        }
        dispatch({ type: "ASSIGNEE_DELETE_FAILED" });
      });
  };
};

// entity_schemas
Actions.fetchEntitySchemas = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ENTITY_SCHEMAS_REQUEST" });
    api
      .fetch(`/entities/${id}/entity_schemas`)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_ENTITY_SCHEMAS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_ENTITY_SCHEMAS_FAILURE", response });
      });
  };
};

Actions.onUploadEntitySchema = (entity_id, file) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITY_SCHEMA_UPLOAD",
    });
    const formData = new FormData();
    formData.append("file", file);
    return api
      .postFormData(`/entities/${entity_id}/entity_schemas`, formData)
      .then((response) => {
        api.openNotification("success", "Файл", "Успешно загружен!");
        dispatch({ type: "ENTITY_SCHEMA_UPLOAD_SUCCEED", response });
      })
      .catch((response) => {
        dispatch({
          type: "ENTITY_SCHEMA_UPLOAD_FAILED",
        });
        dispatch({
          type: "ADD_NOTIFICATION",
          message: { label: response.error, type: "error" },
        });
      });
  };
};

Actions.onDeleteEntitySchema = (entity_id, id) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITY_SCHEMA_DELETE_REQUEST",
    });
    return api
      .delete(`/entities/${entity_id}/entity_schemas/${id}`)
      .then((response) => {
        api.openNotification("success", "Файл", "Успешно удален!");
        dispatch({ type: "ENTITY_SCHEMA_DELETE_SUCCEED", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Файл", error);
          });
        }
        dispatch({ type: "ENTITY_SCHEMA_DELETE_FAILED" });
      });
  };
};

// opening_hours
Actions.onSaveOpeningHours = (id, records) => {
  return (dispatch) => {
    dispatch({ type: "PATCH_OPENING_HOURS_REQUEST" });
    const entity_opening_hours = records.map((item) => {
      return {
        id: item.is_exist ? item.id : null,
        day: item.day,
        opens: item.opens,
        closes: item.closes,
      };
    });

    const data = {
      entity: {
        entity_opening_hours_attributes: entity_opening_hours,
      },
    };

    api
      .patch(`/entities/${id}`, data)
      .then((response) => {
        dispatch({ type: "PATCH_OPENING_HOURS", response });
        api.openNotification("success", "Объект", "Расписание сохранено!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Объект", error);
          });
        }
        dispatch({ type: "PATCH_OPENING_HOURS_FAILURE", response });
      });
  };
};

Actions.onSearch = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_ENTITY_SEARCH",
      value: value,
    });
    return Promise.resolve();
  };
};

// entity_tasks
Actions.fetchEntityTasks = (entity_id, startDate, endDate) => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_ENTITY_TASKS_REQUEST" });
    const params = {
      entity_id: entity_id,
      startDate: startDate,
      endDate: endDate,
    };
    api
      .fetch("/entity_tasks", params)
      .then((response) => {
        dispatch({ type: "FETCH_ENTITY_TASKS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_ENTITY_TASKS_FAILURE", response });
      });
  };
};

// tickets
Actions.fetchTickets = (
  entity_id,
  startCreatedDate = null,
  endCreatedDate = null,
  categories = null
) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ENTITY_TICKETS_REQUEST" });
    const params = {
      entity_id: entity_id,
      startCreatedDate: startCreatedDate,
      endCreatedDate: endCreatedDate,
      categories: categories,
    };
    api
      .fetch("/tickets", params)
      .then((response) => {
        dispatch({ type: "FETCH_ENTITY_TICKETS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_ENTITY_TICKETS_FAILURE", response });
      });
  };
};

Actions.fetchOrders = (entity_id, startDate, endDate) => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_ORDERS_REQUEST" });
    const params = {
      entity_id: entity_id,
      startDate: startDate,
      endDate: endDate,
    };
    api
      .fetch("/orders", params)
      .then((response) => {
        dispatch({ type: "FETCH_ORDERS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_ORDERS_FAILURE", response });
      });
  };
};

Actions.fetchProjects = (entity_id, startDate, endDate) => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_PROJECTS_REQUEST" });
    const params = {
      entity_id: entity_id,
      startDate: startDate,
      endDate: endDate,
    };
    api
      .fetch("/projects", params)
      .then((response) => {
        dispatch({ type: "FETCH_PROJECTS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_PROJECTS_FAILURE", response });
      });
  };
};

// notes
Actions.fetchEntityNotes = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ENTITY_NOTES_REQUEST" });
    api
      .fetch(`/entities/${id}/entity_notes`)
      .then((response) => {
        dispatch({ type: "FETCH_ENTITY_NOTES", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Примечание", error);
          });
        }
        dispatch({ type: "FETCH_ENTITY_NOTES_FAILURE", response });
      });
  };
};

Actions.onCreateNote = (id, value) => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_ENTITY_NOTE_REQUEST" });
    return api
      .post(`/entities/${id}/entity_notes`, {
        entity_note: { note: value },
      })
      .then((response) => {
        dispatch({ type: "POST_ENTITY_NOTE", response });
        api.openNotification("success", "Примечание", "Успешно создано!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Примечание", error);
          });
        }
        dispatch({ type: "POST_ENTITY_NOTE_FAILURE", response });
      });
  };
};

//general
Actions.onChangeStart = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITY_SERVICE_CHANGE_START",
      value: value,
    });
  };
};
Actions.onChangeFinish = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITY_SERVICE_CHANGE_FINISH",
      value: value,
    });
  };
};
Actions.onChangeIsOrganisation = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITY_CHANGE_IS_ORGANISATION",
      value: value,
    });
  };
};
Actions.onChangeCustomer = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITY_SERVICE_CHANGE_CUSTOMER",
      value: value,
    });
  };
};
Actions.onChangeName = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITY_SERVICE_CHANGE_NAME",
      value: value,
    });
  };
};

Actions.onChangeEntityType = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITY_CHANGE_ENTITY_TYPE",
      value: value,
    });
  };
};

Actions.onChangePostalCode = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITY_CHANGE_POSTAL_CODE",
      value: value,
    });
  };
};
Actions.onChangeCity = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITY_CHANGE_CITY",
      value: value,
    });
  };
};
Actions.onChangeStreet = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITY_CHANGE_STREET",
      value: value,
    });
  };
};

Actions.onChangeBuilding = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITY_CHANGE_BUILDING",
      value: value,
    });
  };
};

Actions.onChangeLat = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITY_CHANGE_LAT",
      value: value,
    });
  };
};

Actions.onChangeLon = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITY_CHANGE_LON",
      value: value,
    });
  };
};

Actions.onChangeExtCode = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITY_CHANGE_EXT_CODE",
      value: value,
    });
  };
};

Actions.onChangeTodoTemplates = (values) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITY_SERVICE_CHANGE_TODO_TEMPLATES",
      values: values,
    });
  };
};

//entity_services
Actions.onAddEntityService = (item) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITY_SERVICE_ADD",
      item: item,
    });
  };
};

Actions.onSaveEntityService = (idx, item) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITY_SERVICE_SAVE",
      idx: idx,
      item: item,
    });
  };
};

Actions.onDeleteEntityService = (idx) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITY_SERVICE_DELETE",
      idx: idx,
    });
  };
};

Actions.onDestroyEntityService = (idx) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITY_SERVICE_DESTROY",
      idx: idx,
    });
  };
};

//parameters
Actions.onChangeSquare = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITY_CHANGE_SQUARE",
      value: value,
    });
  };
};

Actions.onChangeRoom = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITY_CHANGE_ROOM",
      value: value,
    });
  };
};

Actions.onChangeEquipment = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITY_CHANGE_EQUIPMENT",
      value: value,
    });
  };
};

Actions.onChangeComplexity = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITY_CHANGE_COMPLEXITY",
      value: value,
    });
  };
};

// extCodes
Actions.onAddExtCode = (item) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITY_EXT_CODE_ADD",
      item: item,
    });
  };
};

Actions.onDeleteExtCode = (idx) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITY_EXT_CODE_DELETE",
      idx: idx,
    });
  };
};

Actions.onDestroyExtCode = (idx) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITY_EXT_CODE_DESTROY",
      idx: idx,
    });
  };
};

// filters
Actions.onSetFilters = () => {
  return (dispatch) => {
    dispatch({
      type: "ENTITIES_SET_FILTERS",
    });
    return Promise.resolve();
  };
};

Actions.onClearFilters = () => {
  return (dispatch) => {
    dispatch({
      type: "ENTITIES_CLEAR_FILTERS",
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterUsers = (values) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITIES_FILTER_USERS",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterMembers = (values) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITIES_FILTER_MEMBERS",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterRegions = (values) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITIES_FILTER_REGIONS",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterCustomer = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITIES_FILTER_CUSTOMER",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterEntityType = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITIES_FILTER_ENTITY_TYPE",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterActive = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITIES_FILTER_ACTIVE",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangePage = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_ENTITIES_CHANGE_PAGE",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeLimit = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_ENTITIES_CHANGE_LIMIT",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onSortBy = (field, direction) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITIES_SORT_BY",
      field: field,
      direction: direction,
    });
    return Promise.resolve();
  };
};

Actions.downloadReport = (params, url = "", filename = "") => {
  let regions = [];
  let users = [];
  let members = [];

  if (params.regions) {
    regions = params.regions.map((region) => {
      if (region && region.id) {
        return region.id;
      }
    });
  }
  if (params.users) {
    users = params.users.map((user) => {
      if (user && user.id) {
        return user.id;
      }
    });
  }
  if (params.members) {
    members = params.members.map((member) => {
      if (member && member.id) {
        return member.id;
      }
    });
  }

  const data = {
    active: params.active,
    search: params.search,
    sort: params.sort,
    direction: params.direction,
    limit: 1000,
    regions: regions,
    customer: params.customer ? params.customer.id : null,
    entityType: params.entityType ? params.entityType.id : null,
    users: users,
    members: members,
  };

  api
    .fetchBlob(`/${url}`, data)
    .then((response) => {
      //console.log(response)
      saveAs(response, `${filename}`);
    })
    .catch((response) => {
      console.log(response.error);
    });
};

export default Actions;
