// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, Form, Card } from "antd";
import General from "./General";
import Sidebar from "./Sidebar";
import moment from "moment";
import "moment/locale/ru";

class PostForm extends Component {
  constructor(props) {
    super(props);
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onSave(values);
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      isNew,
      errors,
      isLoading,
      fieldSet,
      onClose,
      // general
      onChangeName,
      onChangePresentation,
      onChangeOwnerType,
      onChangeFieldSetType,
      onSaveOption,
      onDeleteOptions,
      // sidebar
      onChangeIsActive,
      onChangeIsRequired,
    } = this.props;

    return (
      <Form layout={"vertical"}>
        <Card
          loading={isLoading}
          title={
            <h2 style={{ marginBottom: 0 }}>{`Атрибут #${
              isNew ? "новый" : fieldSet.id
            } от ${moment(fieldSet.created_at).format("LLL")}`}</h2>
          }
          extra={[
            <Button key={1} onClick={onClose}>
              Закрыть
            </Button>,
            <Button
              key={2}
              onClick={this.onSubmit}
              type="primary"
              ghost
              style={{ marginLeft: "8px" }}
            >
              Сохранить
            </Button>
          ]}
        >
          <Col span={13}>
            <General
              getFieldDecorator={getFieldDecorator}
              fieldSet={fieldSet}
              onChangeName={onChangeName}
              onChangePresentation={onChangePresentation}
              onChangeOwnerType={onChangeOwnerType}
              onChangeFieldSetType={onChangeFieldSetType}
              onSaveOption={onSaveOption}
              onDeleteOptions={onDeleteOptions}
            />
          </Col>
          <Col span={1} />
          <Col span={10}>
            <Sidebar
              getFieldDecorator={getFieldDecorator}
              isActive={fieldSet.is_active}
              isRequired={fieldSet.is_required}
              onChangeIsActive={onChangeIsActive}
              onChangeIsRequired={onChangeIsRequired}
            />
          </Col>
        </Card>
      </Form>
    );
  }
}

PostForm.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  fieldSet: PropTypes.object,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
  router: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  form: PropTypes.object,
};

export default Form.create()(PostForm);
