// @flow
import React, { Component } from "react";
import { Row, Col, Form, Input, Tabs, Button } from "antd";
import Finance from "./Finance";
import Notifications from "./Notifications";
import Can from "../../rules/Can";
import moment from "moment";
import "moment/locale/ru";

const TabPane = Tabs.TabPane;
const FormItem = Form.Item;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startAt: moment().startOf("month").format("DD.MM.YYYY"),
      endAt: moment().endOf("month").format("DD.MM.YYYY"),
    };
  }

  onDeleteLocalStorage = () => {
    localStorage.removeItem("entityTasksFilters");
    localStorage.removeItem("ticketsFilters");
    localStorage.removeItem("ordersFilters");
    localStorage.removeItem("orderTasksFilters");
  };

  callback = (key) => {
    const { startAt, endAt } = this.state;
    if (key == "3") {
      this.props.fetchPayrolls(startAt, endAt);
    }
  };

  onChangePeriod = (value) => {
    const startAt = moment(value).startOf("month").format("DD.MM.YYYY");
    const endAt = moment(value).endOf("month").format("DD.MM.YYYY");
    this.setState(
      {
        startAt: startAt,
        endAt: endAt,
      },
      () => {
        this.props.fetchPayrolls(startAt, endAt);
      }
    );
  };

  render() {
    const { startAt, endAt } = this.state;
    const {
      currentUser,
      name,
      email,
      password,
      password_confirmation,
      getFieldDecorator,
      onChangeName,
      onChangeEmail,
      onChangePassword,
      onChangePasswordConfirmation,
      onUpdatePassword,
      // notifications
      notifyCommentSettings,
      onChangeNotifyTickets,
      onChangeNotifyEntityTasks,
      onChangeNotifyOrders,
      onChangeNotifyOrderTasks,
      onChangeNotifyProjects,
      // payrolls
      isLoadingPayrolls,
      payrolls,
      penalties,
    } = this.props;

    return (
      <React.Fragment>
        <Row gutter={16}>
          <Tabs defaultActiveKey={"1"} onChange={this.callback}>
            <TabPane tab="Основные данные" key="1">
              <Col span={12}>
                <FormItem label={"Имя"}>
                  {getFieldDecorator("name", {
                    onChange: onChangeName,
                    initialValue: name,
                    rules: [
                      {
                        required: true,
                        message: "Введите имя!",
                      },
                    ],
                  })(<Input />)}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label={"Email"}>
                  {getFieldDecorator("email", {
                    onChange: onChangeEmail,
                    initialValue: email,
                    rules: [
                      {
                        required: true,
                        message: "Введите email!",
                      },
                    ],
                  })(<Input />)}
                </FormItem>
              </Col>
              <Col span={12}>
                <Button onClick={this.onDeleteLocalStorage} type="primary">
                  Удалить локальное хранилище
                </Button>
              </Col>
            </TabPane>

            <TabPane tab="Смена пароля" key="2">
              <Row gutter={16}>
                <Col span={12}>
                  <FormItem label="Пароль">
                    {getFieldDecorator("password", {
                      onChange: onChangePassword,
                      initialValue: password,
                    })(<Input />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="Подтверждение пароля">
                    {getFieldDecorator("password_confirmation", {
                      onChange: onChangePasswordConfirmation,
                      initialValue: password_confirmation,
                    })(<Input />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Button onClick={onUpdatePassword} type="primary">
                    Сохранить пароль
                  </Button>
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="Расчеты" key="3">
              <Finance
                isLoadingPayrolls={isLoadingPayrolls}
                payrolls={payrolls}
                penalties={penalties}
                startAt={startAt}
                endAt={endAt}
                onChangePeriod={this.onChangePeriod}
              />
            </TabPane>
            <TabPane tab="Уведомления" key="4">
              <Notifications
                notifyCommentSettings={notifyCommentSettings}
                onChangeNotifyTickets={onChangeNotifyTickets}
                onChangeNotifyEntityTasks={onChangeNotifyEntityTasks}
                onChangeNotifyOrders={onChangeNotifyOrders}
                onChangeNotifyOrderTasks={onChangeNotifyOrderTasks}
                onChangeNotifyProjects={onChangeNotifyProjects}
              />
            </TabPane>
          </Tabs>
        </Row>
      </React.Fragment>
    );
  }
}

Header.propTypes = {};

export default Header;
