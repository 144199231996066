const initialState = {
  isAuthenticated: false,
  isRecovered: false,
  currentUser: {},
  authorize_urls: [],
  isLoading: true,
  errors: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "REQUEST_AUTHORIZE_URLS":
      return {
        ...state,
        isLoading: true
      };
    case "FETCH_AUTHORIZE_URLS":
      return {
        ...state,
        authorize_urls: action.response.authorize_urls,
        isLoading: false
      };
    case "AUTHENTICATION_REQUEST":
      return {
        ...state,
        isAuthenticated: false,
        isLoading: true
      };
    case "AUTHENTICATION_SUCCESS":
      return {
        ...state,
        isAuthenticated: true,
        currentUser: action.response.user,
        errors: false,
        isLoading: false
      };
    case "AUTHENTICATION_FAILURE":
      return {
        ...state,
        isAuthenticated: false,
        errors: true,
        isLoading: false
      };
    case "SIGNUP_FAILURE":
      return {
        ...state,
        errors: []
      };
    case "LOGOUT":
      return {
        ...state,
        isAuthenticated: false,
        currentUser: {}
      };

      case "RECOVERY_REQUEST":
      return {
        ...state,
        isRecovered: false,
        isLoading: true
      };
    case "RECOVERY_SUCCESS":
      return {
        ...state,
        isRecovered: true,
        errors: false,
        isLoading: false
      };
    case "RECOVERY_FAILURE":
      return {
        ...state,
        isRecovered: false,
        errors: true,
        isLoading: false
      };
    default:
      return state;
  }
}
