// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Select, Spin, Icon, Divider } from "antd";
import api from "../../api";
import _ from "lodash/array";

const Option = Select.Option;

class SelectFetch extends Component {
  static defaultProps = {
    showSearch: false,
    disabled: false
  };

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      value: null,
      fetching: false
    };
  }

  componentDidMount = () => {
    if (this.props.selected) {
      this.setState({
        data: [
          {
            value: this.props.selected.id,
            name: this.props.selected.name,
            extra: this.props.selected.extra
          }
        ],
        value: `${this.props.selected.id}`
      });
    }
  };

  componentDidUpdate = prevProps => {
    if (this.props.selected !== prevProps.selected) {
      if (this.props.selected) {
        this.setState({
          data: [
            {
              value: this.props.selected.id,
              name: this.props.selected.name,
              extra: this.props.selected.extra
            }
          ],
          value: `${this.props.selected.id}`
        });
      } else {
        this.setState({
          data: [
            {
              value: null,
              name: null,
              extra: null
            }
          ],
          value: ""
        });
      }
    }
  };

  onFocus = () => {
    this.setState({ data: [], fetching: true });

    api.fetch(`${this.props.url}`).then(response => {
      const data = response.data.map(item => ({
        name: `${item.name}`,
        value: `${item.id}`,
        extra: item.extra
      }));
      this.setState({ data, fetching: false });
    });
  };

  onChange = value => {
    // console.log(value);
    if (value == undefined) {
      this.setState(
        {
          value,
          selected: null,
          fetching: false
        },
        () => {
          return this.props.onChange
            ? this.props.onChange(this.state.selected)
            : null;
        }
      );
    }
    const idx = _.findIndex(this.state.data, ["value", value]);
    if (idx !== -1) {
      this.setState(
        {
          value,
          selected: {
            id: value,
            name: this.state.data[idx].name,
            extra: this.state.data[idx].extra
          },
          fetching: false
        },
        () => {
          return this.props.onChange
            ? this.props.onChange(this.state.selected)
            : null;
        }
      );
    }
  };

  onSearch = value => {
    api
      .fetch(`${this.props.url}&q=${encodeURIComponent(value)}`)
      .then(response => {
        const data = response.data.map(item => ({
          name: `${item.name}`,
          value: `${item.id}`,
          extra: item.extra
        }));
        this.setState({ data, fetching: false });
      });
  };

  renderOptions = (data, showSubtitle) => {
    if (showSubtitle) {
      return data.map(d => (
        <Option key={d.value} title={d.name} style={{ whiteSpace: "normal" }}>
          <div>
            <div>{d.name}</div>
            <div
              style={{
                fontSize: "13px",
                color: "#999",
                textOverflow: "text-overflow"
              }}
            >
              {d.extra ? d.extra.subtitle : null}
            </div>
          </div>
        </Option>
      ));
    } else {
      return data.map(d => (
        <Option key={d.value} title={d.name} style={{ whiteSpace: "normal" }}>
          {d.name}
        </Option>
      ));
    }
  };

  onAddItem = () => {
    this.props.onAddItem()
  }

  render() {
    const { fetching, data, value } = this.state;
    const {
      mode,
      placeholder,
      showSearch,
      showSubtitle,
      showFooter,
      disabled
    } = this.props;
    return (
      <Select
        disabled={disabled}
        mode={mode ? mode : "default"}
        allowClear
        showSearch={showSearch}
        onSearch={this.onSearch}
        value={value}
        placeholder={placeholder ? placeholder : "выберите значение"}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        filterOption={false}
        onFocus={this.onFocus}
        onChange={this.onChange}
        style={{ width: "100%" }}
        dropdownRender={menu => (
          <div>
            {menu}
            {showFooter ? (
              <React.Fragment>
                <Divider style={{ margin: "4px 0" }} />
                <div
                  style={{ padding: "4px 8px 8px", cursor: "pointer" }}
                  onMouseDown={e => e.preventDefault()}
                  onClick={this.onAddItem}
                >
                  <Icon type="plus" /> Добавить
                </div>
              </React.Fragment>
            ) : null}
          </div>
        )}
      >
        {this.renderOptions(data, showSubtitle)}
      </Select>
    );
  }
}

SelectFetch.propTypes = {
  url: PropTypes.string,
  mode: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.object,
  selected: PropTypes.object,
  onChange: PropTypes.func
};

export default SelectFetch;
