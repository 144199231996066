import update from "immutability-helper";
import moment from "moment";

const initialState = {
  entities: [],
  entity: {
    assignees: [],
    entity_services: [],
    attachments: [],
    entity_photos: [],
    entity_schemas: [],
    todo_templates: [],
    entity_notes: [],
    entity_extcodes: [],
  },
  entityActivities: [],
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1,
  },
  fetchParams: {
    search: null,
    sort: null,
    direction: null,
    page: 1,
    limit: 10,
    regions: null,
    customer: null,
    entityType: null,
    users: null,
    members: null,
    active: false,
  },
  entityTasks: [],
  isEntityTasksLoading: true,
  tickets: [],
  isTicketsLoading: true,
  orders: [],
  isOrdersLoading: true,
  projects: [],
  isProjectsLoading: true,
  errors: false,
  isLoading: true,
  isOpeningHoursLoading: false,
  isLoadingImages: false,
  isAttachmentLoading: false,
  isUploadingImageImage: true,
  isAssigneesLoading: false,
  isActivitiesLoading: true,
  isEntityNotesLoading: false,
};

function loadLocalFilters(fetchParams) {
  let localFetchParams = null;
  const entitiesFilters = localStorage.getItem("entitiesFilters");
  if (entitiesFilters) {
    localFetchParams = JSON.parse(entitiesFilters);
  } else {
    localStorage.setItem("entitiesFilters", JSON.stringify(fetchParams));
  }

  return localFetchParams;
}

export default function (state = initialState, action) {
  let localFetchParams = null;
  switch (action.type) {
    case "FETCH_ENTITIES_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_ENTITIES":
      return {
        ...state,
        entities: action.response.data,
        meta: action.response.meta,
        isLoading: false,
      };
    case "FETCH_ENTITY_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_ENTITY":
      return {
        ...state,
        entity: action.response.data,
        isLoading: false,
      };
    case "PATCH_ENTITY_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };
    case "PATCH_ENTITY":
      return {
        ...state,
        entity: action.response.data,
        isLoading: false,
        errors: false,
      };

    case "PATCH_ENTITY_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "POST_ENTITY_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "POST_ENTITY":
      return {
        ...state,
        errors: false,
        entity: action.response.data,
        isLoading: false,
      };

    case "POST_ENTITY_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "DELETE_ENTITY_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };
    case "DELETE_ENTITY":
      return {
        ...state,
        entities: action.response.data,
        isLoading: false,
        errors: false,
      };
    case "DELETE_ENTITY_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "ENTITY_NEW":
      return {
        ...state,
        errors: false,
        entity: {
          customer: action.customer,
          start: moment(),
          finish: moment(),
          attachments: [],
          entity_services: [],
          assignees: [],
          todo_templates: [],
          entity_extcodes: [],
          lat: null,
          lon: null,
        },
        isLoading: false,
      };

    case "REQUEST_ENTITY_SEARCH":
      return update(state, {
        fetchParams: { search: { $set: action.value } },
      });

    case "REQUEST_ENTITIES_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } },
      });

    case "REQUEST_ENTITIES_CHANGE_LIMIT":
      return update(state, {
        fetchParams: { limit: { $set: action.value }, page: { $set: 1 } },
      });

    case "REQUEST_ENTITIES_FILTER_ACTIVE":
      return update(state, {
        fetchParams: { active: { $set: action.value } },
      });

    case "ENTITIES_SORT_BY":
      return update(state, {
        fetchParams: {
          sort: { $set: action.field },
          direction: { $set: action.direction },
        },
      });

    //general
    case "ENTITY_SERVICE_CHANGE_START":
      return update(state, {
        entity: { start: { $set: action.value } },
      });

    case "ENTITY_SERVICE_CHANGE_FINISH":
      return update(state, {
        entity: { finish: { $set: action.value } },
      });

    case "ENTITY_CHANGE_IS_ORGANISATION":
      return update(state, {
        entity: {
          is_organisation: { $set: action.value },
          customer: { $set: null },
        },
      });

    case "ENTITY_SERVICE_CHANGE_CUSTOMER":
      return update(state, {
        entity: { customer: { $set: action.value } },
      });

    case "ENTITY_SERVICE_CHANGE_NAME":
      return update(state, {
        entity: { name: { $set: action.value } },
      });

    case "ENTITY_CHANGE_ENTITY_TYPE":
      return update(state, {
        entity: { entity_type: { $set: action.value } },
      });

    case "ENTITY_CHANGE_POSTAL_CODE":
      return update(state, {
        entity: { postal_code: { $set: action.value } },
      });

    case "ENTITY_CHANGE_CITY":
      return update(state, {
        entity: { city: { $set: action.value } },
      });

    case "ENTITY_CHANGE_STREET":
      return update(state, {
        entity: { street: { $set: action.value } },
      });

    case "ENTITY_CHANGE_BUILDING":
      return update(state, {
        entity: { building: { $set: action.value } },
      });

    case "ENTITY_CHANGE_LAT":
      return update(state, {
        entity: { lat: { $set: action.value } },
      });

    case "ENTITY_CHANGE_LON":
      return update(state, {
        entity: { lon: { $set: action.value } },
      });

    case "ENTITY_CHANGE_EXT_CODE":
      return update(state, {
        entity: { ext_code: { $set: action.value } },
      });

    case "ENTITY_SERVICE_CHANGE_TODO_TEMPLATES":
      return update(state, {
        entity: {
          todo_templates: {
            $set: action.values.length > 0 ? action.values : null,
          },
        },
      });

    //entity_services
    case "ENTITY_SERVICE_ADD":
      return update(state, {
        entity: { entity_services: { $push: [action.item] } },
      });

    case "ENTITY_SERVICE_SAVE":
      return update(state, {
        entity: { entity_services: { [action.idx]: { $set: action.item } } },
      });

    case "ENTITY_SERVICE_DELETE":
      return update(state, {
        entity: {
          entity_services: {
            [action.idx]: { _destroy: { $set: true } },
          },
        },
      });

    case "ENTITY_SERVICE_DESTROY":
      return update(state, {
        entity: {
          entity_services: { $splice: [[action.idx, 1]] },
        },
      });

    // attachments
    case "FETCH_ATTACHMENT_ENTITY_REQUEST":
      return {
        ...state,
        isAttachmentLoading: true,
      };

    case "FETCH_ENTITY_ATTACHMENTS":
      return update(state, {
        entity: { attachments: { $set: action.response.data } },
        isAttachmentLoading: { $set: false },
      });

    case "ENTITY_ATTACHMENT_UPLOAD":
      return update(state, {
        isAttachmentLoading: { $set: true },
      });

    case "ENTITY_ATTACHMENT_UPLOAD_SUCCEED":
      return update(state, {
        entity: { attachments: { $set: action.response.data } },
        errors: { $set: false },
        isAttachmentLoading: { $set: false },
      });

    case "ENTITY_ATTACHMENT_UPLOAD_FAILED":
      return update(state, {
        errors: { $set: true },
        isAttachmentLoading: { $set: false },
      });

    case "ATTACHMENT_DELETE_REQUEST":
      return {
        ...state,
        errors: false,
        isAttachmentLoading: true,
      };
    case "ATTACHMENT_DELETE_SUCCEED":
      return {
        ...state,
        errors: false,
        isAttachmentLoading: false,
      };
    case "ATTACHMENT_DELETE_FAILED":
      return {
        ...state,
        errors: true,
        isAttachmentLoading: false,
      };

    // entity_photos
    case "FETCH_ENTITY_PHOTOS_REQUEST":
      return {
        ...state,
        isLoadingImages: true,
      };
    case "FETCH_ENTITY_PHOTOS":
      return update(state, {
        entity: { entity_photos: { $set: action.response.data } },
        isLoadingImages: { $set: false },
      });

    case "ENTITY_PHOTO_UPLOAD":
      return update(state, {
        isUploadingImageImage: { $set: true },
      });

    case "ENTITY_PHOTO_UPLOAD_SUCCEED":
      return update(state, {
        entity: { entity_photos: { $set: action.response.data } },
        errors: { $set: false },
        isUploadingImageImage: { $set: false },
      });

    case "ENTITY_PHOTO_UPLOAD_FAILED":
      return update(state, {
        errors: { $set: true },
        isUploadingImageImage: { $set: false },
      });

    case "ENTITY_PHOTO_DELETE_REQUEST":
      return {
        ...state,
        isLoadingImages: true,
      };

    case "ENTITY_PHOTO_DELETE_SUCCEED":
      return update(state, {
        entity: { entity_photos: { $set: action.response.data } },
        errors: { $set: false },
        isLoadingImages: { $set: false },
      });

    case "ENTITY_PHOTO_DELETE_FAILED":
      return {
        ...state,
        errors: true,
        isLoadingImages: false,
      };

    // entity_schemas
    case "FETCH_ENTITY_SCHEMAS":
      return update(state, {
        entity: { entity_schemas: { $set: action.response.data } },
        isUploadingImage: { $set: false },
      });
    case "FETCH_ENTITY_SCHEMA_REQUEST":
      return {
        ...state,
        isUploadingImage: true,
      };

    case "ENTITY_SCHEMA_UPLOAD":
      return update(state, {
        isUploadingImage: { $set: true },
      });

    case "ENTITY_SCHEMA_UPLOAD_SUCCEED":
      return update(state, {
        entity: { entity_schemas: { $set: action.response.data } },
        errors: { $set: false },
        isUploadingImage: { $set: false },
      });

    case "ENTITY_SCHEMA_UPLOAD_FAILED":
      return update(state, {
        errors: { $set: true },
        isUploadingImage: { $set: false },
      });

    case "ENTITY_SCHEMA_DELETE_REQUEST":
      return {
        ...state,
        errors: false,
        isUploadingImage: true,
      };
    case "ENTITY_SCHEMA_DELETE_SUCCEED":
      return update(state, {
        entity: { entity_schemas: { $set: action.response.data } },
        errors: { $set: false },
        isLoadingImages: { $set: false },
      });

    case "ENTITY_SCHEMA_DELETE_FAILED":
      return {
        ...state,
        errors: true,
        isUploadingImage: false,
      };

    // activities
    case "FETCH_ENTITY_ACTIVITIES_REQUEST":
      return {
        ...state,
        isActivitiesLoading: true,
      };
    case "FETCH_ENTITY_ACTIVITIES":
      return update(state, {
        entityActivities: { $set: action.response.data },
        isActivitiesLoading: { $set: false },
        errors: { $set: false },
      });

    case "FETCH_ENTITY_ACTIVITIES_FAILURE":
      return {
        ...state,
        errors: true,
        isActivitiesLoading: false,
      };

    // assignees
    case "FETCH_ASSIGNEES_REQUEST":
      return {
        ...state,
        isAssigneesLoading: true,
      };
    case "FETCH_ASSIGNEES":
      return update(state, {
        entity: { assignees: { $set: action.response.data } },
        isAssigneesLoading: { $set: false },
      });

    case "FETCH_ASSIGNEES_FAILURE":
      return {
        ...state,
        errors: true,
        isAssigneesLoading: false,
      };

    case "POST_ASSIGNEE_REQUEST":
      return {
        ...state,
        errors: false,
        isAssigneesLoading: true,
      };
    case "POST_ASSIGNEE":
      return {
        ...state,
        errors: false,
        isAssigneesLoading: false,
      };

    case "POST_ASSIGNEE_FAILURE":
      return {
        ...state,
        errors: true,
        isAssigneesLoading: false,
      };

    // opening_hours
    case "PATCH_OPENING_HOURS_REQUEST":
      return {
        ...state,
        isOpeningHoursLoading: false,
        errors: true,
      };

    case "PATCH_OPENING_HOURS":
      return update(state, {
        entity: { opening_hours: { $set: action.response.data.opening_hours } },
        isOpeningHoursLoading: { $set: false },
      });

    case "PATCH_OPENING_HOURS_FAILURE":
      return {
        ...state,
        isOpeningHoursLoading: false,
        errors: true,
      };

    // entity_tasks
    case "FETCH_ENTITY_TASKS_REQUEST":
      return {
        ...state,
        isEntityTasksLoading: true,
      };
    case "FETCH_ENTITY_TASKS":
      return update(state, {
        entityTasks: { $set: action.response.data },
        isEntityTasksLoading: { $set: false },
      });

    case "FETCH_ENTITY_TASKS_FAILURE":
      return {
        ...state,
        errors: true,
        isEntityTasksLoading: false,
      };

    // tickets
    case "FETCH_ENTITY_TICKETS_REQUEST":
      return {
        ...state,
        isTicketsLoading: true,
      };
    case "FETCH_ENTITY_TICKETS":
      return update(state, {
        tickets: { $set: action.response.data },
        isTicketsLoading: { $set: false },
      });

    case "FETCH_ENTITY_TICKETS_FAILURE":
      return {
        ...state,
        errors: true,
        isTicketsLoading: false,
      };

    // orders
    case "FETCH_ORDERS_REQUEST":
      return {
        ...state,
        isOrdersLoading: true,
      };
    case "FETCH_ORDERS":
      return update(state, {
        orders: { $set: action.response.data },
        isOrdersLoading: { $set: false },
      });

    case "FETCH_ORDERS_FAILURE":
      return {
        ...state,
        errors: true,
        isOrdersLoading: false,
      };

    // projects
    case "FETCH_PROJECTS_REQUEST":
      return {
        ...state,
        isProjectsLoading: true,
      };
    case "FETCH_PROJECTS":
      return update(state, {
        projects: { $set: action.response.data },
        isProjectsLoading: { $set: false },
      });

    case "FETCH_PROJECTS_FAILURE":
      return {
        ...state,
        errors: true,
        isProjectsLoading: false,
      };

    // notes
    case "FETCH_ENTITY_NOTES_REQUEST":
      return {
        ...state,
        isEntityNotesLoading: true,
      };
    case "FETCH_ENTITY_NOTES":
      return update(state, {
        entity: { entity_notes: { $set: action.response.data } },
        isEntityNotesLoading: { $set: false },
      });

    case "FETCH_ENTITY_NOTES_FAILURE":
      return {
        ...state,
        errors: true,
        isEntityNotesLoading: false,
      };

    case "POST_ENTITY_NOTE_REQUEST":
      return {
        ...state,
        errors: false,
        isEntityNotesLoading: true,
      };
    case "POST_ENTITY_NOTE":
      return {
        ...state,
        errors: false,
        isEntityNotesLoading: false,
      };

    case "POST_ENTITY_NOTE_FAILURE":
      return {
        ...state,
        errors: true,
        isEntityNotesLoading: false,
      };

    //parameters
    case "ENTITY_CHANGE_SQUARE":
      return update(state, {
        entity: { square: { $set: action.value } },
      });

    case "ENTITY_CHANGE_ROOM":
      return update(state, {
        entity: { room: { $set: action.value } },
      });

    case "ENTITY_CHANGE_EQUIPMENT":
      return update(state, {
        entity: { equipment: { $set: action.value } },
      });

    case "ENTITY_CHANGE_COMPLEXITY":
      return update(state, {
        entity: { complexity: { $set: action.value } },
      });

    // extCodes
    case "ENTITY_EXT_CODE_ADD":
      return update(state, {
        entity: { entity_extcodes: { $push: [action.item] } },
      });

    case "ENTITY_EXT_CODE_DELETE":
      return update(state, {
        entity: {
          entity_extcodes: {
            [action.idx]: { _destroy: { $set: true } },
          },
        },
      });

    case "ENTITY_EXT_CODE_DESTROY":
      return update(state, {
        entity: {
          entity_extcodes: { $splice: [[action.idx, 1]] },
        },
      });

    // filters
    case "ENTITIES_CLEAR_FILTERS":
      localStorage.removeItem("ordersFilters");

      return update(state, {
        fetchParams: {
          users: { $set: null },
          members: { $set: null },
          regions: { $set: null },
          active: { $set: false },
        },
      });

    case "ENTITIES_SET_FILTERS":
      localFetchParams = loadLocalFilters(state.fetchParams);
      if (localFetchParams) {
        localFetchParams.search = null;
        return {
          ...state,
          fetchParams: localFetchParams,
        };
      } else {
        return {
          ...state,
        };
      }

    case "ENTITIES_FILTER_USERS":
      return update(state, {
        fetchParams: {
          users: { $set: action.values.length > 0 ? action.values : null },
          page: { $set: 1 },
        },
      });

    case "ENTITIES_FILTER_MEMBERS":
      return update(state, {
        fetchParams: {
          members: { $set: action.values.length > 0 ? action.values : null },
          page: { $set: 1 },
        },
      });

    case "ENTITIES_FILTER_REGIONS":
      return update(state, {
        fetchParams: {
          regions: { $set: action.values.length > 0 ? action.values : null },
          page: { $set: 1 },
        },
      });

    case "ENTITIES_FILTER_CUSTOMER":
      return update(state, {
        fetchParams: {
          customer: { $set: action.value ? action.value : null },
          page: { $set: 1 },
        },
      });

    case "ENTITIES_FILTER_ENTITY_TYPE":
      return update(state, {
        fetchParams: {
          entityType: { $set: action.value ? action.value : null },
          page: { $set: 1 },
        },
      });

    case "ENTITIES_FILTER_ACTIVE":
      return update(state, {
        fetchParams: {
          active: { $set: action.value },
          page: { $set: 1 },
        },
      });

    default:
      return state;
  }
}
