// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, List, Form, Divider, Avatar, Select, Checkbox } from "antd";
import moment from "moment";
import "moment/locale/ru";
import _ from "lodash/array";
import SelectFetch from "../../../components/SelectFetch";
import Documents from "../../../components/Base64Documents";
import Assignees from "../../../components/Assignees";

import Uploads from "../../../components/Uploads";

const FormItem = Form.Item;

const Option = Select.Option;

class Sidebar extends Component {
  render() {
    const {
      isNew,
      currentUser,
      ticketId,
      isUploading,
      getFieldDecorator,
      isReplaced,
      isTicketReplaceAvailable,
      user,
      member,
      attachments,
      status,
      ticketPriority,
      incident,
      onChangeStatus,
      onChangeReplaced,
      onChangePiority,
      onChangeIncident,
      onUploadAttachment,
      onDeleteAttachment,
      // preUpload
      tempAttachments,
      onTempUpload,
      onDeleteTempAttachment,
      // assignees
      customer,
      organisationAssignees,
      supervisorAssignees,
      customerAssignees,
    } = this.props;

    return (
      <React.Fragment>
        {isNew ? null : (
          <Row gutter={10}>
            {isTicketReplaceAvailable ? (
              <Col span={12}>
                <FormItem>
                  {getFieldDecorator("isReplaced", {
                    onChange: onChangeReplaced,
                    valuePropName: "checked",
                    initialValue: isReplaced,
                  })(<Checkbox>Требуется замена</Checkbox>)}
                </FormItem>
              </Col>
            ) : null}

            <Col md={{ span: 24 }} xs={{ span: 24 }}>
              <FormItem label={"Статус"}>
                {getFieldDecorator("status", {
                  initialValue: status ? status : null,
                })(
                  <SelectFetch
                    placeholder="Статус"
                    onChange={onChangeStatus}
                    selected={status ? status : null}
                    url={`/tickets/${ticketId}/statuses`}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
        )}

        <Row gutter={10}>
          <Col span={12}>
            <FormItem label={"Приоритет"}>
              {getFieldDecorator("ticketPriority", {
                initialValue: ticketPriority ? ticketPriority : null,
                rules: [
                  {
                    required: true,
                    message: "Введите приоритет!",
                  },
                ],
              })(
                <SelectFetch
                  showSearch
                  placeholder="Приоритет"
                  onChange={onChangePiority}
                  selected={ticketPriority ? ticketPriority : null}
                  url={`/ticket_priorities/search/?${
                    customer ? `customer_id=${customer.id}` : null
                  }`}
                />
              )}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label={"Тип инцидента"}>
              {getFieldDecorator("incident", {
                initialValue: incident,
                onChange: onChangeIncident,
                rules: [
                  {
                    required: true,
                    message: "Введите тип инцидента!",
                  },
                ],
              })(
                <Select
                  placeholder="Тип инцидента"
                  optionFilterProp="children"
                  filterOption={false}
                >
                  <Option value={"by_ticket"}>выезд по заявке</Option>
                  <Option value={"by_schedule"}>выезд по регламенту</Option>
                  <Option value={"by_service"}>сервис</Option>
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>

        <Divider>Инициатор заявки</Divider>
        <List>
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar src={user ? user.avatar : member.avatar} />}
              title={user ? user.name : member.name}
              description={user ? user.title : member.title}
            />
            <div>{user ? user.department : null}</div>
          </List.Item>
        </List>
        <Row gutter={10}>
          <Col span={24}>
            <Assignees
              title={"Наблюдатели"}
              isReadOnly={true}
              isAssigneesLoading={false}
              assigneeType="supervisor"
              customer={customer}
              assignees={supervisorAssignees}
              onCreateAssignee={() => null}
              onDeleteAssignee={() => null}
            />
          </Col>
        </Row>

        <Row gutter={10}>
          <Col span={24}>
            <Assignees
              isReadOnly={true}
              isAssigneesLoading={false}
              assigneeType="organisation"
              customer={customer}
              assignees={organisationAssignees}
              onCreateAssignee={() => null}
              onDeleteAssignee={() => null}
            />
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={24}>
            <Assignees
              title={"От заказчика"}
              isReadOnly={true}
              isAssigneesLoading={false}
              assigneeType="customer"
              customer={customer}
              assignees={customerAssignees}
              onCreateAssignee={() => null}
              onDeleteAssignee={() => null}
            />
          </Col>
        </Row>
        {isTicketReplaceAvailable ? (
          <Row gutter={10}>
            <Col span={24}>
              {isNew ? (
                <Uploads
                  title={"Файлы"}
                  currentUser={currentUser}
                  onUpload={onTempUpload}
                  isUploading={isUploading}
                  attachments={tempAttachments}
                  onDeleteAttachment={onDeleteTempAttachment}
                />
              ) : isReplaced ? (
                <Documents
                  title={"Дефектовочный акт"}
                  currentUser={currentUser}
                  attachmentType={2}
                  onUpload={onUploadAttachment}
                  isUploading={isUploading}
                  attachments={attachments}
                  onDeleteAttachment={onDeleteAttachment}
                />
              ) : null}
            </Col>
          </Row>
        ) : null}
      </React.Fragment>
    );
  }
}
Sidebar.propTypes = {
  getFieldDecorator: PropTypes.func,
};

export default Sidebar;
