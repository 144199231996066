// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Input, Col, Row, Select } from "antd";
import SelectMultiFetch from "../../../components/SelectMultiFetch";

const FormItem = Form.Item;
const { TextArea } = Input;
const Option = Select.Option;

class General extends Component {
  constructor(props) {
    super(props);
    this.state = { content: "" };
  }


  render() {
    const {
      getFieldDecorator,
      todoTemplate,
      onChangeName,
      onChangeDescription,
      onChangeTodoType,
      onChangeServices,
    } = this.props;

    const todoType = todoTemplate.todo_type;
    const services = todoTemplate.services || [];

    return (
      <Row gutter={16}>
        <Col span={16}>
          <FormItem label={"Наименование"}>
            {getFieldDecorator("name", {
              onChange: onChangeName,
              initialValue: todoTemplate.name,
              rules: [
                {
                  required: true,
                  message: "Введите наименование!",
                },
              ],
            })(<Input />)}
          </FormItem>
        </Col>

        <Col span={8}>
          <FormItem label={"Тип"}>
            {getFieldDecorator("todo_type", {
              onChange: onChangeTodoType,
              initialValue: todoTemplate.todo_type,
            })(
              <Select
                style={{ width: "100%" }}
                placeholder="Тип"
                optionFilterProp="children"
                filterOption={false}
              >
                <Option value={1}>регламент</Option>
                <Option value={2}>задачи</Option>
                <Option value={3}>внесение изменений в инф. системы</Option>
              </Select>
            )}
          </FormItem>
        </Col>

        <Col span={24}>
          <FormItem label={"Описание"}>
            {getFieldDecorator("description", {
              onChange: onChangeDescription,
              initialValue: todoTemplate.description,
              rules: [
                {
                  required: true,
                  message: "Введите описание!",
                },
              ],
            })(<TextArea autoSize={{ minRows: 1, maxRows: 6 }} />)}
          </FormItem>
        </Col>

        {todoType === 3 ? (
          <Col span={24}>
            <FormItem label={"Разделы"}>
              {getFieldDecorator("services", {
                initialValue: services ?? [],
              })(
                <SelectMultiFetch
                  showSearch
                  placeholder="Разделы"
                  onChange={onChangeServices}
                  selected={services ?? []}
                  url={`/services/search/?`}
                />
              )}
            </FormItem>
          </Col>
        ) : null}
      </Row>
    );
  }
}

General.propTypes = {
  entity: PropTypes.object,
  onChangeDate: PropTypes.func,
  onChangeAssignee: PropTypes.func,
  onChangeAgreement: PropTypes.func,
  onChangeAgreementDate: PropTypes.func,
  onChangeAdditional: PropTypes.func,
  onChangeComment: PropTypes.func,
  onChangeNotice: PropTypes.func,
};

export default General;
