// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Tabs } from "antd";
import Documents from "../../../components/Base64Documents";
import Comments from "./Comments";
import ListTasks from "./ListTasks";
import Schedules from "./Schedules";
import Can from "../../../rules/Can";
import Finance from "./Finance";

const { TabPane } = Tabs;

class Pane extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: "1",
    };
  }

  onChange = (activeKey) => {
    this.setState({ activeKey });
    if (activeKey == 3) {
      this.props.fetchSchedules();
    }
  };

  render() {
    const {
      getFieldDecorator,
      errors,
      currentUser,
      order,
      // comments
      isCommentsLoading,
      onCreateComment,
      // tasks
      isOrderTasksLoading,
      onCreateOrderTask,
      // schedules
      isSchedulesLoading,
      onSaveSchedule,
      onDeleteSchedules,
      // finance
      onChangeProductPrice,
      onChangeServicePrice,
      onChangePenalty,
      onChangePayment,
      // attachments
      isAttachmentLoading,
      attachments,
      onDeleteAttachment,
      onUploadAttachment,
      commentAttachments,
      onUploadCommentAttachment,
      onDeleteCommentAttachment,
    } = this.props;

    return (
      <Can
        role={currentUser.role}
        perform="orders:finance"
        yes={() => (
          <Tabs defaultActiveKey="1" onChange={this.onChange}>
            <TabPane tab="Комментарии" key="1">
              <Row>
                <Comments
                  currentUser={currentUser}
                  getFieldDecorator={getFieldDecorator}
                  isCommentsLoading={isCommentsLoading}
                  errors={errors}
                  onCreateComment={onCreateComment}
                  comments={order.comments}
                  commentAttachments={commentAttachments}
                  onUploadCommentAttachment={onUploadCommentAttachment}
                  onDeleteCommentAttachment={onDeleteCommentAttachment}
                />
              </Row>
            </TabPane>
            {order.is_order_tasks_available ? (
              <TabPane tab="Задачи" key="2">
                <Row>
                  <ListTasks
                    getFieldDecorator={getFieldDecorator}
                    isOrderTasksLoading={isOrderTasksLoading}
                    errors={errors}
                    onCreateOrderTask={onCreateOrderTask}
                    tasks={order.order_tasks}
                    company={order.company}
                    isOrganisation={order.is_organisation}
                    isSupplier={order.is_supplier}
                  />
                </Row>
              </TabPane>
            ) : null}
            <TabPane tab="График работ" key="3">
              <Row>
                <Schedules
                  getFieldDecorator={getFieldDecorator}
                  isSchedulesLoading={isSchedulesLoading}
                  errors={errors}
                  onSaveSchedule={onSaveSchedule}
                  onDeleteSchedules={onDeleteSchedules}
                  schedules={order.schedules}
                />
              </Row>
            </TabPane>
            <TabPane tab="Файлы" key="4">
              <Row>
                <Documents
                  currentUser={currentUser}
                  title={""}
                  isUploading={isAttachmentLoading}
                  attachments={attachments}
                  onUpload={onUploadAttachment}
                  onDeleteAttachment={onDeleteAttachment}
                />
              </Row>
            </TabPane>
            <TabPane tab="Финансы" key="5">
              <Finance
                currentUser={currentUser}
                getFieldDecorator={getFieldDecorator}
                productPrice={order.product_price}
                servicePrice={order.service_price}
                penalty={order.penalty}
                payment={order.payment}
                onChangeProductPrice={onChangeProductPrice}
                onChangeServicePrice={onChangeServicePrice}
                onChangePenalty={onChangePenalty}
                onChangePayment={onChangePayment}
              />
            </TabPane>
          </Tabs>
        )}
        no={() => (
          <Tabs defaultActiveKey="1" onChange={this.onChange}>
            <TabPane tab="Комментарии" key="1">
              <Row>
                <Comments
                  getFieldDecorator={getFieldDecorator}
                  isCommentsLoading={isCommentsLoading}
                  errors={errors}
                  onCreateComment={onCreateComment}
                  comments={order.comments}
                />
              </Row>
            </TabPane>
            {order.is_order_tasks_available ? (
              <TabPane tab="Задачи" key="2">
                <Row>
                  <ListTasks
                    getFieldDecorator={getFieldDecorator}
                    isOrderTasksLoading={isOrderTasksLoading}
                    errors={errors}
                    onCreateOrderTask={onCreateOrderTask}
                    tasks={order.tasks}
                    company={order.company}
                    isOrganisation={order.is_organisation}
                    isSupplier={order.is_supplier}
                  />
                </Row>
              </TabPane>
            ) : null}

            <TabPane tab="График работ" key="3">
              <Row>
                <Schedules
                  getFieldDecorator={getFieldDecorator}
                  isSchedulesLoading={isSchedulesLoading}
                  errors={errors}
                  onSaveSchedule={onSaveSchedule}
                  onDeleteSchedules={onDeleteSchedules}
                  schedules={order.schedules}
                />
              </Row>
            </TabPane>
            <TabPane tab="Файлы" key="4">
              <Row>
                <Documents
                  currentUser={currentUser}
                  title={""}
                  isUploading={isAttachmentLoading}
                  attachments={attachments}
                  onUploadAttachment={onUploadAttachment}
                  onDeleteAttachment={onDeleteAttachment}
                />
              </Row>
            </TabPane>
          </Tabs>
        )}
      />
    );
  }
}

Pane.propTypes = {
  order: PropTypes.object,
};
export default Pane;
