import api from "../api";
const Actions = {};

//field_sets
Actions.fetchFieldSets = (contex, subcontext) => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_FIELD_SETS_REQUEST" });
    const params = getState().fieldSets.fetchParams;
    api
      .fetch("/field_sets", params)
      .then((response) => {
        dispatch({ type: "FETCH_FIELD_SETS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_FIELD_SETS_FAILURE", response });
      });
  };
};

Actions.fetchFieldSet = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_FIELD_SET_REQUEST" });
    api
      .fetch(`/field_sets/${id}`)
      .then((response) => {
        dispatch({ type: "FETCH_FIELD_SET", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_FIELD_SET_FAILURE", response });
      });
  };
};

Actions.onNew = () => {
  return (dispatch) => {
    dispatch({
      type: "FIELD_SET_NEW",
    });
  };
};

Actions.onUpdate = (id) => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_FIELD_SET_REQUEST" });
    const fieldSet = getState().fieldSets.fieldSet;
    const options = fieldSet.options.map((item) => {
      return {
        id: item.is_exist ? item.id : null,
        position: item.position,
        value: item.value,
        _destroy: item._destroy,
      };
    });

    const data = {
      field_set: {
        is_active: fieldSet.is_active,
        owner_type: fieldSet.owner_type,
        name: fieldSet.name,
        presentation: fieldSet.presentation,
        field_set_type: fieldSet.field_set_type,
        default_value: fieldSet.default_value,
        is_required: fieldSet.is_required,
        field_set_options_attributes: options,
      },
    };

    return api
      .patch(`/field_sets/${id}`, data)
      .then((response) => {
        dispatch({ type: "PATCH_FIELD_SET", response });
        api.openNotification("success", "Атрибут", "Успешно обновлен!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Атрибут", error);
          });
        }
        dispatch({ type: "PATCH_FIELD_SET_FAILURE", response });
      });
  };
};

Actions.onCreate = () => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_FIELD_SET_REQUEST" });
    const fieldSet = getState().fieldSets.fieldSet;
    const options = fieldSet.options.map((item) => {
      return {
        id: item.is_exist ? item.id : null,
        position: item.position,
        value: item.value,
        _destroy: item._destroy,
      };
    });

    const data = {
      field_set: {
        is_active: fieldSet.is_active,
        owner_type: fieldSet.owner_type,
        name: fieldSet.name,
        presentation: fieldSet.presentation,
        field_set_type: fieldSet.field_set_type,
        default_value: fieldSet.default_value,
        is_required: fieldSet.is_required,
        field_set_options_attributes: options,
      },
    };

    return api
      .post(`/field_sets`, data)
      .then((response) => {
        dispatch({ type: "POST_FIELD_SET", response });
        api.openNotification("success", "Атрибут", "Успешно создан!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Атрибут", error);
          });
        }
        dispatch({ type: "POST_FIELD_SET_FAILURE", response });
      });
  };
};

Actions.onDelete = (id) => {
  return (dispatch) => {
    dispatch({ type: "DELETE_FIELD_SET_REQUEST" });
    return api
      .delete(`/field_sets/${id}`)
      .then((response) => {
        api.openNotification("success", "Атрибут", "Успешно удален!");
        dispatch({ type: "DELETE_FIELD_SET", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Атрибут", error);
          });
        }
        dispatch({
          type: "DELETE_FIELD_SET_FAILURE",
        });
      });
  };
};

Actions.onSearch = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_FIELD_SET_SEARCH",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangePage = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_FIELD_SET_CHANGE_PAGE",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onSortBy = (field, direction) => {
  return (dispatch) => {
    dispatch({
      type: "FIELD_SETS_SORT_BY",
      field: field,
      direction: direction,
    });
    return Promise.resolve();
  };
};

Actions.onChangeLimit = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_FIELD_SET_CHANGE_LIMIT",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onSortBy = (field, direction) => {
  return (dispatch) => {
    dispatch({
      type: "FIELD_SETS_SORT_BY",
      field: field,
      direction: direction,
    });
    return Promise.resolve();
  };
};

// form
Actions.onChangeName = (value) => {
  return (dispatch) => {
    dispatch({
      type: "FIELD_SET_CHANGE_NAME",
      value: value,
    });
  };
};

Actions.onChangePresentation = (value) => {
  return (dispatch) => {
    dispatch({
      type: "FIELD_SET_CHANGE_PRESENTATION",
      value: value,
    });
  };
};

Actions.onChangeOwnerType = (value) => {
  return (dispatch) => {
    dispatch({
      type: "FIELD_SET_CHANGE_OWNER_TYPE",
      value: value,
    });
  };
};

Actions.onChangeFieldSetType = (value) => {
  return (dispatch) => {
    dispatch({
      type: "FIELD_SET_CHANGE_FIELD_SET_TYPE",
      value: value,
    });
  };
};

// sidebar
Actions.onChangeIsActive = (value) => {
  return (dispatch) => {
    dispatch({
      type: "FIELD_SET_CHANGE_IS_ACTIVE",
      value: value,
    });
  };
};

Actions.onChangeIsRequired = (value) => {
  return (dispatch) => {
    dispatch({
      type: "FIELD_SET_CHANGE_IS_REQUIRED",
      value: value,
    });
  };
};

// options
Actions.onCreateOption = (item) => {
  return (dispatch) => {
    dispatch({
      type: "FIELD_SET_OPTON_CREATE",
      item: item,
    });
  };
};

Actions.onUpdateOption = (idx, item) => {
  return (dispatch) => {
    dispatch({
      type: "FIELD_SET_OPTON_UPDATE",
      idx: idx,
      item: item,
    });
  };
};

Actions.onDeleteOption = (idx) => {
  return (dispatch) => {
    dispatch({
      type: "FIELD_SET_OPTON_DELETE",
      idx: idx,
    });
  };
};

Actions.onDestroyOption = (idx) => {
  return (dispatch) => {
    dispatch({
      type: "FIELD_SET_OPTON_DESTROY",
      idx: idx,
    });
  };
};

export default Actions;
