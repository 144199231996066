import update from "immutability-helper";
import _ from "lodash/array";
import moment from "moment";

const initialState = {
projectStages: [],
projectStage: {},
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1
  },
  fetchParams: {
    status: null,
    search: null,
    page: 1,
    limit: 15
  },
  errors: false,
  isLoading: false,
  isModalVisible: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "FETCH_PROJECT_STAGES_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "FETCH_PROJECT_STAGES":
      return {
        ...state,
        projectStages: action.response.data,
        meta: action.response.meta,
        isLoading: false
      };
    case "FETCH_PROJECT_STAGE_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "FETCH_PROJECT_STAGE":
      return {
        ...state,
        projectStage: action.response.data,
        isLoading: false,
        isModalVisible: true
      };
    case "PATCH_PROJECT_STAGE_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "PATCH_PROJECT_STAGE":
      return {
        ...state,
        projectStage: action.response.data,
        isLoading: false,
        isModalVisible: false
      };

    case "POST_PROJECT_STAGE_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "POST_PROJECT_STAGE":
      return {
        ...state,
        errors: false,
        projectStage: {},
        isLoading: false,
        isModalVisible: false
      };

    case "POST_PROJECT_STAGE_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false
      };

    case "PROJECT_STAGE_ADD":
      return {
        ...state,
        projectStage: {
          name: null
        },
        errors: false,
        isLoading: false,
        isModalVisible: true
      };

    case "DELETE_PROJECT_STAGE_REQUEST":
      return {
        ...state,
        isLoading: true
      };

    case "DELETE_PROJECT_STAGE":
      return {
        ...state,
        isLoading: false
      };
    case "DELETE_PROJECT_STAGE_FAILURE":
      return {
        ...state,
        isLoading: false
      };
    case "REQUEST_PROJECT_STAGE_SEARCH":
      return update(state, {
        fetchParams: { search: { $set: action.value } }
      });

    case "REQUEST_PROJECT_STAGE_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } }
      });

    case "PROJECT_STAGE_TOGGLE_VISIBLE":
      return {
        ...state,
        isModalVisible: !state.isModalVisible
      };

    case "PROJECT_STAGE_CHANGE_NAME":
      return update(state, {
        projectStage: {
          name: { $set: action.value }
        }
      });

    case "PROJECT_STAGE_CHANGE_DESCRIPTION":
      return update(state, {
        projectStage: {
            description: { $set: action.value }
        }
      });

    case "PROJECT_STAGE_CHANGE_POSITION":
      return update(state, {
        projectStage: {
            position: { $set: action.value }
        }
      });

    default:
      return state;
  }
}
