// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Form, Input, InputNumber } from "antd";
import moment from "moment";
import "moment/locale/ru";

const FormItem = Form.Item;

class Header extends Component {
  render() {
    const {
      getFieldDecorator,
      payrollSchema,
      onChangeName,
      onChangeDescription,
      onChangeOrders
    } = this.props;


    return (
      <React.Fragment>
        <Row gutter={16} style={{ paddingBottom: 15 }}>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <FormItem label={"Наименование"}>
              {getFieldDecorator("name", {
                onChange: onChangeName,
                initialValue: payrollSchema.name,
                rules: [
                  {
                    required: true,
                    message: "Пожалуйста, введите наименование!"
                  }
                ]
              })(<Input />)}
            </FormItem>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormItem label={"Описание"}>
              {getFieldDecorator("description", {
                onChange: onChangeDescription,
                initialValue: payrollSchema.description,
                rules: [
                  {
                    required: true,
                    message: "Пожалуйста, введите описание!"
                  }
                ]
              })(<Input />)}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16} style={{ paddingBottom: 15 }}>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <FormItem label={"Заказы, %"}>
              {getFieldDecorator("orders", {
                onChange: onChangeOrders,
                initialValue: payrollSchema.orders
              })(<InputNumber min={0} style={{ width: "100%" }} />)}
            </FormItem>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

Header.propTypes = {};

export default Header;
