import api from "../api";
import { saveAs } from "file-saver";

const Actions = {};

Actions.setTicketStatDiffFetchPeriodType = (value) => {
  return dispatch => {
    dispatch({
      type: 'SET_TICKET_STAT_DIFF_FETCH_PERIOD_TYPE',
      value,
    });

    return Promise.resolve();
  };
};

Actions.setTicketStatDiffThisFetchPeriodDates = (periodFrom, periodTo) => {
  return dispatch => {
    dispatch({
      type: 'SET_TICKET_STAT_DIFF_THIS_FETCH_PERIOD_DATES',
      periodFrom,
      periodTo,
    });

    return Promise.resolve();
  };
};

Actions.setTicketStatDiffThatFetchPeriodDates = (periodFrom, periodTo) => {
  return dispatch => {
    dispatch({
      type: 'SET_TICKET_STAT_DIFF_THAT_FETCH_PERIOD_DATES',
      periodFrom,
      periodTo,
    });

    return Promise.resolve();
  };
};

Actions.setTicketStatDiffFetchFilterReplacementType = (value) => {
  return dispatch => {
    dispatch({
      type: 'SET_TICKET_STAT_DIFF_FETCH_FILTER_REPLACEMENT_TYPE',
      value,
    });

    return Promise.resolve();
  };
};

Actions.setTicketStatDiffFetchFilterCustomer = (value) => {
  return dispatch => {
    dispatch({
      type: 'SET_TICKET_STAT_DIFF_FETCH_FILTER_CUSTOMER',
      value,
    });

    return Promise.resolve();
  };
};

Actions.setTicketStatDiffFetchFilterEntity = (value) => {
  return dispatch => {
    dispatch({
      type: 'SET_TICKET_STAT_DIFF_FETCH_FILTER_ENTITY',
      value,
    });

    return Promise.resolve();
  };
};

Actions.setTicketStatDiffFetchFilterServices = (values) => {
  return dispatch => {
    dispatch({
      type: 'SET_TICKET_STAT_DIFF_FETCH_FILTER_SERVICES',
      values,
    });

    return Promise.resolve();
  };
};

Actions.fetchTicketStatDiff = () => {
  return (dispatch, getState) => {
    dispatch({
      type: 'FETCH_TICKET_STAT_DIFF_REQUEST'
    });

    const dates = getState().analytics.ticketStatDiffFetchPeriodDates;
    const filters = getState().analytics.ticketStatDiffFetchFilters;
    const data = {
      thisPeriodFrom: dates.thisPeriodFrom,
      thisPeriodTo: dates.thisPeriodTo,
      thatPeriodFrom: dates.thatPeriodFrom,
      thatPeriodTo: dates.thatPeriodTo,
      is_replaced:
        filters.replacementType === 'with' ? true :
          filters.replacementType === 'without' ? false :
            null,
      customer: filters.customer?.id,
      entity: filters.entity?.id,
      services: filters.services.map((it) => it.id),
    }

    return api
      .fetch(`/analytics/ticket_stat_diff`, data)
      .then(response => {
        dispatch({
          type: 'FETCH_TICKET_STAT_DIFF_SUCCESS',
          response
        });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({
          type: 'FETCH_TICKET_STAT_DIFF_FAILURE',
          response
        });
      });
  };
};


Actions.setTicketStatDistributionFetchDates = (dateFrom, dateTo) => {
  return dispatch => {
    dispatch({
      type: 'SET_TICKET_STAT_DISTRIBUTION_FETCH_DATES',
      dateFrom,
      dateTo,
    });

    return Promise.resolve();
  };
};

Actions.setTicketStatDistributionFetchResolution = (value) => {
  return dispatch => {
    dispatch({
      type: 'SET_TICKET_STAT_DISTRIBUTION_FETCH_RESOLUTION',
      value,
    });

    return Promise.resolve();
  };
};

Actions.fetchTicketStatDistribution = () => {
  return (dispatch, getState) => {
    dispatch({
      type: 'FETCH_TICKET_STAT_DISTRIBUTION_REQUEST'
    });

    const resolution = getState().analytics.ticketStatDistributionFetchResolution;
    const dates = getState().analytics.ticketStatDistributionFetchDates;
    const filters = getState().analytics.ticketStatDistributionFetchFilters;
    const data = {
      aggregateFrom: dates.analyzeFrom,
      aggregateTo: dates.analyzeTo,
      aggregateBy: resolution,
      is_replaced:
        filters.replacementType === 'with' ? true :
          filters.replacementType === 'without' ? false :
            null,
      customer: filters.customer?.id,
      entity: filters.entity?.id,
      services: filters.services.map((it) => it.id),
    }

    return api
      .fetch(`/analytics/ticket_stat_distribution`, data)
      .then(response => {
        dispatch({
          type: 'FETCH_TICKET_STAT_DISTRIBUTION_SUCCESS',
          response
        });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({
          type: 'FETCH_TICKET_STAT_DISTRIBUTION_FAILURE',
          response
        });
      });
  };
};


Actions.setTicketsForStatTypeFetchStatType = (value) => {
  return dispatch => {
    dispatch({
      type: 'SET_TICKETS_FOR_STAT_TYPE_FETCH_STAT_TYPE',
      value,
    });

    return Promise.resolve();
  };
};

Actions.setTicketsForStatTypeFetchDates = (dateFrom, dateTo) => {
  return dispatch => {
    dispatch({
      type: 'SET_TICKETS_FOR_STAT_TYPE_FETCH_DATES',
      dateFrom,
      dateTo,
    });

    return Promise.resolve();
  };
};

Actions.fetchTicketsForStatType = () => {
  return (dispatch, getState) => {
    dispatch({
      type: 'FETCH_TICKETS_FOR_STAT_TYPE_REQUEST'
    });

    const statType = getState().analytics.ticketsForStatTypeFetchStatType;
    const dateFrom = getState().analytics.ticketsForStatTypeFetchDateFrom;
    const dateTo = getState().analytics.ticketsForStatTypeFetchDateTo;
    const filters = getState().analytics.ticketStatDistributionFetchFilters;
    const data = {
      statType: statType,
      dateFrom: dateFrom,
      dateTo: dateTo,
      is_replaced:
        filters.replacementType === 'with' ? true :
          filters.replacementType === 'without' ? false :
            null,
      customer: filters.customer?.id,
      entity: filters.entity?.id,
      services: filters.services.map((it) => it.id),
    }

    return api
      .fetch(`/analytics/tickets`, data)
      .then(response => {
        dispatch({
          type: 'FETCH_TICKETS_FOR_STAT_TYPE_SUCCESS',
          response
        });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({
          type: 'FETCH_TICKETS_FOR_STAT_TYPE_FAILURE',
          response
        });
      });
  };
};

Actions.downloadTicketsForStatType = () => {
  return (dispatch, getState) => {
    dispatch({
      type: 'DOWNLOAD_TICKETS_FOR_STAT_TYPE_REQUEST'
    });

    const statType = getState().analytics.ticketsForStatTypeFetchStatType;
    const dateFrom = getState().analytics.ticketsForStatTypeFetchDateFrom;
    const dateTo = getState().analytics.ticketsForStatTypeFetchDateTo;
    const filters = getState().analytics.ticketStatDistributionFetchFilters;
    const data = {
      statType: statType,
      dateFrom: dateFrom,
      dateTo: dateTo,
      is_replaced:
        filters.replacementType === 'with' ? true :
          filters.replacementType === 'without' ? false :
            null,
      customer: filters.customer?.id,
      entity: filters.entity?.id,
      services: filters.services.map((it) => it.id),
    }

    return api
      .fetchBlob(`/analytics/tickets.xlsx`, data)
      .then(response => {
        saveAs(response, `${statType}_ticket_analytics.xlsx`);
      });
  };
};


export default Actions;
