// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Form, Card, Steps, Button, message } from "antd";
import moment from "moment";
import "moment/locale/ru";
import Header from "./Header";
import Stages from "./Stages";
import AssigneesStep from "./AssigneesStep";

const { Step } = Steps;

class Content extends Component {

  onSubmitCurrentStep = (step) => {
    if (step == 0) {
      this.props.form.validateFields((err, values) => {
        if (err) {
          return;
        }
        this.props.nextStep();
      });
    }

    if (step == 1) {
      if (this.props.project.project_stages.length == 0) {
        message.error('Необходимо заполнить этапы!');
      } else {
        this.props.nextStep();
      }
    }
    
  }

  render() {
    const {
      currentUser,
      current,
      nextStep,
      prevStep,
      project,
      onCreate,
      // header
      onChangeCustomer,
      onChangeEntity,
      onChangeProjectType,
      onChangeNumber,
      onChangeDescription,
      onChangeAmount,
      // stages
      onSaveStage,
      onDeleteStages,
      // assignees
      onSaveAssignee,
      onDeleteAssignee,
    } = this.props;
    const { getFieldDecorator } = this.props.form;

    const organisationAssignees = project.assignees.filter(function (item) {
      return item.assignee_type == "organisation";
    });

    const customerAssignees = project.assignees.filter(function (item) {
      return item.assignee_type == "customer";
    });

    const isSubmitDisable =
      project.customer == null ||
      project.entity == null ||
      project.number == null ||
      project.project_stages.length == 0 ||
      project.assignees.length == 0;

    const steps = [
      {
        title: "Начало",
        content: (
          <Header
            project={project}
            getFieldDecorator={getFieldDecorator}
            onChangeCustomer={onChangeCustomer}
            onChangeEntity={onChangeEntity}
            onChangeProjectType={onChangeProjectType}
            onChangeNumber={onChangeNumber}
            onChangeDescription={onChangeDescription}
            onChangeAmount={onChangeAmount}
          />
        ),
        description: "Данные о клиенте",
      },
      {
        title: "Этапы",
        content: (
          <Stages
            stages={project.project_stages}
            getFieldDecorator={getFieldDecorator}
            onSaveStage={onSaveStage}
            onDeleteStages={onDeleteStages}
          />
        ),
        description: "Этапы проекта",
      },
      {
        title: "Ответственные",
        content: (
          <AssigneesStep
            customer={project.customer}
            organisationAssignees={organisationAssignees}
            customerAssignees={customerAssignees}
            getFieldDecorator={getFieldDecorator}
            onSaveAssignee={onSaveAssignee}
            onDeleteAssignee={onDeleteAssignee}
          />
        ),
        description: "Ответственные лица",
      },
    ];

    return (
      <Form layout={"vertical"}>
        <Card>
          <Row gutter={16}>
            <Steps progressDot current={current}>
              {steps.map((item) => (
                <Step
                  key={item.title}
                  title={item.title}
                  description={item.description}
                />
              ))}
            </Steps>
          </Row>
          <Row gutter={16}>{steps[current].content}</Row>
          <Row gutter={16} style={{ paddingTop: 15 }}>
            {current < steps.length - 1 && (
              <Button type="primary" onClick={() => this.onSubmitCurrentStep(current)}>
                Далее
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button
                disabled={isSubmitDisable}
                type="primary"
                onClick={onCreate}
              >
                Готово
              </Button>
            )}
            {current > 0 && (
              <Button style={{ marginLeft: 8 }} onClick={() => prevStep()}>
                Назад
              </Button>
            )}
          </Row>
        </Card>
      </Form>
    );
  }
}

Content.propTypes = {
  entity: PropTypes.object,
};

export default Form.create()(Content);
