import React from "react";
import { List, Avatar } from "antd";
import UserInfo from "../UserInfo";

export default function User({ user = null }) {
  return user ? (
    <List>
      <List.Item>
        <List.Item.Meta
          avatar={<Avatar src={user.avatar ? user.avatar : null} />}
          title={
            user.name ? <UserInfo title={user.name} userId={user.id} /> : ""
          }
          description={user.title ? user.title : ""}
        />
        <div>{user.department ? user.department : ""}</div>
      </List.Item>
    </List>
  ) : null;
}
