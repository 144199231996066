// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, Checkbox, Col, Divider, Form, Radio, Row } from "antd";
import "moment/locale/ru";
import SelectMultiFetch from "../../../components/SelectMultiFetch";

const FormItem = Form.Item;

const radioStyle = {
  display: "block",
  height: "30px",
  lineHeight: "30px",
};

class Sidebar extends Component {
  onChange = (e) => {
    console.log("radio checked", e.target.value);
  };

  render() {
    const {
      getFieldDecorator,
      currentUser,
      taskTemplate,
      onChangeActive,
      onChangeHasTiming,
      onChangeHasTodos,
      onChangeHasAttachments,
      onChangeHasComments,
      onChangeHasSupervisorAssignees,
      onChangeHasUserAssignees,
      onChangeHasMemberAssignees,
      onChangeHasAmount,
      onChangeHasFields,
      onChangeTodoTemplates,
      onChangeAssigneeFillType,
      onChangeSupervisorFillType,
      onChangeMemberFillType,
    } = this.props;

    return (
      <Card style={{borderColor: "#1890ff"}}>
        <Row gutter={10}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Checkbox
              checked={taskTemplate.is_active}
              onChange={onChangeActive}
            >
              активен
            </Checkbox>
          </Col>
        </Row>
        <Divider/>
        <Row gutter={10}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Checkbox
              checked={taskTemplate.has_timing}
              onChange={onChangeHasTiming}
            >
              тайминг
            </Checkbox>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Checkbox
              checked={taskTemplate.has_todos}
              onChange={onChangeHasTodos}
            >
              чек-лист
            </Checkbox>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Checkbox
              checked={taskTemplate.has_attachments}
              onChange={onChangeHasAttachments}
            >
              файлы
            </Checkbox>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Checkbox
              checked={taskTemplate.has_comments}
              onChange={onChangeHasComments}
            >
              комментарии
            </Checkbox>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Checkbox
              checked={taskTemplate.has_supervisor_assignees}
              onChange={onChangeHasSupervisorAssignees}
            >
              наблюдатели
            </Checkbox>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Checkbox
              checked={taskTemplate.has_user_assignees}
              onChange={onChangeHasUserAssignees}
            >
              исполнители
            </Checkbox>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Checkbox
              checked={taskTemplate.has_member_assignees}
              onChange={onChangeHasMemberAssignees}
            >
              ответственные заказчика
            </Checkbox>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Checkbox
              checked={taskTemplate.has_amount}
              onChange={onChangeHasAmount}
            >
              использовать сумму
            </Checkbox>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Checkbox
              checked={taskTemplate.has_fields}
              onChange={onChangeHasFields}
            >
              дополнительные поля
            </Checkbox>
          </Col>
        </Row>
        {taskTemplate.has_todos ?
          <React.Fragment>
            <Divider/>
            <Row gutter={10}>
              <Col md={{span: 24}} xs={{span: 24}}>
                <FormItem label={"Шаблоны чек-листов"}>
                  {getFieldDecorator("todo_templates", {
                    initialValue: taskTemplate.todo_templates
                      ? taskTemplate.todo_templates
                      : [],
                  })(
                    <SelectMultiFetch
                      showSearch
                      placeholder="Шаблоны чек-листов"
                      onChange={onChangeTodoTemplates}
                      selected={
                        taskTemplate.todo_templates ? taskTemplate.todo_templates : []
                      }
                      url={"/todo_templates/search/?todo_type=2"}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
          </React.Fragment> : null
        }
        <Divider/>
        <Row gutter={10}>
          <Col md={{span: 24}} xs={{span: 24}}>
            <Radio.Group
              onChange={onChangeAssigneeFillType}
              value={taskTemplate.assignee_fill_type}
            >
              <Radio style={radioStyle} value={1}>
                Исполнители назначаются вручную
              </Radio>
              <Radio style={radioStyle} value={2}>
                Исполнители копируются
              </Radio>
              <Radio style={radioStyle} value={3}>
                Исполнители заполняются из списка
              </Radio>
            </Radio.Group>
          </Col>
        </Row>
        <Divider/>
        <Row gutter={10}>
          <Col md={{span: 24}} xs={{span: 24}}>
            <Radio.Group
              onChange={onChangeSupervisorFillType}
              value={taskTemplate.supervisor_fill_type}
            >
              <Radio style={radioStyle} value={1}>
                Наблюдатели назначаются вручную
              </Radio>
              <Radio style={radioStyle} value={2}>
                Наблюдатели копируются
              </Radio>
              <Radio style={radioStyle} value={3}>
                Наблюдатели заполняются из списка
              </Radio>
            </Radio.Group>
          </Col>
        </Row>
        <Divider/>
        <Row gutter={10}>
          <Col md={{span: 24}} xs={{span: 24}}>
            <Radio.Group
              onChange={onChangeMemberFillType}
              value={taskTemplate.member_fill_type}
            >
              <Radio style={radioStyle} value={1}>
                Ответственные заказчика назначаются вручную
              </Radio>
              <Radio style={radioStyle} value={2}>
                Ответственные заказчика копируются
              </Radio>
              <Radio style={radioStyle} value={3}>
                Ответственные заказчика заполняются из списка
              </Radio>
            </Radio.Group>
          </Col>
        </Row>
      </Card>
    );
  }
}

Sidebar.propTypes = {
  customer: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  onChangeHasTiming: PropTypes.func,
  onChangeHasTodos: PropTypes.func,
  onChangeHasAttachments: PropTypes.func,
  onChangeHasComments: PropTypes.func,
  onChangeHasSupervisorAssignees: PropTypes.func,
  onChangeHasUserAssignees: PropTypes.func,
  onChangeHasMemberAssignees: PropTypes.func,
};

export default Sidebar;
