// @flow
import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {compose} from "redux";
import {Link, withRouter} from "react-router-dom";
import Actions from "../../actions/knowledgeBases"
import {Row, Col, DatePicker, Select, List, Button, PageHeader, Input, Card, Breadcrumb, Icon} from "antd";
import Sections from "./Sections/List";
import Articles from "./Sections/Articles/Search";
import debounce from 'lodash.debounce';
import moment from "moment";
import "moment/locale/ru";
import Can from "../../rules/Can";

const Search = Input.Search;

class KnowledgeBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputQuery: ''
    }
  }

  componentWillMount = () => {
    this.props.dispatch(Actions.fetchKnowledgeBase());
  };

  componentWillUnmount() {
    this.onSearchQueryChangeDebounced.cancel();
  };

  onCreateKnowledgeBase = () => {
    this.props.dispatch(Actions.createKnowledgeBase());
  };

  onSearchQueryChangeDebounced = debounce((value) => {
    let query = value.trim()
    this.props.dispatch(Actions.changeArticlesSearchQuery(query)).then(() => {
      if (query === '') {
        return;
      }

      this.props.dispatch(Actions.searchArticles());
    });
  }, 500);

  onSearchQueryChange = (e) => {
    const value = e.target.value;

    this.setState({
      inputQuery: value
    });

    this.onSearchQueryChangeDebounced(value);
  };

  onSearch = () => {
    this.props.dispatch(Actions.searchArticles())
  }

  render() {
    const {inputQuery} = this.state;
    const {
      currentUser,
      knowledgeBase,
      articles
    } = this.props;

    return (
      <>
        <PageHeader
          onBack={() => window.history.back()}
          title={`База знаний`}
          extra={[
            <Search key="1"
                    allowClear
                    placeholder="Поиск..."
                    value={inputQuery}
                    onChange={this.onSearchQueryChange}
                    onSearch={this.onSearch}
                    style={{width: 250}}
            />
          ]}
        />
        {knowledgeBase === null ?
          <Can
            role={currentUser.role}
            perform={"knowledgeBase:create"}
            yes={() =>
              <Card>
                <Button type={"dashed"}
                        onClick={() => this.onCreateKnowledgeBase()}>
                  {"Создать базу знаний"}
                </Button>
              </Card>
            }
            no={() => null}
          /> :
          inputQuery.trim() === '' ?
            <Sections/> :
            <Articles
              query={inputQuery.trim()}
              data={articles}/>}
      </>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.session.currentUser,
  isLoading: state.knowledgeBases.isLoading,
  fetchParams: state.knowledgeBases.fetchParams,
  knowledgeBase: state.knowledgeBases.knowledgeBase,
  sections: state.sections.sections,
  articles: state.knowledgeBases.articles
});

export default compose(
  withRouter,
  connect(mapStateToProps)
)(KnowledgeBase);
