import api from "../api";

const Actions = {};

Actions.fetchKnowledgeBase = () => {
  return dispatch => {
    dispatch({type: "FETCH_KNOWLEDGE_BASE_REQUEST"});
    api
      .fetch(`/knowledge_base`)
      .then(response => {
        dispatch({type: "FETCH_KNOWLEDGE_BASE", response});
      })
      .catch(error => {
        api.handleError(error, "Пост");
        dispatch({type: "FETCH_KNOWLEDGE_BASE_FAILURE"});
      });
  };
};

Actions.createKnowledgeBase = () => {
  return dispatch => {
    dispatch({type: "POST_KNOWLEDGE_BASE_REQUEST"});

    let data = {}

    api
      .post(`/knowledge_base`, data)
      .then(response => {
        dispatch({type: "POST_KNOWLEDGE_BASE", response});
        api.openNotification("success", "База знаний", "Успешно создана!");
      })
      .catch(error => {
        api.handleError(error, "Пост");
        dispatch({type: "POST_KNOWLEDGE_BASE_FAILURE"});
      });
  };
};

Actions.changeArticlesSearchQuery = (value) => {
  return (dispatch) => {
    dispatch({
      type: "CHANGE_ARTICLES_SEARCH_QUERY",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.searchArticles = () => {
  return (dispatch, getState) => {
    dispatch({type: "SEARCH_ARTICLES_REQUEST"});

    let params = getState().knowledgeBases.fetchParams;

    api
      .fetch(`/sections/all/articles`, params)
      .then(response => {
        dispatch({type: "SEARCH_ARTICLES", response});
      })
      .catch(response => {
        console.log(response.error);
        dispatch({type: "SEARCH_ARTICLES_FAILURE", response});
      });
  };
};

export default Actions;
