// @flow
import React, {Component} from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import {Col, Descriptions, Row} from "antd";
import Presentation from "../../../components/Presentation";

class General extends Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    const {currentUser, entityTask} = this.props;

    return (
      <Row gutter={{md: 8, lg: 24, xl: 48}}>
        <Col span={24}>
          <Descriptions bordered>
            <Descriptions.Item label="Объект" span={3}>
              <Link to={`/entities/${entityTask.entity.id}/edit`}>
                {entityTask.entity.name}
              </Link>
            </Descriptions.Item>
            <Descriptions.Item label="Адрес" span={3}>
              {entityTask.entity.address}
            </Descriptions.Item>
            {entityTask.customer ?
              <Descriptions.Item label="Клиент" span={3}>
                <Presentation
                  currentUser={currentUser}
                  rule={"customers:view"}
                  route={`/customers/${entityTask.customer.id}/edit`}
                  title={entityTask.customer.name}
                />
              </Descriptions.Item> :
              null
            }
            <Descriptions.Item label="Телефоны" span={3}>
              {entityTask.customer ? entityTask.customer.phones : null}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    );
  }
}

General.propTypes = {
  entityTask: PropTypes.object,
  onChangeDate: PropTypes.func,
  onChangeAssignee: PropTypes.func,
  onChangeAgreement: PropTypes.func,
  onChangeAgreementDate: PropTypes.func,
  onChangeAdditional: PropTypes.func,
  onChangeComment: PropTypes.func,
  onChangeNotice: PropTypes.func,
};

export default General;
