import React from "react";
import { List, Avatar, Popover } from "antd";
import UserInfo from "../UserInfo";
import MemberInfo from "../MemberInfo";
import EmployeeInfo from "../EmployeeInfo";

export default function AssigneesCell({ assignees = [] }) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {assignees.map((assignee) => {
        if (assignee.user) {
          return (
            <span key={`assignee-${assignee.id}-user-${assignee.user.id}`}>
              <UserInfo title={assignee.user.name} userId={assignee.user.id} />
            </span>
          );
        }

        if (assignee.member) {
          return (
            <span key={`assignee-${assignee.id}-member-${assignee.member.id}`}>
              <MemberInfo title={assignee.member.name} memberId={assignee.member.id} />
            </span>
          );
        }

        if (assignee.employee) {
          return (
            <span key={`assignee-${assignee.id}-employee-${assignee.employee.id}`}>
              <EmployeeInfo
                title={assignee.employee.name}
                employeeId={assignee.employee.id}
              />
            </span>
          );
        }
      })}
    </div>
  );
}
