import React from "react";
import { Link } from "react-router-dom";
import Can from "../../rules/Can";

export default function Presentation({
  currentUser = null,
  rule = null,
  route = null,
  title = null
}) {
  return (
    <Can
      role={currentUser.role}
      perform={rule}
      yes={() => <Link to={route}>{title}</Link>}
      no={() => (title)}
    />
  );
}
