// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import update from "immutability-helper";
import { Row, Col, Table, Button } from "antd";
import "moment/locale/ru";
import accounting from "accounting";
import PayrollItem from "./PayrollItem";

class PayrollItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      isVisible: false,
      payrollItem: {},
    };
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  onDelete = () => {
    this.props.onDeletePayrollItems(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  // payrollItems
  onOpenPayrollItem = (item) => {
    this.setState({ payrollItem: item }, () => {
      this.setState({ isVisible: true });
    });
  };

  onAddPayrollItem = () => {
    this.setState(
      {
        payrollItem: {
          id: null,
          user: null,
          salary: 0,
          wage: 0,
          description: null,
          payroll_schema: null
        },
      },
      () => {
        this.setState({ isVisible: true });
      }
    );
  };

  onSavePayrollItem = () => {
    this.setState({ isVisible: false }, () => {
      this.props.onSavePayrollItem(this.state.payrollItem);
    });
  };

  onCancel = () => {
    this.setState({ isVisible: false });
  };

  onChangeUser = (value) => {
    this.setState({
      payrollItem: update(this.state.payrollItem, {
        user: { $set: value },
      }),
    });
  };

  onChangeSalary = (value) => {
    this.setState({
      payrollItem: update(this.state.payrollItem, {
        salary: { $set: value },
      }),
    });
  };

  onChangeWage = (value) => {
    this.setState({
      payrollItem: update(this.state.payrollItem, {
        wage: { $set: value },
      }),
    });
  };

  onChangeDescription = (e) => {
    this.setState({
      payrollItem: update(this.state.payrollItem, {
        description: { $set: e.target.value },
      }),
    });
  };

  render() {
    const { selectedRowKeys, isVisible, payrollItem } = this.state;
    const { isNew, isLoadind, department, payrollItems } = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    let mainButton = hasSelected ? (
      <Button key="1" type="danger" ghost onClick={this.onDelete}>
        Удалить
      </Button>
    ) : (
      <Button key="2" type="primary" ghost onClick={this.onAddPayrollItem}>
        Добавить
      </Button>
    );

    const columns = [
      {
        title: "Имя",
        dataIndex: "user",
        render: (user, record) => (
          <a onClick={this.onOpenPayrollItem.bind(this, record)}>
            {user ? user.name : null}
          </a>
        ),
        key: "user",
        width: "18%",
        align: "left",
      },
      {
        title: "Схема начисления",
        dataIndex: "payroll_schema",
        render: (payroll_schema) =>
          payroll_schema ? payroll_schema.name : null,
        key: "payroll_schema",
        align: "center",
        width: "15%",
      },
      {
        title: "Начисление",
        dataIndex: "description",
        key: "description",
        align: "center",
        width: "19%",
      },
      {
        title: "Дополнительно",
        dataIndex: "info",
        align: "center",
        key: "info",
        width: "20%",
      },
      {
        title: "Базовое начисление",
        dataIndex: "salary",
        render: (salary) => accounting.formatNumber(salary, 0, " "),
        key: "salary",
        width: "14%",
        align: "center",
      },
      {
        title: "Бонусы",
        dataIndex: "wage",
        render: (wage) => accounting.formatNumber(wage, 0, " "),
        key: "wage",
        width: "14%",
        align: "center",
      },
    ];

    return (
      <React.Fragment>
        {isNew ? null : (
          <Row gutter={{ md: 8, lg: 24, xl: 48 }} style={{ paddingBottom: 15 }}>
            <Col span={24}>{mainButton}</Col>
          </Row>
        )}
        <Row gutter={{ md: 8, lg: 24, xl: 48 }} style={{ paddingBottom: 15 }}>
          <Col span={24}>
            <Table
              bordered
              size={"small"}
              loading={isLoadind}
              rowSelection={rowSelection}
              pagination={false}
              columns={columns}
              dataSource={payrollItems}
              rowKey="id"
            />
          </Col>
        </Row>
        <PayrollItem
          visible={isVisible}
          user={payrollItem.user}
          salary={payrollItem.salary}
          wage={payrollItem.wage}
          description={payrollItem.description}
          payrollSchema={payrollItem.payroll_schema}
          departmentId={department ? department.id : null}
          onSave={this.onSavePayrollItem}
          onCancel={this.onCancel}
          onChangeUser={this.onChangeUser}
          onChangeSalary={this.onChangeSalary}
          onChangeWage={this.onChangeWage}
          onChangeDescription={this.onChangeDescription}
        />
      </React.Fragment>
    );
  }
}
PayrollItems.propTypes = {
  getFieldDecorator: PropTypes.func,
};

export default PayrollItems;
