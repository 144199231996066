// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Input, Col, Row } from "antd";
import SelectFetch from "../../../components/SelectFetch";

const FormItem = Form.Item;

class Address extends Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    const {
      name,
      website,
      postal_code,
      city,
      street,
      building,
      getFieldDecorator,
      onChangeName,
      onChangeWebsite,
      onChangePostalCode,
      onChangeCity,
      onChangeStreet,
      onChangeBuilding,
    } = this.props;

    return (
      <Row gutter={16}>
        <Col md={{ span: 12 }} xs={{ span: 12 }}>
          <FormItem label={"Наименование"}>
            {getFieldDecorator("name", {
              onChange: onChangeName,
              initialValue: name,
              rules: [
                {
                  required: true,
                  message: "Введите наименование!",
                },
              ],
            })(<Input />)}
          </FormItem>
        </Col>
        <Col md={{ span: 12 }} xs={{ span: 12 }}>
          <FormItem label={"Веб-сайт"}>
            {getFieldDecorator("website", {
              onChange: onChangeWebsite,
              initialValue: website,
            })(<Input />)}
          </FormItem>
        </Col>
        <Col md={{ span: 6 }} xs={{ span: 6 }}>
          <FormItem label={"Индекс"}>
            {getFieldDecorator("postal_code", {
              onChange: onChangePostalCode,
              initialValue: postal_code,
              // rules: [
              //   {
              //     required: true,
              //     message: "Введите почтовый индекс!"
              //   }
              // ]
            })(<Input />)}
          </FormItem>
        </Col>
        <Col md={{ span: 18 }} xs={{ span: 18 }}>
          <FormItem label={"Город"}>
            {getFieldDecorator("city", {
              initialValue: city ? city : null,
              rules: [
                {
                  required: true,
                  message: "Пожалуйста, город!",
                },
              ],
            })(
              <SelectFetch
                showSearch
                showSubtitle
                placeholder="Город"
                onChange={onChangeCity}
                selected={city ? city : null}
                url={"/cities/search/?"}
              />
            )}
          </FormItem>
        </Col>
        <Col md={{ span: 18 }} xs={{ span: 18 }}>
          <FormItem label={"Улица"}>
            {getFieldDecorator("street", {
              onChange: onChangeStreet,
              initialValue: street,
              rules: [
                {
                  required: true,
                  message: "Введите улицу!",
                },
              ],
            })(<Input />)}
          </FormItem>
        </Col>
        <Col md={{ span: 6 }} xs={{ span: 6 }}>
          <FormItem label={"Дом"}>
            {getFieldDecorator("building", {
              onChange: onChangeBuilding,
              initialValue: building,
              rules: [
                {
                  required: true,
                  message: "Введите дом!",
                },
              ],
            })(<Input />)}
          </FormItem>
        </Col>
      </Row>
    );
  }
}

Address.propTypes = {
  customer: PropTypes.object,
  onChangeDate: PropTypes.func,
  onChangeAssignee: PropTypes.func,
  onChangeAgreement: PropTypes.func,
  onChangeAgreementDate: PropTypes.func,
  onChangeAdditional: PropTypes.func,
  onChangeComment: PropTypes.func,
  onChangeNotice: PropTypes.func,
};

export default Address;
