import update from "immutability-helper";
import _ from "lodash/array";

const initialState = {
  payroll_schemas: [],
  payrollSchema: { payroll_schema_values: [] },
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1,
  },
  fetchParams: {
    is_active: true,
    search: null,
    sort: null,
    direction: null,
    page: 1,
    limit: 15,
  },
  errors: false,
  isLoading: true,
};

export default function (state = initialState, action) {
  let idx = -1;
  switch (action.type) {
    case "FETCH_PAYROLL_SCHEMAS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_PAYROLL_SCHEMAS":
      return {
        ...state,
        payroll_schemas: action.response.data,
        meta: action.response.meta,
        isLoading: false,
      };
    case "FETCH_PAYROLL_SCHEMAS_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "FETCH_PAYROLL_SCHEMA_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_PAYROLL_SCHEMA":
      return {
        ...state,
        payrollSchema: action.response.data,
        isLoading: false,
      };

    case "FETCH_PAYROLL_SCHEMA_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "PATCH_PAYROLL_SCHEMA_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };

    case "PATCH_PAYROLL_SCHEMA":
      return {
        ...state,
        payrollSchema: action.response.data,
        isLoading: false,
        errors: false,
      };

    case "PATCH_PAYROLL_SCHEMA_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "POST_PAYROLL_SCHEMA":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };

    case "POST_PAYROLL_SCHEMA_SUCCEED":
      return {
        ...state,
        payrollSchema: action.response.data,
        isLoading: false,
        errors: false,
      };

    case "POST_PAYROLL_SCHEMA_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "DELETE_PAYROLL_SCHEMA_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "DELETE_PAYROLL_SCHEMA":
      return {
        ...state,
        errors: false,
        isLoading: false,
      };
    case "DELETE_PAYROLL_SCHEMA_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "PAYROLL_SCHEMA_NEW":
      return {
        ...state,
        errors: false,
        payrollSchema: { payroll_schema_values: [] },
        isLoading: false,
      };

    case "REQUEST_PAYROLL_SCHEMA_SEARCH":
      return update(state, {
        fetchParams: { search: { $set: action.value }, page: { $set: 1 } },
      });

    case "REQUEST_PAYROLL_SCHEMA_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } },
      });

    case "REQUEST_PAYROLL_SCHEMA_CHANGE_LIMIT":
      return update(state, {
        fetchParams: { limit: { $set: action.value }, page: { $set: 1 } },
      });

    case "REQUEST_PAYROLL_SCHEMAS_FILTER_ACTIVE":
      return update(state, {
        fetchParams: { is_active: { $set: action.value } },
      });

    case "PAYROLL_SCHEMAS_SORT_BY":
      return update(state, {
        fetchParams: {
          sort: { $set: action.field },
          direction: { $set: action.direction },
        },
      });

    // header
    case "PAYROLL_SCHEMA_CHANGE_NAME":
      return update(state, {
        payrollSchema: { name: { $set: action.value } },
      });

    case "PAYROLL_SCHEMA_CHANGE_DESCRIPTION":
      return update(state, {
        payrollSchema: { description: { $set: action.value } },
      });

    case "PAYROLL_SCHEMA_CHANGE_ORDERS":
      return update(state, {
        payrollSchema: { orders: { $set: action.value } },
      });

    // payroll_schema_values
    case "PAYROLL_SCHEMA_VALUE_DELETE":
      return update(state, {
        payrollSchema: {
          payroll_schema_values: {
            [action.idx]: { _destroy: { $set: true } },
          },
        },
      });

    case "PAYROLL_SCHEMA_ADD_VALUE":
      return update(state, {
        payrollSchema: {
          payroll_schema_values: { $push: [action.item] },
        },
      });

    case "PAYROLL_SCHEMA_VALUE_DESTROY":
      return update(state, {
        payrollSchema: {
          payroll_schema_values: { $splice: [[action.idx, 1]] },
        },
      });

    case "PAYROLL_SCHEMA_CHANGE_VALUE_NAME":
      idx = _.findIndex(state.payrollSchema.payroll_schema_values, [
        "id",
        action.id,
      ]);
      if (idx === -1) {
        return null;
      }
      return update(state, {
        payrollSchema: {
          payroll_schema_values: {
            [idx]: { name: { $set: action.value } },
          },
        },
      });

    case "PAYROLL_SCHEMA_CHANGE_VALUE_WEIGHT":
      idx = _.findIndex(state.payrollSchema.payroll_schema_values, [
        "id",
        action.id,
      ]);
      if (idx === -1) {
        return null;
      }
      return update(state, {
        payrollSchema: {
          payroll_schema_values: {
            [idx]: { weight: { $set: action.value } },
          },
        },
      });

    case "PAYROLL_SCHEMA_CHANGE_VALUE":
      idx = _.findIndex(state.payrollSchema.payroll_schema_values, [
        "id",
        action.id,
      ]);
      if (idx === -1) {
        return null;
      }
      return update(state, {
        payrollSchema: {
          payroll_schema_values: {
            [idx]: { value: { $set: action.value } },
          },
        },
      });

    case "PAYROLL_SCHEMA_CHANGE_VALUE_FROM":
      idx = _.findIndex(state.payrollSchema.payroll_schema_values, [
        "id",
        action.id,
      ]);
      if (idx === -1) {
        return null;
      }
      return update(state, {
        payrollSchema: {
          payroll_schema_values: {
            [idx]: { from: { $set: action.value } },
          },
        },
      });

      case "PAYROLL_SCHEMA_CHANGE_VALUE_TO":
      idx = _.findIndex(state.payrollSchema.payroll_schema_values, [
        "id",
        action.id,
      ]);
      if (idx === -1) {
        return null;
      }
      return update(state, {
        payrollSchema: {
          payroll_schema_values: {
            [idx]: { to: { $set: action.value } },
          },
        },
      });

    default:
      return state;
  }
}
