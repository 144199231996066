// @flow
import React, { Component } from "react";
import {
  Row,
  Col,
  Modal,
  Form,
  Input,
  InputNumber,
} from "antd";

const FormItem = Form.Item;

class Option extends React.Component {
  onSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onSave(values);
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      visible,
      onCancel,
      option,
      onChangeValue,
      onChangePosition
    } = this.props;
    return (
      <Modal
        title={option.value ? option.value : "Новое значение"}
        destroyOnClose
        visible={visible}
        onOk={this.onSubmit}
        onCancel={onCancel}
      >
        <Form layout={"vertical"}>
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <FormItem label={"Значение"}>
                {getFieldDecorator("value", {
                  onChange: onChangeValue,
                  initialValue: option.value,
                  rules: [
                    {
                      required: true,
                      message: "Введите значение!"
                    }
                  ]
                })(<Input />)}
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem label={"Позиция"}>
                {getFieldDecorator("position", {
                  onChange: onChangePosition,
                  initialValue: option.position,
                })(<InputNumber style={{width: "100%"}} />)}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(Option);
