// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table, Button, PageHeader } from "antd";
import { Pagination } from "antd";
import { Row, Col, Input } from "antd";

import PropTypes from "prop-types";
import accounting from "accounting";

import moment from "moment";
import "moment/locale/ru";

const Search = Input.Search;

class CustomersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: []
    };
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  onDelete = () => {
    this.props.onDelete(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  onChangeTable = (pagination, filters, sorter) => {
    let direction = "desc";
    if (sorter.order === "descend") {
      direction = "desc";
    } else if (sorter.order === "ascend") {
      direction = "asc";
    }
    this.props.onSortBy(sorter.field, direction);
  };

  render() {
    const { selectedRowKeys } = this.state;
    const {
      loading,
      customers,
      meta,
      fetchParams,
      onSearch,
      onChangePage,
      onChangeLimit
    } = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    };

    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: "Клиент",
        dataIndex: "name",
        render: (name, record) => (
          <Link to={`/customers/${record.id}/edit`}>{record.name}</Link>
        ),
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "number",
        align: "center",
        width: "25%"
      },
      {
        title: "Адрес",
        dataIndex: "address",
        align: "center",
        width: "20%"
      },
      {
        title: "Emails",
        dataIndex: "emails",
        render: (emails, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {emails.map(item => {
              return <span style={{ opacity: "0.7" }}>{item.email}</span>;
            })}
          </div>
        ),
        align: "center",
        width: "20%"
      },
      {
        title: "Телефоны",
        dataIndex: "phones",
        render: (phones, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {phones.map(item => {
              return <span style={{ opacity: "0.7" }}>{item.phone}</span>;
            })}
          </div>
        ),
        align: "center",
        width: "20%"
      },
      {
        title: "Дата создания",
        dataIndex: "created_at",
        render: created_at => moment(created_at).format("L"),
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "date",
        align: "center",
        width: "15%"
      }
    ];

    const faqButton = (
      <Button
        type={"ghost"}
        key="7"
        icon="question"
        style={{marginRight: 10}}
        onClick={this.props.onFaqToggle}
      />
    );

    const search = (
      <Search
        allowClear
        key="99"
        placeholder="поиск..."
        value={fetchParams.search}
        onChange={onSearch}
        style={{ width: 250 }}
      />
    );

    let mainButton = hasSelected ? (
      <Button key="1" type="danger" ghost onClick={this.onDelete}>
        Удалить
      </Button>
    ) : (
      <Link
        key="2"
        className={"ant-btn ant-btn-primary ant-btn-background-ghost"}
        to={{ pathname: `/customers/new` }}
      >
        Создать
      </Link>
    );

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Клиенты`}
        extra={[
          faqButton, 
          search,
          mainButton
        ]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Table
                bordered
                rowSelection={rowSelection}
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={customers}
                onChange={this.onChangeTable}
                rowKey="id"
              />
            </Col>
            <Col
              span={24}
              style={{ paddingTop: "15px", paddingBottom: "15px", textAlign: "right" }}
            >
              <Pagination
                showSizeChanger
                pageSize={fetchParams.limit}
                current={meta.current_page}
                onChange={onChangePage}
                onShowSizeChange={onChangeLimit}
                showTotal={total => `Всего: ${meta.total_count}`}
                total={meta.total_count}
              />
            </Col>
          </Row>
        }
      />
    );
  }
}

CustomersList.propTypes = {
  customers: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object
};

export default CustomersList;
