// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, Form, Card, Modal, Tabs, Statistic } from "antd";
import Header from "./Header";
import Employees from "./Employees";

const { confirm } = Modal;
const { TabPane } = Tabs;

class PayrollForm extends Component {
  onReFillIn = () => {
    const { onReFillInEmployees } = this.props;
    confirm({
      title: "Перезаполнить строки?",
      content: "Все введенные данные будут удалены и заполнены заново.",
      onOk() {
        onReFillInEmployees();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  onUpdateDepartmentAndRefillIn = (value) => {
    const {
      employeePayroll,
      onChangeDepartment,
      onUpdateDepartmentAndRefillIn,
    } = this.props;
    confirm({
      title: "Перезаполнить строки?",
      content:
        "Изменилось подразделение, все введенные данные будут удалены и заполнены заново.",
      onOk() {
        onUpdateDepartmentAndRefillIn(value);
      },
      onCancel() {
        onChangeDepartment(employeePayroll.department);
      },
    });
  };

  onSubmit = (e) => {
    const { isNew } = this.props;
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (isNew) {
          this.props.onFillInEmployees();
        } else {
          this.onReFillIn();
        }
      }
    });
  };

  onChangeDepartment = (value) => {
    const { isNew, employeePayroll, onChangeDepartment } = this.props;
    if (!isNew && employeePayroll.department.id != value.id) {
      this.onUpdateDepartmentAndRefillIn(value);
    } else {
      onChangeDepartment(value);
    }
  };

  render() {
    const {
      isLoading,
      isNew,
      employeePayroll,
      onClose,
      onChangeDate,
      onChangePayrollSchema,
      // employee_payroll_items
      isLoadindPayrollItems,
      onDeleteEemployees,
      onChangeSalary,
      onCreateEmployee,
    } = this.props;
    const { getFieldDecorator } = this.props.form;

    const total = employeePayroll.employee_payroll_items.reduce(
      (sum, record) => sum + Number(record.salary),
      0
    );

    return (
      <Card
        loading={isLoading}
        title={
          <h2 style={{ marginBottom: 0 }}>{`Базовое начисление #${
            isNew ? "новое" : `${employeePayroll.id}`
          }`}</h2>
        }
        extra={
          <div>
            <Button onClick={onClose}>Закрыть</Button>
            <Button
              onClick={this.onSubmit}
              type="primary"
              ghost
              style={{ marginLeft: "8px" }}
            >
              Заполнить
            </Button>
          </div>
        }
      >
        <Form layout={"vertical"}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Row>
              <Header
                getFieldDecorator={getFieldDecorator}
                employeePayroll={employeePayroll}
                onChangeDate={onChangeDate}
                onChangeDepartment={this.onChangeDepartment}
                onChangePayrollSchema={onChangePayrollSchema}
              />
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Tabs
              defaultActiveKey="1"
              tabBarExtraContent={
                <Statistic
                  key={"2"}
                  style={{ padding: 0 }}
                  // title="Day Level"
                  // prefix={"Всего: "}
                  groupSeparator=" "
                  value={total}
                  precision={2}
                  valueStyle={{ color: "#1777d1" }}
                  // suffix="р."
                />
              }
            >
              <TabPane tab="Сотрудники" key="1">
                <Employees
                  isLoadindPayrollItems={isLoadindPayrollItems}
                  employees={
                    employeePayroll.employee_payroll_items.length > 0
                      ? employeePayroll.employee_payroll_items
                      : []
                  }
                  date={employeePayroll.date}
                  department={employeePayroll.department}
                  onDeleteEemployees={onDeleteEemployees}
                  onChangeSalary={onChangeSalary}
                  onCreateEmployee={onCreateEmployee}
                />
              </TabPane>
            </Tabs>
          </Col>
        </Form>
      </Card>
    );
  }
}

PayrollForm.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  employeePayroll: PropTypes.object,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
  router: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  form: PropTypes.object,
};

export default Form.create()(PayrollForm);
