import update from "immutability-helper";

const initialState = {
  taskTemplates: [],
  taskTemplate: { departments: [], todo_templates: [], field_sets: [] },
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1,
  },
  fetchParams: {
    task_owner: null,
    task_type: null,
    sort: null,
    direction: null,
    page: 1,
    limit: 15,
  },
  errors: false,
  isLoading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "FETCH_TASK_TEMPLATES_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_TASK_TEMPLATES":
      return {
        ...state,
        taskTemplates: action.response.data,
        meta: action.response.meta,
        isLoading: false,
      };

    // pagination
    case "REQUEST_TASK_TEMPLATES_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } },
      });

    case "REQUEST_TASK_TEMPLATES_CHANGE_LIMIT":
      return update(state, {
        fetchParams: { limit: { $set: action.value }, page: { $set: 1 } },
      });

    case "TASK_TEMPLATES_SORT_BY":
      return update(state, {
        fetchParams: {
          sort: { $set: action.field },
          direction: { $set: action.direction },
        },
      });

    case "FETCH_TASK_TEMPLATE_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_TASK_TEMPLATE":
      return {
        ...state,
        taskTemplate: action.response.data,
        isLoading: false,
      };
    case "PATCH_TASK_TEMPLATE_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };
    case "PATCH_TASK_TEMPLATE":
      return {
        ...state,
        taskTemplate: action.response.data,
        isLoading: false,
        errors: false,
      };

    case "PATCH_TASK_TEMPLATE_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "TASK_TEMPLATE_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "TASK_TEMPLATE_SUCCEED":
      return {
        ...state,
        errors: false,
        taskTemplate: action.response.data,
        isLoading: false,
      };

    case "TASK_TEMPLATE_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "PUBLISH_TASK_TEMPLATE_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "PUBLISH_TASK_TEMPLATE_SUCCEED":
      return {
        ...state,
        errors: false,
        taskTemplate: action.response.data,
        isLoading: false,
      };

    case "PUBLISH_TASK_TEMPLATE_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "DELETE_TASK_TEMPLATE_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };
    case "DELETE_TASK_TEMPLATE":
      return {
        ...state,
        isLoading: false,
        errors: false,
      };
    case "DELETE_TASK_TEMPLATE_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "TASK_TEMPLATE_NEW":
      return {
        ...state,
        errors: false,
        taskTemplate: {
          is_active: false,
          visibility: 1,
          customer: null,
          name: null,
          description: null,
          task_owner: null,
          task_type: "common",
          action_template: null,
          has_timing: false,
          has_todos: false,
          has_attachments: false,
          has_comments: false,
          has_user_assignees: false,
          has_member_assignees: false,
          departments: [],
          customers: [],
          assignees: [],
          supervisors: [],
          todo_templates: [],
          field_sets: [],
          assignee_fill_type: 1,
          supervisor_fill_type: 1,
          member_fill_type: 1,
        },
        isLoading: false,
      };

    case "TASK_TEMPLATE_CHANGE_ACTIVE":
      return update(state, {
        taskTemplate: { is_active: { $set: !state.taskTemplate.is_active } },
      });

    case "TASK_TEMPLATE_CHANGE_VISIBILITY":
      return update(state, {
        taskTemplate: {
          visibility: { $set: action.value },
          customers: { $set: [] },
        },
      });

    case "TASK_TEMPLATE_CHANGE_CUSTOMER":
      return update(state, {
        taskTemplate: { customer: { $set: action.value } },
      });

    case "TASK_TEMPLATE_CHANGE_NAME":
      return update(state, {
        taskTemplate: { name: { $set: action.value } },
      });

    case "TASK_TEMPLATE_CHANGE_DESCRIPTION":
      return update(state, {
        taskTemplate: { description: { $set: action.value } },
      });

    case "TASK_TEMPLATE_CHANGE_OWNER":
      return update(state, {
        taskTemplate: { task_owner: { $set: action.value } },
      });

    case "TASK_TEMPLATE_CHANGE_ACTION_TEMPLATE":
      return update(state, {
        taskTemplate: { action_template: { $set: action.value } },
      });

    case "TASK_TEMPLATE_CHANGE_ACTION_TEMPLATE_HOURS":
      return update(state, {
        taskTemplate: { action_template_hours: { $set: action.value } },
      });

    case "TASK_TEMPLATE_CHANGE_ACTION_TEMPLATE_SUBJECT":
      return update(state, {
        taskTemplate: { action_template_subject: { $set: action.value } },
      });

    case "TASK_TEMPLATE_CHANGE_HAS_TIMING":
      return update(state, {
        taskTemplate: { has_timing: { $set: !state.taskTemplate.has_timing } },
      });

    case "TASK_TEMPLATE_CHANGE_HAS_TODOS":
      return update(state, {
        taskTemplate: { has_todos: { $set: !state.taskTemplate.has_todos } },
      });

    case "TASK_TEMPLATE_CHANGE_HAS_ATTACHMENTS":
      return update(state, {
        taskTemplate: {
          has_attachments: { $set: !state.taskTemplate.has_attachments },
        },
      });

    case "TASK_TEMPLATE_CHANGE_HAS_COMMENTS":
      return update(state, {
        taskTemplate: {
          has_comments: { $set: !state.taskTemplate.has_comments },
        },
      });

    case "TASK_TEMPLATE_CHANGE_HAS_SUPERVISOR_ASSIGNEES":
      return update(state, {
        taskTemplate: {
          has_supervisor_assignees: {
            $set: !state.taskTemplate.has_supervisor_assignees,
          },
        },
      });

    case "TASK_TEMPLATE_CHANGE_HAS_USER_ASSIGNEES":
      return update(state, {
        taskTemplate: {
          has_user_assignees: { $set: !state.taskTemplate.has_user_assignees },
        },
      });

    case "TASK_TEMPLATE_CHANGE_HAS_MEMBER_ASSIGNEES":
      return update(state, {
        taskTemplate: {
          has_member_assignees: {
            $set: !state.taskTemplate.has_member_assignees,
          },
        },
      });

    case "TASK_TEMPLATE_CHANGE_HAS_AMOUNT":
      return update(state, {
        taskTemplate: {
          has_amount: {
            $set: !state.taskTemplate.has_amount,
          },
        },
      });

    case "TASK_TEMPLATE_CHANGE_HAS_FIELDS":
      return update(state, {
        taskTemplate: {
          has_fields: {
            $set: !state.taskTemplate.has_fields,
          },
        },
      });

    case "TASK_TEMPLATE_CHANGE_DEPARTMENTS":
      return update(state, {
        taskTemplate: {
          departments: {
            $set: action.values,
          },
        },
      });

    case "TASK_TEMPLATE_CHANGE_CUSTOMERS":
      return update(state, {
        taskTemplate: {
          customers: {
            $set: action.values,
          },
        },
      });

    case "TASK_TEMPLATE_CHANGE_TODO_TEMPLATES":
      return update(state, {
        taskTemplate: {
          todo_templates: {
            $set: action.values,
          },
        },
      });

    case "TASK_TEMPLATE_CHANGE_ASSIGNEE_FILL_TYPE":
      return update(state, {
        taskTemplate: {
          assignee_fill_type: { $set: action.value },
          assignees: {
            $set: action.value == 3 ? [] : state.taskTemplate.assignees,
          },
        },
      });

    case "TASK_TEMPLATE_CHANGE_ASSIGNEES":
      return update(state, {
        taskTemplate: {
          assignees: {
            $set: action.values.length > 0 ? action.values : [],
          },
        },
      });

    case "TASK_TEMPLATE_CHANGE_SUPERVISOR_FILL_TYPE":
      return update(state, {
        taskTemplate: {
          supervisor_fill_type: { $set: action.value },
          supervisors: {
            $set: action.value == 3 ? [] : state.taskTemplate.supervisors,
          },
        },
      });

    case "TASK_TEMPLATE_CHANGE_SUPERVISORS":
      return update(state, {
        taskTemplate: {
          supervisors: {
            $set: action.values.length > 0 ? action.values : [],
          },
        },
      });

    case "TASK_TEMPLATE_CHANGE_MEMBER_FILL_TYPE":
      return update(state, {
        taskTemplate: {
          member_fill_type: { $set: action.value },
          members: {
            $set: action.value == 3 ? [] : state.taskTemplate.members,
          },
        },
      });

    case "TASK_TEMPLATE_CHANGE_MEMBERS":
      return update(state, {
        taskTemplate: {
          members: {
            $set: action.values.length > 0 ? action.values : [],
          },
        },
      });

    // field_sets
    case "TASK_TEMPLATE_FIELD_SET_CREATE":
      return update(state, {
        taskTemplate: { field_sets: { $push: [action.item] } },
      });

    case "TASK_TEMPLATE_FIELD_SET_UPDATE":
      return update(state, {
        taskTemplate: { field_sets: { [action.idx]: { $set: action.item } } },
      });

    case "TASK_TEMPLATE_FIELD_SET_DELETE":
      return update(state, {
        taskTemplate: {
          field_sets: {
            [action.idx]: { _destroy: { $set: true } },
          },
        },
      });

    case "TASK_TEMPLATE_FIELD_SET_DESTROY":
      return update(state, {
        taskTemplate: {
          field_sets: { $splice: [[action.idx, 1]] },
        },
      });

    // filters
    case "TASK_TEMPLATES_CLEAR_FILTERS":
      return update(state, {
        fetchParams: {
          task_owner: { $set: null },
          task_type: { $set: null },
          page: { $set: 1 },
        },
      });

    case "TASK_TEMPLATES_FILTER_TASK_OWNER":
      return update(state, {
        fetchParams: {
          task_owner: { $set: action.value },
          page: { $set: 1 },
        },
      });

    case "TICKETS_FILTER_TASK_TYPE":
      return update(state, {
        fetchParams: {
          task_type: { $set: action.value },
          page: { $set: 1 },
        },
      });

    default:
      return state;
  }
}
