// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Table, Typography, Badge, Tag, DatePicker, Card } from "antd";
import update from "immutability-helper";
import { Row, Col } from "antd";
import AssigneesCell from "../../../components/AssigneesCell";
import SelectMultiFetch from "../../../components/SelectMultiFetch";

import moment from "moment";
import "moment/locale/ru";

const { Paragraph } = Typography;
const { RangePicker } = DatePicker;

function statuses(status) {
  switch (status) {
    case "pended":
      return "error";
    case "accepted":
      return "processing";
    case "closed":
      return "success";
    default:
      return "default";
  }
}

function serviceTag(entity_service) {
  return (
    <Tag key={entity_service.id} color="#108ee9">
      {entity_service.name}
    </Tag>
  );
}

class Tickets extends Component {
  render() {
    const {
      isLoading,
      tickets,
      startCreatedDate,
      endCreatedDate,
      categories,
      onChangePeriodTickets,
      onChangeCategories,
    } = this.props;

    const columns = [
      {
        title: "Статус Приоритет Замена",
        dataIndex: "status",
        render: (status, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <Badge
                status={statuses(record.status.id)}
                text={record.status.name}
              />
            </span>
            <span style={{ opacity: "0.7", fontSize: "12px" }}>
              {record.ticket_priority ? record.ticket_priority.name : null}
            </span>
            <span style={{ opacity: "0.7", fontSize: "12px" }}>
              {record.is_replaced ? "замена" : null}
            </span>
          </div>
        ),
        key: "status",
        align: "center",
        width: "10%",
      },
      {
        title: "#",
        dataIndex: "number",
        render: (number, record) => (
          <Link to={`/tickets/${record.id}/edit`}>{number}</Link>
        ),
        key: "number",
        align: "center",
        width: "10%",
      },
      {
        title: "Дата",
        dataIndex: "created_at",
        render: (created_at) => moment(created_at).format("L"),
        key: "created_at",
        align: "center",
        width: "10%",
      },
      {
        title: "Выполнение",
        dataIndex: "finish",
        render: (finish) => (finish ? moment(finish).format("L") : null),
        key: "finish",
        align: "center",
        width: "10%",
      },
      {
        title: "Тема / Раздел",
        dataIndex: "subject",
        render: (subject, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <Paragraph ellipsis={{ rows: 3, expandable: true }}>
                {subject}
              </Paragraph>
            </span>
            <span>
              {record.category
                ? serviceTag(record.category)
                : record.entity_service
                ? serviceTag(record.entity_service)
                : null}
            </span>
          </div>
        ),
        align: "left",
        width: "23%",
      },
      {
        title: "Ответственный",
        dataIndex: "assignees",
        render: (assignees, record) => <AssigneesCell assignees={assignees} />,
        align: "center",
        width: "18%",
      },
    ];

    return (
      <React.Fragment>
        
        <Row gutter={{ md: 8, lg: 24, xl: 48 }} style={{ paddingTop: 10 }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Table
              loading={isLoading}
              size={"middle"}
              bordered
              pagination={false}
              columns={columns}
              dataSource={tickets}
              rowKey="id"
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

Tickets.propTypes = {
  orderTickets: PropTypes.arrayOf(PropTypes.object),
  total: PropTypes.number,
  discount: PropTypes.object,
  shippingCost: PropTypes.number,
  amountDiscountCode: PropTypes.number,
  onRemoveVariant: PropTypes.func,
};
export default Tickets;
