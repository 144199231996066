// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  DatePicker,
  Select
} from "antd";

import { Row, Col, PageHeader } from "antd";
import _ from "lodash/array";

import PropTypes from "prop-types";
import accounting from "accounting";

import moment from "moment";
import "moment/locale/ru";

const { RangePicker } = DatePicker;
const Option = Select.Option;

class DataList extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  render() {
    const {
      loading,
      report,
      fetchParams,
      period,
      onChangeDateRange,
      onChangePeriod,
      onChangeMode
    } = this.props;

    // render: (effectiveness, record) => `${Number(record.closed / record.total * 100).toFixed(2)}%`,

    const dateFormat = "DD.MM.YYYY";
    let columns = []
    if (fetchParams.mode == 'list') {
      columns = [
        {
          title: "Номер",
          dataIndex: "order_number",
          render: (order_number, record) => (
              <span>
                <Link to={order_number ? `/orders/${record.order_id}/edit` : null} target="_blank">{order_number}</Link>
              </span>
              
          ),
          align: "left",
          width: "25%",
        },
        {
          title: "Дата создания",
          dataIndex: "created_at",
          render: (created_at, record) => created_at ? moment(created_at).format("L") : null,
          align: "center",
          width: "25%",
        },
        {
          title: "Дата поставки",
          dataIndex: "finished_at",
          render: (finished_at, record) => finished_at ? moment(finished_at).format("L") : null,
          align: "center",
          width: "25%",
        },
        {
          title: "Продолжительность, дни",
          dataIndex: "days",
          render: (days) =>
            accounting.formatNumber(days, 0, " "),
          align: "center",
          width: "25%",
        },
        
      ];
    } else {
      columns = [
        {
          title: "Период",
          dataIndex: "period",
          render: (period, record) => period ? moment(period).format("MM.YYYY") : null,
          align: "left",
          width: "25%",
        },
        {
          title: "Продолжительность средняя, дни",
          dataIndex: "days",
          render: (days, record) =>
            accounting.formatNumber(`${Number(days / record.count).toFixed(2)}`, 0, " "),
          align: "center",
          width: "25%",
        },
        {
          title: "Максимум, дни",
          dataIndex: "max",
          align: "center",
          width: "25%",
        },
        {
          title: "Минимум, дни",
          dataIndex: "min",
          align: "center",
          width: "25%",
        },
      ];
    }
    

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Закупки за период c ${fetchParams.startDate} по ${fetchParams.endDate}`}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }} style={{ paddingBottom: 15 }}>
            <Col span={24}>
              <Table
                bordered
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={report}
                rowKey="order_id"
              />
            </Col>
          </Row>
        }
      >
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <RangePicker
              style={{ width: "100%" }}
              value={[
                moment(fetchParams.startDate, dateFormat),
                moment(fetchParams.endDate, dateFormat),
              ]}
              format={dateFormat}
              onChange={onChangeDateRange}
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <Select
              style={{ width: "100%" }}
              value={period}
              placeholder="Период"
              optionFilterProp="children"
              filterOption={false}
              onChange={onChangePeriod}
            >
              <Option value={0}>произвольный период</Option>
              <Option value={1}>этот месяц</Option>
              <Option value={2}>этот квартал</Option>
              <Option value={3}>этот год</Option>
              <Option value={4}>прошлый месяц</Option>
              <Option value={5}>прошлый квартал</Option>
              <Option value={6}>прошлый год</Option>
            </Select>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <Select
              style={{ width: "100%" }}
              value={fetchParams.mode}
              placeholder="Группировка"
              optionFilterProp="children"
              filterOption={false}
              onChange={onChangeMode}
            >
              <Option value={'list'}>список</Option>
              <Option value={'months'}>по месяцам</Option>
            </Select>
          </Col>
          
        </Row>
      </PageHeader>
    );
  }
}

DataList.propTypes = {
  payments: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default DataList;
