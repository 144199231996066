// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Form, DatePicker, Input } from "antd";
import SelectFetch from "../../../../components/SelectFetch";
import moment from "moment";
import "moment/locale/ru";

const FormItem = Form.Item;
const { RangePicker } = DatePicker;

class Header extends Component {
  render() {
    const {
      isNew,
      getFieldDecorator,
      payroll,
      onChangePeriod,
      onChangeDepartment,
      onChangeDescription,
    } = this.props;

    const dateFormat = "DD.MM.YYYY";

    return (
      <React.Fragment>
        <Row gutter={16} style={{ paddingBottom: 15 }}>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <FormItem label={"Период"}>
              {getFieldDecorator("period", {
                onChange: onChangePeriod,
                initialValue: [
                  payroll.start_at ? moment(payroll.start_at) : null,
                  payroll.end_at ? moment(payroll.end_at) : null,
                ],
                rules: [
                  {
                    required: true,
                    message: "Ведите период!",
                  },
                ],
              })(
                <RangePicker
                  style={{ width: "100%" }}
                  format={dateFormat}
                  placeholder={["начало", "окончание"]}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <FormItem label={"Подразделение"}>
              {getFieldDecorator("department", {
                initialValue: payroll.department ? payroll.department : null,
                rules: [
                  {
                    required: true,
                    message: "Ведите подразделение!",
                  },
                ],
              })(
                <SelectFetch
                  showSearch
                  placeholder="Подразделение"
                  onChange={onChangeDepartment}
                  selected={payroll.department ? payroll.department : null}
                  url={"/departments/search/?"}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={12} sm={12} md={12} lg={8} xl={8}>
            <FormItem label={"Описание"}>
              {getFieldDecorator("description", {
                validateTrigger: "onBlur",
                // onChange: onChangeDescription,
                initialValue: payroll.description,
              })(<Input onBlur={onChangeDescription} />)}
            </FormItem>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

Header.propTypes = {};

export default Header;
