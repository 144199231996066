// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, List, Divider, Select, Tabs } from "antd";
import Statistics from "./Statistics";
import Contacts from "./Contacts";

const { TabPane } = Tabs;

const Option = Select.Option;

class Statuses extends Component {
  render() {
    const {
      isNew,
      isContactsLoading,
      customer,
      onSaveContact,
      onDeleteContact
    } = this.props;

    return (
      <React.Fragment>
        <Row gutter={10}>
          <Statistics />
        </Row>
        <Row gutter={10}>
          <Contacts
            isNew={isNew}
            isContactsLoading={isContactsLoading}
            contacts={customer.contacts}
            onSaveContact={onSaveContact}
            onDeleteContact={onDeleteContact}
          />
        </Row>
      </React.Fragment>
    );
  }
}
Statuses.propTypes = {
  customer: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  onAdressEdit: PropTypes.func,
  onChangePaymentType: PropTypes.func,
  onChangePaymentStatus: PropTypes.func,
  onChangeShippingStatus: PropTypes.func,
  onChangeShippingMethod: PropTypes.func
};

export default Statuses;
