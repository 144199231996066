import React from "react";
import { List, Avatar } from "antd";
// import UserInfo from "../UserInfo";

export default function Member({ member = null }) {
  return member ? (
    <List>
      <List.Item>
        <List.Item.Meta
          avatar={<Avatar src={member.avatar ? member.avatar : null} />}
          title={member.name ? member.name : ""}
          description={member.title ? member.title : ""}
        />
      </List.Item>
    </List>
  ) : null;
}
