// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../../actions/supportMessages";
import { Spin } from "antd";
import SupportMessageForm from "./Form";

class SupportMessage extends Component {
  componentWillMount = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(
        Actions.fetchSupportMessage(this.props.match.params.id)
      );
    }
  };

  onClose = () => {
    this.props.history.push(`/support_messages`);
  };

  // comments
  onCreateComment = value => {
    this.props
      .dispatch(Actions.onCreateComment(this.props.match.params.id, value))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchSupportMessageComments(this.props.match.params.id)
          );
        }
      });
  };

  render() {
    const { isLoading, isCommentsLoading, errors, supportMessage } = this.props;
    return isLoading ? (
      <Spin />
    ) : (
      <SupportMessageForm
        errors={errors}
        supportMessage={supportMessage}
        onClose={this.onClose}
        // comments
        isCommentsLoading={isCommentsLoading}
        onCreateComment={this.onCreateComment}
      />
    );
  }
}

SupportMessage.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  supportMessage: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool
};

const mapStateToProps = state => ({
  isLoading: state.supportMessages.isLoading,
  errors: state.supportMessages.errors,
  supportMessage: state.supportMessages.supportMessage,
  isCommentsLoading: state.supportMessages.isCommentsLoading
});

export default connect(mapStateToProps)(withRouter(SupportMessage));
