// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Table, Typography, Tag } from "antd";
import { Row, Col } from "antd";
import OrderProgress from "../../../components/OrderProgress";
import AssigneesCell from "../../../components/AssigneesCell";

import moment from "moment";
import "moment/locale/ru";

const { Paragraph } = Typography;

function serviceTag(entity_service) {
  return (
    <Tag key={entity_service.id} color="#108ee9">
      {entity_service.name}
    </Tag>
  );
}

class Orders extends Component {
  render() {
    const { isLoading, orders } = this.props;

    const columns = [
      {
        title: "Выполнение",
        dataIndex: "status",
        render: (status, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <OrderProgress orderId={record.id} progress={record.progress}/>
            </span>
            <span style={{ opacity: "0.7" }}>{record.status_locale}</span>
          </div>
        ),
        key: "status",
        align: "center",
        width: "20%",
      },
      {
        title: "Номер / Номер КП",
        dataIndex: "number",
        render: (number, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <Link to={`/orders/${record.id}/edit`}>{number}</Link>
            </span>
            <span style={{ opacity: "0.7" }}>{record.number_int}</span>
          </div>
        ),
        key: "number",
        align: "center",
        width: "15%",
      },
      {
        title: "Дата",
        dataIndex: "created_at",
        render: (created_at) => moment(created_at).format("L"),
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "created_at",
        align: "center",
        width: "15%",
      },
      {
        title: "Тема / Раздел",
        dataIndex: "subject",
        render: (subject, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Paragraph ellipsis={{ rows: 3, expandable: true }}></Paragraph>
            <span>
              <Paragraph ellipsis={{ rows: 3, expandable: true }}>
                {subject}
              </Paragraph>
            </span>
            <span>
              {serviceTag(record.entity_service)}
              {/* {record.entity_service ? record.entity_service.name : null} */}
            </span>
          </div>
        ),
        align: "left",
        width: "25%",
      },
      {
        title: "Ответственный",
        dataIndex: "assignees",
        render: (assignees, record) => <AssigneesCell assignees={assignees} />,
        align: "center",
        width: "25%",
      },
    ];

    return (
      <div>
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col span={24}>
            <Table
              loading={isLoading}
              size={"middle"}
              bordered
              pagination={false}
              columns={columns}
              dataSource={orders}
              rowKey="id"
            />
          </Col>
        </Row>
      </div>
    );
  }
}

Orders.propTypes = {
  orderOrders: PropTypes.arrayOf(PropTypes.object),
  total: PropTypes.number,
  discount: PropTypes.object,
  shippingCost: PropTypes.number,
  amountDiscountCode: PropTypes.number,
  onRemoveVariant: PropTypes.func,
};
export default Orders;
