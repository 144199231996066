import update from "immutability-helper";
import moment from "moment";

const initialState = {
  orders: [],
  order: {
    assignees: [],
    order_services: [],
    comments: [],
    tasks: [],
    schedules: [],
    proposals: [],
    attachments: [],
  },
  orderActivities: [],
  notices: [],
  tasks: [],
  order_tasks: [],
  actions: [],
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1,
  },
  fetchParams: {
    startDate: null,
    endDate: null,
    starFinish: null,
    endFinish: null,
    orderTaskStatus: null,
    statusTaskType: null,
    is_ordered: null,
    search: null,
    sort: null,
    direction: null,
    page: 1,
    limit: 10,
    status: null,
    users: null,
    customer: null,
    members: null,
    services: null,
  },
  errors: false,
  isLoading: true,
  isLoadingProposals: false,
  isAttachmentLoading: false,
  isAssigneesLoading: false,
  isOrderTasksLoading: false,
  isTasksLoading: false,
  isSchedulesLoading: false,
  isCommentsLoading: false,
  isActivitiesLoading: true,
};

function loadLocalOrdersFilters(fetchParams) {
  let localFetchParams = null;
  const ordersFilters = localStorage.getItem("ordersFilters");
  if (ordersFilters) {
    localFetchParams = JSON.parse(ordersFilters);
  } else {
    localStorage.setItem("ordersFilters", JSON.stringify(fetchParams));
  }

  return localFetchParams;
}

export default function (state = initialState, action) {
  let localFetchParams = null;
  switch (action.type) {
    case "FETCH_ORDERS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_ORDERS":
      return {
        ...state,
        orders: action.response.data,
        meta: action.response.meta,
        notices: action.response.notices,
        actions: action.response.actions,
        isLoading: false,
      };
    case "FETCH_ORDER_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_ORDER":
      return {
        ...state,
        order: action.response.data,
        isLoading: false,
      };
    case "PATCH_ORDER_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };
    case "PATCH_ORDER":
      return {
        ...state,
        order: action.response.data,
        isLoading: false,
        errors: false,
      };

    case "PATCH_ORDER_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "POST_ORDER_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "POST_ORDER":
      return {
        ...state,
        errors: false,
        order: action.response.data,
        isLoading: false,
      };
    case "POST_ORDER_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "NEW_ORDER_FOR_TASK_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "NEW_ORDER_FOR_TASK_SUCCESS":
      return {
        ...state,
        order: action.response.data,
        isLoading: false,
      };
    case "NEW_ORDER_FOR_TASK_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "CREATE_ORDER_FOR_TASK_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "CREATE_ORDER_FOR_TASK_SUCCESS":
      return {
        ...state,
        errors: false,
        order: action.response.data,
        isLoading: false,
      };
    case "CREATE_ORDER_FOR_TASK_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "DELETE_ORDER_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };
    case "DELETE_ORDER":
      return {
        ...state,
        orders: action.response.data,
        isLoading: false,
        errors: false,
      };
    case "DELETE_ORDER_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "ORDER_NEW":
      return {
        ...state,
        errors: false,
        order: {
          customer: null,
          status: "pended",
          user: action.user,
          start: moment(),
          finish: moment(),
          amount: 0,
          service_price: 0,
          product_price: 0,
          assignees: [],
          order_services: [],
          comments: [],
          order_tasks: [],
          schedules: [],
          proposals: [],
          attachments: [],
        },
        isLoading: false,
      };

    case "REQUEST_ORDER_SEARCH":
      return update(state, {
        fetchParams: { search: { $set: action.value }, page: { $set: 1 } },
      });

    case "REQUEST_ORDER_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } },
      });

    case "REQUEST_ORDER_CHANGE_LIMIT":
      return update(state, {
        fetchParams: { limit: { $set: action.value }, page: { $set: 1 } },
      });

    case "REQUEST_ORDERS_FILTER_ACTIVE":
      return update(state, {
        fetchParams: { active: { $set: action.value } },
      });

    case "ORDERS_SORT_BY":
      return update(state, {
        fetchParams: {
          sort: { $set: action.field },
          direction: { $set: action.direction },
        },
      });

    //general
    case "ORDER_CHANGE_DUE_BY":
      return update(state, {
        order: { due_by: { $set: action.value } },
      });

    case "ORDER_CHANGE_NUMBER_INT":
      return update(state, {
        order: { number_int: { $set: action.value } },
      });

    case "ORDER_CHANGE_NUMBER":
      return update(state, {
        order: { number: { $set: action.value } },
      });

    case "ORDER_CHANGE_CUSTOMER":
      return update(state, {
        order: {
          customer: { $set: action.value },
          entity: { $set: null },
          entity_service: { $set: null },
          address: { $set: null },
        },
      });

    case "ORDER_CHANGE_CUSTOMER_ENTITY":
      return update(state, {
        order: {
          entity: { $set: action.value },
          entity_service: { $set: null },
          address: { $set: action.value.extra.address },
        },
      });

    case "ORDER_CHANGE_ENTITY_SERVICE":
      return update(state, {
        order: { entity_service: { $set: action.value } },
      });

    case "ORDER_CHANGE_AMOUNT":
      return update(state, {
        order: {
          amount: { $set: action.value },
          // service_price: {$apply: function() {return parseFloat(action.value) - parseFloat(state.order.product_price)}}
        },
      });

    case "ORDER_CHANGE_SUBJECT":
      return update(state, {
        order: { subject: { $set: action.value } },
      });

    case "ORDER_CHANGE_DESCRIPTION":
      return update(state, {
        order: { description: { $set: action.value } },
      });

    // statuses
    case "PATCH_ORDER_REQUEST_STATUS":
      return {
        ...state,
        errors: false,
      };
    case "PATCH_ORDER_STATUS":
      return update(state, {
        order: { status: { $set: action.response.data } },
      });

    case "PATCH_ORDER_FAILURE_STATUS":
      return {
        ...state,
        errors: true,
      };

    case "ORDER_CHANGE_IS_ORGANISATION":
      return update(state, {
        order: {
          is_organisation: { $set: action.value },
          entity: { $set: null },
          entity_service: { $set: null },
          customer: { $set: null },
          company: { $set: null },
        },
      });

    case "ORDER_CHANGE_IS_SUPPLIER":
      return update(state, {
        order: { is_supplier: { $set: action.value } },
      });

    case "ORDER_CHANGE_PRIORITY":
      return update(state, {
        order: { priority: { $set: action.value } },
      });

    case "ORDER_CHANGE_COMPANY":
      return update(state, {
        order: { company: { $set: action.value } },
      });

    // activities
    case "FETCH_ORDER_ACTIVITIES_REQUEST":
      return {
        ...state,
        isActivitiesLoading: true,
      };
    case "FETCH_ORDER_ACTIVITIES":
      return update(state, {
        orderActivities: { $set: action.response.data },
        isActivitiesLoading: { $set: false },
        errors: { $set: false },
      });

    case "FETCH_ORDER_ACTIVITIES_FAILURE":
      return {
        ...state,
        errors: true,
        isActivitiesLoading: false,
      };

    // assignees
    case "FETCH_ASSIGNEES_REQUEST":
      return {
        ...state,
        isAssigneesLoading: true,
      };
    case "FETCH_ASSIGNEES":
      return update(state, {
        order: { assignees: { $set: action.response.data } },
        isAssigneesLoading: { $set: false },
      });

    case "FETCH_ASSIGNEES_FAILURE":
      return {
        ...state,
        errors: true,
        isAssigneesLoading: false,
      };

    case "POST_ASSIGNEE_REQUEST":
      return {
        ...state,
        errors: false,
        isAssigneesLoading: true,
      };
    case "POST_ASSIGNEE":
      return {
        ...state,
        errors: false,
        isAssigneesLoading: false,
      };

    case "POST_ASSIGNEE_FAILURE":
      return {
        ...state,
        errors: true,
        isAssigneesLoading: false,
      };

    // order_tasks
    case "FETCH_ORDER_ORDER_TASKS_REQUEST":
      return {
        ...state,
        isTasksLoading: true,
      };
    case "FETCH_ORDER_ORDER_TASKS":
      return update(state, {
        order: { order_tasks: { $set: action.response.data } },
        isOrderTasksLoading: { $set: false },
      });

    case "FETCH_ORDER_ORDER_TASKS_FAILURE":
      return {
        ...state,
        errors: true,
        isOrderTasksLoading: false,
      };

    case "POST_ORDER_ORDER_TASK_REQUEST":
      return {
        ...state,
        errors: false,
        isOrderTasksLoading: true,
      };
    case "POST_ORDER_ORDER_TASK":
      return {
        ...state,
        errors: false,
        isOrderTasksLoading: false,
      };

    case "POST_ORDER_ORDER_TASK_FAILURE":
      return {
        ...state,
        errors: true,
        isOrderTasksLoading: false,
      };

    // schedules
    case "FETCH_ORDER_SCHEDULES_REQUEST":
      return {
        ...state,
        isSchedulesLoading: true,
      };
    case "FETCH_ORDER_SCHEDULES":
      return update(state, {
        order: { schedules: { $set: action.response.data } },
        isSchedulesLoading: { $set: false },
      });

    case "FETCH_ORDER_SCHEDULES_FAILURE":
      return {
        ...state,
        errors: true,
        isSchedulesLoading: false,
      };

    case "PATCH_ORDER_SCHEDULE_REQUEST":
      return {
        ...state,
        isSchedulesLoading: true,
        errors: false,
      };
    case "PATCH_ORDER_SCHEDULE":
      return {
        ...state,
        isSchedulesLoading: false,
        errors: false,
      };

    case "PATCH_ORDER_SCHEDULE_FAILURE":
      return {
        ...state,
        isSchedulesLoading: false,
        errors: true,
      };

    case "POST_ORDER_SCHEDULE_REQUEST":
      return {
        ...state,
        errors: false,
        isSchedulesLoading: true,
      };
    case "POST_ORDER_SCHEDULE":
      return {
        ...state,
        errors: false,
        isSchedulesLoading: false,
      };

    case "POST_ORDER_SCHEDULE_FAILURE":
      return {
        ...state,
        errors: true,
        isSchedulesLoading: false,
      };

    case "DELETE_ORDER_SCHEDULE_REQUEST":
      return {
        ...state,
        isSchedulesLoading: true,
        errors: false,
      };
    case "DELETE_ORDER_SCHEDULE":
      return {
        ...state,
        isSchedulesLoading: false,
        errors: false,
      };

    case "DELETE_ORDER_SCHEDULE_FAILURE":
      return {
        ...state,
        isSchedulesLoading: false,
        errors: true,
      };

    // comments
    case "FETCH_ORDER_COMMENTS_REQUEST":
      return {
        ...state,
        isCommentsLoading: true,
      };
    case "FETCH_ORDER_COMMENTS":
      return update(state, {
        order: { comments: { $set: action.response.data } },
        isCommentsLoading: { $set: false },
      });

    case "FETCH_ORDER_COMMENTS_FAILURE":
      return {
        ...state,
        errors: true,
        isCommentsLoading: false,
      };

    case "POST_ORDER_COMMENT_REQUEST":
      return {
        ...state,
        errors: false,
        isCommentsLoading: true,
      };
    case "POST_ORDER_COMMENT":
      return {
        ...state,
        errors: false,
        isCommentsLoading: false,
      };

    case "POST_ORDER_COMMENT_FAILURE":
      return {
        ...state,
        errors: true,
        isCommentsLoading: false,
      };

    // proposals
    case "FETCH_ORDER_PROPOSALS_REQUEST":
      return {
        ...state,
        isLoadingProposals: true,
      };

    case "FETCH_ORDER_PROPOSALS":
      return update(state, {
        order: { proposals: { $set: action.response.data } },
        isLoadingProposals: { $set: false },
      });

    case "FETCH_ORDER_PROPOSALS":
      return update(state, {
        order: { proposals: { $set: action.response.data } },
        isLoadingProposals: { $set: false },
      });

    case "ORDER_PROPOSAL_UPLOAD":
      return update(state, {
        isLoadingProposals: { $set: true },
      });

    case "ORDER_PROPOSAL_UPLOAD_SUCCEED":
      return update(state, {
        order: { proposals: { $set: action.response.data } },
        errors: { $set: false },
        isLoadingProposals: { $set: false },
      });

    case "ORDER_PROPOSAL_UPLOAD_FAILED":
      return update(state, {
        errors: { $set: true },
        isLoadingProposals: { $set: false },
      });

    case "ORDER_PROPOSAL_DELETE_REQUEST":
      return {
        ...state,
        errors: false,
        isLoadingProposals: true,
      };
    case "ORDER_PROPOSAL_DELETE_SUCCEED":
      return {
        ...state,
        errors: false,
        isLoadingProposals: false,
      };
    case "ORDER_PROPOSAL_DELETE_FAILED":
      return {
        ...state,
        errors: true,
        isLoadingProposals: false,
      };

    // attachments
    case "FETCH_ATTACHMENTS":
      return update(state, {
        order: { attachments: { $set: action.response.data } },
        isAttachmentLoading: { $set: false },
      });
    case "FETCH_ATTACHMENT_REQUEST":
      return {
        ...state,
        isAttachmentLoading: true,
      };

    case "ATTACHMENT_UPLOAD":
      return update(state, {
        isAttachmentLoading: { $set: true },
      });

    case "ATTACHMENT_UPLOAD_SUCCEED":
      return update(state, {
        order: { attachments: { $set: action.response.data } },
        errors: { $set: false },
        isAttachmentLoading: { $set: false },
      });

    case "ATTACHMENT_UPLOAD_FAILED":
      return update(state, {
        errors: { $set: true },
        isAttachmentLoading: { $set: false },
      });

    case "ATTACHMENT_DELETE_REQUEST":
      return {
        ...state,
        errors: false,
        isAttachmentLoading: true,
      };
    case "ATTACHMENT_DELETE_SUCCEED":
      return {
        ...state,
        errors: false,
        isAttachmentLoading: false,
      };
    case "ATTACHMENT_DELETE_FAILED":
      return {
        ...state,
        errors: true,
        isAttachmentLoading: false,
      };

    // filters
    case "ORDERS_CLEAR_FILTERS":
      localStorage.removeItem("ordersFilters");

      return update(state, {
        fetchParams: {
          status: { $set: null },
          users: { $set: null },
          members: { $set: null },
        },
      });

    case "ORDERS_SET_FILTERS":
      localFetchParams = loadLocalOrdersFilters(state.fetchParams);
      if (localFetchParams) {
        localFetchParams.search = null;
        return {
          ...state,
          fetchParams: localFetchParams,
        };
      } else {
        return {
          ...state,
        };
      }

    case "ORDERS_FILTER_STATUS":
      return update(state, {
        fetchParams: {
          status: { $set: action.values },
          page: { $set: 1 },
        },
      });

    case "ORDER_TASKS_FILTER_STATUS":
      return update(state, {
        fetchParams: {
          orderTaskStatus: { $set: action.value },
          statusTaskType: { $set: action.statusTaskType },
          page: { $set: 1 },
        },
      });

    case "ORDERS_FILTER_ORDERED":
      return update(state, {
        fetchParams: {
          is_ordered: {
            $set: action.value,
          },
          page: { $set: 1 },
        },
      });

    case "ORDERS_FILTER_PERIOD":
      return update(state, {
        fetchParams: {
          startDate: { $set: action.startDate },
          endDate: { $set: action.endDate },
          starFinish: { $set: null },
          endFinish: { $set: null },
          page: { $set: 1 },
        },
      });

    case "ORDERS_FILTER_PERIOD_FINISH":
      return update(state, {
        fetchParams: {
          starFinish: { $set: action.starFinish },
          endFinish: { $set: action.endFinish },
          startDate: { $set: null },
          endDate: { $set: null },
          page: { $set: 1 },
        },
      });

    case "ORDERS_FILTER_USERS":
      return update(state, {
        fetchParams: {
          users: { $set: action.values.length > 0 ? action.values : null },
          page: { $set: 1 },
        },
      });

    case "ORDERS_FILTER_CUSTOMER":
      return update(state, {
        fetchParams: {
          customer: { $set: action.value },
          page: { $set: 1 },
        },
      });

    case "ORDERS_FILTER_MEMBERS":
      return update(state, {
        fetchParams: {
          members: { $set: action.values.length > 0 ? action.values : null },
          page: { $set: 1 },
        },
      });

    case "ORDERS_FILTER_SERVICES":
      return update(state, {
        fetchParams: {
          services: { $set: action.values.length > 0 ? action.values : null },
          page: { $set: 1 },
        },
      });

    // finance
    case "ORDER_CHANGE_PRODUCT_PRICE":
      return update(state, {
        order: {
          product_price: { $set: action.value },
          amount: {
            $apply: function () {
              return (
                parseFloat(state.order.service_price) + parseFloat(action.value)
              );
            },
          },
        },
      });

    case "ORDER_CHANGE_SERVICE_PRICE":
      return update(state, {
        order: {
          service_price: { $set: action.value },
          amount: {
            $apply: function () {
              return (
                parseFloat(state.order.product_price) + parseFloat(action.value)
              );
            },
          },
        },
      });

    case "ORDER_CHANGE_PENALTY":
      return update(state, {
        order: { penalty: { $set: action.value } },
      });

    case "ORDER_CHANGE_PAYMENT":
      return update(state, {
        order: { payment: { $set: action.value } },
      });

    // notices
    case "POST_ORDER_MARK_ALL_AS_READ_REQUEST":
      return {
        ...state,
        errors: false,
      };
    case "POST_ORDER_MARK_ALL_AS_READ":
      return {
        ...state,
        errors: false,
        notices: action.response.data,
      };

    case "POST_ORDER_MARK_ALL_AS_READ_FAILURE":
      return {
        ...state,
        errors: true,
      };

    // tasks
    case "FETCH_ORDER_TASKS_REQUEST":
      return {
        ...state,
        isTasksLoading: true,
      };
    case "FETCH_ORDER_TASKS":
      return update(state, {
        tasks: { $set: action.response.data },
        isTasksLoading: { $set: false },
        errors: { $set: false },
      });

    case "FETCH_ORDER_TASKS_FAILURE":
      return {
        ...state,
        errors: true,
        isTasksLoading: false,
      };

    default:
      return state;
  }
}
