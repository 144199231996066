// @flow
import React, {Component} from "react";
import PropTypes from "prop-types";
import {Button, Card, Checkbox, Col, Form, Input, List, Row} from "antd";
import ExtCodes from "./ExtCodes";

const { TextArea } = Input;
const FormItem = Form.Item;

class ServiceForm extends Component {
  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSave(values);
      }
    });
  };

  render() {
    const {
      isNew,
      isLoading,
      onClose,
      service,
      onChangeIsAvailable,
      onChangeName,
      onChangeDescription,
      onChangeExtCode,
      onAppendExtCode,
      onDeleteExtCode,
    } = this.props;

    const { getFieldDecorator } = this.props.form;

    const displayedExtCodes = (service.service_extcodes ?? []).filter((it) => !it._destroy);

    return (
      <Col xs={24} sm={24} md={24} lg={17} xl={17}>
        <Card
          loading={isLoading}
          title={
            <h3 style={{ marginBottom: 0 }}>
              {isNew ? "новый" : service.name}
            </h3>
          }
          extra={
            <div>
              <Button onClick={onClose}>Закрыть</Button>
              <Button
                type="primary"
                ghost
                style={{ marginLeft: "8px" }}
                onClick={this.onSubmit}
              >
                Сохранить
              </Button>
            </div>
          }
        >
          <Form layout={"vertical"} onSubmit={this.onSubmit}>
            <Row>
              <Col xs={24} sm={24} md={24} lg={9} xl={15}>
                <Row gutter={16}>
                  <Col span={24}>
                    <FormItem label="Наименование">
                      {getFieldDecorator("name", {
                        initialValue: service.name,
                        onChange: onChangeName,
                        rules: [
                          {
                            required: true,
                            message: "Введите наименование!",
                          },
                        ],
                      })(<Input />)}
                    </FormItem>
                  </Col>
                  <Col span={24}>
                    <FormItem label="Описание">
                      {getFieldDecorator("description", {
                        onChange: onChangeDescription,
                        initialValue: service.description,
                      })(<TextArea autoSize={{ minRows: 2, maxRows: 3 }} />)}
                    </FormItem>
                  </Col>
                  <Col span={24}>
                    <FormItem label="Внешние коды">
                      <ExtCodes
                          extCodes={displayedExtCodes}
                          onAppendExtCode={onAppendExtCode}
                          onDeleteExtCode={onDeleteExtCode}
                      />
                    </FormItem>
                  </Col>
                </Row>
              </Col>
              <Col xs={0} sm={0} md={1} lg={2} xl={2} />
              <Col xs={24} sm={24} md={24} lg={7} xl={7}>
                <Card style={{ borderColor: "#1890ff" }}>
                  <Row gutter={16}>
                    <Col span={24}>
                      <FormItem>
                        {getFieldDecorator("is_available", {
                          initialValue: service.is_available,
                          valuePropName: "checked",
                          onChange: onChangeIsAvailable,
                        })(<Checkbox>доступность</Checkbox>)}
                      </FormItem>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Form>
        </Card>
      </Col>
    );
  }
}

ServiceForm.propTypes = {
  service: PropTypes.object,
};

export default Form.create()(ServiceForm);
