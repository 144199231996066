import api from "../api";

const Actions = {};

Actions.fetchMembers = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_MEMBERS_REQUEST" });
    const params = getState().members.fetchParams;
    api
      .fetch("/members", params)
      .then(response => {
        dispatch({ type: "FETCH_MEMBERS", response });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({ type: "FETCH_MEMBERS_FAILURE", response });
      });
  };
};

Actions.fetchMember = (id = null) => {
  return dispatch => {
    dispatch({ type: "FETCH_MEMBER_REQUEST" });
    api
      .fetch(`/members/${id}`)
      .then(response => {
        dispatch({ type: "FETCH_MEMBER", response });
      })
      .catch(error => {
        api.handleError(error, "Пользователь");
        dispatch({ type: "FETCH_MEMBER_FAILURE" });
      });
  };
};

Actions.onNew = () => {
  return dispatch => {
    dispatch({
      type: "MEMBER_NEW",
    });
  };
};

Actions.onUpdate = id => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_MEMBER_REQUEST" });
    const member = getState().members.member;
    const data = {
      member: {
        is_active: member.is_active,
        role: member.role,
        title: member.title,
        email: member.email,
        phone: member.phone,
        name: member.name,
        customer_id: member.customer ? member.customer.id : null,
      }
    };
    api
      .patch(`/members/${id}`, data)
      .then(response => {
        dispatch({ type: "PATCH_MEMBER", response });
        api.openNotification("success", "Пользователь", "Успешно обновлен!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Пользователь", error);
          });
        }
        dispatch({ type: "PATCH_MEMBER_FAILURE", response });
      });
  };
};

Actions.onUpdatePassword = () => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_PASSWORD_MEMBER_REQUEST" });
    const member = getState().members.member;
    const data = {
      member: {
        password: member.password,
        password_confirmation: member.password_confirmation
      }
    };
    api
      .patch(`/members/${member.id}/update_password`, data)
      .then(response => {
        dispatch({ type: "PATCH_PASSWORD_MEMBER", response });
        api.openNotification("success", "Пароль", "Успешно обновлен!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Пароль", error);
          });
        }
        dispatch({ type: "PATCH_PASSWORD_MEMBER_FAILURE", response });
      });
  };
};

Actions.onCreate = () => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_MEMBER_REQUEST" });
    const member = getState().members.member;
    const data = {
      member: {
        is_active: member.is_active,
        role: member.role,
        title: member.title,
        email: member.email,
        phone: member.phone,
        name: member.name,
        customer_id: member.customer ? member.customer.id : null,
        password: member.password,
        password_confirmation: member.password_confirmation
      }
    };
    return api
      .post(`/members`, data)
      .then(response => {
        dispatch({ type: "POST_MEMBER", response });
        api.openNotification(
          "success",
          "Пользователь системы",
          "Успешно создан!"
        );
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Пользователь системы", error);
          });
        }
        dispatch({ type: "POST_MEMBER_FAILURE", response });
      });
  };
};

Actions.onDelete = id => {
  return dispatch => {
    dispatch({ type: "DELETE_MEMBER_REQUEST" });
    return api
      .delete(`/members/${id}`)
      .then(response => {
        api.openNotification("success", "Пользователь", "Успешно удален!");
        dispatch({ type: "DELETE_MEMBER", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Пользователь", error);
          });
        }
        dispatch({
          type: "DELETE_MEMBER_FAILURE"
        });
      });
  };
};

Actions.onUploadAvatar = (id, file) => {
  return dispatch => {
    dispatch({
      type: "MEMBER_AVATAR_UPLOAD"
    });

    const formData = new FormData();
    formData.append("file", file);
    return api
      .patchFormData(`/members/${id}/avatar`, formData)
      .then(response => {
        api.openNotification(
          "success",
          "Пользователь",
          "Аватар успешно загружен!"
        );
        dispatch({ type: "MEMBER_AVATAR_UPLOAD_SUCCEED", response });
      })
      .catch(response => {
        dispatch({
          type: "MEMBER_AVATAR_UPLOAD_FAILED"
        });
        dispatch({
          type: "ADD_NOTIFICATION",
          message: { label: response.error, type: "error" }
        });
      });
  };
};

Actions.onSearch = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_MEMBER_SEARCH",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangePage = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_MEMBER_CHANGE_PAGE",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangeLimit = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_MEMBER_CHANGE_LIMIT",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterActive = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_MEMBERS_FILTER_ACTIVE",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterCustomer = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_MEMBERS_FILTER_CUSTOMER",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onSortBy = (field, direction) => {
  return dispatch => {
    dispatch({
      type: "MEMBERS_SORT_BY",
      field: field,
      direction: direction
    });
    return Promise.resolve();
  };
};

Actions.onChangeActive = value => {
  return dispatch => {
    dispatch({
      type: "MEMBER_CHANGE_ACTIVE",
      value: value
    });
  };
};

Actions.onChangeRole = value => {
  return dispatch => {
    dispatch({
      type: "MEMBER_CHANGE_ROLE",
      value: value
    });
  };
};

Actions.onChangeName = value => {
  return dispatch => {
    dispatch({
      type: "MEMBER_CHANGE_NAME",
      value: value
    });
  };
};

Actions.onChangeCustomer = (value) => {
  return dispatch => {
    dispatch({
      type: "MEMBER_CHANGE_CUSTOMER",
      value: value
    });
  };
};

Actions.onChangeEmail = value => {
  return dispatch => {
    dispatch({
      type: "MEMBER_CHANGE_EMAIL",
      value: value
    });
  };
};

Actions.onChangeTitle = value => {
  return dispatch => {
    dispatch({
      type: "MEMBER_CHANGE_TITLE",
      value: value
    });
  };
};

Actions.onChangePhone = value => {
  return dispatch => {
    dispatch({
      type: "MEMBER_CHANGE_PHONE",
      value: value
    });
  };
};


Actions.onChangePassword = value => {
  return dispatch => {
    dispatch({
      type: "MEMBER_CHANGE_PASSWORD",
      value: value
    });
  };
};

Actions.onChangePasswordConfirmation = value => {
  return dispatch => {
    dispatch({
      type: "MEMBER_CHANGE_PASSWORD_CONFIRMATION",
      value: value
    });
  };
};

export default Actions;
