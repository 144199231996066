// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Actions from "../../../../actions/payrollSchemas";
import PayrollSchemasList from "./List";

class PayrollSchemas extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.fetchPayrollSchemas());
  };

  onDelete = ids => {
    ids.map(id => {
      this.props.dispatch(Actions.onDelete(id)).then(() => {
        this.props.dispatch(Actions.fetchPayrollSchemas());
      });
    });
  };


  onChangePage = value => {
    this.props
      .dispatch(Actions.onChangePage(value))
      .then(() => this.props.dispatch(Actions.fetchPayrollSchemas()));
  };

  onChangeLimit = (current, size) => {
    this.props
      .dispatch(Actions.onChangeLimit(size))
      .then(() => this.props.dispatch(Actions.fetchPayrollSchemas()));
  };


  onSortBy = (field, direction) => {
    this.props
      .dispatch(Actions.onSortBy(field, direction))
      .then(() => this.props.dispatch(Actions.fetchPayrollSchemas()));
  };

  render() {
    const { isLoading, payrollSchemas, meta, fetchParams } = this.props;
    return (
      <PayrollSchemasList
        loading={isLoading}
        payrollSchemas={payrollSchemas}
        meta={meta}
        fetchParams={fetchParams}
        onDelete={this.onDelete}
        onChangePage={this.onChangePage}
        onChangeLimit={this.onChangeLimit}
        onSortBy={this.onSortBy}
      />
    );
  }
}

PayrollSchemas.propTypes = {
  payrollSchemas: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  fetchParams: PropTypes.object,
  meta: PropTypes.object,
  dispatch: PropTypes.func,
  onDelete: PropTypes.func
};

const mapStateToProps = state => ({
  isLoading: state.payrollSchemas.isLoading,
  payrollSchemas: state.payrollSchemas.payroll_schemas,
  meta: state.payrollSchemas.meta,
  fetchParams: state.payrollSchemas.fetchParams
});

export default connect(mapStateToProps)(PayrollSchemas);
