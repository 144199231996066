// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import {Table, Button, PageHeader, Badge} from "antd";
import { Pagination } from "antd";
import { Row, Col, Icon, Input } from "antd";
import Can from "../../../rules/Can";
import Presentation from "../../../components/Presentation";
import AssigneesCell from "../../../components/AssigneesCell";
import NoticeIcon from "../../../components/NoticeIcon";

import PropTypes from "prop-types";

import moment from "moment";
import "moment/locale/ru";
import Filter from "./Filter";

const Search = Input.Search;

class OrderTasksList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      isFilterVisible: false,
    };
  }

  onFilterToggle = () => {
    this.setState({ isFilterVisible: !this.state.isFilterVisible });
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  onDelete = () => {
    this.props.onDelete(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  onChangeTable = (pagination, filters, sorter) => {
    let direction = "desc";
    if (sorter.order === "descend") {
      direction = "desc";
    } else if (sorter.order === "ascend") {
      direction = "asc";
    }
    this.props.onSortBy(sorter.field, direction);
  };

  onItemClick = (item) => {
    this.props.history.push(`/order_tasks/${item.parent_id}/edit`);
  };

  onClearNotices = () => {
    this.props.onClearNotices();
  };

  render() {
    const { selectedRowKeys, isFilterVisible } = this.state;
    const {
      loading,
      currentUser,
      orderTasks,
      meta,
      fetchParams,
      notices,
      onSearch,
      onChangePage,
      onChangeLimit,
      // filters
      onClearFilters,
      onChangeFilterStatus,
      onChangeFilterTaskType,
      onChangeFilterPeriod,
      onChangeFilterUsers,
      onChangeFilterMembers,
      onChangeFilterOrderUsers,
      onChangeFilterNoAssignees,
    } = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;
    const dateFormat = "DD.MM.YYYY";
    const timeFormat = "LT";

    const columns = [
      {
        title: "Статус",
        dataIndex: "status",
        key: "status",
        render: (status, record) => status.name,
        width: "10%",
        align: "center",
      },
      {
        title: (
          <div style={{ textAlign: "center" }}>
            <span>Номер</span>
            <br />
            <span>Тип</span>
            <br />
            <span>Заказ</span>
          </div>
        ),
        dataIndex: "number",
        key: "number",
        render: (number, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <Link to={`/order_tasks/${record.id}/edit`}>{number}</Link>
            </span>
            <span style={{ fontSize: 13, opacity: 0.7 }}>
              {record.task_type_locale}
            </span>
            <span>
              <Presentation
                currentUser={currentUser}
                rule={"orders:view"}
                route={`/orders/${record.order.id}/edit`}
                title={record.order.number}
              />
            </span>
          </div>
        ),
        sorter: true,
        sortDirections: ["ascend", "descend"],
        width: "10%",
        align: "center",
      },
      {
        title: (
          <div style={{ textAlign: "center" }}>
            <span>Плановая</span>
            <br />
            <span>дата</span>
            <br />
            <span>окончания</span>
          </div>
        ),
        dataIndex: "due_by",
        key: "due_by",
        render: (due_by) =>
          due_by ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ fontSize: 13 }}>
                {moment(due_by).format(dateFormat)}
              </span>
              <span style={{ fontSize: 13, opacity: 0.7 }}>
                {moment(due_by).format(timeFormat)}
              </span>
            </div>
          ) : null,
        sorter: true,
        sortDirections: ["ascend", "descend"],
        align: "center",
        width: "10%",
      },
      {
        title: (
          <div style={{ textAlign: "center" }}>
            <span>Начало</span>
            <br />
            <span>(факт)</span>
          </div>
        ),
        dataIndex: "start",
        render: (start) =>
          start ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ fontSize: 13 }}>
                {moment(start).format(dateFormat)}
              </span>
              <span style={{ fontSize: 13, opacity: 0.7 }}>
                {moment(start).format(timeFormat)}
              </span>
            </div>
          ) : null,
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "start",
        align: "center",
        width: "10%",
      },
      {
        title: (
          <div style={{ textAlign: "center" }}>
            <span>Окончание</span>
            <br />
            <span>(факт)</span>
          </div>
        ),
        dataIndex: "finish",
        render: (finish) =>
          finish ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ fontSize: 13 }}>
                {moment(finish).format(dateFormat)}
              </span>
              <span style={{ fontSize: 13, opacity: 0.7 }}>
                {moment(finish).format(timeFormat)}
              </span>
            </div>
          ) : null,
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "finish",
        align: "center",
        width: "10%",
      },
      {
        title: "Клиент / Объект",
        dataIndex: "customer",
        key: "customer",
        render: (customer, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              {customer ? (
                <Presentation
                  currentUser={currentUser}
                  rule={"customers:view"}
                  route={`/customers/${record.customer.id}/edit`}
                  title={record.customer.name}
                />
              ) : null}
            </span>
            <span>
              {record.company ? (
                <Presentation
                  currentUser={currentUser}
                  rule={"companies:view"}
                  route={`/companies/${record.company.id}/edit`}
                  title={record.company.name}
                />
              ) : null}
            </span>
            <span style={{ opacity: "0.7" }}>
              {record.entity ? record.entity.name : null}
            </span>
            <span style={{ opacity: "0.7" }}>
              {record.entity ? record.entity.address : null}
            </span>
          </div>
        ),
        align: "center",
        width: "30%",
      },
      {
        title: "Ответственный",
        dataIndex: "assignees",
        render: (assignees, record) => <AssigneesCell assignees={assignees} />,
        filterIcon: (filtered) => (
          <Icon
            type="filter"
            style={{
              color: fetchParams.assignee ? "#1890ff" : undefined,
            }}
          />
        ),
        align: "center",
        width: "15%",
      },
      {
        title: "",
        dataIndex: "unread_comment_count",
        render: (unread_comment_count, record) => (
          <Badge count={unread_comment_count}
                 title={'Непрочитанные комментарии'}
                 style={{boxShadow: "none"}}/>
        ),
        align: "center",
        width: "5%",
      },
    ];

    const faqButton = (
      <Button
        type={"ghost"}
        key="7"
        icon="question"
        onClick={this.props.onFaqToggle}
      />
    );

    let mainButton = hasSelected ? (
      <Can
        role={currentUser.role}
        perform="order_tasks:delete"
        yes={() => (
          <Button key="2" type="danger" ghost onClick={this.onDelete}>
            Удалить
          </Button>
        )}
      />
    ) : null;

    let filterButton = (
      <Button
        type={
          fetchParams.startDate ||
          fetchParams.status ||
          fetchParams.taskTypes ||
          fetchParams.users ||
          fetchParams.members ||
          fetchParams.orderUsers
            ? "primary"
            : "ghost"
        }
        key="3"
        icon="filter"
        onClick={this.onFilterToggle}
      />
    );

    const unread = notices.filter(function (item) {
      return item.read == false;
    });

    const noticeIcon = (
      <NoticeIcon
        key="5"
        count={unread.length}
        onItemClick={this.onItemClick}
        list={notices}
        showClear
        onClear={this.onClearNotices}
        emptyText={"новых комментариев нет"}
      />
    );

    const totalSelected = hasSelected
      ? `, Всего (выбрано): ${selectedRowKeys.length}`
      : "";
    const totalInfo = `Всего: ${meta.total_count}${totalSelected}`;

    return (
      <PageHeader
        className="page-header-with-notices"
        onBack={() => window.history.back()}
        title={`Задачи по заказам`}
        subTitle={
          fetchParams.startDate && fetchParams.endDate
            ? `c ${fetchParams.startDate} по ${fetchParams.endDate}`
            : null
        }
        extra={[
          faqButton,
          noticeIcon,
          <Search
            allowClear
            key="1"
            placeholder="поиск..."
            value={fetchParams.search}
            onChange={onSearch}
            style={{ width: 250 }}
          />,
          mainButton,
          filterButton,
        ]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Can
                role={currentUser.role}
                perform="order_tasks:delete"
                yes={() => (
                  <Table
                    // bordered
                    rowSelection={rowSelection}
                    size={"middle"}
                    loading={loading}
                    pagination={false}
                    columns={columns}
                    dataSource={orderTasks}
                    onChange={this.onChangeTable}
                    rowKey="id"
                  />
                )}
                no={() => (
                  <Table
                    // bordered
                    size={"middle"}
                    loading={loading}
                    pagination={false}
                    columns={columns}
                    dataSource={orderTasks}
                    onChange={this.onChangeTable}
                    rowKey="id"
                  />
                )}
              />
            </Col>
            <Col
              span={24}
              style={{
                paddingTop: "15px",
                paddingBottom: "15px",
                textAlign: "right",
                textAlign: "right",
              }}
            >
              <Pagination
                showSizeChanger
                pageSize={fetchParams.limit}
                current={meta.current_page}
                onChange={onChangePage}
                onShowSizeChange={onChangeLimit}
                showTotal={(total) => totalInfo}
                total={meta.total_count}
              />
            </Col>
          </Row>
        }
      >
        <Filter
          visible={isFilterVisible}
          users={fetchParams.users}
          members={fetchParams.members}
          orderUsers={fetchParams.orderUsers}
          status={fetchParams.status}
          startDate={fetchParams.startDate}
          endDate={fetchParams.endDate}
          taskTypes={fetchParams.taskTypes}
          status={fetchParams.status}
          onClose={this.onFilterToggle}
          onClearFilters={onClearFilters}
          onChangeStatus={onChangeFilterStatus}
          onChangeFilterTaskType={onChangeFilterTaskType}
          onChangePeriod={onChangeFilterPeriod}
          onChangeUsers={onChangeFilterUsers}
          onChangeMembers={onChangeFilterMembers}
          onChangeOrderUsers={onChangeFilterOrderUsers}
          onChangeNoAssignees={onChangeFilterNoAssignees}
        />
      </PageHeader>
    );
  }
}

OrderTasksList.propTypes = {
  orderTasks: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default withRouter(OrderTasksList);
