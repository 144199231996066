// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Actions from "../../actions/currentUser";
import ProfileForm from "./Form";

class Profile extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.fetchCurrentUser());
  };

  onSave = () => {
    this.props.dispatch(Actions.onUpdate());
  };

  onClose = () => {
    this.props.history.push(`/`);
  };

  onChangeCurrentUserName = e => {
    this.props.dispatch(Actions.onChangeCurrentUserName(e.target.value));
  };

  onChangeCurrentUserMiddleName = e => {
    this.props.dispatch(Actions.onChangeCurrentUserMiddleName(e.target.value));
  };

  onChangeCurrentUserLastName = e => {
    this.props.dispatch(Actions.onChangeCurrentUserLastName(e.target.value));
  };

  onChangeCurrentUserEmail = e => {
    this.props.dispatch(Actions.onChangeCurrentUserEmail(e.target.value));
  };

  // change password
  onUpdateCurrentUsePassword = () => {
    this.props.dispatch(Actions.onUpdateCurrentUsePassword());
  };

  onChangeCurrentUsePassword = e => {
    this.props.dispatch(Actions.onChangeCurrentUsePassword(e.target.value));
  };

  onChangeCurrentUsePasswordConfirmation = e => {
    this.props.dispatch(Actions.onChangeCurrentUsePasswordConfirmation(e.target.value));
  };

  // notifications
  onChangeNotifyTickets = e => {
    this.props.dispatch(Actions.onChangeNotifyTickets(e.target.value));
  };

  onChangeNotifyEntityTasks = e => {
    this.props.dispatch(Actions.onChangeNotifyEntityTasks(e.target.value));
  };

  onChangeNotifyOrders = e => {
    this.props.dispatch(Actions.onChangeNotifyOrders(e.target.value));
  };

  onChangeNotifyOrderTasks = e => {
    this.props.dispatch(Actions.onChangeNotifyOrderTasks(e.target.value));
  };

  onChangeNotifyProjects = e => {
    this.props.dispatch(Actions.onChangeNotifyProjects(e.target.value));
  };

  // payrolls
  fetchPayrolls = (startAt, endAt) => {
    const { user } = this.props;
    this.props.dispatch(Actions.fetchPayrolls(user.id, startAt, endAt));
  };


  render() {
    const { isLoading, user, currentUser, isLoadingPayrolls, payrolls, penalties } = this.props;
    return (
      <ProfileForm
        isLoading={isLoading}
        user={user}
        currentUser={currentUser}
        onSave={this.onSave}
        onClose={this.onClose}
        onChangeCurrentUserName={this.onChangeCurrentUserName}
        onChangeCurrentUserMiddleName={this.onChangeCurrentUserMiddleName}
        onChangeCurrentUserLastName={this.onChangeCurrentUserLastName}
        onChangeCurrentUserEmail={this.onChangeCurrentUserEmail}
        onUpdateCurrentUsePassword={this.onUpdateCurrentUsePassword}
        onChangeCurrentUsePassword={this.onChangeCurrentUsePassword}
        onChangeCurrentUsePasswordConfirmation={this.onChangeCurrentUsePasswordConfirmation}
         // notifications
         onChangeNotifyTickets={this.onChangeNotifyTickets}
         onChangeNotifyEntityTasks={this.onChangeNotifyEntityTasks}
         onChangeNotifyOrders={this.onChangeNotifyOrders}
         onChangeNotifyOrderTasks={this.onChangeNotifyOrderTasks}
         onChangeNotifyProjects={this.onChangeNotifyProjects}
        // payrolls
        isLoadingPayrolls={isLoadingPayrolls}
        payrolls={payrolls}
        penalties={penalties}
        fetchPayrolls={this.fetchPayrolls}
      />
    );
  }
}

Profile.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  currentUser: PropTypes.object,
  isLoading: PropTypes.bool
};

const mapStateToProps = state => ({
  isLoading: state.currentUser.isLoading,
  user: state.currentUser.currentUser,
  currentUser: state.session.currentUser,
  // payrolls
  isLoadingPayrolls: state.currentUser.isLoadingPayrolls,
  payrolls: state.currentUser.payrolls,
  penalties: state.currentUser.penalties
});

export default connect(mapStateToProps)(Profile);
