// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, Row, Col, Form, Checkbox, Divider } from "antd";
import moment from "moment";
import "moment/locale/ru";

import Todos from "./Todos";

const FormItem = Form.Item;

class Sidebar extends Component {
  render() {
    const { todoTemplate, onChangeActive, onAddTodo, onDeleteTodo, onNewTodoItem } = this.props;

    const todos = todoTemplate.todo_template_items.filter(function(item) {
      return !item._destroy;
    });

    return (
      <Card style={{ borderColor: "#1890ff" }}>
        <Row gutter={10}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Checkbox
              checked={todoTemplate.is_active}
              onChange={onChangeActive}
            >
              активен
            </Checkbox>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Divider>Список</Divider>
          </Col>
        </Row>
        <Row gutter={10} style={{ marginTop: 10 }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Todos
              todoType={todoTemplate.todo_type}
              todos={todos}
              onAddTodo={onAddTodo}
              onDeleteTodo={onDeleteTodo}
              onNewTodoItem={onNewTodoItem}
            />
          </Col>
        </Row>
      </Card>
    );
  }
}
Sidebar.propTypes = {
  customer: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  onChangeHasTiming: PropTypes.func,
  onChangeHasTodos: PropTypes.func,
  onChangeHasAttachments: PropTypes.func,
  onChangeHasComments: PropTypes.func,
  onChangeHasSupervisorAssignees: PropTypes.func,
  onChangeHasUserAssignees: PropTypes.func,
  onChangeHasMemberAssignees: PropTypes.func,
};

export default Sidebar;
