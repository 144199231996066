import { combineReducers } from 'redux';
import analytics from "./analytics";
import dashboard from './dashboard';
import session from './session';
import currentUser from './currentUser';
import organisation from './organisation';
import settings from './settings';
import users from './users';
import members from './members';
import tickets from './tickets';
import customers from './customers';
import suppliers from './suppliers';
import entities from './entities';
import entityTypes from './entityTypes';
import entityTasks from './entityTasks';
import services from './services';
import orders from './orders';
import orderTasks from './orderTasks';
import projects from './projects';
import projectStages from './projectStages';
import projectTypes from './projectTypes';
import supportMessages from './supportMessages';
import notices from './notices';
import posts from './posts';
import faqs from './faqs';
import contentTags from './contentTags';
import knowledgeBases from "./knowledgeBases";
import sections from "./sections";
import articles from "./articles";
import reports from './reports';
import payrolls from './payrolls';
import payrollSchemas from './payrollSchemas';
import employeePayrolls from './employeePayrolls';
import taskTemplates from './taskTemplates';
import tasks from './tasks';
import todoTemplates from './todoTemplates';
import fieldSets from './fieldSets';
import smsnotifications from './smsnotifications';
import supportMessageTypes from './supportMessageTypes';

const appReducer = combineReducers({
  analytics,
  dashboard,
  session,
  currentUser,
  organisation,
  settings,
  users,
  members,
  tickets,
  customers,
  suppliers,
  entities,
  entityTypes,
  entityTasks,
  services,
  orders,
  orderTasks,
  projects,
  supportMessages,
  notices,
  posts,
  faqs,
  contentTags,
  knowledgeBases,
  sections,
  articles,
  reports,
  payrolls,
  payrollSchemas,
  employeePayrolls,
  projectStages,
  projectTypes,
  taskTemplates,
  tasks,
  todoTemplates,
  fieldSets,
  smsnotifications,
  supportMessageTypes
});

export default function (state, action) {
  if (action.type === 'LOGOUT') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
}