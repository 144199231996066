// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Spin } from "antd";
import Actions from "../../../actions/services";
import ServiceForm from "./Form";

class Service extends Component {
  constructor(props) {
    super(props);
    this.state = { isNew: false };
  }

  componentWillMount = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.fetchService(this.props.match.params.id));
    } else {
      this.setState({ isNew: true }, () => {
        this.props.dispatch(Actions.onNew());
      });
    }
  };

  onSave = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.onUpdateService(this.props.match.params.id));
    } else {
      this.props
        .dispatch(Actions.onCreateService(this.props.match.params.categoryID))
        .then(() => {
          if (!this.props.errors) {
            this.props.dispatch(Actions.fetchServices()).then(() => {
              this.props.history.push(
                `/categories/${this.props.match.params.categoryID}/services/${
                  this.props.service.id
                }/edit`
              );
            });
          }
        });
    }
  };

  onClose = () => {
    this.props.history.push(
      `/categories/${this.props.match.params.categoryID}/services`
    );
  };

  // General
  onChangeIsAvailable = e => {
    this.props.dispatch(Actions.onChangeIsAvailable(e.target.checked));
  };

  onChangeName = e => {
    this.props.dispatch(Actions.onChangeName(e.target.value));
  };

  onChangeDescription = e => {
    this.props.dispatch(Actions.onChangeDescription(e.target.value));
  };

  onChangeExtCode = e => {
    this.props.dispatch(Actions.onChangeExtCode(e.target.value));
  };

  onAppendExtCode = (it) => {
    this.props.dispatch(Actions.onAppendExtCode(it));
  };

  onDeleteExtCode = (id) => {
    const { service } = this.props;
    let idx = service.service_extcodes.findIndex((it) => it.id === id);
    if (idx === -1) {
      return null;
    }

    if (service.service_extcodes[idx].is_exist) {
      this.props.dispatch(Actions.onDeleteExtCode(idx));
    } else {
      this.props.dispatch(Actions.onRemoveExtCode(idx));
    }
  };
  

  render() {
    const { isLoading, service } = this.props;
    return isLoading ? (
      <Spin />
    ) : (
      <ServiceForm
        isNew={this.state.isNew}
        service={service}
        onSave={this.onSave}
        onClose={this.onClose}
        onChangeIsAvailable={this.onChangeIsAvailable}
        onChangeName={this.onChangeName}
        onChangeDescription={this.onChangeDescription}
        onChangeExtCode={this.onChangeExtCode}
        onAppendExtCode={this.onAppendExtCode}
        onDeleteExtCode={this.onDeleteExtCode}
      />
    );
  }
}

Service.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  services: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
  errors: PropTypes.bool
};

const mapStateToProps = state => ({
  isLoading: state.services.isServiceLoading,
  service: state.services.service,
  errors: state.services.errors
});

export default connect(mapStateToProps)(Service);
