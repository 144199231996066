// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Drawer, Col, Row, Select, Form } from "antd";
import SelectMultiFetch from "../../../components/SelectMultiFetch";

const { Option } = Select;
const FormItem = Form.Item;

class Filter extends Component {
  render() {
    const {
      visible,
      statuses,
      users,
      members,
      supportMessageTypes,
      onClose,
      onChangeStatuses,
      onChangeTypes,
      onChangeUsers,
      onChangeMembers,
    } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Drawer
        width={540}
        visible={visible}
        title="Фильтры"
        placement={"right"}
        closable={false}
        onClose={onClose}
      >
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Статус"}>
              {getFieldDecorator("statuses", {
                onChange: onChangeStatuses,
                initialValue: statuses ? statuses : [],
              })(
                <Select
                  mode={"multiple"}
                  style={{ width: "100%" }}
                  placeholder="Статус"
                  optionFilterProp="children"
                  filterOption={false}
                >
                  <Option value={"1"}>новое</Option>
                  <Option value={"2"}>в работе</Option>
                  <Option value={"3"}>закрыто</Option>
                </Select>
              )}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Тип"}>
              {getFieldDecorator("supportMessageTypes", {
                initialValue: supportMessageTypes ? supportMessageTypes : [],
              })(
                <SelectMultiFetch
                  showSearch
                  placeholder="Тип"
                  onChange={onChangeTypes}
                  selected={supportMessageTypes ? supportMessageTypes : []}
                  url={"/support_message_types/search/?"}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Отправители"}>
              {getFieldDecorator("users", {
                initialValue: users ? users : [],
              })(
                <SelectMultiFetch
                  showSearch
                  placeholder="Отправители"
                  onChange={onChangeUsers}
                  selected={users ? users : []}
                  url={"/users/search/?all='true'"}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Отправители (клиенты)"}>
              {getFieldDecorator("members", {
                initialValue: members ? members : [],
              })(
                <SelectMultiFetch
                  showSearch
                  placeholder="Отправители"
                  onChange={onChangeMembers}
                  selected={members ? members : []}
                  url={"/members/search/?'"}
                />
              )}
            </FormItem>
          </Col>
        </Row>
      </Drawer>
    );
  }
}

Filter.propTypes = {
  entity: PropTypes.object,
};

export default Form.create()(Filter);
