// @flow
import React, {Component} from "react";
import PropTypes from "prop-types";
import {Row, Col, Button, Form, Card, Input} from "antd";
import {Editor} from "@tinymce/tinymce-react";
import {FormInstance} from 'antd/lib/form';
import Header from "./Header";
import Sidebar from "./Sidebar";
import moment from "moment";
import "moment/locale/ru";
import SelectMultiFetch from "../../../../../components/SelectMultiFetch";
import ReactQuill from "react-quill";

const icons = ReactQuill.Quill.import("ui/icons");
icons["undo"] = `<svg viewbox="0 0 18 18">
    <polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
    <path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
  </svg>`;
icons["redo"] = `<svg viewbox="0 0 18 18">
    <polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
    <path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
  </svg>`;

class ArticleForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: ""
    };

    this.onEditorChange = this.onEditorChange.bind(this);

    this.editorRef = React.createRef();
  }

  onEditorChange(content, editor) {
    this.props.onChangeBody(content);
  }

  onUndoChangeComment = () => {
    const editor = this.editorRef.getEditor();

    return editor.history.undo();
  };

  onRedoChangeComment = () => {
    const editor = this.editorRef.getEditor();

    return editor.history.redo();
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }

      this.props.onSave(values);
    });
  };

  render() {
    const {
      errors,
      isLoading,
      article,
      permissions,
      onSave,
      onClose,
      onChangeTitle,
      onChangeBody,
      onChangeTags,
      // sidebar
      onChangePublished,
      onChangePermissionForAdministrator,
      onChangePermissionForManager,
      onChangePermissionForEngineer,
      onChangePermissionForSupervisor,
      onChangePermissionForSimple,
    } = this.props;

    const {getFieldDecorator} = this.props.form;

    return (
      <Form
        layout={"vertical"}>
        <Card
          loading={isLoading}
          title={
            <h3>{article.title}</h3>
          }
          extra={[
            <Button
              key={"1"}
              onClick={onClose}>
              Закрыть
            </Button>,
            <Button
              key={"2"}
              onClick={this.onSubmit}
              type="primary"
              ghost
              style={{marginLeft: "8px"}}
            >
              Сохранить
            </Button>
          ]}>
          <Row gutter={{md: 8, lg: 24, xl: 48}}>
            <Col span={16}>
              <Row>
                <Form.Item label={"Заголовок"}>
                  {getFieldDecorator("title", {
                    initialValue: article.title,
                    onChange: onChangeTitle,
                    rules: [
                      {
                        required: true,
                        message: "Введите заголовок!"
                      }
                    ]
                  })(<Input/>)}
                </Form.Item>
              </Row>
              <Row>
                <Form.Item label={"Категории"}>
                  {getFieldDecorator("tags", {
                    initialValue: article.content_tags ? article.content_tags : [],
                  })(<SelectMultiFetch
                    showSearch
                    placeholder="Категории"
                    onChange={onChangeTags}
                    selected={article.content_tags ? article.content_tags : []}
                    url={"/content_tags/search/?'"}
                  />)}
                </Form.Item>
              </Row>
              <Row className={'comment-editor'}
                   style={{
                     marginBottom: 16,
                   }}>
                <ReactQuill ref={(it) => this.editorRef = it}
                            bounds={'.comment-editor'}
                            theme={"snow"}
                            value={article.body}
                            modules={{
                              toolbar: {
                                container: [
                                  ['undo', 'redo'],
                                  [{ 'header': [false, 1, 2, 3, 4, 5, 6] }],
                                  ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                  [{ 'color': [] }, { 'background': [] }],
                                  ['clean'],
                                  [{ 'align': [] }],
                                  [{ 'indent': '-1' }, { 'indent': '+1' }],
                                  ['video', 'image', 'link'],
                                ],
                                handlers: {
                                  'undo': this.onUndoChangeComment,
                                  'redo': this.onRedoChangeComment,
                                }
                              },
                              history: {
                                delay: 1000,
                                maxStack: 100,
                                userOnly: false
                              },
                            }}
                            onChange={this.onEditorChange}/>
              </Row>
            </Col>
            <Col span={8}>
              <Sidebar
                published={!!article.published_at}
                permissions={permissions}
                onChangePublished={onChangePublished}
                onChangePermissionForAdministrator={onChangePermissionForAdministrator}
                onChangePermissionForManager={onChangePermissionForManager}
                onChangePermissionForEngineer={onChangePermissionForEngineer}
                onChangePermissionForSupervisor={onChangePermissionForSupervisor}
                onChangePermissionForSimple={onChangePermissionForSimple}
                getFieldDecorator={getFieldDecorator}
              />
            </Col>
          </Row>
        </Card>
      </Form>
    );
  }
}

ArticleForm.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  article: PropTypes.object,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
  router: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  form: PropTypes.object,
};

export default Form.create()(ArticleForm);
