// @flow
import React, { Component } from "react";
import {
  Row,
  Col,
  Modal,
  Button,
  Form,
  Input,
  DatePicker,
  Upload,
  Icon
} from "antd";

import moment from "moment";
import "moment/locale/ru";

const FormItem = Form.Item;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

class ContactModal extends React.Component {
  state = {
    file: null,
    imageUrl: null
  };

  onSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onSave(values);
    });
  };

  beforeUpload = file => {
    this.setState(state => ({
      file: file
    }));
  };

  onImageUpload = () => {};

  onChange = info => {
    getBase64(info.file.originFileObj, imageUrl => {
      this.props.onChangeAvatar(imageUrl);
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      visible,
      onCancel,
      contact,
      onChangeName,
      onChangeTitle,
      onChangeBirthday,
      onChangePhone,
      onChangeEmail
    } = this.props;

    const uploadButton = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexFlow: "column",
          minHeight: "140px",
          minWidth: "140px"
        }}
      >
        <Icon type={this.state.loading ? "loading" : "plus"} />
        <div>Загрузить картинку</div>
      </div>
    );

    return (
      <Modal
        title={contact.name}
        destroyOnClose
        visible={visible}
        onOk={this.onSubmit}
        onCancel={onCancel}
      >
        <Form layout={"vertical"}>
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={8}>
              <Row
                gutter={{ md: 8, lg: 24, xl: 48 }}
                type="flex"
                justify="center"
              >
                <Col span={24}>
                  <Upload
                    name="url"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={this.beforeUpload}
                    customRequest={this.onImageUpload}
                    onChange={this.onChange}
                  >
                    {contact.avatar ? (
                      <img height={"140"} src={contact.url} alt="avatar" />
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                </Col>
              </Row>
            </Col>
            <Col span={16}>
              <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
                <Col span={24}>
                  <FormItem label={"Должность"}>
                    {getFieldDecorator("title", {
                      onChange: onChangeTitle,
                      initialValue: contact.title,
                      rules: [
                        {
                          required: true,
                          message: "Введите имя!"
                        }
                      ]
                    })(<Input />)}
                  </FormItem>
                </Col>
                <Col span={24}>
                  <FormItem label={"Дата рождения"}>
                    {getFieldDecorator("birthday", {
                      onChange: onChangeBirthday,
                      initialValue: moment(
                        contact.birthday ? contact.birthday : null
                      )
                    })(
                      <DatePicker
                        format={"LLL"}
                        className="ant-btn-width-100"
                        showTime
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <FormItem label={"Имя"}>
                {getFieldDecorator("name", {
                  onChange: onChangeName,
                  initialValue: contact.name,
                  rules: [
                    {
                      required: true,
                      message: "Введите имя!"
                    }
                  ]
                })(<Input />)}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label={"Телефон"}>
                {getFieldDecorator("phone", {
                  onChange: onChangePhone,
                  initialValue: contact.phone,
                  rules: [
                    {
                      required: true,
                      message: "Введите телефон!"
                    }
                  ]
                })(<Input />)}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label={"Email"}>
                {getFieldDecorator("email", {
                  onChange: onChangeEmail,
                  initialValue: contact.phone,
                  rules: [
                    {
                      required: true,
                      message: "Введите email!"
                    }
                  ]
                })(<Input />)}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(ContactModal);
