// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Table, Button } from "antd";
import update from "immutability-helper";
import "moment/locale/ru";
import accounting from "accounting";
import EditableInputCell from "../../../../components/EditableCells/EditableInputCell";
import Employee from "./Employee";

class Employees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      isVisible: false,
      employee: {},
    };
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  onDeleteEemployees = () => {
    this.props.onDeleteEemployees(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  // department
  onOpenEemployee = (item) => {
    this.setState({ employee: item }, () => {
      this.setState({ isVisible: true });
    });
  };

  onAddEemployee = () => {
    this.setState(
      {
        employee: {
          id: null,
          user: null,
          salary: 0,
        },
      },
      () => {
        this.setState({ isVisible: true });
      }
    );
  };

  onCreateEmployee = () => {
    this.setState({ isVisible: false }, () => {
      this.props.onCreateEmployee(this.state.employee);
    });
  };

  onCancel = () => {
    this.setState({ isVisible: false });
  };

  onChangeEmployeeSalary = (value) => {
    this.setState({
      employee: update(this.state.employee, {
        salary: { $set: value },
      }),
    });
  };

  onChangeEmployeeUser = (value) => {
    this.setState({
      employee: update(this.state.employee, {
        user: { $set: value },
      }),
    });
  };

  render() {
    const { selectedRowKeys, isVisible, employee } = this.state;
    const { isLoadindPayrollItems, employees, date, department, onChangeSalary } = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    let mainButton = hasSelected ? (
      <Button key="1" type="danger" ghost onClick={this.onDeleteEemployees}>
        Удалить
      </Button>
    ) : (
      <Button key="2" type="primary" ghost onClick={this.onAddEemployee}>
        Добавить
      </Button>
    );

    const columns = [
      {
        title: "Имя",
        dataIndex: "user",
        render: (user) => (user ? user.name : null),
        key: "user",
        width: "60%",
        align: "left",
      },
      {
        title: "Базовый оклад",
        dataIndex: "salary",
        render: (salary, record) => (
          <EditableInputCell
            recordId={record.id}
            precision={0}
            value={salary}
            onChange={onChangeSalary}
          />
        ),
        key: "salary",
        width: "40%",
        align: "center",
      },
    ];

    return (
      <React.Fragment>
        {employees.length > 0 ? (
          <Row gutter={{ md: 8, lg: 24, xl: 48 }} style={{ paddingBottom: 15 }}>
            <Col span={24}>{mainButton}</Col>
          </Row>
        ) : null}

        <Row gutter={{ md: 8, lg: 24, xl: 48 }} style={{ paddingBottom: 15 }}>
          <Col span={24}>
            <Table
              bordered
              rowSelection={rowSelection}
              size={"middle"}
              loading={isLoadindPayrollItems}
              pagination={false}
              columns={columns}
              dataSource={employees}
              rowKey="id"
            />
          </Col>
          <Employee
            visible={isVisible}
            employee={employee}
            date={date}
            departmentId={department ? department.id : null}
            onSave={this.onCreateEmployee}
            onCancel={this.onCancel}
            onChangeSalary={this.onChangeEmployeeSalary}
            onChangeUser={this.onChangeEmployeeUser}
          />
        </Row>
      </React.Fragment>
    );
  }
}
Employees.propTypes = {
  getFieldDecorator: PropTypes.func,
};

export default Employees;
