// @flow
import React, {Component, Fragment} from "react";
import update from "immutability-helper";
import {Row, Col, Button, Card, Tabs} from "antd";
import Calendar from "./Calendar/Calendar";
import Gantt from "./Gantt/Gantt";
import Statistic from "./Statistic";
import KnowledgeBase from "../KnowledgeBase";
import Can from "../../rules/Can";
import moment from "moment";
import "moment/locale/ru";
import {Link} from "react-router-dom";
import Actions from "../../actions/dashboard";
import Analytic from "./Analytic/Analytic";

const {TabPane} = Tabs;

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: "1",
      fetchParams: {
        startDate: moment().startOf("month").format("DD.MM.YYYY"),
        endDate: moment().endOf("month").format("DD.MM.YYYY"),
        groupBy: "users",
        users: null,
        status: null,
        taskAssigneeType: null,
      },
    };
  }

  onChange = (activeKey) => {
    this.setState({activeKey});
    if (activeKey == 2) {
      this.props.fetchGanttEvents(this.state.fetchParams);
    }
  };

  onChangeGanttGroupBy = (e) => {
    this.setState(
      {
        fetchParams: update(this.state.fetchParams, {
          groupBy: {$set: e.target.value},
        }),
      },
      () => {
        this.props.fetchGanttEvents(this.state.fetchParams);
      }
    );
  };

  onChangeGanttUsers = (values) => {
    this.setState(
      {
        fetchParams: update(this.state.fetchParams, {
          users: {$set: values},
        }),
      },
      () => {
        this.props.fetchGanttEvents(this.state.fetchParams);
      }
    );
  };

  onChangeGanttDateRange = (dates) => {
    this.setState(
      {
        fetchParams: update(this.state.fetchParams, {
          startDate: {$set: moment(dates[0]).format("L")},
          endDate: {$set: moment(dates[1]).format("L")},
        }),
      },
      () => {
        this.props.fetchGanttEvents(this.state.fetchParams);
      }
    );
  };

  onChangeGanttStatus = (value) => {
    this.setState(
      {
        fetchParams: update(this.state.fetchParams, {
          status: {$set: value},
        }),
      },
      () => {
        this.props.fetchGanttEvents(this.state.fetchParams);
      }
    );
  };

  onChangeGanttTaskAssigneeType = (value) => {
    this.setState(
      {
        fetchParams: update(this.state.fetchParams, {
          taskAssigneeType: {$set: value},
        }),
      },
      () => {
        this.props.fetchGanttEvents(this.state.fetchParams);
      }
    );
  };

  render() {
    const {
      history,
      currentUser,
      onDrawerToggle,
      isCalendarLoading,
      calendarEvents,
      onChangeEventType,
      onCalendarNavigate,
      // gantt
      isGanttLoading,
      ganttEvents,
      ganttTotals,
      // other
      onSelectEvent,
      isEventLoading,
      selectedEvent,
      onChangeUsers,
      onChangeTaskTypes,
      onChangeTaskStates,
      onChangeCalendarTaskAssigneeType,
      // params
      fetchParams,
      // tasks
      fetchTasks,
      onChangeTaskFilterCreatedPeriod,
      onChangeTaskFilterDueByPeriod,
      onChangeTaskFilterUsers,
      fetchStatistic,
      isTasksLoading,
      isStatisticLoading,
      tasks,
      countOverdueTasks,
      onChangeTasksAssigneeType,
      tasksStatistic,
      taskSeries,
      fetchTasksParams,
      fetchStatisticParams,
      onChangeStatisticDateRange,
      onChangeStatisticPeriod,
      onChangeStatisticDepartments,
      projectSeries,
      projectsStatistic,
      // orders
      ordersStatistic,
      orderCustomers,
      onChangeOrdersCustomer,
      // tickets
      ticketSeries,
      isTicketsStatisticLoading,
      ticketsStatistic,
      ticketCustomers,
      onChangeTicketsCustomer,
      // entity_tasks
      entityTaskSeries,
      // intime_statistic
      isIntimeStatisticLoading,
      intimeStatistic,
      // services
      services,
    } = this.props;

    const {
      groupBy,
      status,
      users,
      startDate,
      endDate
    } = this.state.fetchParams;

    return (
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Tabs
            defaultActiveKey="1"
            onChange={this.onChange}
            tabBarExtraContent={[
              <Button
                key={"1"}
                onClick={() => history.push(`/reports/employees`)}>
                Структура организации
              </Button>,
              <Button
                key={"2"}
                onClick={() => history.push(`/knowledge_base`)}
                style={{ marginLeft: "8px" }}>
                База знаний
              </Button>,
              <Button
                key={"3"}
                onClick={onDrawerToggle}
                style={{ marginLeft: "8px" }}>
                Новости
              </Button>
            ]}
          >
            <TabPane tab="Статистика" key="1">
              <Statistic
                currentUser={currentUser}
                fetchTasks={fetchTasks}
                onChangeTaskFilterCreatedPeriod={onChangeTaskFilterCreatedPeriod}
                onChangeTaskFilterDueByPeriod={onChangeTaskFilterDueByPeriod}
                onChangeTaskFilterUsers={onChangeTaskFilterUsers}
                isTasksLoading={isTasksLoading}
                fetchStatisticParams={fetchStatisticParams}
                fetchStatistic={fetchStatistic}
                isStatisticLoading={isStatisticLoading}
                tasks={tasks}
                countOverdueTasks={countOverdueTasks}
                onChangeTasksAssigneeType={onChangeTasksAssigneeType}
                tasksStatistic={tasksStatistic}
                taskSeries={taskSeries}
                fetchTasksParams={fetchTasksParams}
                onChangeStatisticDateRange={onChangeStatisticDateRange}
                onChangeStatisticPeriod={onChangeStatisticPeriod}
                onChangeStatisticDepartments={onChangeStatisticDepartments}
                projectSeries={projectSeries}
                projectsStatistic={projectsStatistic}
                // orders
                ordersStatistic={ordersStatistic}
                orderCustomers={orderCustomers}
                onChangeOrdersCustomer={onChangeOrdersCustomer}
                // tickets
                ticketSeries={ticketSeries}
                isTicketsStatisticLoading={isTicketsStatisticLoading}
                ticketsStatistic={ticketsStatistic}
                ticketCustomers={ticketCustomers}
                onChangeTicketsCustomer={onChangeTicketsCustomer}
                // entity_tasks
                entityTaskSeries={entityTaskSeries}
                // intime_statistic
                isIntimeStatisticLoading={isIntimeStatisticLoading}
                intimeStatistic={intimeStatistic}
                // services
                services={services}
              />
            </TabPane>

            <TabPane tab="Аналитика" key="2">
              <Analytic />
            </TabPane>

            <TabPane tab="Календарь" key="3">
              <Calendar
                isCalendarLoading={isCalendarLoading}
                events={calendarEvents}
                onChangeEventType={onChangeEventType}
                onCalendarNavigate={onCalendarNavigate}
                onSelectEvent={onSelectEvent}
                isEventLoading={isEventLoading}
                selectedEvent={selectedEvent}
                fetchParams={fetchParams}
                onChangeUsers={onChangeUsers}
                onChangeTaskTypes={onChangeTaskTypes}
                onChangeTaskStates={onChangeTaskStates}
                onChangeTaskAssigneeType={onChangeCalendarTaskAssigneeType}
              />
            </TabPane>
            <TabPane tab="Гант" key="4">
              <Can
                role={currentUser.role}
                perform="dashboard:gantt"
                yes={() => (
                  <Gantt
                    isGanttLoading={isGanttLoading}
                    startDate={startDate}
                    endDate={endDate}
                    ganttEvents={ganttEvents}
                    ganttTotals={ganttTotals}
                    groupBy={groupBy}
                    status={status}
                    users={users}
                    onChangeGroupBy={this.onChangeGanttGroupBy}
                    onChangeGanttUsers={this.onChangeGanttUsers}
                    onChangeDateRange={this.onChangeGanttDateRange}
                    onChangeGanttStatus={this.onChangeGanttStatus}
                    onChangeTaskAssigneeType={this.onChangeGanttTaskAssigneeType}
                  />
                )}
                no={() => null}
              />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    );
  }
}

export default Container;
