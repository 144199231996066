// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Form, Input, Col, Row, Descriptions } from "antd";
import moment from "moment";
import "moment/locale/ru";
import EntityOpeningHours from "../../../components/EntityOpeningHours";

class GeneralInfo extends Component {
  constructor(props, context) {
    super(props, context);
  }
  render() {
    const { ticket } = this.props;

    return (
      <Col span={24}>
        <Row gutter={16}>
          <Descriptions bordered>
            <Descriptions.Item label="Клиент" span={3}>
              {ticket.customer ? ticket.customer.name : null}
            </Descriptions.Item>
            <Descriptions.Item label="Дата и время исполнения" span={3}>
              {moment(ticket.dueBy).format("LLL")}
            </Descriptions.Item>
            <Descriptions.Item
              label={
                ticket.entity ? (
                  <EntityOpeningHours
                    title={"Объект"}
                    entityId={ticket.entity.id}
                  />
                ) : (
                  "Объект"
                )
              }
              span={3}
            >
              {ticket.entity ? (
                <Link to={`/entities/${ticket.entity.id}/edit`}>
                  {ticket.entity.name}
                </Link>
              ) : null}
            </Descriptions.Item>
            <Descriptions.Item label="Адрес" span={3}>
              {ticket.address}
            </Descriptions.Item>
            <Descriptions.Item label="Раздел" span={3}>
              {ticket.entity_service ? ticket.entity_service.name : null}
            </Descriptions.Item>
            <Descriptions.Item label="Тема" span={3}>
              {ticket.subject ? ticket.subject : null}
            </Descriptions.Item>
            <Descriptions.Item label="Описание" span={3}>
              {ticket.description ? ticket.description : null}
            </Descriptions.Item>
          </Descriptions>
        </Row>
      </Col>
    );
  }
}

GeneralInfo.propTypes = {
  ticket: PropTypes.object,
  onChangeCustomer: PropTypes.func,
  onChangeEntity: PropTypes.func,
  onChangeEntityService: PropTypes.func,
  onChangeStatus: PropTypes.func,
  onChangepPiority: PropTypes.func,
  onChangeSubject: PropTypes.func,
  onChangeDescription: PropTypes.func,
};

export default GeneralInfo;
