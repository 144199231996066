// @flow
import React, { Component, Fragment } from "react";
import { Row, Col, Button, Card, Statistic, Icon } from "antd";
import Line from "./Line";



import moment from "moment";
import "moment/locale/ru";

class EntityTasks extends Component {
  render() {
    const {
      currentUser,
      fetchParams,
      isStatisticLoading,
      entityTaskSeries,
    } = this.props;

    
    

    return (
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card loading={isStatisticLoading}>
            <Line data={entityTaskSeries.length > 0 ? entityTaskSeries : []} />
          </Card>
        </Col>
      </Row>
    );
  }
}

export default EntityTasks;
