import React from "react";
import { Row, Col, Popover, Table } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/ru";
import accounting from "accounting";

export default function EmployeeDetails({
  loading = false,
  name = null,
  data = [],
  onChange = null,
}) {
  return (
    <Popover
      placement="right"
      content={<Details loading={loading} data={data} />}
      title="Расшифровка"
      trigger="click"
      onVisibleChange={onChange}
    >
      <span style={{ cursor: "pointer" }}>{name}</span>
    </Popover>
  );
}

function Details({ loading = false, data = null }) {
  const columns = [
    {
      title: "Начисление",
      dataIndex: "name",
      render: (name, record) =>
        record.order_id ? (
          <Link
            to={`/orders/${record.order_id}/edit`}
            target="_blank"
          >{record.name}</Link>
        ) : (
          record.name
        ),
      key: "name",
      align: "left",
      width: "75%",
    },
    {
      title: "Сумма",
      dataIndex: "amount",
      render: (amount) => accounting.formatNumber(amount, 0, " "),
      key: "amount",
      align: "center",
      width: "25%",
    },
  ];

  return (
    <div style={{ width: 640 }}>
      <Table
        rowClassName={"progress-row"}
        size={"small"}
        loading={loading}
        // showHeader={false}
        columns={columns}
        dataSource={data}
        pagination={false}
        rowKey="id"
      />
    </div>
  );
}
