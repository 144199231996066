// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Table,
  Row,
  Col,
  PageHeader,
  Pagination,
  Button,
  Badge,
  Input,
  Tag,
  Select,
  Icon
} from "antd";
import moment from "moment";
import "moment/locale/ru";
import accounting from "accounting";

const Search = Input.Search;
const Option = Select.Option;

class UsersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      filterActiveVisible: false
    };
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  onDelete = () => {
    this.props.onDelete(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };



  render() {
    const {
      loading,
      payrollSchemas,
      meta,
      fetchParams,
      onChangePage,
      onChangeLimit,
      onSearch
    } = this.props;
    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    };

    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: "Наименование схемы",
        dataIndex: "name",
        render: (name, record) => (
          <Link to={`/finances/payroll_schemas/${record.id}/edit`}>{name}</Link>
        ),
        key: "name",
        width: "35%",
        align: "left"
      },
      {
        title: "Описание схемы",
        dataIndex: "description",
        key: "description",
        width: "50%",
        align: "left"
      },
      {
        title: "Cоздана",
        dataIndex: "created_at",
        render: created_at => moment(created_at).format("L"),
        key: "created_at",
        align: "center",
        width: "15%"
      }
    ];

    let mainButton = hasSelected ? (
      <Button key="1" type="danger" ghost onClick={this.onDelete}>
        Удалить
      </Button>
    ) : (
      <Link
        key="2"
        className={"ant-btn ant-btn-primary ant-btn-background-ghost"}
        to={{ pathname: `/finances/payroll_schemas/new` }}
      >
        Создать
      </Link>
    );

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Схемы начислений`}
        extra={[
          <Search
            allowClear
            key="3"
            placeholder="поиск..."
            value={fetchParams.search}
            onChange={onSearch}
            style={{ width: 250 }}
            allowClear
          />,
          mainButton
        ]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Table
                bordered
                rowSelection={rowSelection}
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={payrollSchemas}
                onChange={this.onChange}
                rowKey="id"
              />
            </Col>
            <Col
              span={24}
              style={{ paddingTop: "15px", paddingBottom: "15px", textAlign: "right" }}
            >
              <Pagination
                showSizeChanger
                pageSize={fetchParams.limit}
                current={meta.current_page}
                onChange={onChangePage}
                onShowSizeChange={onChangeLimit}
                showTotal={total => `Всего: ${meta.total_count}`}
                total={meta.total_count}
              />
            </Col>
          </Row>
        }
      />
    );
  }
}

UsersList.propTypes = {
  payrollSchemas: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
  onSearch: PropTypes.func,
  onChangePage: PropTypes.func
};

export default UsersList;
