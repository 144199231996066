// @flow
import React, {Component} from "react";
import {Link, withRouter} from "react-router-dom";
import {Badge, Button, Col, Icon, Input, PageHeader, Pagination, Row, Table, Tag, Tooltip} from "antd";
import Can from "../../../rules/Can";
import Presentation from "../../../components/Presentation";

import PropTypes from "prop-types";
import AssigneesCell from "../../../components/AssigneesCell";
import NoticeIcon from "../../../components/NoticeIcon";

import moment from "moment";
import "moment/locale/ru";
import Filter from "./Filter";
import CommentForm from "./CommentForm";

const Search = Input.Search;

function statuses(status) {
  switch (status) {
    case "pending":
      return "error";
    case "processing":
      return "processing";
    case "finished":
      return "success";
    default:
      return "default";
  }
}

function serviceTag(item) {
  return (
    <Tooltip
      key={item.entity_service.id}
      placement="rightTop"
      title={
        item.entity_service ? item.entity_service.service.extra.category : null
      }
    >
      <Tag color="#108ee9">{item.entity_service.service.name}</Tag>
    </Tooltip>
  );
}

class EntityTasksList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      isFilterVisible: false,

      isCommentAllModalOpen: false,
    };
  }

  onFilterToggle = () => {
    this.setState({isFilterVisible: !this.state.isFilterVisible});
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({selectedRowKeys});
  };

  onDelete = () => {
    this.props.onDelete(this.state.selectedRowKeys);
    this.setState({selectedRowKeys: []});
  };

  onChangeTable = (pagination, filters, sorter) => {
    let direction = "desc";
    if (sorter.order === "descend") {
      direction = "desc";
    } else if (sorter.order === "ascend") {
      direction = "asc";
    }
    this.props.onSortBy(sorter.field, direction);
  };

  onItemClick = (item) => {
    this.props.history.push(`/entity_tasks/${item.parent_id}/edit`);
  };

  onClearNotices = () => {
    this.props.onClearNotices();
  };


  onOpenCommentAllModal = () => {
    this.setState({
      isCommentAllModalOpen: true,
    });
  };

  onSubmitCommentAllModal = (comment, mentioned) => {
    this.props.createCommentAll(this.state.selectedRowKeys, comment, mentioned);

    this.setState({
      isCommentAllModalOpen: false,
    });
  };

  onCancelCommentAllModal = () => {
    this.setState({
      isCommentAllModalOpen: false,
    });
  };

  render() {
    const {
      selectedRowKeys,
      isFilterVisible,
      isCommentAllModalOpen,
    } = this.state;

    const {
      currentUser,
      loading,
      entityTasks,
      meta,
      fetchParams,
      notices,
      onSearch,
      onChangePage,
      onChangeLimit,
      // filters
      onClearFilters,
      onChangeFilterStatus,
      onChangeFilterPeriod,
      onChangeFilterUsers,
      onChangeFilterMembers,
      onChangeFilterServices,
      onChangeFilterCustomer,
      downloadReport,
    } = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: "#",
        dataIndex: "id",
        key: "number",
        render: (id, record) => (
          <Link to={`/entity_tasks/${id}/edit`}>{id}</Link>
        ),
        align: "center",
        width: "5%",
      },
      {
        title: "Статус",
        dataIndex: "state",
        render: (state, record) => <Badge status={statuses(record.status)}/>,
        key: "state",
        align: "center",
        width: "5%",
      },
      {
        title: "План",
        dataIndex: "plan",
        render: (plan) =>
          plan ? (
            <span style={{fontSize: 13}}>{moment(plan).format("LLL")}</span>
          ) : null,
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "plan",
        align: "center",
        width: "10%",
      },
      {
        title: "Начало",
        dataIndex: "start",
        render: (start) =>
          start ? (
            <span style={{fontSize: 13}}>{moment(start).format("LLL")}</span>
          ) : null,
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "start",
        align: "center",
        width: "10%",
      },
      {
        title: "Окончание",
        dataIndex: "finish",
        render: (finish) =>
          finish ? (
            <span style={{fontSize: 13}}>{moment(finish).format("LLL")}</span>
          ) : null,
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "finish",
        align: "center",
        width: "10%",
      },
      {
        title: "Клиент / Объект",
        dataIndex: "customer",
        render: (customer, record) => (
          <div style={{display: "flex", flexDirection: "column"}}>
            {customer ? (
              <span>
                <Presentation
                  currentUser={currentUser}
                  rule={"customers:view"}
                  route={`/customers/${record.customer.id}/edit`}
                  title={record.customer.name}
                />
              </span>
            ) : null}

            <span>{record.entity ? record.entity.name : null}</span>
            <span style={{opacity: "0.7"}}>
              {record.entity ? record.entity.address : null}
            </span>
          </div>
        ),
        align: "center",
        width: "20%",
      },
      {
        title: "Раздел",
        dataIndex: "entity_task_items",
        key: "entity_task_items",
        render: (entity_task_items, record) => {
          return entity_task_items.map((item) => {
            return serviceTag(item);
          });
        },
        align: "center",
        width: "20%",
      },
      {
        title: "Ответственный",
        dataIndex: "assignees",
        render: (assignees, record) => <AssigneesCell assignees={assignees}/>,
        align: "center",
        width: "15%",
      },
      {
        title: "",
        dataIndex: "unread_comment_count",
        render: (unread_comment_count, record) => (
          <Badge count={unread_comment_count}
                 title={'Непрочитанные комментарии'}
                 style={{boxShadow: "none"}}/>
        ),
        align: "center",
        width: "5%",
      },
    ];

    const faqButton = (
      <Button
        type={"ghost"}
        key="7"
        icon="question"
        onClick={this.props.onFaqToggle}
      />
    );

    let commentAllButton = hasSelected ? (
      <Tooltip
        placement="bottom"
        title={"Добавить комментарий для выбранных записей"}>
        <Button
          key="42"
          type={"ghost"}
          icon={"message"}
          onClick={this.onOpenCommentAllModal}
        />
      </Tooltip>
    ) : null;

    let deleteButton = hasSelected ? (
      <Can
        role={currentUser.role}
        perform="entity_tasks:delete"
        yes={() => (
          <Button key="2" type="danger" ghost onClick={this.onDelete}>
            Удалить
          </Button>
        )}
      />
    ) : null;

    let excelButton = (
      <Tooltip placement="bottom" title={"Выгрузить в Excel"}>
        <Button key="excelButton" type="dashed" onClick={downloadReport}>
          <Icon type="file-excel" style={{color: "green"}}/>
          Выгрузить
        </Button>
      </Tooltip>
    );

    let filterButton = (
      <Button
        type={
          fetchParams.startDate || fetchParams.status || fetchParams.users || fetchParams.customer
            ? "primary"
            : "ghost"
        }
        key="3"
        icon="filter"
        onClick={this.onFilterToggle}
      />
    );

    const unread = notices.filter(function (item) {
      return item.read == false;
    });

    const noticeIcon = (
      <NoticeIcon
        key="5"
        count={unread.length}
        onItemClick={this.onItemClick}
        list={notices}
        showClear
        onClear={this.onClearNotices}
        emptyText={"новых комментариев нет"}
      />
    );

    const totalSelected = hasSelected
      ? `, Всего (выбрано): ${selectedRowKeys.length}`
      : "";
    const totalInfo = `Всего: ${meta.total_count}${totalSelected}`;

    return (
      <PageHeader
        className="page-header-with-notices"
        onBack={() => window.history.back()}
        title={`Регламент`}
        subTitle={
          fetchParams.startDate
            ? `c ${fetchParams.startDate} по ${fetchParams.endDate}`
            : null
        }
        extra={[
          commentAllButton,
          faqButton,
          noticeIcon,
          <Search
            allowClear
            key="1"
            placeholder="поиск..."
            value={fetchParams.search}
            onChange={onSearch}
            style={{width: 250}}
          />,
          deleteButton,
          excelButton,
          filterButton,
        ]}
        footer={
          <Row gutter={{md: 8, lg: 24, xl: 48}}>
            <Col span={24}>
              <Can
                role={currentUser.role}
                perform="order_tasks:delete"
                yes={() => (
                  <Table
                    rowSelection={rowSelection}
                    size={"middle"}
                    loading={loading}
                    pagination={false}
                    columns={columns}
                    dataSource={entityTasks}
                    onChange={this.onChangeTable}
                    rowKey="id"
                  />
                )}
                no={() => (
                  <Table
                    size={"middle"}
                    loading={loading}
                    pagination={false}
                    columns={columns}
                    dataSource={entityTasks}
                    onChange={this.onChangeFilterUsers}
                    rowKey="id"
                  />
                )}
              />
            </Col>
            <Col
              span={24}
              style={{
                paddingTop: "15px",
                paddingBottom: "15px",
                textAlign: "right",
              }}
            >
              <Pagination
                showSizeChanger
                pageSize={fetchParams.limit}
                current={meta.current_page}
                onChange={onChangePage}
                onShowSizeChange={onChangeLimit}
                showTotal={(total) => totalInfo}
                total={meta.total_count}
              />
            </Col>
          </Row>
        }
      >
        <Filter
          visible={isFilterVisible}
          status={fetchParams.status}
          startDate={fetchParams.startDate}
          endDate={fetchParams.endDate}
          users={fetchParams.users}
          services={fetchParams.services}
          customer={fetchParams.customer}
          onClose={this.onFilterToggle}
          onClearFilters={onClearFilters}
          onChangeStatus={onChangeFilterStatus}
          onChangePeriod={onChangeFilterPeriod}
          onChangeUsers={onChangeFilterUsers}
          onChangeMembers={onChangeFilterMembers}
          onChangeServices={onChangeFilterServices}
          onChangeCustomer={onChangeFilterCustomer}
          downloadReport={downloadReport}
        />
        <CommentForm
          isCommentAllModalOpen={isCommentAllModalOpen}
          onSubmitCommentAllModal={this.onSubmitCommentAllModal}
          onCancel={this.onCancelCommentAllModal}/>
      </PageHeader>
    );
  }
}

EntityTasksList.propTypes = {
  entityTasks: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default withRouter(EntityTasksList);
