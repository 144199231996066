import update from "immutability-helper";
import moment from "moment";
import "moment/locale/ru";

const initialState = {
  isLoading: true,
  fetchParams: {},
  errors: false,
  contentTags: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "FETCH_CONTENT_TAGS_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "FETCH_CONTENT_TAGS":
      return {
        ...state,
        isLoading: false,
        contentTags: action.response.data
      };
    case "POST_CONTENT_TAG_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false
      };
    case "POST_CONTENT_TAG_SUCCEED":
      return {
        ...state,
        isLoading: false,
        errors: false
      };
    case "POST_CONTENT_TAG_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true
      };
    case "PATCH_CONTENT_TAG_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false
      };
    case "PATCH_CONTENT_TAG_SUCCEED":
      return {
        ...state,
        isLoading: false,
        errors: false
      };
    case "PATCH_CONTENT_TAG_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true
      };
    case "DELETE_CONTENT_TAG_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false
      };
    case "DELETE_CONTENT_TAG":
      return {
        ...state,
        isLoading: false,
        errors: false
      };
    case "DELETE_CONTENT_TAG_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true
      };
    // case "FETCH_ARTICLE_REQUEST":
    //   return {
    //     ...state,
    //     isLoading: true,
    //   };
    // case "FETCH_ARTICLE":
    //   return {
    //     ...state,
    //     isLoading: false,
    //     article: action.response.data,
    //     permissions: action.response.data.permissions
    //   };
    // case "FETCH_ARTICLE_FAILURE":
    //   return {
    //     ...state,
    //     isLoading: false,
    //     errors: true
    //   };
    // case "NEW_ARTICLE":
    //   return {
    //     ...state,
    //     isLoading: false,
    //     article: {},
    //     permissions: [
    //       {enabled: true, role: 'administrator'},
    //       {enabled: true, role: 'manager'},
    //       {enabled: true, role: 'engineer'},
    //       {enabled: true, role: 'supervisor'},
    //       {enabled: true, role: 'simple'}
    //     ],
    //     errors: false,
    //   };
    // case "CHANGE_ARTICLE_PUBLISHED":
    //   return update(state, {
    //     article: {
    //       published_at: {$set: action.value ? moment() : null},
    //       published_by: {$set: action.value ? action.user.user_id : null}
    //     },
    //   });
    // case "CHANGE_ARTICLE_TITLE":
    //   return update(state, {
    //     article: {title: {$set: action.value}},
    //   });
    // case "CHANGE_ARTICLE_BODY":
    //   return update(state, {
    //     article: {body: {$set: action.value}},
    //   });
    // case "CHANGE_ARTICLE_TAGS":
    //   return update(state, {
    //     article: {tags: {$set: action.values}},
    //   });
    // case "CHANGE_ARTICLE_PERMISSION_FOR_ADMINISTRATOR":
    //   permissionIndex = state.permissions.findIndex((it) => it.role === 'administrator');
    //   if (permissionIndex === -1) {
    //     return {
    //       ...state,
    //       permissions: [...state.permissions, {enabled: action.value, role: 'administrator'}]
    //     }
    //   }
    //
    //   return update(state, {
    //     permissions: {
    //       [permissionIndex]: {
    //         enabled: {
    //           $set: action.value
    //         }
    //       }
    //     },
    //   });
    // case "CHANGE_ARTICLE_PERMISSION_FOR_MANAGER":
    //   permissionIndex = state.permissions.findIndex((it) => it.role === 'manager');
    //   if (permissionIndex === -1) {
    //     return {
    //       ...state,
    //       permissions: [...state.permissions, {enabled: action.value, role: 'manager'}]
    //     }
    //   }
    //
    //   return update(state, {
    //     permissions: {
    //       [permissionIndex]: {
    //         enabled: {
    //           $set: action.value
    //         }
    //       }
    //     },
    //   });
    // case "CHANGE_ARTICLE_PERMISSION_FOR_ENGINEER":
    //   permissionIndex = state.permissions.findIndex((it) => it.role === 'engineer');
    //   if (permissionIndex === -1) {
    //     return {
    //       ...state,
    //       permissions: [...state.permissions, {enabled: action.value, role: 'engineer'}]
    //     }
    //   }
    //
    //   return update(state, {
    //     permissions: {
    //       [permissionIndex]: {
    //         enabled: {
    //           $set: action.value
    //         }
    //       }
    //     },
    //   });
    // case "CHANGE_ARTICLE_PERMISSION_FOR_SUPERVISOR":
    //   permissionIndex = state.permissions.findIndex((it) => it.role === 'supervisor');
    //   if (permissionIndex === -1) {
    //     return {
    //       ...state,
    //       permissions: [...state.permissions, {enabled: action.value, role: 'supervisor'}]
    //     }
    //   }
    //
    //   return update(state, {
    //     permissions: {
    //       [permissionIndex]: {
    //         enabled: {
    //           $set: action.value
    //         }
    //       }
    //     },
    //   });
    // case "CHANGE_ARTICLE_PERMISSION_FOR_SIMPLE":
    //   permissionIndex = state.permissions.findIndex((it) => it.role === 'simple');
    //   if (permissionIndex === -1) {
    //     return {
    //       ...state,
    //       permissions: [...state.permissions, {enabled: action.value, role: 'simple'}]
    //     }
    //   }
    //
    //   return update(state, {
    //     permissions: {
    //       [permissionIndex]: {
    //         enabled: {
    //           $set: action.value
    //         }
    //       }
    //     },
    //   });
    // case "POST_ARTICLE_REQUEST":
    //   return {
    //     ...state,
    //     isLoading: true,
    //     errors: false,
    //   };
    // case "POST_ARTICLE_SUCCEED":
    //   return {
    //     ...state,
    //     isLoading: false,
    //     errors: false,
    //     article: action.response.data,
    //     permissions: action.response.data.permissions,
    //   };
    // case "POST_ARTICLE_FAILURE":
    //   return {
    //     ...state,
    //     isLoading: false,
    //     errors: true,
    //   };
    // case "PATCH_ARTICLE_REQUEST":
    //   return {
    //     ...state,
    //     isLoading: true,
    //     errors: false,
    //   };
    // case "PATCH_ARTICLE_SUCCEED":
    //   return {
    //     ...state,
    //     isLoading: false,
    //     errors: false,
    //     article: action.response.data,
    //     permissions: action.response.data.permissions,
    //   };
    // case "PATCH_ARTICLE_FAILURE":
    //   return {
    //     ...state,
    //     isLoading: false,
    //     errors: true,
    //   };
    // case "DELETE_ARTICLE_REQUEST":
    //   return {
    //     ...state,
    //     isLoading: true,
    //     errors: false,
    //   };
    // case "DELETE_ARTICLE":
    //   return {
    //     ...state,
    //     isLoading: false,
    //     errors: false,
    //   };
    // case "DELETE_ARTICLE_FAILURE":
    //   return {
    //     ...state,
    //     isLoading: false,
    //     errors: true,
    //   };
    default:
      return state;
  }
}
