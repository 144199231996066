// @flow
import React, { Component } from "react";
import { Table, Button, Tabs, Row, Col, Tooltip } from "antd";
import PropTypes from "prop-types";
import update from "immutability-helper";
import { v4 as uuidv4 } from "uuid";
import FieldSet from "./FieldSet";
import Actions from "./Actions";

const { TabPane } = Tabs;

class Panes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      isVisible: false,
      fieldSet: {},
    };
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  onDelete = () => {
    this.props.onDelete(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  // fieldSet
  onOpenFieldSet = (item) => {
    this.setState({ fieldSet: item }, () => {
      this.setState({ isVisible: true });
    });
  };

  onAddFieldSet = () => {
    const { fieldSets } = this.props;
    this.setState(
      {
        fieldSet: {
          id: uuidv4(),
          position: fieldSets.length + 1,
          field_set: null,
          is_exist: false,
          _destroy: false,
        },
      },
      () => {
        this.setState({ isVisible: true });
      }
    );
  };

  onSaveFieldSet = () => {
    this.setState({ isVisible: false }, () => {
      this.props.onSaveFieldSet(this.state.fieldSet);
    });
  };

  onDeleteFieldSets = () => {
    this.props.onDeleteFieldSets(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  onCancelFieldSet = () => {
    this.setState({ isVisible: false });
  };

  onChangeFieldSet = (value) => {
    // console.log(value)
    this.setState({
      fieldSet: update(this.state.fieldSet, {
        field_set: { $set: value },
      }),
    });
  };

  onChangePosition = (value) => {
    this.setState({
      fieldSet: update(this.state.fieldSet, {
        position: { $set: value },
      }),
    });
  };

  render() {
    const { selectedRowKeys, isVisible, fieldSet } = this.state;
    const {
      getFieldDecorator,
      hasFields,
      fieldSets,
      // actions
      taskTemplateId,
      actionTemplate,
      actionTemplateHours,
      actionTemplateSubject,
      onChangeActionTemplate,
      onChangeActionTemplateHours,
      onChangeActionTemplateSubject,
    } = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: "Атрибут",
        dataIndex: "fieldSet",
        render: (fieldSet, record) => (
          <a onClick={this.onOpenFieldSet.bind(this, record)}>
            {record.field_set ? record.field_set.name : ""}
          </a>
        ),
        key: "name",
        width: "100%",
      },
    ];

    const operations = hasSelected ? (
      <Tooltip placement="bottom" title={"Удалить атрибут"}>
        <Button type="danger" icon="delete" onClick={this.onDeleteFieldSets} />
      </Tooltip>
    ) : (
      <Tooltip placement="bottom" title={"Добавить атрибут"}>
        <Button icon="plus" onClick={this.onAddFieldSet} />
      </Tooltip>
    );

    return (
      <Tabs defaultActiveKey="1" tabBarExtraContent={operations}>
        {hasFields ? (
          <TabPane tab="Атрибуты" key="1">
            <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
              <Col
                span={24}
                style={{
                  paddingTop: "15px",
                  paddingBottom: "15px",
                }}
              >
                <Table
                  bordered
                  rowSelection={rowSelection}
                  size={"small"}
                  pagination={false}
                  columns={columns}
                  dataSource={fieldSets}
                  onChange={this.onChangeTable}
                  rowKey="id"
                />
              </Col>
              <Col span={24}>
                <FieldSet
                  visible={isVisible}
                  fieldSet={fieldSet}
                  onSave={this.onSaveFieldSet}
                  onCancel={this.onCancelFieldSet}
                  onChangeFieldSet={this.onChangeFieldSet}
                  onChangePosition={this.onChangePosition}
                />
              </Col>
            </Row>
          </TabPane>
        ) : null}
        <TabPane tab="Действия" key="2">
          <Actions
            getFieldDecorator={getFieldDecorator}
            taskTemplateId={taskTemplateId}
            actionTemplate={actionTemplate}
            actionTemplateHours={actionTemplateHours}
            actionTemplateSubject={actionTemplateSubject}
            onChangeActionTemplate={onChangeActionTemplate}
            onChangeActionTemplateHours={onChangeActionTemplateHours}
            onChangeActionTemplateSubject={onChangeActionTemplateSubject}
          />
        </TabPane>
      </Tabs>
    );
  }
}

Panes.propTypes = {
  fieldSets: PropTypes.arrayOf(PropTypes.object),
};

export default Panes;
