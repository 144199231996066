// @flow
import React, { Component } from "react";
import { Modal, Row, Col, Form, Input, InputNumber, Select } from "antd";
import PropTypes from "prop-types";
import SelectFetch from "../../../components/SelectFetch";

const FormItem = Form.Item;
const Option = Select.Option;

class ModalForm extends Component {
  onSave = () => {
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }

      this.props.onSave();
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const {
      projectStage,
      visible,
      onCancel,
      onChangeName,
      onChangeDescription,
      onChangePosition
    } = this.props;

    return (
      <Modal
        title={`Этап #${projectStage.id ? projectStage.name : "новый"} `}
        destroyOnClose
        visible={visible}
        width={800}
        onOk={this.onSave}
        onCancel={onCancel}
      >
        <Form layout={"vertical"}>
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={18}>
              <FormItem label={"Наименование"}>
                {getFieldDecorator("name", {
                  onChange: onChangeName,
                  initialValue: projectStage.name,
                  rules: [
                    {
                      required: true,
                      message: "Введите наименование!"
                    }
                  ]
                })(<Input placeholder="введите наименование" />)}
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label={"Позиция"}>
                {getFieldDecorator("position", {
                  onChange: onChangePosition,
                  initialValue: projectStage.position
                })(
                  <InputNumber
                    style={{ width: "100%" }}
                    min={0}
                  />
                )}
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem label={"Описание"}>
                {getFieldDecorator("description", {
                  onChange: onChangeDescription,
                  initialValue: projectStage.description,
                  rules: [
                    {
                      required: true,
                      message: "Введите описание!"
                    }
                  ]
                })(<Input placeholder="введите описание" />)}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

ModalForm.propTypes = {
  projectStage: PropTypes.object
};

export default Form.create()(ModalForm);
