// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Drawer, Col, Row, Select, Form } from "antd";

const { Option } = Select;
const FormItem = Form.Item;

class Filter extends Component {
  render() {
    const {
      visible,
      taskOwner,
      taskType,
      onClose,
      onChangeTaskOwner,
      onChangeTaskType,
    } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Drawer
        width={540}
        visible={visible}
        title="Фильтры"
        placement={"right"}
        closable={false}
        onClose={onClose}
      >
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <FormItem label={"Владелец"}>
              {getFieldDecorator("task_owner", {
                onChange: onChangeTaskOwner,
                initialValue: taskOwner,
              })(
                <Select
                  style={{ width: "100%" }}
                  placeholder="Владелец"
                  optionFilterProp="children"
                  filterOption={false}
                >
                  <Option value={null}>нет</Option>
                  <Option value={1}>проекты</Option>
                  <Option value={2}>этапы проектов</Option>
                  <Option value={3}>заказы</Option>
                  <Option value={4}>заявки</Option>
                  <Option value={5}>регламент</Option>
                  <Option value={6}>объекты</Option>
                </Select>
              )}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <FormItem label={"Тип"}>
              {getFieldDecorator("status", {
                onChange: onChangeTaskType,
                initialValue: taskType,
              })(
                <Select
                  style={{ width: "100%" }}
                  placeholder="Тип"
                  optionFilterProp="children"
                  filterOption={false}
                >
                  <Option value={null}>нет</Option>
                  <Option value={1}>простое</Option>
                  <Option value={2}>выполнение работ</Option>
                  <Option value={3}>принятие работ</Option>
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>
      </Drawer>
    );
  }
}

Filter.propTypes = {
  entity: PropTypes.object,
};

export default Form.create()(Filter);
