// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Col, Row, Form, Checkbox, Drawer } from "antd";
import "moment/locale/ru";
import SelectMultiFetch from "../../../components/SelectMultiFetch";
import SelectFetch from "../../../components/SelectFetch";

const FormItem = Form.Item;

class Filter extends Component {
  onChangeUsers = (values) => {
    this.props.onChangeUsers(values);
  };

  onChangeMembers = (values) => {
    this.props.onChangeMembers(values);
  };

  onChangeRegions = (values) => {
    this.props.onChangeRegions(values);
  };

  onChangeActive = (value) => {
    this.props.onChangeActive(value);
  };

  render() {
    const {
      visible,
      users,
      members,
      regions,
      customer,
      entityType,
      active,
      onClose,
      onChangeCustomer,
      onChangeEntityType,
    } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Drawer
        width={540}
        visible={visible}
        title="Фильтры"
        placement={"right"}
        closable={false}
        onClose={onClose}
      >
        <Row>
          <Col span={24}>
            <Checkbox
              value="active"
              checked={active}
              onChange={this.onChangeActive}
            >
              Только незавершенные
            </Checkbox>
          </Col>
        </Row>
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Ответственные"}>
              {getFieldDecorator("users", {
                initialValue: users ? users : [],
              })(
                <SelectMultiFetch
                  showSearch
                  placeholder="Ответственные"
                  onChange={this.onChangeUsers}
                  selected={users ? users : []}
                  url={"/users/search/?all='true'"}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Клиент"}>
              {getFieldDecorator("customer", {
                initialValue: customer ? customer : null,
              })(
                <SelectFetch
                  showSearch
                  placeholder="Клиент"
                  onChange={onChangeCustomer}
                  selected={customer ? customer : null}
                  url={"/customers/search/?"}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Ответственные клиента"}>
              {getFieldDecorator("members", {
                initialValue: members ? members : [],
              })(
                <SelectMultiFetch
                  showSearch
                  placeholder="Ответственные клиента"
                  onChange={this.onChangeMembers}
                  selected={members ? members : []}
                  url={"/members/search/?"}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Регионы"}>
              {getFieldDecorator("regions", {
                initialValue: regions ? regions : [],
              })(
                <SelectMultiFetch
                  showSearch
                  placeholder="Регионы"
                  onChange={this.onChangeRegions}
                  selected={regions ? regions : []}
                  url={"/regions/search/?"}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Тип объекта"}>
              {getFieldDecorator("entityType", {
                initialValue: entityType ? entityType : null,
              })(
                <SelectFetch
                  showSearch
                  placeholder="Тип объекта"
                  onChange={onChangeEntityType}
                  selected={entityType ? entityType : null}
                  url={"/entity_types/search/?"}
                />
              )}
            </FormItem>
          </Col>
        </Row>
      </Drawer>
    );
  }
}

Filter.propTypes = {
  entity: PropTypes.object,
};

export default Form.create()(Filter);
