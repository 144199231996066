// @flow
import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {compose} from "redux";
import {Link, withRouter} from "react-router-dom";
import Actions from "../../../actions/contentTags"
import {Form, Row, Col, DatePicker, Select, List, Button, PageHeader, Input, Card, Breadcrumb, Icon, Tag} from "antd";
import debounce from 'lodash.debounce';
import moment from "moment";
import "moment/locale/ru";
import Can from "../../../rules/Can";
import NoticesActions from "../../../actions/notices";

const Search = Input.Search;

class ContentTags extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contentTagInputEnabled: false,
      contentTagInputValue: '',
      contentTagEditId: null,
      contentTagEditValue: '',
    }
  }

  componentWillMount = () => {
    this.props.dispatch(Actions.fetchContentTags());
  };

  onContentTagInputEnable = () => {
    this.setState({
      contentTagInputEnabled: true
    });
  };

  onContentTagInputChange = (e) => {
    let value = e.target.value
    this.setState({
      contentTagInputValue: value
    });
  };

  onContentTagInputConfirm = () => {
    let value = this.state.contentTagInputValue.trim();
    if (value &&
      this.props.contentTags.find((it) => it.name === value) === undefined) {
      this.props.dispatch(Actions.createContentTag(value)).then(() => {
        this.props.dispatch(Actions.fetchContentTags());
      });
    }

    this.setState({
      contentTagInputEnabled: false,
      contentTagInputValue: ''
    });
  };

  onContentTagEditEnable = (id, value) => {
    this.setState({
      contentTagEditId: id,
      contentTagEditValue: value
    });
  };

  onContentTagEditChange = (e) => {
    let value = e.target.value
    this.setState({
      contentTagEditValue: value
    });
  };

  onContentTagEditConfirm = (id, oldValue) => {
    let newValue = this.state.contentTagEditValue.trim()
    if (oldValue === newValue || newValue === '') {
      this.setState({
        contentTagEditId: null
      });

      return;
    }

    this.props.dispatch(Actions.updateContentTag(id, newValue)).then(() => {
      this.props.dispatch(Actions.fetchContentTags());
    });

    this.setState({
      contentTagEditId: null
    });
  };

  onContentTagDelete = (id) => {
    this.props.dispatch(Actions.deleteContentTag(id)).then(() => {
      this.props.dispatch(Actions.fetchContentTags());
    });
  }

  render() {
    const {
      contentTagInputEnabled,
      contentTagInputValue,
      contentTagEditId,
      contentTagEditValue
    } = this.state;
    const {
      currentUser,
      contentTags
    } = this.props;
    const {
      getFieldDecorator
    } = this.props.form;

    let renderContentTagInput = contentTagInputEnabled ?
      <Row
        style={{marginBottom: "8px"}}>
        {getFieldDecorator("name", {
          initialValue: contentTagInputValue,
          onChange: this.onContentTagInputChange
        })(<Input
          autoFocus
          type="text"
          size="small"
          onBlur={this.onContentTagInputConfirm}
          onPressEnter={this.onContentTagInputConfirm}
          style={{
            width: "124px",
            verticalAlign: "top"
          }}
        />)}
      </Row> :
      <Row
        style={{marginBottom: "8px"}}>
        <Tag
          onClick={this.onContentTagInputEnable}
          style={{
            background: "#fff",
            borderStyle: "dashed",
            cursor: "text"
          }}>
          <Icon
            type={"plus"}/>
          {` Новая категория`}
        </Tag>
      </Row>;

    let renderContentTags = contentTags.map((contentTag) => {
      return contentTag.id === contentTagEditId ?
        <Row
          key={contentTag.id}
          style={{marginBottom: "8px"}}>
          {getFieldDecorator("name", {
            initialValue: contentTagEditValue,
            onChange: this.onContentTagEditChange
          })(<Input
            autoFocus
            type="text"
            size="small"
            value={contentTagEditValue}
            onChange={this.onContentTagEditChange}
            onBlur={() => this.onContentTagEditConfirm(contentTag.id, contentTag.name)}
            onPressEnter={() => this.onContentTagEditConfirm(contentTag.id, contentTag.name)}
            style={{
              width: "124px",
              verticalAlign: "top"
            }}
          />)}
        </Row> :
        <Row
          key={contentTag.id}
          style={{marginBottom: "8px"}}>
          <Tag
            closable={true}
            onClick={() => this.onContentTagEditEnable(contentTag.id, contentTag.name)}
            onClose={() => this.onContentTagDelete(contentTag.id)}
            color="#108ee9"
            style={{
              cursor: "text"
            }}
          >
            {contentTag.name}
          </Tag>
        </Row>;
    });

    return (
      <>
        <PageHeader
          onBack={() => window.history.back()}
          title={`Категории (база знаний)`}
        />
        <Card>
          <Form
            layout={"vertical"}>
            {renderContentTagInput}
            {renderContentTags}
          </Form>
        </Card>
      </>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.session.currentUser,
  isLoading: state.contentTags.isLoading,
  fetchParams: state.contentTags.fetchParams,
  contentTags: state.contentTags.contentTags
});

export default compose(
  Form.create(),
  withRouter,
  connect(mapStateToProps)
)(ContentTags);
