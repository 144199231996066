import update from "immutability-helper";

const initialState = {
  employeePayrolls: [],
  employeePayroll: { employee_payroll_items: [] },
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1,
  },
  fetchParams: {
    is_active: null,
    search: null,
    sort: null,
    direction: null,
    page: 1,
    limit: 15,
  },
  errors: false,
  isLoading: true,
  isLoadingPayrollItems: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "FETCH_EMPLOYEE_PAYROLLS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_EMPLOYEE_PAYROLLS":
      return {
        ...state,
        employeePayrolls: action.response.data,
        meta: action.response.meta,
        isLoading: false,
      };
    case "FETCH_EMPLOYEE_PAYROLLS_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "FETCH_EMPLOYEE_PAYROLL_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_EMPLOYEE_PAYROLL":
      return {
        ...state,
        employeePayroll: action.response.data,
        isLoading: false,
      };

    case "FETCH_EMPLOYEE_PAYROLLS_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "POST_EMPLOYEE_PAYROLL_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "POST_EMPLOYEE_PAYROLL":
      return {
        ...state,
        employeePayroll: action.response.data,
        isLoading: false,
      };

    case "POST_EMPLOYEE_PAYROLL_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "PATCH_EMPLOYEE_PAYROLL_REQUEST":
      return {
        ...state,
        errors: false,
      };
    case "PATCH_EMPLOYEE_PAYROLL":
      return {
        ...state,
        employeePayroll: action.response.data,
        errors: false,
      };

    case "PATCH_EMPLOYEE_PAYROLL_FAILURE":
      return {
        ...state,
        errors: true,
      };

    case "FILL_IN_EMPLOYEE_PAYROLL_ITEMS_REQUEST":
      return {
        ...state,
        isLoadindPayrollItems: true,
        errors: false,
      };
    case "FILL_IN_EMPLOYEE_PAYROLL_ITEMS":
      return {
        ...state,
        employeePayroll: action.response.data,
        isLoadindPayrollItems: false,
        errors: false,
      };

    case "FILL_IN_EMPLOYEE_PAYROLL_ITEMS_FAILURE":
      return {
        ...state,
        isLoadindPayrollItems: false,
        errors: true,
      };

    case "DELETE_EMPLOYEE_PAYROLL_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };
    case "DELETE_EMPLOYEE_PAYROLL":
      return {
        ...state,
        isLoading: false,
        errors: false,
      };
    case "DELETE_EMPLOYEE_PAYROLL_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "EMPLOYEE_PAYROLL_NEW":
      return {
        ...state,
        errors: false,
        employeePayroll: {
          employee_payroll_items: [],
          is_active: true,
        },
        isLoading: false,
      };

    case "REQUEST_EMPLOYEE_PAYROLL_SEARCH":
      return update(state, {
        fetchParams: { search: { $set: action.value }, page: { $set: 1 } },
      });

    case "REQUEST_EMPLOYEE_PAYROLL_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } },
      });

    case "REQUEST_EMPLOYEE_PAYROLL_CHANGE_LIMIT":
      return update(state, {
        fetchParams: { limit: { $set: action.value }, page: { $set: 1 } },
      });

    case "EMPLOYEE_PAYROLLS_SORT_BY":
      return update(state, {
        fetchParams: {
          sort: { $set: action.field },
          direction: { $set: action.direction },
        },
      });

    case "EMPLOYEE_PAYROLL_CHANGE_ACTIVE":
      return update(state, {
        employeePayroll: { is_active: { $set: action.value } },
      });

    case "EMPLOYEE_PAYROLL_CHANGE_DATE":
      return update(state, {
        employeePayroll: {
          date: { $set: action.value },
        },
      });

    case "EMPLOYEE_PAYROLL_CHANGE_DEPARTMENT":
      return update(state, {
        employeePayroll: { department: { $set: action.value } },
      });

    case "EMPLOYEE_PAYROLL_CHANGE_PAYROLL_SCHEMA":
      return update(state, {
        employeePayroll: { payroll_schema: { $set: action.value } },
      });

    // employee_payroll_items
    case "REFILL_IN_EMPLOYEE_PAYROLL_ITEMS_REQUEST":
      return {
        ...state,
        isLoadindPayrollItems: true,
        errors: false,
      };
    case "REFILL_IN_EMPLOYEE_PAYROLL_ITEMS":
      return {
        ...state,
        employeePayroll: action.response.data,
        isLoadindPayrollItems: false,
        errors: false,
      };

    case "REFILL_IN_EMPLOYEE_PAYROLL_ITEMS_FAILURE":
      return {
        ...state,
        isLoadindPayrollItems: false,
        errors: true,
      };

    case "PATCH_EMPLOYEE_PAYROLL_ITEM_REQUEST":
      return {
        ...state,
        errors: false,
      };
    case "PATCH_EMPLOYEE_PAYROLL_ITEM":
      return {
        ...state,
        errors: false,
      };

    case "PATCH_EMPLOYEE_PAYROLL_ITEM_FAILURE":
      return {
        ...state,
        errors: true,
      };

    case "POST_EMPLOYEE_PAYROLL_ITEM_REQUEST":
      return {
        ...state,
        isLoadindPayrollItems: true,
        errors: false,
      };
    case "POST_EMPLOYEE_PAYROLL_ITEM":
      return update(state, {
        employeePayroll: {
          employee_payroll_items: {
            $set: action.response.data.employee_payroll_items,
          },
        },
        isLoadindPayrollItems: { $set: false },
        errors: { $set: false },
      });

    case "POST_EMPLOYEE_PAYROLL_ITEM_FAILURE":
      return {
        ...state,
        errors: true,
        isLoadindPayrollItems: false,
      };

    case "DELETE_EMPLOYEE_PAYROLL_ITEM_REQUEST":
      return {
        ...state,
        isLoadindPayrollItems: true,
        errors: false,
      };
    case "DELETE_EMPLOYEE_PAYROLL_ITEM":
      return update(state, {
        employeePayroll: {
          employee_payroll_items: {
            $set: action.response.data.employee_payroll_items,
          },
        },
        isLoadindPayrollItems: { $set: false },
        errors: { $set: false },
      });
    case "DELETE_EMPLOYEE_PAYROLL_ITEM_FAILURE":
      return {
        ...state,
        isLoadindPayrollItems: false,
        errors: true,
      };

    default:
      return state;
  }
}
