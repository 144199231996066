// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Col, Row, Button, Table } from "antd";
import update from "immutability-helper";
import OpeningHourModal from "./OpeningHourModal";
import _ from "lodash/array";
import moment from "moment";
import "moment/locale/ru";

class OpeningHours extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      isVisible: false,
      records: []
    };
  }

  onOpenOpeningHourModal = item => {
    this.setState({ entityService: item }, () => {
      this.setState({ isVisible: true });
    });
  };

  onAddOpeningHours = () => {
    this.setState(
      {
        records: [
          {
            id: `${Date.now()}-1`,
            day: 1,
            opens: null,
            closes: null,
            is_exist: false
          },
          {
            id: `${Date.now()}-2`,
            day: 2,
            opens: null,
            closes: null,
            is_exist: false
          },
          {
            id: `${Date.now()}-3`,
            day: 3,
            opens: null,
            closes: null,
            is_exist: false
          },
          {
            id: `${Date.now()}-4`,
            day: 4,
            opens: null,
            closes: null,
            is_exist: false
          },
          {
            id: `${Date.now()}-5`,
            day: 5,
            opens: null,
            closes: null,
            is_exist: false
          },
          {
            id: `${Date.now()}-6`,
            day: 6,
            opens: null,
            closes: null,
            is_exist: false
          },
          {
            id: `${Date.now()}-7`,
            day: 7,
            opens: null,
            closes: null,
            is_exist: false
          }
        ]
      },
      () => {
        this.setState({ isVisible: true });
      }
    );
  };

  onEditOpeningHours = () => {
    this.setState({ records: this.props.openingHours }, () => {
      this.setState({ isVisible: true });
    });
  };

  onSaveOpeningHours = () => {
    this.setState({ isVisible: false }, () => {
      this.props.onSaveOpeningHours(this.state.records);
    });
  };

  onCancel = () => {
    this.setState({ isVisible: false });
  };

  onChangeOpens = (id, value, timeString) => {
    const idx = _.findIndex(this.state.records, ["id", id]);
    if (idx !== -1) {
      this.setState(
        {
          records: update(this.state.records, {
            [idx]: { opens: { $set: timeString } }
          })
        },
        () => {
          if (idx == 0) {
            this.fillOpensByMonday(timeString);
          }
        }
      );
    }
  };

  fillOpensByMonday = timeString => {
    let records = this.state.records;
    records.map((record, i) => {
      // if (record.opens == null && record.day < 6) {
      if (record.day < 6) {
        records[i].opens = timeString;
      }
    });
    this.setState({ records: records });
  };

  onChangeCloses = (id, value, timeString) => {
    const idx = _.findIndex(this.state.records, ["id", id]);
    if (idx !== -1) {
      this.setState(
        {
          records: update(this.state.records, {
            [idx]: { closes: { $set: timeString } }
          })
        },
        () => {
          if (idx == 0) {
            this.fillClosesByMonday(timeString);
          }
        }
      );
    }
  };

  fillClosesByMonday = timeString => {
    let records = this.state.records;
    records.map((record, i) => {
      if (record.day < 6) {
        records[i].closes = timeString;
      }
    });
    this.setState({ records: records });
  };

  render() {
    const { isVisible, records } = this.state;
    const { openingHours } = this.props;

    const columns = [
      {
        title: "#",
        dataIndex: "day",
        render: day =>
          moment()
            .isoWeekday(day)
            .format("dddd"),
        key: "name"
      },
      {
        title: "C",
        dataIndex: "opens",
        key: "opens"
      },
      {
        title: "По",
        dataIndex: "closes",
        key: "closes"
      }
    ];

    const navButton = (
      <Row gutter={{ md: 8, lg: 24, xl: 48 }} style={{ marginBottom: "10px" }}>
        <Col span={24}>
          {openingHours.length > 0 ? (
            <Button onClick={this.onEditOpeningHours}>Изменить</Button>
          ) : (
            <Button onClick={this.onAddOpeningHours}>Добавить</Button>
          )}
        </Col>
      </Row>
    );

    return (
      <React.Fragment>
        {navButton}
        <Row gutter={16} style={{ paddingBottom: 10 }}>
          <Col span={24}>
            <Table
              size={"small"}
              showHeader={false}
              pagination={false}
              dataSource={openingHours}
              columns={columns}
            />
          </Col>
        </Row>
        <OpeningHourModal
          visible={isVisible}
          records={records}
          onSave={this.onSaveOpeningHours}
          onCancel={this.onCancel}
          onChangeOpens={this.onChangeOpens}
          onChangeCloses={this.onChangeCloses}
        />
      </React.Fragment>
    );
  }
}

OpeningHours.propTypes = {
  records: PropTypes.arrayOf(PropTypes.object)
};

export default OpeningHours;
