// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import Actions from "../../../actions/projects";
import Task from "./Task";

class Info extends Component {
  componentDidMount = () => {
    const { taskOwner, ownerId  } = this.props;
    const params = {
      task_owner: taskOwner,
      owner_id: ownerId
    };
    // console.log("componentDidMount", stateId);
    if (taskOwner && ownerId) {
      this.props.dispatch(Actions.fetchTasks(params));
    }
  };


  render() {
    const { isLoading, tasks } = this.props;
    return (
      <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
        <Col span={24}>
          <Task isLoading={isLoading} tasks={tasks} />
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.projects.isTasksLoading,
  tasks: state.projects.tasks
});

export default connect(mapStateToProps)(Info);
