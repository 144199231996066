// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, Form, Card, Tabs, Divider, Badge } from "antd";
import Statuses from "./Statuses";
import Sidebar from "./Sidebar";
import General from "./General";
import Comments from "./Comments";
import Activities from "./Activities";
import Can from "../../../rules/Can";
import CommentForm from "../../../components/CommentForm";
import Todos from "./Todos";

const { TabPane } = Tabs;

function statuses(status) {
  switch (status) {
    case "pending":
      return "error";
    case "processing":
      return "processing";
    case "finished":
      return "success";
    default:
      return "default";
  }
}

class CustomerForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: "",
      status: null,
      isCommentFormVisible: false,
    };
  }

  onSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onChangeStatus(values);
    });
  };

  callback = key => {
    if (key == "2") {
      this.props.fetchTasksActivities();
    }
  };

  // status
  onChangeStatus = (value) => {
    if (value.id == "finished") {
      this.setState({ isCommentFormVisible: true, status: value });
    } else {
      this.props.onChangeStatus(value, null);
    }
    // console.log(value)
  };

  // comment
  onSaveComment = () => {
    const { status, comment } = this.state;
    this.props.onChangeStatus(status, comment);
    this.setState({ isCommentFormVisible: false, status: null });
  };

  onCancelComment = () => {
    this.setState({ comment: "" }, () => {
      this.setState({ isCommentFormVisible: false, status: null }, () => {
        this.props.fetchTicket();
      });
    });
  };

  onChangeComment = (e) => {
    this.setState({
      comment: e.target.value,
    });
  };

  render() {
    const { comment, isCommentFormVisible } = this.state;
    const { getFieldDecorator } = this.props.form;
    const {
      isNew,
      errors,
      isLoading,
      isCommentsLoading,
      currentUser,
      orderTask,
      onClose,
      onChangeSubject,
      onChangeDescription,
      onChangeDueFrom,
      onChangeDueBy,
      onChangePlanDays,
      services,
      onChangeServices,
      // comments
      onCreateComment,
      // assignees
      isAssigneesLoading,
      onCreateAssignee,
      onDeleteAssignee,
      // attachments
      isAttachmentLoading,
      onDeleteAttachment,
      onUploadAttachment,
      onUploadCommentAttachment,
      onDeleteCommentAttachment,
      // activities
      isActivitiesLoading,
      taskActivities,
      // statuses
      onChangeStatus,
      // todos
      onSaveTodoItemComment,
      onCompleteTodoItem,
    } = this.props;

    const organisationAssignees = orderTask.assignees.filter(function(item) {
      return item.assignee_type == "organisation";
    });

    const customerAssignees = orderTask.assignees.filter(function(item) {
      return item.assignee_type == "customer";
    });

    const companyAssignees = orderTask.assignees.filter(function (item) {
      return item.assignee_type == "company";
    });

    const commentAttachments = (orderTask.attachments ?? [])
      .filter((attachment) => !!attachment.order_task_comment_id);

    const orderTaskTodos = orderTask.order_task_todos ?? []

    return (
      <Form layout={"vertical"} onSubmit={this.onSubmit}>
        <Card
          loading={isLoading}
          title={
            <div>
              <h2
                style={{ marginBottom: 0 }}
              >{`Задача по заказу (${orderTask.task_type_locale}) #${orderTask.number}`}</h2>
              <span style={{ fontSize: "12px" }}>
                <Badge
                  status={statuses(
                    orderTask.status ? orderTask.status.id : null
                  )}
                  text={orderTask.status ? orderTask.status.name : null}
                />
              </span>
            </div>
          }
          extra={
            <div>
              <Button onClick={onClose}>Закрыть</Button>
            </div>
          }
        >
          <Col span={13}>
            <Row>
              <General
                entity={orderTask.entity}
                order={orderTask.order}
                currentUser={currentUser}
                customer={orderTask.customer}
                subject={orderTask.subject}
                description={orderTask.description}
                onChangeSubject={onChangeSubject}
                onChangeDescription={onChangeDescription}
              />
            </Row>
            {orderTaskTodos.length === 0 ?
              null :
              <>
                <Divider>Чек-лист</Divider>
                <Row>
                  <Todos
                    orderTaskTodos={orderTaskTodos}
                    onCompleted={onCompleteTodoItem}
                    onSaveComment={onSaveTodoItemComment}
                  />
                </Row>
              </>
            }
            <Divider>Комментарии</Divider>
            <Row>
              {isNew ? null : (
                <Comments
                  currentUser={currentUser}
                  getFieldDecorator={getFieldDecorator}
                  isCommentsLoading={isCommentsLoading}
                  errors={errors}
                  onCreateComment={onCreateComment}
                  comments={orderTask.comments}
                  onUploadCommentAttachment={onUploadCommentAttachment}
                  onDeleteCommentAttachment={onDeleteCommentAttachment}
                  commentAttachments={commentAttachments}
                />
              )}
            </Row>
          </Col>
          <Col span={1} />
          <Col span={10}>
            <Row>
              <Tabs defaultActiveKey="1" onChange={this.callback}>
                <TabPane tab="Статусы" key="1">
                  <Can
                    role={currentUser.role}
                    perform="entity_tasks:edit"
                    yes={() => (
                      <Statuses
                        isNew={isNew}
                        currentUser={currentUser}
                        taskType={orderTask.task_type}
                        due_from={orderTask.due_from}
                        due_by={orderTask.due_by}
                        planDays={orderTask.plan_days}
                        start={orderTask.start}
                        finish={orderTask.finish}
                        getFieldDecorator={getFieldDecorator}
                        onChangeDueFrom={onChangeDueFrom}
                        onChangeDueBy={onChangeDueBy}
                        onChangePlanDays={onChangePlanDays}
                        services={services}
                        onChangeServices={onChangeServices}
                        // assignees
                        isAssigneesLoading={isAssigneesLoading}
                        organisationAssignees={organisationAssignees}
                        customer={orderTask.customer}
                        customerAssignees={customerAssignees}
                        company={orderTask.company}
                        companyAssignees={companyAssignees}
                        onCreateAssignee={onCreateAssignee}
                        onDeleteAssignee={onDeleteAssignee}
                        // attachments
                        isAttachmentLoading={isAttachmentLoading}
                        attachments={orderTask.attachments}
                        onDeleteAttachment={onDeleteAttachment}
                        onUploadAttachment={onUploadAttachment}
                        // statuses
                        taskId={orderTask.id}
                        status={orderTask.status}
                        statusUrl={orderTask.status_url}
                        statusPostUrl={orderTask.status_post_url}
                        onChangeStatus={this.onChangeStatus}
                      />
                    )}
                    no={() => (
                      <Sidebar
                        isNew={isNew}
                        currentUser={currentUser}
                        taskType={orderTask.task_type}
                        due_from={orderTask.due_from}
                        due_by={orderTask.due_by}
                        planDays={orderTask.plan_days}
                        start={orderTask.start}
                        finish={orderTask.finish}
                        getFieldDecorator={getFieldDecorator}
                        onChangeDueFrom={onChangeDueFrom}
                        onChangeDueBy={onChangeDueBy}
                        onChangePlanDays={onChangePlanDays}
                        // assignees
                        isAssigneesLoading={isAssigneesLoading}
                        organisationAssignees={organisationAssignees}
                        customer={orderTask.customer}
                        customerAssignees={customerAssignees}
                        company={orderTask.company}
                        companyAssignees={companyAssignees}
                        onCreateAssignee={onCreateAssignee}
                        onDeleteAssignee={onDeleteAssignee}
                        // attachments
                        isAttachmentLoading={isAttachmentLoading}
                        attachments={orderTask.attachments}
                        onDeleteAttachment={onDeleteAttachment}
                        onUploadAttachment={onUploadAttachment}
                        // statuses
                        taskId={orderTask.id}
                        status={orderTask.status}
                        statusUrl={orderTask.status_url}
                        statusPostUrl={orderTask.status_post_url}
                        onChangeStatus={this.onChangeStatus}
                      />
                    )}
                  />
                </TabPane>
                <TabPane tab="Лог изменений" key="2">
                  <Activities
                    taskActivities={taskActivities}
                    isActivitiesLoading={isActivitiesLoading}
                  />
                </TabPane>
              </Tabs>
            </Row>
          </Col>
        </Card>
        <CommentForm
          visible={isCommentFormVisible}
          comment={comment}
          onSave={this.onSaveComment}
          onCancel={this.onCancelComment}
          onChangeComment={this.onChangeComment}
        />
      </Form>
    );
  }
}

CustomerForm.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  orderTask: PropTypes.object,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
  router: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  form: PropTypes.object
};

export default Form.create()(CustomerForm);
