import update from "immutability-helper";
import moment from "moment";

const initialState = {
  projects: [],
  project: {
    assignees: [],
    project_services: [],
    comments: [],
    proposals: [],
    attachments: [],
  },
  activities: [],
  notices: [],
  tasks: [],
  actions: [],
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1,
  },
  fetchParams: {
    startDate: null,
    endDate: null,
    starFinish: null,
    endFinish: null,
    search: null,
    sort: null,
    direction: null,
    page: 1,
    limit: 10,
    status: null,
    customer: null,
    users: null,
    members: null,
  },
  errors: false,
  isLoading: true,
  isTasksLoading: false,
  isAttachmentLoading: false,
  isAssigneesLoading: false,
  isCommentsLoading: false,
  isActivitiesLoading: true,
  isStagesLoading: false,
};

function loadLocalFilters(fetchParams) {
  let localFetchParams = null;
  const projectsFilters = localStorage.getItem("projectsFilters");
  if (projectsFilters) {
    localFetchParams = JSON.parse(projectsFilters);
  } else {
    localStorage.setItem("projectsFilters", JSON.stringify(fetchParams));
  }

  return localFetchParams;
}

export default function (state = initialState, action) {
  let localFetchParams = null;
  switch (action.type) {
    case "FETCH_PROJECTS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_PROJECTS":
      return {
        ...state,
        projects: action.response.data,
        meta: action.response.meta,
        actions: action.response.actions,
        notices: action.response.notices,
        isLoading: false,
      };
    case "FETCH_PROJECT_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_PROJECT":
      return {
        ...state,
        project: action.response.data,
        actions: action.response.actions,
        isLoading: false,
      };
    case "PATCH_PROJECT_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };
    case "PATCH_PROJECT":
      return {
        ...state,
        project: action.response.data,
        isLoading: false,
        errors: false,
      };

    case "PATCH_PROJECT_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "POST_PROJECT_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "POST_PROJECT":
      return {
        ...state,
        errors: false,
        project: action.response.data,
        isLoading: false,
      };

    case "POST_PROJECT_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "DELETE_PROJECT_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };
    case "DELETE_PROJECT":
      return {
        ...state,
        projects: action.response.data,
        isLoading: false,
        errors: false,
      };
    case "DELETE_PROJECT_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "PROJECT_NEW":
      return {
        ...state,
        errors: false,
        project: {
          customer: null,
          status: "pended",
          start: moment(),
          finish: moment(),
          assignees: [],
          comments: [],
          attachments: [],
        },
        isLoading: false,
      };

    case "REQUEST_PROJECT_SEARCH":
      return update(state, {
        fetchParams: { search: { $set: action.value }, page: { $set: 1 } },
      });

    case "REQUEST_PROJECT_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } },
      });

    case "REQUEST_PROJECT_CHANGE_LIMIT":
      return update(state, {
        fetchParams: { limit: { $set: action.value }, page: { $set: 1 } },
      });

    case "REQUEST_PROJECTS_FILTER_ACTIVE":
      return update(state, {
        fetchParams: { active: { $set: action.value } },
      });

    case "PROJECTS_SORT_BY":
      return update(state, {
        fetchParams: {
          sort: { $set: action.field },
          direction: { $set: action.direction },
        },
      });

    //header
    case "PROJECT_CHANGE_NUMBER":
      return update(state, {
        project: { number: { $set: action.value } },
      });

    case "PROJECT_CHANGE_CUSTOMER":
      return update(state, {
        project: {
          customer: { $set: action.value },
          entity: { $set: null },
          address: { $set: null },
        },
      });

    case "PROJECT_CHANGE_CUSTOMER_ENTITY":
      return update(state, {
        project: {
          entity: { $set: action.value },
          address: { $set: action.value.extra.address },
        },
      });

    case "PROJECT_CHANGE_PROJECT_TYPE":
      return update(state, {
        project: {
          project_type: { $set: action.value },
        },
      });

    case "PROJECT_CHANGE_AMOUNT":
      return update(state, {
        project: { amount: { $set: action.value } },
      });

    case "PROJECT_CHANGE_DESCRIPTION":
      return update(state, {
        project: { description: { $set: action.value } },
      });

    // statuses
    case "PROJECT_CHANGE_STATUS":
      return update(state, {
        project: { status: { $set: action.value } },
      });

    // activities
    case "FETCH_PROJECT_ACTIVITIES_REQUEST":
      return {
        ...state,
        isActivitiesLoading: true,
      };
    case "FETCH_PROJECT_ACTIVITIES":
      return update(state, {
        activities: { $set: action.response.data },
        isActivitiesLoading: { $set: false },
        errors: { $set: false },
      });

    case "FETCH_PROJECT_ACTIVITIES_FAILURE":
      return {
        ...state,
        errors: true,
        isActivitiesLoading: false,
      };

    // assignees
    case "FETCH_ASSIGNEES_REQUEST":
      return {
        ...state,
        isAssigneesLoading: true,
      };
    case "FETCH_ASSIGNEES":
      return update(state, {
        project: { assignees: { $set: action.response.data } },
        isAssigneesLoading: { $set: false },
      });

    case "FETCH_ASSIGNEES_FAILURE":
      return {
        ...state,
        errors: true,
        isAssigneesLoading: false,
      };

    case "POST_ASSIGNEE_REQUEST":
      return {
        ...state,
        errors: false,
        isAssigneesLoading: true,
      };
    case "POST_ASSIGNEE":
      return {
        ...state,
        errors: false,
        isAssigneesLoading: false,
      };

    case "POST_ASSIGNEE_FAILURE":
      return {
        ...state,
        errors: true,
        isAssigneesLoading: false,
      };

    // stages
    case "FETCH_PROJECT_STAGES_REQUEST":
      return {
        ...state,
        isStagesLoading: true,
      };
    case "FETCH_PROJECT_STAGES":
      return update(state, {
        project: { project_stages: { $set: action.response.data } },
        isStagesLoading: { $set: false },
      });

    case "FETCH_PROJECT_STAGES_FAILURE":
      return {
        ...state,
        errors: true,
        isStagesLoading: false,
      };

    case "PATCH_PROJECT_STAGE_REQUEST":
      return {
        ...state,
        isStagesLoading: true,
        errors: false,
      };
    case "PATCH_PROJECT_STAGE":
      return {
        ...state,
        isStagesLoading: false,
        errors: false,
      };

    case "PATCH_PROJECT_STAGE_FAILURE":
      return {
        ...state,
        isStagesLoading: false,
        errors: true,
      };

    case "POST_PROJECT_SCHEDULE_REQUEST":
      return {
        ...state,
        errors: false,
        isStagesLoading: true,
      };
    case "POST_PROJECT_STAGE":
      return {
        ...state,
        errors: false,
        isStagesLoading: false,
      };

    case "POST_PROJECT_STAGE_FAILURE":
      return {
        ...state,
        errors: true,
        isStagesLoading: false,
      };

    case "DELETE_PROJECT_STAGES_REQUEST":
      return {
        ...state,
        isStagesLoading: true,
        errors: false,
      };
    case "DELETE_PROJECT_STAGES_SUCCEED":
      return {
        ...state,
        isSchedulesLoading: false,
        errors: false,
      };

    case "DELETE_PROJECT_STAGES_FAILURE":
      return {
        ...state,
        isSchedulesLoading: false,
        errors: true,
      };

    // comments
    case "FETCH_PROJECT_COMMENTS_REQUEST":
      return {
        ...state,
        isCommentsLoading: true,
      };
    case "FETCH_PROJECT_COMMENTS":
      return update(state, {
        project: { comments: { $set: action.response.data } },
        isCommentsLoading: { $set: false },
      });

    case "FETCH_PROJECT_COMMENTS_FAILURE":
      return {
        ...state,
        errors: true,
        isCommentsLoading: false,
      };

    case "POST_PROJECT_COMMENT_REQUEST":
      return {
        ...state,
        errors: false,
        isCommentsLoading: true,
      };
    case "POST_PROJECT_COMMENT":
      return {
        ...state,
        errors: false,
        isCommentsLoading: false,
      };

    case "POST_PROJECT_COMMENT_FAILURE":
      return {
        ...state,
        errors: true,
        isCommentsLoading: false,
      };

    // proposals
    case "FETCH_PROJECT_PROPOSALS_REQUEST":
      return {
        ...state,
        isLoadingProposals: true,
      };

    case "FETCH_PROJECT_PROPOSALS":
      return update(state, {
        project: { proposals: { $set: action.response.data } },
        isLoadingProposals: { $set: false },
      });

    case "FETCH_PROJECT_PROPOSALS":
      return update(state, {
        project: { proposals: { $set: action.response.data } },
        isLoadingProposals: { $set: false },
      });

    case "PROJECT_PROPOSAL_UPLOAD":
      return update(state, {
        isLoadingProposals: { $set: true },
      });

    case "PROJECT_PROPOSAL_UPLOAD_SUCCEED":
      return update(state, {
        project: { proposals: { $set: action.response.data } },
        errors: { $set: false },
        isLoadingProposals: { $set: false },
      });

    case "PROJECT_PROPOSAL_UPLOAD_FAILED":
      return update(state, {
        errors: { $set: true },
        isLoadingProposals: { $set: false },
      });

    case "PROJECT_PROPOSAL_DELETE_REQUEST":
      return {
        ...state,
        errors: false,
        isLoadingProposals: true,
      };
    case "PROJECT_PROPOSAL_DELETE_SUCCEED":
      return {
        ...state,
        errors: false,
        isLoadingProposals: false,
      };
    case "PROJECT_PROPOSAL_DELETE_FAILED":
      return {
        ...state,
        errors: true,
        isLoadingProposals: false,
      };

    // attachments
    case "FETCH_PROJECT_ATTACHMENTS_REQUEST":
      return {
        ...state,
        isAttachmentLoading: true,
      };

    case "FETCH_PROJECT_ATTACHMENTS":
      return update(state, {
        project: { attachments: { $set: action.response.data } },
        isAttachmentLoading: { $set: false },
      });
    case "FETCH_PROJECT_ATTACHMENTS_FAILURE":
      return {
        ...state,
        errors: true,
        isAttachmentLoading: false,
      };

    case "ATTACHMENT_UPLOAD":
      return update(state, {
        isAttachmentLoading: { $set: true },
      });

    case "ATTACHMENT_UPLOAD_SUCCEED":
      return update(state, {
        project: { attachments: { $set: action.response.data } },
        errors: { $set: false },
        isAttachmentLoading: { $set: false },
      });

    case "ATTACHMENT_UPLOAD_FAILED":
      return update(state, {
        errors: { $set: true },
        isAttachmentLoading: { $set: false },
      });

    case "ATTACHMENT_DELETE_REQUEST":
      return {
        ...state,
        errors: false,
        isAttachmentLoading: true,
      };
    case "ATTACHMENT_DELETE_SUCCEED":
      return {
        ...state,
        errors: false,
        isAttachmentLoading: false,
      };
    case "ATTACHMENT_DELETE_FAILED":
      return {
        ...state,
        errors: true,
        isAttachmentLoading: false,
      };

    // tasks
    case "FETCH_PROJECT_TASKS_REQUEST":
      return {
        ...state,
        tasks: [],
        isTasksLoading: true,
      };
    case "FETCH_PROJECT_TASKS":
      return {
        ...state,
        tasks: action.response.data,
        isTasksLoading: false,
      };
    case "FETCH_PROJECT_TASK_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_PROJECT_TASK":
      return {
        ...state,
        task: action.response.data,
        isLoading: false,
      };

    // filters
    case "PROJECTS_CLEAR_FILTERS":
      localStorage.removeItem("projectsFilters");

      return update(state, {
        fetchParams: {
          status: { $set: null },
          users: { $set: null },
          members: { $set: null },
        },
      });

    case "PROJECTS_SET_FILTERS":
      localFetchParams = loadLocalFilters(state.fetchParams);
      if (localFetchParams) {
        localFetchParams.search = null;
        return {
          ...state,
          fetchParams: localFetchParams,
        };
      } else {
        return {
          ...state,
        };
      }

    case "PROJECTS_FILTER_STATUS":
      return update(state, {
        fetchParams: {
          status: { $set: action.values },
          page: { $set: 1 },
        },
      });

    case "PROJECTS_FILTER_PERIOD":
      return update(state, {
        fetchParams: {
          startDate: { $set: action.startDate },
          endDate: { $set: action.endDate },
          starFinish: { $set: null },
          endFinish: { $set: null },
          page: { $set: 1 },
        },
      });

    case "PROJECTS_FILTER_PERIOD_FINISH":
      return update(state, {
        fetchParams: {
          starFinish: { $set: action.starFinish },
          endFinish: { $set: action.endFinish },
          startDate: { $set: null },
          endDate: { $set: null },
          page: { $set: 1 },
        },
      });

    case "PROJECTS_FILTER_USERS":
      return update(state, {
        fetchParams: {
          users: { $set: action.values.length > 0 ? action.values : null },
          page: { $set: 1 },
        },
      });

    case "PROJECTS_FILTER_MEMBERS":
      return update(state, {
        fetchParams: {
          members: { $set: action.values.length > 0 ? action.values : null },
          page: { $set: 1 },
        },
      });

    case "PROJECTS_FILTER_CUSTOMER":
      return update(state, {
        fetchParams: {
          customer: { $set: action.value ? action.value : null },
          page: { $set: 1 },
        },
      });

    // notices
    case "POST_PROJECT_MARK_ALL_AS_READ_REQUEST":
      return {
        ...state,
        errors: false,
      };
    case "POST_PROJECT_MARK_ALL_AS_READ":
      return {
        ...state,
        errors: false,
        notices: action.response.data,
      };

    case "POST_PROJECT_MARK_ALL_AS_READ_FAILURE":
      return {
        ...state,
        errors: true,
      };

    // notices
    case "POST_PROJECT_MARK_ALL_AS_READ_REQUEST":
      return {
        ...state,
        errors: false,
      };
    case "POST_PROJECT_MARK_ALL_AS_READ":
      return {
        ...state,
        errors: false,
        notices: action.response.data,
      };

    case "POST_PROJECT_MARK_ALL_AS_READ_FAILURE":
      return {
        ...state,
        errors: true,
      };

    default:
      return state;
  }
}
