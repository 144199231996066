// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Col,
  Row,
  DatePicker,
  Select,
  Form,
  Drawer,
} from "antd";
import moment from "moment";
import "moment/locale/ru";
import SelectMultiFetch from "../../../components/SelectMultiFetch";
import Can from "../../../rules/Can";

const { RangePicker } = DatePicker;
const { Option, OptGroup } = Select;
const FormItem = Form.Item;

class Filter extends Component {
  onChangeTaskUsers = (values) => {
    this.props.onChangeTaskUsers(values);
  };

  onChangeUsers = (values) => {
    this.props.onChangeUsers(values);
  };

  onChangeSupervisorUsers = (values) => {
    this.props.onChangeSupervisorUsers(values);
  };

  render() {
    const {
      currentUser,
      visible,
      startCreatedDate,
      endCreatedDate,
      startDate,
      endDate,
      status,
      templates,
      taskUsers,
      users,
      supervisorUsers,
      fieldSets,
      onClose,
      onChangeCreatedPeriod,
      onChangeDueByPeriod,
      onChangeStatus,
      onChangeTemplates,
      onChangeFieldSets
    } = this.props;
    const { getFieldDecorator } = this.props.form;


    let fieldSetsSelect = (
      <Can
        role={currentUser.role}
        perform="orders:finance"
        yes={() => (
          <FormItem label={"Дополнительные поля"}>
            {getFieldDecorator("fieldSets", {
              initialValue: fieldSets ? fieldSets : [],
            })(
              <SelectMultiFetch
                showSearch
                placeholder="Дополнительные поля для выгрузки"
                onChange={onChangeFieldSets}
                selected={fieldSets ? fieldSets : []}
                url={"/field_sets/search/?ownerType=1"}
              />
            )}
          </FormItem>
        )}
        no={() => null}
      />
    );

    return (
      <Drawer
        width={540}
        visible={visible}
        title="Фильтры"
        placement={"right"}
        closable={false}
        onClose={onClose}
      >
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <FormItem label={"Дата создания"}>
              {getFieldDecorator("start", {
                onChange: onChangeCreatedPeriod,
                initialValue: [
                  startCreatedDate
                    ? moment(startCreatedDate, "DD.MM.YYYY")
                    : null,
                  endCreatedDate ? moment(endCreatedDate, "DD.MM.YYYY") : null,
                ],
              })(
                <RangePicker
                  showTime={false}
                  format={"DD.MM.YYYY"}
                  placeholder={["начало", "окончание"]}
                  style={{ width: "100%" }}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <FormItem label={"Выполнить до"}>
              {getFieldDecorator("finish", {
                onChange: onChangeDueByPeriod,
                initialValue: [
                  startDate ? moment(startDate, "DD.MM.YYYY") : null,
                  endDate ? moment(endDate, "DD.MM.YYYY") : null,
                ],
              })(
                <RangePicker
                  showTime={false}
                  format={"DD.MM.YYYY"}
                  placeholder={["начало", "окончание"]}
                  style={{ width: "100%" }}
                />
              )}
            </FormItem>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Статус"}>
              {getFieldDecorator("status", {
                onChange: onChangeStatus,
                initialValue: status ? status : [],
              })(
                <Select
                  mode={"multiple"}
                  style={{ width: "100%" }}
                  placeholder="Статус"
                  optionFilterProp="children"
                  filterOption={false}
                >
                  <Option value={"pending"}>новая</Option>
                  <Option value={"processing"}>в работе</Option>
                  <Option value={"finished"}>выполнено</Option>
                  <Option value={"canceled"}>отменена</Option>
                </Select>
              )}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Шаблоны"}>
              {getFieldDecorator("templates", {
                initialValue: templates ? templates : [],
              })(
                <SelectMultiFetch
                  showSearch
                  placeholder="Шаблоны"
                  onChange={onChangeTemplates}
                  selected={templates ? templates : []}
                  url={"/task_templates/search/?'"}
                />
              )}
            </FormItem>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Инициаторы"}>
              {getFieldDecorator("taskUsers", {
                initialValue: taskUsers ? taskUsers : [],
              })(
                <SelectMultiFetch
                  showSearch
                  placeholder="Инициаторы"
                  onChange={this.onChangeTaskUsers}
                  selected={taskUsers ? taskUsers : []}
                  url={"/users/search/?all='true'"}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Ответственные"}>
              {getFieldDecorator("users", {
                initialValue: users ? users : [],
              })(
                <SelectMultiFetch
                  showSearch
                  placeholder="Ответственные"
                  onChange={this.onChangeUsers}
                  selected={users ? users : []}
                  url={"/users/search/?all='true'"}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Наблюдатели"}>
              {getFieldDecorator("supervisorUsers", {
                initialValue: supervisorUsers ? supervisorUsers : [],
              })(
                <SelectMultiFetch
                  showSearch
                  placeholder="Наблюдатели"
                  onChange={this.onChangeSupervisorUsers}
                  selected={supervisorUsers ? supervisorUsers : []}
                  url={"/users/search/?all='true'"}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            {fieldSetsSelect}
          </Col>
        </Row>
      </Drawer>
    );
  }
}

Filter.propTypes = {
  entity: PropTypes.object,
};

export default Form.create()(Filter);
