// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, Row, Col, Form, DatePicker, InputNumber, Statistic } from "antd";
import Assignees from "../../../components/Assignees";
import Documents from "../../../components/Documents";
import moment from "moment";
import "moment/locale/ru";
import SelectFetch from "../../../components/SelectFetch";
import SelectMultiFetch from "../../../components/SelectMultiFetch";

const FormItem = Form.Item;

class Statuses extends Component {
  render() {
    const {
      isNew,
      getFieldDecorator,
      currentUser,
      taskType,
      due_from,
      due_by,
      planDays,
      start,
      finish,
      onChangeDueFrom,
      onChangeDueBy,
      onChangePlanDays,
      services,
      onChangeServices,
      // statuses
      taskId,
      status,
      statusUrl,
      statusPostUrl,
      onChangeStatus,
      // assignees
      isAssigneesLoading,
      organisationAssignees,
      customer,
      customerAssignees,
      company,
      companyAssignees,
      onCreateAssignee,
      onDeleteAssignee,
      // attachments
      isAttachmentLoading,
      attachments,
      onDeleteAttachment,
      onUploadAttachment,
    } = this.props;

    return (
      <Card style={{ borderColor: "#1890ff" }}>
        <Row gutter={10}>
          <Col span={12}>
            <FormItem label={"Плановая дата начала"}>
              {getFieldDecorator("due_from", {
                onOk: onChangeDueFrom,
                initialValue: due_from ? moment(due_from) : null,
                rules: [
                  {
                    required: true,
                    message: "Введите дату!",
                  },
                ],
              })(
                <DatePicker
                  format={"LLL"}
                  showTime
                  className="ant-btn-width-100"
                  onOk={onChangeDueFrom}
                />
              )}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label={"Плановая дата окончания"}>
              {getFieldDecorator("due_by", {
                onOk: onChangeDueBy,
                initialValue: due_by ? moment(due_by) : null,
                rules: [
                  {
                    required: true,
                    message: "Введите дату!",
                  },
                ],
              })(
                <DatePicker
                  format={"LLL"}
                  showTime
                  className="ant-btn-width-100"
                  onOk={onChangeDueBy}
                />
              )}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label={"Статус"}>
              {getFieldDecorator("status", {
                initialValue: status ? status : null,
              })(
                <SelectFetch
                  placeholder="Статус"
                  onChange={onChangeStatus}
                  selected={status ? status : null}
                  url={`/order_tasks/${taskId}/${statusUrl}`}
                />
              )}
            </FormItem>
          </Col>
          {taskType === "proposal_processing" ? (
            <Col span={12}>
              <FormItem label={"Дней на выполнение"}>
                {getFieldDecorator("planDays", {
                  onChange: onChangePlanDays,
                  initialValue: planDays,
                })(
                  <InputNumber min={0} max={1000} style={{ width: "100%" }} />
                )}
              </FormItem>
            </Col>
          ) : null}
          {taskType === "info_systems_altering" ? (
            <Col span={12}>
              <FormItem label={"Разделы"}>
                {getFieldDecorator("services", {
                  initialValue: services ?? [],
                })(
                  <SelectMultiFetch
                    showSearch
                    placeholder="Разделы"
                    onChange={onChangeServices}
                    selected={services ?? []}
                    url={`/services/search/?`}
                  />
                )}
              </FormItem>
            </Col>
          ) : null}
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Statistic
              title="Начало выполнения работ"
              value={start ? moment(start).format("lll") : "не начались"}
              valueStyle={{ color: "#1777d1" }}
            />
          </Col>
          <Col span={12}>
            <Statistic
              title="Окончание выполнения работ"
              value={finish ? moment(finish).format("lll") : "не закончены"}
              valueStyle={{ color: "#3f8600" }}
            />
          </Col>
        </Row>

        <Row gutter={10}>
          <Col span={24}>
            <Assignees
              isReadOnly={false}
              isAssigneesLoading={isAssigneesLoading}
              assigneeType="organisation"
              assignees={organisationAssignees}
              onCreateAssignee={onCreateAssignee}
              onDeleteAssignee={onDeleteAssignee}
            />
          </Col>
          {customer ? (
            <Col span={24}>
              <Assignees
                title={"От заказчика"}
                isReadOnly={false}
                isAssigneesLoading={isAssigneesLoading}
                assigneeType="customer"
                assignees={customerAssignees}
                onCreateAssignee={onCreateAssignee}
                onDeleteAssignee={onDeleteAssignee}
              />
            </Col>
          ) : null}
          {company ? (
            <Col span={24}>
              <Assignees
                title={"От подрядчика"}
                isReadOnly={false}
                isAssigneesLoading={isAssigneesLoading}
                assigneeType="company"
                assignees={companyAssignees}
                onCreateAssignee={onCreateAssignee}
                onDeleteAssignee={onDeleteAssignee}
              />
            </Col>
          ) : null}
        </Row>
        <Row gutter={10}>
          {isNew ? null : (
            <Documents
              currentUser={currentUser}
              isUploading={isAttachmentLoading}
              attachments={attachments}
              onUploadAttachment={onUploadAttachment}
              onDeleteAttachment={onDeleteAttachment}
            />
          )}
        </Row>
      </Card>
    );
  }
}
Statuses.propTypes = {
  customer: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  onAdressEdit: PropTypes.func,
  onChangePaymentType: PropTypes.func,
  onChangePaymentStatus: PropTypes.func,
  onChangeShippingStatus: PropTypes.func,
  onChangeShippingMethod: PropTypes.func,
};

export default Statuses;
