// @flow
import React, { Component, Fragment } from "react";
import {Row, Col, Button, Card, Tag, Checkbox, Divider, Select, Radio, Badge} from "antd";
import RBCalendar from "../../../components/RBCalendar";
import SelectMultiFetch from "../../../components/SelectMultiFetch";
import Info from "./Info";
import moment from "moment";
import "moment/locale/ru";

const Option = Select.Option;

function colorTaskEvent(event) {
  console.log('# EVENT')
  console.log(moment().diff())
  let eventStatus = event.status;
  let overdued = moment(event.end).isBefore(moment());
  if ((eventStatus === 'pending' || eventStatus === 'processing') && overdued) {
    return '#d40808';
  }

  if (eventStatus === 'pending') {
    return '#dbbe00';
  } else if (eventStatus === 'processing') {
    return '#e06c00';
  } else if (eventStatus === 'finished' || eventStatus === 'cancelled') {
    return '#119100';
  }

  return '#b700db';
}

class Calendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      taskTypes: [],
      taskStates: [],
      taskAssigneeType: []
    };
  }

  onSelectEvent = event => {
    this.setState({ selected: event });
    this.props.onSelectEvent(event.id, event.eventType);
  };

  onChangeUsers = values => {
    let userIds = [];
    values.map(value => {
      userIds.push(value.id);
    });
    this.setState({ users: values }, () => {
      this.props.onChangeUsers(userIds);
    });
  };

  onChangeTaskTypes = values => {
    this.setState({ taskTypes: values }, () => {
      this.props.onChangeTaskTypes(values);
    });
  };

  onChangeTaskStates = values => {
    this.setState({ taskStates: values }, () => {
      this.props.onChangeTaskStates(values);
    });
  };

  onChangeTaskAssigneeType = (e) => {
    this.props.onChangeTaskAssigneeType(e.target.value)
  };

  render() {
    const {
      users,
      taskTypes,
      taskStates,
      taskAssigneeType,
    } = this.state;
    const {
      currentUser,
      onCalendarNavigate,
      isCalendarLoading,
      events,
      onChangeEventType,
      isEventLoading,
      selectedEvent,
      fetchParams
    } = this.props;

    const data = [];
    const entityTasks = events.entity_tasks || [];
    entityTasks.map(event => {
      data.push({
        id: event.id,
        start: moment(event.start).toDate(),
        end: moment(event.end).toDate(),
        allDay: true,
        eventType: "entity_tasks",
        eventTypeTitle: `Регламент #${event.id}`,
        title: event.title,
        customer: event.customer,
        color: "#2db7f5"
      });
    });

    const orderTasks = events.order_tasks || [];
    orderTasks.map(event => {
      data.push({
        id: event.id,
        start: moment(event.start).toDate(),
        end: moment(event.end).toDate(),
        allDay: true,
        eventType: "order_tasks",
        eventTypeTitle: `Задача по заказу #${event.id}`,
        title: event.title,
        customer: event.customer,
        color: "#87d068"
      });
    });

    const tickets = events.tickets || [];
    tickets.map(event => {
      data.push({
        id: event.id,
        start: moment(event.start).toDate(),
        end: moment(event.end).toDate(),
        allDay: true,
        eventType: "tickets",
        eventTypeTitle: `Заявка #${event.id}`,
        title: event.title,
        customer: event.customer,
        color: "#1777d1"
      });
    });

    const tasks = events.tasks || [];
    tasks.map(event => {
      data.push({
        id: event.id,
        start: moment(event.start).toDate(),
        end: moment(event.end).toDate(),
        allDay: true,
        eventType: "tasks",
        eventTypeTitle: `Задача #${event.id}`,
        title: event.title,
        customer: event.customer,
        color: colorTaskEvent(event),
      });
    });

    let countOverdueTasks = events.count_overdue_tasks || 0;

    return (
      // loading={isCalendarLoading}
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={18} xl={18}>
          <Card style={{ height: "83vh" }}>
            <RBCalendar
              events={data}
              onNavigate={onCalendarNavigate}
              onSelectEvent={this.onSelectEvent}
            />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          <Card>
            <Divider>Фильтры</Divider>
            <Row>
              <Col span={24}>
                <Checkbox
                  value="tickets"
                  checked={fetchParams["tickets"]}
                  onChange={onChangeEventType}
                >
                  <Tag color="#1777d1">Заявки</Tag>
                </Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox
                  value="orderTasks"
                  checked={fetchParams["orderTasks"]}
                  onChange={onChangeEventType}
                >
                  <Tag color="#87d068">Задачи по заказам</Tag>
                </Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox
                  value="entityTasks"
                  checked={fetchParams["entityTasks"]}
                  onChange={onChangeEventType}
                >
                  <Tag color="#2db7f5">Регламент</Tag>
                </Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox
                  value="tasks"
                  checked={fetchParams["tasks"]}
                  onChange={onChangeEventType}
                >
                  <Tag color="#b700db">Задачи</Tag>
                </Checkbox>
              </Col>
            </Row>
            <Row style={{ paddingTop: 10 }}>
              <Col span={24}>
                <SelectMultiFetch
                  showSearch
                  placeholder="Пользователи"
                  onChange={this.onChangeUsers}
                  selected={users}
                  url={"/users/search/?all='true'"}
                />
              </Col>
            </Row>
            {fetchParams["orderTasks"] ? (
              <Row style={{ paddingTop: 10 }}>
                <Col span={24}>
                  <Select
                    mode={"multiple"}
                    style={{ width: "100%" }}
                    placeholder="Тип задачи"
                    optionFilterProp="children"
                    filterOption={false}
                    value={taskTypes ? taskTypes : []}
                    onChange={this.onChangeTaskTypes}
                  >
                    <Option value={1}>выезд на обследование</Option>
                    <Option value={2}>создание КП</Option>
                    <Option value={3}>согласование КП</Option>
                    <Option value={4}>закупка оборудования</Option>
                    <Option value={5}>выполнение работ</Option>
                    <Option value={6}>принятие работ</Option>
                  </Select>
                </Col>
              </Row>
            ) : null}
            <Row style={{ paddingTop: 10 }}>
              <Col span={24}>
                  <Select
                    mode={"multiple"}
                    style={{ width: "100%" }}
                    placeholder="Статус задачи"
                    optionFilterProp="children"
                    filterOption={false}
                    value={taskStates ? taskStates : []}
                    onChange={this.onChangeTaskStates}
                  >
                    <Option value={"pending"}>новая</Option>
                    <Option value={"processing"}>в работе</Option>
                  </Select>
              </Col>
            </Row>
            <Row style={{ paddingTop: 10 }}>
              <Col span={24}>
                <Radio.Group
                  defaultValue="current"
                  buttonStyle="solid"
                  onChange={this.onChangeTaskAssigneeType}
                >
                  <Radio.Button value="current">Мои</Radio.Button>
                  <Radio.Button value="observer">Наблюдаю</Radio.Button>
                  <Radio.Button value="own">Поручил</Radio.Button>
                  <Badge count={isEventLoading ? 0 : countOverdueTasks}>
                    <Radio.Button value="overdue">Просрочены</Radio.Button>
                  </Badge>
                </Radio.Group>
              </Col>
            </Row>
          </Card>
        </Col>
        {selectedEvent ? (
          <Info loading={isEventLoading} event={selectedEvent} />
        ) : null}
      </Row>
    );
  }
}

export default Calendar;
