// @flow
import React, { Component, Fragment } from "react";
import { Row, Col } from "antd";
import {
  Chart,
  Annotation,
  Axis,
  Tooltip,
  Interval,
  Interaction,
  Coordinate,
} from "bizcharts";

import accounting from "accounting";

class ProjectPie extends Component {
  render() {
    const { currentUser, fetchParams, data } = this.props;

    const total = Number(data.total);

    const totals = [
      {
        type: "С заменой",
        percent:
          data.replaced_progress > 0
            ? (Number(data.replaced_progress) / total) * 100
            : 0,
        amount: Number(data.replaced_progress),
      },
      {
        type: "Без замены",
        percent:
          data.progress > 0
            ? (Number(data.progress) / total) * 100
            : 0,
        amount: Number(data.progress),
      },
    ];

    // console.log(totals)

    const cols = {
      percent: {
        formatter: (val) => {
          val = Math.round(val) + "%";
          return val;
        },
      },
    };

    return (
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Chart data={totals} scale={cols} height={300} autoFit>
          <Coordinate type="theta" radius={0.8} innerRadius={0.75} />
          <Axis visible={false} />
          <Tooltip showTitle={false} />
          <Interval
            adjust="stack"
            position="percent"
            color="type"
            shape="sliceShape"
            label={[
              "amount",
              {
                content: (totals) => {
                  return `${totals.type}: ${accounting.formatNumber(
                    totals.amount,
                    0,
                    " "
                  )}`;
                },
              },
            ]}
          />
          <Annotation.Text
            position={["50%", "50%"]}
            content={`${accounting.formatNumber(total, 0, " ")}`}
            style={{
              lineHeight: "140px",
              fontSize: "25",
              fill: "#262626",
              textAlign: "center",
            }}
          />
          <Interaction type="element-single-selected" />
        </Chart>
      </Col>
    );
  }
}

export default ProjectPie;
