import api from "../api";

const Actions = {};

Actions.fetchOrganisation = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_ORGANISATION_REQUEST" });
    return api
      .fetch(`/organisation`)
      .then((response) => {
        dispatch({ type: "FETCH_ORGANISATION", response });
      })
      .catch((error) => {
        api.handleError(error, "Пост");
        dispatch({ type: "FETCH_ORGANISATION_FAILURE" });
      });
  };
};

Actions.onUpdate = () => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_ORGANISATION_REQUEST" });
    const organisation = getState().organisation.organisation;
    const ticket_priorities = organisation.ticket_priorities.map((item) => {
      return {
        id: item.id,
        name: item.name,
        sort: item.sort,
        response_time: item.response_time,
        use_open_hours: item.use_open_hours,
        _destroy: false,
      };
    });
    const data = {
      organisation: {
        name: organisation.name,
        assignee_id: organisation.assignee ? organisation.assignee.id : null,
        settings: organisation.settings,
        ticket_priorities_attributes: ticket_priorities,
      },
    };
    api
      .patch(`/organisation`, data)
      .then((response) => {
        dispatch({ type: "PATCH_ORGANISATION", response });
        api.openNotification("success", "Организация", "Успешно обновлена!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Организация", error);
          });
        }
        dispatch({ type: "PATCH_ORGANISATION_FAILURE", response });
      });
  };
};

// autogenerate entity_tasks
Actions.onAutogenerateEntityTasks = (startDate, endDate) => {
  return (dispatch, getState) => {
    dispatch({ type: "AUTOGENERATE_ENTITY_TASKS_REQUEST" });
    const data = {
      startDate: startDate,
      endDate: endDate,
    };
    api
      .fetch(`/entity_tasks/autogenerate`, data)
      .then((response) => {
        dispatch({ type: "AUTOGENERATE_ENTITY_TASKS_SUCCESS", response });
      })
      .catch((response) => {
        dispatch({ type: "AUTOGENERATE_ENTITY_TASKS_FAILURE", response });
      });
  };
};

Actions.onRollbackEntityTasks = (startDate, endDate) => {
  return (dispatch, getState) => {
    dispatch({ type: "ROLLBACK_ENTITY_TASKS_REQUEST" });
    const data = {
      startDate: startDate,
      endDate: endDate,
    };
    api
      .fetch(`/entity_tasks/rollback`, data)
      .then((response) => {
        dispatch({ type: "ROLLBACK_ENTITY_TASKS_SUCCESS", response });
      })
      .catch((response) => {
        dispatch({ type: "ROLLBACK_ENTITY_TASKS_FAILURE", response });
      });
  };
};

Actions.onChangeName = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORGANISATION_CHANGE_NAME",
      value: value,
    });
  };
};

Actions.onChangeAssignee = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORGANISATION_CHANGE_ASSIGNEE",
      value: value,
    });
  };
};

// settings
Actions.onChangeIsChiefVisibleTasks = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORGANISATION_CHANGE_IS_CHIEF_VISIBLE_TASKS",
      value: value,
    });
  };
};

// notification email
Actions.onChangeNotificationApprove = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORGANISATION_CHANGE_NOTIFICATION_APPROVE",
      value: value,
    });
  };
};

Actions.onChangeNotificationAccept = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORGANISATION_CHANGE_NOTIFICATION_ACCEPT",
      value: value,
    });
  };
};

Actions.onChangeNotificationRate = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORGANISATION_CHANGE_NOTIFICATION_RATE",
      value: value,
    });
  };
};

Actions.onChangeNotificationInbound = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORGANISATION_CHANGE_NOTIFICATION_INBOUND",
      value: value,
    });
  };
};

Actions.onChangeNotificationErrors = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORGANISATION_CHANGE_NOTIFICATION_ERRORS",
      value: value,
    });
  };
};

Actions.onChangeNotificationCopy = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORGANISATION_CHANGE_NOTIFICATION_COPY",
      value: value,
    });
  };
};

// departments
Actions.onCreateDepartment = (data) => {
  return (dispatch) => {
    dispatch({ type: "POST_DEPARTMENT_REQUEST" });
    return api
      .post(`/departments`, data)
      .then((response) => {
        dispatch({ type: "POST_DEPARTMENT", response });
        api.openNotification("success", "Подразделение", "Успешно создано!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Подразделение", error);
          });
        }
        dispatch({ type: "POST_DEPARTMENT_FAILURE", response });
      });
  };
};

// departments
Actions.fetchDepartments = () => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ORGANISATION_DEPARTMENTS_REQUEST" });
    return api
      .fetch(`/departments`)
      .then((response) => {
        dispatch({ type: "FETCH_ORGANISATION_DEPARTMENTS", response });
      })
      .catch((error) => {
        api.handleError(error, "Подразделение");
        dispatch({ type: "FETCH_ORGANISATION_DEPARTMENTS_FAILURE" });
      });
  };
};

Actions.onUpdateDepartment = (departmentId, data) => {
  return (dispatch) => {
    dispatch({ type: "PATCH_DEPARTMENT_REQUEST" });
    const params = { department: data };
    return api
      .patch(`/departments/${departmentId}`, params)
      .then((response) => {
        dispatch({ type: "PATCH_DEPARTMENT", response });
        api.openNotification("success", "Подразделение", "Успешно обновлено!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Подразделение", error);
          });
        }
        dispatch({ type: "PATCH_DEPARTMENT_FAILURE", response });
      });
  };
};

Actions.onDeleteDepartment = (id) => {
  return (dispatch) => {
    dispatch({ type: "DELETE_ORGANISATION_DEPARTMENT_REQUEST" });
    return api
      .delete(`/departments/${id}`)
      .then((response) => {
        api.openNotification("success", "Подразделение", "Успешно удалено!");
        dispatch({ type: "DELETE_ORGANISATION_DEPARTMENT", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Подразделение", error);
          });
        }
        dispatch({
          type: "DELETE_ORGANISATION_DEPARTMENT_FAILURE",
        });
      });
  };
};

// users
Actions.fetchUsers = (departmentId, finish) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ORGANISATION_USERS_REQUEST" });
    const params = { department_id: departmentId, finish: finish };
    return api
      .fetch(`/users`, params)
      .then((response) => {
        dispatch({ type: "FETCH_ORGANISATION_USERS", response });
      })
      .catch((error) => {
        api.handleError(error, "Подразделение");
        dispatch({ type: "FETCH_ORGANISATION_USERS_FAILURE" });
      });
  };
};

// tariff_plans
Actions.fetchTariffPlan = () => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ORGANISATION_TARIFF_PLAN_REQUEST" });
    return api
      .fetch(`/tariff_plan`)
      .then((response) => {
        dispatch({ type: "FETCH_ORGANISATION_TARIFF_PLAN", response });
      })
      .catch((error) => {
        api.handleError(error, "Тарифный план");
        dispatch({ type: "FETCH_ORGANISATION_TARIFF_PLAN_FAILURE" });
      });
  };
};

// statistic
Actions.fetchStatistic = () => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ORGANISATION_STATISTIC_REQUEST" });
    return api
      .fetch(`/organisation/statistic`)
      .then((response) => {
        dispatch({ type: "FETCH_ORGANISATION_STATISTIC", response });
      })
      .catch((error) => {
        api.handleError(error, "Статистика");
        dispatch({ type: "FETCH_ORGANISATION_STATISTIC_FAILURE" });
      });
  };
};

// providers
Actions.fetchProviders = () => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ORGANISATION_SERVICES_REQUEST" });
    return api
      .fetch(`/organisation/services`)
      .then((response) => {
        dispatch({ type: "FETCH_ORGANISATION_SERVICES", response });
      })
      .catch((error) => {
        api.handleError(error, "Сервис");
        dispatch({ type: "FETCH_ORGANISATION_SERVICES_FAILURE" });
      });
  };
};

// priorities
Actions.onChangeResponseTime = (id, value) => {
  return (dispatch) => {
    dispatch({
      type: "ORGANISATION_CHANGE_TICKET_PRIORITY_RESPONSE_TIME",
      id: id,
      value: value,
    });
  };
};

Actions.onChangeUseOpenHours = (id, value) => {
  return (dispatch) => {
    dispatch({
      type: "ORGANISATION_CHANGE_TICKET_PRIORITY_USE_OPEN_HOURS",
      id: id,
      value: value,
    });
  };
};

Actions.onSaveOrganisationProvider = (organisationProviderId, service) => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_ORGANISATION_SERVICE_REQUEST" });
    const data = {
      organisation_provider: {
        is_active: service.is_active,
        settings: service.settings,
      },
    };
    return api
      .patch(`/organisation_providers/${organisationProviderId}`, data)
      .then((response) => {
        dispatch({ type: "PATCH_ORGANISATION_SERVICE", response });
        api.openNotification("success", "Сервис", "Успешно обновлен!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Сервис", error);
          });
        }
        dispatch({ type: "PATCH_ORGANISATION_SERVICE_FAILURE", response });
      });
  };
};

Actions.onCreaterganisationProvider = (provider) => {
  return (dispatch) => {
    dispatch({ type: "POST_ORGANISATION_SERVICE_REQUEST" });
    const data = {
      organisation_provider: {
        provider_id: provider.id,
        settings: {},
      },
    };
    return api
      .post(`/organisation_providers`, data)
      .then((response) => {
        dispatch({ type: "POST_ORGANISATION_SERVICE", response });
        api.openNotification("success", "Сервис", "Успешно создано!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Сервис", error);
          });
        }
        dispatch({ type: "POST_ORGANISATION_SERVICE_FAILURE", response });
      });
  };
};

Actions.onDeleteOrganisationProvider = (id) => {
  return (dispatch) => {
    dispatch({ type: "DELETE_ORGANISATION_SERVICE_REQUEST" });
    return api
      .delete(`/organisation_providers/${id}`)
      .then((response) => {
        api.openNotification("success", "Сервис", "Успешно удален!");
        dispatch({ type: "DELETE_ORGANISATION_SERVICE_SUCCEED", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Сервис", error);
          });
        }
        dispatch({
          type: "DELETE_ORGANISATION_SERVICE_FAILURE",
        });
      });
  };
};

export default Actions;
