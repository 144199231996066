// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Form, Input, Tabs, Button } from "antd";
import SelectFetch from "../../../components/SelectFetch";

const TabPane = Tabs.TabPane;
const FormItem = Form.Item;

class Header extends Component {
  render() {
    const {
      isNew,
      name,
      title,
      customer,
      email,
      phone,
      password,
      password_confirmation,
      getFieldDecorator,
      onChangeName,
      onChangeCustomer,
      onChangeEmail,
      onChangeTitle,
      onChangePhone,
      onChangePassword,
      onChangePasswordConfirmation,
      onUpdatePassword
    } = this.props;

    return (
      <React.Fragment>
        <Row gutter={16}>
          <Tabs defaultActiveKey={"1"}>
            <TabPane tab="Основные данные" key="1">
              <Col span={12}>
                <FormItem label={"Имя"}>
                  {getFieldDecorator("name", {
                    onChange: onChangeName,
                    initialValue: name,
                    rules: [
                      {
                        required: true,
                        message: "Введите имя!"
                      }
                    ]
                  })(<Input />)}
                </FormItem>
              </Col>

              <Col md={{ span: 12 }} xs={{ span: 12 }}>
                <FormItem label={"Клиент"}>
                  {getFieldDecorator("customer", {
                    initialValue: customer ? customer : null,
                    rules: [
                      {
                        required: true,
                        message: "Пожалуйста, введите клиента!"
                      }
                    ]
                  })(
                    <SelectFetch
                      showSearch
                      placeholder="Клиент"
                      onChange={onChangeCustomer}
                      selected={customer ? customer : null}
                      url={"/customers/search/?"}
                    />
                  )}
                </FormItem>
              </Col>

              <Col span={12}>
                <FormItem label={"Email"}>
                  {getFieldDecorator("email", {
                    onChange: onChangeEmail,
                    initialValue: email,
                    rules: [
                      {
                        required: true,
                        message: "Введите email!"
                      }
                    ]
                  })(<Input />)}
                </FormItem>
              </Col>

              <Col span={12}>
                <FormItem label={"Должность"}>
                  {getFieldDecorator("title", {
                    onChange: onChangeTitle,
                    initialValue: title,
                    rules: [
                      {
                        required: true,
                        message: "Введите должность!"
                      }
                    ]
                  })(<Input />)}
                </FormItem>
              </Col>

              <Col span={12}>
                <FormItem label={"Телефон"}>
                  {getFieldDecorator("phone", {
                    onChange: onChangePhone,
                    initialValue: phone,
                    rules: [
                      {
                        required: true,
                        message: "Введите телефон!"
                      }
                    ]
                  })(<Input />)}
                </FormItem>
              </Col>

              {isNew ? (
                <React.Fragment>
                  <Col span={12}>
                    <FormItem label="Пароль">
                      {getFieldDecorator("password", {
                        onChange: onChangePassword,
                        initialValue: password,
                        rules: [
                          {
                            required: true,
                            message: "Введите пароль!"
                          }
                        ]
                      })(<Input />)}
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem label="Подтверждение пароля">
                      {getFieldDecorator("password_confirmation", {
                        onChange: onChangePasswordConfirmation,
                        initialValue: password_confirmation,
                        rules: [
                          {
                            required: true,
                            message: "Введите пароль еще раз!"
                          }
                        ]
                      })(<Input />)}
                    </FormItem>
                  </Col>
                </React.Fragment>
              ) : null}
            </TabPane>
            {!isNew ? (
              <TabPane tab="Смена пароля" key="2">
                <Row gutter={16}>
                  <Col span={12}>
                    <FormItem label="Пароль">
                      {getFieldDecorator("password", {
                        onChange: onChangePassword,
                        initialValue: password
                      })(<Input />)}
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem label="Подтверждение пароля">
                      {getFieldDecorator("password_confirmation", {
                        onChange: onChangePasswordConfirmation,
                        initialValue: password_confirmation
                      })(<Input />)}
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Button onClick={onUpdatePassword} type="primary">
                      Сохранить пароль
                    </Button>
                  </Col>
                </Row>
              </TabPane>
            ) : null}
          </Tabs>
        </Row>
      </React.Fragment>
    );
  }
}

Header.propTypes = {};

export default Header;
