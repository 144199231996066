// @flow
import React, { Component, lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Actions from "../../actions/organisation";
import LoadingSpin from "../../components/LoadingSpin";
import moment from "moment";
import "moment/locale/ru";

const Form = lazy(() => import("./Form"));
const renderLoader = () => <LoadingSpin />;

class Organisation extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.fetchOrganisation()).then(() => {
      this.props.dispatch(Actions.fetchTariffPlan());
      this.props.dispatch(Actions.fetchStatistic());
    });
  };

  onSave = () => {
    this.props.dispatch(Actions.onUpdate());
  };

  onClose = () => {
    this.props.history.push(`/`);
  };

  onChangeName = (e) => {
    this.props.dispatch(Actions.onChangeName(e.target.value));
  };

  onChangeAssignee = (value) => {
    this.props.dispatch(Actions.onChangeAssignee(value));
  };

  onAutogenerateEntityTasks = (startDate, endDate) => {
    this.props.dispatch(Actions.onAutogenerateEntityTasks(startDate, endDate));
  };

  onRollbackEntityTasks = (startDate, endDate) => {
    this.props.dispatch(Actions.onRollbackEntityTasks(startDate, endDate));
  };

  // department
  onSaveDepartment = (item) => {
    if (item.id) {
      this.props.dispatch(
        Actions.onUpdateDepartment(item.id, {
          name: item.name,
          city_id: item.city ? item.city.id : null,
          assignee_id: item.assignee ? item.assignee.id : null,
          ancestry: item.ancestry ? item.ancestry.id : null,
        })
      );
    } else {
      this.props.dispatch(
        Actions.onCreateDepartment({
          name: item.name,
          city_id: item.city ? item.city.id : null,
          assignee_id: item.assignee ? item.assignee.id : null,
          ancestry: item.ancestry ? item.ancestry.id : null,
        })
      );
    }
  };

  onDeleteDepartment = (ids) => {
    ids.map((id) => {
      return this.props.dispatch(Actions.onDeleteDepartment(id));
    });
    this.props.dispatch(Actions.fetchDepartments());
  };

  // users
  fetchUsers = (departmentId) => {
    this.props.dispatch(
      Actions.fetchUsers(departmentId, moment().format("MM.DD.YY"))
    );
  };

  // settings
  onChangeIsChiefVisibleTasks = (e) => {
    this.props.dispatch(Actions.onChangeIsChiefVisibleTasks(e.target.checked));
  };

  // notification_emails
  onChangeNotificationApprove = (e) => {
    this.props.dispatch(Actions.onChangeNotificationApprove(e.target.value));
  };

  onChangeNotificationAccept = (e) => {
    this.props.dispatch(Actions.onChangeNotificationAccept(e.target.value));
  };

  onChangeNotificationRate = (e) => {
    this.props.dispatch(Actions.onChangeNotificationRate(e.target.value));
  };

  onChangeNotificationInbound = (e) => {
    this.props.dispatch(Actions.onChangeNotificationInbound(e.target.value));
  };

  onChangeNotificationErrors = (e) => {
    this.props.dispatch(Actions.onChangeNotificationErrors(e.target.value));
  };

  onChangeNotificationCopy = (e) => {
    this.props.dispatch(Actions.onChangeNotificationCopy(e.target.value));
  };

  // providers
  fetchProviders = () => {
    this.props.dispatch(Actions.fetchProviders());
  };

  onSaveOrganisationProvider = (organisationProviderId, service) => {
    this.props
      .dispatch(
        Actions.onSaveOrganisationProvider(organisationProviderId, service)
      )
      .then(() => {
        this.props.dispatch(Actions.fetchProviders());
      });
  };

  onCreateOrganisationProvider = (provider) => {
    this.props
      .dispatch(Actions.onCreateOrganisationProvider(provider))
      .then(() => {
        this.props.dispatch(Actions.fetchProviders());
      });
  };

  onDeleteOrganisationProviders = (ids) => {
    ids.map((id) => {
      this.props.dispatch(Actions.onDeleteOrganisationProvider(id)).then(() => {
        if (!this.props.errors) {
          this.props.dispatch(Actions.fetchProviders());
        }
      });
    });
  };

  // priorities
  onChangeResponseTime = (id, value) => {
    this.props.dispatch(Actions.onChangeResponseTime(id, value));
  };

  onChangeUseOpenHours = (id, { value }) => {
    this.props.dispatch(Actions.onChangeUseOpenHours(id, value));
  };

  render() {
    const {
      isLoading,
      organisation,
      isLoadingDepartments,
      isLoadingUsers,
      users,
      // tariff_plans
      isLoadingTariffPlans,
      tariffPlan,
      // statistic
      isLoadingStatistic,
      statistic,
      // providers
      isLoadinOrganisationProviders,
      organisationProviders,
    } = this.props;
    return (
      <Suspense fallback={renderLoader()}>
        <Form
          isLoading={isLoading}
          organisation={organisation}
          onSave={this.onSave}
          onClose={this.onClose}
          onChangeName={this.onChangeName}
          onChangeAssignee={this.onChangeAssignee}
          onAutogenerateEntityTasks={this.onAutogenerateEntityTasks}
          onRollbackEntityTasks={this.onRollbackEntityTasks}
          // department
          isLoadingDepartments={isLoadingDepartments}
          onSaveDepartment={this.onSaveDepartment}
          onDeleteDepartment={this.onDeleteDepartment}
          // users
          isLoadingUsers={isLoadingUsers}
          users={users}
          fetchUsers={this.fetchUsers}
          // tariff_plans
          isLoadingTariffPlans={isLoadingTariffPlans}
          tariffPlan={tariffPlan}
          // statistic
          isLoadingStatistic={isLoadingStatistic}
          statistic={statistic}
          // settings
          onChangeIsChiefVisibleTasks={this.onChangeIsChiefVisibleTasks}
          // notification_emails
          onChangeNotificationApprove={this.onChangeNotificationApprove}
          onChangeNotificationAccept={this.onChangeNotificationAccept}
          onChangeNotificationRate={this.onChangeNotificationRate}
          onChangeNotificationInbound={this.onChangeNotificationInbound}
          onChangeNotificationErrors={this.onChangeNotificationErrors}
          onChangeNotificationCopy={this.onChangeNotificationCopy}
          // providers
          isLoadinOrganisationProviders={isLoadinOrganisationProviders}
          organisationProviders={organisationProviders}
          fetchProviders={this.fetchProviders}
          onSaveOrganisationProvider={this.onSaveOrganisationProvider}
          onCreateOrganisationProvider={this.onCreateOrganisationProvider}
          onDeleteOrganisationProviders={this.onDeleteOrganisationProviders}
          // priorities
          onChangeResponseTime={this.onChangeResponseTime}
          onChangeUseOpenHours={this.onChangeUseOpenHours}
        />
      </Suspense>
    );
  }
}

Organisation.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  organisation: PropTypes.object,
  isLoading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isLoading: state.organisation.isLoading,
  organisation: state.organisation.organisation,
  // tariff_plans
  isLoadingTariffPlans: state.organisation.isLoadingTariffPlans,
  tariffPlan: state.organisation.tariffPlan,
  // statistic
  isLoadingStatistic: state.organisation.isLoadingStatistic,
  statistic: state.organisation.statistic,
  // departments
  isLoadingDepartments: state.organisation.isLoadingDepartments,
  // users
  isLoadingUsers: state.organisation.isLoadingUsers,
  users: state.organisation.users,
  // providers
  isLoadinOrganisationProviders:
    state.organisation.isLoadinOrganisationProviders,
  organisationProviders: state.organisation.organisationProviders,
});

export default connect(mapStateToProps)(Organisation);
