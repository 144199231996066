import api from "../api";

const Actions = {};

Actions.fetchSuppliers = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_SUPPLIERS_REQUEST" });
    const params = getState().suppliers.fetchParams;
    api
      .fetch("/suppliers", params)
      .then((response) => {
        dispatch({ type: "FETCH_SUPPLIERS", response });
      })
      .catch((error) => {
        if (error.response.status == 500) {
          error.response.data.errors.map((error) => {
            return api.openNotification("error", "Подрядчик", error);
          });
        }
        dispatch({ type: "FETCH_SUPPLIERS_FAILURE", error });
      });
  };
};

Actions.fetchSupplier = (id = null) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_SUPPLIER_REQUEST" });
    api
      .fetch(`/suppliers/${id}`)
      .then((response) => {
        dispatch({ type: "FETCH_SUPPLIER", response });
      })
      .catch((error) => {
        api.handleError(error, "Подрядчик");
        dispatch({ type: "FETCH_SUPPLIER_FAILURE" });
      });
  };
};

Actions.onUpdate = (id, value) => {
  return (dispatch) => {
    dispatch({ type: "PATCH_SUPPLIER_REQUEST" });

    const data = {
      supplier: {
        is_active: value,
      },
    };

    return api
      .patch(`/suppliers/${id}`, data)
      .then((response) => {
        dispatch({ type: "PATCH_SUPPLIER", response });
        api.openNotification("success", "Подрядчик", "Успешно обновлен!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Подрядчик", error);
          });
        }
        dispatch({ type: "PATCH_SUPPLIER_FAILURE", response });
      });
  };
};

Actions.onCreate = (params) => {
  return (dispatch) => {
    dispatch({ type: "POST_SUPPLIER_REQUEST" });

    const data = {
      supplier: {
        is_active: true,
        company_id: params.company ? params.company.id : null,
      },
    };

    return api
      .post(`/suppliers`, data)
      .then((response) => {
        dispatch({ type: "POST_SUPPLIER", response });
        api.openNotification("success", "Подрядчик", "Успешно создан!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Подрядчик", error);
          });
        }
        dispatch({ type: "POST_SUPPLIER_FAILURE", response });
      });
  };
};

Actions.onNew = () => {
  return (dispatch) => {
    dispatch({
      type: "SUPPLIER_NEW",
    });
  };
};

Actions.onSearch = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_SUPPLIER_SEARCH",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangePage = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_SUPPLIER_CHANGE_PAGE",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeLimit = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_SUPPLIER_CHANGE_LIMIT",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onSortBy = (field, direction) => {
  return (dispatch) => {
    dispatch({
      type: "Suppliers_SORT_BY",
      field: field,
      direction: direction,
    });
    return Promise.resolve();
  };
};

Actions.onChangeActive = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SUPPLIER_CHANGE_ACTIVE",
      value: value,
    });
  };
};

export default Actions;
