// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Table } from "antd";
import "moment/locale/ru";
import accounting from "accounting";
import EmployeeDetails from "./EmployeeDetails";

class Employees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
    };
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  onDelete = () => {
    this.props.onDeletePayrollItem(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  render() {
    const {
      isLoadind,
      employeesItems,
      // employee_details
      isLoadindEmployeeDetails,
      employeeDetails,
      onEmployeeDetails,
    } = this.props;

    const columns = [
      {
        title: "Имя",
        dataIndex: "name",
        render: (name, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <a>
              <EmployeeDetails
                loading={isLoadindEmployeeDetails}
                progress={record.progress}
                name={name}
                data={employeeDetails}
                onChange={onEmployeeDetails.bind(this, record.id)}
              />
            </a>
          </div>
        ),
        key: "name",
        width: "40%",
        align: "left",
      },
      {
        title: "Базовое начисление",
        dataIndex: "salary",
        render: (salary) => accounting.formatNumber(salary, 0, " "),
        key: "salary",
        width: "15%",
        align: "right",
      },
      {
        title: "Бонусы",
        dataIndex: "wage",
        render: (wage) => accounting.formatNumber(wage, 0, " "),
        key: "wage",
        width: "15%",
        align: "right",
      },
      {
        title: "Штрафы",
        dataIndex: "penalty",
        render: (penalty) => accounting.formatNumber(penalty, 0, " "),
        key: "penalty",
        width: "15%",
        align: "right",
      },
      {
        title: "Итого",
        dataIndex: "total",
        render: (total, record) =>
          accounting.formatNumber(
            Number(record.salary) +
              Number(record.wage) -
              Number(record.penalty),
            0,
            " "
          ),
        key: "total",
        width: "15%",
        align: "right",
      },
    ];

    return (
      <Row gutter={{ md: 8, lg: 24, xl: 48 }} style={{ paddingBottom: 15 }}>
        <Col span={24}>
          <Table
            bordered
            size={"small"}
            loading={isLoadind}
            pagination={false}
            columns={columns}
            dataSource={employeesItems}
            rowKey="id"
          />
        </Col>
      </Row>
    );
  }
}
Employees.propTypes = {
  getFieldDecorator: PropTypes.func,
};

export default Employees;
