// @flow
import React, { Component } from "react";
import { Table, Button, PageHeader, Pagination, Row, Col, Input } from "antd";

import PropTypes from "prop-types";
import moment from "moment";
import "moment/locale/ru";
import ModalForm from "../Form";

const Search = Input.Search;

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: []
    };
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  onDelete = () => {
    this.props.onDelete(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  render() {
    const { selectedRowKeys } = this.state;
    const {
      loading,
      isModalVisible,
      entityTypes,
      meta,
      fetchParams,
      onSearch,
      onChangePage,
      fetchEntityType,
      onNew
    } = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    };

    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: "Наименование",
        dataIndex: "id",
        render: (id, record) => (
          <a onClick={fetchEntityType.bind(this, id)}>{record.name}</a>
        ),
        key: "id",
        width: "70%"
      },
      {
        title: "Cоздан",
        dataIndex: "created_at",
        render: created_at => moment(created_at).format("L"),
        key: "created_at",
        align: "center",
        width: "30%"
      }
    ];

    let mainButton = hasSelected ? (
      <Button key="1" type="danger" ghost onClick={this.onDelete}>
        Удалить
      </Button>
    ) : (
      <Button key="2" type="primary" ghost onClick={onNew}>
        Создать
      </Button>
    );

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={"Типы объектов"}
        extra={[
          <Search
            key="3"
            placeholder="поиск..."
            value={fetchParams.search}
            onChange={onSearch}
            style={{ width: 250 }}
          />,
          mainButton
        ]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Table
                bordered
                rowSelection={rowSelection}
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={entityTypes}
                // expandedRowRender={record => this.renderExpandedRow(record)}
                rowKey="id"
              />
            </Col>
            <Col
              span={24}
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
              <Pagination
                showSizeChanger
                pageSize={fetchParams.limit}
                current={meta.current_page}
                onChange={onChangePage}
                onShowSizeChange={this.onChangeFetchParamsLimit}
                total={meta.total_count}
              />
            </Col>
            <ModalForm visible={isModalVisible} />
          </Row>
        }
      />
    );
  }
}

List.propTypes = {
  payments: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object
};

export default List;
