// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table, Button, PageHeader, Pagination, Row, Col, Badge } from "antd";

import PropTypes from "prop-types";
import moment from "moment";
import "moment/locale/ru";


class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      isFilterVisible: false,
    };
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  onDelete = () => {
    this.props.onDelete(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  onChangeTable = (pagination, filters, sorter) => {
    let direction = "desc";
    if (sorter.order === "descend") {
      direction = "desc";
    } else if (sorter.order === "ascend") {
      direction = "asc";
    }
    this.props.onSortBy(sorter.field, direction);
  };
  
  render() {
    const { selectedRowKeys, isFilterVisible } = this.state;
    const {
      loading,
      fieldSets,
      meta,
      fetchParams,
      onSearch,
      onChangePage,
      onChangeLimit,
      onSortBy,
    } = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: "",
        dataIndex: "is_active",
        render: (is_active, record) => (
          <span>
            <Badge status={is_active ? "success" : "error"} className={"large"} />
          </span>
        ),
        key: "is_active",
        width: "5%",
        align: "center"
      },
      {
        title: "Наименование",
        dataIndex: "id",
        render: (id, record) => (
          <Link to={`/field_sets/${record.id}/edit`}>{record.presentation}</Link>
        ),
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "id",
        width: "40%",
      },
      {
        title: "Тип",
        dataIndex: "field_set_type_local",
        render: (field_set_type_local) => (field_set_type_local),
        key: "field_set_type_local",
        width: "25%",
        align: "center",
      },
      {
        title: "Cоздан",
        dataIndex: "created_at",
        render: (created_at) => moment(created_at).format("L"),
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "created_at",
        align: "center",
        width: "30%",
      },
    ];

    let mainButton = hasSelected ? (
      <Button key="1" type="danger" ghost onClick={this.onDelete}>
        Удалить
      </Button>
    ) : (
      <Link
        key="2"
        className={"ant-btn ant-btn-primary ant-btn-background-ghost"}
        to={{ pathname: `/field_sets/new` }}
      >
        Создать
      </Link>
    );

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Атрибуты`}
        extra={mainButton}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col
              span={24}
              style={{
                paddingTop: "15px",
                paddingBottom: "15px",
              }}
            >
              <Table
                bordered
                rowSelection={rowSelection}
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={fieldSets}
                onChange={this.onChangeTable}
                rowKey="id"
              />
              
            </Col>
            <Col
              span={24}
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
              <Pagination
                showSizeChanger
                pageSize={fetchParams.limit}
                current={meta.current_page}
                onChange={onChangePage}
                // onShowSizeChange={this.onChangeFetchParamsLimit}
                total={meta.total_count}
              />
            </Col>
          </Row>
        }
      />
    );
  }
}

List.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default List;
