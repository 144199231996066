import update from "immutability-helper";

const initialState = {
  services: [],
  service: {
    service_extcodes: [],
  },
  categories: [],
  category: { name: "", is_exist: false, children: [] },
  orders: [],
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1,
  },
  fetchParams: {
    search: null,
    category_id: null,
    customers: null,
    isAvailable: null,
    page: 1,
    limit: 15,
  },
  errors: false,
  isLoading: true,
  isServiceLoading: false,
  isCategoryLoading: true,
  isCategoriesLoading: false,
  isOrdersLoading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "FETCH_SERVICES_REQUEST":
      return {
        ...state,
        isServiceLoading: true,
      };
    case "FETCH_SERVICES":
      return {
        ...state,
        isServiceLoading: false,
        services: action.response.data,
        meta: action.response.meta,
      };
    case "FETCH_SERVICE_REQUEST":
      return {
        ...state,
        isServiceLoading: true,
      };
    case "FETCH_SERVICE":
      return {
        ...state,
        service: action.response.data,
        isServiceLoading: false,
      };
    case "PATCH_SERVICE_REQUEST":
      return {
        ...state,
        isServiceLoading: true,
      };
    case "PATCH_SERVICE":
      return {
        ...state,
        errors: false,
        service: action.response.data,
        isServiceLoading: false,
      };

    case "PATCH_SERVICE_FAILURE":
      return {
        ...state,
        errors: true,
        isServiceLoading: false,
      };

    // categories
    case "FETCH_CATEGORY_REQUEST":
      return {
        ...state,
        isCategoryLoading: true,
      };
    case "FETCH_CATEGORY":
      return {
        ...state,
        category: action.response.data,
        isCategoryLoading: false,
      };

    case "POST_CATEGORY_REQUEST":
      return {
        ...state,
        errors: false,
        isCategoriesLoading: true,
      };
    case "POST_CATEGORY_SUCCEED":
      return {
        ...state,
        errors: false,
        categories: action.response.data,
        isCategoriesLoading: false,
      };

    case "POST_CATEGORY_FAILURE":
      return {
        ...state,
        errors: true,
        isCategoriesLoading: false,
      };

    case "PATCH_CATEGORY_REQUEST":
      return {
        ...state,
        isCategoriesLoading: true,
      };
    case "PATCH_CATEGORY_SUCCEED":
      return {
        ...state,
        errors: false,
        categories: action.response.data,
        isCategoriesLoading: false,
      };

    case "PATCH_CATEGORY_FAILURE":
      return {
        ...state,
        errors: true,
        isCategoriesLoading: false,
      };

    case "DELETE_CATEGORY_REQUEST":
      return {
        ...state,
        errors: false,
        isCategoriesLoading: true,
      };
    case "DELETE_CATEGORY_SUCCEED":
      return {
        ...state,
        errors: false,
        categories: action.response.data,
        isCategoriesLoading: false,
      };
    case "DELETE_CATEGORY_FAILURE":
      return {
        ...state,
        errors: true,
        isCategoriesLoading: false,
      };

    case "FETCH_CATEGORIES_REQUEST":
      return {
        ...state,
        isCategoriesLoading: true,
      };
    case "FETCH_CATEGORIES":
      return {
        ...state,
        isCategoriesLoading: false,
        categories: action.response.data,
      };

    // change category
    case "SERVICE_CHANGE_CATEGORY_REQUEST":
      return {
        ...state,
        isServiceLoading: true,
      };
    case "SERVICE_CHANGE_CATEGORY":
      return {
        ...state,
        isServiceLoading: false,
      };

    case "SERVICE_CHANGE_CATEGORY_FAILURE":
      return {
        ...state,
        errors: true,
        isServiceLoading: false,
      };

    case "SERVICES_SELECT_CATEGORY":
      return update(state, {
        fetchParams: { category_id: { $set: action.value }, page: { $set: 1 } },
      });
    // end service_categories

    case "POST_SERVICE_REQUEST":
      return {
        ...state,
        errors: false,
        isServiceLoading: true,
      };
    case "POST_SERVICE":
      return {
        ...state,
        errors: false,
        service: action.response.data,
        isServiceLoading: false,
      };
    case "POST_SERVICE_FAILURE":
      return {
        ...state,
        errors: true,
        isServiceLoading: false,
      };

    case "DELETE_SERVICE_REQUEST":
      return {
        ...state,
        errors: false,
        isServiceLoading: true,
      };
    case "DELETE_SERVICE":
      return {
        ...state,
        errors: false,
        services: action.response.data,
        isServiceLoading: false,
      };
    case "DELETE_SERVICE_FAILURE":
      return {
        ...state,
        errors: true,
        isServiceLoading: false,
      };
    case "REQUEST_SERVICE_SEARCH":
      return update(state, {
        fetchParams: { search: { $set: action.value }, page: { $set: 1 } },
      });

    case "REQUEST_SERVICES_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } },
      });

    case "REQUEST_SERVICES_CHANGE_LIMIT":
      return update(state, {
        fetchParams: { limit: { $set: action.value }, page: { $set: 1 } },
      });

    case "SERVICE_NEW":
      return {
        ...state,
        errors: false,
        service: {
          service_category: null,
          name: null,
          service_extcodes: [],
        },
        isServiceLoading: false,
      };

    case "CATEGORY_NEW":
      return {
        ...state,
        errors: false,
        category: {
          id: null,
          name: null,
          ancestry: action.ancestry,
          customer: null,
          category_type: null,
          position: 0,
        },
        isCategoriesLoading: false,
      };

    // general
    case "SERVICE_CHANGE_NAME":
      return update(state, {
        service: { name: { $set: action.value } },
      });

    case "SERVICE_CHANGE_DESCRIPTION":
      return update(state, {
        service: { description: { $set: action.value } },
      });

    case "SERVICE_CHANGE_EXT_CODE":
      return update(state, {
        service: { ext_code: { $set: action.value } },
      });

    case "SERVICE_CHANGE_IS_AVAILABLE":
      return update(state, {
        service: { is_available: { $set: action.value } },
      });

    // category
    case "CATEGORY_CHANGE_NAME":
      return update(state, {
        category: { name: { $set: action.value } },
      });

    case "CATEGORY_CHANGE_POSITION":
      return update(state, {
        category: { position: { $set: action.value } },
      });

    case "CATEGORY_CHANGE_CUSTOMER":
      return update(state, {
        category: { customer: { $set: action.value } },
      });

    case "CATEGORY_CHANGE_TYPE":
      return update(state, {
        category: { category_type: { $set: action.value } },
      });

    case "CATEGORY_CHANGE_EXT_CODE":
      return update(state, {
        category: { ext_code: { $set: action.value } },
      });

    // filters
    case "CATEGORIES_FILTER_CUSTOMERS":
      return update(state, {
        fetchParams: {
          customers: { $set: action.values.length > 0 ? action.values : null },
          page: { $set: 1 },
        },
      });

    case "CATEGORIES_FILTER_IS_AVAILABLE":
      return update(state, {
        fetchParams: {
          isAvailable: { $set: action.value },
          page: { $set: 1 },
        },
      });

    case "SERVICE_EXT_CODE_APPEND":
      return update(state, {
        service: {
          service_extcodes: {
            $push: [action.value]
          }
        },
      });

    case "SERVICE_EXT_CODE_REMOVE":
      return update(state, {
        service: {
          service_extcodes: {
            $splice: [[action.idx, 1]]
          },
        },
      });

    case "SERVICE_EXT_CODE_DELETE":
      return update(state, {
        service: {
          service_extcodes: {
            [action.idx]: { _destroy: { $set: true } },
          },
        },
      });

    default:
      return state;
  }
}
