// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Input } from "antd";
import Actions from "../../../actions/services";
import List from "./List";


class Servises extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount = () => {
    console.log(this.props.match.params.categoryID)
    // this.props.dispatch(Actions.fetchServices());
  };

  componentDidUpdate(prevProps) {
    // console.log(this.props.match.url)
    if (
      this.props.fetchParams.category_id !== prevProps.fetchParams.category_id
    ) {
      this.props.dispatch(Actions.fetchServices());
    }
    // if (
    //   this.props.match.url !== prevProps.match.url
    // ) {
    //   this.props.dispatch(Actions.fetchServices());
    // }
  }

  onDelete = id => {
    this.props.dispatch(Actions.onDelete(id)).then(() => {
      if (!this.props.errors) {
        this.props.dispatch(Actions.fetchServices());
      }
    });
  };

  onSearch = e => {
    this.props
      .dispatch(Actions.onSearch(e.target.value))
      .then(() => this.props.dispatch(Actions.fetchServices()));
  };

  onChangePage = value => {
    this.props
      .dispatch(Actions.onChangePage(value))
      .then(() => this.props.dispatch(Actions.fetchServices()));
  };

  onChangeLimit = (current, size) => {
    this.props
      .dispatch(Actions.onChangeLimit(size))
      .then(() => this.props.dispatch(Actions.fetchServices()));
  };

  onChangeCategory = value => {
    this.props
      .dispatch(Actions.onChangeCategory(value))
      .then(() => this.props.dispatch(Actions.fetchServices()));
  };

  // filters
  onChangeFilterIsAvailable = (value) => {
    this.props
      .dispatch(Actions.onChangeFilterIsAvailable(value))
      .then(() => this.props.dispatch(Actions.fetchServices()))
  };

  onChangeFilterCustomers = (values) => {
    this.props
      .dispatch(Actions.onChangeFilterCustomers(values))
      .then(() => this.props.dispatch(Actions.fetchServices()))
  };

  render() {
    const { isLoading, services, meta, fetchParams, categories } = this.props;

    return (
      <List
        params={this.props.match.params}
        isLoading={isLoading}
        services={services}
        meta={meta}
        fetchParams={fetchParams}
        categories={categories}
        onSearch={this.onSearch}
        onDelete={this.onDelete}
        onChangeCategory={this.onChangeCategory}
        onChangePage={this.onChangePage}
        onChangeLimit={this.onChangeLimit}
        // filters
        onChangeFilterIsAvailable={this.onChangeFilterIsAvailable}
        onChangeFilterCustomers={this.onChangeFilterCustomers}
      />
    );
  }
}

Servises.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  services: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
  errors: PropTypes.bool
};

const mapStateToProps = state => ({
  isLoading: state.services.isServiceLoading,
  services: state.services.services,
  meta: state.services.meta,
  fetchParams: state.services.fetchParams,
  categories: state.services.categories,
});

export default connect(mapStateToProps)(Servises);
