// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, Icon, Statistic } from "antd";

class Statistics extends Component {
  render() {
    const { getFieldDecorator, customer } = this.props;

    return (
      <React.Fragment>
        <Row gutter={16}>
          <Col span={12}>
            <Card>
              <Statistic
                title="Регламентные работы (план)"
                value={34}
                valueStyle={{ color: "#3f8600" }}
                prefix={<Icon type="clock-circle" />}
                suffix="шт."
              />
            </Card>
          </Col>
          <Col span={12}>
            <Card>
              <Statistic
                title="Регламентные работы (выполнено)"
                value={9}
                valueStyle={{ color: "#1777d1" }}
                prefix={<Icon type="check" />}
                suffix="шт."
              />
            </Card>
          </Col>
        </Row>
        <Row gutter={16} style={{ paddingTop: "10px" }}>
          <Col span={12}>
            <Card>
              <Statistic
                title="Заявки (в работе)"
                value={11}
                valueStyle={{ color: "#3f8600" }}
                prefix={<Icon type="clock-circle" />}
                suffix="шт."
              />
            </Card>
          </Col>
          <Col span={12}>
            <Card>
              <Statistic
                title="Заявки (выполнено)"
                value={93}
                valueStyle={{ color: "#1777d1" }}
                prefix={<Icon type="check" />}
                suffix="шт."
              />
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
Statistics.propTypes = {
  customer: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  onAdressEdit: PropTypes.func,
  onChangePaymentType: PropTypes.func,
  onChangePaymentStatus: PropTypes.func,
  onChangeShippingStatus: PropTypes.func,
  onChangeShippingMethod: PropTypes.func
};

export default Statistics;
