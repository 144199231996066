import update from "immutability-helper";
import moment from "moment";
import "moment/locale/ru";

const initialState = {
  isLoading: true,
  fetchParams: {
    search: null,
  },
  errors: false,
  knowledgeBase: null,
  articles: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "FETCH_KNOWLEDGE_BASE_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_KNOWLEDGE_BASE":
      return {
        ...state,
        isLoading: false,
        knowledgeBase: action.response.data,
      };
    case "POST_KNOWLEDGE_BASE_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };
    case "POST_KNOWLEDGE_BASE":
      return {
        ...state,
        isLoading: false,
        errors: false,
        knowledgeBase: action.response.data,
      };
    case "POST_KNOWLEDGE_BASE_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };
    case "SEARCH_ARTICLES_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "SEARCH_ARTICLES":
      return {
        ...state,
        isLoading: false,
        articles: action.response.data
      };
    case "SEARCH_ARTICLES_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true
      };
    case "CHANGE_ARTICLES_SEARCH_QUERY":
      return update(state, {
        fetchParams: {
          search: {$set: action.value}
        },
      });
    default:
      return state;
  }
}
