import update from "immutability-helper";

const initialState = {
  currentUser: {},
  errors: false,
  payrolls: [],
  penalties: [],
  isLoadingPayrolls: true,
  isLoading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "FETCH_CURRENT_USER_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_CURRENT_USER":
      return {
        ...state,
        currentUser: action.response.data,
        isLoading: false,
      };
    case "PATCH_CURRENT_USER_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "PATCH_CURRENT_USER":
      return {
        ...state,
        currentUser: action.response.data,
        isLoading: false,
      };

    case "PATCH_CURRENT_USER_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };
    case "PATCH_PASSWORD_CURRENT_USER_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "PATCH_PASSWORD_CURRENT_USER":
      return {
        ...state,
        isLoading: false,
      };
    case "PATCH_PASSWORD_CURRENT_USER_FAILURE":
      return {
        ...state,
        isLoading: false,
      };
    case "CURRENT_USER_CHANGE_NAME":
      return update(state, {
        currentUser: { first_name: { $set: action.value } },
      });

    case "CURRENT_USER_CHANGE_MIDDLE_NAME":
      return update(state, {
        currentUser: { middle_name: { $set: action.value } },
      });

    case "CURRENT_USER_CHANGE_LAST_NAME":
      return update(state, {
        currentUser: { last_name: { $set: action.value } },
      });
    case "CURRENT_USER_CHANGE_EMAIL":
      return update(state, {
        currentUser: { email: { $set: action.value } },
      });

    case "CURRENT_USER_CHANGE_PASSWORD":
      return update(state, {
        currentUser: { password: { $set: action.value } },
      });

    case "CURRENT_USER_CHANGE_PASSWORD_CONFIRMATION":
      return update(state, {
        currentUser: { password_confirmation: { $set: action.value } },
      });

    // notifications
    case "CURRENT_USER_CHANGE_NOTIFY_COMMENT_SETTINGS_TICKETS":
      return update(state, {
        currentUser: {
          user_profile: {
            notify_comment_settings: {
              tickets: {
                $set: !state.currentUser.user_profile.notify_comment_settings
                  .tickets,
              },
            },
          },
        },
      });

    case "CURRENT_USER_CHANGE_NOTIFY_COMMENT_SETTINGS_ENTITY_TASKS":
      return update(state, {
        currentUser: {
          user_profile: {
            notify_comment_settings: {
              entity_tasks: {
                $set: !state.currentUser.user_profile.notify_comment_settings
                  .entity_tasks,
              },
            },
          },
        },
      });

    case "CURRENT_USER_CHANGE_NOTIFY_COMMENT_SETTINGS_ORDERS":
      return update(state, {
        currentUser: {
          user_profile: {
            notify_comment_settings: {
              orders: {
                $set: !state.currentUser.user_profile.notify_comment_settings
                  .orders,
              },
            },
          },
        },
      });

    case "CURRENT_USER_CHANGE_NOTIFY_COMMENT_SETTINGS_ORDER_TASKS":
      return update(state, {
        currentUser: {
          user_profile: {
            notify_comment_settings: {
              order_tasks: {
                $set: !state.currentUser.user_profile.notify_comment_settings
                  .order_tasks,
              },
            },
          },
        },
      });

      case "CURRENT_USER_CHANGE_NOTIFY_COMMENT_SETTINGS_PROJECTS":
      return update(state, {
        currentUser: {
          user_profile: {
            notify_comment_settings: {
              projects: {
                $set: !state.currentUser.user_profile.notify_comment_settings
                  .projects,
              },
            },
          },
        },
      });

    // payrolls
    case "FETCH_CURRENT_USER_PAYROLLS_REQUEST":
      return {
        ...state,
        isLoadingPayrolls: true,
      };
    case "FETCH_CURRENT_USER_PAYROLLS":
      return {
        ...state,
        payrolls: action.response.data.payrolls,
        penalties: action.response.data.penalties,
        isLoadingPayrolls: false,
      };
    case "FETCH_CURRENT_USER_PAYROLLS_FAILURE":
      return {
        ...state,
        isLoadingPayrolls: true,
      };

    default:
      return state;
  }
}
