// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, Form, Select, Checkbox, Upload, Icon } from "antd";
import "moment/locale/ru";

const FormItem = Form.Item;
const Option = Select.Option;

class Statuses extends Component {
  onUpload = ({ onSuccess, onError, file }) => {
    this.props.onUploadAvatar(file);
  };

  render() {
    const {
      isNew,
      avatar,
      isUploadingAvatar,
      is_active,
      role,
      getFieldDecorator,
      onChangeActive,
      onChangeRole
    } = this.props;

    const uploadButton = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexFlow: "column",
          minHeight: "140px",
          minWidth: "140px"
        }}
      >
        <Icon type={isUploadingAvatar ? "loading" : "plus"} />
        <div>Загрузить картинку</div>
      </div>
    );

    return (
      <Card style={{ borderColor: "#1890ff" }}>
        <Row gutter={10}>
          <Col span={24}>
            <FormItem>
              {getFieldDecorator("is_active", {
                initialValue: is_active,
                valuePropName: "checked",
                onChange: onChangeActive
              })(<Checkbox>активен</Checkbox>)}
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem label={"Роль"}>
              {getFieldDecorator("role", {
                initialValue: role,
                onChange: onChangeRole,
                rules: [
                  {
                    required: true,
                    message: "Введите роль!"
                  }
                ]
              })(
                <Select
                  style={{ width: "100%" }}
                  placeholder="Роль"
                  optionFilterProp="children"
                  filterOption={false}
                >
                  <Option value={"manager"}>менеджер</Option>
                  <Option value={"administrator"}>администратор</Option>
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>
        {!isNew ? (
          <Row gutter={10}>
            <Col span={24}>
              <Upload
                name="url"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                customRequest={this.onUpload}
              >
                {avatar && avatar.standart ? (
                  <img height={"140"} src={avatar.standart} alt="avatar" />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Col>
          </Row>
        ) : null}
      </Card>
    );
  }
}
Statuses.propTypes = {
  getFieldDecorator: PropTypes.func
};

export default Statuses;
