import api from "../api";

const Actions = {};

Actions.fetchUsers = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_USERS_REQUEST" });
    const params = getState().users.fetchParams;
    api
      .fetch("/users", params)
      .then(response => {
        dispatch({ type: "FETCH_USERS", response });
      })
      .catch(error => {
        if (error.response.status == 500) {
          error.response.data.errors.map(error => {
            return api.openNotification("error", "Пользователь", error);
          });
        }
        dispatch({ type: "FETCH_USERS_FAILURE", error });
      });
  };
};

Actions.fetchUser = (id = null) => {
  return dispatch => {
    dispatch({ type: "FETCH_USER_REQUEST" });
    api
      .fetch(`/users/${id}`)
      .then(response => {
        dispatch({ type: "FETCH_USER", response });
      })
      .catch((error) => {
        api.handleError(error, "Пользователь");
        dispatch({ type: "FETCH_USER_FAILURE" });
      });
  };
};

Actions.onNew = () => {
  return dispatch => {
    dispatch({
      type: "USER_NEW"
    });
  };
};

Actions.onUpdate = id => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_USER_REQUEST" });
    const user = getState().users.user;
    const data = {
      user: {
        is_active: user.is_active,
        start: user.start,
        finish: user.finish,
        role: user.role,
        title: user.title,
        email: user.email,
        phone: user.phone,
        name: user.name,
        department_id: user.department ? user.department.id : null,
        user_profile_attributes: {
          id: user.user_profile.id,
          notify_comment_settings: user.user_profile.notify_comment_settings,
          settings: user.user_profile.settings,
        }
      }
    };
    api
      .patch(`/users/${id}`, data)
      .then(response => {
        dispatch({ type: "PATCH_USER", response });
        api.openNotification("success", "Пользователь", "Успешно обновлен!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Пользователь", error);
          });
        }
        dispatch({ type: "PATCH_USER_FAILURE", response });
      });
  };
};

Actions.onUpdatePassword = () => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_PASSWORD_USER_REQUEST" });
    const user = getState().users.user;
    const data = {
      user: {
        password: user.password,
        password_confirmation: user.password_confirmation
      }
    };
    api
      .patch(`/users/${user.id}/update_password`, data)
      .then(response => {
        dispatch({ type: "PATCH_PASSWORD_USER", response });
        api.openNotification("success", "Пароль", "Успешно обновлен!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Пароль", error);
          });
        }
        dispatch({ type: "PATCH_PASSWORD_USER_FAILURE", response });
      });
  };
};

Actions.onCreate = () => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_USER_REQUEST" });
    const user = getState().users.user;
    const data = {
      user: {
        is_active: user.is_active,
        start: user.start,
        finish: user.finish,
        role: user.role,
        title: user.title,
        email: user.email,
        phone: user.phone,
        name: user.name,
        department_id: user.department ? user.department.id : null,
        // password: user.password,
        // password_confirmation: user.password_confirmation
      }
    };
    return api
      .post(`/users`, data)
      .then(response => {
        dispatch({ type: "POST_USER", response });
        api.openNotification(
          "success",
          "Пользователь системы",
          "Успешно создан!"
        );
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Пользователь системы", error);
          });
        }
        dispatch({ type: "POST_USER_FAILURE", response });
      });
  };
};

Actions.onDelete = id => {
  return dispatch => {
    dispatch({ type: "DELETE_USER_REQUEST" });
    return api
      .delete(`/users/${id}`)
      .then(response => {
        api.openNotification("success", "Пользователь", "Успешно удален!");
        dispatch({ type: "DELETE_USER", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Пользователь", error);
          });
        }
        dispatch({
          type: "DELETE_USER_FAILURE"
        });
      });
  };
};

Actions.onUploadAvatar = (id, file) => {
  return dispatch => {
    dispatch({
      type: "USER_AVATAR_UPLOAD"
    });

    const formData = new FormData();
    formData.append("file", file);
    return api
      .patchFormData(`/users/${id}/avatar`, formData)
      .then(response => {
        api.openNotification(
          "success",
          "Пользователь",
          "Аватар успешно загружен!"
        );
        dispatch({ type: "USER_AVATAR_UPLOAD_SUCCEED", response });
      })
      .catch(response => {
        dispatch({
          type: "USER_AVATAR_UPLOAD_FAILED"
        });
        dispatch({
          type: "ADD_NOTIFICATION",
          message: { label: response.error, type: "error" }
        });
      });
  };
};

Actions.onSearch = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_USER_SEARCH",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangePage = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_USER_CHANGE_PAGE",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangeLimit = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_USER_CHANGE_LIMIT",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterActive = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_USERS_FILTER_ACTIVE",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onSortBy = (field, direction) => {
  return dispatch => {
    dispatch({
      type: "USERS_SORT_BY",
      field: field,
      direction: direction
    });
    return Promise.resolve();
  };
};

Actions.onChangeActive = value => {
  return dispatch => {
    dispatch({
      type: "USER_CHANGE_ACTIVE",
      value: value
    });
  };
};

Actions.onChangeStart = value => {
  return dispatch => {
    dispatch({
      type: "USER_CHANGE_START",
      value: value
    });
  };
};

Actions.onChangeFinish = value => {
  return dispatch => {
    dispatch({
      type: "USER_CHANGE_FINISH",
      value: value
    });
  };
};

Actions.onChangeRole = value => {
  return dispatch => {
    dispatch({
      type: "USER_CHANGE_ROLE",
      value: value
    });
  };
};

Actions.onChangeName = value => {
  return dispatch => {
    dispatch({
      type: "USER_CHANGE_NAME",
      value: value
    });
  };
};

Actions.onChangeDepartment = value => {
  return dispatch => {
    dispatch({
      type: "USER_CHANGE_DEPARTMENT",
      value: value
    });
  };
};

Actions.onChangeEmail = value => {
  return dispatch => {
    dispatch({
      type: "USER_CHANGE_EMAIL",
      value: value
    });
  };
};

Actions.onChangeTitle = value => {
  return dispatch => {
    dispatch({
      type: "USER_CHANGE_TITLE",
      value: value
    });
  };
};

Actions.onChangePhone = value => {
  return dispatch => {
    dispatch({
      type: "USER_CHANGE_PHONE",
      value: value
    });
  };
};

Actions.onChangePassword = value => {
  return dispatch => {
    dispatch({
      type: "USER_CHANGE_PASSWORD",
      value: value
    });
  };
};

Actions.onChangePasswordConfirmation = value => {
  return dispatch => {
    dispatch({
      type: "USER_CHANGE_PASSWORD_CONFIRMATION",
      value: value
    });
  };
};

Actions.onChangeTickets = (value) => {
  return (dispatch) => {
    dispatch({
      type: "USER_CHANGE_SETTINGS_TICKETS",
      value: value,
    });
  };
};

export default Actions;
