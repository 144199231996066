// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Input, Col, Row, Select } from "antd";
import Options from "./Options";

const FormItem = Form.Item;
const Option = Select.Option;

class General extends Component {
  constructor(props) {
    super(props);
    this.state = { content: "" };
    this.onEditorChange = this.onEditorChange.bind(this);
  }

  onEditorChange(content, editor) {
    this.props.onChangeBody(content);
    // this.setState({ content });
  }

  render() {
    const {
      getFieldDecorator,
      fieldSet,
      onChangeName,
      onChangePresentation,
      onChangeOwnerType,
      onChangeFieldSetType,
      onSaveOption,
      onDeleteOptions,
    } = this.props;

    const options = fieldSet.options.filter(function (item) {
      return !item._destroy;
    }).sort(function(a, b) {
      return a.position - b.position;
    });

    return (
      <Row gutter={16}>
        <Col span={12}>
          <FormItem label={"Имя атрибута (латиницей)"}>
            {getFieldDecorator("name", {
              onChange: onChangeName,
              initialValue: fieldSet.name,
              rules: [
                {
                  required: true,
                  message: "Пожалуйста, введите наименование!",
                },
              ],
            })(<Input />)}
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem label={"Представление"}>
            {getFieldDecorator("presentation", {
              onChange: onChangePresentation,
              initialValue: fieldSet.presentation,
              rules: [
                {
                  required: true,
                  message: "Пожалуйста, введите представление!",
                },
              ],
            })(<Input />)}
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem label={"Владелец"}>
            {getFieldDecorator("ownerType", {
              initialValue: fieldSet.owner_type,
              onChange: onChangeOwnerType,
              rules: [
                {
                  required: true,
                  message: "Введите владельца!",
                },
              ],
            })(
              <Select
                placeholder="Владелец"
                optionFilterProp="children"
                filterOption={false}
              >
                <Option value={0}>все</Option>
                <Option value={1}>задачи</Option>
                <Option value={2}>заявки</Option>
              </Select>
            )}
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem label={"Тип атрибута"}>
            {getFieldDecorator("fieldSetType", {
              initialValue: fieldSet.field_set_type,
              onChange: onChangeFieldSetType,
              rules: [
                {
                  required: true,
                  message: "Введите тип!",
                },
              ],
            })(
              <Select
                placeholder="Тип атрибута"
                optionFilterProp="children"
                filterOption={false}
              >
                <Option value={"number"}>число</Option>
                <Option value={"float"}>число с плавающей запятой</Option>
                <Option value={"string"}>строка</Option>
                <Option value={"date"}>дата</Option>
                <Option value={"boolean"}>флажок</Option>
                <Option value={"select"}>список значений</Option>
              </Select>
            )}
          </FormItem>
        </Col>

        <Col span={24}>
          {fieldSet.field_set_type == "select" ? (
            <Options
              options={options}
              onSaveOption={onSaveOption}
              onDeleteOptions={onDeleteOptions}
            />
          ) : null}
        </Col>
      </Row>
    );
  }
}

General.propTypes = {
  fieldSet: PropTypes.object,
  onChangeName: PropTypes.func,
  onChangePresentation: PropTypes.func,
  onChangeOwnerType: PropTypes.func,
  onChangeFieldSetType: PropTypes.func,
};

export default General;
