import React from "react";
import { Link } from "react-router-dom";
import { Table, Empty, Typography } from "antd";
import accounting from "accounting";
import moment from "moment";
import "moment/locale/ru";

import AssigneesCell from "../../../components/AssigneesCell";

const { Paragraph } = Typography;

const Task = ({ isLoading, tasks, ...rest }) => {
  const columns = [
    {
      title: "Выполнить до",
      dataIndex: "due_by",
      render: (due_by) => (
        <span style={{ fontSize: 12 }}>{moment(due_by).format("LLL")}</span>
      ),
      key: "due_by",
      align: "center",
      width: "25%",
    },
    {
      title: "Тема / Описание",
      dataIndex: "subject",
      render: (subject, record) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span>
            <Link to={`/tasks/${record.id}/edit`}>{subject}</Link>
          </span>
          <span style={{ fontSize: 12 }}>
            <Paragraph ellipsis={{ rows: 3, expandable: true }}>
              {record.description}
            </Paragraph>
          </span>
        </div>
      ),
      key: "subject",
      align: "center",
      width: "50%",
    },
    {
      title: "Ответственный",
      dataIndex: "assignees",
      render: (assignees, record) => <AssigneesCell assignees={assignees} />,
      align: "center",
      width: "25%",
    },
  ];

  tasks.map((order, i) => {
    order.key = i
  })

  return tasks.length > 0 ? (
    <Table
      loading={isLoading}
      style={{background: "white"}}
      columns={columns}
      dataSource={tasks}
      size="small"
      pagination={false}
      rowKey="key"
    />
  ) : <Empty />;
};

export default Task;
