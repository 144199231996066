import update from "immutability-helper";

const initialState = {
  suppliers: [],
  supplier: {},
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1,
  },
  fetchParams: {
    is_active: true,
    search: null,
    sort: null,
    direction: null,
    page: 1,
    limit: 15,
  },
  errors: false,
  isLoading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "FETCH_SUPPLIERS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_SUPPLIERS":
      return {
        ...state,
        suppliers: action.response.data,
        meta: action.response.meta,
        isLoading: false,
      };
    case "FETCH_SUPPLIERS_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "FETCH_SUPPLIER_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_SUPPLIER":
      return {
        ...state,
        supplier: action.response.data,
        isLoading: false,
      };

    case "FETCH_SUPPLIER_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "POST_SUPPLIER_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "POST_SUPPLIER":
      return {
        ...state,
        errors: false,
        supplier: action.response.data,
        isLoading: false,
      };

    case "POST_SUPPLIER_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "PATCH_SUPPLIER_REQUEST":
      return {
        ...state,
        isLoading: false,
        errors: false,
      };

    case "PATCH_SUPPLIER":
      return {
        ...state,
        isLoading: false,
        errors: false,
        supplier: action.response.data,
      };

    case "PATCH_SUPPLIER_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "REQUEST_SUPPLIER_SEARCH":
      return update(state, {
        fetchParams: { search: { $set: action.value }, page: { $set: 1 } },
      });

    case "REQUEST_SUPPLIER_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } },
      });

    case "REQUEST_SUPPLIER_CHANGE_LIMIT":
      return update(state, {
        fetchParams: { limit: { $set: action.value }, page: { $set: 1 } },
      });

    case "SUPPLIERS_SORT_BY":
      return update(state, {
        fetchParams: {
          sort: { $set: action.field },
          direction: { $set: action.direction },
        },
      });

    case "SUPPLIER_CHANGE_ACTIVE":
      return update(state, {
        supplier: { is_active: { $set: action.value } },
      });

    default:
      return state;
  }
}
