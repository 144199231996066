import api from "../api";
import { saveAs } from "file-saver";

const Actions = {};

Actions.fetchSLA = (params) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_REPORT_REQUEST" });
    api
      .fetch("/reports/sla", params)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_REPORT", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_REPORT_FAILURE", response });
      });
  };
};

Actions.fetchSLAE = (params) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_REPORT_SLAE_REQUEST" });
    api
      .fetch("/reports/slae", params)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_REPORT_SLAE", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_REPORT_SLAE_FAILURE", response });
      });
  };
};

Actions.fetchOE = (params) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_REPORT_OE_REQUEST" });
    api
      .fetch("/reports/oe", params)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_REPORT_OE", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_REPORT_OE_FAILURE", response });
      });
  };
};

Actions.fetchPlan = (params) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_REPORT_PLAN_REQUEST" });
    api
      .fetch("/reports/plan", params)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_REPORT_PLAN", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_REPORT_PLAN_FAILURE", response });
      });
  };
};

Actions.fetchDaily = (params) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_REPORT_DAILY_REQUEST" });
    api
      .fetch("/reports/daily", params)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_REPORT_DAILY", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_REPORT_DAILY_FAILURE", response });
      });
  };
};

Actions.fetchDailyDetails = (userId, type, startDate, endDate) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_DAILY_DETAILS_REQUEST" });
    const params = {
      user_id: userId,
      type: type,
      startDate: startDate,
      endDate: endDate,
    };
    api
      .fetch(`/reports/dayly_details`, params)
      .then((response) => {
        dispatch({ type: "FETCH_DAILY_DETAILS", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Детализация", error);
          });
        }
        dispatch({ type: "FETCH_DAILY_DETAILS_FAILURE", response });
      });
  };
};

Actions.fetchTasks = (params) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_REPORT_TASKS_REQUEST" });
    api
      .fetch("/reports/tasks", params)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_REPORT_TASKS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_REPORT_TASKS_FAILURE", response });
      });
  };
};

Actions.fetchTasksDetails = (userId, status, startDate, endDate) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_TASKS_DETAILS_REQUEST" });
    const params = {
      user_id: userId,
      status: status,
      startDate: startDate,
      endDate: endDate,
    };
    api
      .fetch(`/reports/tasks_details`, params)
      .then((response) => {
        dispatch({ type: "FETCH_TASKS_DETAILS", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Задачи", error);
          });
        }
        dispatch({ type: "FETCH_TASKS_DETAILS_FAILURE", response });
      });
  };
};

Actions.fetchOrganisation = () => {
  return (dispatch) => {
    dispatch({ type: "FETCH_REPORT_ORGANISATION_REQUEST" });
    api
      .fetch("/reports/employees")
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_REPORT_ORGANISATION", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_REPORT_ORGANISATION_FAILURE", response });
      });
  };
};

Actions.fetchOrganisationDetails = (departmentId) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ORGANISATION_DETAILS_REQUEST" });
    const params = {
      department: departmentId,
    };
    api
      .fetch(`/reports/employee_details`, params)
      .then((response) => {
        dispatch({ type: "FETCH_ORGANISATION_DETAILS", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Структура", error);
          });
        }
        dispatch({ type: "FETCH_ORGANISATION_DETAILS_FAILURE", response });
      });
  };
};

// map
Actions.fetchEntities = (regionId = null, cityId = null, entityType = null) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ENTITIES_MAP_REQUEST" });
    const params = {
      region: regionId,
      city: cityId,
      entityType: entityType,
    };
    return api
      .fetch(`/reports/entities`, params)
      .then((response) => {
        dispatch({ type: "FETCH_ENTITIES_MAP", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Карта", error);
          });
        }
        dispatch({ type: "FETCH_ENTITIES_MAP_FAILURE", response });
      });
  };
};

Actions.fetchEmployeeRates = (fetchParams) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_EMPLOYEE_RATES_REQUEST" });
    const params = {
      departments: fetchParams.departments,
      startDate: fetchParams.startDate,
      endDate: fetchParams.endDate
    };
    api
      .fetch(`/reports/employee_rates`, params)
      .then((response) => {
        dispatch({ type: "FETCH_EMPLOYEE_RATES", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification(
              "error",
              "Рейтинг пользователей",
              error
            );
          });
        }
        dispatch({ type: "FETCH_EMPLOYEE_RATES_FAILURE", response });
      });
  };
};

Actions.fetchEmployeeRateDetails = (userId, startDate, endDate) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_EMPLOYEE_RATE_DETAILS_REQUEST" });
    const params = {
      user_id: userId,
      startDate: startDate,
      endDate: endDate
    };
    api
      .fetch(`/reports/employee_rate_details`, params)
      .then((response) => {
        dispatch({ type: "FETCH_EMPLOYEE_RATE_DETAILS", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification(
              "error",
              "Рейтинг пользователей",
              error
            );
          });
        }
        dispatch({ type: "FETCH_EMPLOYEE_RATE_DETAILS_FAILURE", response });
      });
  };
};

Actions.fetchPurchases = (params) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_PURCHASES_REQUEST" });
    api
      .fetch("/reports/purchases", params)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_PURCHASES", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_PURCHASES_FAILURE", response });
      });
  };
};

Actions.downloadReport = (params, url = "", filename = "") => {
  api
    .fetchBlob(`/reports/${url}`, params)
    .then((response) => {
      //console.log(response)
      saveAs(response, `${filename}`);
    })
    .catch((response) => {
      console.log(response.error);
    });
};

export default Actions;
