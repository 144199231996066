// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Input, Col, Row, DatePicker } from "antd";
import SelectFetch from "../../../components/SelectFetch";
import SelectMultiFetch from "../../../components/SelectMultiFetch";
import EntityOpeningHours from "../../../components/EntityOpeningHours";
import moment from "moment";
import "moment/locale/ru";
import canCheck from "../../../rules/canCheck";

const FormItem = Form.Item;
const { TextArea } = Input;

class General extends Component {
  constructor(props, context) {
    super(props, context);
  }
  render() {
    const {
      currentUser,
      isNew,
      ticket,
      getFieldDecorator,
      onChangeCustomer,
      onChangeCompany,
      onChangeEntity,
      onChangeEntityService,
      onChangeSubject,
      onChangeDescription,
      onChangeDueBy,
      onChangeCategory,
      onChangeServices,
    } = this.props;

    let canEditDueBy = canCheck(currentUser, "tickets:due_by", {
      userId: currentUser.user_id,
      ownerId: ticket.user ? ticket.user.id : null,
    });

    return (
      <Col span={24}>
        <Row gutter={16}>
          {ticket.is_organisation ? null : (
            <Col md={{ span: 12 }} xs={{ span: 12 }}>
              <FormItem label={"Клиент"}>
                {getFieldDecorator("customer", {
                  initialValue: ticket.customer ? ticket.customer : null,
                  rules: [
                    {
                      required: true,
                      message: "Пожалуйста, введите клиента!",
                    },
                  ],
                })(
                  <SelectFetch
                    disabled={!isNew}
                    showSearch
                    placeholder="Клиент"
                    onChange={onChangeCustomer}
                    selected={ticket.customer ? ticket.customer : null}
                    url={"/customers/search/?"}
                  />
                )}
              </FormItem>
            </Col>
          )}
          {ticket.is_supplier ? (
            <Col md={{ span: 12 }} xs={{ span: 12 }}>
              <FormItem label={"Подрядчик"}>
                {getFieldDecorator("company", {
                  initialValue: ticket.company ? ticket.company : null,
                  rules: [
                    {
                      required: true,
                      message: "Пожалуйста, введите клиента!",
                    },
                  ],
                })(
                  <SelectFetch
                    disabled={false}
                    showSearch
                    placeholder="Подрядчик"
                    onChange={onChangeCompany}
                    selected={ticket.company ? ticket.company : null}
                    url={"/companies/search/?"}
                  />
                )}
              </FormItem>
            </Col>
          ) : null}
          <Col span={12}>
            <FormItem label={"Плановые дата и время"}>
              {getFieldDecorator("due_by", {
                onChange: onChangeDueBy,
                initialValue: ticket.due_by ? moment(ticket.due_by) : null,
              })(
                <DatePicker
                  disabled={!canEditDueBy}
                  format={"LLL"}
                  placeholder="Будет расчитано автоматически"
                  showTime
                  className="ant-btn-width-100"
                />
              )}
            </FormItem>
          </Col>
          <Col md={{ span: 12 }} xs={{ span: 12 }}>
            <FormItem
              label={
                ticket.entity ? (
                  <EntityOpeningHours
                    title={"Объект"}
                    entityId={ticket.entity.id}
                  />
                ) : (
                  "Объект"
                )
              }
            >
              {getFieldDecorator("entity", {
                initialValue: ticket.entity ? ticket.entity : null,
                rules: [
                  {
                    required: true,
                    message: "Пожалуйста, введите объект!",
                  },
                ],
              })(
                <SelectFetch
                  disabled={ticket.is_organisation ? false : ticket.customer ? false : true}
                  showSearch
                  placeholder="Объект"
                  onChange={onChangeEntity}
                  selected={ticket.entity ? ticket.entity : null}
                  url={`/entities/search/?${
                    ticket.customer ? `customer_id=${ticket.customer.id}` : null
                  }${
                    ticket.is_organisation
                      ? `&&is_organisation=${true}`
                      : `&&is_organisation=${false}`
                  }`}
                />
              )}
            </FormItem>
          </Col>
          <Col md={{ span: 12 }} xs={{ span: 12 }}>
            <FormItem label={"Раздел"}>
              {getFieldDecorator("entity_service", {
                initialValue: ticket.entity_service
                  ? ticket.entity_service
                  : null,
                rules: [
                  {
                    required: true,
                    message: "Пожалуйста, введите раздел!",
                  },
                ],
              })(
                <SelectFetch
                  disabled={ticket.entity ? false : true}
                  showSearch
                  showSubtitle
                  placeholder="Раздел"
                  onChange={onChangeEntityService}
                  selected={
                    ticket.entity_service ? ticket.entity_service : null
                  }
                  url={ticket.entity ? `/entities/${ticket.entity.id}/entity_services`: null}
                />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col md={{ span: 24 }} xs={{ span: 24 }}>
            <p>{ticket.address}</p>
          </Col>
        </Row>
        {/* <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Инциденты"}>
              {getFieldDecorator("services", {
                // initialValue: ticket.services ? ticket.services : [],
                rules: [
                  {
                    required: true,
                    message: "Пожалуйста, введите инциденты!",
                  },
                ],
              })(
                <SelectMultiFetch
                  showSearch
                  showSubtitle={true}
                  placeholder="Инциденты"
                  onChange={onChangeServices}
                  selected={ticket.services ? ticket.services : []}
                  url={`/services/search/?category_id=${
                    ticket.category ? ticket.category.id : null
                  }`}
                />
              )}
            </FormItem>
          </Col>
        </Row> */}
        <Row gutter={16}>
          <Col span={24}>
            <FormItem label={"Тема"}>
              {getFieldDecorator("subject", {
                onChange: onChangeSubject,
                initialValue: ticket.subject,
                rules: [
                  {
                    required: true,
                    message: "Пожалуйста, введите тему!",
                  },
                ],
              })(<TextArea autoSize={{ minRows: 1, maxRows: 6 }} />)}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <FormItem label={"Описание"}>
              {getFieldDecorator("Описание", {
                onChange: onChangeDescription,
                initialValue: ticket.description,
              })(<TextArea autoSize={{ minRows: 2, maxRows: 6 }} />)}
            </FormItem>
          </Col>
        </Row>
      </Col>
    );
  }
}

General.propTypes = {
  ticket: PropTypes.object,
  onChangeCustomer: PropTypes.func,
  onChangeEntity: PropTypes.func,
  onChangeEntityService: PropTypes.func,
  onChangeStatus: PropTypes.func,
  onChangepPiority: PropTypes.func,
  onChangeSubject: PropTypes.func,
  onChangeDescription: PropTypes.func,
};

export default General;
