// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, Form, Checkbox } from "antd";

import Assignees from "../../../components/Assignees";
import canCheck from "../../../rules/canCheck";
import Can from "../../../rules/Can";

const FormItem = Form.Item;

class Statuses extends Component {
  render() {
    const {
      getFieldDecorator,
      isNew,
      currentUser,
      onChangeIsOrganisation,
      isOrganisation,
      customer,
      organisationAssignees,
      customerAssignees,
      supervisorAssignees,
      isAssigneesLoading,
      onDeleteAssignee,
      onCreateAssignee,
    } = this.props;

    return (
      <Card style={{ borderColor: "#1890ff" }}>
        <Row gutter={10}>
          <Col span={12}>
            <FormItem>
              {getFieldDecorator("isOrganisation", {
                onChange: onChangeIsOrganisation,
                valuePropName: "checked",
                initialValue: isOrganisation,
              })(<Checkbox>Собственный объект</Checkbox>)}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={24}>
            <Assignees
              title={"Наблюдатели"}
              isReadOnly={canCheck(currentUser, "entities:edit") ? false : true}
              isAssigneesLoading={isAssigneesLoading}
              assigneeType="supervisor"
              assignees={supervisorAssignees}
              onCreateAssignee={onCreateAssignee}
              onDeleteAssignee={onDeleteAssignee}
            />
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={24}>
            <Assignees
              isReadOnly={canCheck(currentUser, "entities:edit") ? false : true}
              isAssigneesLoading={isAssigneesLoading}
              assigneeType="organisation"
              assignees={organisationAssignees}
              onCreateAssignee={onCreateAssignee}
              onDeleteAssignee={onDeleteAssignee}
            />
          </Col>
        </Row>
        {isOrganisation ? null : (
          <Row gutter={10}>
            <Col span={24}>
              <Assignees
                title={"От заказчика"}
                isReadOnly={
                  canCheck(currentUser, "entities:edit") ? false : true
                }
                isAssigneesLoading={isAssigneesLoading}
                customer={customer}
                assigneeType="customer"
                assignees={customerAssignees}
                onCreateAssignee={onCreateAssignee}
                onDeleteAssignee={onDeleteAssignee}
              />
            </Col>
          </Row>
        )}
      </Card>
    );
  }
}
Statuses.propTypes = {
  entity: PropTypes.object,
  getFieldDecorator: PropTypes.func,
};

export default Statuses;
