// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table, Button, PageHeader, Badge } from "antd";
import { Pagination } from "antd";
import { Row, Col, Input } from "antd";

import PropTypes from "prop-types";

import moment from "moment";
import "moment/locale/ru";

import AssigneesCell from "../../../components/AssigneesCell";
import Filter from "./Filter";

const Search = Input.Search;

function statuses(status) {
  switch (status) {
    case "pending":
      return "error";
    case "processing":
      return "processing";
    case "closed":
      return "success";
    default:
      return "default";
  }
}

class SupportMessagesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      isFilterVisible: false,
    };
  }

  onFilterToggle = () => {
    this.setState({ isFilterVisible: !this.state.isFilterVisible });
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  onDelete = () => {
    this.props.onDelete(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  // filters
  onChangeFilterStatus = (value) => {
    this.setState({ filterStatusVisible: false }, () => {
      this.props.onChangeFilterStatus(value);
    });
  };

  onResetFilterStatus = () => {
    this.setState({ filterStatusVisible: false }, () => {
      this.props.onChangeFilterStatus(null);
    });
  };

  onChangeFilterAssignee = (item) => {
    this.setState({ filterAssigneeVisible: false }, () => {
      this.props.onChangeFilterAssignee(item.id);
    });
  };

  onResetFilterAssignee = () => {
    this.setState({ filterAssigneeVisible: false }, () => {
      this.props.onChangeFilterAssignee(null);
    });
  };

  onChangeTable = (pagination, filters, sorter) => {
    let direction = "desc";
    if (sorter.order === "descend") {
      direction = "desc";
    } else if (sorter.order === "ascend") {
      direction = "asc";
    }
    this.props.onSortBy(sorter.field, direction);
  };

  render() {
    const { selectedRowKeys, isFilterVisible } = this.state;
    const {
      loading,
      currentUser,
      supportMessages,
      meta,
      fetchParams,
      onSearch,
      onChangePage,
      onChangeLimit,
      onSortBy,
      // filters
      onClearFilters,
      onChangeFilterStatuses,
      onChangeFilterTypes,
      onChangeFilterUsers,
      onChangeFilterMembers,
    } = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: "#",
        dataIndex: "id",
        render: (id, record) => (
          <Link to={`/support_messages/${record.id}/edit`}>{id}</Link>
        ),
        width: "5%",
        align: "center",
      },
      {
        title: "Статус",
        dataIndex: "status",
        render: (status, record) => (
          <Badge status={statuses(record.status)} text={record.status_locale} />
        ),
        key: "status",
        align: "center",
        width: "10%",
      },
      {
        title: "Тип",
        dataIndex: "support_message_type",
        render: (support_message_type) => support_message_type ? support_message_type.name : null,
        key: "support_message_type",
        width: "10%",
        align: "center",
      },
      {
        title: "Дата",
        dataIndex: "created_at",
        render: (created_at) => moment(created_at).format("L"),
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "created_at",
        align: "center",
        width: "10%",
      },
      {
        title: "Клиент",
        dataIndex: "customer",
        render: (customer, record) =>
          customer ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span>
                <Link to={`/customers/${customer.id}/edit`}>
                  {customer.name}
                </Link>
              </span>
            </div>
          ) : null,
        align: "center",
        width: "15%",
      },
      {
        title: "Тема",
        dataIndex: "subject",
        render: (subject, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{subject}</span>
          </div>
        ),
        align: "left",
        width: "20%",
      },
      {
        title: "Отправитель",
        dataIndex: "user",
        render: (user, record) => (
          <span style={{ opacity: "0.7" }}>
            {user ? user.name : record.member.name}
          </span>
        ),
        align: "center",
        width: "15%",
      },
      {
        title: "Ответственный",
        dataIndex: "assignees",
        render: (assignees, record) => <AssigneesCell assignees={assignees} />,
        align: "center",
        width: "15%",
      },
    ];

    let mainButton = hasSelected ? (
      <Button key="1" type="danger" ghost onClick={this.onDelete}>
        Удалить
      </Button>
    ) : null;

    let filterButton = (
      <Button
        type={
          fetchParams.task_owner || fetchParams.task_type ? "primary" : "ghost"
        }
        key="3"
        icon="filter"
        onClick={this.onFilterToggle}
      />
    );

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Сообщения в службу поддержки`}
        extra={[
          <Search
            allowClear
            key="99"
            placeholder="поиск..."
            value={fetchParams.search}
            onChange={onSearch}
            style={{ width: 250 }}
          />,
          mainButton,
          filterButton,
        ]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Table
                bordered
                rowSelection={rowSelection}
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={supportMessages}
                onChange={this.onChangeTable}
                rowKey="id"
              />
            </Col>
            <Col
              span={24}
              style={{
                paddingTop: "15px",
                paddingBottom: "15px",
                textAlign: "right",
              }}
            >
              <Pagination
                showSizeChanger
                defaultPageSize={fetchParams.limit}
                defaultCurrent={meta.current_page}
                onChange={onChangePage}
                onShowSizeChange={onChangeLimit}
                showTotal={(total) => `Всего: ${meta.total_count}`}
                total={meta.total_count}
              />
            </Col>
          </Row>
        }
      >
        <Filter
          visible={isFilterVisible}
          statuses={fetchParams.statuses}
          users={fetchParams.users}
          members={fetchParams.members}
          supportMessageTypes={fetchParams.support_message_types}
          // filters
          onClose={this.onFilterToggle}
          onClearFilters={onClearFilters}
          onChangeStatuses={onChangeFilterStatuses}
          onChangeTypes={onChangeFilterTypes}
          onChangeUsers={onChangeFilterUsers}
          onChangeMembers={onChangeFilterMembers}
        />
      </PageHeader>
    );
  }
}

SupportMessagesList.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default SupportMessagesList;
