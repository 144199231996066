// @flow
import React, {Component} from "react";
import {Card, Col, Row} from "antd";
import TaskTabs from "./TaskTabs";
import StatisticCards from "./StatisticCards";
import TaskPie from "./TaskPie";
import TaskLine from "./TaskLine";
import "moment/locale/ru";

class Tasks extends Component {
  render() {
    const {
      currentUser,
      fetchParams,
      isStatisticLoading,
      tasksStatistic,
      taskSeries,
      // tasks
      isTasksLoading,
      tasks,
      countOverdueTasks,
      onChangeTasksAssigneeType,
      fetchTasks,
      fetchTasksParams,
      onChangeTaskFilterCreatedPeriod,
      onChangeTaskFilterDueByPeriod,
      onChangeTaskFilterUsers,
    } = this.props;

    return (
      <React.Fragment>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={14} xl={14}>
            <Row gutter={16}>
              <Card style={{minHeight: 460}}>
                <TaskTabs
                  tasks={tasks}
                  fetchParams={fetchTasksParams}
                  fetchTasks={fetchTasks}
                  isLoading={isTasksLoading}
                  countOverdueTasks={countOverdueTasks}
                  onChangeTasksAssigneeType={onChangeTasksAssigneeType}
                  onChangeTaskFilterCreatedPeriod={onChangeTaskFilterCreatedPeriod}
                  onChangeTaskFilterDueByPeriod={onChangeTaskFilterDueByPeriod}
                  onChangeTaskFilterUsers={onChangeTaskFilterUsers}
                />
              </Card>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={24} lg={10} xl={10}>
            <StatisticCards data={tasksStatistic}/>
            <Card loading={isStatisticLoading}>
              <TaskPie data={tasksStatistic}/>
            </Card>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Card>
              <TaskLine isLoading={isStatisticLoading} data={taskSeries}/>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default Tasks;
