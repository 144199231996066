// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table, Input, Avatar, Button, Tooltip } from "antd";
import update from "immutability-helper";
import { Row, Col } from "antd";
import Can from "../../../rules/Can";
import UserInfo from "../../../components/UserInfo";

import EntityServiceModal from "./EntityServiceModal";

import moment from "moment";
import "moment/locale/ru";

function periodicities(periodicity) {
  switch (periodicity) {
    case 0:
      return "нет";
    case 1:
      return "месяц";
    case 3:
      return "квартал";
    case 6:
      return "полугодие";
    default:
      return "нет";
  }
}

class Items extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      isVisible: false,
      entityService: {},
    };
  }

  onOpenEntityServiceModal = (item) => {
    this.setState({ entityService: item }, () => {
      this.setState({ isVisible: true });
    });
  };

  onAddEntityService = () => {
    this.setState(
      {
        entityService: {
          id: Date.now(),
          service: null,
          periodicity: 1,
          duration: 0,
          start: moment(),
          is_supplier: false,
          company: null,
          users: [],
          employees: [],
          is_exist: false,
          _destroy: false,
        },
      },
      () => {
        this.setState({ isVisible: true });
      }
    );
  };

  onSaveEntityService = () => {
    this.setState({ isVisible: false }, () => {
      this.props.onSaveEntityService(this.state.entityService);
    });
  };

  onCancel = () => {
    this.setState({ isVisible: false });
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  onDeleteEntityServices = () => {
    this.props.onDeleteEntityServices(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  onChangeEntityService = (value) => {
    this.setState({
      entityService: update(this.state.entityService, {
        service: { $set: value },
      }),
    });
  };
  onChangeEntityServiceDuration = (value) => {
    this.setState({
      entityService: update(this.state.entityService, {
        duration: { $set: value },
      }),
    });
  };
  onChangeEntityServicePeriodicity = (value) => {
    this.setState({
      entityService: update(this.state.entityService, {
        periodicity: { $set: value },
      }),
    });
  };
  onChangeEntityServiceStart = (value) => {
    this.setState({
      entityService: update(this.state.entityService, {
        start: { $set: value },
      }),
    });
  };

  onChangeEntityServiceIsSupplier = (e) => {
    this.setState({
      entityService: update(this.state.entityService, {
        is_supplier: { $set: e.target.checked },
      }),
    });
  };

  onChangeEntityServiceCompany = (value) => {
    this.setState({
      entityService: update(this.state.entityService, {
        company: { $set: value },
      }),
    });
  };

  onChangeEntityServiceUsers = (values) => {
    this.setState({
      entityService: update(this.state.entityService, {
        users: { $set: values },
      }),
    });
  };

  onChangeEntityServicEemployees = (values) => {
    this.setState({
      entityService: update(this.state.entityService, {
        employees: { $set: values },
      }),
    });
  };

  render() {
    const { currentUser, entityServices } = this.props;

    const { selectedRowKeys, isVisible, entityService } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: "Раздел",
        dataIndex: "service",
        render: (service, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <Can
                role={currentUser.role}
                perform="entities:edit"
                yes={() => (
                  <a onClick={this.onOpenEntityServiceModal.bind(this, record)}>
                    {record.service ? record.service.name : null}
                  </a>
                )}
                no={() => (record.service ? record.service.name : null)}
              />
            </span>
            <span style={{ opacity: "0.7" }}>
              {record.service ? record.service.extra.category : null}
            </span>
          </div>
        ),
        align: "left",
        width: "30%",
      },
      {
        title: "Ответственные",
        dataIndex: "users",
        render: (users, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {users.map((user) => (
              <span>
                <UserInfo title={user.name} userId={user.id} />
              </span>
            ))}
          </div>
        ),
        align: "center",
        width: "25%",
      },
      {
        title: "Продолжительность, мин.",
        dataIndex: "duration",
        width: "15%",
        align: "right",
      },
      {
        title: "Периодичность",
        dataIndex: "periodicity",
        render: (periodicity) => periodicities(periodicity),
        width: "15%",
        align: "right",
      },
      {
        title: "Следующая дата регламента",
        dataIndex: "start",
        render: (start) => moment(start).format("LLL"),
        width: "15%",
        align: "center",
      },
    ];

    const navButton = (
      <Row gutter={{ md: 8, lg: 24, xl: 48 }} style={{ marginBottom: "10px" }}>
        <Col span={24}>
          {hasSelected ? (
            <Button onClick={this.onDeleteEntityServices}>Удалить</Button>
          ) : (
            <Button onClick={this.onAddEntityService}>Добавить</Button>
          )}
        </Col>
      </Row>
    );

    return (
      <React.Fragment>
        <Can
          role={currentUser.role}
          perform="entities:edit"
          yes={() => navButton}
          no={() => null}
        />

        <Row gutter={{ md: 8, lg: 24, xl: 48 }} style={{ paddingTop: 10 }}>
          <Col span={24}>
            <Can
              role={currentUser.role}
              perform="entities:edit"
              yes={() => (
                <Table
                  rowClassName={() => "editable-row"}
                  rowSelection={rowSelection}
                  size={"middle"}
                  bordered
                  pagination={false}
                  columns={columns}
                  dataSource={entityServices}
                  rowKey="id"
                />
              )}
              no={() => (
                <Table
                  size={"middle"}
                  bordered
                  pagination={false}
                  columns={columns}
                  dataSource={entityServices}
                  rowKey="id"
                />
              )}
            />
          </Col>
        </Row>
        <EntityServiceModal
          visible={isVisible}
          entityService={entityService}
          onSave={this.onSaveEntityService}
          onCancel={this.onCancel}
          onChangeEntityService={this.onChangeEntityService}
          onChangeEntityServiceDuration={this.onChangeEntityServiceDuration}
          onChangeEntityServicePeriodicity={
            this.onChangeEntityServicePeriodicity
          }
          onChangeEntityServiceStart={this.onChangeEntityServiceStart}
          onChangeEntityServiceIsSupplier={this.onChangeEntityServiceIsSupplier}
          onChangeEntityServiceCompany={this.onChangeEntityServiceCompany}
          onChangeEntityServiceUsers={this.onChangeEntityServiceUsers}
          onChangeEntityServicEemployees={this.onChangeEntityServicEemployees}
        />
      </React.Fragment>
    );
  }
}

Items.propTypes = {
  orderItems: PropTypes.arrayOf(PropTypes.object),
  total: PropTypes.number,
  discount: PropTypes.object,
  shippingCost: PropTypes.number,
  amountDiscountCode: PropTypes.number,
  onRemoveVariant: PropTypes.func,
};
export default Items;
