import api from "../api";

const Actions = {};

Actions.fetchServices = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_SERVICES_REQUEST" });
    let customers = [];
    const params = getState().services.fetchParams;

    if (params.customers) {
      customers = params.customers.map((customer) => {
        if (customer && customer.id) {
          return customer.id;
        }
      });
    }

    const data = {
      search: params.search,
      category_id: params.category_id,
      customers: customers,
      isAvailable: params.isAvailable, 
      sort: params.sort,
      direction: params.direction,
      page: params.page,
      limit: params.limit,
    };

    return api
      .fetch("/services", data)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_SERVICES", response });
      })
      .catch((response) => {
        // console.log(response.error);
        dispatch({ type: "FETCH_SERVICES_FAILURE", response });
      });
  };
};

Actions.fetchService = (id = null) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_SERVICE_REQUEST" });
    api
      .fetch(`/services/${id}`)
      .then((response) => {
        dispatch({ type: "FETCH_SERVICE", response });
      })
      .catch((error) => {
        api.handleError(error, "Услуга");
        dispatch({ type: "FETCH_SERVICE_FAILURE" });
      });
  };
};

Actions.fetchCategory = (id = null) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_CATEGORY_REQUEST" });
    return api
      .fetch(`/categories/${id}`)
      .then((response) => {
        dispatch({ type: "FETCH_CATEGORY", response });
      })
      .catch((error) => {
        api.handleError(error, "Категория");
        dispatch({ type: "FETCH_SCATEGORY_FAILURE" });
      });
  };
};

Actions.fetchCategories = () => {
  return (dispatch) => {
    dispatch({ type: "FETCH_CATEGORIES_REQUEST" });
    return api
      .fetch("/categories")
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_CATEGORIES", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_CATEGORIES_FAILURE", response });
      });
  };
};

Actions.fetchOrders = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_SERVICE_ORDERS_REQUEST" });
    return api
      .fetch(`/services/${id}/orders`)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_SERVICE_ORDERS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_SERVICE_ORDERS_FAILURE", response });
      });
  };
};

Actions.onChangeCategory = (data) => {
  return (dispatch) => {
    dispatch({ type: "SERVICE_CHANGE_CATEGORY_REQUEST" });

    return api
      .patch(`/services/move`, data)
      .then((response) => {
        api.openNotification("success", "Услуга", "Успешно обновлены!");
        dispatch({ type: "SERVICE_CHANGE_CATEGORY", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Услуга", error);
          });
        }
        dispatch({ type: "SERVICE_CHANGE_CATEGORY_FAILURE", response });
      });
  };
};

Actions.onUpdateService = (id) => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_SERVICE_REQUEST" });
    const service = getState().services.service;

    const service_extcodes_attributes = (service.service_extcodes ?? []).map((item) => {
      return {
        id: item.is_exist ? item.id : null,
        code: item.code,
        _destroy: item._destroy,
      };
    });

    const data = {
      service: {
        active: service.active,
        name: service.name,
        description: service.description,
        ext_code: service.ext_code,
        service_extcodes_attributes: service_extcodes_attributes,
        is_available: service.is_available,
      },
    };
    return api
      .patch(`/services/${id}`, data)
      .then((response) => {
        api.openNotification("success", "Услуга", "Успешно обновлена!");
        dispatch({ type: "PATCH_SERVICE", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Услуга", error);
          });
        }
        dispatch({ type: "PATCH_SERVICE_FAILURE", response });
      });
  };
};

Actions.onCreateService = (categoryId) => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_SERVICE_REQUEST" });
    const service = getState().services.service;

    const service_extcodes_attributes = (service.service_extcodes ?? []).map((item) => {
      return {
        id: item.is_exist ? item.id : null,
        code: item.code,
        _destroy: item._destroy,
      };
    });

    const data = {
      service: {
        category_id: categoryId,
        active: service.active,
        name: service.name,
        description: service.description,
        ext_code: service.ext_code,
        service_extcodes_attributes: service_extcodes_attributes,
        is_available: service.is_available,
      },
    };

    return api
      .post(`/services`, data)
      .then((response) => {
        api.openNotification("success", "Услуга", "Успешно создана!");
        dispatch({ type: "POST_SERVICE", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Услуга", error);
          });
        }
        dispatch({ type: "POST_SERVICE_FAILURE", response });
      });
  };
};

Actions.onDelete = (id) => {
  return (dispatch) => {
    dispatch({ type: "DELETE_SERVICE_REQUEST" });
    return api
      .delete(`/services/${id}`)
      .then((response) => {
        api.openNotification("success", "Услуга", "Успешно удален!");
        dispatch({ type: "DELETE_SERVICE", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Услуга", error);
          });
        }
        dispatch({
          type: "DELETE_SERVICE_FAILURE",
        });
      });
  };
};

Actions.onNew = () => {
  return (dispatch) => {
    dispatch({
      type: "SERVICE_NEW",
    });
  };
};

Actions.onNewCategory = (ancestry) => {
  return (dispatch) => {
    dispatch({
      type: "CATEGORY_NEW",
      ancestry: ancestry,
    });
  };
};

Actions.onSearch = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_SERVICE_SEARCH",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onSelectCategory = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SERVICES_SELECT_CATEGORY",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangePage = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_SERVICES_CHANGE_PAGE",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeLimit = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_SERVICES_CHANGE_LIMIT",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeName = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SERVICE_CHANGE_NAME",
      value: value,
    });
  };
};

Actions.onChangeDescription = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SERVICE_CHANGE_DESCRIPTION",
      value: value,
    });
  };
};

Actions.onAppendExtCode = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SERVICE_EXT_CODE_APPEND",
      value: value,
    });
  };
};

Actions.onRemoveExtCode = (idx) => {
  return (dispatch) => {
    dispatch({
      type: "SERVICE_EXT_CODE_REMOVE",
      idx: idx,
    });
  };
};

Actions.onDeleteExtCode = (idx) => {
  return (dispatch) => {
    dispatch({
      type: "SERVICE_EXT_CODE_DELETE",
      idx: idx,
    });
  };
};

Actions.onChangeExtCode = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SERVICE_CHANGE_EXT_CODE",
      value: value,
    });
  };
};

Actions.onChangeIsAvailable = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SERVICE_CHANGE_IS_AVAILABLE",
      value: value,
    });
  };
};

// categories
Actions.onCreateCategory = () => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_CATEGORY_REQUEST" });
    const category = getState().services.category;
    const params = {
      category: {
        name: category.name,
        ancestry: category.ancestry,
        position: category.position,
        description: category.description,
        category_type: category.category_type,
        ext_code: category.ext_code,
        customer_id: category.customer ? category.customer.id : null,
      },
    };

    return api
      .post(`/categories`, params)
      .then((response) => {
        api.openNotification("success", "Категория", "Успешно создана!");
        dispatch({ type: "POST_CATEGORY_SUCCEED", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Категория", error);
          });
        }
        dispatch({ type: "POST_CATEGORY_FAILURE", response });
      });
  };
};

Actions.onUpdateCategory = (id) => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_CATEGORY_REQUEST" });
    const category = getState().services.category;

    const params = {
      category: {
        name: category.name,
        ancestry: category.ancestry,
        position: category.position,
        description: category.description,
        category_type: category.category_type,
        ext_code: category.ext_code,
        customer_id: category.customer ? category.customer.id : null,
      },
    };
    return api
      .patch(`/categories/${id}`, params)
      .then((response) => {
        api.openNotification("success", "Категория", "Успешно обновлена!");
        dispatch({ type: "PATCH_CATEGORY_SUCCEED", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Категория", error);
          });
        }
        dispatch({ type: "PATCH_CATEGORY_FAILURE", response });
      });
  };
};

Actions.onDeleteCategory = (id) => {
  return (dispatch) => {
    dispatch({ type: "DELETE_CATEGORY_REQUEST" });
    return api
      .delete(`/categories/${id}`)
      .then((response) => {
        api.openNotification("success", "Категория", "Успешно удалена!");
        dispatch({ type: "DELETE_CATEGORY_SUCCEED", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Категория", error);
          });
        }
        dispatch({
          type: "DELETE_CATEGORY_FAILURE",
        });
      });
  };
};

Actions.onChangeCategoryName = (value) => {
  return (dispatch) => {
    dispatch({
      type: "CATEGORY_CHANGE_NAME",
      value: value,
    });
  };
};

Actions.onChangeCategoryPosition = (value) => {
  return (dispatch) => {
    dispatch({
      type: "CATEGORY_CHANGE_POSITION",
      value: value,
    });
  };
};

Actions.onChangeCategoryCustomer = (value) => {
  return (dispatch) => {
    dispatch({
      type: "CATEGORY_CHANGE_CUSTOMER",
      value: value,
    });
  };
};

Actions.onChangeCategoryType = (value) => {
  return (dispatch) => {
    dispatch({
      type: "CATEGORY_CHANGE_TYPE",
      value: value,
    });
  };
};

Actions.onChangeCategoryExtCode = (value) => {
  return (dispatch) => {
    dispatch({
      type: "CATEGORY_CHANGE_EXT_CODE",
      value: value,
    });
  };
};


// filtes
Actions.onChangeFilterCustomers = (values) => {
  return (dispatch) => {
    dispatch({
      type: "CATEGORIES_FILTER_CUSTOMERS",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterIsAvailable = (value) => {
  return (dispatch) => {
    dispatch({
      type: "CATEGORIES_FILTER_IS_AVAILABLE",
      value: value,
    });
    return Promise.resolve();
  };
};

export default Actions;
