// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Select, Spin } from "antd";
import api from "../../api";

const Option = Select.Option;

class SelectMultiFetch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      value: null,
      fetching: false,
    };
  }

  static defaultProps = {
    disabled: false,
  };

  componentWillMount = () => {
    console.log('componentWillMount');
    const data = this.props.selected.map((d) => {
      if (d) {
        return { value: d.id, name: d.name, extra: d.extra };
      }
    });
    const value = this.props.selected.map((d) => {
      if (d) {
        return { key: d.id, label: d.name, extra: d.extra };
      }
    });

    if (this.props.selected) {
      this.setState({
        data: data,
        value: value,
        extra: this.props.selected.extra,
      });
    }
  };

  componentDidUpdate = (prevProps) => {
    console.log('componentDidUpdate');
    if (this.props.selected !== prevProps.selected) {
      if (this.props.selected) {
        const data = this.props.selected.map((d) => {
          if (d) {
            return { value: d.id, name: d.name, extra: d.extra };
          }
        });
        const value = this.props.selected.map((d) => {
          if (d) {
            return { key: d.id, label: d.name, extra: d.extra };
          }
        });
        this.setState({
          data: data,
          value: value,
          extra: this.props.selected.extra,
        });
      } else {
        this.setState({
          data: [],
          value: null,
          fetching: false,
        });
      }
    }
  };

  onFocus = () => {
    this.setState({ data: [], fetching: true });
    api.fetch(`${this.props.url}`).then((response) => {
      const data = response.data.map((item) => ({
        name: item.name,
        value: item.id,
        extra: item.extra,
      }));
      this.setState({ data, fetching: false });
    });
  };

  onChange = (value) => {
    const values = value.map((d) => {
      return { key: d.key, label: d.label };
    });
    const selected = value.map((d) => {
      return { id: d.key, name: d.label };
    });
    this.setState(
      {
        value: values,
        selected: selected,
        fetching: false,
      },
      () => {
        return this.props.onChange
          ? this.props.onChange(this.state.selected)
          : null;
      }
    );
  };

  onSearch = (value) => {
    api
      .fetch(`${this.props.url}&q=${encodeURIComponent(value)}`)
      .then((response) => {
        const data = response.data.map((item) => ({
          name: `${item.name}`,
          value: `${item.id}`,
          extra: item.extra,
        }));
        this.setState({ data, fetching: false });
      });
  };

  renderOptions = (data, showSubtitle) => {
    if (showSubtitle) {
      return data.map((d) => (
        <Option key={d.value} title={d.name} style={{ whiteSpace: "normal" }}>
          <div>
            <div>{d.name}</div>
            <div
              style={{
                fontSize: "13px",
                color: "#999",
                textOverflow: "text-overflow",
              }}
            >
              {d.extra ? d.extra.subtitle : null}
            </div>
          </div>
        </Option>
      ));
    } else {
      return data.map((d) => (
        <Option key={d.value} title={d.name} style={{ whiteSpace: "normal" }}>
          {d.name}
        </Option>
      ));
    }
  };

  render() {
    const { fetching, data, value } = this.state;
    const { showSubtitle, placeholder, disabled, style } = this.props;
    return (
      <Select
        disabled={disabled}
        mode={"multiple"}
        labelInValue
        value={value}
        placeholder={placeholder ? placeholder : "выберите значение"}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        filterOption={false}
        onFocus={this.onFocus}
        onChange={this.onChange}
        onSearch={this.onSearch}
        style={style ? style : { width: "100%" }}
      >
        {this.renderOptions(data, showSubtitle)}
      </Select>
    );
  }
}

SelectMultiFetch.propTypes = {
  url: PropTypes.string,
  mode: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.array,
  selected: PropTypes.array,
  onChange: PropTypes.func,
};

export default SelectMultiFetch;
