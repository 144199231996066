import api from "../api";

const Actions = {};

Actions.fetchTaskTemplates = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_TASK_TEMPLATES_REQUEST" });
    const params = getState().taskTemplates.fetchParams;
    api
      .fetch("/task_templates", params)
      .then((response) => {
        dispatch({ type: "FETCH_TASK_TEMPLATES", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_TASK_TEMPLATES_FAILURE", response });
      });
  };
};

Actions.fetchTaskTemplate = (id = null) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_TASK_TEMPLATE_REQUEST" });
    api
      .fetch(`/task_templates/${id}`)
      .then((response) => {
        dispatch({ type: "FETCH_TASK_TEMPLATE", response });
      })
      .catch((error) => {
        api.handleError(error, "Шаблон задачи");
        dispatch({ type: "FETCH_TASK_TEMPLATE_FAILURE" });
      });
  };
};

Actions.onNew = () => {
  return (dispatch, getState) => {
    const currentUser = getState().session.currentUser;
    dispatch({
      type: "TASK_TEMPLATE_NEW",
      user: currentUser,
    });
  };
};

Actions.onUpdate = (id) => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_TASK_TEMPLATE_REQUEST" });
    const taskTemplate = getState().taskTemplates.taskTemplate;
    let departments = []
    taskTemplate.departments.map((department) => {
      return (
        departments.push(department.id)
      );
    });

    let customers = []
    taskTemplate.customers.map((customer) => {
      return (
        customers.push(customer.id)
      );
    });

    let assignees = []
    taskTemplate.assignees.map((assignee) => {
      return (
        assignees.push(assignee.id)
      );
    });

    let supervisors = []
    taskTemplate.supervisors.map((supervisor) => {
      return (
        supervisors.push(supervisor.id)
      );
    });

    let members = []
    taskTemplate.members.map((member) => {
      return (
        members.push(member.id)
      );
    });

    let todoTemplates = []
    taskTemplate.todo_templates.map((todo_template) => {
      return (
        todoTemplates.push(todo_template.id)
      );
    });

    const fieldSets = taskTemplate.field_sets.map((item) => {
      return {
        id: item.is_exist ? item.id : null,
        field_set_id: item.field_set ? item.field_set.id : null,
        position: item.position,
        value: item.value,
        _destroy: item._destroy,
      };
    });

    const data = {
        task_template: {
            is_active: taskTemplate.is_active,
            visibility: taskTemplate.visibility,
            name: taskTemplate.name,
            description: taskTemplate.description,
            task_owner: taskTemplate.task_owner,
            task_type: taskTemplate.task_type,
            action_template_id: taskTemplate.action_template ? taskTemplate.action_template.id : null,
            action_template_hours: taskTemplate.action_template_hours,
            action_template_subject: taskTemplate.action_template_subject,
            has_timing: taskTemplate.has_timing,
            has_todos: taskTemplate.has_todos,
            has_attachments: taskTemplate.has_attachments,
            has_comments: taskTemplate.has_comments,
            has_supervisor_assignees: taskTemplate.has_supervisor_assignees,
            has_user_assignees: taskTemplate.has_user_assignees,
            has_member_assignees: taskTemplate.has_member_assignees,
            has_amount: taskTemplate.has_amount,
            has_fields: taskTemplate.has_fields,
            department_ids: departments,
            customer_ids: customers,
            user_ids: assignees,
            supervisor_ids: supervisors,
            member_ids: members,
            todo_template_ids: todoTemplates,
            assignee_fill_type: taskTemplate.assignee_fill_type,
            supervisor_fill_type: taskTemplate.supervisor_fill_type,
            member_fill_type: taskTemplate.member_fill_type,
            task_template_field_sets_attributes: fieldSets
      },
    };

    api
      .patch(`/task_templates/${id}`, data)
      .then((response) => {
        dispatch({ type: "PATCH_TASK_TEMPLATE", response });
        api.openNotification("success", "Шаблон задачи", "Успешно обновлен!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Заказ", error);
          });
        }
        dispatch({ type: "PATCH_TASK_TEMPLATE_FAILURE", response });
      });
  };
};

Actions.onCreate = () => {
  return (dispatch, getState) => {
    dispatch({ type: "TASK_TEMPLATE_REQUEST" });
    const taskTemplate = getState().taskTemplates.taskTemplate;

    const departments = taskTemplate?.departments
      ?.map((it) => it.id);

    const customers = taskTemplate?.customers
      ?.map((it) => it.id);

    const assignees = taskTemplate?.assignees
      ?.map((it) => it.id);

    const supervisors = taskTemplate?.supervisors
      ?.map((it) => it.id);

    const members = taskTemplate?.members
      ?.map((it) => it.id);

    const todoTemplates = taskTemplate?.todo_templates
      ?.map((it) => it.id);

    const fieldSets = taskTemplate?.field_sets
      ?.map((it) => ({
        id: it.is_exist ? it.id : null,
        field_set_id: it.field_set ? it.field_set.id : null,
        position: it.position,
        value: it.value,
        _destroy: it._destroy,
      }));

    const data = {
        task_template: {
            is_active: taskTemplate.is_active,
            visibility: taskTemplate.visibility,
            name: taskTemplate.name,
            description: taskTemplate.description,
            task_owner: taskTemplate.task_owner,
            task_type: taskTemplate.task_type,
            action_template_id: taskTemplate.action_template ? taskTemplate.action_template.id : null,
            action_template_hours: taskTemplate.action_template_hours,
            action_template_subject: taskTemplate.action_template_subject,
            has_timing: taskTemplate.has_timing,
            has_todos: taskTemplate.has_todos,
            has_attachments: taskTemplate.has_attachments,
            has_comments: taskTemplate.has_comments,
            has_supervisor_assignees: taskTemplate.has_supervisor_assignees,
            has_user_assignees: taskTemplate.has_user_assignees,
            has_member_assignees: taskTemplate.has_member_assignees,
            has_amount: taskTemplate.has_amount,
            has_fields: taskTemplate.has_fields,
            department_ids: departments,
            customer_ids: customers,
            user_ids: assignees,
            supervisor_ids: supervisors,
            member_ids: members,
            todo_template_ids: todoTemplates,
            assignee_fill_type: taskTemplate.assignee_fill_type,
            supervisor_fill_type: taskTemplate.supervisor_fill_type,
            member_fill_type: taskTemplate.member_fill_type,
            task_template_field_sets_attributes: fieldSets
      },
    };
    return api
      .post(`/task_templates`, data)
      .then((response) => {
        dispatch({ type: "TASK_TEMPLATE_SUCCEED", response });
        api.openNotification("success", "Шаблон задачи", "Успешно создан!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Шаблон задачи", error);
          });
        }
        dispatch({ type: "TASK_TEMPLATE_FAILURE", response });
      });
  };
};

Actions.onDelete = (id) => {
  return (dispatch) => {
    dispatch({ type: "DELETE_TASK_TEMPLATE_REQUEST" });
    return api
      .delete(`/task_templates/${id}`)
      .then((response) => {
        api.openNotification("success", "Шаблон задачи", "Успешно удалено!");
        dispatch({ type: "DELETE_TASK_TEMPLATE", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Шаблон задачи", error);
          });
        }
        dispatch({
          type: "DELETE_TASK_TEMPLATE_FAILURE",
        });
      });
  };
};

Actions.onChangePage = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_TASK_TEMPLATES_CHANGE_PAGE",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeLimit = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_TASK_TEMPLATES_CHANGE_LIMIT",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onSortBy = (field, direction) => {
  return (dispatch) => {
    dispatch({
      type: "TASK_TEMPLATES_SORT_BY",
      field: field,
      direction: direction,
    });
    return Promise.resolve();
  };
};

Actions.onChangeActive = (value) => {
    return (dispatch) => {
      dispatch({
        type: "TASK_TEMPLATE_CHANGE_ACTIVE",
        value: value,
      });
    };
  };

  Actions.onChangeVisibility = (value) => {
    return (dispatch) => {
      dispatch({
        type: "TASK_TEMPLATE_CHANGE_VISIBILITY",
        value: value,
      });
    };
  };

Actions.onChangeCustomer = (value) => {
  return (dispatch) => {
    dispatch({
      type: "TASK_TEMPLATE_CHANGE_CUSTOMER",
      value: value,
    });
  };
};

Actions.onChangeName = (value) => {
  return (dispatch) => {
    dispatch({
      type: "TASK_TEMPLATE_CHANGE_NAME",
      value: value,
    });
  };
};

Actions.onChangeDescription = (value) => {
  return (dispatch) => {
    dispatch({
      type: "TASK_TEMPLATE_CHANGE_DESCRIPTION",
      value: value,
    });
  };
};

Actions.onChangeOwner = (value) => {
  return (dispatch) => {
    dispatch({
      type: "TASK_TEMPLATE_CHANGE_OWNER",
      value: value,
    });
  };
};

Actions.onChangeActionTemplate = (value) => {
  return (dispatch) => {
    dispatch({
      type: "TASK_TEMPLATE_CHANGE_ACTION_TEMPLATE",
      value: value,
    });
  };
};

Actions.onChangeActionTemplateHours = (value) => {
  return (dispatch) => {
    dispatch({
      type: "TASK_TEMPLATE_CHANGE_ACTION_TEMPLATE_HOURS",
      value: value,
    });
  };
};

Actions.onChangeActionTemplateSubject = (value) => {
  return (dispatch) => {
    dispatch({
      type: "TASK_TEMPLATE_CHANGE_ACTION_TEMPLATE_SUBJECT",
      value: value,
    });
  };
};

Actions.onChangeHasTiming = (value) => {
  return (dispatch) => {
    dispatch({
      type: "TASK_TEMPLATE_CHANGE_HAS_TIMING",
      value: value,
    });
  };
};

Actions.onChangeHasTodos = (value) => {
  return (dispatch) => {
    dispatch({
      type: "TASK_TEMPLATE_CHANGE_HAS_TODOS",
      value: value,
    });
  };
};

Actions.onChangeHasAttachments = (value) => {
  return (dispatch) => {
    dispatch({
      type: "TASK_TEMPLATE_CHANGE_HAS_ATTACHMENTS",
      value: value,
    });
  };
};

Actions.onChangeHasComments = (value) => {
  return (dispatch) => {
    dispatch({
      type: "TASK_TEMPLATE_CHANGE_HAS_COMMENTS",
      value: value,
    });
  };
};

Actions.onChangeHasSupervisorAssignees = (value) => {
    return (dispatch) => {
      dispatch({
        type: "TASK_TEMPLATE_CHANGE_HAS_SUPERVISOR_ASSIGNEES",
        value: value,
      });
    };
  };

Actions.onChangeHasUserAssignees = (value) => {
  return (dispatch) => {
    dispatch({
      type: "TASK_TEMPLATE_CHANGE_HAS_USER_ASSIGNEES",
      value: value,
    });
  };
};

Actions.onChangeHasMemberAssignees = (value) => {
  return (dispatch) => {
    dispatch({
      type: "TASK_TEMPLATE_CHANGE_HAS_MEMBER_ASSIGNEES",
      value: value,
    });
  };
};

Actions.onChangeHasAmount = (value) => {
  return (dispatch) => {
    dispatch({
      type: "TASK_TEMPLATE_CHANGE_HAS_AMOUNT",
      value: value,
    });
  };
};

Actions.onChangeHasFields = (value) => {
  return (dispatch) => {
    dispatch({
      type: "TASK_TEMPLATE_CHANGE_HAS_FIELDS",
      value: value,
    });
  };
};

Actions.onChangeDepartments = (values) => {
  return (dispatch) => {
    dispatch({
      type: "TASK_TEMPLATE_CHANGE_DEPARTMENTS",
      values: values,
    });
  };
};

Actions.onChangeCustomers = (values) => {
  return (dispatch) => {
    dispatch({
      type: "TASK_TEMPLATE_CHANGE_CUSTOMERS",
      values: values,
    });
  };
};

Actions.onChangeTodoTemplates = (values) => {
  return (dispatch) => {
    dispatch({
      type: "TASK_TEMPLATE_CHANGE_TODO_TEMPLATES",
      values: values,
    });
  };
};

Actions.onChangeAssigneeFillType = (value) => {
  return (dispatch) => {
    dispatch({
      type: "TASK_TEMPLATE_CHANGE_ASSIGNEE_FILL_TYPE",
      value: value,
    });
  };
};

Actions.onChangeAssignees = (values) => {
  return (dispatch) => {
    dispatch({
      type: "TASK_TEMPLATE_CHANGE_ASSIGNEES",
      values: values,
    });
  };
};

Actions.onChangeSupervisorFillType = (value) => {
  return (dispatch) => {
    dispatch({
      type: "TASK_TEMPLATE_CHANGE_SUPERVISOR_FILL_TYPE",
      value: value,
    });
  };
};

Actions.onChangeSupervisors = (values) => {
  return (dispatch) => {
    dispatch({
      type: "TASK_TEMPLATE_CHANGE_SUPERVISORS",
      values: values,
    });
  };
};

Actions.onChangeMemberFillType = (value) => {
  return (dispatch) => {
    dispatch({
      type: "TASK_TEMPLATE_CHANGE_MEMBER_FILL_TYPE",
      value: value,
    });
  };
};

Actions.onChangeMembers = (values) => {
  return (dispatch) => {
    dispatch({
      type: "TASK_TEMPLATE_CHANGE_MEMBERS",
      values: values,
    });
  };
};

// field_sets
Actions.onCreateFieldSet = (item) => {
  return (dispatch) => {
    dispatch({
      type: "TASK_TEMPLATE_FIELD_SET_CREATE",
      item: item,
    });
  };
};

Actions.onUpdateFieldSet = (idx, item) => {
  return (dispatch) => {
    dispatch({
      type: "TASK_TEMPLATE_FIELD_SET_UPDATE",
      idx: idx,
      item: item,
    });
  };
};

Actions.onDeleteFieldSet = (idx) => {
  return (dispatch) => {
    dispatch({
      type: "TASK_TEMPLATE_FIELD_SET_DELETE",
      idx: idx,
    });
  };
};

Actions.onDestroyFieldSet = (idx) => {
  return (dispatch) => {
    dispatch({
      type: "TASK_TEMPLATE_FIELD_SET_DESTROY",
      idx: idx,
    });
  };
};

// filters
Actions.onSetFilters = () => {
  return (dispatch) => {
    dispatch({
      type: "TASK_TEMPLATES_SET_FILTERS",
    });
    return Promise.resolve();
  };
};

Actions.onClearFilters = (value) => {
  return (dispatch) => {
    dispatch({
      type: "TASK_TEMPLATES_CLEAR_FILTERS",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterTaskOwner = (value) => {
  return (dispatch) => {
    dispatch({
      type: "TASK_TEMPLATES_FILTER_TASK_OWNER",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterTaskType = (value) => {
  return (dispatch) => {
    dispatch({
      type: "TICKETS_FILTER_TASK_TYPE",
      value: value,
    });
    return Promise.resolve();
  };
};

export default Actions;
