// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../../actions/taskTemplates";
import { Spin } from "antd";
import Form from "./Form";

class TaskTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: false
    };
  }

  componentWillMount = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.fetchTaskTemplate(this.props.match.params.id));
    } else {
      this.setState({ isNew: true }, () => {
        this.props.dispatch(Actions.onNew());
      });
    }
  };

  onSave = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.onUpdate(this.props.match.params.id));
    } else {
      this.props.dispatch(Actions.onCreate()).then(() => {
        if (!this.props.errors) {
          this.props.history.push(`/task_templates/${this.props.taskTemplate.id}/edit`);
        }
      });
    }
  };

  onClose = () => {
    this.props.history.push(`/task_templates`);
  };


  // general
  onChangeActive = e => {
    this.props.dispatch(Actions.onChangeActive(e.target.value));
  };

  onChangeVisibility = value => {
    this.props.dispatch(Actions.onChangeVisibility(value));
  };

  onChangeCustomers = values => {
    this.props.dispatch(Actions.onChangeCustomers(values));
  }

  onChangeCustomer = value => {
    this.props.dispatch(Actions.onChangeCustomer(value));
  };

  onChangeAssignees = values => {
    this.props.dispatch(Actions.onChangeAssignees(values));
  };

  onChangeSupervisors = values => {
    this.props.dispatch(Actions.onChangeSupervisors(values));
  };

  onChangeMembers = values => {
    this.props.dispatch(Actions.onChangeMembers(values));
  };

  onChangeName = e => {
    this.props.dispatch(Actions.onChangeName(e.target.value));
  };

  onChangeDescription = (e) => {
    this.props.dispatch(Actions.onChangeDescription(e.target.value));
  };

  onChangeOwner = value => {
    this.props.dispatch(Actions.onChangeOwner(value));
  };

  // actions
  onChangeActionTemplate = value => {
    this.props.dispatch(Actions.onChangeActionTemplate(value));
  };

  onChangeActionTemplateHours = value => {
    this.props.dispatch(Actions.onChangeActionTemplateHours(value));
  };

  onChangeActionTemplateSubject = e => {
    this.props.dispatch(Actions.onChangeActionTemplateSubject(e.target.value));
  };

  onChangeHasTiming = e => {
    this.props.dispatch(Actions.onChangeHasTiming(e.target.value));
  };

  onChangeHasTodos = e => {
    this.props.dispatch(Actions.onChangeHasTodos(e.target.value));
  };

  onChangeHasAttachments = e => {
    this.props.dispatch(Actions.onChangeHasAttachments(e.target.value));
  };

  onChangeHasComments = e => {
    this.props.dispatch(Actions.onChangeHasComments(e.target.value));
  };

  onChangeHasSupervisorAssignees = e => {
    this.props.dispatch(Actions.onChangeHasSupervisorAssignees(e.target.value));
  };

  onChangeHasUserAssignees = e => {
    this.props.dispatch(Actions.onChangeHasUserAssignees(e.target.value));
  };

  onChangeHasMemberAssignees = e => {
    this.props.dispatch(Actions.onChangeHasMemberAssignees(e.target.value));
  };

  onChangeHasAmount = e => {
    this.props.dispatch(Actions.onChangeHasAmount(e.target.value));
  };

  onChangeHasFields = e => {
    this.props.dispatch(Actions.onChangeHasFields(e.target.value));
  };

  onChangeDepartments = values => {
    this.props.dispatch(Actions.onChangeDepartments(values));
  }

  onChangeTodoTemplates = (values) => {
    this.props.dispatch(Actions.onChangeTodoTemplates(values));
  };

  onChangeAssigneeFillType = (e) => {
    this.props.dispatch(Actions.onChangeAssigneeFillType(e.target.value));
  };

  onChangeSupervisorFillType = (e) => {
    this.props.dispatch(Actions.onChangeSupervisorFillType(e.target.value));
  };

  onChangeMemberFillType = (e) => {
    this.props.dispatch(Actions.onChangeMemberFillType(e.target.value));
  };

  // field_sets
  onSaveFieldSet = (item) => {
    const { taskTemplate } = this.props;
    let idx = taskTemplate.field_sets.findIndex(function (o) {
      return o.id == item.id;
    });
    if (idx === -1) {
      this.props.dispatch(Actions.onCreateFieldSet(item));
    } else {
      this.props.dispatch(Actions.onUpdateFieldSet(idx, item));
    }
  };

  onDeleteFieldSets = (ids) => {
    const { taskTemplate } = this.props;
    ids.map((id) => {
      let idx = taskTemplate.field_sets.findIndex(function (o) {
        return o.id == id;
      });
      if (idx === -1) {
        return null;
      }
      if (taskTemplate.field_sets[idx].is_exist) {
        this.props.dispatch(Actions.onDeleteFieldSet(idx));
      } else {
        this.props.dispatch(Actions.onDestroyFieldSet(idx));
      }
    });
  };

  render() {
    const { isNew } = this.props;
    const { isLoading, taskTemplate } = this.props;
    return isLoading ? (
      <Spin />
    ) : (
      <Form
        isNew={isNew}
        taskTemplate={taskTemplate}
        onSave={this.onSave}
        onClose={this.onClose}
        // header
        onChangeActive={this.onChangeActive}
        onChangeVisibility={this.onChangeVisibility}
        onChangeCustomer={this.onChangeCustomer}
        onChangeName={this.onChangeName}
        onChangeDescription={this.onChangeDescription}
        onChangeOwner={this.onChangeOwner}
        onChangeHasTiming={this.onChangeHasTiming}
        onChangeHasTodos={this.onChangeHasTodos}
        onChangeHasAttachments={this.onChangeHasAttachments}
        onChangeHasComments={this.onChangeHasComments}
        onChangeHasSupervisorAssignees={this.onChangeHasSupervisorAssignees}
        onChangeHasUserAssignees={this.onChangeHasUserAssignees}
        onChangeHasMemberAssignees={this.onChangeHasMemberAssignees}
        onChangeHasAmount={this.onChangeHasAmount}
        onChangeHasFields={this.onChangeHasFields}
        onChangeDepartments={this.onChangeDepartments}
        onChangeCustomers={this.onChangeCustomers}
        onChangeTodoTemplates={this.onChangeTodoTemplates}
        onChangeAssigneeFillType={this.onChangeAssigneeFillType}
        onChangeAssignees={this.onChangeAssignees}
        onChangeSupervisorFillType={this.onChangeSupervisorFillType}
        onChangeSupervisors={this.onChangeSupervisors}
        onChangeMemberFillType={this.onChangeMemberFillType}
        onChangeMembers={this.onChangeMembers}
        // field_sets
        onSaveFieldSet={this.onSaveFieldSet}
        onDeleteFieldSets={this.onDeleteFieldSets}
        // actions
        onChangeActionTemplate={this.onChangeActionTemplate}
        onChangeActionTemplateHours={this.onChangeActionTemplateHours}
        onChangeActionTemplateSubject={this.onChangeActionTemplateSubject}
      />
    );
  }
}

TaskTemplate.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  taskTemplate: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool
};

const mapStateToProps = state => ({
  isLoading: state.taskTemplates.isLoading,
  errors: state.taskTemplates.errors,
  taskTemplate: state.taskTemplates.taskTemplate
});

export default connect(mapStateToProps)(withRouter(TaskTemplate));
