// @flow
import React, { Component } from "react";
import {
  Row,
  Col,
  Modal,
  Button,
  Form,
  Input,
  DatePicker,
} from "antd";
import moment from "moment";
import "moment/locale/ru";
import _ from "lodash/array";

import SelectFetch from "../../../components/SelectFetch";

const FormItem = Form.Item;

class SchedulModal extends React.Component {

  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onSave(values);
    });
  };


  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      visible,
      onCancel,
      stage,
      onChangeProjectStage,
      onChangePosition,
      onChangeDescription,
      onChangePlanStart,
      onChangePlanFinish,
    } = this.props;


    return (
      <Modal
        title={stage.id ? stage.name : "Новый этап"}
        width={700}
        destroyOnClose
        visible={visible}
        onOk={this.onSubmit}
        onCancel={onCancel}
      >
        <Form layout={"vertical"}>
          <Row gutter={16}>
            <Col span={24}>
              <FormItem label={"Этап проекта"}>
                {getFieldDecorator("entity_service", {
                  initialValue: stage.project_stage
                    ? stage.project_stage
                    : null,
                  rules: [
                    {
                      required: true,
                      message: "Введите этап проекта!",
                    },
                  ],
                })(
                  <SelectFetch
                    showSearch
                    showSubtitle
                    placeholder="Этап проекта"
                    onChange={onChangeProjectStage}
                    selected={stage.project_stage ? stage.project_stage : null}
                    url={`/project_stages/search/?`}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <FormItem label={"Описание этапа"}>
                {getFieldDecorator("description", {
                  onChange: onChangeDescription,
                  initialValue: stage.description,
                  rules: [
                    {
                      required: true,
                      message: "Введите описание!",
                    },
                  ],
                })(<Input />)}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <FormItem label={"Начало (план)"}>
                {getFieldDecorator("plan_start", {
                  onChange: onChangePlanStart,
                  initialValue: stage.plan_start
                    ? moment(stage.plan_start)
                    : null,
                  rules: [
                    {
                      required: true,
                      message: "Введите дату начала!",
                    },
                  ],
                })(
                  <DatePicker
                    format={"LLL"}
                    className="ant-btn-width-100"
                    showTime
                  />
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label={"Окончание (план)"}>
                {getFieldDecorator("plan_finish", {
                  onChange: onChangePlanFinish,
                  initialValue: stage.plan_finish
                    ? moment(stage.plan_finish)
                    : null,
                  rules: [
                    {
                      required: true,
                      message: "Введите дату окончания!",
                    },
                  ],
                })(
                  <DatePicker
                    format={"LLL"}
                    className="ant-btn-width-100"
                    showTime
                  />
                )}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(SchedulModal);
