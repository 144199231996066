import api from "../api";

const Actions = {};

Actions.fetchArticles = (sectionId) => {
  return (dispatch, getState) => {
    dispatch({type: "FETCH_ARTICLES_REQUEST"});
    const params = getState().articles.fetchParams;
    params.section_id = sectionId
    api
      .fetch(`/sections/${sectionId}/articles`, params)
      .then(response => {
        dispatch({type: "FETCH_ARTICLES", response});
      })
      .catch(response => {
        console.log(response.error);
        dispatch({type: "FETCH_ARTICLES_FAILURE", response});
      });
  };
};

Actions.fetchArticle = (sectionId, id) => {
  return dispatch => {
    dispatch({type: "FETCH_ARTICLE_REQUEST"});
    api
      .fetch(`/sections/${sectionId}/articles/${id}`)
      .then(response => {
        dispatch({type: "FETCH_ARTICLE", response});
      })
      .catch(error => {
        api.handleError(error, "Статья");
        dispatch({type: "FETCH_ARTICLE_FAILURE"});
      });
  };
};

Actions.newArticle = () => {
  return (dispatch, getState) => {
    dispatch({
      type: "NEW_ARTICLE"
    });
  };
};

Actions.createArticle = (sectionId) => {
  return (dispatch, getState) => {
    dispatch({type: "POST_ARTICLE_REQUEST"});

    let article = getState().articles.article;
    let permissions = getState().articles.permissions;
    let contentTagIds = article.content_tags?.map((it) => it.id)

    let data = {
      article: {
        section_id: sectionId,
        title: article.title,
        body: article.body,
        published_at: article.published_at,
        article_permissions_attributes: permissions,
        content_tag_ids: contentTagIds
      }
    };

    return api
      .post(`/sections/${sectionId}/articles`, data)
      .then(response => {
        dispatch({type: "POST_ARTICLE_SUCCEED", response});
        api.openNotification("success", "Статья", "Успешно создана!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Статья", error);
          });
        }
        dispatch({type: "POST_ARTICLE_FAILURE", response});
      });
  };
};

Actions.changeArticlePublished = (value) => {
  return (dispatch, getState) => {
    dispatch({
      type: "CHANGE_ARTICLE_PUBLISHED",
      value: value
    });
  };
};

Actions.changeArticleTitle = (value) => {
  return dispatch => {
    dispatch({
      type: "CHANGE_ARTICLE_TITLE",
      value: value
    });
  };
};

Actions.changeArticleBody = (value) => {
  return dispatch => {
    dispatch({
      type: "CHANGE_ARTICLE_BODY",
      value: value
    });
  };
};

Actions.changeArticleTags = (values) => {
  return dispatch => {
    dispatch({
      type: "CHANGE_ARTICLE_TAGS",
      values: values
    });
  };
};

Actions.onChangeArticlePermissionForAdministrator = (value) => {
  return dispatch => {
    dispatch({
      type: "CHANGE_ARTICLE_PERMISSION_FOR_ADMINISTRATOR",
      value: value
    });
  };
};

Actions.onChangeArticlePermissionForManager = (value) => {
  return dispatch => {
    dispatch({
      type: "CHANGE_ARTICLE_PERMISSION_FOR_MANAGER",
      value: value
    });
  };
};

Actions.onChangeArticlePermissionForEngineer = (value) => {
  return dispatch => {
    dispatch({
      type: "CHANGE_ARTICLE_PERMISSION_FOR_ENGINEER",
      value: value
    });
  };
};

Actions.onChangeArticlePermissionForSupervisor = (value) => {
  return dispatch => {
    dispatch({
      type: "CHANGE_ARTICLE_PERMISSION_FOR_SUPERVISOR",
      value: value
    });
  };
};

Actions.onChangeArticlePermissionForSimple = (value) => {
  return dispatch => {
    dispatch({
      type: "CHANGE_ARTICLE_PERMISSION_FOR_SIMPLE",
      value: value
    });
  };
};

Actions.updateArticle = (sectionId, id) => {
  return (dispatch, getState) => {
    dispatch({type: "PATCH_ARTICLE_REQUEST"});

    let article = getState().articles.article;
    let permissions = getState().articles.permissions;
    let contentTagIds = article.content_tags?.map((it) => it.id)

    let data = {
      article: {
        section_id: sectionId,
        title: article.title,
        body: article.body,
        published_at: article.published_at,
        article_permissions_attributes: permissions,
        content_tag_ids: contentTagIds
      }
    };

    api
      .patch(`/sections/${sectionId}/articles/${id}`, data)
      .then(response => {
        dispatch({type: "PATCH_ARTICLE_SUCCEED", response});
        api.openNotification("success", "Статья", "Успешно обновлена!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Статья", error);
          });
        }
        dispatch({type: "PATCH_ARTICLE_FAILURE", response});
      });
  };
};

Actions.deleteArticle = (sectionId, id) => {
  return dispatch => {
    dispatch({type: "DELETE_ARTICLE_REQUEST"});
    return api
      .delete(`/sections/${sectionId}/articles/${id}`)
      .then(response => {
        api.openNotification("success", "Статья", "Успешно удалена!");
        dispatch({type: "DELETE_ARTICLE", response});
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Статья", error);
          });
        }
        dispatch({type: "DELETE_ARTICLE_FAILURE"});
      });
  };
};

// Actions.onChangePage = value => {
//   return dispatch => {
//     dispatch({
//       type: "REQUEST_FAQ_CHANGE_PAGE",
//       value: value
//     });
//     return Promise.resolve();
//   };
// };
//
// Actions.onChangeLimit = value => {
//   return dispatch => {
//     dispatch({
//       type: "REQUEST_FAQ_CHANGE_LIMIT",
//       value: value
//     });
//     return Promise.resolve();
//   };
// };
//
// Actions.onSortBy = (field, direction) => {
//   return dispatch => {
//     dispatch({
//       type: "POSTS_SORT_BY",
//       field: field,
//       direction: direction
//     });
//     return Promise.resolve();
//   };
// };
//

//
//   Actions.onChangeBody = value => {
//     return dispatch => {
//       dispatch({
//         type: "FAQ_CHANGE_BODY",
//         value: value
//       });
//     };
//   };
//
//   Actions.onChangeTags = values => {
//     return dispatch => {
//       dispatch({
//         type: "FAQ_CHANGE_TAGS",
//         values: values
//       });
//     };
//   };
//
// Actions.onChangePublished = value => {
//   return dispatch => {
//     dispatch({
//       type: "FAQ_CHANGE_PUBLISHED",
//       value: value
//     });
//   };
// };
//
// Actions.onChangeApplicationType = value => {
//   return dispatch => {
//     dispatch({
//       type: "FAQ_CHANGE_APPLICATION_TYPE",
//       value: value
//     });
//   };
// };
//
//
// Actions.onChangeContex = value => {
//   return dispatch => {
//     dispatch({
//       type: "FAQ_CHANGE_CONTEX",
//       value: value
//     });
//   };
// };
//
// Actions.onChangeSubcontext = value => {
//     return dispatch => {
//       dispatch({
//         type: "FAQ_CHANGE_SUBCONTEX",
//         value: value
//       });
//     };
//   };


export default Actions;
