// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import Actions from "../../../actions/taskTemplates";
import TaskTemplatesList from "./List";

class TaskTemplates extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.fetchTaskTemplates());
  };

  onDelete = (ids) => {
    ids.map((id) => {
      this.props.dispatch(Actions.onDelete(id)).then(() => {
        this.props.dispatch(Actions.fetchTaskTemplates());
      });
    });
  };

  onChangePage = (value) => {
    this.props
      .dispatch(Actions.onChangePage(value))
      .then(() => this.props.dispatch(Actions.fetchTaskTemplates()));
  };

  onChangeLimit = (current, size) => {
    this.props
      .dispatch(Actions.onChangeLimit(size))
      .then(() => this.props.dispatch(Actions.fetchTaskTemplates()));
  };

  onSortBy = (field, direction) => {
    this.props
      .dispatch(Actions.onSortBy(field, direction))
      .then(() => this.props.dispatch(Actions.fetchTaskTemplates()));
  };

  // filters
  onClearFilters = () => {
    this.props
      .dispatch(Actions.onClearFilters())
      .then(() => this.props.dispatch(Actions.fetchTaskTemplates()));
  };

  onChangeFilterTaskOwner = (value) => {
    this.props
      .dispatch(Actions.onChangeFilterTaskOwner(value))
      .then(() => this.props.dispatch(Actions.fetchTaskTemplates()))
  };

  onChangeFilterTaskType = (value) => {
    this.props
      .dispatch(Actions.onChangeFilterTaskType(value))
      .then(() => this.props.dispatch(Actions.fetchTaskTemplates()))
  };

  render() {
    const {
      currentUser,
      isLoading,
      taskTemplates,
      meta,
      fetchParams,
    } = this.props;
    return (
      <TaskTemplatesList
        currentUser={currentUser}
        loading={isLoading}
        taskTemplates={taskTemplates}
        meta={meta}
        fetchParams={fetchParams}
        onDelete={this.onDelete}
        onSearch={this.onSearch}
        onChangePage={this.onChangePage}
        onChangeLimit={this.onChangeLimit}
        onSortBy={this.onSortBy}
        // filters
        onClearFilters={this.onClearFilters}
        onChangeFilterTaskOwner={this.onChangeFilterTaskOwner}
        onChangeFilterTaskType={this.onChangeFilterTaskType}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  isLoading: state.taskTemplates.isLoading,
  taskTemplates: state.taskTemplates.taskTemplates,
  meta: state.taskTemplates.meta,
  fetchParams: state.taskTemplates.fetchParams,
});

export default connect(mapStateToProps)(TaskTemplates);
