// @flow
import React, { Component, Fragment } from "react";
import { Row, Col, Button, Card, Statistic, Icon } from "antd";
import Line from "./Line";
import Pie from "./Pie";
import Cards from "./Cards";
import SelectMultiFetch from "../../../../components/SelectMultiFetch";

import moment from "moment";
import "moment/locale/ru";
import Days from "./Days";
import Bar from "./Bar";

class Tickets extends Component {
  render() {
    const {
      currentUser,
      fetchParams,
      isStatisticLoading,
      ticketSeries,
      isTicketsStatisticLoading,
      ticketsStatistic,
      ticketCustomers,
      onChangeTicketsCustomer,
    } = this.props;

    return (
      <React.Fragment>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Cards
              currentUser={currentUser}
              data={ticketsStatistic}
              startDate={fetchParams.startDate}
              endDate={fetchParams.endDate}
              ticketCustomers={ticketCustomers}
            />
            <Card
              title="Заявки"
              loading={isTicketsStatisticLoading}
              extra={
                <SelectMultiFetch
                  showSearch
                  placeholder="Клиент"
                  onChange={onChangeTicketsCustomer}
                  selected={ticketCustomers ? ticketCustomers : []}
                  url={"/customers/search/?"}
                  style={{ width: "400px" }}
                />
              }
            >
              <Pie data={ticketsStatistic} />
              {/* <Days
                loading={isStatisticLoading}
                data={ticketsStatistic}
                startDate={fetchParams.startDate}
                endDate={fetchParams.endDate}
              /> */}
            </Card>
          </Col>
        </Row>
        {/* <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Card title="Заявки" loading={isStatisticLoading}>
              <Bar />
            </Card>
          </Col>
        </Row> */}
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Card loading={isStatisticLoading}>
              <Line data={ticketSeries.length > 0 ? ticketSeries : []} />
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default Tickets;
