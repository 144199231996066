// @flow
import React, {Component} from "react";
import PropTypes from "prop-types";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {compose} from "redux";
import Actions from "../../../../actions/sections";
import {Button, Card, Col, List, PageHeader, Row, Spin} from "antd";
import Articles from "../Articles/List";
import Can from "../../../../rules/Can";

class Section extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.fetchSection(this.props.match.params.id));
    }
  };

  onClose = () => {
    this.props.history.push(`/knowledge_base`);
  };

  render() {
    const {
      currentUser,
      isLoading,
      section,
      permissions
    } = this.props;
    return isLoading ? (
      <Spin/>
    ) : (
      <>
        <PageHeader
          onBack={() => window.history.back()}
          title={`Раздел: ${section.title}`}

        />
        <Card
          title={
            <Can
              role={currentUser.role}
              perform={"articles:create"}
              yes={() =>
                <Link
                  key={1}
                  to={{pathname: `/sections/${section.id}/articles/new`}}
                  className={"ant-btn"}
                >
                  {"Новая статья"}
                </Link>
              }
              no={() => null}
            />
          }
          extra={[
            <Button key={"1"}
                    onClick={this.onClose}>
              Закрыть
            </Button>,
            <Can
              key={"2"}
              role={currentUser.role}
              perform={"sections:edit"}
              yes={() =>
                <Link
                  to={{pathname: `/sections/${section.id}/edit`}}
                  className={"ant-btn ant-btn-primary ant-btn-background-ghost"}
                  style={{marginLeft: "8px"}}
                >
                  Редактировать
                </Link>
              }
              no={() => null}
            />
          ]}
        />
        <Articles/>
      </>
    );
  }
}

Section.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  section: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  isLoading: state.sections.isLoading,
  errors: state.sections.errors,
  knowledgeBase: state.knowledgeBases.knowledgeBase,
  section: state.sections.section,
  permissions: state.sections.permissions,
});

export default compose(
  withRouter,
  connect(mapStateToProps)
)(Section);
