// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Avatar, Upload, Icon } from "antd";

class Title extends Component {
  
  onUpload = ({ onSuccess, onError, file }) => {
    this.props.onUploadLogo(file);
  };
  

  render() {
    const { isNew, isUploadingLogo, name, logo } = this.props;
    const uploadButton = (
      <Avatar size={64} icon="user" style={{ verticalAlign: "middle" }} />
    );

    return (
      <Row type="flex" justify="start" align="middle">
        <Upload
          name="logo"
          listType="picture"
          className="logo-uploader"
          showUploadList={false}
          customRequest={this.onUpload}
        >
          {logo ? (
            <Avatar
              size={64}
              icon="user"
              style={{ verticalAlign: "middle" }}
              src={logo}
            />
          ) : (
            isNew ? null : uploadButton
          )}
        </Upload>

        <h2
          style={{ verticalAlign: "middle", marginBottom: 0, paddingLeft: 10 }}
        >{`Клиент #${isNew ? "новый" : name}`}</h2>
      </Row>
    );
  }
}

Title.propTypes = {
  customer: PropTypes.object
};

export default Title;
