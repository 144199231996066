// @flow
import React, { Component } from "react";
import {
  Row,
  Col,
  Modal,
  Form,
  Input,
  InputNumber,
} from "antd";

import SelectFetch from "../../../../components/SelectFetch";

const FormItem = Form.Item;

class FieldSet extends React.Component {
  onSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onSave(values);
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      visible,
      onCancel,
      fieldSet,
      onChangeFieldSet,
      onChangePosition
    } = this.props;
    return (
      <Modal
        title={fieldSet.field_set ? fieldSet.field_set.name : "Новое атрибут"}
        destroyOnClose
        visible={visible}
        onOk={this.onSubmit}
        onCancel={onCancel}
      >
        <Form layout={"vertical"}>
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
            <FormItem label={"Атрибут"}>
                {getFieldDecorator("field_set", {
                  initialValue: fieldSet.field_set
                    ? fieldSet.field_set
                    : null,
                  rules: [
                    {
                      required: true,
                      message: "Пожалуйста введите, атрибут!"
                    }
                  ]
                })(
                  <SelectFetch
                    showSearch
                    showSubtitle
                    placeholder="Атрибут"
                    onChange={onChangeFieldSet}
                    selected={
                      fieldSet.field_set ? fieldSet.field_set : null
                    }
                    url={"/field_sets/search/?"}
                  />
                )}
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem label={"Позиция"}>
                {getFieldDecorator("position", {
                  onChange: onChangePosition,
                  initialValue: fieldSet.position,
                })(<InputNumber style={{width: "100%"}} />)}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(FieldSet);
