// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Tree, TreeNode } from "react-organizational-chart";
import PropTypes from "prop-types";
import { Row, Col, PageHeader, Card, List, Avatar } from "antd";
import UserInfo from "../../../components/UserInfo";
import Details from "./Details";

class DataList extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  departmentLeaf = (item = {}) => {
    const { onDetails, isLoadingDetails, details } = this.props;
    return (
      <TreeNode
        key={item.id}
        label={
          <Card
            size={"small"}
            title={item.name}
            bordered={true}
            style={{ minWidth: 200, minHeight: 200 }}
          >
            <Row>
              <Col span={24}>
              {/* <div className="ant-list-item">
                <List.Item.Meta
                  avatar={<Avatar src={item.assignee ? item.assignee.avatar : null} />}
                  title={<UserInfo title={item.assignee.name} userId={item.assignee.id} />}
                  description={item.assignee ? item.assignee.title : null}
                />
                </div> */}
              
                <div className="ant-list-item">
                  <div className="ant-list-item-meta">
                    <div className="ant-list-item-meta-avatar">
                      <Avatar
                        src={item.assignee ? item.assignee.avatar : null}
                      />
                    </div>
                    <div
                      className="ant-list-item-meta-content"
                      style={{ textAlign: "left" }}
                    >
                      <h4 className="ant-list-item-meta-title">
                        {item.assignee ? <UserInfo title={item.assignee.name} userId={item.assignee.id} /> : null}
                      </h4>
                      <div className="ant-list-item-meta-description">
                        {item.assignee ? item.assignee.title : null}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                Всего сотрудников:{" "}
                <a>
                  <Details
                    loading={isLoadingDetails}
                    value={item.employees ? item.employees : 0}
                    data={details}
                    onChange={onDetails.bind(this, item.id)}
                  />
                </a>
              </Col>
            </Row>
          </Card>
        }
      >
        {item.children.map((child) => {
          return this.departmentLeaf(child);
        })}
      </TreeNode>
    );
  };

  render() {
    const { height, isLoading, organisation, employees } = this.props;

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Структура организации`}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }} style={{ paddingBottom: 15 }}>
            <Col span={24} style={{ overflow: "auto", height: "80vh" }}>
              <Tree
                lineWidth={"2px"}
                lineColor={"#000000a6"}
                lineBorderRadius={"10px"}
                label={
                  <Card size={"small"} bordered={false}>
                    <p>{organisation ? organisation.name : null}</p>
                  </Card>
                }
              >
                {employees.map((item, i) => this.departmentLeaf(item))}
              </Tree>
            </Col>
          </Row>
        }
      ></PageHeader>
    );
  }
}

DataList.propTypes = {
  payments: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default DataList;
