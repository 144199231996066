// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table, Button, PageHeader, Tooltip } from "antd";
import { Pagination } from "antd";
import { Row, Col, Input } from "antd";

import PropTypes from "prop-types";

import moment from "moment";
import "moment/locale/ru";

import AssigneesCell from "../../../components/AssigneesCell";

const Search = Input.Search;

class SmsNotificationsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      isFilterVisible: false,
    };
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  onDelete = () => {
    this.props.onDelete(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  onChangeTable = (pagination, filters, sorter) => {
    let direction = "desc";
    if (sorter.order === "descend") {
      direction = "desc";
    } else if (sorter.order === "ascend") {
      direction = "asc";
    }
    this.props.onSortBy(sorter.field, direction);
  };

  render() {
    const { selectedRowKeys, isFilterVisible } = this.state;
    const {
      loading,
      smsNotifications,
      meta,
      fetchParams,
      onFetchStatus,
      onSearch,
      onChangePage,
      onChangeLimit,
      onSortBy,
    } = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: "#",
        dataIndex: "actions",
        render: (actions, record) =>
          record.sms_id ? (
            <Tooltip placement="bottom" title={"Проверить статус доставки"}>
              <Button
                icon={"play-circle"}
                type="primary"
                onClick={onFetchStatus.bind(this, record.id)}
              />
            </Tooltip>
          ) : null,
        width: "5%",
        align: "center",
      },
      {
        title: "#",
        dataIndex: "sms_id",
        width: "15%",
        align: "center",
      },
      {
        title: "Статус",
        dataIndex: "status",
        render: (status, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{status}</span>
            <span>{record.status_text}</span>
          </div>
        ),
        key: "status",
        align: "center",
        width: "10%",
      },
      {
        title: "Сервис",
        dataIndex: "provider",
        render: (provider, record) => (provider ? provider.name : null),
        key: "provider",
        width: "10%",
        align: "center",
      },
      {
        title: "Отправка",
        dataIndex: "created_at",
        render: (created_at) => moment(created_at).format("L"),
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "created_at",
        align: "center",
        width: "10%",
      },
      {
        title: "Доставка",
        dataIndex: "delivered_at",
        render: (delivered_at) =>
          delivered_at ? moment(delivered_at).format("L") : null,
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "delivered_at",
        align: "center",
        width: "10%",
      },
      {
        title: "Тема",
        dataIndex: "msg",
        render: (msg, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{msg}</span>
          </div>
        ),
        align: "left",
        width: "20%",
      },
      {
        title: "Получатель",
        dataIndex: "user",
        render: (user, record) => (
          <span style={{ opacity: "0.7" }}>
            {user ? user.name : record.member.name}
          </span>
        ),
        align: "center",
        width: "15%",
      },
    ];

    let mainButton = hasSelected ? (
      <Button key="1" type="danger" ghost onClick={this.onDelete}>
        Удалить
      </Button>
    ) : null;

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`SMS уведомления`}
        extra={[
          <Search
            allowClear
            key="99"
            placeholder="поиск..."
            value={fetchParams.search}
            onChange={onSearch}
            style={{ width: 250 }}
          />,
          mainButton,
        ]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Table
                bordered
                rowSelection={rowSelection}
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={smsNotifications}
                onChange={this.onChangeTable}
                rowKey="id"
              />
            </Col>
            <Col
              span={24}
              style={{
                paddingTop: "15px",
                paddingBottom: "15px",
                textAlign: "right",
              }}
            >
              <Pagination
                showSizeChanger
                defaultPageSize={fetchParams.limit}
                defaultCurrent={meta.current_page}
                onChange={onChangePage}
                onShowSizeChange={onChangeLimit}
                showTotal={(total) => `Всего: ${meta.total_count}`}
                total={meta.total_count}
              />
            </Col>
          </Row>
        }
      ></PageHeader>
    );
  }
}

SmsNotificationsList.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default SmsNotificationsList;
