import update from "immutability-helper";
import moment from "moment";
import "moment/locale/ru";

function getDefaultThisPeriodFrom(period) {
  if (period === null) {
    return null;
  }

  switch (period) {
    case 'day':
    case 'custom':
      return moment.utc().startOf('day');
    case 'week':
      return moment.utc().startOf('week');
    case 'month':
      return moment.utc().startOf('month');
    case 'quarter':
      return moment.utc().startOf('quarter');
    default:
      return null;
  }
}

function getDefaultThisPeriodTo(period) {
  if (period === null) {
    return null;
  }

  switch (period) {
    case 'day':
    case 'custom':
      return moment.utc().endOf('day');
    case 'week':
      return moment.utc().endOf('week');
    case 'month':
      return moment.utc().endOf('month');
    case 'quarter':
      return moment.utc().endOf('quarter');
    default:
      return null;
  }
}

function getDefaultThatPeriodFrom(period) {
  if (period === null) {
    return null;
  }

  switch (period) {
    case 'day':
    case 'custom':
      return moment.utc().add(-1, 'days').startOf('day');
    case 'week':
      return moment.utc().add(-1, 'weeks').startOf('week');
    case 'month':
      return moment.utc().add(-1, 'months').startOf('month');
    case 'quarter':
      return moment.utc().add(-1, 'quarters').startOf('quarter');
    default:
      return null;
  }
}

function getDefaultThatPeriodTo(period) {
  if (period === null) {
    return null;
  }

  switch (period) {
    case 'day':
    case 'custom':
      return moment.utc().add(-1, 'days').endOf('day');
    case 'week':
      return moment.utc().add(-1, 'weeks').endOf('week');
    case 'month':
      return moment.utc().add(-1, 'months').endOf('month');
    case 'quarter':
      return moment.utc().add(-1, 'quarters').endOf('quarter');
    default:
      return null;
  }
}

function getDefaultDateForStatDistributionAnalyzeFrom(period) {
  if (period === null) {
    return null;
  }

  switch (period) {
    case 'day':
    case 'custom':
      return moment.utc().startOf('day');
    case 'week':
      return moment.utc().startOf('week');
    case 'month':
      return moment.utc().startOf('month');
    case 'quarter':
      return moment.utc().startOf('quarter');
    default:
      return null;
  }
}

function getDefaultDateForStatDistributionAnalyzeTo(period) {
  if (period === null) {
    return null;
  }

  switch (period) {
    case 'day':
    case 'custom':
      return moment.utc().endOf('day');
    case 'week':
      return moment.utc().endOf('week');
    case 'month':
      return moment.utc().endOf('month');
    case 'quarter':
      return moment.utc().endOf('quarter');
    default:
      return null;
  }
}

const initialState = {
  ticketStatDiffFetchPeriodType: 'day',
  ticketStatDiffFetchPeriodDates: {
    thisPeriodFrom: getDefaultThisPeriodFrom('day'),
    thisPeriodTo: getDefaultThisPeriodTo('day'),
    thatPeriodFrom: getDefaultThatPeriodFrom('day'),
    thatPeriodTo: getDefaultThatPeriodTo('day'),
  },
  ticketStatDiffFetchFilters: {
    replacementType: null,
    customer: null,
    entity: null,
    services: [],
  },
  ticketStatDiffLoading: false,
  ticketStatDiff: {},

  ticketStatDistributionFetchResolution: 'day',
  ticketStatDistributionFetchDates: {
    analyzeFrom: moment.utc().add(-1, 'months').startOf('day'),
    analyzeTo: moment.utc().endOf('day'),
  },
  ticketStatDistributionFetchFilters: {
    replacementType: null,
    customer: null,
    entity: null,
    services: [],
  },
  ticketStatDistributionLoading: false,
  ticketStatDistribution: [],

  ticketsForStatTypeFetchStatType: null,
  ticketsForStatTypeFetchDateFrom: null,
  ticketsForStatTypeFetchDateTo: null,
  ticketsForStatTypeLoading: false,
  ticketsForStatType: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_TICKET_STAT_DIFF_FETCH_PERIOD_TYPE':
      return update(state, {
        ticketStatDiffFetchPeriodType: {
          $set: action.value
        },
        ticketStatDiffFetchPeriodDates: {
          thisPeriodFrom: {
            $set: getDefaultThisPeriodFrom(action.value)
          },
          thisPeriodTo: {
            $set: getDefaultThisPeriodTo(action.value)
          },
          thatPeriodFrom: {
            $set: getDefaultThatPeriodFrom(action.value)
          },
          thatPeriodTo: {
            $set: getDefaultThatPeriodTo(action.value)
          },
        },
      });
    case 'SET_TICKET_STAT_DIFF_THIS_FETCH_PERIOD_DATES':
      return update(state, {
        ticketStatDiffFetchPeriodDates: {
          thisPeriodFrom: {
            $set: action.periodFrom
          },
          thisPeriodTo: {
            $set: action.periodTo
          },
        },
      });
    case 'SET_TICKET_STAT_DIFF_THAT_FETCH_PERIOD_DATES':
      return update(state, {
        ticketStatDiffFetchPeriodDates: {
          thatPeriodFrom: {
            $set: action.periodFrom
          },
          thatPeriodTo: {
            $set: action.periodTo
          },
        },
      });
    case 'SET_TICKET_STAT_DIFF_FETCH_FILTER_REPLACEMENT_TYPE':
      return update(state, {
        ticketStatDiffFetchFilters: {
          replacementType: {
            $set: action.value
          },
        },
      });
    case 'SET_TICKET_STAT_DIFF_FETCH_FILTER_CUSTOMER':
      return update(state, {
        ticketStatDiffFetchFilters: {
          customer: {
            $set: action.value
          },
        },
      });
    case 'SET_TICKET_STAT_DIFF_FETCH_FILTER_ENTITY':
      return update(state, {
        ticketStatDiffFetchFilters: {
          entity: {
            $set: action.value
          },
        },
      });
    case 'SET_TICKET_STAT_DIFF_FETCH_FILTER_SERVICES':
      return update(state, {
        ticketStatDiffFetchFilters: {
          services: {
            $set: action.values
          },
        },
      });
    case 'FETCH_TICKET_STAT_DIFF_REQUEST':
      return {
        ...state,
        ticketStatDiffLoading: true,
      };
    case 'FETCH_TICKET_STAT_DIFF_SUCCESS':
      return {
        ...state,
        ticketStatDiffLoading: false,
        ticketStatDiff: action.response.data,
      };
    case 'FETCH_TICKET_STAT_DIFF_FAILURE':
      return {
        ...state,
        ticketStatDiffLoading: false,
      };

    case 'SET_TICKET_STAT_DISTRIBUTION_FETCH_RESOLUTION':
      return update(state, {
        ticketStatDistributionFetchResolution: {
          $set: action.value
        },
      });
    case 'SET_TICKET_STAT_DISTRIBUTION_FETCH_DATES':
      return update(state, {
        ticketStatDistributionFetchDates: {
          analyzeFrom: {
            $set: action.dateFrom
          },
          analyzeTo: {
            $set: action.dateTo
          },
        },
      });
    case 'FETCH_TICKET_STAT_DISTRIBUTION_REQUEST':
      return {
        ...state,
        ticketStatDistributionLoading: true,
      };
    case 'FETCH_TICKET_STAT_DISTRIBUTION_SUCCESS':
      return {
        ...state,
        ticketStatDistributionLoading: false,
        ticketStatDistribution: action.response.data,
      };
    case 'FETCH_TICKET_STAT_DISTRIBUTION_FAILURE':
      return {
        ...state,
        ticketStatDistributionLoading: false,
      };

    case 'SET_TICKETS_FOR_STAT_TYPE_FETCH_STAT_TYPE':
      return update(state, {
        ticketsForStatTypeFetchStatType: {
          $set: action.value
        },
      });
    case 'SET_TICKETS_FOR_STAT_TYPE_FETCH_DATES':
      return update(state, {
        ticketsForStatTypeFetchDateFrom: {
          $set: action.dateFrom
        },
        ticketsForStatTypeFetchDateTo: {
          $set: action.dateTo
        },
      });
    case 'FETCH_TICKETS_FOR_STAT_TYPE_REQUEST':
      return {
        ...state,
        ticketsForStatTypeLoading: true,
      };
    case 'FETCH_TICKETS_FOR_STAT_TYPE_SUCCESS':
      return {
        ...state,
        ticketsForStatTypeLoading: false,
        ticketsForStatType: action.response.data,
      };
    case 'FETCH_TICKETS_FOR_STAT_TYPE_FAILURE':
      return {
        ...state,
        ticketsForStatTypeLoading: false,
      };

    default:
      return state;
  }
}
