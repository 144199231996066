// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, Row, Col, Form, Checkbox } from "antd";
import "moment/locale/ru";

const FormItem = Form.Item;


class Sidebar extends Component {
  render() {
    const {
      getFieldDecorator,
      isActive,
      isRequired,
      onChangeIsActive,
      onChangeIsRequired
    } = this.props;

    return (
      <Card style={{ borderColor: "#1890ff" }}>
        <Row gutter={10}>
          <Col span={24}>
            <FormItem>
              {getFieldDecorator("isActive", {
                onChange: onChangeIsActive,
                valuePropName: "checked",
                initialValue: isActive,
              })(<Checkbox>Активен</Checkbox>)}
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem>
              {getFieldDecorator("isRequired", {
                onChange: onChangeIsRequired,
                valuePropName: "checked",
                initialValue: isRequired,
              })(<Checkbox>Требуется при заполнении</Checkbox>)}
            </FormItem>
          </Col>
        </Row>
      </Card>
    );
  }
}
Sidebar.propTypes = {
  isActive: PropTypes.bool,
  getFieldDecorator: PropTypes.func,
};

export default Sidebar;
