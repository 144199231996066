// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Drawer, Col, Row, DatePicker, Select, Button, Icon, Form } from "antd";
import moment from "moment";
import "moment/locale/ru";
import SelectMultiFetch from "../../../components/SelectMultiFetch";
import SelectFetch from "../../../components/SelectFetch";
import Can from "../../../rules/Can";

const { RangePicker } = DatePicker;
const { Option, OptGroup } = Select;
const FormItem = Form.Item;

class Filter extends Component {
  onChangeUsers = (values) => {
    this.props.onChangeUsers(values);
  };

  onChangeMembers = (values) => {
    this.props.onChangeMembers(values);
  };

  render() {
    const {
      currentUser,
      visible,
      status,
      orderTaskStatus,
      startDate,
      endDate,
      starFinish,
      endFinish,
      users,
      customer,
      members,
      services,
      isOrdered,
      onClose,
      onChangeStatus,
      onChangeOrderTaskStatus,
      onChangeOrdered,
      onChangePeriod,
      onChangePeriodFinish,
      onChangeServices,
      onChangeCustomer,
    } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Drawer
        width={540}
        visible={visible}
        title="Фильтры"
        placement={"right"}
        closable={false}
        onClose={onClose}
      >
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Период (создание)"}>
              {getFieldDecorator("start", {
                onChange: onChangePeriod,
                initialValue: [
                  startDate ? moment(startDate, "DD.MM.YYYY") : null,
                  endDate ? moment(endDate, "DD.MM.YYYY") : null,
                ],
              })(
                <RangePicker
                  showTime={false}
                  format={"DD.MM.YYYY"}
                  placeholder={["начало", "окончание"]}
                  style={{ width: "100%" }}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Период (принятия)"}>
              {getFieldDecorator("starFinish", {
                onChange: onChangePeriodFinish,
                initialValue: [
                  starFinish ? moment(starFinish, "DD.MM.YYYY") : null,
                  endFinish ? moment(endFinish, "DD.MM.YYYY") : null,
                ],
              })(
                <RangePicker
                  showTime={false}
                  format={"DD.MM.YYYY"}
                  placeholder={["начало", "окончание"]}
                  style={{ width: "100%" }}
                />
              )}
            </FormItem>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Статус по задаче"}>
              {getFieldDecorator("orderTaskStatus", {
                onChange: onChangeOrderTaskStatus,
                initialValue: orderTaskStatus,
              })(
                <Select
                  style={{ width: "100%" }}
                  placeholder="Статус по задаче"
                  optionFilterProp="children"
                  filterOption={false}
                >
                  <Option key={null} value={null}>
                    все
                  </Option>
                  <OptGroup label="статусы">
                    <Option key={"status"} value={"pending"}>
                      ожидание
                    </Option>
                    <Option key={"status"} value={"processing"}>
                      выполняется
                    </Option>
                    <Option key={"status"} value={"finished"}>
                      выполнено
                    </Option>
                    <Option key={"status"} value={"canceled"}>
                      отменено
                    </Option>
                  </OptGroup>
                  <OptGroup label="согласования КП">
                    <Option
                      key={"proposal_approving_status"}
                      value={"proposal_approving_pending"}
                    >
                      ожидание
                    </Option>
                    <Option
                      key={"proposal_approving_status"}
                      value={"proposal_approving_failed"}
                    >
                      не согласовано
                    </Option>
                    <Option
                      key={"proposal_approving_status"}
                      value={"proposal_approving_finished"}
                    >
                      согласовано
                    </Option>
                    <Option
                      key={"proposal_approving_status"}
                      value={"proposal_approving_canceled"}
                    >
                      отменено
                    </Option>
                  </OptGroup>
                  <OptGroup label="закупка оборудования">
                    <Option key={"purchase_status"} value={"purchase_pending"}>
                      ожидание
                    </Option>
                    <Option
                      key={"purchase_status"}
                      value={"purchase_processing"}
                    >
                      в закупке
                    </Option>
                    <Option
                      key={"purchase_status"}
                      value={"purchase_purchased"}
                    >
                      в наличии
                    </Option>
                    <Option key={"purchase_status"} value={"purchase_finished"}>
                      доставлено на объект
                    </Option>
                    <Option key={"purchase_status"} value={"purchase_canceled"}>
                      отменено
                    </Option>
                  </OptGroup>
                  <OptGroup label="принятие работ">
                    <Option
                      key={"job_approving_status"}
                      value={"job_approving_pending"}
                    >
                      ожидание
                    </Option>
                    <Option
                      key={"job_approving_status"}
                      value={"job_approving_accepted"}
                    >
                      принято
                    </Option>
                    <Option
                      key={"job_approving_status"}
                      value={"job_approving_canceled"}
                    >
                      отклонено с замечаниями
                    </Option>
                  </OptGroup>
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <FormItem label={"Статус"}>
              {getFieldDecorator("status", {
                onChange: onChangeStatus,
                initialValue: status ? status : [],
              })(
                <Select
                  mode={"multiple"}
                  style={{ width: "100%" }}
                  placeholder="Статус"
                  optionFilterProp="children"
                  filterOption={false}
                >
                  <Option value={"1"}>в работе</Option>
                  <Option value={"2"}>выполнен</Option>
                  <Option value={"3"}>отменен</Option>
                  <Option value={"4"}>приостановлен</Option>
                </Select>
              )}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <FormItem label={"Перенос из заявки"}>
              {getFieldDecorator("isOrdered", {
                onChange: onChangeOrdered,
                initialValue: isOrdered,
              })(
                <Select
                  style={{ width: "100%" }}
                  placeholder="Перенос из заявки"
                  optionFilterProp="children"
                  filterOption={false}
                >
                  <Option value={null}>все</Option>
                  <Option value={true}>да</Option>
                </Select>
              )}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Клиент"}>
              {getFieldDecorator("customer", {
                initialValue: customer ? customer : null,
              })(
                <SelectFetch
                  showSearch
                  placeholder="Клиент"
                  onChange={onChangeCustomer}
                  selected={customer ? customer : null}
                  url={"/customers/search/?"}
                />
              )}
            </FormItem>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Ответственные"}>
              {getFieldDecorator("users", {
                initialValue: users ? users : [],
              })(
                <SelectMultiFetch
                  showSearch
                  placeholder="Ответственные"
                  onChange={this.onChangeUsers}
                  selected={users ? users : []}
                  url={"/users/search/?all='true'"}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Ответственные клиента"}>
              {getFieldDecorator("members", {
                initialValue: members ? members : [],
              })(
                <SelectMultiFetch
                  showSearch
                  placeholder="Ответственные клиента"
                  onChange={this.onChangeMembers}
                  selected={members ? members : []}
                  url={"/members/search/?"}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Разделы"}>
              {getFieldDecorator("services", {
                initialValue: services ? services : [],
              })(
                <SelectMultiFetch
                  showSearch
                  placeholder="Разделы"
                  onChange={onChangeServices}
                  selected={services ? services : []}
                  url={`/services/search/?`}
                />
              )}
            </FormItem>
          </Col>
        </Row>
      </Drawer>
    );
  }
}

Filter.propTypes = {
  entity: PropTypes.object,
};

export default Form.create()(Filter);
