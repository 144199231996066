import update from "immutability-helper";
import _ from "lodash/array";
import moment from "moment";

const initialState = {
  entityTypes: [],
  entityType: {},
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1,
  },
  fetchParams: {
    status: null,
    search: null,
    page: 1,
    limit: 15,
  },
  errors: false,
  isLoading: false,
  isModalVisible: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "FETCH_ENTITY_TYPES_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_ENTITY_TYPES":
      return {
        ...state,
        entityTypes: action.response.data,
        meta: action.response.meta,
        isLoading: false,
      };
    case "FETCH_ENTITY_TYPE_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_ENTITY_TYPE":
      return {
        ...state,
        entityType: action.response.data,
        isLoading: false,
        isModalVisible: true,
      };
    case "PATCH_ENTITY_TYPE_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "PATCH_ENTITY_TYPE":
      return {
        ...state,
        entityType: action.response.data,
        isLoading: false,
        isModalVisible: false,
      };

    case "POST_ENTITY_TYPE_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "POST_ENTITY_TYPE":
      return {
        ...state,
        errors: false,
        entityType: {},
        isLoading: false,
        isModalVisible: false,
      };

    case "POST_ENTITY_TYPE_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "ENTITY_TYPE_ADD":
      return {
        ...state,
        entityType: {
          name: null,
        },
        errors: false,
        isLoading: false,
        isModalVisible: true,
      };

    case "DELETE_ENTITY_TYPE_REQUEST":
      return {
        ...state,
        isLoading: true,
      };

    case "DELETE_ENTITY_TYPE":
      return {
        ...state,
        isLoading: false,
      };
    case "DELETE_ENTITY_TYPE_FAILURE":
      return {
        ...state,
        isLoading: false,
      };
    case "REQUEST_ENTITY_TYPE_SEARCH":
      return update(state, {
        fetchParams: { search: { $set: action.value } },
      });

    case "REQUEST_ENTITY_TYPE_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } },
      });

    case "ENTITY_TYPE_TOGGLE_VISIBLE":
      return {
        ...state,
        isModalVisible: !state.isModalVisible,
      };

    case "ENTITY_TYPE_CHANGE_NAME":
      return update(state, {
        entityType: {
          name: { $set: action.value },
        },
      });

      case "ENTITY_TYPE_CHANGE_ICON":
      return update(state, {
        entityType: {
          icon: { $set: action.value },
        },
      });

    default:
      return state;
  }
}
