// @flow
import React, { Component, Fragment } from "react";
import { Row, Col, Table } from "antd";
import Details from "./Details";

class Days extends Component {
  render() {
    const { loading, startDate, endDate, data } = this.props;

    const dataSource = [
      {
        key: "1",
        name: (
          <Details
            title="до 3-х дней"
            type={"threeDays"}
            startDate={startDate}
            endDate={endDate}
          />
        ),
        qty: data.three_days > 0 ? Number(data.three_days) : 0,
      },
      {
        key: "2",
        name: (
          <Details
            title="свыше 3-х дней"
            type={"sevenDays"}
            startDate={startDate}
            endDate={endDate}
          />
        ),
        qty: data.seven_days > 0 ? Number(data.seven_days) : 0,
      },
      {
        key: "2",
        name: (
          <Details
            title="свыше 7-и дней"
            type={"restDays"}
            startDate={startDate}
            endDate={endDate}
          />
        ),
        qty: data.rest_days > 0 ? Number(data.rest_days) : 0,
      },
    ];

    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Age",
        dataIndex: "qty",
        key: "qty",
        align: "center",
      },
    ];

    return (
      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
        <Table
          loading={loading}
          bordered
          showHeader={false}
          dataSource={dataSource}
          columns={columns}
          pagination={false}
        />
      </Col>
    );
  }
}

export default Days;
