// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Row, Col, Button, List, Avatar, Tag, Icon, Progress } from "antd";
import update from "immutability-helper";

import moment from "moment";
import "moment/locale/ru";

import TaskModal from "./TaskModal";

function progress(taskType, currentStatus, percent) {
  switch (taskType) {
    case "examination":
      return currentStatus == "canceled" ? (
        <Progress type="circle" percent={100} status="exception" width={50} />
      ) : (
        <Progress type="circle" percent={percent} width={50} />
      );
    case "proposal_processing":
      return currentStatus == "canceled" ? (
        <Progress type="circle" percent={100} status="exception" width={50} />
      ) : (
        <Progress type="circle" percent={percent} width={50} />
      );
    case "proposal_approving":
      return currentStatus == "proposal_approving_failed" ? (
        <Progress type="circle" percent={100} status="exception" width={50} />
      ) : (
        <Progress type="circle" percent={percent} width={50} />
      );
    case "purchasing":
      return currentStatus == "purchase_canceled" ? (
        <Progress type="circle" percent={100} status="exception" width={50} />
      ) : (
        <Progress type="circle" percent={percent} width={50} />
      );
    case "job_processing":
      return currentStatus == "canceled" ? (
        <Progress type="circle" percent={100} status="exception" width={50} />
      ) : (
        <Progress type="circle" percent={percent} width={50} />
      );
    case "info_systems_altering":
      return currentStatus == "info_systems_altering_not_required" ? (
        <Progress type="circle" percent={100} status="exception" width={50} />
      ) : (
        <Progress type="circle" percent={percent} width={50} />
      );
    case "job_approving":
      return currentStatus == "job_approving_canceled" ? (
        <Progress type="circle" percent={100} status="exception" width={50} />
      ) : (
        <Progress type="circle" percent={percent} width={50} />
      );
    default:
      return <Progress type="circle" percent={percent} width={50} />;
  }
}

class ListTasks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      isVisible: false,
      task: { assignees: [] },
    };
  }

  onOpenTaskModal = (item) => {
    this.setState({ entityService: item }, () => {
      this.setState({ isVisible: true });
    });
  };

  onAddTask = () => {
    this.setState(
      {
        task: {
          id: Date.now(),
          task_type: 1,
          subject: null,
          description: null,
          due_from: moment(),
          due_by: moment(),
          plan_days: 0,
          assignees: [],
        },
      },
      () => {
        this.setState({ isVisible: true });
      }
    );
  };

  onCreateTask = () => {
    this.setState({ isVisible: false }, () => {
      this.props.onCreateOrderTask(this.state.task);
    });
  };

  onCancel = () => {
    this.setState({ isVisible: false });
  };

  onChangeTaskType = (value) => {
    this.setState({
      task: update(this.state.task, {
        task_type: { $set: value },
      }),
    });
  };

  onChangeSubject = (e) => {
    this.setState({
      task: update(this.state.task, {
        subject: { $set: e.target.value },
      }),
    });
  };

  onChangeDescription = (e) => {
    this.setState({
      task: update(this.state.task, {
        description: { $set: e.target.value },
      }),
    });
  };

  onChangeDueFrom = (value) => {
    this.setState({
      task: update(this.state.task, {
        due_from: { $set: value },
      }),
    });
  };

  onChangeDueBy = (value) => {
    this.setState({
      task: update(this.state.task, {
        due_by: { $set: value },
      }),
    });
  };

  onChangePlanDays = (value) => {
    this.setState({
      task: update(this.state.task, {
        plan_days: { $set: value },
      }),
    });
  };

  // assignees
  onAddAssignee = (value) => {
    this.setState({
      task: update(this.state.task, {
        assignees: { $push: [value] },
      }),
    });
  };

  onDeleteAssignee = (idx) => {
    this.setState({
      task: update(this.state.task, {
        assignees: { $splice: [[idx, 1]] },
      }),
    });
  };

  render() {
    const { selectedRowKeys, isVisible, task } = this.state;
    const { tasks, isTasksLoading, company, isOrganisation, isSupplier } =
      this.props;

    const IconText = ({ type, text }) => (
      <span>
        <Icon type={type} style={{ marginRight: 8 }} />
        {text}
      </span>
    );

    return (
      <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
        <Col span={24}>
          <List
            bordered
            itemLayout="horizontal"
            dataSource={tasks}
            header={<Button onClick={this.onAddTask}>Добавить</Button>}
            renderItem={(item) => (
              <List.Item
                key={item.id}
                extra={[
                  <div
                    key={1}
                    style={{
                      width: 180,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ color: "#3c95de" }}>
                      {item.start
                        ? moment(item.start).format("LLL")
                        : "не начато"}
                    </span>
                    <span>{<Icon type="arrow-down" />}</span>
                    <span style={{ color: "#3c95de" }}>
                      {item.finish
                        ? moment(item.finish).format("LLL")
                        : "не закончено"}
                    </span>
                  </div>,
                ]}
              >
                <List.Item.Meta
                  avatar={progress(
                    item.task_type,
                    item.status.id,
                    Number(
                      (
                        (item.progress.current / item.progress.steps) *
                        100
                      ).toFixed(0)
                    )
                  )}
                  title={
                    <Link to={`/order_tasks/${item.id}/edit`}>
                      {item.subject}
                    </Link>
                  }
                  description={<Tag>{item.task_type_locale}</Tag>}
                />
              </List.Item>
            )}
          />
          <TaskModal
            visible={isVisible}
            task={task}
            company={company}
            isOrganisation={isOrganisation}
            isSupplier={isSupplier}
            onSave={this.onCreateTask}
            onCancel={this.onCancel}
            onChangeTaskType={this.onChangeTaskType}
            onChangeSubject={this.onChangeSubject}
            onChangeDescription={this.onChangeDescription}
            onChangeDueFrom={this.onChangeDueFrom}
            onChangeDueBy={this.onChangeDueBy}
            onChangePlanDays={this.onChangePlanDays}
            onAddAssignee={this.onAddAssignee}
            onDeleteAssignee={this.onDeleteAssignee}
          />
        </Col>
      </Row>
    );
  }
}

ListTasks.propTypes = {
  orderTasks: PropTypes.arrayOf(PropTypes.object),
  total: PropTypes.number,
  discount: PropTypes.object,
  shippingCost: PropTypes.number,
  amountDiscountCode: PropTypes.number,
  onRemoveVariant: PropTypes.func,
};
export default ListTasks;
