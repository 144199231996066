// @flow
import React, {Component} from "react";
import {Link, withRouter} from "react-router-dom";
import {Badge, Button, Col, Icon, Input, PageHeader, Pagination, Row, Table, Tag, Tooltip, Typography,} from "antd";

import PropTypes from "prop-types";
import AssigneesCell from "../../../components/AssigneesCell";
import OrderProgress from "../../../components/OrderProgress";
import accounting from "accounting";
import NoticeIcon from "../../../components/NoticeIcon";

import moment from "moment";
import "moment/locale/ru";
import Filter from "./Filter";
import Can from "../../../rules/Can";

const Search = Input.Search;
const {Paragraph} = Typography;

function serviceTag(entity_service) {
  return (
    <Tag key={entity_service.id} color="#108ee9">
      {entity_service.name}
    </Tag>
  );
}

class OrdersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      selectedTotalAmount: 0,
      orderProgress: false,
      isFilterVisible: false,
    };
  }

  onFilterToggle = () => {
    this.setState({isFilterVisible: !this.state.isFilterVisible});
  };

  onSelectChange = (selectedRowKeys, records) => {
    const selectedTotalAmount = records.reduce(
      (sum, record) => sum + Number(record.amount),
      0
    );
    // console.log(selectedRowKeys, e)
    this.setState({selectedRowKeys, selectedTotalAmount});
  };

  onDelete = () => {
    this.props.onDelete(this.state.selectedRowKeys);
    this.setState({selectedRowKeys: []});
  };

  onChangeTable = (pagination, filters, sorter) => {
    let direction = "desc";
    if (sorter.order === "descend") {
      direction = "desc";
    } else if (sorter.order === "ascend") {
      direction = "asc";
    }
    this.props.onSortBy(sorter.field, direction);
  };

  onOrderProgressChange = (id, visible) => {
    if (visible) {
      this.props.onOrderProgress(id);
    }
  };

  onItemClick = (item) => {
    this.props.history.push(`/orders/${item.parent_id}/edit`);
  };

  onClearNotices = () => {
    this.props.onClearNotices();
  };

  render() {
    const {
      currentUser,
      loading,
      orders,
      meta,
      fetchParams,
      notices,
      onSearch,
      onChangePage,
      onChangeLimit,
      onSortBy,
      // filters
      onClearFilters,
      onChangeFilterStatus,
      onChangeFilterOrderTaskStatus,
      onChangeFilterOrdered,
      onChangeFilterPeriod,
      onChangeFilterPeriodFinish,
      onChangeFilterUsers,
      onChangeFilterCustomer,
      onChangeFilterMembers,
      onChangeFilterServices,
      downloadReport,
    } = this.props;

    const {
      selectedRowKeys,
      selectedTotalAmount,
      isFilterVisible,
    } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: "Выполнение",
        dataIndex: "status",
        render: (status, record) => (
          <div style={{display: "flex", flexDirection: "column"}}>
            <span>
              <OrderProgress orderId={record.id} progress={record.progress}/>
            </span>
            <span style={{opacity: "0.7"}}>{record.status_locale}</span>
          </div>
        ),
        key: "status",
        align: "center",
        width: "10%",
      },
      {
        title: "Номер / Номер КП",
        dataIndex: "number",
        render: (number, record) => (
          <div style={{display: "flex", flexDirection: "column"}}>
            <span>
              <Link to={`/orders/${record.id}/edit`}>{number}</Link>
            </span>
            <span style={{opacity: "0.7"}}>{record.number_int}</span>
          </div>
        ),
        key: "number",
        align: "center",
        width: "10%",
      },
      {
        title: "Дата создания",
        dataIndex: "created_at",
        render: (created_at) => (
          <span style={{fontSize: 12}}>{moment(created_at).format("L")}</span>
        ),
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "created_at",
        align: "center",
        width: "10%",
      },
      {
        title: "Дата принятия",
        dataIndex: "finish",
        render: (finish) =>
          finish ? (
            <span style={{fontSize: 12}}>{moment(finish).format("LLL")}</span>
          ) : null,
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "finish",
        align: "center",
        width: "10%",
      },
      {
        title: "Сумма",
        dataIndex: "amount",
        render: (amount) => accounting.formatNumber(amount, 0, " "),
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "amount",
        align: "center",
        width: "10%",
      },
      {
        title: "Клиент / Объект",
        dataIndex: "customer",
        render: (customer, record) => (
          <div style={{display: "flex", flexDirection: "column"}}>
            <span>
              <Link
                to={customer ? `/customers/${customer.id}/edit` : `/companies/${record.company.id}/edit`}>{customer ? customer.name : record.company.name}</Link>
            </span>
            <span style={{opacity: "0.7"}}>
              {record.entity ? record.entity.name : null}
            </span>
            <span style={{opacity: "0.7"}}>
              {record.entity ? record.entity.address : null}
            </span>
          </div>
        ),
        align: "center",
        width: "15%",
      },
      {
        title: "Тема / Раздел",
        dataIndex: "subject",
        render: (subject, record) => (
          <div style={{display: "flex", flexDirection: "column"}}>
            <span>
              <Paragraph ellipsis={{rows: 3, expandable: true}}>
                {subject}
              </Paragraph>
            </span>
            <span>
              {serviceTag(record.entity_service)}
              {/* {record.entity_service ? record.entity_service.name : null} */}
            </span>
          </div>
        ),
        align: "left",
        width: "15%",
      },
      {
        title: "Ответственный",
        dataIndex: "assignees",
        render: (assignees, record) => <AssigneesCell assignees={assignees}/>,
        align: "center",
        width: "15%",
      },
      {
        title: "",
        dataIndex: "unread_comment_count",
        render: (unread_comment_count, record) => (
          <Badge count={unread_comment_count}
                 title={'Непрочитанные комментарии'}
                 style={{boxShadow: "none"}}/>
        ),
        align: "center",
        width: "5%",
      },
    ];

    const faqButton = (
      <Button
        type={"ghost"}
        key="7"
        icon="question"
        onClick={this.props.onFaqToggle}
      />
    );

    let mainButton = hasSelected ? (
      <Button key="2" type="danger" ghost onClick={this.onDelete}>
        Удалить
      </Button>
    ) : (
      <Link
        key="3"
        className={"ant-btn ant-btn-primary ant-btn-background-ghost"}
        to={{pathname: `/orders/new`}}
      >
        Создать
      </Link>
    );

    let excelButton = (
      <Can
        role={currentUser.role}
        perform="orders:finance"
        yes={() => (
          <Tooltip placement="bottom" title={"Выгрузить в Excel"}>
            <Button key="excelButton" type="dashed" onClick={downloadReport}>
              <Icon type="file-excel" style={{color: "green"}}/>
              Выгрузить
            </Button>
          </Tooltip>
        )}
        no={() => null}
      />
    );

    let filterButton = (
      <Button
        type={
          fetchParams.startDate ||
          fetchParams.status ||
          fetchParams.users ||
          fetchParams.categories ||
          fetchParams.customer ||
          fetchParams.members
            ? "primary"
            : "ghost"
        }
        key="4"
        icon="filter"
        onClick={this.onFilterToggle}
      />
    );

    const unread = notices.filter(function (item) {
      return item.read == false;
    });

    const noticeIcon = (
      <NoticeIcon
        key="5"
        count={unread.length}
        onItemClick={this.onItemClick}
        list={notices}
        showClear
        onClear={this.onClearNotices}
        emptyText={"новых комментариев нет"}
      />
    );


    let totalPx = meta.total_price;

    let totalProductPx = meta.total_product_price;

    let totalServicePx = meta.total_service_price;


    let selected = orders.filter((order) => selectedRowKeys.includes(order.id))

    let selectedPx = selected
      .map((order) => order.amount)
      .reduce((result, amount) => result + amount, 0);

    let selectedProductPx = selected
      .filter((order) => selectedRowKeys.includes(order.id))
      .map((order) => order.product_price)
      .reduce((result, amount) => result + amount, 0);

    let selectedServicePx = selected
      .filter((order) => selectedRowKeys.includes(order.id))
      .map((order) => order.service_price)
      .reduce((result, amount) => result + amount, 0);


    let totalInfo = '' +
      `Всего: ${meta.total_count}, ` +
      `Сумма: ${accounting.formatNumber(totalPx, 0, " ")} ` +
      `(товар: ${accounting.formatNumber(totalProductPx, 0, " ")}; услуги: ${accounting.formatNumber(totalServicePx, 0, " ")})`;

    const selectedInfo = hasSelected ?
      `Выбрано: ${selectedRowKeys.length}, ` +
      `Сумма: ${accounting.formatNumber(selectedPx, 0, " ")} ` +
      `(товар: ${accounting.formatNumber(selectedProductPx, 0, " ")}; услуги: ${accounting.formatNumber(selectedServicePx, 0, " ")})` :
      "";

    let info = `${totalInfo}, ${(hasSelected ? selectedInfo : "")}`;

    return (
      <PageHeader
        className="page-header-with-notices"
        onBack={() => window.history.back()}
        title={`Заказы`}
        subTitle={
          fetchParams.startDate && fetchParams.endDate
            ? `c ${fetchParams.startDate} по ${fetchParams.endDate}`
            : null
        }
        extra={[
          faqButton,
          noticeIcon,
          <Search
            allowClear
            key="1"
            placeholder="поиск..."
            value={fetchParams.search}
            onChange={onSearch}
            style={{width: 250}}
          />,
          mainButton,
          excelButton,
          filterButton,
        ]}
        footer={
          <Row gutter={{md: 8, lg: 24, xl: 48}}>
            <Col span={24}>
              <Table
                // bordered
                rowSelection={rowSelection}
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={orders}
                onChange={this.onChangeTable}
                rowKey="id"
              />
            </Col>
            <Col
              span={24}
              style={{
                paddingTop: "15px",
                paddingBottom: "15px",
                textAlign: "right",
              }}
            >
              <Pagination
                showSizeChanger
                pageSize={fetchParams.limit}
                current={meta.current_page}
                onChange={onChangePage}
                onShowSizeChange={onChangeLimit}
                showTotal={(total) => info}
                total={meta.total_count}
              />
            </Col>
          </Row>
        }
      >
        <Filter
          currentUser={currentUser}
          visible={isFilterVisible}
          status={fetchParams.status}
          orderTaskStatus={fetchParams.orderTaskStatus}
          startDate={fetchParams.startDate}
          endDate={fetchParams.endDate}
          starFinish={fetchParams.starFinish}
          endFinish={fetchParams.endFinish}
          users={fetchParams.users}
          customer={fetchParams.customer}
          members={fetchParams.members}
          services={fetchParams.services}
          isOrdered={fetchParams.isOrdered}
          onClose={this.onFilterToggle}
          onClearFilters={onClearFilters}
          onChangeStatus={onChangeFilterStatus}
          onChangeOrderTaskStatus={onChangeFilterOrderTaskStatus}
          onChangeOrdered={onChangeFilterOrdered}
          onChangePeriod={onChangeFilterPeriod}
          onChangePeriodFinish={onChangeFilterPeriodFinish}
          onChangeUsers={onChangeFilterUsers}
          onChangeCustomer={onChangeFilterCustomer}
          onChangeMembers={onChangeFilterMembers}
          onChangeServices={onChangeFilterServices}
          downloadReport={downloadReport}
        />
      </PageHeader>
    );
  }
}

OrdersList.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default withRouter(OrdersList);
