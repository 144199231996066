import api from "../api";
const Actions = {};

//posts
Actions.fetchPosts = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_POSTS_REQUEST" });
    api
      .fetch("/dashboard/posts")
      .then((response) => {
        dispatch({ type: "FETCH_POSTS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_POSTS_FAILURE", response });
      });
  };
};

Actions.fetchPost = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_POST_REQUEST" });
    api
      .fetch(`/posts/${id}`)
      .then((response) => {
        dispatch({ type: "FETCH_POST", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_POST_FAILURE", response });
      });
  };
};

// calendar
Actions.fetchCalendarEvents = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_CALENDAR_EVENTS_REQUEST" });
    const params = getState().dashboard.fetchParams;
    api
      .fetch("/dashboard/calendar_events", params)
      .then((response) => {
        dispatch({ type: "FETCH_CALENDAR_EVENTS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_CALENDAR_EVENTS_FAILURE", response });
      });
  };
};

// calendar
Actions.fetchGanttEvents = (params) => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_GANTT_EVENTS_REQUEST" });
    api
      .fetch("/dashboard/gantt_events", params)
      .then((response) => {
        dispatch({ type: "FETCH_GANTT_EVENTS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_GANTT_EVENTS_FAILURE", response });
      });
  };
};

Actions.fetchEvent = (id, eventType) => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_EVENT_REQUEST" });
    api
      .fetch(`/${eventType}/${id}/event`)
      .then((response) => {
        dispatch({ type: "FETCH_EVENT", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_EVENT_FAILURE", response });
      });
  };
};

Actions.onChangeDate = (startDate, endDate) => {
  return (dispatch) => {
    dispatch({
      type: "DASHBOARD_CHANGE_DATE",
      startDate: startDate,
      endDate: endDate,
    });
    return Promise.resolve();
  };
};

Actions.onChangeEventType = (value) => {
  return (dispatch) => {
    dispatch({
      type: "DASHBOARD_CHANGE_EVENT_TYPE",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeUsers = (values) => {
  return (dispatch) => {
    dispatch({
      type: "DASHBOARD_CHANGE_USERS",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.onChangeTaskTypes = (values) => {
  return (dispatch) => {
    dispatch({
      type: "DASHBOARD_CHANGE_TASK_TYPES",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.onChangeTaskStates = (values) => {
  return (dispatch) => {
    dispatch({
      type: "DASHBOARD_CHANGE_TASK_STATES",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.onChangeCalendarTaskAssigneeType = (value) => {
  return (dispatch) => {
    dispatch({
      type: "DASHBOARD_CALENDAR_CHANGE_TASK_ASSIGNEE_TYPE",
      value: value,
    });
    return Promise.resolve();
  };
};

// statistic
Actions.fetchTasks = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_DASHBOARD_TASKS_REQUEST" });
    let params = getState().dashboard.fetchTasksParams;

    let data = {
      startDate: params.startDate,
      endDate: params.endDate,
      startCreatedDate: params.startCreatedDate,
      endCreatedDate: params.endCreatedDate,
      startDueByDate: params.startDueByDate,
      endDueByDate: params.endDueByDate,
      assigneeType: params.assigneeType,
      userIds: params.users?.map((it) => it?.id),
      page: params.page,
      limit: params.limit,
      sort: params.sort,
      direction: params.direction,
    };

    return api
      .fetch("/dashboard/tasks", data)
      .then((response) => {
        dispatch({ type: "FETCH_DASHBOARD_TASKS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_DASHBOARD_TASKS_FAILURE", response });
      });
  };
};

Actions.setDashboardTaskFilters = () => {
  return (dispatch) => {
    dispatch({
      type: "SET_DASHBOARD_TASK_FILTERS",
    });
    return Promise.resolve();
  };
};

Actions.changeDashboardTaskFilterCreatedPeriod = (startDate, endDate) => {
  return (dispatch) => {
    dispatch({
      type: "CHANGE_DASHBOARD_TASK_FILTER_CREATED_PERIOD",
      startDate: startDate,
      endDate: endDate,
    });
    return Promise.resolve();
  };
};

Actions.changeDashboardTaskFilterDueByPeriod = (startDate, endDate) => {
  return (dispatch) => {
    dispatch({
      type: "CHANGE_DASHBOARD_TASK_FILTER_DUE_BY_PERIOD",
      startDate: startDate,
      endDate: endDate,
    });
    return Promise.resolve();
  };
};

Actions.changeDashboardTaskFilterUsers = (values) => {
  return (dispatch) => {
    dispatch({
      type: "CHANGE_DASHBOARD_TASK_FILTER_USERS",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.fetchStatistic = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_DASHBOARD_STATISTIC_REQUEST" });
    let departments = [];
    const params = getState().dashboard.fetchStatisticParams;
    if (params.departments) {
      departments = params.departments.map((department) => {
        if (department && department.id) {
          return department.id;
        }
      });
    }

    const data = {
      startDate: params.startDate,
      endDate: params.endDate,
      departments: departments,
    };

    return api
      .fetch("/dashboard/statistic", data)
      .then((response) => {
        dispatch({ type: "FETCH_DASHBOARD_STATISTIC", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_DASHBOARD_STATISTIC_FAILURE", response });
      });
  };
};

Actions.fetchOrders = (orderCustomers) => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_DASHBOARD_ORDERS_STATISTIC_REQUEST" });
    let departments = [];
    let customers = [];
    const params = getState().dashboard.fetchStatisticParams;
    if (params.departments) {
      departments = params.departments.map((department) => {
        if (department && department.id) {
          return department.id;
        }
      });
    }

    if (orderCustomers) {
      customers = orderCustomers.map((customer) => {
        if (customer && customer.id) {
          return customer.id;
        }
      });
    }

    const data = {
      startDate: params.startDate,
      endDate: params.endDate,
      departments: departments,
      customers: customers
    };

    return api
      .fetch("/dashboard/orders_statistic", data)
      .then((response) => {
        dispatch({ type: "FETCH_DASHBOARD_ORDERS_STATISTIC", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_DASHBOARD_ORDERS_STATISTIC_FAILURE", response });
      });
  };
};

Actions.ticketsStatistic = (ticketCustomers) => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_DASHBOARD_TICKETS_STATISTIC_REQUEST" });
    let departments = [];
    let customers = [];
    const params = getState().dashboard.fetchStatisticParams;
    if (params.departments) {
      departments = params.departments.map((department) => {
        if (department && department.id) {
          return department.id;
        }
      });
    }

    if (ticketCustomers) {
      customers = ticketCustomers.map((customer) => {
        if (customer && customer.id) {
          return customer.id;
        }
      });
    }

    const data = {
      startDate: params.startDate,
      endDate: params.endDate,
      departments: departments,
      customers: customers
    };

    return api
      .fetch("/dashboard/tickets_statistic", data)
      .then((response) => {
        dispatch({ type: "FETCH_DASHBOARD_TICKETS_STATISTIC", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_DASHBOARD_TICKETS_STATISTIC_FAILURE", response });
      });
  };
};

Actions.ticketsBarSeries = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_DASHBOARD_TICKETS_BAR_SERIES_REQUEST" });
    let departments = [];
    const params = getState().dashboard.fetchStatisticParams;
    if (params.departments) {
      departments = params.departments.map((department) => {
        if (department && department.id) {
          return department.id;
        }
      });
    }

    const data = {
      startDate: params.startDate,
      endDate: params.endDate,
      departments: departments,
    };

    return api
      .fetch("/dashboard/tickets_bar_series", data)
      .then((response) => {
        dispatch({ type: "FETCH_DASHBOARD_TICKETS_BAR_SERIES", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_DASHBOARD_TICKETS_BAR_SERIES_FAILURE", response });
      });
  };
};

Actions.fetchIntimeStatistic = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_DASHBOARD_INTIME_STATISTIC_REQUEST" });
    let departments = [];
    const params = getState().dashboard.fetchStatisticParams;
    if (params.departments) {
      departments = params.departments.map((department) => {
        if (department && department.id) {
          return department.id;
        }
      });
    }

    const data = {
      startDate: params.startDate,
      endDate: params.endDate,
      departments: departments,
    };

    return api
      .fetch("/dashboard/intime_statistic", data)
      .then((response) => {
        dispatch({ type: "FETCH_DASHBOARD_INTIME_STATISTIC", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_DASHBOARD_INTIME_STATISTIC_FAILURE", response });
      });
  };
};

Actions.onChangeStatisticDateRange = (startDate, endDate) => {
  return (dispatch) => {
    dispatch({
      type: "DASHBOARD_CHANGE_STATISTIC_DATE_RANGE",
      startDate: startDate,
      endDate: endDate,
    });
    return Promise.resolve();
  };
};

Actions.onChangeStatisticDepartments = (values) => {
  return (dispatch) => {
    dispatch({
      type: "DASHBOARD_CHANGE_STATISTIC_DEPARTMENTS",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.onChangeTasksAssigneeType = (value) => {
  return (dispatch) => {
    dispatch({
      type: "DASHBOARD_CHANGE_TASKS_ASSIGNEE_TYPE",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onDrawerToggle = () => {
  return (dispatch) => {
    dispatch({ type: "DRAWER_TOGGLE" });
    return Promise.resolve();
  };
};

export default Actions;
