// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  DatePicker,
  Select
} from "antd";

import { Row, Col, PageHeader } from "antd";
import _ from "lodash/array";

import PropTypes from "prop-types";
import accounting from "accounting";

import moment from "moment";
import "moment/locale/ru";

const { RangePicker } = DatePicker;
const Option = Select.Option;

class DataList extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  render() {
    const {
      loading,
      employees,
      fetchParams,
      period,
      onChangeDateRange,
      onChangePeriod
    } = this.props;

    // render: (effectiveness, record) => `${Number(record.closed / record.total * 100).toFixed(2)}%`,

    const dateFormat = "DD.MM.YYYY";

    const columns = [
      {
        title: "Имя",
        dataIndex: "name",
        align: "left",
        width: "25%",
      },
      {
        title: "Часов в периоде",
        dataIndex: "hours",
        align: "center",
        width: "15%",
      },
      {
        title: "На заявки без замены",
        dataIndex: "tickets_hours",
        render: (tickets_hours) =>
          accounting.formatNumber(tickets_hours, 0, " "),
        align: "center",
        width: "15%",
      },
      {
        title: "На заявки с заменой",
        dataIndex: "tickets_replaced_hours",
        render: (tickets_replaced_hours) =>
          accounting.formatNumber(tickets_replaced_hours, 0, " "),
        align: "center",
        width: "15%",
      },
      {
        title: "На регламент",
        dataIndex: "entity_tasks_hours",
        render: (entity_tasks_hours) =>
          accounting.formatNumber(entity_tasks_hours, 0, " "),
        align: "center",
        width: "15%",
      },
      {
        title: "Коэффициент занятости",
        dataIndex: "ratio",
        render: (ratio, record) =>
          accounting.formatNumber( (Number(record.tickets_hours) + Number(record.tickets_replaced_hours) + Number(record.entity_tasks_hours)) / Number(record.hours) * 100, 2, " "),
        align: "center",
        width: "15%",
      },
      
    ];

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Коэффициент занятости за период c ${fetchParams.startDate} по ${fetchParams.endDate}`}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }} style={{ paddingBottom: 15 }}>
            <Col span={24}>
              <Table
                bordered
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={employees}
                rowKey="id"
              />
            </Col>
          </Row>
        }
      >
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <RangePicker
              style={{ width: "100%" }}
              value={[
                moment(fetchParams.startDate, dateFormat),
                moment(fetchParams.endDate, dateFormat),
              ]}
              format={dateFormat}
              onChange={onChangeDateRange}
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <Select
              style={{ width: "100%" }}
              value={period}
              placeholder="Период"
              optionFilterProp="children"
              filterOption={false}
              onChange={onChangePeriod}
            >
              <Option value={0}>произвольный период</Option>
              <Option value={1}>этот месяц</Option>
              <Option value={2}>этот квартал</Option>
              <Option value={3}>этот год</Option>
              <Option value={4}>прошлый месяц</Option>
              <Option value={5}>прошлый квартал</Option>
              <Option value={6}>прошлый год</Option>
            </Select>
          </Col>
          
        </Row>
      </PageHeader>
    );
  }
}

DataList.propTypes = {
  payments: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default DataList;
