// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Col, Row, Form, InputNumber } from "antd";

const FormItem = Form.Item;

class Parameters extends Component {
  render() {
    const {
      getFieldDecorator,
      square,
      onChangeSquare,
      room,
      onChangeRoom,
      equipment,
      onChangeEquipment,
      complexity,
      onChangeComplexity
    } = this.props;
    return (
      <Row gutter={16}>
        <Col md={{ span: 12 }} xs={{ span: 12 }}>
          <FormItem label={"Площадь, м2"}>
            {getFieldDecorator("square", {
              onChange: onChangeSquare,
              initialValue: square
            })(<InputNumber min={0} step={0.1} decimalSeparator={','} style={{ width: "100%" }} />)}
          </FormItem>
        </Col>

        <Col md={{ span: 12 }} xs={{ span: 12 }}>
          <FormItem label={"Помещений (кол-во)"}>
            {getFieldDecorator("room", {
              onChange: onChangeRoom,
              initialValue: room
            })(<InputNumber min={0} style={{ width: "100%" }} />)}
          </FormItem>
        </Col>

        <Col md={{ span: 12 }} xs={{ span: 12 }}>
          <FormItem label={"Оборудование (кол-во)"}>
            {getFieldDecorator("equipment", {
              onChange: onChangeEquipment,
              initialValue: equipment
            })(<InputNumber min={0} style={{ width: "100%" }} />)}
          </FormItem>
        </Col>

        <Col md={{ span: 12 }} xs={{ span: 12 }}>
          <FormItem label={"Коэффициент сложности"}>
            {getFieldDecorator("complexity", {
              onChange: onChangeComplexity,
              initialValue: complexity
            })(<InputNumber min={0} step={0.1} decimalSeparator={','} style={{ width: "100%" }} />)}
          </FormItem>
        </Col>
      </Row>
    );
  }
}

Parameters.propTypes = {
  product: PropTypes.object,
  onClose: PropTypes.func,
  onChangeAssets: PropTypes.func,
  onDeletePhoto: PropTypes.func,
  uploadAsset: PropTypes.func,
  images: PropTypes.array
};

export default Parameters;
