import api from "../api";

const Actions = {};

Actions.fetchOrderTasks = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_ORDER_TASKS_REQUEST" });
    let users = [];
    let members = [];
    let orderUsers = [];
    const params = getState().orderTasks.fetchParams;
    if (params.users) {
      users = params.users.map((user) => {
        if (user && user.id) {
          return user.id;
        }
      });
    }
    if (params.members) {
      members = params.members.map((member) => {
        if (member && member.id) {
          return member.id;
        }
      });
    }

    if (params.orderUsers) {
      orderUsers = params.orderUsers.map((orderUser) => {
        if (orderUser && orderUser.id) {
          return orderUser.id;
        }
      });
    }

    const data = {
      startDate: params.startDate,
      endDate: params.endDate,
      search: params.search,
      sort: params.sort,
      direction: params.direction,
      page: params.page,
      limit: params.limit,
      status: params.status,
      statusTaskType: params.statusTaskType,
      taskTypes: params.taskTypes,
      users: users,
      members: members,
      orderUsers: orderUsers,
      noAssignees: params.noAssignees,
    };
    return api
      .fetch("/order_tasks", data)
      .then((response) => {
        dispatch({ type: "FETCH_ORDER_TASKS", response });
      })
      .catch((error) => {
        api.handleError(error, "Задача по допработе");
        dispatch({ type: "FETCH_ORDER_TASKS_FAILURE" });
      });
  };
};

Actions.fetchOrderTask = (id = null) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ORDER_TASK_REQUEST" });

    return api
      .fetch(`/order_tasks/${id}`)
      .then((response) => {
        dispatch({ type: "FETCH_ORDER_TASK", response });
      })
      .catch((error) => {
        api.handleError(error, "Задача по допработе");
        dispatch({ type: "FETCH_ORDER_TASK_FAILURE", error });
      });
  };
};

Actions.onChangeDueFrom = (id, value) => {
  return (dispatch) => {
    dispatch({ type: "PATCH_ORDER_TASK_DUE_FROM_REQUEST" });
    const data = { order_task: { due_from: value } };
    api
      .patch(`/order_tasks/${id}`, data)
      .then((response) => {
        dispatch({ type: "PATCH_ORDER_DUE_FROM_TASK", response });
        api.openNotification(
          "success",
          "Задача по допработе",
          "Плановая дата начала обновлена!"
        );
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Задача по допработе", error);
          });
        }
        dispatch({ type: "PATCH_ORDER_TASK_DUE_FROM_FAILURE", response });
      });
  };
};

Actions.onChangeDueBy = (id, value) => {
  return (dispatch) => {
    dispatch({ type: "PATCH_ORDER_TASK_DUE_BY_REQUEST" });
    const data = { order_task: { due_by: value } };
    api
      .patch(`/order_tasks/${id}`, data)
      .then((response) => {
        dispatch({ type: "PATCH_ORDER_DUE_BY_TASK", response });
        api.openNotification(
          "success",
          "Задача по допработе",
          "Плановая дата окончания обновлена!"
        );
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Задача по допработе", error);
          });
        }
        dispatch({ type: "PATCH_ORDER_TASK_DUE_BY_FAILURE", response });
      });
  };
};

Actions.onChangePlanDays = (id, value) => {
  return (dispatch) => {
    dispatch({ type: "PATCH_ORDER_TASK_PLAN_DAYS_REQUEST" });
    const data = { order_task: { plan_days: value } };
    api
      .patch(`/order_tasks/${id}`, data)
      .then((response) => {
        dispatch({ type: "PATCH_ORDER_TASK_PLAN_DAYS", response });
        api.openNotification(
          "success",
          "Задача по допработе",
          "Плановая дата окончания обновлена!"
        );
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Задача по допработе", error);
          });
        }
        dispatch({ type: "PATCH_ORDER_TASK_PLAN_DAYS_FAILURE", response });
      });
  };
};

Actions.onChangeSubject = (id, value) => {
  return (dispatch) => {
    dispatch({ type: "PATCH_ORDER_TASK_SUBJECT_REQUEST" });
    const data = { order_task: { subject: value } };
    api
      .patch(`/order_tasks/${id}`, data)
      .then((response) => {
        dispatch({ type: "PATCH_ORDER_TASK_SUBJECT", value: value });
        api.openNotification(
          "success",
          "Задача по допработе",
          "Тема обновлена!"
        );
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Задача по допработе", error);
          });
        }
        dispatch({ type: "PATCH_ORDER_TASK_SUBJECT_FAILURE", response });
      });
  };
};

Actions.onChangeDescription = (id, value) => {
  return (dispatch) => {
    dispatch({ type: "PATCH_ORDER_TASK_DESCRIPTION_REQUEST" });
    const data = { order_task: { description: value } };
    api
      .patch(`/order_tasks/${id}`, data)
      .then((response) => {
        dispatch({ type: "PATCH_ORDER_DESCRIPTION_TASK", value: value });
        api.openNotification(
          "success",
          "Задача по допработе",
          "Описание обновлено!"
        );
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Задача по допработе", error);
          });
        }
        dispatch({ type: "PATCH_ORDER_TASK_DESCRIPTION_FAILURE", response });
      });
  };
};

// comments
Actions.fetchOrderTaskComments = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ORDER_TASK_COMMENTS_REQUEST" });
    api
      .fetch(`/order_tasks/${id}/order_task_comments`)
      .then((response) => {
        dispatch({ type: "FETCH_ORDER_TASK_COMMENTS", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Статус", error);
          });
        }
        dispatch({ type: "FETCH_ORDER_TASK_COMMENTS_FAILURE", response });
      });
  };
};

Actions.onCreateComment = (id, comment, mentioned) => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_ORDER_TASK_COMMENT_REQUEST" });

    const mentioned_user_ids = mentioned.users
      ?.map((it) => it.id)
      ?.map((it) => parseInt(it) || null)
      ?.filter((it) => it !== null);
    const mentioned_member_ids = mentioned.members
      ?.map((it) => it.id)
      ?.map((it) => parseInt(it) || null)
      ?.filter((it) => it !== null);

    return api
      .post(`/order_tasks/${id}/order_task_comments`, {
        order_task_comment: {
          comment: comment,
          mentioned_user_ids: mentioned_user_ids,
          mentioned_member_ids: mentioned_member_ids,
        },
      })
      .then((response) => {
        dispatch({ type: "POST_ORDER_TASK_COMMENT", response });
        api.openNotification("success", "Комментарий", "Успешно создан!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Комментарий", error);
          });
        }
        dispatch({ type: "POST_ORDER_TASK_COMMENT_FAILURE", response });
      });
  };
};

// assignees
Actions.fetchAssignees = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ASSIGNEES_REQUEST" });
    api
      .fetch(`/order_tasks/${id}/order_task_assignees`)
      .then((response) => {
        dispatch({ type: "FETCH_ASSIGNEES", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_ASSIGNEES_FAILURE", response });
      });
  };
};

Actions.onCreateAssignee = (id, assignee, type) => {
  return (dispatch) => {
    dispatch({ type: "POST_ASSIGNEE_REQUEST" });
    return api
      .post(`/order_tasks/${id}/order_task_assignees`, {
        order_task_assignee: {
          assignee_type: type,
          user_id:
            type == "organisation" || type == "supervisor" ? assignee.id : null,
          member_id: type == "customer" ? assignee.id : null,
          employee_id: type == "company" ? assignee.id : null,
        },
      })
      .then((response) => {
        dispatch({ type: "POST_ASSIGNEE", response });
        api.openNotification("success", "Ответственный", "Успешно добавлен!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Ответственный", error);
          });
        }
        dispatch({ type: "POST_ASSIGNEE_FAILURE", response });
      });
  };
};

Actions.onDeleteAssignee = (order_task_id, id) => {
  return (dispatch) => {
    dispatch({
      type: "ASSIGNEE_DELETE_REQUEST",
    });
    return api
      .delete(`/order_tasks/${order_task_id}/order_task_assignees/${id}`)
      .then((response) => {
        api.openNotification("success", "Ответственный", "Успешно удален!");
        dispatch({ type: "ASSIGNEE_DELETE_SUCCEED", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Ответственный", error);
          });
        }
        dispatch({ type: "ASSIGNEE_DELETE_FAILED" });
      });
  };
};

// attachments
Actions.fetchAttachments = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ATTACHMENTS_REQUEST" });
    api
      .fetch(`/order_tasks/${id}/order_task_attachments`)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_ATTACHMENTS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_ATTACHMENTS_FAILURE", response });
      });
  };
};

Actions.onUploadAttachment = (id, file, commentId) => {
  return (dispatch) => {
    dispatch({
      type: "ATTACHMENT_UPLOAD",
    });

    const data = {
      order_task_comment_id: commentId,
      name: file.name,
      content_type: file.content_type,
      attachment: file.attachment,
    };

    return api
      .post(`/order_tasks/${id}/order_task_attachments`, data)
      .then((response) => {
        api.openNotification("success", "Файл", "Успешно загружен!");
        dispatch({ type: "ATTACHMENT_UPLOAD_SUCCEED", response });
      })
      .catch((response) => {
        dispatch({
          type: "ATTACHMENT_UPLOAD_FAILED",
        });
        dispatch({
          type: "ADD_NOTIFICATION",
          message: { label: response.error, type: "error" },
        });
      });
  };
};

Actions.onDeleteAttachment = (order_task_id, id) => {
  return (dispatch) => {
    dispatch({
      type: "ATTACHMENT_DELETE_REQUEST",
    });
    return api
      .delete(`/order_tasks/${order_task_id}/order_task_attachments/${id}`)
      .then((response) => {
        api.openNotification("success", "Файл", "Успешно удален!");
        dispatch({ type: "ATTACHMENT_DELETE_SUCCEED", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Файл", error);
          });
        }
        dispatch({ type: "ATTACHMENT_DELETE_FAILED" });
      });
  };
};

// statuses
Actions.onChangeStatus = (id, status, statusPostUrl, comment = null) => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_ORDER_TASK_REQUEST_STATUS" });
    const data = { status: status, comment: comment };

    return api
      .patch(`/order_tasks/${id}/${statusPostUrl}`, data)
      .then((response) => {
        api.openNotification("success", "Статус", "Статус изменен!");
        dispatch({ type: "PATCH_ORDER_TASK_STATUS", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Статус", error);
          });
        }
        dispatch({
          type: "PATCH_ORDER_TASK_FAILURE_STATUS",
        });
      });
  };
};

// activities
Actions.fetchTasksActivities = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ORDER_TASK_ACTIVITIES_REQUEST" });
    return api
      .fetch(`/order_tasks/${id}/order_task_activities`)
      .then((response) => {
        dispatch({ type: "FETCH_ORDER_TASK_ACTIVITIES", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Статус", error);
          });
        }
        dispatch({
          type: "FETCH_ORDER_TASK_ACTIVITIES_FAILURE",
        });
      });
  };
};

Actions.onDelete = (id) => {
  return (dispatch) => {
    dispatch({ type: "DELETE_ORDER_TASK_REQUEST" });
    return api
      .delete(`/order_tasks/${id}`)
      .then((response) => {
        api.openNotification(
          "success",
          "Задача по допработе",
          "Успешно удален!"
        );
        dispatch({ type: "DELETE_ORDER_TASK", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Задача по допработе", error);
          });
        }
        dispatch({
          type: "DELETE_ORDER_TASK_FAILURE",
        });
      });
  };
};

Actions.onSearch = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_ORDER_TASK_SEARCH",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangePage = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_ORDER_TASK_CHANGE_PAGE",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeLimit = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_ORDER_TASK_CHANGE_LIMIT",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterActive = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_ORDER_TASKS_FILTER_ACTIVE",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onSortBy = (field, direction) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_TASKS_SORT_BY",
      field: field,
      direction: direction,
    });
    return Promise.resolve();
  };
};

// filters
Actions.onSetFilters = (values) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_TASKS_SET_FILTERS",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.onClearFilters = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_TASKS_CLEAR_FILTERS",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterStatus = (statusTaskType, value) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_TASKS_FILTER_STATUS",
      statusTaskType: statusTaskType,
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterTaskType = (values) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_TASKS_FILTER_TASK_TYPES",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterPeriod = (startDate, endDate) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_TASKS_FILTER_PERIOD",
      startDate: startDate,
      endDate: endDate,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterUsers = (values) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_TASKS_FILTER_USERS",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterMembers = (values) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_TASKS_FILTER_MEMBERS",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterOrderUsers = (values) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_TASKS_FILTER_ORDER_USERS",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterNoAssignees = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_TASKS_FILTER_NO_ASSIGNEES",
      value: value,
    });
    return Promise.resolve();
  };
};

// notices
Actions.onClearNotices = () => {
  return (dispatch) => {
    dispatch({ type: "POST_ORDER_TASK_MARK_ALL_AS_READ_REQUEST" });

    return api
      .post(`/order_task_comment_marks/mark_all_as_read`)
      .then((response) => {
        api.openNotification(
          "success",
          "Задачи",
          "Все комментарии отмечены как прочитанные!"
        );
        dispatch({ type: "POST_ORDER_TASK_MARK_ALL_AS_READ", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Заявка", error);
          });
        }
        dispatch({
          type: "POST_ORDER_TASK_MARK_ALL_AS_READ_FAILURE",
        });
      });
  };
};

// todos

Actions.fetchOrderTaskTodos = (id) => {
  return (dispatch) => {
    dispatch({ type: "ORDER_TASK_TODOS_FETCH" });

    return api
      .fetch(`/order_tasks/${id}/order_task_todos`)
      .then((response) => {
        dispatch({ type: "ORDER_TASK_TODOS_FETCH_SUCCESS", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Задача", error);
          });
        }

        dispatch({ type: "ORDER_TASK_TODOS_FETCH_FAILURE", response });
      });
  };
};

Actions.createOrderTaskTodos = (id, services) => {
  return (dispatch) => {
    dispatch({ type: "ORDER_TASK_TODOS_CREATE" });

    const serviceIds = services
      ?.map((it) => it.id)
      ?.map((it) => parseInt(it) || null)
      ?.filter((it) => it !== null);

    const data = {
      service_ids: serviceIds,
    };

    return api
      .post(`/order_tasks/${id}/create_todos`, data)
      .then((response) => {
        dispatch({ type: "ORDER_TASK_TODOS_CREATE_SUCCESS", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Ошибка создания чек-листа", error);
          });
        }

        dispatch({ type: "ORDER_TASK_TODOS_CREATE_FAILURE", response });
      });
  };
};

Actions.updateOrderTaskTodoItemComment = (
  todoId,
  todoIdx,
  todoItemId,
  todoItemIdx,
  comment
) => {
  return (dispatch) => {
    dispatch({ type: "ORDER_TASK_TODO_ITEM_COMMENT_UPDATE" });

    const data = {
      order_task_todo_item: {
        comment: comment.trim() === '' ? null : comment,
      }
    };

    return api
      .patch(`/order_task_todos/${todoId}/order_task_todo_items/${todoItemId}`, data)
      .then((response) => {
        dispatch({
          type: "ORDER_TASK_TODO_ITEM_COMMENT_UPDATE_SUCCESS",
          response: response,
          todoIdx: todoIdx,
          todoItemIdx: todoItemIdx,
        });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Ошибка", error);
          });
        }

        dispatch({ type: "ORDER_TASK_TODO_ITEM_COMMENT_UPDATE_FAILURE", response });
      });
  };
};

Actions.updateOrderTaskTodoItemCompletedAt = (
  todoId,
  todoIdx,
  todoItemId,
  todoItemIdx,
  completed_at
) => {
  return (dispatch) => {
    dispatch({ type: "ORDER_TASK_TODO_ITEM_COMPLETED_AT_UPDATE" });

    const data = {
      order_task_todo_item: {
        completed_at: completed_at,
      }
    };

    return api
      .patch(`/order_task_todos/${todoId}/order_task_todo_items/${todoItemId}`, data)
      .then((response) => {
        dispatch({
          type: "ORDER_TASK_TODO_ITEM_COMPLETED_AT_UPDATE_SUCCESS",
          response: response,
          todoIdx: todoIdx,
          todoItemIdx: todoItemIdx,
        });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Ошибка", error);
          });
        }

        dispatch({ type: "ORDER_TASK_TODO_ITEM_COMPLETED_AT_UPDATE_FAILURE", response });
      });
  };
};

export default Actions;
