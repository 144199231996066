// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import Actions from "../../../actions/todoTemplates";
import TodoTemplatesList from "./List";

class TodoTemplates extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.fetchTodoTemplates());
  };

  onDelete = ids => {
    ids.map(id => {
      this.props.dispatch(Actions.onDelete(id)).then(() => {
        this.props.dispatch(Actions.fetchTodoTemplates());
      });
    });
  };

  onChangePage = value => {
    this.props
      .dispatch(Actions.onChangePage(value))
      .then(() => this.props.dispatch(Actions.fetchTodoTemplates()));
  };

  onChangeLimit = (current, size) => {
    this.props
      .dispatch(Actions.onChangeLimit(size))
      .then(() => this.props.dispatch(Actions.fetchTodoTemplates()));
  };

  onSortBy = (field, direction) => {
    this.props
      .dispatch(Actions.onSortBy(field, direction))
      .then(() => this.props.dispatch(Actions.fetchTodoTemplates()));
  };

  render() {
    const { currentUser, isLoading, todoTemplates, meta, fetchParams } = this.props;
    return (
      <TodoTemplatesList
        currentUser={currentUser}
        loading={isLoading}
        todoTemplates={todoTemplates}
        meta={meta}
        fetchParams={fetchParams}
        onDelete={this.onDelete}
        onSearch={this.onSearch}
        onChangePage={this.onChangePage}
        onChangeLimit={this.onChangeLimit}
        onSortBy={this.onSortBy}
      />
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.session.currentUser,
  isLoading: state.todoTemplates.isLoading,
  todoTemplates: state.todoTemplates.todoTemplates,
  meta: state.todoTemplates.meta,
  fetchParams: state.todoTemplates.fetchParams
});

export default connect(mapStateToProps)(TodoTemplates);
