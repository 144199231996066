// @flow
import React, {Component} from "react";
import PropTypes from "prop-types";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {compose} from "redux";
import Actions from "../../../../../actions/articles";
import {Button, Card, Col, Divider, PageHeader, Row, Spin, Tag, Typography} from "antd";
import moment from "moment/moment";
import Can from "../../../../../rules/Can";

const { Text } = Typography;

class Article extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount = () => {
    if (this.props.match.params.sectionId && this.props.match.params.id) {
      this.props.dispatch(Actions.fetchArticle(this.props.match.params.sectionId, this.props.match.params.id));
    }
  };

  onClose = () => {
    this.props.history.push(`/sections/${this.props.match.params.sectionId}`);
  };

  render() {
    const {
      currentUser,
      isLoading,
      section,
      article,
      permissions
    } = this.props;

    let contentTags = article.content_tags ? (article.content_tags.map((contentTag, index) => {
      return (
        <Tag key={index} color="#108ee9">
          {contentTag.name}
        </Tag>
      );
    })) : (
      <></>
    );

    return isLoading ? (
      <Spin/>
    ) : (
      <>
        <PageHeader
          onBack={() => window.history.back()}
          title={`Статья: ${article.title}`}
        />
        <Card
          title={
            <Row gutter={{md: 8, lg: 24, xl: 48}}>
              <Col span={24}>
                {contentTags}
              </Col>
              <Divider/>
              <Col span={24}>
                {article.published_by ? `Автор: ${article.published_by ? article.published_by.name : null}` : null}
              </Col>
              <Col span={24}>
                {article.published_at ? `Опубликовано: ${moment(article.published_at).format("LLL")}` : null}
              </Col>
            </Row>
          }
          extra={[
            <Button
              key={"1"}
              onClick={this.onClose}>
              Закрыть
            </Button>,
            <Can
              key={"2"}
              role={currentUser.role}
              perform={"articles:edit"}
              yes={() =>
                <Link key={2}
                      to={{pathname: `/sections/${this.props.match.params.sectionId}/articles/${this.props.match.params.id}/edit`}}
                      className={"ant-btn ant-btn-primary ant-btn-background-ghost"}
                      style={{marginLeft: "8px"}}
                >
                  Редактировать
                </Link>
              }
              no={() => null}
            />
          ]}>
          <Text>
            <div dangerouslySetInnerHTML={{ __html: article.body }}/>
          </Text>
        </Card>
      </>
    );
  }
}

Article.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  section: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  isLoading: state.articles.isLoading,
  errors: state.articles.errors,
  section: state.sections.section,
  article: state.articles.article,
  permissions: state.articles.permissions,
});

export default compose(
  withRouter,
  connect(mapStateToProps)
)(Article);
