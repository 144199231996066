// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { Layout, Menu, Dropdown, Avatar, Icon, Tooltip } from "antd";
import NoticeIcon from "../../components/NoticeIconTabs";
import update from "immutability-helper";
import SupportMessage from "./SupportMessage";
import canCheck from "../../rules/canCheck";

const { Header } = Layout;

class MainHeader extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      isUploading: false,
      message: {}
    };
  }

  onMenuClick = () => {};

  onOpenFeedback = () => {
    // console.log("onOpenFeedback")
    this.setState(
      {
        message: {
          support_message_type: "empty",
          url: window.location,
          attachments: []
        }
      },
      () => {
        this.setState({ isVisible: true });
      }
    );
  };

  onViewMore = () => {
    this.props.history.push(`/support_messages`);
  };

  onNotificationClick = item => {
    const { currentUser } = this.props;
    if (canCheck(currentUser.role, "supportMessages:edit")) {
      this.props.history.push(`/support_messages/${item.id}/edit`);
    } else {
      this.props.history.push(`/support_messages/${item.id}`);
    }
  };

  onEntityTaskClick = item => {
    console.log(item)
    this.props.history.push(`/entity_tasks/${item.id}/edit`);
  };

  onOrderTaskClick = item => {
    this.props.history.push(`/order_tasks/${item.id}/edit`);
  };

  onCancelFeedback = () => {
    this.setState({ isVisible: false });
  };

  onSaveFeedback = () => {
    this.setState({ isVisible: false }, () => {
      this.props.onSaveFeedback(this.state.message);
    });
  };

  onChangeFeedbackType = value => {
    this.setState({
      message: update(this.state.message, {
        support_message_type: { $set: value }
      })
    });
  };

  onChangeSubject = e => {
    this.setState({
      message: update(this.state.message, {
        subject: { $set: e.target.value }
      })
    });
  };

  onChangeDescription = e => {
    this.setState({
      message: update(this.state.message, {
        description: { $set: e.target.value }
      })
    });
  };

  onUploadAttachment = imageUrl => {
    // console.log(imageUrl)
    this.setState({
      message: update(this.state.message, {
        attachments: { $push: [imageUrl.file] }
      })
    });
  };

  onDeleteAttachment = idx => {
    this.setState({
      message: update(this.state.message, {
        attachments: { $splice: [[idx, 1]] }
      })
    });
  };

  render() {
    const {
      isSidebarCollapsed,
      onToggleSideBar,
      currentUser,
      onLogout,
      supportMessages,
      entityTasks,
      orderTasks,
    } = this.props;
    const menu = (
      <Menu className={"menu"} selectedKeys={[]} onClick={this.onMenuClick}>
        <Menu.Item>
          <Link to={"/profile"}>Профиль</Link>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="logout" onClick={onLogout}>
          <Icon type="logout" />
          Выход
        </Menu.Item>
      </Menu>
    );

    

    return (
      <div>
        <Header
          className="main-header"
          style={{ position: "fixed", zIndex: 1000, width: "100%" }}
        >
          <Tooltip
            placement="bottom"
            title={isSidebarCollapsed ? "Развернуть меню" : "Свернуть меню"}
          >
            <Icon
              className="trigger custom_trigger"
              type={isSidebarCollapsed ? "menu-unfold" : "menu-fold"}
              onClick={onToggleSideBar}
            />
          </Tooltip>
          <div
            className={"right"}
            style={{
              paddingRight: `${isSidebarCollapsed ? "100px" : "220px"}`
            }}
          >
            <NoticeIcon
              count={
                // supportMessages.length + entityTasks.length + orderTasks.length
                entityTasks.length + orderTasks.length
              }
            >
              {/* <NoticeIcon.Tab
                tabKey="notification"
                count={supportMessages.length}
                list={supportMessages}
                title={"Сообщения"}
                emptyText={"новых сообщений нет"}
                locale={{
                  viewMore: "Создать сообщение",
                  create: "Открыть сообщения"
                }}
                onItemClick={this.onNotificationClick}
                onViewMore={this.onViewMore}
                showOnViewMore
                showOnCreate
                onCreate={this.onOpenFeedback}
              /> */}
              <NoticeIcon.Tab
                onItemClick={this.onEntityTaskClick}
                tabKey="entitytask"
                count={entityTasks.length}
                list={entityTasks}
                title={"Регламент"}
                emptyText={"новых регламентных заданий нет"}
              />
              <NoticeIcon.Tab
                onItemClick={this.onOrderTaskClick}
                tabKey="ordertask"
                count={orderTasks.length}
                list={orderTasks}
                title={"Задачи"}
                emptyText={"новых задач нет"}
              />
            </NoticeIcon>
            {currentUser ? (
              <Dropdown overlay={menu}>
                <span className={`${"action"} ${"account"}`}>
                  <Avatar
                    size="default"
                    className={"avatar"}
                    src={currentUser.avatar}
                  />
                  <span className={"name"}>{currentUser.name}</span>
                </span>
              </Dropdown>
            ) : null}
          </div>
        </Header>
      </div>
    );
  }
}

MainHeader.propTypes = {
  isLoading: PropTypes.bool,
  isSidebarCollapsed: PropTypes.bool,
  onToggleSideBar: PropTypes.func,
  onLogout: PropTypes.func,
  currentUser: PropTypes.object
};

export default withRouter(MainHeader);
