// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Card,
  Row,
  Col,
  Form,
  DatePicker,
  Statistic,
  Descriptions,
} from "antd";
import Documents from "../../../components/Base64Documents";
import moment from "moment";
import "moment/locale/ru";
import Assignees from "../../../components/Assignees";
import canCheck from "../../../rules/canCheck";
import Can from "../../../rules/Can";

const FormItem = Form.Item;

class Statuses extends Component {
  render() {
    const {
      currentUser,
      getFieldDecorator,
      isAssigneesLoading,
      isOrganisation,
      company,
      plan,
      start,
      finish,
      organisationAssignees,
      supervisorAssignees,
      customerAssignees,
      companyAssignees,
      onChangePlan,
      onCreateAssignee,
      onDeleteAssignee,
      // attachments
      attachments,
      isUploading,
      onDeleteAttachment,
      onUploadAttachment,
      // activities
      entityTaskActivities,
      isActivitiesLoading,
      fetchEntityTaskActivities,
    } = this.props;

    return (
      <Card style={{ borderColor: "#1890ff" }}>
        <Can
          role={currentUser.role}
          perform="entity_tasks:edit"
          yes={() => (
            <Row gutter={10}>
              <Col span={24}>
                <FormItem label={"Плановые дата и время"}>
                  {getFieldDecorator("plan", {
                    onOk: onChangePlan,
                    initialValue: moment(plan),
                    rules: [
                      {
                        required: true,
                        message: "Введите дату!",
                      },
                    ],
                  })(
                    <DatePicker
                      format={"LLL"}
                      showTime
                      className="ant-btn-width-100"
                      onOk={onChangePlan}
                    />
                  )}
                </FormItem>
              </Col>
              <Col span={24}>
                <Descriptions bordered>
                  <Descriptions.Item label="Начало выполнения работ" span={3}>
                    {start ? moment(start).format("lll") : "не начались"}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label="Окончание выполнения работ"
                    span={3}
                  >
                    {finish ? moment(finish).format("lll") : "не закончены"}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
          )}
          no={() => (
            <Row gutter={10}>
              <Col span={24}>
                <Descriptions bordered>
                  <Descriptions.Item label="Плановые дата и время" span={3}>
                    {plan ? moment(plan).format("lll") : null}
                  </Descriptions.Item>
                  <Descriptions.Item label="Начало выполнения работ" span={3}>
                    {start ? moment(start).format("lll") : "не начались"}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label="Окончание выполнения работ"
                    span={3}
                  >
                    {finish ? moment(finish).format("lll") : "не закончены"}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
          )}
        />
        <Row gutter={10}>
          <Col span={24}>
            <Assignees
              title={"Наблюдатели"}
              isReadOnly={
                canCheck(currentUser, "entity_tasks:assignees") ? false : true
              }
              isAssigneesLoading={isAssigneesLoading}
              assigneeType="supervisor"
              assignees={supervisorAssignees}
              onCreateAssignee={onCreateAssignee}
              onDeleteAssignee={onDeleteAssignee}
            />
          </Col>
        </Row>
        {isOrganisation && company == null ? (
          <Row gutter={10}>
            <Col span={24}>
              <Assignees
                isReadOnly={
                  canCheck(currentUser, "entity_tasks:assignees") ? false : true
                }
                isAssigneesLoading={isAssigneesLoading}
                assigneeType="organisation"
                assignees={organisationAssignees}
                onCreateAssignee={onCreateAssignee}
                onDeleteAssignee={onDeleteAssignee}
              />
            </Col>
          </Row>
        ) : null}
        {isOrganisation == false && company == null ? (
          <Row gutter={10}>
            <Col span={24}>
              <Assignees
                isReadOnly={
                  canCheck(currentUser, "entity_tasks:assignees") ? false : true
                }
                isAssigneesLoading={isAssigneesLoading}
                assigneeType="organisation"
                assignees={organisationAssignees}
                onCreateAssignee={onCreateAssignee}
                onDeleteAssignee={onDeleteAssignee}
              />
            </Col>
          </Row>
        ) : null}
        {isOrganisation == false && company ? (
          <Row gutter={10}>
            <Col span={24}>
              <Assignees
                title={"От подрядчика"}
                isReadOnly={true}
                isAssigneesLoading={isAssigneesLoading}
                assigneeType="company"
                assignees={companyAssignees}
                onCreateAssignee={onCreateAssignee}
                onDeleteAssignee={onDeleteAssignee}
              />
            </Col>
          </Row>
        ) : null}
        <Row gutter={10}>
          <Col span={24}>
            {isOrganisation ? (
              <Assignees
                title={"От подрядчика"}
                isReadOnly={true}
                isAssigneesLoading={isAssigneesLoading}
                assigneeType="company"
                assignees={companyAssignees}
                onCreateAssignee={onCreateAssignee}
                onDeleteAssignee={onDeleteAssignee}
              />
            ) : (
              <Assignees
                title={"От заказчика"}
                isReadOnly={
                  canCheck(currentUser, "entity_tasks:assignees") ? false : true
                }
                isAssigneesLoading={isAssigneesLoading}
                assigneeType="customer"
                assignees={customerAssignees}
                onCreateAssignee={onCreateAssignee}
                onDeleteAssignee={onDeleteAssignee}
              />
            )}
          </Col>
        </Row>

        <Documents
          currentUser={currentUser}
          attachmentType={1}
          isUploading={isUploading}
          attachments={attachments}
          onUpload={onUploadAttachment}
          onDeleteAttachment={onDeleteAttachment}
        />
      </Card>
    );
  }
}
Statuses.propTypes = {
  customer: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  onAdressEdit: PropTypes.func,
  onChangePaymentType: PropTypes.func,
  onChangePaymentStatus: PropTypes.func,
  onChangeShippingStatus: PropTypes.func,
  onChangeShippingMethod: PropTypes.func,
};

export default Statuses;
