// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Row, Col, Table, Button, Tabs, Progress } from "antd";
import update from "immutability-helper";

import moment from "moment";
import "moment/locale/ru";

import Stage from "./Stage";

const { TabPane } = Tabs;

class Stages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      isVisible: false,
      stage: {},
    };
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  onDeleteStages = () => {
    this.props.onDeleteStages(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  onOpenStageModal = (item) => {
    this.setState({ entityService: item }, () => {
      this.setState({ isVisible: true });
    });
  };

  onAddStage = () => {
    this.setState(
      {
        stage: {
          id: Date.now(),
          project_stage: null,
          position: 0,
          description: null,
          start: null,
          finish: null,
          plan_start: null,
          plan_finish: null,
          complete: 0,
        },
      },
      () => {
        this.setState({ isVisible: true });
      }
    );
  };

  onDelete = () => {
    this.props.onDeleteStages(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  onSaveStage = () => {
    this.setState({ isVisible: false }, () => {
      this.props.onSaveStage(this.state.stage);
    });
  };

  onOpenStage = (record) => {
    this.setState({ stage: record }, () => {
      this.setState({ isVisible: true });
    });
  };

  onCancel = () => {
    this.setState({ isVisible: false });
  };

  onChangeProjectStage = (value) => {
    this.setState({
      stage: update(this.state.stage, {
        project_stage: { $set: value },
      }),
    });
  };

  onChangePosition = (value) => {
    this.setState({
      stage: update(this.state.stage, {
        position: { $set: value },
      }),
    });
  };

  onChangeDescription = (e) => {
    this.setState({
      stage: update(this.state.stage, {
        description: { $set: e.target.value },
      }),
    });
  };


  onChangePlanStart = (value) => {
    this.setState({
      stage: update(this.state.stage, {
        plan_start: { $set: value },
      }),
    });
  };


  onChangePlanFinish = (value) => {
    this.setState({
      stage: update(this.state.stage, {
        plan_finish: { $set: value },
      }),
    });
  };


  render() {
    const { stages, isLoading } = this.props;
    const { selectedRowKeys, isVisible, stage } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: "Наименование",
        dataIndex: "project_stage",
        render: (project_stage, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ fontSize: 12 }}>
              <a onClick={this.onOpenStage.bind(this, record)}>
                {project_stage.name}
              </a>
            </span>
            <span style={{ fontSize: 12 }}>{record.description}</span>
          </div>
        ),
        width: "60%",
        align: "center",
      },
      {
        title: "Начало (план)",
        dataIndex: "plan_start",
        render: (plan_start, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ fontSize: 12 }}>
              {plan_start ? moment(plan_start).format("L") : "-"}
            </span>
          </div>
        ),
        width: "20%",
        align: "center",
      },
      {
        title: "Окончание (план)",
        dataIndex: "plan_finish",
        render: (plan_finish, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ fontSize: 12 }}>
              {plan_finish ? moment(plan_finish).format("L") : "-"}
            </span>
          </div>
        ),
        width: "20%",
        align: "center",
      },
    ];

    const operations = hasSelected ? (
      <Button key="1" type="danger" ghost onClick={this.onDelete}>
        Удалить
      </Button>
    ) : (
      <Button onClick={this.onAddStage}>Добавить</Button>
    );

    return (
      <Tabs
        defaultActiveKey="1"
        onChange={this.callback}
        tabBarExtraContent={operations}
      >
        <TabPane tab="Этапы" key="1">
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Table
                loading={isLoading}
                size={"small"}
                rowSelection={rowSelection}
                pagination={false}
                columns={columns}
                dataSource={stages}
                rowKey="id"
              />
            </Col>
          </Row>
          <Stage
            visible={isVisible}
            stage={stage}
            onSave={this.onSaveStage}
            onCancel={this.onCancel}
            onChangeProjectStage={this.onChangeProjectStage}
            onChangePosition={this.onChangePosition}
            onChangeDescription={this.onChangeDescription}
            onChangePlanStart={this.onChangePlanStart}
            onChangePlanFinish={this.onChangePlanFinish}
          />
        </TabPane>
      </Tabs>
    );
  }
}

Stages.propTypes = {
  orderStages: PropTypes.arrayOf(PropTypes.object),
  total: PropTypes.number,
  discount: PropTypes.object,
  shippingCost: PropTypes.number,
  amountDiscountCode: PropTypes.number,
  onRemoveVariant: PropTypes.func,
};
export default Stages;
