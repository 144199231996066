// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, Form, Card } from "antd";
import General from "./General";
import Sidebar from "./Sidebar";
import moment from "moment";
import "moment/locale/ru";

class PostForm extends Component {
  constructor(props) {
    super(props);
  }

  onSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onSave(values);
    });
  };

  onPublish = e => {
    e.preventDefault();
    this.props.onPublish(true);
  };

  onUnPublish = e => {
    e.preventDefault();
    this.props.onPublish(false);
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      isNew,
      errors,
      isLoading,
      currentUser,
      post,
      onClose,
      // general
      onChangeTitle,
      onChangeBody,
      // sidebar
      onChangePostAvailable,
      onChangePostUserRoles
    } = this.props;

    let publishButton = isNew ? null : (
      <Button
        key="3"
        type="danger"
        ghost
        onClick={post.published_at ? this.onUnPublish : this.onPublish}
        style={{ marginLeft: "8px" }}
      >
        {post.published_at === null ? "Опубликовать" : "Отменить"}
      </Button>
    );

    return (
      <Form layout={"vertical"}>
        <Card
          loading={isLoading}
          title={
            <h2 style={{ marginBottom: 0 }}>{`Пост #${
              isNew ? "новый" : post.id
            } от ${moment(post.created_at).format("LLL")}`}</h2>
          }
          extra={[
            <Button key={1} onClick={onClose}>
              Закрыть
            </Button>,
            <Button
              key={2}
              onClick={this.onSubmit}
              type="primary"
              ghost
              style={{ marginLeft: "8px" }}
            >
              Сохранить
            </Button>,
            publishButton
          ]}
        >
          <Col span={13}>
            <General
              getFieldDecorator={getFieldDecorator}
              title={post.title}
              body={post.body}
              onChangeTitle={onChangeTitle}
              onChangeBody={onChangeBody}
            />
          </Col>
          <Col span={1} />
          <Col span={10}>
            <Sidebar
              getFieldDecorator={getFieldDecorator}
              currentUser={currentUser}
              postType={post.post_type}
              postAvailable={post.post_available}
              onChangePostAvailable={onChangePostAvailable}
              postUserRoles={post.post_user_roles}
              onChangePostUserRoles={onChangePostUserRoles}
            />
          </Col>
        </Card>
      </Form>
    );
  }
}

PostForm.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  post: PropTypes.object,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
  router: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  form: PropTypes.object
};

export default Form.create()(PostForm);
