// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../../actions/entityTasks";
import TaskActions from "../../../actions/tasks";
import LoadingSpin from "../../../components/LoadingSpin";
import Form from "./Form";
import moment from "moment";
import "moment/locale/ru";
import {encodeBase64} from "../../../util/attachments";

class EntityTask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: false,
    };
  }

  componentWillMount = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.fetchEntityTask(this.props.match.params.id)).then(() => {
        this.props.dispatch(TaskActions.fetchTaskActions({taskOwners: [5]}));
      });
    } else {
      this.setState({ isNew: true }, () => {
        this.props.dispatch(Actions.onNew());
      });
    }
  };

  onClose = () => {
    this.props.history.push(`/entity_tasks`);
  };

  onChangeStatus = (comment = null) => {
    if (this.props.entityTask.status == "pending") {
      this.props.dispatch(
        Actions.onChangeStatus(this.props.match.params.id, "processing")
      )
    } else {
      this.props.dispatch(
        Actions.onChangeStatus(this.props.match.params.id, "finished", comment)
      ).then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchEntityTaskComments(this.props.match.params.id)
          );
        }
      });
    }
  };

  onChangePlan = (value) => {
    this.props.dispatch(
      Actions.onChangePlan(this.props.match.params.id, value)
    );
  };

  // comments
  onCreateComment = (comment, mentioned) => {
    this.props.dispatch(Actions.onCreateComment(this.props.match.params.id, comment, mentioned))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchEntityTaskComments(this.props.match.params.id)
          );
        }
      });
  };

  // assignees
  onCreateAssignee = (value, type) => {
    this.props
      .dispatch(
        Actions.onCreateAssignee(this.props.match.params.id, value, type)
      )
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAssignees(this.props.match.params.id)
          );
        }
      });
  };

  onDeleteAssignee = (id) => {
    this.props
      .dispatch(Actions.onDeleteAssignee(this.props.match.params.id, id))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAssignees(this.props.match.params.id)
          );
        }
      });
  };

  // attachments
  onDeleteAttachment = (id) => {
    this.props
      .dispatch(Actions.onDeleteAttachment(this.props.match.params.id, id))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAttachments(this.props.match.params.id)
          );
        }
      });
  };

  onUploadAttachment = (file) => {
    this.props
      .dispatch(Actions.onUploadAttachment(this.props.match.params.id, file))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAttachments(this.props.match.params.id)
          );
        }
      });
  };

  onUploadCommentAttachment = (commentId, file) => {
    encodeBase64(file, (fileUrl) =>
      this.props
        .dispatch(Actions.onUploadAttachment(
          this.props.match.params.id,
          {
            id: file.uid,
            name: file.name,
            content_type: file.type,
            file_size: file.size,
            attachment: fileUrl,
          },
          commentId))
        .then(() => {
          if (!this.props.errors) {
            this.props.dispatch(
              Actions.fetchAttachments(this.props.match.params.id)
            );
          }
        })
    );
  };

  fetchEntityTaskActivities = () => {
    this.props.dispatch(
      Actions.fetchEntityTaskActivities(this.props.match.params.id)
    );
  };

  // tasks
  fetchTasks = () => {
    this.props.dispatch(Actions.fetchTasks(this.props.match.params.id));
  };

  // actions
  onOpenTaskForm = (action) => {
    const { entityTask } = this.props;
    this.props.dispatch(TaskActions.fetchNew(action.id, entityTask.id));
    // this.props.dispatch(TaskActions.onOpenModalForm(action, id));
  };

  // todos
  fetchEntityTaskTodos = () => {
    this.props.dispatch(
      Actions.fetchEntityTaskTodos(this.props.match.params.id)
    );
  };

  fillEntityTaskTodos = () => {
    this.props.dispatch(
      Actions.fillEntityTaskTodos(this.props.match.params.id)
    );
  };

  onEntityTaskTodoSaveComment = (entityTaskTodoId, id, entityTaskTodoIdx, idx, comment) => {
    this.props.dispatch(
      Actions.onEntityTaskTodoSaveComment(entityTaskTodoId, id, entityTaskTodoIdx, idx, comment)
    );
  } 

  onEntityTaskTodoCompleted = (entityTaskTodoId, id, entityTaskTodoIdx, idx, e) => {
    this.props.dispatch(
      Actions.onEntityTaskTodoCompleted(entityTaskTodoId, id, entityTaskTodoIdx, idx, e.target.checked ? moment() : null)
    );
  } 

  render() {
    const {
      currentUser,
      errors,
      isLoading,
      isUploading,
      entityTask,
      entityTaskActivities,
      isActivitiesLoading,
      // todos
      isTodosLoading,
      isTodoLoading,
      // tasks
      isTasksLoading,
      tasks,
      // actions
      actions,
      isTaskActionsLoading,
    } = this.props;
    return isLoading || errors ? (
      <LoadingSpin />
    ) : (
      <Form
        isNew={this.state.isNew}
        currentUser={currentUser}
        entityTask={entityTask}
        onClose={this.onClose}
        onChangeStatus={this.onChangeStatus}
        onChangePlan={this.onChangePlan}
        // comments
        onCreateComment={this.onCreateComment}
        // assignees
        onCreateAssignee={this.onCreateAssignee}
        onDeleteAssignee={this.onDeleteAssignee}
        // attachments
        isUploading={isUploading}
        onDeleteAttachment={this.onDeleteAttachment}
        onUploadAttachment={this.onUploadAttachment}
        onUploadCommentAttachment={this.onUploadCommentAttachment}
        onDeleteCommentAttachment={this.onDeleteAttachment}
        // activities
        entityTaskActivities={entityTaskActivities}
        isActivitiesLoading={isActivitiesLoading}
        fetchEntityTaskActivities={this.fetchEntityTaskActivities}
        // todos
        isTodosLoading={isTodosLoading}
        isTodoLoading={isTodoLoading}
        fetchEntityTaskTodos={this.fetchEntityTaskTodos}
        fillEntityTaskTodos={this.fillEntityTaskTodos}
        onEntityTaskTodoCompleted={this.onEntityTaskTodoCompleted}
        onEntityTaskTodoSaveComment={this.onEntityTaskTodoSaveComment}
        // tasks
        isTasksLoading={isTasksLoading}
        tasks={tasks}
        fetchTasks={this.fetchTasks}
        // actions
        actions={actions}
        isTaskActionsLoading={isTaskActionsLoading}
        onOpenTaskForm={this.onOpenTaskForm}
      />
    );
  }
}

EntityTask.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  entityTask: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  isLoading: state.entityTasks.isLoading,
  isUploading: state.entityTasks.isUploading,
  errors: state.entityTasks.errors,
  entityTask: state.entityTasks.entityTask,
  entityTaskActivities: state.entityTasks.entityTaskActivities,
  isActivitiesLoading: state.entityTasks.isActivitiesLoading,
  // todos
  isTodosLoading: state.entityTasks.isTodosLoading,
  isTodoLoading: state.entityTasks.isTodoLoading,
  // tasks
  isTasksLoading: state.entityTasks.isTasksLoading,
  tasks: state.entityTasks.tasks,
  // actions
  actions: state.tasks.actions,
  isTaskActionsLoading: state.tasks.isLoading,
});

export default connect(mapStateToProps)(withRouter(EntityTask));
