import update from "immutability-helper";
import _ from "lodash/array";
import moment from "moment";

const initialState = {
projectTypes: [],
projectType: {},
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1
  },
  fetchParams: {
    status: null,
    search: null,
    page: 1,
    limit: 15
  },
  errors: false,
  isLoading: false,
  isModalVisible: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "FETCH_PROJECT_TYPES_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "FETCH_PROJECT_TYPES":
      return {
        ...state,
        projectTypes: action.response.data,
        meta: action.response.meta,
        isLoading: false
      };
    case "FETCH_PROJECT_TYPE_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "FETCH_PROJECT_TYPE":
      return {
        ...state,
        projectType: action.response.data,
        isLoading: false,
        isModalVisible: true
      };
    case "PATCH_PROJECT_TYPE_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "PATCH_PROJECT_TYPE":
      return {
        ...state,
        projectType: action.response.data,
        isLoading: false,
        isModalVisible: false
      };

    case "POST_PROJECT_TYPE_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "POST_PROJECT_TYPE":
      return {
        ...state,
        errors: false,
        projectType: {},
        isLoading: false,
        isModalVisible: false
      };

    case "POST_PROJECT_TYPE_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false
      };

    case "PROJECT_TYPE_ADD":
      return {
        ...state,
        projectType: {
          name: null
        },
        errors: false,
        isLoading: false,
        isModalVisible: true
      };

    case "DELETE_PROJECT_TYPE_REQUEST":
      return {
        ...state,
        isLoading: true
      };

    case "DELETE_PROJECT_TYPE":
      return {
        ...state,
        isLoading: false
      };
    case "DELETE_PROJECT_TYPE_FAILURE":
      return {
        ...state,
        isLoading: false
      };
    case "REQUEST_PROJECT_TYPE_SEARCH":
      return update(state, {
        fetchParams: { search: { $set: action.value } }
      });

    case "REQUEST_PROJECT_TYPE_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } }
      });

    case "PROJECT_TYPE_TOGGLE_VISIBLE":
      return {
        ...state,
        isModalVisible: !state.isModalVisible
      };

    case "PROJECT_TYPE_CHANGE_NAME":
      return update(state, {
        projectType: {
          name: { $set: action.value }
        }
      });


    default:
      return state;
  }
}
