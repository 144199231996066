// @flow
import React, { Component, Fragment } from "react";
import { Row, Col, Tooltip, Icon } from "antd";
import ChartCard from "../../../../components/ChartCard";
import Field from "../../../../components/Field";

class Info extends Component {
  

  render() {
    const { currentUser, fetchParams } = this.props;

    return (
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={14} xl={14}>
        
        </Col>
      </Row>
    );
  }
}

export default Info;
