import update from "immutability-helper";
import _ from "lodash/array";

const initialState = {
  organisation: { name: "", departments: [], ticket_priorities: [] },
  users: [],
  tariffPlan: {
    name: null,
    price: 0,
    users_qty: 0,
    price_per_user: 0,
    customers_qty: 0,
    price_per_customer: 0,
    services: {
      tasks: false,
      orders: false,
      tickets: false,
      payrolls: false,
      projects: false,
      entity_tasks: false,
    },
  },
  statistic: {
    users: 0,
    members: 0,
    tickets: 0,
    orders: 0,
    projects: 0,
    tasks: 0,
  },
  organisationProviders: [],
  errors: false,
  isLoading: true,
  isLoadingDepartments: false,
  isLoadingUsers: true,
  isLoadingTariffPlans: true,
  isLoadingStatistic: true,
  isLoadinOrganisationProviders: true,
};

export default function (state = initialState, action) {
  let idx = -1;
  switch (action.type) {
    case "FETCH_ORGANISATION_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_ORGANISATION":
      return {
        ...state,
        organisation: action.response.data,
        isLoading: false,
      };
    case "PATCH_ORGANISATION_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "PATCH_ORGANISATION":
      return {
        ...state,
        organisation: action.response.data,
        isLoading: false,
      };

    case "PATCH_ORGANISATION_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "ORGANISATION_CHANGE_NAME":
      return update(state, {
        organisation: { name: { $set: action.value } },
      });

    case "ORGANISATION_CHANGE_ASSIGNEE":
      return update(state, {
        organisation: { assignee: { $set: action.value } },
      });

    // settings
    case "ORGANISATION_CHANGE_IS_CHIEF_VISIBLE_TASKS":
      return update(state, {
        organisation: {
          settings: {
            is_chief_visible_tasks: {
              $set: action.value,
            },
          },
        },
      });

    case "ORGANISATION_CHANGE_NOTIFICATION_APPROVE":
      return update(state, {
        organisation: {
          settings: {
            notification_emails: {
              approve: {
                $set: action.value,
              },
            },
          },
        },
      });

    case "ORGANISATION_CHANGE_NOTIFICATION_ACCEPT":
      return update(state, {
        organisation: {
          settings: {
            notification_emails: {
              accept: {
                $set: action.value,
              },
            },
          },
        },
      });

    case "ORGANISATION_CHANGE_NOTIFICATION_RATE":
      return update(state, {
        organisation: {
          settings: {
            notification_emails: {
              rate: {
                $set: action.value,
              },
            },
          },
        },
      });

    case "ORGANISATION_CHANGE_NOTIFICATION_INBOUND":
      return update(state, {
        organisation: {
          settings: {
            notification_emails: {
              inbound: {
                $set: action.value,
              },
            },
          },
        },
      });

    case "ORGANISATION_CHANGE_NOTIFICATION_ERRORS":
      return update(state, {
        organisation: {
          settings: {
            notification_emails: {
              errors: {
                $set: action.value,
              },
            },
          },
        },
      });

    case "ORGANISATION_CHANGE_NOTIFICATION_COPY":
      return update(state, {
        organisation: {
          settings: {
            notification_emails: {
              copy: {
                $set: action.value,
              },
            },
          },
        },
      });

    // departments
    case "FETCH_ORGANISATION_DEPARTMENTS_REQUEST":
      return {
        ...state,
        isLoadingDepartments: true,
      };
    case "FETCH_ORGANISATION_DEPARTMENTS":
      return update(state, {
        organisation: {
          departments: { $set: action.response.data },
        },
        errors: { $set: false },
        isLoadingDepartments: { $set: false },
      });
    case "FETCH_ORGANISATION_DEPARTMENTS_FAILURE":
      return {
        ...state,
        isLoadingDepartments: true,
      };

    case "POST_DEPARTMENT_REQUEST":
      return {
        ...state,
        errors: false,
        isLoadingDepartments: true,
      };

    case "POST_DEPARTMENT":
      return update(state, {
        organisation: {
          departments: { $set: action.response.data },
        },
        errors: { $set: false },
        isLoadingDepartments: { $set: false },
      });

    case "POST_DEPARTMENT_FAILURE":
      return {
        ...state,
        errors: true,
        isLoadingDepartments: false,
      };

    case "PATCH_DEPARTMENT_REQUEST":
      return {
        ...state,
        errors: false,
        isLoadingDepartments: true,
      };
    case "PATCH_DEPARTMENT":
      return update(state, {
        organisation: {
          departments: { $set: action.response.data },
        },
        errors: { $set: false },
        isLoadingDepartments: { $set: false },
      });

    case "PATCH_DEPARTMENT_FAILURE":
      return {
        ...state,
        errors: true,
        isLoadingDepartments: false,
      };

    case "DELETE_ORGANISATION_DEPARTMENT_REQUEST":
      return {
        ...state,
        isLoadingDepartments: true,
        errors: false,
      };
    case "DELETE_ORGANISATION_DEPARTMENT":
      return {
        ...state,
        isLoadingDepartments: false,
        errors: false,
      };
    case "DELETE_ORGANISATION_DEPARTMENT_FAILURE":
      return {
        ...state,
        isLoadingDepartments: false,
        errors: true,
      };

    // users
    case "FETCH_ORGANISATION_USERS_REQUEST":
      return {
        ...state,
        users: [],
        isLoadingUsers: true,
      };
    case "FETCH_ORGANISATION_USERS":
      return {
        ...state,
        users: action.response.data,
        isLoadingUsers: false,
      };
    case "FETCH_ORGANISATION_USERS_FAILURE":
      return {
        ...state,
        isLoadingUsers: true,
      };

    // tariff_plans
    case "FETCH_ORGANISATION_TARIFF_PLAN_REQUEST":
      return {
        ...state,
        isLoadingTariffPlans: true,
      };
    case "FETCH_ORGANISATION_TARIFF_PLAN":
      return {
        ...state,
        tariffPlan: action.response.data,
        isLoadingTariffPlans: false,
      };
    case "FETCH_ORGANISATION_TARIFF_PLAN_FAILURE":
      return {
        ...state,
        isLoadingTariffPlans: true,
      };

    // statistic
    case "FETCH_ORGANISATION_STATISTIC_REQUEST":
      return {
        ...state,
        isLoadingStatistic: true,
      };
    case "FETCH_ORGANISATION_STATISTIC":
      return {
        ...state,
        statistic: action.response.data,
        isLoadingStatistic: false,
      };
    case "FETCH_ORGANISATION_STATISTIC_FAILURE":
      return {
        ...state,
        isLoadingStatistic: true,
      };

    // providers
    case "FETCH_ORGANISATION_SERVICES_REQUEST":
      return {
        ...state,
        isLoadinOrganisationProviders: true,
      };
    case "FETCH_ORGANISATION_SERVICES":
      return {
        ...state,
        organisationProviders: action.response.data,
        isLoadinOrganisationProviders: false,
      };
    case "FETCH_ORGANISATION_STATISTIC_FAILURE":
      return {
        ...state,
        isLoadinOrganisationProviders: true,
      };

    case "PATCH_ORGANISATION_SERVICE_REQUEST":
      return {
        ...state,
        errors: false,
        isLoadinOrganisationProviders: true,
      };

    case "PATCH_ORGANISATION_SERVICE":
      return update(state, {
        errors: { $set: false },
        isLoadinOrganisationProviders: { $set: false },
      });

    case "PATCH_ORGANISATION_SERVICE_FAILURE":
      return {
        ...state,
        errors: true,
        isLoadinOrganisationProviders: false,
      };

    case "POST_DEPARTMENT_REQUEST":
      return {
        ...state,
        errors: false,
        isLoadinOrganisationProviders: true,
      };

    case "POST_ORGANISATION_SERVICE":
      return update(state, {
        errors: { $set: false },
        isLoadinOrganisationProviders: { $set: false },
      });

    case "POST_ORGANISATION_SERVICE_FAILURE":
      return {
        ...state,
        errors: true,
        isLoadinOrganisationProviders: false,
      };

    case "DELETE_ORGANISATION_SERVICE_REQUEST":
      return {
        ...state,
        errors: false,
        isLoadinOrganisationProviders: true,
      };
    case "DELETE_ORGANISATION_SERVICE_SUCCEED":
      return {
        ...state,
        errors: false,
        isLoadinOrganisationProviders: false,
      };
    case "DELETE_ORGANISATION_SERVICE_FAILURE":
      return {
        ...state,
        errors: true,
        isLoadinOrganisationProviders: false,
      };

    case "ORGANISATION_CHANGE_TICKET_PRIORITY_RESPONSE_TIME":
      idx = _.findIndex(state.organisation.ticket_priorities, ["id", action.id]);
      if (idx === -1) {
        return null;
      }

      return update(state, {
        organisation: {
          ticket_priorities: {
            [idx]: {
              response_time: { $set: action.value },
            },
          },
        },
      });

    case "ORGANISATION_CHANGE_TICKET_PRIORITY_USE_OPEN_HOURS":
      idx = _.findIndex(state.organisation.ticket_priorities, ["id", action.id]);
      if (idx === -1) {
        return null;
      }

      return update(state, {
        organisation: {
          ticket_priorities: {
            [idx]: {
              use_open_hours: { $set: action.value },
            },
          },
        },
      });

    default:
      return state;
  }
}
