// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import Actions from "../../../actions/customers";
import FaqActions from "../../../actions/faqs";
import CustomersList from "./CustomersList";

class Customers extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.onClearFilters()).then(() => {
      this.props.dispatch(Actions.fetchCustomers());
    })
  };

  onDelete = ids => {
    ids.map(id => {
      this.props.dispatch(Actions.onDelete(id)).then(() => {
        this.props.dispatch(Actions.fetchCustomers());
      });
    });
  };

  onSearch = e => {
    this.props
      .dispatch(Actions.onSearch(e.target.value))
      .then(() => this.props.dispatch(Actions.fetchCustomers()));
  };

  onChangePage = value => {
    this.props
      .dispatch(Actions.onChangePage(value))
      .then(() => this.props.dispatch(Actions.fetchCustomers()));
  };

  onChangeLimit = (current, size) => {
    this.props
      .dispatch(Actions.onChangeLimit(size))
      .then(() => this.props.dispatch(Actions.fetchCustomers()));
  };

  onSortBy = (field, direction) => {
    this.props
      .dispatch(Actions.onSortBy(field, direction))
      .then(() => this.props.dispatch(Actions.fetchCustomers()));
  };

  // faqs
  onFaqToggle = () => {
    this.props.dispatch(FaqActions.onDrawerToggle()).then(() => {
      this.props.dispatch(FaqActions.fetchFaqs(60, 1))
    });
  };

  render() {
    const { isLoading, customers, meta, fetchParams } = this.props;
    return (
      <CustomersList
        loading={isLoading}
        customers={customers}
        meta={meta}
        fetchParams={fetchParams}
        onDelete={this.onDelete}
        onSearch={this.onSearch}
        onChangePage={this.onChangePage}
        onChangeLimit={this.onChangeLimit}
        onSortBy={this.onSortBy}
        // faqs
        onFaqToggle={this.onFaqToggle}
      />
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.customers.isLoading,
  customers: state.customers.customers,
  meta: state.customers.meta,
  fetchParams: state.customers.fetchParams
});

export default connect(mapStateToProps)(Customers);
