import api from "../api";
import _ from "lodash/array";
import { saveAs } from "file-saver";

const Actions = {};

Actions.fetchOrders = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_ORDERS_REQUEST" });
    let users = [];
    let members = [];
    let services = [];
    let params = getState().orders.fetchParams;
    if (params.users) {
      users = params.users.map((user) => {
        if (user && user.id) {
          return user.id;
        }
      });
    }
    if (params.members) {
      members = params.members.map((member) => {
        if (member && member.id) {
          return member.id;
        }
      });
    }

    if (params.services) {
      services = params.services.map((category) => {
        if (category && category.id) {
          return category.id;
        }
      });
    }

    const data = {
      startDate: params.startDate,
      endDate: params.endDate,
      starFinish: params.starFinish,
      endFinish: params.endFinish,
      search: params.search,
      sort: params.sort,
      direction: params.direction,
      page: params.page,
      limit: params.limit,
      status: params.status,
      orderTaskStatus: params.orderTaskStatus,
      is_ordered: params.is_ordered,
      statusTaskType: params.statusTaskType,
      users: users,
      customer: params.customer ? params.customer.id : null,
      members: members,
      services: services,
    };

    // console.log(params)
    return api
      .fetch("/orders", data)
      .then((response) => {
        dispatch({ type: "FETCH_ORDERS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_ORDERS_FAILURE", response });
      });
  };
};

Actions.fetchOrder = (id = null) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ORDER_REQUEST" });
    return api
      .fetch(`/orders/${id}`)
      .then((response) => {
        dispatch({ type: "FETCH_ORDER", response });
      })
      .catch((error) => {
        api.handleError(error, "Заказ");
        dispatch({ type: "FETCH_ORDER_FAILURE" });
      });
  };
};

Actions.onNew = () => {
  return (dispatch, getState) => {
    const currentUser = getState().session.currentUser;
    dispatch({
      type: "ORDER_NEW",
      user: currentUser,
    });
  };
};

Actions.onNewForTask = (taskId) => {
  return (dispatch, getState) => {
    let data = {
      taskId: taskId,
    }

    dispatch({type: "NEW_ORDER_FOR_TASK_REQUEST"});

    return api
      .fetch(`/orders/new_for_task`, data)
      .then((response) => {
        dispatch({type: "NEW_ORDER_FOR_TASK_SUCCESS", response});
      })
      .catch((error) => {
        api.handleError(error, "Заявка");
        dispatch({type: "NEW_ORDER_FOR_TASK_FAILURE"});
      });
  };
};

Actions.onUpdate = (id) => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_ORDER_REQUEST" });
    const order = getState().orders.order;
    const data = {
      order: {
        is_organisation: order.is_organisation,
        is_supplier: order.is_supplier,
        priority: order.priority,
        due_by: order.due_by,
        number_int: order.number_int,
        number: order.number,
        customer_id: order.customer ? order.customer.id : null,
        company_id: order.company ? order.company.id : null,
        entity_id: order.entity ? order.entity.id : null,
        entity_service_id: order.entity_service
          ? order.entity_service.id
          : null,
        amount: order.amount,
        product_price: order.product_price,
        service_price: order.service_price,
        penalty: order.penalty,
        payment: order.payment,
        subject: order.subject,
        description: order.description,
      },
    };

    api
      .patch(`/orders/${id}`, data)
      .then((response) => {
        dispatch({ type: "PATCH_ORDER", response });
        api.openNotification("success", "Заказ", "Успешно обновлен!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Заказ", error);
          });
        }
        dispatch({ type: "PATCH_ORDER_FAILURE", response });
      });
  };
};

Actions.onCreate = () => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_ORDER_REQUEST" });
    const order = getState().orders.order;

    const data = {
      order: {
        is_organisation: order.is_organisation,
        is_supplier: order.is_supplier,
        priority: order.priority,
        status: 1,
        due_by: order.due_by,
        number_int: order.number_int,
        number: order.number,
        customer_id: order.customer ? order.customer.id : null,
        company_id: order.company ? order.company.id : null,
        entity_id: order.entity ? order.entity.id : null,
        entity_service_id: order.entity_service
          ? order.entity_service.id
          : null,
        amount: order.amount,
        subject: order.subject,
        description: order.description,
      },
    };

    return api
      .post(`/orders`, data)
      .then((response) => {
        dispatch({ type: "POST_ORDER", response });
        api.openNotification("success", "Заказ", "Успешно создан!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Заказ", error);
          });
        }
        dispatch({ type: "POST_ORDER_FAILURE", response });
      });
  };
};

Actions.onCreateForTask = (taskId) => {
  return (dispatch, getState) => {
    dispatch({ type: "CREATE_ORDER_FOR_TASK_REQUEST" });

    let order = getState().orders.order;

    let data = {
      taskId: taskId,
      order: {
        is_organisation: order.is_organisation,
        is_supplier: order.is_supplier,
        priority: order.priority,
        status: 1,
        due_by: order.due_by,
        number_int: order.number_int,
        number: order.number,
        customer_id: order.customer ? order.customer.id : null,
        company_id: order.company ? order.company.id : null,
        entity_id: order.entity ? order.entity.id : null,
        entity_service_id: order.entity_service
          ? order.entity_service.id
          : null,
        amount: order.amount,
        subject: order.subject,
        description: order.description,
      },
    };

    return api
      .post(`/orders/create_for_task`, data)
      .then((response) => {
        dispatch({ type: "CREATE_ORDER_FOR_TASK_SUCCESS", response });
        api.openNotification("success", "Заказ", "Успешно создан!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Заказ", error);
          });
        }
        dispatch({ type: "CREATE_ORDER_FOR_TASK_FAILURE", response });
      });
  };
};

Actions.onDelete = (id) => {
  return (dispatch) => {
    dispatch({ type: "DELETE_ORDER_REQUEST" });
    return api
      .delete(`/orders/${id}`)
      .then((response) => {
        api.openNotification("success", "Заказ", "Успешно удален!");
        dispatch({ type: "DELETE_ORDER", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Заказ", error);
          });
        }
        dispatch({
          type: "DELETE_ORDER_FAILURE",
        });
      });
  };
};

// assignees
Actions.fetchAssignees = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ASSIGNEES_REQUEST" });
    api
      .fetch(`/orders/${id}/order_assignees`)
      .then((response) => {
        dispatch({ type: "FETCH_ASSIGNEES", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_ASSIGNEES_FAILURE", response });
      });
  };
};

Actions.onCreateAssignee = (id, assignee, type) => {
  return (dispatch) => {
    dispatch({ type: "POST_ASSIGNEE_REQUEST" });
    return api
      .post(`/orders/${id}/order_assignees`, {
        order_assignee: {
          assignee_type: type,
          user_id:
            type == "organisation" || type == "supervisor" ? assignee.id : null,
          member_id:
            type == "customer" ? assignee.id : null,
          employee_id:
            type == "company"  ? assignee.id : null,
        },
      })
      .then((response) => {
        dispatch({ type: "POST_ASSIGNEE", response });
        api.openNotification("success", "Ответственный", "Успешно добавлен!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Ответственный", error);
          });
        }
        dispatch({ type: "POST_ASSIGNEE_FAILURE", response });
      });
  };
};

Actions.onDeleteAssignee = (order_id, id) => {
  return (dispatch) => {
    dispatch({
      type: "ASSIGNEE_DELETE_REQUEST",
    });
    return api
      .delete(`/orders/${order_id}/order_assignees/${id}`)
      .then((response) => {
        api.openNotification("success", "Ответственный", "Успешно удален!");
        dispatch({ type: "ASSIGNEE_DELETE_SUCCEED", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Ответственный", error);
          });
        }
        dispatch({ type: "ASSIGNEE_DELETE_FAILED" });
      });
  };
};

// proposals
Actions.fetchProposals = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ORDER_PROPOSALS_REQUEST" });
    api
      .fetch(`/orders/${id}/order_proposals`)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_ORDER_PROPOSALS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_ORDER_PROPOSALS_FAILURE", response });
      });
  };
};

Actions.onUploadProposal = (id, file) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_PROPOSAL_UPLOAD",
    });
    const data = {
      name: file.name,
      content_type: file.content_type,
      attachment_type: file.attachment_type,
      attachment: file.attachment,
    };

    return api
      .post(`/orders/${id}/order_proposals`, data)
      .then((response) => {
        api.openNotification("success", "Файл", "Успешно загружен!");
        dispatch({ type: "ORDER_PROPOSAL_UPLOAD_SUCCEED", response });
      })
      .catch((response) => {
        dispatch({
          type: "ORDER_PROPOSAL_UPLOAD_FAILED",
        });
        dispatch({
          type: "ADD_NOTIFICATION",
          message: { label: response.error, type: "error" },
        });
      });
  };
};

Actions.onDeleteProposal = (order_id, id) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_PROPOSAL_DELETE_REQUEST",
    });
    return api
      .delete(`/orders/${order_id}/order_proposals/${id}`)
      .then((response) => {
        api.openNotification("success", "Файл", "Успешно удален!");
        dispatch({ type: "ORDER_PROPOSAL_DELETE_SUCCEED", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Файл", error);
          });
        }
        dispatch({ type: "ORDER_PROPOSAL_DELETE_FAILED" });
      });
  };
};

// attachments
Actions.fetchAttachments = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ATTACHMENTS_REQUEST" });
    api
      .fetch(`/orders/${id}/order_attachments`)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_ATTACHMENTS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_ATTACHMENTS_FAILURE", response });
      });
  };
};

Actions.onUploadAttachment = (id, file, commentId) => {
  return (dispatch) => {
    dispatch({
      type: "ATTACHMENT_UPLOAD",
    });

    const data = {
      order_comment_id: commentId,
      name: file.name,
      content_type: file.content_type,
      attachment_type: file.attachment_type,
      attachment: file.attachment,
    };

    return api
      .post(`/orders/${id}/order_attachments`, data)
      .then((response) => {
        api.openNotification("success", "Файл", "Успешно загружен!");
        dispatch({ type: "ATTACHMENT_UPLOAD_SUCCEED", response });
      })
      .catch((response) => {
        dispatch({
          type: "ATTACHMENT_UPLOAD_FAILED",
        });
        dispatch({
          type: "ADD_NOTIFICATION",
          message: { label: response.error, type: "error" },
        });
      });
  };
};

Actions.onDeleteAttachment = (entity_task_id, id) => {
  return (dispatch) => {
    dispatch({
      type: "ATTACHMENT_DELETE_REQUEST",
    });
    return api
      .delete(`/orders/${entity_task_id}/order_attachments/${id}`)
      .then((response) => {
        api.openNotification("success", "Файл", "Успешно удален!");
        dispatch({ type: "ATTACHMENT_DELETE_SUCCEED", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Файл", error);
          });
        }
        dispatch({ type: "ATTACHMENT_DELETE_FAILED" });
      });
  };
};

// order_tasks
Actions.fetchOrderTasks = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ORDER_ORDER_TASKS_REQUEST" });
    api
      .fetch(`/order_tasks`, { order_id: id })
      .then((response) => {
        dispatch({ type: "FETCH_ORDER_ORDER_TASKS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_ORDER_ORDER_TASKS_FAILURE", response });
      });
  };
};

Actions.onCreateOrderTask = (id, task) => {
  return (dispatch) => {
    dispatch({ type: "POST_ORDER_ORDER_TASK_REQUEST" });
    const assignees = task.assignees.map((assignee) => {
      return {
        assignee_type: assignee.assignee_type,
        user_id: assignee.assignee_type == "organisation" ? assignee.id : null,
        member_id:
          assignee.assignee_type == "organisation" ? null : assignee.id,
      };
    });

    const data = {
      order_task: {
        order_id: id,
        task_type: task.task_type,
        subject: task.subject,
        description: task.description,
        due_from: task.due_from,
        due_by: task.due_by,
        plan_days: task.plan_days,
        order_task_assignees_attributes: assignees,
      },
    };
    return api
      .post(`/order_tasks`, data)
      .then((response) => {
        dispatch({ type: "POST_ORDER_ORDER_TASK", response });
        api.openNotification("success", "Задача", "Успешно добавлено!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Задача", error);
          });
        }
        dispatch({ type: "POST_ORDER_ORDER_TASK_FAILURE", response });
      });
  };
};

// schedules
Actions.fetchSchedules = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ORDER_SCHEDULES_REQUEST" });
    api
      .fetch(`/order_schedules`, { order_id: id })
      .then((response) => {
        dispatch({ type: "FETCH_ORDER_SCHEDULES", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_ORDER_SCHEDULES_FAILURE", response });
      });
  };
};

Actions.onUpdateSchedule = (id, schedule) => {
  return (dispatch) => {
    dispatch({ type: "PATCH_ORDER_SCHEDULE_REQUEST" });
    const data = {
      order_schedule: {
        order_id: id,
        schedule_type: schedule.schedule_type,
        name: schedule.name,
        start: schedule.start,
        finish: schedule.finish,
      },
    };
    return api
      .patch(`/order_schedules/${schedule.id}`, data)
      .then((response) => {
        dispatch({ type: "PATCH_ORDER_SCHEDULE", response });
        api.openNotification("success", "График", "Успешно обновлен!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "График", error);
          });
        }
        dispatch({ type: "PATCH_ORDER_SCHEDULE_FAILURE", response });
      });
  };
};

Actions.onCreateSchedule = (id, schedule) => {
  return (dispatch) => {
    dispatch({ type: "POST_ORDER_SCHEDULE_REQUEST" });
    const data = {
      order_schedule: {
        order_id: id,
        schedule_type: schedule.schedule_type,
        name: schedule.name,
        start: schedule.start,
        finish: schedule.finish,
      },
    };
    return api
      .post(`/order_schedules`, data)
      .then((response) => {
        dispatch({ type: "POST_ORDER_SCHEDULE", response });
        api.openNotification("success", "График", "Успешно добавлен!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "График", error);
          });
        }
        dispatch({ type: "POST_ORDER_SCHEDULE_FAILURE", response });
      });
  };
};

Actions.onDeleteSchedule = (id) => {
  return (dispatch) => {
    dispatch({
      type: "DELETE_ORDER_SCHEDULE_REQUEST",
    });
    return api
      .delete(`/order_schedules/${id}`)
      .then((response) => {
        api.openNotification("success", "График", "Успешно удален!");
        dispatch({ type: "DELETE_ORDER_SCHEDULE", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "График", error);
          });
        }
        dispatch({ type: "DELETE_ORDER_SCHEDULE_FAILURE" });
      });
  };
};

// activities
Actions.fetchOrderActivities = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ORDER_ACTIVITIES_REQUEST" });
    return api
      .fetch(`/orders/${id}/order_activities`)
      .then((response) => {
        dispatch({ type: "FETCH_ORDER_ACTIVITIES", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Заявка", error);
          });
        }
        dispatch({
          type: "FETCH_ORDER_ACTIVITIES_FAILURE",
        });
      });
  };
};

// comments
Actions.fetchOrderComments = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ORDER_COMMENTS_REQUEST" });
    api
      .fetch(`/orders/${id}/order_comments`)
      .then((response) => {
        dispatch({ type: "FETCH_ORDER_COMMENTS", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Статус", error);
          });
        }
        dispatch({ type: "FETCH_ORDER_COMMENTS_FAILURE", response });
      });
  };
};

Actions.onCreateComment = (id, comment, mentioned) => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_ORDER_COMMENT_REQUEST" });

    const mentioned_user_ids = mentioned.users
      ?.map((it) => it.id)
      ?.map((it) => parseInt(it) || null)
      ?.filter((it) => it !== null);
    const mentioned_member_ids = mentioned.members
      ?.map((it) => it.id)
      ?.map((it) => parseInt(it) || null)
      ?.filter((it) => it !== null);
    const mentioned_emails = mentioned.emails
      ?.map((it) => it.value || null)
      ?.filter((it) => it !== null && it.trim() !== '');

    return api.post(`/orders/${id}/order_comments`, {
        order_comment: {
          comment: comment,
          mentioned_user_ids: mentioned_user_ids,
          mentioned_member_ids: mentioned_member_ids,
          mentioned_emails: mentioned_emails,
        },
      })
      .then((response) => {
        dispatch({ type: "POST_ORDER_COMMENT", response });
        api.openNotification("success", "Комментарий", "Успешно создан!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Комментарий", error);
          });
        }
        dispatch({ type: "POST_ORDER_COMMENT_FAILURE", response });
      });
  };
};

Actions.onSearch = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_ORDER_SEARCH",
      value: value,
    });
    return Promise.resolve();
  };
};

//general
Actions.onChangeDueBy = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_CHANGE_DUE_BY",
      value: value,
    });
  };
};

Actions.onChangeNumberInt = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_CHANGE_NUMBER_INT",
      value: value,
    });
  };
};

Actions.onChangeNumber = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_CHANGE_NUMBER",
      value: value,
    });
  };
};

Actions.onChangeCustomer = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_CHANGE_CUSTOMER",
      value: value,
    });
  };
};

Actions.onChangeEntity = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_CHANGE_CUSTOMER_ENTITY",
      value: value,
    });
  };
};

Actions.onChangeEntityService = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_CHANGE_ENTITY_SERVICE",
      value: value,
    });
  };
};

Actions.onChangeAmount = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_CHANGE_AMOUNT",
      value: value,
    });
  };
};

Actions.onChangeSubject = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_CHANGE_SUBJECT",
      value: value,
    });
  };
};

Actions.onChangeDescription = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_CHANGE_DESCRIPTION",
      value: value,
    });
  };
};

// status
Actions.onChangeStatus = (id, status) => {
  return (dispatch) => {
    dispatch({ type: "PATCH_ORDER_REQUEST_STATUS" });
    const data = { status: status };

    return api
      .patch(`/orders/${id}/status`, data)
      .then((response) => {
        api.openNotification("success", "Заказ", "Статус изменен!");
        dispatch({ type: "PATCH_ORDER_STATUS", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Заказ", error);
          });
        }
        dispatch({
          type: "PATCH_ORDER_FAILURE_STATUS",
        });
      });
  };
};

// tasks
Actions.fetchTasks = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ORDER_TASKS_REQUEST" });
    return api
      .fetch(`/orders/${id}/tasks`)
      .then((response) => {
        dispatch({ type: "FETCH_ORDER_TASKS", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Задачи", error);
          });
        }
        dispatch({
          type: "FETCH_ORDER_TASKS_FAILURE",
        });
      });
  };
};

Actions.onChangeIsOrganisation = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_CHANGE_IS_ORGANISATION",
      value: value,
    });
  };
};

Actions.onChangeIsSupplier = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_CHANGE_IS_SUPPLIER",
      value: value,
    });
  };
};

Actions.onChangePriority = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_CHANGE_PRIORITY",
      value: value,
    });
  };
};

Actions.onChangeCompany = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_CHANGE_COMPANY",
      value: value,
    });
  };
};


// filters
Actions.onSetFilters = () => {
  return (dispatch) => {
    dispatch({
      type: "ORDERS_SET_FILTERS",
    });
    return Promise.resolve();
  };
};

Actions.onClearFilters = () => {
  return (dispatch) => {
    dispatch({
      type: "ORDERS_CLEAR_FILTERS",
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterStatus = (values) => {
  return (dispatch) => {
    dispatch({
      type: "ORDERS_FILTER_STATUS",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterOrderTaskStatus = (statusTaskType, value) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_TASKS_FILTER_STATUS",
      statusTaskType: statusTaskType,
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterOrdered = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORDERS_FILTER_ORDERED",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterPeriod = (startDate, endDate) => {
  return (dispatch) => {
    dispatch({
      type: "ORDERS_FILTER_PERIOD",
      startDate: startDate,
      endDate: endDate,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterPeriodFinish = (starFinish, endFinish) => {
  return (dispatch) => {
    dispatch({
      type: "ORDERS_FILTER_PERIOD_FINISH",
      starFinish: starFinish,
      endFinish: endFinish,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterUsers = (values) => {
  return (dispatch) => {
    dispatch({
      type: "ORDERS_FILTER_USERS",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterCustomer = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORDERS_FILTER_CUSTOMER",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterMembers = (values) => {
  return (dispatch) => {
    dispatch({
      type: "ORDERS_FILTER_MEMBERS",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterServices = (values) => {
  return (dispatch) => {
    dispatch({
      type: "ORDERS_FILTER_SERVICES",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.onChangePage = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_ORDER_CHANGE_PAGE",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeLimit = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_ORDER_CHANGE_LIMIT",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onSortBy = (field, direction) => {
  return (dispatch) => {
    dispatch({
      type: "ORDERS_SORT_BY",
      field: field,
      direction: direction,
    });
    return Promise.resolve();
  };
};

// finance
Actions.onChangeProductPrice = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_CHANGE_PRODUCT_PRICE",
      value: value == "" || value == null ? 0 : value,
    });
  };
};

Actions.onChangeServicePrice = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_CHANGE_SERVICE_PRICE",
      value: value == "" || value == null ? 0 : value,
    });
  };
};

Actions.onChangePenalty = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_CHANGE_PENALTY",
      value: value,
    });
  };
};

Actions.onChangePayment = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORDER_CHANGE_PAYMENT",
      value: value,
    });
  };
};

Actions.downloadReport = (params, url = "", filename = "") => {
  let users = [];
  let members = [];
  let services = [];

  if (params.users) {
    users = params.users.map((user) => {
      if (user && user.id) {
        return user.id;
      }
    });
  }
  if (params.members) {
    members = params.members.map((member) => {
      if (member && member.id) {
        return member.id;
      }
    });
  }

  if (params.services) {
    services = params.services.map((category) => {
      if (category && category.id) {
        return category.id;
      }
    });
  }

  const data = {
    startDate: params.startDate,
    endDate: params.endDate,
    starFinish: params.starFinish,
    endFinish: params.endFinish,
    search: params.search,
    sort: params.sort,
    direction: params.direction,
    page: params.page,
    limit: 1000,
    status: params.status,
    orderTaskStatus: params.orderTaskStatus,
    is_ordered: params.is_ordered,
    statusTaskType: params.statusTaskType,
    users: users,
    customer: params.customer ? params.customer.id : null,
    members: members,
    services: services,
  };

  api
    .fetchBlob(`/${url}`, data)
    .then((response) => {
      //console.log(response)
      saveAs(response, `${filename}`);
    })
    .catch((response) => {
      console.log(response.error);
    });
};

// notices
Actions.onClearNotices = () => {
  return (dispatch) => {
    dispatch({ type: "POST_ORDER_MARK_ALL_AS_READ_REQUEST" });

    return api
      .post(`/order_comment_marks/mark_all_as_read`)
      .then((response) => {
        api.openNotification(
          "success",
          "Заказы",
          "Все комментарии отмечены как прочитанные!"
        );
        dispatch({ type: "POST_ORDER_MARK_ALL_AS_READ", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Заказы", error);
          });
        }
        dispatch({
          type: "POST_ORDER_MARK_ALL_AS_READ_FAILURE",
        });
      });
  };
};

export default Actions;
