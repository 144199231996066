// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Input, Col, Row } from "antd";

const FormItem = Form.Item;

class Details extends Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    const {
      details,
      getFieldDecorator,
      onChangeInn,
      onChangeKpp,
      onChangeOgrn
    } = this.props;

    return (
      <Row gutter={16}>
        <Col md={{ span: 12 }} xs={{ span: 12 }}>
          <FormItem label={"ИНН"}>
            {getFieldDecorator("inn", {
              onChange: onChangeInn,
              initialValue: details.inn ? details.inn : null
            })(<Input />)}
          </FormItem>
        </Col>
        <Col md={{ span: 12 }} xs={{ span: 12 }}>
          <FormItem label={"КПП"}>
            {getFieldDecorator("kpp", {
              onChange: onChangeKpp,
              initialValue: details.kpp ? details.kpp : null
            })(<Input />)}
          </FormItem>
        </Col>
        <Col md={{ span: 12 }} xs={{ span: 12 }}>
          <FormItem label={"ОГРН"}>
            {getFieldDecorator("ogrn", {
              onChange: onChangeOgrn,
              initialValue: details.ogrn ? details.ogrn : null
            })(<Input />)}
          </FormItem>
        </Col>
      </Row>
    );
  }
}

Details.propTypes = {
  customer: PropTypes.object,
  onChangeDate: PropTypes.func,
  onChangeAssignee: PropTypes.func,
  onChangeAgreement: PropTypes.func,
  onChangeAgreementDate: PropTypes.func,
  onChangeAdditional: PropTypes.func,
  onChangeComment: PropTypes.func,
  onChangeNotice: PropTypes.func
};

export default Details;
