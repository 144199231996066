// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  PageHeader,
  Form,
  Table,
  DatePicker,
  Select,
  Typography,
  Popover,
  Button,
} from "antd";
import _ from "lodash/array";
import Filter from "./Filter";

import PropTypes from "prop-types";
import accounting from "accounting";

import moment from "moment";
import "moment/locale/ru";

const { Title } = Typography;
const { RangePicker } = DatePicker;
const Option = Select.Option;
const FormItem = Form.Item;

class DataList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFilterVisible: false,
    };
  }

  static contextTypes = {
    router: PropTypes.object,
  };

  onFilterToggle = () => {
    this.setState({ isFilterVisible: !this.state.isFilterVisible });
  };

  render() {
    const { isFilterVisible } = this.state;
    const {
      height,
      threshold,
      loading,
      tickets,
      fetchParams,
      period,
      onChangeDateRange,
      onChangePeriod,
      onChangeRoles,
      onChangeThreshold,
      onChangeCustomers,
      onChangeDepartments,
    } = this.props;

    // render: (effectiveness, record) => `${Number(record.closed / record.total * 100).toFixed(2)}%`,

    const dateFormat = "DD.MM.YYYY HH:mm";
    const { getFieldDecorator } = this.props.form;

    const columns = [
      {
        title: "Имя",
        dataIndex: "name",
        fixed: "left",
        align: "left",
        width: 250,
      },
      {
        title: "Заявки",
        children: [
          {
            title: (
              <Popover
                content={"Все созданные заявки в указанном периоде"}
                title="Правило расчета"
                trigger="hover"
              >
                Создано
              </Popover>
            ),
            dataIndex: "tickets_total",
            render: (tickets_total) =>
              tickets_total > 0
                ? accounting.formatNumber(tickets_total, 0, " ")
                : null,
            align: "center",
            width: 100,
          },
          {
            title: (
              <Popover
                content={
                  <div style={{ maxWidth: 250 }}>
                    Все завершенные заявки в указанном периоде, независимо от
                    даты создания.
                  </div>
                }
                title="Правило расчета"
                trigger="hover"
              >
                Выполнено
              </Popover>
            ),
            dataIndex: "tickets_finished",
            render: (tickets_finished) =>
              tickets_finished > 0
                ? accounting.formatNumber(tickets_finished, 0, " ")
                : null,
            align: "center",
            width: 100,
          },
          {
            title: (
              <Popover
                content={
                  <div style={{ maxWidth: 250 }}>
                    Все завершенные заявки в указанном периоде, независимо от
                    даты создания и при этом плановая дата меньше или равна дате
                    выполнения.
                  </div>
                }
                title="Правило расчета"
                trigger="hover"
              >
                В срок
              </Popover>
            ),
            dataIndex: "tickets_intime",
            render: (tickets_intime) =>
              tickets_intime > 0
                ? accounting.formatNumber(tickets_intime, 0, " ")
                : null,
            align: "center",
            width: 100,
          },
          {
            title: (
              <Popover
                content={
                  <div style={{ maxWidth: 250 }}>
                    (В срок / Выполнено) * 100
                  </div>
                }
                title="Правило расчета"
                trigger="hover"
              >
                %
              </Popover>
            ),
            dataIndex: "tickets_percent",
            render: (tickets_percent, record) => {
              return (
                <span
                  style={{
                    color: `${
                      Number(record.tickets_intime) /
                        Number(record.tickets_finished) >
                      threshold
                        ? "#87d068"
                        : "#f50"
                    }`,
                  }}
                >
                  {record.tickets_intime > 0
                    ? accounting.formatNumber(
                        (Number(record.tickets_intime) /
                          Number(record.tickets_finished)) *
                          100,
                        2,
                        " "
                      )
                    : null}
                </span>
              );
            },
            align: "center",
            width: 100,
          },
          {
            title: (
              <Popover
                content={
                  <div style={{ maxWidth: 250 }}>
                    Незавершенные заявки на конечную дату выбранного периода,
                    независимо от даты создания.
                  </div>
                }
                title="Правило расчета"
                trigger="hover"
              >
                В работе
              </Popover>
            ),
            dataIndex: "tickets_progress",
            render: (tickets_progress) =>
              tickets_progress > 0
                ? accounting.formatNumber(tickets_progress, 0, " ")
                : null,
            align: "center",
            width: 100,
          },
        ],
      },
      {
        title: "Заявки c заменой",
        children: [
          {
            title: (
              <Popover
                content={"Созданные заявки c заменой в указанном периоде"}
                title="Правило расчета"
                trigger="hover"
              >
                Создано
              </Popover>
            ),
            dataIndex: "replaced_tickets_total",
            render: (replaced_tickets_total) =>
              replaced_tickets_total > 0
                ? accounting.formatNumber(replaced_tickets_total, 0, " ")
                : null,
            align: "center",
            width: 100,
          },
          {
            title: (
              <Popover
                content={
                  <div style={{ maxWidth: 250 }}>
                    Завершенные заявки c заменой в указанном периоде, независимо
                    от даты создания.
                  </div>
                }
                title="Правило расчета"
                trigger="hover"
              >
                Выполнено
              </Popover>
            ),
            dataIndex: "replaced_tickets_finished",
            render: (replaced_tickets_finished) =>
              replaced_tickets_finished > 0
                ? accounting.formatNumber(replaced_tickets_finished, 0, " ")
                : null,
            align: "center",
            width: 100,
          },
          {
            title: (
              <Popover
                content={
                  <div style={{ maxWidth: 250 }}>
                    Завершенные заявки c заменой в указанном периоде, независимо
                    от даты создания и при этом плановая дата меньше или равна
                    дате выполнения.
                  </div>
                }
                title="Правило расчета"
                trigger="hover"
              >
                В срок
              </Popover>
            ),
            dataIndex: "replaced_tickets_intime",
            render: (replaced_tickets_intime) =>
              replaced_tickets_intime > 0
                ? accounting.formatNumber(replaced_tickets_intime, 0, " ")
                : null,
            align: "center",
            width: 100,
          },
          {
            title: (
              <Popover
                content={
                  <div style={{ maxWidth: 250 }}>
                    (В срок / Выполнено) * 100
                  </div>
                }
                title="Правило расчета"
                trigger="hover"
              >
                %
              </Popover>
            ),
            dataIndex: "replaced_tickets_percent",
            render: (replaced_tickets_percent, record) => {
              return (
                <span
                  style={{
                    color: `${
                      Number(record.replaced_tickets_intime) /
                        Number(record.replaced_tickets_finished) >
                      threshold
                        ? "#87d068"
                        : "#f50"
                    }`,
                  }}
                >
                  {record.replaced_tickets_intime > 0
                    ? accounting.formatNumber(
                        (Number(record.replaced_tickets_intime) /
                          Number(record.replaced_tickets_finished)) *
                          100,
                        2,
                        " "
                      )
                    : null}
                </span>
              );
            },
            align: "center",
            width: 100,
          },
          {
            title: (
              <Popover
                content={
                  <div style={{ maxWidth: 250 }}>
                    Незавершенные заявки c заменой на конечную дату выбранного
                    периода, независимо от даты создания.
                  </div>
                }
                title="Правило расчета"
                trigger="hover"
              >
                В работе
              </Popover>
            ),
            dataIndex: "replaced_tickets_progress",
            render: (replaced_tickets_progress) =>
              replaced_tickets_progress > 0
                ? accounting.formatNumber(replaced_tickets_progress, 0, " ")
                : null,
            align: "center",
            width: 100,
          },
        ],
      },
      {
        title: "Регламент",
        children: [
          {
            title: (
              <Popover
                content={"Cозданный регламент в указанном периоде"}
                title="Правило расчета"
                trigger="hover"
              >
                Создано
              </Popover>
            ),
            dataIndex: "entity_tasks_total",
            render: (entity_tasks_total) =>
              entity_tasks_total > 0
                ? accounting.formatNumber(entity_tasks_total, 0, " ")
                : null,
            align: "center",
            width: 100,
          },
          {
            title: (
              <Popover
                content={
                  <div style={{ maxWidth: 250 }}>
                    Завершенный регламент в указанном периоде, независимо от
                    даты создания.
                  </div>
                }
                title="Правило расчета"
                trigger="hover"
              >
                Выполнено
              </Popover>
            ),
            dataIndex: "entity_tasks_finished",
            render: (entity_tasks_finished) =>
              entity_tasks_finished > 0
                ? accounting.formatNumber(entity_tasks_finished, 0, " ")
                : null,
            align: "center",
            width: 100,
          },
          {
            title: (
              <Popover
                content={
                  <div style={{ maxWidth: 250 }}>
                    Завершенный регламент в указанном периоде, независимо от
                    даты создания и при этом плановая дата меньше или равна дате
                    выполнения.
                  </div>
                }
                title="Правило расчета"
                trigger="hover"
              >
                В срок
              </Popover>
            ),
            dataIndex: "entity_tasks_intime",
            render: (entity_tasks_intime) =>
              entity_tasks_intime > 0
                ? accounting.formatNumber(entity_tasks_intime, 0, " ")
                : null,
            align: "center",
            width: 100,
          },
          {
            title: (
              <Popover
                content={
                  <div style={{ maxWidth: 250 }}>
                    (В срок / Выполнено) * 100
                  </div>
                }
                title="Правило расчета"
                trigger="hover"
              >
                %
              </Popover>
            ),
            dataIndex: "entity_tasks_percent",
            render: (entity_tasks_percent, record) => {
              return (
                <span
                  style={{
                    color: `${
                      Number(record.entity_tasks_intime) /
                        Number(record.entity_tasks_finished) >
                      threshold
                        ? "#87d068"
                        : "#f50"
                    }`,
                  }}
                >
                  {record.entity_tasks_intime > 0
                    ? accounting.formatNumber(
                        (Number(record.entity_tasks_intime) /
                          Number(record.entity_tasks_finished)) *
                          100,
                        2,
                        " "
                      )
                    : null}
                </span>
              );
            },
            align: "center",
            width: 100,
          },
          {
            title: (
              <Popover
                content={
                  <div style={{ maxWidth: 250 }}>
                    Незавершенные регламент на конечную дату выбранного периода,
                    независимо от даты создания.
                  </div>
                }
                title="Правило расчета"
                trigger="hover"
              >
                В работе
              </Popover>
            ),
            dataIndex: "entity_tasks_progress",
            render: (entity_tasks_progress) =>
              entity_tasks_progress > 0
                ? accounting.formatNumber(entity_tasks_progress, 0, " ")
                : null,
            align: "center",
            width: 100,
          },
        ],
      },
      {
        title: "Заказы",
        children: [
          {
            title: (
              <Popover
                content={"Созданные заказы в указанном периоде"}
                title="Правило расчета"
                trigger="hover"
              >
                Создано
              </Popover>
            ),
            dataIndex: "orders_total",
            render: (orders_total) =>
              orders_total > 0
                ? accounting.formatNumber(orders_total, 0, " ")
                : null,
            align: "center",
            width: 100,
          },
          {
            title: (
              <Popover
                content={
                  <div style={{ maxWidth: 250 }}>
                    Выполненные заказы в указанном периоде, независимо от даты
                    создания.
                  </div>
                }
                title="Правило расчета"
                trigger="hover"
              >
                Выполнено
              </Popover>
            ),
            dataIndex: "orders_finished",
            render: (orders_finished) =>
              orders_finished > 0
                ? accounting.formatNumber(orders_finished, 0, " ")
                : null,
            align: "center",
            width: 100,
          },
          {
            title: (
              <Popover
                content={
                  <div style={{ maxWidth: 250 }}>
                    Незавершенные заказы на конечную дату выбранного периода,
                    независимо от даты создания.
                  </div>
                }
                title="Правило расчета"
                trigger="hover"
              >
                В работе
              </Popover>
            ),
            dataIndex: "orders_progress",
            render: (orders_progress) =>
              orders_progress > 0
                ? accounting.formatNumber(orders_progress, 0, " ")
                : null,
            align: "center",
            width: 100,
          },
        ],
      },
    ];

    let filterButton = (
      <Button
        type={
          fetchParams.startDate ||
          fetchParams.endDate ||
          fetchParams.roles ||
          fetchParams.departments ||
          fetchParams.customers
            ? "primary"
            : "ghost"
        }
        key="3"
        icon="filter"
        onClick={this.onFilterToggle}
      />
    );

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Выполнение плана по заявкам и регламенту за период c ${fetchParams.startDate} по ${fetchParams.endDate}`}
        extra={[filterButton]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }} style={{ paddingBottom: 15 }}>
            <Col span={24}>
              <Table
                bordered
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={tickets}
                scroll={{ x: 1300, y: height - 280 }}
                // scroll={{ x: 1200, y: height - 410 }}
                rowKey="id"
              />
            </Col>
          </Row>
        }
      >
        <Filter
          visible={isFilterVisible}
          fetchParams={fetchParams}
          period={period}
          threshold={threshold}
          onClose={this.onFilterToggle}
          onChangeDateRange={onChangeDateRange}
          onChangePeriod={onChangePeriod}
          onChangeRoles={onChangeRoles}
          onChangeThreshold={onChangeThreshold}
          onChangeCustomers={onChangeCustomers}
          onChangeDepartments={onChangeDepartments}
        />
      </PageHeader>
    );
  }
}

DataList.propTypes = {
  payments: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default Form.create()(DataList);
