// @flow
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Row, Col, Button, Card } from "antd";
import { Spin } from "antd";
import Actions from "../../../actions/services";
import CategoriesTree from "./CategoriesTree";

class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedRowKeys: [], selectedKeys: [] };
  }

  componentWillMount = () => {
    const categoryID =
      this.props.match.params.categoryID == "all"
        ? null
        : this.props.match.params.categoryID;
    this.props
      .dispatch(Actions.fetchCategories())
      .then(() => this.props.dispatch(Actions.onSelectCategory(categoryID)));
    this.setState({ selectedKeys: [`${this.props.match.params.categoryID}`] });
  };

  componentDidUpdate = (prevProps) => {
    if (
      this.props.match.params.categoryID !== prevProps.match.params.categoryID
    ) {
      const categoryID =
        this.props.match.params.categoryID == "all"
          ? null
          : this.props.match.params.categoryID;
      this.props.dispatch(Actions.onSelectCategory(categoryID));
    }
  };

  onSearch = (e) => {
    this.props
      .dispatch(Actions.onSearch(e.target.value))
      .then(() => this.props.dispatch(Actions.fetchProducts()));
  };

  onAddCategory = (ancestry) => {
    this.props.dispatch(Actions.onNewCategory(ancestry))
  }

  onSave = () => {
    // console.log(this.props.match.params.categoryID);
    if (this.props.match.params.categoryID) {
      this.props
        .dispatch(Actions.onUpdateCategory(this.props.match.params.categoryID))
        .then(() => this.props.dispatch(Actions.fetchCategories()));
    }
  };

  onCreate = () => {
    // console.log(this.props.match.params.categoryID);
    this.props
      .dispatch(Actions.onCreateCategory())
      .then(() => this.props.dispatch(Actions.fetchCategories()));
  };

  onDelete = (id) => {
    this.props.dispatch(Actions.onDeleteCategory(id)).then(() => {
      if (!this.props.errors) {
        this.props.dispatch(Actions.fetchCategories());
      }
    });
  };

  // category
  fetchCategory = (id) => {
    this.props.dispatch(Actions.fetchCategory(id));
  };

  onChangeName = (e) => {
    this.props.dispatch(Actions.onChangeCategoryName(e.target.value));
  };

  onChangePosition = (value) => {
    this.props.dispatch(Actions.onChangeCategoryPosition(value));
  };

  onChangeCustomer = (value) => {
    this.props.dispatch(Actions.onChangeCategoryCustomer(value));
  };

  onChangeCategoryType = (value) => {
    this.props.dispatch(Actions.onChangeCategoryType(value));
  };

  onChangeExtCode = (e) => {
    this.props.dispatch(Actions.onChangeCategoryExtCode(e.target.value));
  };

  render() {
    const {
      isLoading,
      isCategoryLoading,
      category,
      categories,
      fetchParams,
    } = this.props;

    return isLoading ? (
      <Spin />
    ) : (
      <Col xs={24} sm={24} md={24} lg={7} xl={7}>
        <CategoriesTree
          isCategoryLoading={isCategoryLoading}
          category={category}
          categories={categories}
          selectedKeys={this.state.selectedKeys}
          onAddCategory={this.onAddCategory}
          onCreate={this.onCreate}
          onSave={this.onSave}
          onDelete={this.onDelete}
          fetchCategory={this.fetchCategory}
          onChangeName={this.onChangeName}
          onChangePosition={this.onChangePosition}
          onChangeCustomer={this.onChangeCustomer}
          onChangeCategoryType={this.onChangeCategoryType}
          onChangeExtCode={this.onChangeExtCode}
        />
      </Col>
    );
  }
}

Categories.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  categories: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isLoading: state.services.isCategoriesLoading,
  isCategoryLoading: state.services.isCategoryLoading,
  categories: state.services.categories,
  category: state.services.category,
  fetchParams: state.services.fetchParams,
});

export default connect(mapStateToProps)(withRouter(Categories));
