// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Row, Col, Table, Button } from "antd";
import update from "immutability-helper";

import moment from "moment";
import "moment/locale/ru";

import ScheduleModal from "./ScheduleModal";

class Schedules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      isVisible: false,
      schedule: {}
    };
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  onDeleteSchedules = () => {
    this.props.onDeleteSchedules(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  onOpenScheduleModal = item => {
    this.setState({ entityService: item }, () => {
      this.setState({ isVisible: true });
    });
  };

  onAddSchedule = () => {
    this.setState(
      {
        schedule: {
          id: null,
          schedule_type: "examination",
          name: null,
          start: moment(),
          finish: moment()
        }
      },
      () => {
        this.setState({ isVisible: true });
      }
    );
  };

  onSaveSchedule = () => {
    this.setState({ isVisible: false }, () => {
      this.props.onSaveSchedule(this.state.schedule);
    });
  };

  onEditSchedule = (record) => {
    this.setState({ schedule: record }, () => {
        this.setState({ isVisible: true });
      }
    );
  };

  onCancel = () => {
    this.setState({ isVisible: false });
  };

  onChangeScheduleType = value => {
    this.setState({
      schedule: update(this.state.schedule, {
        schedule_type: { $set: value }
      })
    });
  };

  onChangeName = e => {
    this.setState({
      schedule: update(this.state.schedule, {
        name: { $set: e.target.value }
      })
    });
  };

  onChangeStart = value => {
    this.setState({
      schedule: update(this.state.schedule, {
        start: { $set: value }
      })
    });
  };

  onChangeFinish = value => {
    this.setState({
      schedule: update(this.state.schedule, {
        finish: { $set: value }
      })
    });
  };

  render() {
    const { schedules, isSchedulesLoading } = this.props;
    const { selectedRowKeys, isVisible, schedule } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    };

    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: "Тип",
        dataIndex: "schedule_type_locale",
        width: "25%",
        align: "center"
      },
      {
        title: "Наименование",
        dataIndex: "name",
        render: (name, record) => (
          <a onClick={this.onEditSchedule.bind(this, record)}>{name}</a>
        ),
        width: "35%",
        align: "center"
      },
      {
        title: "Начало",
        dataIndex: "start",
        render: start => moment(start).format("LLL"),
        width: "20%",
        align: "center"
      },
      {
        title: "Окончание",
        dataIndex: "finish",
        render: finish => moment(finish).format("LLL"),
        width: "20%",
        align: "center"
      }
    ];

    let mainButton = hasSelected ? (
      <Button key="1" type="danger" ghost onClick={this.onDeleteSchedules}>
        Удалить
      </Button>
    ) : (
      <Button onClick={this.onAddSchedule}>Добавить</Button>
    );

    return (
      <div>
        <Row
          gutter={{ md: 8, lg: 24, xl: 48 }}
          style={{ marginBottom: "10px" }}
        >
          <Col span={24}>
            <div>{mainButton}</div>
          </Col>
        </Row>
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col span={24}>
            <Table
              loading={isSchedulesLoading}
              size={"middle"}
              rowSelection={rowSelection}
              bordered
              pagination={false}
              columns={columns}
              dataSource={schedules}
              rowKey="id"
            />
          </Col>
        </Row>
        <ScheduleModal
          visible={isVisible}
          schedule={schedule}
          onSave={this.onSaveSchedule}
          onCancel={this.onCancel}
          onChangeScheduleType={this.onChangeScheduleType}
          onChangeName={this.onChangeName}
          onChangeStart={this.onChangeStart}
          onChangeFinish={this.onChangeFinish}
        />
      </div>
    );
  }
}

Schedules.propTypes = {
  orderSchedules: PropTypes.arrayOf(PropTypes.object),
  total: PropTypes.number,
  discount: PropTypes.object,
  shippingCost: PropTypes.number,
  amountDiscountCode: PropTypes.number,
  onRemoveVariant: PropTypes.func
};
export default Schedules;
