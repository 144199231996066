import update from "immutability-helper";

const initialState = {
  report: [],
  totals: {},
  slae: [],
  tickets: [],
  tasks: [],
  organisation: null,
  employees: [],
  employeeRates: [],
  memberRates: [],
  statistic: { count: 0, references: 0, sent: 0 },
  details: [],
  points: [],
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1,
  },
  errors: false,
  isLoading: true,
  isLoadingDetails: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "FETCH_REPORT_REQUEST":
      return {
        ...state,
        report: [],
        meta: {
          total_pages: 1,
          current_page: 1,
          total_count: 1,
        },
        totals: {},
        isLoading: true,
        errors: false,
      };
    case "FETCH_REPORT":
      return {
        ...state,
        report: action.response.data,
        isLoading: false,
        errors: false,
      };

    case "FETCH_REPORT_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "FETCH_REPORT_SLAE_REQUEST":
      return {
        ...state,
        slae: [],
        totals: {},
        isLoading: true,
        errors: false,
      };
    case "FETCH_REPORT_SLAE":
      return {
        ...state,
        slae: action.response.data,
        isLoading: false,
        errors: false,
      };

    case "FETCH_REPORT_SLAE_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "FETCH_REPORT_OE_REQUEST":
      return {
        ...state,
        employees: [],
        meta: {
          total_pages: 1,
          current_page: 1,
          total_count: 1,
        },
        totals: {},
        isLoading: true,
        errors: false,
      };
    case "FETCH_REPORT_OE":
      return {
        ...state,
        employees: action.response.data,
        isLoading: false,
        errors: false,
      };

    case "FETCH_REPORT_OE_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "FETCH_REPORT_PLAN_REQUEST":
      return {
        ...state,
        tickets: [],
        meta: {
          total_pages: 1,
          current_page: 1,
          total_count: 1,
        },
        totals: {},
        isLoading: true,
        errors: false,
      };
    case "FETCH_REPORT_PLAN":
      return {
        ...state,
        tickets: action.response.data,
        isLoading: false,
        errors: false,
      };

    case "FETCH_REPORT_PLAN_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "FETCH_REPORT_DAILY_REQUEST":
      return {
        ...state,
        tickets: [],
        meta: {
          total_pages: 1,
          current_page: 1,
          total_count: 1,
        },
        totals: {},
        isLoading: true,
        errors: false,
      };
    case "FETCH_REPORT_DAILY":
      return {
        ...state,
        tickets: action.response.data,
        isLoading: false,
        errors: false,
      };

    case "FETCH_REPORT_DAILY_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "FETCH_DAILY_DETAILS_REQUEST":
      return {
        ...state,
        details: [],
        isLoadingDetails: true,
        errors: false,
      };
    case "FETCH_DAILY_DETAILS":
      return {
        ...state,
        details: action.response.data,
        isLoadingDetails: false,
        errors: false,
      };

    case "FETCH_DAILY_DETAILS_FAILURE":
      return {
        ...state,
        isLoadingDetails: false,
        errors: true,
      };

    case "FETCH_REPORT_TASKS_REQUEST":
      return {
        ...state,
        tasks: [],
        meta: {
          total_pages: 1,
          current_page: 1,
          total_count: 1,
        },
        totals: {},
        isLoading: true,
        errors: false,
      };
    case "FETCH_REPORT_TASKS":
      return {
        ...state,
        tasks: action.response.data,
        isLoading: false,
        errors: false,
      };

    case "FETCH_REPORT_TASKS_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "FETCH_TASKS_DETAILS_REQUEST":
      return {
        ...state,
        details: [],
        isLoadingDetails: true,
        errors: false,
      };
    case "FETCH_TASKS_DETAILS":
      return {
        ...state,
        details: action.response.data,
        isLoadingDetails: false,
        errors: false,
      };

    case "FETCH_TASKS_DETAILS_FAILURE":
      return {
        ...state,
        isLoadingDetails: false,
        errors: true,
      };

    case "FETCH_REPORT_ORGANISATION_REQUEST":
      return {
        ...state,
        organisation: null,
        employees: [],
        isLoading: true,
        errors: false,
      };
    case "FETCH_REPORT_ORGANISATION":
      return {
        ...state,
        organisation: action.response.data.organisation,
        employees: action.response.data.departments,
        isLoading: false,
        errors: false,
      };

    case "FETCH_REPORT_ORGANISATION_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "FETCH_ORGANISATION_DETAILS_REQUEST":
      return {
        ...state,
        details: [],
        isLoadingDetails: true,
        errors: false,
      };
    case "FETCH_ORGANISATION_DETAILS":
      return {
        ...state,
        details: action.response.data,
        isLoadingDetails: false,
        errors: false,
      };

    case "FETCH_ORGANISATION_DETAILS_FAILURE":
      return {
        ...state,
        isLoadingDetails: false,
        errors: true,
      };

    case "FETCH_ENTITIES_MAP_REQUEST":
      return {
        ...state,
        points: [],
        isLoading: true,
        errors: false,
      };
    case "FETCH_ENTITIES_MAP":
      return {
        ...state,
        points: action.response.points,
        isLoading: false,
        errors: false,
      };

    case "FETCH_ENTITIES_MAP_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "FETCH_EMPLOYEE_RATES_REQUEST":
      return {
        ...state,
        employeeRates: [],
        memberRates: [],
        statistic: { count: 0, references: 0, sent: 0 },
        isLoading: true,
        errors: false,
      };
    case "FETCH_EMPLOYEE_RATES":
      return {
        ...state,
        employeeRates: action.response.data,
        memberRates: action.response.members,
        statistic: action.response.statistic,
        details: [],
        isLoading: false,
        errors: false,
      };

    case "FETCH_EMPLOYEE_RATES_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "FETCH_EMPLOYEE_RATE_DETAILS_REQUEST":
      return {
        ...state,
        details: [],
        isLoadingDetails: true,
        errors: false,
      };
    case "FETCH_EMPLOYEE_RATE_DETAILS":
      return {
        ...state,
        details: action.response.data,
        isLoadingDetails: false,
        errors: false,
      };

    case "FETCH_EMPLOYEE_RATE_DETAILS_FAILURE":
      return {
        ...state,
        isLoadingDetails: false,
        errors: true,
      };

    case "FETCH_PURCHASES_REQUEST":
      return {
        ...state,
        report: [],
        meta: {
          total_pages: 1,
          current_page: 1,
          total_count: 1,
        },
        totals: {},
        isLoading: true,
        errors: false,
      };
    case "FETCH_PURCHASES":
      return {
        ...state,
        report: action.response.data,
        isLoading: false,
        errors: false,
      };

    case "FETCH_PURCHASES_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    default:
      return state;
  }
}
