// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import Actions from "../../../actions/smsnotifications";
import SmsNotificationsList from "./List";

class SmsNotifications extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.fetchSmsNotifications());
  };

  onSearch = (e) => {
    this.props
      .dispatch(Actions.onSearch(e.target.value))
      .then(() => this.props.dispatch(Actions.fetchSmsNotifications()));
  };

  onChangePage = (value) => {
    this.props
      .dispatch(Actions.onChangePage(value))
      .then(() => this.props.dispatch(Actions.fetchSmsNotifications()));
  };

  onChangeLimit = (current, size) => {
    this.props
      .dispatch(Actions.onChangeLimit(size))
      .then(() => this.props.dispatch(Actions.fetchSmsNotifications()));
  };

  onSortBy = (field, direction) => {
    this.props
      .dispatch(Actions.onSortBy(field, direction))
      .then(() => this.props.dispatch(Actions.fetchSmsNotifications()));
  };

  onFetchStatus = (id) => {
    this.props.dispatch(Actions.onFetchStatus(id));
  }

  render() {
    const { currentUser, isLoading, smsNotifications, meta, fetchParams } =
      this.props;
    return (
      <SmsNotificationsList
        currentUser={currentUser}
        loading={isLoading}
        smsNotifications={smsNotifications}
        onFetchStatus={this.onFetchStatus}
        meta={meta}
        fetchParams={fetchParams}
        onDelete={this.onDelete}
        onSearch={this.onSearch}
        onChangePage={this.onChangePage}
        onChangeLimit={this.onChangeLimit}
        onSortBy={this.onSortBy}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  isLoading: state.smsnotifications.isLoading,
  smsNotifications: state.smsnotifications.smsNotifications,
  meta: state.smsnotifications.meta,
  fetchParams: state.smsnotifications.fetchParams,
});

export default connect(mapStateToProps)(SmsNotifications);
