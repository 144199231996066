import React from "react";
import { Link } from "react-router-dom";
import { Menu, Icon } from "antd";
const SubMenu = Menu.SubMenu;

function commomRoutes(services) {
  return [
    {
      isActive: true,
      path: "/",
      exact: true,
      key: 1,
      name: "Рабочий стол",
      icon: "dashboard",
      subroutes: [],
    },
    {
      isActive: services.tickets,
      path: "/tickets",
      key: 2,
      name: "Заявки",
      icon: "fire",
      subroutes: [],
    },
    {
      isActive: services.entity_tasks,
      path: "/entity_tasks",
      key: 3,
      name: "Регламент",
      icon: "calendar",
      subroutes: [],
    },
  ];
}

function adminRoutes(services) {
  return [
    {
      isActive: services.orders,
      path: null,
      key: 4,
      name: "Заказы",
      icon: "tags",
      subroutes: [
        {
          isActive: services.orders,
          path: "/orders",
          key: 7,
          name: "Заказы",
          icon: "block",
          subroutes: [],
        },
        {
          isActive: services.order_tasks,
          path: "/order_tasks",
          key: 8,
          name: "Задачи по заказам",
          icon: "bars",
          subroutes: [],
        },
      ],
    },
    {
      isActive: services.projects,
      path: "/projects",
      key: 90,
      name: "Проекты",
      icon: "project",
      subroutes: [],
    },
    {
      isActive: services.tasks,
      path: "/tasks",
      key: 100,
      name: "Задачи",
      icon: "check-square",
      subroutes: [],
    },
    {
      isActive: true,
      path: null,
      key: 5,
      name: "Справочники",
      icon: "setting",
      subroutes: [
        {
          isActive: true,
          path: "/customers",
          key: 51,
          name: "Клиенты",
          icon: "team",
          subroutes: [],
        },
        {
          isActive: true,
          path: null,
          key: "entities",
          name: "Объекты",
          icon: "appstore",
          subroutes: [
            {
              isActive: true,
              path: "/entities",
              key: 521,
              name: "Объекты",
              icon: "appstore",
              subroutes: [],
            },
            {
              isActive: true,
              path: "/entity_types",
              key: 522,
              name: "Типы объектов",
              icon: "appstore",
              subroutes: [],
            },
          ],
        },
        {
          isActive: true,
          path: "/organisation",
          key: 53,
          name: "Организация",
          icon: "block",
          subroutes: [],
        },
        {
          isActive: true,
          path: "/suppliers",
          key: 54,
          name: "Подрядчики",
          icon: "audit",
          subroutes: [],
        },
        {
          isActive: true,
          path: "/categories/all/services",
          key: 55,
          name: "Услуги",
          icon: "bars",
          subroutes: [],
        },
        {
          isActive: true,
          path: "/users",
          key: 56,
          name: "Пользователи",
          icon: "user",
          subroutes: [],
        },
        {
          isActive: true,
          path: "/members",
          key: 57,
          name: "Пользователи (клиентские)",
          icon: "user",
          subroutes: [],
        },
        {
          isActive: true,
          path: "/content_tags",
          key: 581,
          name: "Категории (база знаний)",
          icon: "read",
          subroutes: [],
        },
        {
          isActive: true,
          path: "/posts",
          key: 58,
          name: "Новости",
          icon: "edit",
          subroutes: [],
        },
        {
          isActive: true,
          path: null,
          key: 59,
          name: "Поддержка",
          icon: "message",
          subroutes: [
            {
              isActive: true,
              path: "/support_messages",
              key: 591,
              name: "Сообщения",
              icon: "message",
              subroutes: [],
            },
            {
              isActive: true,
              path: "/support_message_types",
              key: 592,
              name: "Типы сообщений",
              icon: "message",
              subroutes: [],
            },
          ],
        },
        {
          isActive: true,
          path: "/sms",
          key: 595,
          name: "SMS",
          icon: "message",
          subroutes: [],
        },
        {
          isActive: true,
          path: null,
          key: "projects",
          name: "Проекты",
          icon: "project",
          subroutes: [
            {
              isActive: true,
              path: "/project_types",
              key: 59,
              name: "Типы проектов",
              icon: "project",
              subroutes: [],
            },
            {
              isActive: true,
              path: "/project_stages",
              key: 60,
              name: "Этапы проектов",
              icon: "project",
              subroutes: [],
            },
          ],
        },
        {
          isActive: true,
          path: null,
          key: "templates",
          name: "Шаблоны",
          icon: "project",
          subroutes: [
            {
              isActive: true,
              path: "/task_templates",
              key: 70,
              name: "Шаблоны задач",
              icon: "project",
              subroutes: [],
            },
            {
              isActive: true,
              path: "/todo_templates",
              key: 71,
              name: "Шаблоны чек-листов",
              icon: "project",
              subroutes: [],
            },
            {
              isActive: true,
              path: "/field_sets",
              key: 72,
              name: "Атрибуты",
              icon: "project",
              subroutes: [],
            },
          ],
        },
      ],
    },
    {
      isActive: true,
      path: null,
      key: 6,
      name: "Отчеты",
      icon: "bar-chart",
      subroutes: [
        {
          isActive: true,
          path: "/reports/sla",
          key: 61,
          name: "SLA выполнения",
          icon: "stock",
          subroutes: [],
        },
        {
          isActive: true,
          path: "/reports/slae",
          key: 62,
          name: "SLA эффективности",
          icon: "stock",
          subroutes: [],
        },
        {
          isActive: true,
          path: "/reports/oe",
          key: 63,
          name: "Коэффициент занятости",
          icon: "stock",
          subroutes: [],
        },
        {
          isActive: true,
          path: "/reports/plan",
          key: 64,
          name: "Выполнение плана",
          icon: "stock",
          subroutes: [],
        },
        {
          isActive: true,
          path: "/reports/daily",
          key: 65,
          name: "Ежедневный отчет",
          icon: "stock",
          subroutes: [],
        },
        {
          isActive: true,
          path: "/reports/tasks",
          key: 66,
          name: "Задачи",
          icon: "stock",
          subroutes: [],
        },
        {
          isActive: true,
          path: "/reports/employees",
          key: 67,
          name: "Структура организации",
          icon: "stock",
          subroutes: [],
        },
        {
          isActive: true,
          path: "/reports/employee_rates",
          key: 68,
          name: "Рейтинг исполнителей",
          icon: "stock",
          subroutes: [],
        },
        {
          isActive: true,
          path: "/reports/purchases",
          key: 69,
          name: "Закупки",
          icon: "stock",
          subroutes: [],
        },
      ],
    },
    {
      isActive: services.payrolls,
      path: null,
      key: 7,
      name: "Финансы",
      icon: "wallet",
      subroutes: [
        {
          isActive: true,
          path: "/finances/payrolls",
          key: 71,
          name: "Начисления",
          icon: "wallet",
          subroutes: [],
        },
        {
          isActive: true,
          path: "/finances/employee_payrolls",
          key: 72,
          name: "Базовые начисления",
          icon: "wallet",
          subroutes: [],
        },
        {
          isActive: true,
          path: "/finances/payroll_schemas",
          key: 73,
          name: "Схемы начислений",
          icon: "wallet",
          subroutes: [],
        },
      ],
    },
  ];
}

function managerRoutes(services) {
  return [
    {
      isActive: services.orders,
      path: null,
      key: 4,
      name: "Заказы",
      icon: "tags",
      subroutes: [
        {
          isActive: services.orders,
          path: "/orders",
          key: 41,
          name: "Заказы",
          icon: "block",
          subroutes: [],
        },
        {
          isActive: services.order_tasks,
          path: "/order_tasks",
          key: 44,
          name: "Задачи по заказам",
          icon: "bars",
          subroutes: [],
        },
      ],
    },
    {
      isActive: true,
      path: "/entities",
      key: 5,
      name: "Объекты",
      icon: "appstore",
      subroutes: [],
    },
    {
      isActive: services.projects,
      path: "/projects",
      key: 91,
      name: "Проекты",
      icon: "project",
      subroutes: [],
    },
    {
      isActive: services.tasks,
      path: "/tasks",
      key: 100,
      name: "Задачи",
      icon: "check-square",
      subroutes: [],
    },
    {
      isActive: true,
      path: null,
      key: 200,
      name: "Отчеты",
      icon: "bar-chart",
      subroutes: [
        {
          isActive: true,
          path: "/reports/daily",
          key: 201,
          name: "Ежедневный отчет",
          icon: "stock",
          subroutes: [],
        },
      ],
    },
  ];
}

function supervisorRoutes(services) {
  return [
    {
      isActive: services.orders,
      path: null,
      key: 4,
      name: "Заказы",
      icon: "tags",
      subroutes: [
        {
          isActive: services.orders,
          path: "/orders",
          key: 41,
          name: "Заказы",
          icon: "block",
          subroutes: [],
        },
        {
          isActive: services.order_tasks,
          path: "/order_tasks",
          key: 44,
          name: "Задачи по заказам",
          icon: "bars",
          subroutes: [],
        },
      ],
    },
    {
      isActive: true,
      path: "/entities",
      key: 5,
      name: "Объекты",
      icon: "appstore",
      subroutes: [],
    },
    {
      isActive: services.projects,
      path: "/projects",
      key: 91,
      name: "Проекты",
      icon: "project",
      subroutes: [],
    },
    {
      isActive: services.tasks,
      path: "/tasks",
      key: 100,
      name: "Задачи",
      icon: "check-square",
      subroutes: [],
    },
  ];
}

function engineerRoutes(services) {
  return [
    {
      isActive: services.order_tasks,
      path: "/order_tasks",
      key: 4,
      name: "Задачи по заказам",
      icon: "bars",
      subroutes: [],
    },
    {
      isActive: true,
      path: "/entities",
      key: 5,
      name: "Объекты",
      icon: "appstore",
      subroutes: [],
    },
    {
      isActive: services.tasks,
      path: "/tasks",
      key: 100,
      name: "Задачи",
      icon: "check-square",
      subroutes: [],
    },
  ];
}

function simpleRoutes(services) {
  return [
    {
      isActive: services.tasks,
      path: "/tasks",
      key: 100,
      name: "Задачи",
      icon: "check-square",
      subroutes: [],
    },
  ];
}

function roleRoutes(role, services) {
  switch (role) {
    case "administrator":
      return commomRoutes(services).concat(adminRoutes(services));
    case "manager":
      return commomRoutes(services).concat(managerRoutes(services));
    case "engineer":
      return commomRoutes(services).concat(engineerRoutes(services));
    case "supervisor":
      return commomRoutes(services).concat(supervisorRoutes(services));
    case "simple":
      return simpleRoutes(services);
    default:
      return [];
  }
}

function menuLeaf(item = {}) {
  if (item.subroutes.length > 0) {
    return (
      <SubMenu
        key={item.key}
        title={
          <span>
            <Icon type={item.icon} />
            <span>{item.name}</span>
          </span>
        }
      >
        {item.subroutes.map((child) => {
          return menuLeaf(child);
        })}
      </SubMenu>
    );
  } else {
    if (item.isActive) {
      return (
        <Menu.Item key={item.key}>
          <Link to={item.path} className="nav-text">
            <Icon type={item.icon} />
            <span>{item.name}</span>
          </Link>
        </Menu.Item>
      );
    }
  }
}

export default function MenuRoutes({ role, collapsed, services }) {
  let routes = roleRoutes(role, services).filter(function (item) {
    return item.isActive;
  });

  return (
    <Menu
      theme="dark"
      collapsed={collapsed}
      mode="inline"
      defaultSelectedKeys={["1"]}
    >
      {routes.map((item, i) => menuLeaf(item))}
    </Menu>
  );
}
