// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Col, Row, Descriptions } from "antd";
import moment from "moment";
import "moment/locale/ru";

class GeneralInfo extends Component {
  render() {
    const { entity } = this.props;

    return (
      <Col span={24}>
        <Descriptions bordered>
          <Descriptions.Item label="Наименование" span={3}>
            {entity.name}
          </Descriptions.Item>
          <Descriptions.Item label="Тип объекта" span={3}>
            {entity.entity_type ? entity.entity_type.name : null}
          </Descriptions.Item>
          <Descriptions.Item label="Клиент" span={3}>
            {entity.customer ? entity.customer.name : null}
          </Descriptions.Item>
          <Descriptions.Item label="Дата принятия" span={3}>
            {entity.start ? moment(entity.start).format("LLL") : null}
          </Descriptions.Item>
          <Descriptions.Item label="Дата окончания" span={3}>
            {entity.finish ? moment(entity.finish).format("LLL") : null}
          </Descriptions.Item>
          <Descriptions.Item label="Адрес" span={3}>
            {entity.address}
          </Descriptions.Item>
        </Descriptions>
      </Col>
    );
  }
}

GeneralInfo.propTypes = {
  entity: PropTypes.object
};

export default GeneralInfo;
