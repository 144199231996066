// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Table,
  Row,
  Col,
  PageHeader,
  Pagination,
  Button,
  Badge,
  Input,
  Tag,
  Select,
  Icon
} from "antd";
import moment from "moment";
import "moment/locale/ru";
import accounting from "accounting";

const Search = Input.Search;
const Option = Select.Option;

class UsersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      filterActiveVisible: false
    };
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  onDelete = () => {
    this.props.onDelete(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };



  render() {
    const {
      loading,
      payrolls,
      meta,
      fetchParams,
      onChangePage,
      onChangeLimit,
      onSearch
    } = this.props;
    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    };

    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: "",
        dataIndex: "is_active",
        render: (is_active, record) => (
          <span>
            <Badge status={is_active ? "success" : "error"} className={"large"} />
          </span>
        ),
        key: "is_active",
        width: "5%",
        align: "center"
      },
      {
        title: "#",
        dataIndex: "id",
        render: (id, record) => (
          <Link to={`/finances/payrolls/${record.id}/edit`}>{id}</Link>
        ),
        key: "id",
        width: "5%",
        align: "center"
      },
      {
        title: "Период",
        dataIndex: "start_at",
        render: (start_at, record) => `c ${moment(start_at).format("L")} по ${moment(record.end_at).format("L")}`,
        key: "start_at",
        width: "10%",
        align: "center"
      },
      {
        title: "Описание",
        dataIndex: "description",
        key: "description",
        width: "20%",
        align: "center"
      },
      {
        title: "Подразделение",
        dataIndex: "department",
        key: "department",
        render: (department, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{department ? department.name : null}</span>
          </div>
        ),
        width: "20%",
        align: "center"
      },
      {
        title: "Базовое начисление",
        dataIndex: "salary",
        render: salary => accounting.formatNumber(salary, 0, " "),
        key: "salary",
        width: "10%",
        align: "center"
      },
      {
        title: "Бонусы",
        dataIndex: "wage",
        render: wage => accounting.formatNumber(wage, 0, " "),
        key: "wage",
        width: "10%",
        align: "center"
      },
      {
        title: "Штрафы",
        dataIndex: "penalty",
        render: penalty => accounting.formatNumber(penalty, 0, " "),
        key: "penalty",
        width: "10%",
        align: "center"
      },
      {
        title: "Cоздан",
        dataIndex: "created_at",
        render: created_at => moment(created_at).format("L"),
        key: "created_at",
        align: "center",
        width: "10%"
      }
    ];

    let mainButton = hasSelected ? (
      <Button key="1" type="danger" ghost onClick={this.onDelete}>
        Удалить
      </Button>
    ) : (
      <Link
        key="2"
        className={"ant-btn ant-btn-primary ant-btn-background-ghost"}
        to={{ pathname: `/finances/payrolls/new` }}
      >
        Создать
      </Link>
    );

    let stepButton = 
      <Link
        key="3"
        className={"ant-btn ant-btn-primary ant-btn-background-ghost"}
        to={{ pathname: `/finances/payrolls/steps` }}
      >
        По подразделениям
      </Link>
    

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Начисления`}
        extra={[
          <Search
            allowClear
            key="3"
            placeholder="поиск..."
            value={fetchParams.search}
            onChange={onSearch}
            style={{ width: 250 }}
            allowClear
          />,
          mainButton,
          stepButton
        ]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Table
                bordered
                rowSelection={rowSelection}
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={payrolls}
                onChange={this.onChange}
                rowKey="id"
              />
            </Col>
            <Col
              span={24}
              style={{ paddingTop: "15px", paddingBottom: "15px", textAlign: "right" }}
            >
              <Pagination
                showSizeChanger
                pageSize={fetchParams.limit}
                current={meta.current_page}
                onChange={onChangePage}
                onShowSizeChange={onChangeLimit}
                showTotal={total => `Всего: ${meta.total_count}`}
                total={meta.total_count}
              />
            </Col>
          </Row>
        }
      />
    );
  }
}

UsersList.propTypes = {
  payrolls: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
  onSearch: PropTypes.func,
  onChangePage: PropTypes.func
};

export default UsersList;
