// @flow
import React, {Component} from "react";
import PropTypes from "prop-types";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {compose} from "redux";
import Actions from "../../../../actions/sections";
import {PageHeader, Button, Spin} from "antd";
import Form from "./Form";

class Section extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creating: false,
    };
  }

  componentWillMount = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.fetchSection(this.props.match.params.id));
    } else {
      this.setState({creating: true}, () => {
        this.props.dispatch(Actions.newSection());
      });
    }
  };

  onSave = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.updateSection(this.props.knowledgeBase.id, this.props.match.params.id));
    } else {
      this.props.dispatch(Actions.createSection(this.props.knowledgeBase.id)).then(() => {
        if (!this.props.errors) {
          this.props.history.push(`/sections/${this.props.section.id}/edit`);
        }
      });
    }
  };

  onClose = () => {
    this.props.history.push(`/knowledge_base`);
  };

  onChangeTitle = (e) => {
    this.props.dispatch(Actions.changeSectionTitle(e.target.value));
  };

  // sidebar

  onChangePermissionForAdministrator = (value) => {
    this.props.dispatch(Actions.onChangeSectionPermissionForAdministrator(value.target.checked));
  };

  onChangePermissionForManager = (value) => {
    this.props.dispatch(Actions.onChangeSectionPermissionForManager(value.target.checked));
  };

  onChangePermissionForEngineer = (value) => {
    this.props.dispatch(Actions.onChangeSectionPermissionForEngineer(value.target.checked));
  };

  onChangePermissionForSupervisor = (value) => {
    this.props.dispatch(Actions.onChangeSectionPermissionForSupervisor(value.target.checked));
  };

  onChangePermissionForSimple = (value) => {
    this.props.dispatch(Actions.onChangeSectionPermissionForSimple(value.target.checked));
  };

  render() {
    const {creating} = this.state;
    const {
      isLoading,
      section,
      permissions
    } = this.props;

    return isLoading ? (
      <Spin/>
    ) : (
      <PageHeader
        onBack={() => window.history.back()}
        title={creating ? `Новый раздел` : section.title}
      >
        <Form
          section={section}
          permissions={permissions}
          onSave={this.onSave}
          onClose={this.onClose}
          onChangeTitle={this.onChangeTitle}
          // sidebar
          onChangePermissionForAdministrator={this.onChangePermissionForAdministrator}
          onChangePermissionForManager={this.onChangePermissionForManager}
          onChangePermissionForEngineer={this.onChangePermissionForEngineer}
          onChangePermissionForSupervisor={this.onChangePermissionForSupervisor}
          onChangePermissionForSimple={this.onChangePermissionForSimple}
        />
      </PageHeader>
    );
  }
}

Section.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  section: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isLoading: state.sections.isLoading,
  errors: state.sections.errors,
  knowledgeBase: state.knowledgeBases.knowledgeBase,
  section: state.sections.section,
  permissions: state.sections.permissions,
});

export default compose(
  withRouter,
  connect(mapStateToProps)
)(Section);
