// @flow
import React, { Component } from "react";
import { Row, Col, Modal, Form, Input, InputNumber, Select } from "antd";
import SelectFetch from "../../../components/SelectFetch";

import "moment/locale/ru";

const FormItem = Form.Item;
const Option = Select.Option;

class Category extends React.Component {
  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onSave(values);
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      isLoading,
      visible,
      onCancel,
      category,
      onChangeName,
      onChangePosition,
      onChangeCustomer,
      onChangeExtCode,
      onChangeCategoryType,
    } = this.props;
    return (
      <Modal
        title={"Категория"}
        destroyOnClose
        visible={visible}
        onOk={this.onSubmit}
        onCancel={onCancel}
      >
        <Form layout={"vertical"}>
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <FormItem label={"Наименование"}>
                {getFieldDecorator("name", {
                  onChange: onChangeName,
                  initialValue: category.name,
                  rules: [
                    {
                      required: true,
                      message: "Введите наименование!",
                    },
                  ],
                })(<Input />)}
              </FormItem>
            </Col>

            <Col span={24}>
              <FormItem label={"Тип"}>
                {getFieldDecorator("category_type", {
                  initialValue: category.category_type,
                  onChange: onChangeCategoryType,
                  rules: [
                    {
                      required: true,
                      message: "Введите тип!",
                    },
                  ],
                })(
                  <Select
                    placeholder="Тип"
                    optionFilterProp="children"
                    filterOption={false}
                  >
                    <Option value={1}>сервис</Option>
                    <Option value={2}>инциденты</Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem label={"Позиция в списке"}>
                {getFieldDecorator("position", {
                  onChange: onChangePosition,
                  initialValue: category.position,
                })(<InputNumber min={0} style={{ width: "100%" }} />)}
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem label={"Клиент"}>
                {getFieldDecorator("customer", {
                  initialValue: category.customer ? category.customer : null,
                })(
                  <SelectFetch
                    showSearch
                    placeholder="Клиент"
                    onChange={onChangeCustomer}
                    selected={category.customer ? category.customer : null}
                    url={"/customers/search/?"}
                  />
                )}
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem label={"Внешний код"}>
                {getFieldDecorator("ext_code", {
                  onChange: onChangeExtCode,
                  initialValue: category.ext_code,
                })(<Input />)}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(Category);
