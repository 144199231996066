// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Drawer, Col, Row, DatePicker, Select, Form } from "antd";
import moment from "moment";
import "moment/locale/ru";
import SelectMultiFetch from "../../../components/SelectMultiFetch";
import SelectFetch from "../../../components/SelectFetch";

const { RangePicker } = DatePicker;
const { Option, OptGroup } = Select;
const FormItem = Form.Item;

class Filter extends Component {
  onChangeUsers = (values) => {
    this.props.onChangeUsers(values);
  };

  onChangeMembers = (values) => {
    this.props.onChangeMembers(values);
  };

  render() {
    const {
      visible,
      status,
      startDate,
      endDate,
      starFinish,
      endFinish,
      users,
      members,
      customer,
      onClose,
      onChangeStatus,
      onChangePeriod,
      onChangePeriodFinish,
      onChangeCustomer,
    } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Drawer
        width={540}
        visible={visible}
        title="Фильтры"
        placement={"right"}
        closable={false}
        onClose={onClose}
      >
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Период (создание)"}>
              {getFieldDecorator("start", {
                onChange: onChangePeriod,
                initialValue: [
                  startDate ? moment(startDate, "DD.MM.YYYY") : null,
                  endDate ? moment(endDate, "DD.MM.YYYY") : null,
                ],
              })(
                <RangePicker
                  showTime={false}
                  format={"DD.MM.YYYY"}
                  placeholder={["начало", "окончание"]}
                  style={{ width: "100%" }}
                />
              )}
            </FormItem>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Период (принятия)"}>
              {getFieldDecorator("starFinish", {
                onChange: onChangePeriodFinish,
                initialValue: [
                  starFinish ? moment(starFinish, "DD.MM.YYYY") : null,
                  endFinish ? moment(endFinish, "DD.MM.YYYY") : null,
                ],
              })(
                <RangePicker
                  showTime={false}
                  format={"DD.MM.YYYY"}
                  placeholder={["начало", "окончание"]}
                  style={{ width: "100%" }}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Статус"}>
              {getFieldDecorator("status", {
                onChange: onChangeStatus,
                initialValue: status ? status : [],
              })(
                <Select
                  mode={"multiple"}
                  style={{ width: "100%" }}
                  placeholder="Статус"
                  optionFilterProp="children"
                  filterOption={false}
                >
                  <Option value={"1"}>в работе</Option>
                  <Option value={"2"}>выполнен</Option>
                  <Option value={"3"}>отменен</Option>
                  <Option value={"4"}>приостановлен</Option>
                </Select>
              )}
            </FormItem>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Ответственные"}>
              {getFieldDecorator("users", {
                initialValue: users ? users : [],
              })(
                <SelectMultiFetch
                  showSearch
                  placeholder="Ответственные"
                  onChange={this.onChangeUsers}
                  selected={users ? users : []}
                  url={"/users/search/?all='true'"}
                />
              )}
            </FormItem>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Клиент"}>
              {getFieldDecorator("customer", {
                initialValue: customer ? customer : null,
              })(
                <SelectFetch
                  showSearch
                  placeholder="Клиент"
                  onChange={onChangeCustomer}
                  selected={customer ? customer : null}
                  url={"/customers/search/?"}
                />
              )}
            </FormItem>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Ответственные клиента"}>
              {getFieldDecorator("members", {
                initialValue: members ? members : [],
              })(
                <SelectMultiFetch
                  showSearch
                  placeholder="Ответственные клиента"
                  onChange={this.onChangeMembers}
                  selected={members ? members : []}
                  url={"/members/search/?"}
                />
              )}
            </FormItem>
          </Col>
        </Row>
      </Drawer>
    );
  }
}

Filter.propTypes = {
  entity: PropTypes.object,
};

export default Form.create()(Filter);
