// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, Form, Card } from "antd";
import Header from "./Header";

class ProfileForm extends Component {
  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSave(values);
      }
    });
  };

  render() {
    const {
      isLoading,
      user,
      currentUser,
      onClose,
      onChangeCurrentUserName,
      onChangeCurrentUserMiddleName,
      onChangeCurrentUserLastName,
      onChangeCurrentUserEmail,
      onUpdateCurrentUsePassword,
      onChangeCurrentUsePassword,
      onChangeCurrentUsePasswordConfirmation,
      // notifications
      onChangeNotifyTickets,
      onChangeNotifyEntityTasks,
      onChangeNotifyOrders,
      onChangeNotifyOrderTasks,
      onChangeNotifyProjects,
      // payrolls
      isLoadingPayrolls,
      payrolls,
      penalties,
      fetchPayrolls,
    } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Card
        loading={isLoading}
        title={
          <h2
            style={{ marginBottom: 0 }}
          >{`Пользователь #${`${user.name}`}`}</h2>
        }
        extra={
          <div>
            <Button onClick={onClose}>Закрыть</Button>
            <Button
              onClick={this.onSubmit}
              type="primary"
              ghost
              style={{ marginLeft: "8px" }}
            >
              Сохранить
            </Button>
          </div>
        }
      >
        <Form layout={"vertical"}>
          <Col xs={24} sm={24} md={24} lg={16} xl={16}>
            <Row>
              <Header
                currentUser={currentUser}
                name={user.name}
                email={user.email}
                password={user.password}
                password_confirmation={user.password_confirmation}
                getFieldDecorator={getFieldDecorator}
                onChangeName={onChangeCurrentUserName}
                onChangeMiddleName={onChangeCurrentUserMiddleName}
                onChangeLastName={onChangeCurrentUserLastName}
                onChangeEmail={onChangeCurrentUserEmail}
                onChangePassword={onChangeCurrentUsePassword}
                onChangePasswordConfirmation={
                  onChangeCurrentUsePasswordConfirmation
                }
                onUpdatePassword={onUpdateCurrentUsePassword}
                // notifications
                notifyCommentSettings={
                  user.user_profile
                    ? user.user_profile.notify_comment_settings
                    : null
                }
                onChangeNotifyTickets={onChangeNotifyTickets}
                onChangeNotifyEntityTasks={onChangeNotifyEntityTasks}
                onChangeNotifyOrders={onChangeNotifyOrders}
                onChangeNotifyOrderTasks={onChangeNotifyOrderTasks}
                onChangeNotifyProjects={onChangeNotifyProjects}
                // payrolls
                isLoadingPayrolls={isLoadingPayrolls}
                payrolls={payrolls}
                penalties={penalties}
                fetchPayrolls={fetchPayrolls}
              />
            </Row>
          </Col>
        </Form>
      </Card>
    );
  }
}

ProfileForm.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  user: PropTypes.object,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
  router: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  form: PropTypes.object,
  onChangeName: PropTypes.func,
};

export default Form.create()(ProfileForm);
