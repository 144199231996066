// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Avatar, Button, Col, Comment, Form, Icon, Popover, Row, Switch, Tooltip, Typography, Upload } from "antd";
import moment from "moment";
import "moment/locale/ru";
import api from "../../../api";
import { getIconColorForContentType, getIconTypeForContentType } from "../../../util/attachments";
import ReactQuill from "react-quill";
import QuillMention from 'quill-mention'

ReactQuill.Quill.register({ 'modules/mention': QuillMention });

const icons = ReactQuill.Quill.import("ui/icons");
icons["undo"] = `<svg viewbox="0 0 18 18">
    <polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
    <path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
  </svg>`;
icons["redo"] = `<svg viewbox="0 0 18 18">
    <polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
    <path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
  </svg>`;

const { Text } = Typography;

class Comments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showToolbar: false,
      comment: "",
      users: [],
      mentioned_users: [],
      members: [],
      mentioned_members: [],
    };

    this.editorRef = React.createRef();
  }

  componentDidMount() {
    const {
      showToolbar,
    } = this.state;

    const qlToolbarElement = document.querySelector('.ql-toolbar');
    if (qlToolbarElement) {
      qlToolbarElement.style.display = showToolbar ? 'block' : 'none';
    }

    const qlContainerElement = document.querySelector('.ql-container');
    if (qlContainerElement) {
      qlContainerElement.style.borderTop = showToolbar ? '0px' : '1px';
      qlContainerElement.style.borderTopColor = '#ccc'
      qlContainerElement.style.borderTopStyle = 'solid';
    }
  }

  toggleToolbar = () => {
    this.setState({
      showToolbar: !this.state.showToolbar
    });
  };

  fetchMentions = async (searchTerm, renderList) => {
    const responseUsers = await api.fetch(`/users/search/?`, {
      q: searchTerm,
    });

    const responseMembers = await api.fetch(`/members/search/?`, {
      customer_id: this.props.customer?.id,
      q: searchTerm,
    });

    const mentions = [];
    responseUsers.data.forEach((user) => {
      mentions.push({
        id: user.id,
        type: 'user',
        value: user.name,
      })
    });
    responseMembers.data.forEach((member) => {
      mentions.push({
        id: member.id,
        type: 'member',
        value: member.name,
      })
    });

    renderList(mentions);
  };

  renderMention = (mention) => {
    return mention.value
  };

  selectMention = (item, insertItem) => {
    if (item.type === 'user') {
      this.setState({
        mentioned_users: [...this.state.mentioned_users, item],
      });
    }

    if (item.type === 'member') {
      this.setState({
        mentioned_members: [...this.state.mentioned_members, item],
      });
    }

    insertItem(item);
  };

  onChangeComment = (content, delta, source, editor) => {
    this.setState({
      comment: content,
    });
  };

  onUndoChangeComment = () => {
    const editor = this.editorRef.getEditor();

    return editor.history.undo();
  };

  onRedoChangeComment = () => {
    const editor = this.editorRef.getEditor();

    return editor.history.redo();
  };

  onSubmitComment = () => {
    let {
      errors,
      comment,
      mentioned_users,
      mentioned_members,
    } = this.state;

    if (comment.length > 3) {
      this.props.onCreateComment(
        comment,
        {
          users: mentioned_users,
          members: mentioned_members,
        });

      if (!errors) {
        this.setState({
          comment: "",
          mentioned_users: [],
          mentioned_members: [],
        });
      }
    }
  };

  onReplyToComment = (comment) => {
    const commentBody = new DOMParser().parseFromString(comment.comment, 'text/html').body
    const commentText = commentBody.children.length === 0 ?
      commentBody.textContent :
      Array.from(
        commentBody.children,
        ({ textContent }) => textContent.trim())
        .filter(Boolean)
        .join(' ');

    const editor = this.editorRef.getEditor();
    editor.setContents(
      [
        { insert: commentText },
        { insert: '\n' }
      ]);
    editor.formatText(0, editor.getLength(), 'blockquote', true);
    editor.insertText(editor.getLength(), '');
    editor.setSelection(editor.getLength(), 0);
    editor.scrollIntoView();
  };

  render() {
    const {
      currentUser,
      comments,
      isCommentsLoading,
      commentAttachments,
      onUploadCommentAttachment,
      onDeleteCommentAttachment,
    } = this.props;

    const {
      showToolbar,
      comment,
    } = this.state;

    const qlToolbarElement = document.querySelector('.ql-toolbar');
    if (qlToolbarElement) {
      qlToolbarElement.style.display = showToolbar ? 'block' : 'none';
    }

    const qlContainerElement = document.querySelector('.ql-container');
    if (qlContainerElement) {
      qlContainerElement.style.borderTop = showToolbar ? '0px' : '1px';
      qlContainerElement.style.borderTopColor = '#ccc'
      qlContainerElement.style.borderTopStyle = 'solid';
    }

    return (
      <Row>
        <Col span={24}>
          {comments.map((comment) => {
            let author = null
            let avatar = null
            if (comment.user) {
              author = comment.user.name
              avatar = comment.user.avatar
            }

            if (comment.member) {
              author = comment.member.name
              avatar = comment.member.avatar
            }

            if (comment.employee) {
              author = comment.employee.name
              avatar = comment.employee.avatar
            }

            const commentUserId = comment.user?.id

            return (
              <Row key={comment.id}
                   style={{
                     marginBottom: 16,
                   }}>
                <Comment author={author}
                         avatar={<Avatar src={avatar} alt=""/>}
                         datetime={<span>{moment(comment.created_at).format("LLL")}</span>}
                         content={
                           <Text>
                             <div dangerouslySetInnerHTML={{ __html: comment.comment }}/>
                           </Text>
                         }>
                  <Row style={{
                    display: "flex",
                    flexDirection: "row",
                  }}>
                    {currentUser.user_id === commentUserId ?
                      <Col style={{
                        marginRight: 8,
                      }}>
                        <Upload showUploadList={false}
                                multiple={true}
                                customRequest={({ file }) => onUploadCommentAttachment(comment.id, file)}>
                          <Text type={"secondary"}
                                style={{
                                  cursor: "pointer",
                                  fontSize: 12
                                }}>
                            <Icon type={"paper-clip"}/>
                            {"Добавить файл"}
                          </Text>
                        </Upload>
                      </Col> :
                      null
                    }
                    <Col>
                      <Text type={"secondary"}
                            style={{
                              cursor: "pointer",
                              fontSize: 12
                            }}
                            onClick={() => this.onReplyToComment(comment)}>
                        {"Ответить"}
                      </Text>
                    </Col>
                  </Row>
                  {commentAttachments
                    .filter((attachment) => attachment.entity_task_comment_id === comment.id)
                    .map((attachment) =>
                      <Row key={`attachment-${attachment.id}`}
                           type={"flex"}
                           align={"middle"}>
                        <Col style={{
                          marginRight: 8,
                        }}>
                          <Icon type={getIconTypeForContentType(attachment.content_type)}
                                style={{
                                  backgroundColor: "transparent",
                                  color: getIconColorForContentType(attachment.content_type)
                                }}
                          />
                        </Col>
                        <Col style={{
                          marginRight: 8,
                        }}>
                          <a href={attachment.url}
                             download={attachment.name}>
                            {attachment.name}
                          </a>
                        </Col>
                        <Col style={{
                          marginRight: 8,
                        }}>
                          <Text type={"secondary"}>
                            {`${moment(attachment.created_at).format("LLL")} - ${attachment.file_size}`}
                          </Text>
                        </Col>
                        <Col>
                          <Tooltip placement="bottom" title={"Удалить"}>
                            <Popover
                              content={
                                <Button
                                  type="danger"
                                  style={{ width: "100%" }}
                                  onClick={() => onDeleteCommentAttachment(attachment.id)}>
                                  Удалить
                                </Button>
                              }
                              title="Удаление файла"
                              trigger="click">
                              <Button type={"dashed"}
                                      shape={"circle"}
                                      icon={"close"}/>
                            </Popover>
                          </Tooltip>
                        </Col>
                      </Row>
                    )}
                </Comment>
              </Row>
            );
          })}
          <Row style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}>
            <Text type={"secondary"}
                  style={{
                    marginRight: 4,
                    fontSize: 12,
                  }}>
              {"Форматирование"}
            </Text>
            <Switch
              size="small"
              checked={showToolbar}
              onChange={this.toggleToolbar}/>
          </Row>
          <Row className={'comment-editor'}
               style={{
                 marginBottom: 16,
               }}>
            <ReactQuill ref={(it) => this.editorRef = it}
                        bounds={'.comment-editor'}
                        theme={"snow"}
                        placeholder={"Используйте @@ для выбора пользователя"}
                        value={comment}
                        modules={{
                          toolbar: {
                            container: [
                              ['undo', 'redo'],
                              [{ 'header': [false, 1, 2, 3, 4, 5, 6] }],
                              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                              [{ 'color': [] }, { 'background': [] }],
                              ['clean'],
                              [{ 'align': [] }],
                              [{ 'indent': '-1' }, { 'indent': '+1' }],
                              ['video', 'image', 'link'],
                            ],
                            handlers: {
                              'undo': this.onUndoChangeComment,
                              'redo': this.onRedoChangeComment,
                            }
                          },
                          mention: {
                            mentionDenotationChars: ['@@'],
                            allowedChars: /^.*$/,
                            dataAttributes: ['id', 'value', 'type', 'disabled'],
                            source: this.fetchMentions,
                            renderItem: this.renderMention,
                            onSelect: this.selectMention,
                            positioningStrategy: 'fixed',
                          },
                          history: {
                            delay: 1000,
                            maxStack: 100,
                            userOnly: false
                          },
                        }}
                        onChange={this.onChangeComment}/>
          </Row>
          <Form.Item>
            <Button
              loading={isCommentsLoading}
              onClick={this.onSubmitComment}
              type="primary">
              Добавить комментарий
            </Button>
          </Form.Item>
        </Col>
      </Row>
    );
  }
}

Comments.propTypes = {
  orderComments: PropTypes.arrayOf(PropTypes.object),
  total: PropTypes.number,
  discount: PropTypes.object,
  shippingCost: PropTypes.number,
  amountDiscountCode: PropTypes.number,
  onRemoveVariant: PropTypes.func,
};
export default Comments;
