// @flow
import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Badge, Button, Col, Radio, Row, Table, Tabs, Tooltip, Typography} from "antd";
import moment from "moment";
import "moment/locale/ru";
import Filter from "./Filter";

const {TabPane} = Tabs;
const {Paragraph} = Typography;

class TaskTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFilterVisible: false,
    };
  }

  componentWillMount = () => {
    this.props.fetchTasks();
  };

  onChangeTasksAssigneeType = (e) => {
    this.props.onChangeTasksAssigneeType(e.target.value)
  };

  onFilterToggle = () => {
    this.setState({
      isFilterVisible: !this.state.isFilterVisible
    });
  };

  render() {
    const {
      currentUser,
      isLoading,
      tasks,
      countOverdueTasks,
      fetchParams,
      onChangeTaskFilterCreatedPeriod,
      onChangeTaskFilterDueByPeriod,
      onChangeTaskFilterUsers,
    } = this.props;
    const {
      isFilterVisible
    } = this.state;

    const columns = [
      {
        title: "Тема",
        dataIndex: "subject",
        render: (subject, record) => (
          <span>
            <Link to={`/tasks/${record.id}/edit`}>{subject}</Link>
          </span>
        ),
        key: "subject",
        align: "center",
        width: "30%",
      },
      {
        title: "Описание",
        dataIndex: "description",
        render: (description, record) => (
          <Paragraph ellipsis={{rows: 1, expandable: true}}>
            {description}
          </Paragraph>
        ),
        key: "description",
        align: "center",
        width: "50%",
      },
      {
        title: "Выполнить до",
        dataIndex: "due_by",
        render: (due_by) => (
          <span style={{fontSize: 12}}>{moment(due_by).format("LLL")}</span>
        ),
        key: "due_by",
        align: "center",
        width: "20%",
      },
    ];

    return (
      <React.Fragment>
        <Row gutter={16} style={{paddingBottom: 10}}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Radio.Group
              defaultValue="current"
              buttonStyle="solid"
              onChange={this.onChangeTasksAssigneeType}
            >
              <Radio.Button value="current">Мои</Radio.Button>
              <Radio.Button value="observer">Наблюдаю</Radio.Button>
              <Radio.Button value="own">Поручил</Radio.Button>
              <Badge count={isLoading ? 0 : countOverdueTasks}>
                <Radio.Button value="overdue">Просрочены</Radio.Button>
              </Badge>
            </Radio.Group>
            <Tooltip title={"Фильтры"}
                     placement="bottom">
              <Button
                type={
                  fetchParams.startCreatedDate || fetchParams.endCreatedDate ||
                  fetchParams.startDueByDate || fetchParams.endDueByDate ||
                  fetchParams.users
                    ? "primary"
                    : "ghost"
                }
                key="filterButton"
                icon="filter"
                onClick={this.onFilterToggle}
                style={{marginLeft: 10}}
              />
            </Tooltip>
          </Col>
          <Filter
            visible={isFilterVisible}
            currentUser={currentUser}
            startCreatedDate={fetchParams.startCreatedDate}
            endCreatedDate={fetchParams.endCreatedDate}
            startDueByDate={fetchParams.startDueByDate}
            endDueByDate={fetchParams.endDueByDate}
            users={fetchParams.users}
            onChangeTaskFilterCreatedPeriod={onChangeTaskFilterCreatedPeriod}
            onChangeTaskFilterDueByPeriod={onChangeTaskFilterDueByPeriod}
            onChangeTaskFilterUsers={onChangeTaskFilterUsers}
            onClose={this.onFilterToggle}
          />
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Table
              loading={isLoading}
              size="small"
              columns={columns}
              dataSource={tasks}
              pagination={{pageSize: 50}}
              scroll={{y: 255}}
              rowKey={"id"}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default TaskTabs;
