// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Col, Form, Input, Row, Select } from "antd";
import SelectMultiFetch from "../../../components/SelectMultiFetch";
import Panes from "./Tabs/Panes";

const FormItem = Form.Item;
const {TextArea} = Input;
const Option = Select.Option;

class General extends Component {
  constructor(props) {
    super(props);
    this.state = {content: ""};
    this.onEditorChange = this.onEditorChange.bind(this);
  }

  onEditorChange(content, editor) {
    this.props.onChangeBody(content);
    // this.setState({ content });
  }

  render() {
    const {
      getFieldDecorator,
      taskTemplate,
      onChangeName,
      onChangeVisibility,
      onChangeDescription,
      onChangeOwner,
      onChangeDepartments,
      onChangeCustomers,
      onChangeAssignees,
      onChangeSupervisors,
      onChangeMembers,
      // field_sets
      onSaveFieldSet,
      onDeleteFieldSets,
      // actions
      onChangeActionTemplate,
      onChangeActionTemplateHours,
      onChangeActionTemplateSubject,
    } = this.props;

    const fieldSets = taskTemplate.field_sets
      .filter(function (item) {
        return !item._destroy;
      })
      .sort(function (a, b) {
        return a.position - b.position;
      });

    return (
      <Row gutter={16}>
        <Col span={18}>
          <FormItem label={"Наименование"}>
            {getFieldDecorator("name", {
              onChange: onChangeName,
              initialValue: taskTemplate.name,
              rules: [
                {
                  required: true,
                  message: "Введите наименование!",
                },
              ],
            })(<Input/>)}
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem label={"Видимость"}>
            {getFieldDecorator("visibility", {
              onChange: onChangeVisibility,
              initialValue: taskTemplate.visibility,
            })(
              <Select
                style={{width: "100%"}}
                placeholder="Видимость"
                optionFilterProp="children"
                filterOption={false}
              >
                <Option value={1}>организация</Option>
                <Option value={2}>все</Option>
                <Option value={3}>выбранные клиенты</Option>
              </Select>
            )}
          </FormItem>
        </Col>
        <Col span={24}>
          <FormItem label={"Описание"}>
            {getFieldDecorator("description", {
              onChange: onChangeDescription,
              initialValue: taskTemplate.description,
              rules: [
                {
                  required: true,
                  message: "Введите описание!",
                },
              ],
            })(<TextArea autoSize={{minRows: 1, maxRows: 6}}/>)}
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem label={"Владелец"}>
            {getFieldDecorator("task_owner", {
              onChange: onChangeOwner,
              initialValue: taskTemplate.task_owner,
            })(
              <Select
                style={{width: "100%"}}
                placeholder="Владелец"
                optionFilterProp="children"
                filterOption={false}
              >
                <Option value={null}>нет</Option>
                <Option value={"projects"}>проекты</Option>
                <Option value={"project_stages"}>этапы проектов</Option>
                <Option value={"orders"}>заказы</Option>
                <Option value={"tickets"}>заявки</Option>
                <Option value={"entity_tasks"}>регламент</Option>
                <Option value={"entities"}>объекты</Option>
              </Select>
            )}
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem label={"Тип задачи"}>
            {getFieldDecorator("task_type", {
              onChange: onChangeOwner,
              initialValue: taskTemplate.task_type,
            })(
              <Select
                style={{width: "100%"}}
                placeholder="Тип задачи"
                optionFilterProp="children"
                filterOption={false}
              >
                <Option value={"common"}>простое</Option>
                <Option value={"job"}>выполнение работ</Option>
                <Option value={"approving"}>принятие работ</Option>
              </Select>
            )}
          </FormItem>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <FormItem label={"Подразделения"}>
            {getFieldDecorator("departments", {
              initialValue: taskTemplate.departments
                ? taskTemplate.departments
                : [],
            })(
              <SelectMultiFetch
                showSearch
                placeholder="Подразделения"
                onChange={onChangeDepartments}
                selected={
                  taskTemplate.departments ? taskTemplate.departments : []
                }
                url={"/departments/search/?"}
              />
            )}
          </FormItem>
        </Col>
        {taskTemplate.visibility == 3 ? (
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Клиенты"}>
              {getFieldDecorator("customers", {
                initialValue: taskTemplate.customers
                  ? taskTemplate.customers
                  : [],
              })(
                <SelectMultiFetch
                  showSearch
                  placeholder="Подразделения"
                  onChange={onChangeCustomers}
                  selected={
                    taskTemplate.customers ? taskTemplate.customers : []
                  }
                  url={"/customers/search/?"}
                />
              )}
            </FormItem>
          </Col>
        ) : null}
        {taskTemplate.assignee_fill_type === 3 ? (
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Исполнители"}>
              {getFieldDecorator("assignees", {
                initialValue: taskTemplate.assignees
                  ? taskTemplate.assignees
                  : [],
              })(
                <SelectMultiFetch
                  showSearch
                  placeholder="Исполнители"
                  onChange={onChangeAssignees}
                  selected={
                    taskTemplate.assignees ? taskTemplate.assignees : []
                  }
                  url={"/users/search/?"}
                />
              )}
            </FormItem>
          </Col>
        ) : null}
        {taskTemplate.supervisor_fill_type === 3 ? (
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Наблюдатели"}>
              {getFieldDecorator("supervisors", {
                initialValue: taskTemplate.supervisors
                  ? taskTemplate.supervisors
                  : [],
              })(
                <SelectMultiFetch
                  showSearch
                  placeholder="Наблюдатели"
                  onChange={onChangeSupervisors}
                  selected={
                    taskTemplate.supervisors ? taskTemplate.supervisors : []
                  }
                  url={"/users/search/?"}
                />
              )}
            </FormItem>
          </Col>
        ) : null}
        {taskTemplate.member_fill_type === 3 ? (
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"От заказчика"}>
              {getFieldDecorator("members", {
                initialValue: taskTemplate.members ? taskTemplate.members : [],
              })(
                <SelectMultiFetch
                  showSearch
                  placeholder="От заказчика"
                  onChange={onChangeMembers}
                  selected={
                    taskTemplate.members ? taskTemplate.members : []
                  }
                  url={
                    taskTemplate.customers ?
                      `/members/search/?${taskTemplate.customers.map((it) => `customer_ids[]=${it.id}`).join('&')}` :
                      `/members/search/?`
                  }
                />
              )}
            </FormItem>
          </Col>
        ) : null}
        <Col span={24}>
          <Panes
            getFieldDecorator={getFieldDecorator}
            hasFields={taskTemplate.has_fields}
            fieldSets={fieldSets}
            onSaveFieldSet={onSaveFieldSet}
            onDeleteFieldSets={onDeleteFieldSets}
            // actions
            taskTemplateId={taskTemplate.id}
            actionTemplate={taskTemplate.action_template}
            actionTemplateHours={taskTemplate.action_template_hours}
            actionTemplateSubject={taskTemplate.action_template_subject}
            onChangeActionTemplate={onChangeActionTemplate}
            onChangeActionTemplateHours={onChangeActionTemplateHours}
            onChangeActionTemplateSubject={onChangeActionTemplateSubject}
          />
        </Col>
      </Row>
    );
  }
}

General.propTypes = {
  entity: PropTypes.object,
  onChangeDate: PropTypes.func,
  onChangeAssignee: PropTypes.func,
  onChangeAgreement: PropTypes.func,
  onChangeAgreementDate: PropTypes.func,
  onChangeAdditional: PropTypes.func,
  onChangeComment: PropTypes.func,
  onChangeNotice: PropTypes.func,
};

export default General;
