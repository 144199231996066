// @flow
import React, { Component, Fragment } from "react";
import { Row, Col, Button, Card, Statistic, Icon } from "antd";
import OrderPie from "./Pie";
import ProjectBar from "./ProjectBar";
import StatisticCards from "./StatisticCards";
import SelectMultiFetch from "../../../../components/SelectMultiFetch";

import moment from "moment";
import "moment/locale/ru";

class Orders extends Component {
  render() {
    const {
      currentUser,
      fetchParams,
      isStatisticLoading,
      ordersStatistic,
      orderCustomers,
      onChangeOrdersCustomer,
    } = this.props;
    
    return (
      <React.Fragment>
        <StatisticCards
          data={ordersStatistic}
          startDate={fetchParams.startDate}
          endDate={fetchParams.endDate}
          orderCustomers={orderCustomers}
          currentUser={currentUser}
        />
        <Card
          title="Заказы"
          loading={isStatisticLoading}
          extra={
            <SelectMultiFetch
              showSearch
              placeholder="Клиент"
              onChange={onChangeOrdersCustomer}
              selected={orderCustomers ? orderCustomers : []}
              url={"/customers/search/?"}
              style={{ width:'400px' }}
            />
          }
        >
          <OrderPie data={ordersStatistic} />
        </Card>

        {/* <Col xs={24} sm={24} md={24} lg={14} xl={14}>
          <Row gutter={16}>
            <Card>
              <ProjectBar isLoading={isStatisticLoading} data={projectSeries} />
            </Card>
          </Row>
        </Col> */}
      </React.Fragment>
    );
  }
}

export default Orders;
