// @flow
import React, { Component } from "react";
import { Row, Col, PageHeader, Statistic, Icon, DatePicker, Table } from "antd";
import moment from "moment";
import "moment/locale/ru";
import accounting from "accounting";
import { v4 as uuidv4 } from "uuid";

const { MonthPicker } = DatePicker;
const dateFormat = "DD.MM.YYYY";

class Finance extends Component {
  render() {
    const {
      isLoadingPayrolls,
      payrolls,
      penalties,
      startAt,
      endAt,
      onChangePeriod,
    } = this.props;

    const totalPayrolls = payrolls.reduce(
      (sum, record) => sum + Number(record.salary) + Number(record.wage),
      0
    );

    const totalPenalties = penalties.reduce(
      (sum, record) => sum + Number(record.penalty),
      0
    );

    const payment = totalPayrolls - totalPenalties;

    let data = [];

    payrolls.map((item) => {
      data.push({
        id: uuidv4(),
        description: item.description,
        salary: item.salary,
        wage: item.wage,
        penalty: 0,
      });
    });

    penalties.map((item) => {
      data.push({
        id: uuidv4(),
        description: item.description,
        salary: 0,
        wage: 0,
        penalty: item.penalty,
      });
    });

    const columns = [
      {
        title: "Начисление",
        dataIndex: "description",
        render: (description, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{description}</span>
            <span style={{ opacity: "0.7", fontSize: 12 }}>
              {record.info ? record.info : null}
            </span>
          </div>
        ),
        key: "work",
        width: "55%",
        align: "left",
      },
      {
        title: "Базовый оклад",
        dataIndex: "salary",
        key: "salary",
        render: (salary, record) => (
          <span>
            {salary > 0 ? accounting.formatNumber(salary, 2, " ") : ""}
          </span>
        ),
        width: "15%",
        align: "right",
      },
      {
        title: "Бонусы",
        dataIndex: "wage",
        key: "wage",
        render: (wage, record) => (
          <span>{wage > 0 ? accounting.formatNumber(wage, 2, " ") : ""}</span>
        ),
        width: "15%",
        align: "right",
      },
      {
        title: "Штраф",
        dataIndex: "penalty",
        render: (penalty, record) => (
          <span style={{ color: "#cf1322" }}>
            {penalty > 0 ? accounting.formatNumber(penalty, 2, " ") : ""}
          </span>
        ),
        key: "penalty",
        width: "15%",
        align: "right",
      },
    ];

    return (
      <PageHeader
        // title={`Пользователи`}
        footer={
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Table
                loading={isLoadingPayrolls}
                bordered
                size={"small"}
                dataSource={data}
                columns={columns}
                pagination={false}
                rowKey="id"
              />
            </Col>
          </Row>
        }
      >
        <Row gutter={16}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <MonthPicker
              placeholder="Выберите месяц"
              value={moment(startAt, dateFormat)}
              onChange={onChangePeriod}
              format={"MMMM"}
            />
          </Col>
          <Col
            xs={4}
            sm={4}
            md={4}
            lg={4}
            xl={4}
            style={{ textAlign: "right" }}
          >
            <Statistic title="К выплате" value={payment} suffix={"₽"} />
          </Col>
          <Col
            xs={4}
            sm={4}
            md={4}
            lg={4}
            xl={4}
            style={{ textAlign: "right" }}
          >
            <Statistic title="Начислено" value={totalPayrolls} suffix={"₽"} />
          </Col>
          <Col
            xs={4}
            sm={4}
            md={4}
            lg={4}
            xl={4}
            style={{ textAlign: "right" }}
          >
            <Statistic
              title="Штрафы"
              value={totalPenalties}
              valueStyle={{ color: "#cf1322" }}
              suffix={"₽"}
            />
          </Col>
        </Row>
      </PageHeader>
    );
  }
}

Finance.propTypes = {};

export default Finance;
