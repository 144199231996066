// @flow
import React, { Component, Fragment } from "react";
import { Row, Col, Card, Statistic } from "antd";
import Details from "./Details";

class StatisticCards extends Component {
  render() {
    const { currentUser, data, startDate, endDate, orderCustomers } = this.props;

    return (
      <Row gutter={1}>
        <Col span={3}>
          <Card>
            <Statistic
              title={
                <Details
                  title="Заказов в работе всего"
                  type={"processing"}
                  startDate={startDate}
                  endDate={endDate}
                  orderCustomers={orderCustomers}
                  currentUser={currentUser}
                />
              }
              value={data.processing}
              precision={0}
              valueStyle={{ color: "#3f8600" }}
              // prefix={<Icon type="arrow-up" />}
              suffix="шт."
            />
          </Card>
        </Col>
        <Col span={3}>
          <Card>
            <Statistic
              title={
                <Details
                  title="Заказов создано"
                  type={"created"}
                  startDate={startDate}
                  endDate={endDate}
                  orderCustomers={orderCustomers}
                  currentUser={currentUser}
                />
              }
              value={data.created}
              precision={0}
              valueStyle={{ color: "#3f8600" }}
              // prefix={<Icon type="arrow-up" />}
              suffix="шт."
            />
          </Card>
        </Col>
        <Col span={3}>
          <Card>
            <Statistic
              title={
                <Details
                  title="Согласованные в работе"
                  type={"proposal_approved"}
                  startDate={startDate}
                  endDate={endDate}
                  orderCustomers={orderCustomers}
                  currentUser={currentUser}
                />
              }
              value={data.proposal_approved}
              precision={0}
              valueStyle={{ color: "#3f8600" }}
              // prefix={<Icon type="arrow-up" />}
              suffix="шт."
            />
          </Card>
        </Col>
        <Col span={3}>
          <Card>
            <Statistic
              title={
                <Details
                  title="Ожидают согласования"
                  type={"proposal_pending"}
                  startDate={startDate}
                  endDate={endDate}
                  orderCustomers={orderCustomers}
                  currentUser={currentUser}
                />
              }
              value={data.proposal_pending}
              precision={0}
              valueStyle={{ color: "#3f8600" }}
              // prefix={<Icon type="arrow-up" />}
              suffix="шт."
            />
          </Card>
        </Col>
        <Col span={3}>
          <Card>
            <Statistic
              title={
                <Details
                  title="Ожидают принятия"
                  type={"job_approving"}
                  startDate={startDate}
                  endDate={endDate}
                  orderCustomers={orderCustomers}
                  currentUser={currentUser}
                />
              }
              value={data.job_approving}
              precision={0}
              valueStyle={{ color: "#3f8600" }}
              // prefix={<Icon type="arrow-up" />}
              suffix="шт."
            />
          </Card>
        </Col>
        <Col span={3}>
          <Card>
            <Statistic
              title={
                <Details
                  title="Просроченные в работе"
                  type={"overdue"}
                  startDate={startDate}
                  endDate={endDate}
                  orderCustomers={orderCustomers}
                  currentUser={currentUser}
                />
              }
              value={data.overdue}
              precision={0}
              valueStyle={{ color: "#3f8600" }}
              // prefix={<Icon type="arrow-up" />}
              suffix="шт."
            />
          </Card>
        </Col>
        <Col span={3}>
          <Card>
            <Statistic
              title={
                <Details
                  title="Риск просрочки"
                  type={"overdue_risk"}
                  startDate={startDate}
                  endDate={endDate}
                  orderCustomers={orderCustomers}
                  currentUser={currentUser}
                />
              }
              value={data.overdue_risk}
              precision={0}
              valueStyle={{ color: "#3f8600" }}
              // prefix={<Icon type="arrow-up" />}
              suffix="шт."
            />
          </Card>
        </Col>
        <Col span={3}>
          <Card>
            <Statistic
              title={
                <Details
                  title="Принято за сегодня"
                  type={"job_approved_today"}
                  startDate={startDate}
                  endDate={endDate}
                  orderCustomers={orderCustomers}
                  currentUser={currentUser}
                />
              }
              value={data.job_approved_today}
              precision={0}
              valueStyle={{ color: "#cf1322" }}
              // prefix={<Icon type="arrow-down" />}
              suffix="шт."
            />
          </Card>
        </Col>
      </Row>
    );
  }
}

export default StatisticCards;
