// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Input, Col, Row } from "antd";
import { Editor } from "@tinymce/tinymce-react";

const FormItem = Form.Item;
const { TextArea } = Input;

class General extends Component {
  constructor(props) {
    super(props);
    this.state = { content: "" };
    this.onEditorChange = this.onEditorChange.bind(this);
  }

  onEditorChange(content, editor) {
    this.props.onChangeBody(content);
    // this.setState({ content });
  }

  render() {
    const {
      getFieldDecorator,
      title,
      body,
      onChangeTitle,
      onChangeBody
    } = this.props;

    return (
      <Row gutter={16}>
        <Col span={24}>
          <FormItem label={"Заголовок"}>
            {getFieldDecorator("subject", {
              onChange: onChangeTitle,
              initialValue: title,
              rules: [
                {
                  required: true,
                  message: "Пожалуйста, введите заголовок!"
                }
              ]
            })(<Input />)}
          </FormItem>
        </Col>
        <Col span={24}>
          <Editor
            apiKey="n2iws6zuwpna7twgyz38iyrixvx6y2hywnmyty7yi3n1icsh"
            value={body}
            init={{
              plugins: "media, image",
              menubar: "insert",
              // toolbar: "media",
              media_live_embeds: true,
              height: 500,
              // menubar: false,
              content_style:"body { font-size: 11pt; font-weight: 400 }",
              toolbar:
                "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | \
                 bullist numlist outdent indent | removeformat | media image"
            }}
            onEditorChange={this.onEditorChange}
          />
        </Col>
      </Row>
    );
  }
}

General.propTypes = {
  entity: PropTypes.object,
  onChangeDate: PropTypes.func,
  onChangeAssignee: PropTypes.func,
  onChangeAgreement: PropTypes.func,
  onChangeAgreementDate: PropTypes.func,
  onChangeAdditional: PropTypes.func,
  onChangeComment: PropTypes.func,
  onChangeNotice: PropTypes.func
};

export default General;
