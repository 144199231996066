import api from "../api";

const Actions = {};

Actions.fetchCurrentUser = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_CURRENT_USER_REQUEST" });
    const currentUserID = getState().session.currentUser.user_id;
    api
      .fetch(`/users/${currentUserID}`)
      .then((response) => {
        dispatch({ type: "FETCH_CURRENT_USER", response });
      })
      .catch((response) => {
        dispatch({ type: "FETCH_CURRENT_USER_FAILURE", response });
      });
  };
};

Actions.onUpdate = () => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_CURRENT_USER_REQUEST" });
    const currentUser = getState().currentUser.currentUser;
    const data = {
      user: {
        email: currentUser.email,
        first_name: currentUser.first_name,
        middle_name: currentUser.middle_name,
        last_name: currentUser.last_name,
        user_profile_attributes: {
          id: currentUser.user_profile.id,
          notify_comment_settings:
            currentUser.user_profile.notify_comment_settings,
        },
      },
    };
    api
      .patch(`/users/${currentUser.id}`, data)
      .then((response) => {
        dispatch({ type: "PATCH_CURRENT_USER", response });
        api.openNotification("success", "Пользователь", "Успешно обновлен!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Пользователь", error);
          });
        }
        dispatch({ type: "PATCH_CURRENT_USER_FAILURE", response });
      });
  };
};

Actions.onUpdateCurrentUsePassword = () => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_PASSWORD_CURRENT_USER_REQUEST" });
    const currentUser = getState().currentUser.currentUser;
    const data = {
      user: {
        password: currentUser.password,
        password_confirmation: currentUser.password_confirmation,
      },
    };
    api
      .patch(`/users/${currentUser.id}/update_password`, data)
      .then((response) => {
        dispatch({ type: "PATCH_PASSWORD_CURRENT_USER", response });
        api.openNotification("success", "Пароль", "Успешно обновлен!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Пароль", error);
          });
        }
        dispatch({ type: "PATCH_PASSWORD_CURRENT_USER_FAILURE", response });
      });
  };
};

Actions.onChangeCurrentUserName = (value) => {
  return (dispatch) => {
    dispatch({
      type: "CURRENT_USER_CHANGE_NAME",
      value: value,
    });
  };
};

Actions.onChangeCurrentUserEmail = (value) => {
  return (dispatch) => {
    dispatch({
      type: "CURRENT_USER_CHANGE_EMAIL",
      value: value,
    });
  };
};

Actions.onChangeCurrentUsePassword = (value) => {
  return (dispatch) => {
    dispatch({
      type: "CURRENT_USER_CHANGE_PASSWORD",
      value: value,
    });
  };
};

Actions.onChangeCurrentUsePasswordConfirmation = (value) => {
  return (dispatch) => {
    dispatch({
      type: "CURRENT_USER_CHANGE_PASSWORD_CONFIRMATION",
      value: value,
    });
  };
};

// notifications
Actions.onChangeNotifyTickets = (value) => {
  return (dispatch) => {
    dispatch({
      type: "CURRENT_USER_CHANGE_NOTIFY_COMMENT_SETTINGS_TICKETS",
      value: value,
    });
  };
};

Actions.onChangeNotifyEntityTasks = (value) => {
  return (dispatch) => {
    dispatch({
      type: "CURRENT_USER_CHANGE_NOTIFY_COMMENT_SETTINGS_ENTITY_TASKS",
      value: value,
    });
  };
};

Actions.onChangeNotifyOrders = (value) => {
  return (dispatch) => {
    dispatch({
      type: "CURRENT_USER_CHANGE_NOTIFY_COMMENT_SETTINGS_ORDERS",
      value: value,
    });
  };
};

Actions.onChangeNotifyOrderTasks = (value) => {
  return (dispatch) => {
    dispatch({
      type: "CURRENT_USER_CHANGE_NOTIFY_COMMENT_SETTINGS_ORDER_TASKS",
      value: value,
    });
  };
};

Actions.onChangeNotifyProjects = (value) => {
  return (dispatch) => {
    dispatch({
      type: "CURRENT_USER_CHANGE_NOTIFY_COMMENT_SETTINGS_PROJECTS",
      value: value,
    });
  };
};

// payrolls
Actions.fetchPayrolls = (id, startDate, endDate) => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_CURRENT_USER_PAYROLLS_REQUEST" });
    const params = { startDate: startDate, endDate: endDate };
    api
      .fetch(`/users/${id}/payrolls`, params)
      .then((response) => {
        dispatch({ type: "FETCH_CURRENT_USER_PAYROLLS", response });
      })
      .catch((response) => {
        dispatch({ type: "FETCH_CURRENT_USER_PAYROLLS_FAILURE", response });
      });
  };
};

export default Actions;
