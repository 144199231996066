// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import update from "immutability-helper";
import { Tree } from "antd";
import { Row, Col, Card, Button } from "antd";
import Category from "./Category";
// /categories/all/services
const TreeNode = Tree.TreeNode;

function categoryLeaf(item = {}) {
  return (
    <TreeNode
      title={
        <Link to={{ pathname: `/categories/${item.id}/services` }}>
          {item.name}
        </Link>
      }
      key={item.id}
    >
      {item.children.map((child) => {
        return categoryLeaf(child);
      })}
    </TreeNode>
  );
}

class CategoriesTree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      selectedCategory: 0,
    };
  }

  // category

  onSelectCategory = (node) => {
    // console.log(Number(node[0]));
    this.setState({ selectedCategory: Number(node[0]) });
  };

  onOpenCategory = (node) => {
    const { selectedCategory } = this.state;
    if (selectedCategory) {
      this.props.fetchCategory(selectedCategory);
    }
    this.setState({ isVisible: true });
  };

  onAddCategory = () => {
    const { selectedCategory } = this.state;
    this.setState(
      {
        isVisible: true,
      },
      () => {
        this.props.onAddCategory(selectedCategory);
      }
    );
  };

  onSaveCategory = () => {
    const { category } = this.props;
    // console.log(category);
    this.setState({ isVisible: false }, () => {
      if (category.id) {
        this.props.onSave(category);
      } else {
        this.props.onCreate(category);
      }
    });
  };

  onCancel = () => {
    this.setState({ isVisible: false });
  };

  onDelete = () => {
    this.props.onDelete(this.state.selectedCategory);
  };

  render() {
    const { isVisible, selectedCategory } = this.state;
    const {
      isCategoryLoading,
      category,
      categories,
      onChangeName,
      onChangePosition,
      onChangeCustomer,
      onChangeCategoryType,
      onChangeExtCode,
    } = this.props;

    const openButton =
      selectedCategory > 0 ? (
        <Button
          key={2}
          onClick={this.onOpenCategory}
          style={{ marginLeft: 10 }}
        >
          Открыть
        </Button>
      ) : null;

    const deleteButton =
      selectedCategory > 0 ? (
        <Button key="1" type="danger" ghost onClick={this.onDelete}>
          Удалить
        </Button>
      ) : null;

    return (
      <React.Fragment>
        <Card
          // loading={isLoading}
          title={[
            <Button key={1} onClick={this.onAddCategory}>
              Создать
            </Button>,
            openButton,
          ]}
          extra={deleteButton}
        >
          <Tree
            showLine
            defaultExpandedKeys={["0"]}
            style={{ height: 600, overflow: "auto" }}
            onSelect={this.onSelectCategory}
          >
            <TreeNode
              title={
                <Link to={{ pathname: `/categories/all/services` }}>
                  Категории
                </Link>
              }
              key="0"
            >
              {categories.map((item, i) => categoryLeaf(item))};
            </TreeNode>
          </Tree>
        </Card>

        <Category
          isLoading={isCategoryLoading}
          visible={isVisible}
          category={category}
          onSave={this.onSaveCategory}
          onCancel={this.onCancel}
          onChangeName={onChangeName}
          onChangePosition={onChangePosition}
          onChangeCustomer={onChangeCustomer}
          onChangeCategoryType={onChangeCategoryType}
          onChangeExtCode={onChangeExtCode}
        />
      </React.Fragment>
    );
  }
}

CategoriesTree.propTypes = {
  onSelectCategory: PropTypes.func,
  categories: PropTypes.arrayOf(PropTypes.object),
};

export default CategoriesTree;
