// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import update from "immutability-helper";
import { Row, Col, List, Avatar, Divider, Tooltip, Button } from "antd";
import { v4 as uuidv4 } from "uuid";
import FormTodoItem from "../FormTodoItem";

import "moment/locale/ru";
import _ from "lodash/array";
import SelectFetch from "../../../components/SelectFetch";

class Todos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      todo: null,
    };
  }

  onAddTodo = () => {
    const { todo } = this.state;
    const { todos } = this.props;
    this.props.onAddTodo({
      id: uuidv4(),
      position: todos.length + 1,
      todo_item: todo,
      is_exist: false,
      _destroy: false,
    });
    this.setState({ todo: null });
  };

  onSelectTodo = (todo) => {
    this.setState({ todo });
  };

  onAddItem = () => {
    this.props.onNewTodoItem()
  }

  render() {
    const { todo } = this.state;
    const { todoType, todos, onDeleteTodo } = this.props;

    return (
      <React.Fragment>
        <Row gutter={16}>
          <Col span={22}>
            <SelectFetch
              showSearch
              showSubtitle
              showFooter
              placeholder="Задача"
              onChange={this.onSelectTodo}
              onAddItem={this.onAddItem}
              selected={todo}
              url={`/todo_items/search/?todo_type=${todoType}`}
            />
          </Col>
          <Col span={2} style={{ textAlign: "left" }}>
            <Tooltip placement="bottom" title={"Добавить"}>
              <Button disabled={todo ? false : true} type="primary" icon="plus" onClick={this.onAddTodo} />
            </Tooltip>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <List
              size="small"
              itemLayout="horizontal"
              dataSource={todos}
              renderItem={(item) => (
                <List.Item
                  actions={[
                    <Button
                      type="dashed"
                      shape="circle"
                      icon="close"
                      onClick={onDeleteTodo.bind(this, item.id)}
                    />,
                  ]}
                >
                  <List.Item.Meta
                    title={item.todo_item ? item.todo_item.name : null}
                  />
                </List.Item>
              )}
            />
          </Col>
        </Row>
        <FormTodoItem />
      </React.Fragment>
    );
  }
}
Todos.propTypes = {
  todo: PropTypes.arrayOf(PropTypes.object),
};

export default Todos;
