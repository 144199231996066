// @flow
import React, { Component, Fragment } from "react";
import { Row, Col } from "antd";
import { LineChart } from "bizcharts";

import accounting from "accounting";
import moment from "moment";
import "moment/locale/ru";

class Line extends Component {
  render() {
    const { currentUser, fetchParams, data } = this.props;

    let series = [];

    data.map((item) => {
      series.push(
        {
          period: moment(item.period).format("DD.MM"),
          status: "Факт (выполнение)",
          quantity: Number(item.et_finished),
        },
        {
          period: moment(item.period).format("DD.MM"),
          status: "План (выполнение)",
          quantity: Number(item.et_planned),
        }
      );
    });

    return (
      <Row gutter={1}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <LineChart
            data={series}
            title={{
              visible: true,
              text: "Регламент",
            }}
            // description={{
            //   visible: true,
            //   text: "по статусам",
            // }}
            smooth
            point={{
              shape: "hollow-circle",
              visible: true,
              style: () => {},
            }}
            xField="period"
            yField="quantity"
            seriesField="status"
          />
        </Col>
      </Row>
    );
  }
}

export default Line;
