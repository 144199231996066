import api from "../api";

const Actions = {};

Actions.fetchSections = () => {
  return (dispatch, getState) => {
    dispatch({type: "FETCH_SECTIONS_REQUEST"});
    const params = getState().sections.fetchParams;
    api
      .fetch("/sections", params)
      .then(response => {
        dispatch({type: "FETCH_SECTIONS", response});
      })
      .catch(response => {
        console.log(response.error);
        dispatch({type: "FETCH_SECTIONS_FAILURE", response});
      });
  };
};

Actions.fetchSection = (id = null) => {
  return dispatch => {
    dispatch({type: "FETCH_SECTION_REQUEST"});
    api
      .fetch(`/sections/${id}`)
      .then(response => {
        dispatch({type: "FETCH_SECTION", response});
      })
      .catch(error => {
        api.handleError(error, "Пост");
        dispatch({type: "FETCH_SECTION_FAILURE"});
      });
  };
};

Actions.newSection = () => {
  return (dispatch, getState) => {
    dispatch({
      type: "NEW_SECTION"
    });
  };
};

Actions.createSection = (knowledgeBaseId) => {
  return (dispatch, getState) => {
    dispatch({type: "POST_SECTION_REQUEST"});

    const section = getState().sections.section;
    const permissions = getState().sections.permissions;

    const data = {
      section: {
        knowledge_base_id: knowledgeBaseId,
        title: section.title,
        section_permissions_attributes: permissions
      }
    };

    return api
      .post(`/sections`, data)
      .then(response => {
        dispatch({type: "POST_SECTION_SUCCEED", response});
        api.openNotification("success", "Раздел", "Успешно создан!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Раздел", error);
          });
        }
        dispatch({type: "POST_SECTION_FAILURE", response});
      });
  };
};

Actions.changeSectionTitle = (value) => {
  return dispatch => {
    dispatch({
      type: "CHANGE_SECTION_TITLE",
      value: value
    });
  };
};

Actions.onChangeSectionPermissionForAdministrator = (value) => {
  return dispatch => {
    dispatch({
      type: "CHANGE_SECTION_PERMISSION_FOR_ADMINISTRATOR",
      value: value
    });
  };
};

Actions.onChangeSectionPermissionForManager = (value) => {
  return dispatch => {
    dispatch({
      type: "CHANGE_SECTION_PERMISSION_FOR_MANAGER",
      value: value
    });
  };
};

Actions.onChangeSectionPermissionForEngineer = (value) => {
  return dispatch => {
    dispatch({
      type: "CHANGE_SECTION_PERMISSION_FOR_ENGINEER",
      value: value
    });
  };
};

Actions.onChangeSectionPermissionForSupervisor = (value) => {
  return dispatch => {
    dispatch({
      type: "CHANGE_SECTION_PERMISSION_FOR_SUPERVISOR",
      value: value
    });
  };
};

Actions.onChangeSectionPermissionForSimple = (value) => {
  return dispatch => {
    dispatch({
      type: "CHANGE_SECTION_PERMISSION_FOR_SIMPLE",
      value: value
    });
  };
};

Actions.updateSection = (knowledgeBaseId, id) => {
  return (dispatch, getState) => {
    dispatch({type: "PATCH_SECTION_REQUEST"});

    const section = getState().sections.section;
    const permissions = getState().sections.permissions;

    const data = {
      section: {
        title: section.title,
        section_permissions_attributes: permissions
      }
    };

    api
      .patch(`/sections/${id}`, data)
      .then(response => {
        dispatch({type: "PATCH_SECTION_SUCCEED", response});
        api.openNotification("success", "Раздел", "Успешно обновлен!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Раздел", error);
          });
        }
        dispatch({type: "PATCH_SECTION_FAILURE", response});
      });
  };
};

Actions.deleteSection = (id) => {
  return dispatch => {
    dispatch({ type: "DELETE_SECTION_REQUEST" });
    return api
      .delete(`/sections/${id}`)
      .then(response => {
        api.openNotification("success", "Раздел", "Успешно удален!");
        dispatch({ type: "DELETE_SECTION", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Раздел", error);
          });
        }
        dispatch({
          type: "DELETE_SECTION_FAILURE"
        });
      });
  };
};

// Actions.onChangePage = value => {
//   return dispatch => {
//     dispatch({
//       type: "REQUEST_FAQ_CHANGE_PAGE",
//       value: value
//     });
//     return Promise.resolve();
//   };
// };
//
// Actions.onChangeLimit = value => {
//   return dispatch => {
//     dispatch({
//       type: "REQUEST_FAQ_CHANGE_LIMIT",
//       value: value
//     });
//     return Promise.resolve();
//   };
// };
//
// Actions.onSortBy = (field, direction) => {
//   return dispatch => {
//     dispatch({
//       type: "POSTS_SORT_BY",
//       field: field,
//       direction: direction
//     });
//     return Promise.resolve();
//   };
// };
//

//
//   Actions.onChangeBody = value => {
//     return dispatch => {
//       dispatch({
//         type: "FAQ_CHANGE_BODY",
//         value: value
//       });
//     };
//   };
//
//   Actions.onChangeTags = values => {
//     return dispatch => {
//       dispatch({
//         type: "FAQ_CHANGE_TAGS",
//         values: values
//       });
//     };
//   };
//
// Actions.onChangePublished = value => {
//   return dispatch => {
//     dispatch({
//       type: "FAQ_CHANGE_PUBLISHED",
//       value: value
//     });
//   };
// };
//
// Actions.onChangeApplicationType = value => {
//   return dispatch => {
//     dispatch({
//       type: "FAQ_CHANGE_APPLICATION_TYPE",
//       value: value
//     });
//   };
// };
//
//
// Actions.onChangeContex = value => {
//   return dispatch => {
//     dispatch({
//       type: "FAQ_CHANGE_CONTEX",
//       value: value
//     });
//   };
// };
//
// Actions.onChangeSubcontext = value => {
//     return dispatch => {
//       dispatch({
//         type: "FAQ_CHANGE_SUBCONTEX",
//         value: value
//       });
//     };
//   };


export default Actions;
