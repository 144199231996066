import React from "react";
import { Link } from "react-router-dom";
import {Avatar, List, Button, Typography} from "antd";
import classNames from "classnames";
import moment from "moment";
import "moment/locale/ru";

const ButtonGroup = Button.Group;

const {Text} = Typography;

export default function NoticeList({
  data = [],
  onClick,
  title,
  locale,
  emptyText,
  emptyImage,
  onAchive = null,
  onClear = null,
  showClear = true,
}) {
  if (data.length === 0) {
    return (
      <React.Fragment>
        <div className={"notFound"}>
          {emptyImage ? <img src={emptyImage} alt="not found" /> : null}
          <div><Link to={`/notices`}>перейти в архив уведомлений</Link></div>
        </div>
      </React.Fragment>
    );
  }
  return (
    <div>
      <List className={"notice-list"}>
        {data.map((item, i) => {
          const itemCls = classNames("notice-list item", {
            ["notice-list read"]: item.read,
          });
          // eslint-disable-next-line no-nested-ternary
          const leftIcon = item.avatar ? (
            typeof item.avatar === "string" ? (
              <Avatar className={"notice-list avatar"} src={item.avatar} />
            ) : (
              <span className={"notice-list iconElement"}>{item.avatar}</span>
            )
          ) : (
            <Avatar className={"notice-list avatar"} />
          );

          return (
            <List.Item
              className={itemCls}
              key={item.key || i}
              onClick={() => onClick(item)}
              // actions={[<a key="list-loadmore-more">more</a>]}
            >
              <List.Item.Meta
                className={"notice-list meta"}
                avatar={leftIcon}
                title={
                  <div className={"notice-list title"}>
                    <span>{item.title}</span>
                    <br />
                    <span>{item.extra}</span>
                  </div>
                }
                description={
                  <div>
                    <div
                      className={"notice-list description"}
                      title={item.description}
                    >
                      <Text>
                        <div dangerouslySetInnerHTML={{__html: item.description}}/>
                      </Text>
                    </div>
                    <div className={"notice-list datetime"}>
                      {moment(item.datetime).format("LLL")}
                    </div>
                  </div>
                }
              />
            </List.Item>
          );
        })}
      </List>
      <div className={"notice-list noticeBottomBar"}>
        <ButtonGroup style={{ padding: 5 }}>
          <Button style={{ width: "50%" }} onClick={onClear}>
            Отметить все
          </Button>

          <Button style={{ width: "50%" }} onClick={onAchive}>Архив уведомлений</Button>
        </ButtonGroup>
      </div>
    </div>
  );
}
