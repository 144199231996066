// @flow
import React, {Component} from "react";
import {Button, Col, DatePicker, PageHeader, Popover, Radio, Row, Statistic, Typography} from "antd";
import Timeline from "react-calendar-timeline";
import {Link} from "react-router-dom";
import moment from "moment";
import "moment/locale/ru";
import ProgressInfo from "./ProgressInfo";
import SelectMultiFetch from "../../../components/SelectMultiFetch";

const {Paragraph} = Typography;
const {RangePicker} = DatePicker;
const dateFormat = "DD.MM.YYYY";

const eventTitle = (type, id, number) => {
  if (type === 'tasks') {
    return (<div>
      <Link
        to={`/${type}/${id}/edit`}
        target="_blank"
      >{`Задача #${number}`}</Link>
    </div>);
  }

  return (
    <div>
      <Link
        to={`/order_tasks/${id}/edit`}
        target="_blank"
      >{`Задача по заказу #${number}`}</Link>
    </div>
  );
};

const eventContent = (data = [], customer, entity, order) => {
  return (
    <div style={{width: 540}}>
      <ProgressInfo
        data={data}
        customer={customer}
        entity={entity}
        order={order}
      />
    </div>
  );
};

const groupRenderer = ({group}) => {
  return (
    <Paragraph ellipsis>{group.title}</Paragraph>
  );
};

const itemRenderer = ({item, itemContext, timelineContext, getItemProps, getResizeProps}) => {
  const {
    left: leftResizeProps,
    right: rightResizeProps
  } = getResizeProps();

  const data = [
    {
      id: "1",
      progress: item.progress,
      start_time: item.start_time,
      end_time: item.end_time
    }
  ];

  let now = item.now;
  let startTime = moment.max([moment(timelineContext.visibleTimeStart), item.start_time]);
  let endTime = moment.min([moment(timelineContext.visibleTimeEnd), item.end_time]);

  // Number of pixels per millisecond on the visible part of the timeframe
  let scale = timelineContext.timelineWidth / (timelineContext.visibleTimeEnd - timelineContext.visibleTimeStart);

  let deadlineSegmentWidth = 6;
  let ongoingSegmentWidth = itemContext.dimensions.width < deadlineSegmentWidth ?
    0 :
    now.isBetween(startTime, endTime) ?
      now.diff(startTime) * scale :
      endTime.diff(startTime) * scale;
  let overdueSegmentWidth = itemContext.dimensions.width < deadlineSegmentWidth ?
    0 :
    now.diff(endTime) * scale;

  if (item.type === 'tasks') {
    return (
      <Popover
        placement="bottom"
        title={eventTitle(item.type, item.event_id, item.number)}
        content={eventContent(data, item.customer, item.entity, item.order)}
        trigger="hover"
      >
        <div {...getItemProps(item.itemProps)}>
          {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ""}

          <div
            className="rct-item-content"
            style={{
              background: 'rgb(33, 150, 243)',
              border: 'none',
              borderRadius: '0',
              maxHeight: `${itemContext.dimensions.height}`,
              padding: '0px',
              width: `${ongoingSegmentWidth}px`,
              left: '0px'
            }}
          >
          </div>

          <div
            className="rct-item-content"
            style={{
              position: 'absolute',
              background: 'rgb(255 0 0)',
              border: 'none',
              borderRadius: '0',
              maxHeight: `${itemContext.dimensions.height}`,
              padding: '0px',
              width: `${deadlineSegmentWidth}px`,
              left: `${itemContext.dimensions.width}px`,
            }}
          >
          </div>

          {overdueSegmentWidth < 0 ?
            null :
            <div
              className="rct-item-content"
              style={{
                position: 'absolute',
                background: 'rgb(255 0 0 / 60%)',
                border: 'none',
                borderRadius: '0',
                maxHeight: `${itemContext.dimensions.height}`,
                padding: '0px',
                width: `${overdueSegmentWidth}px`,
                left: `${itemContext.dimensions.width}px`,
              }}
            >
            </div>}

          {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ""}
        </div>
      </Popover>
    );
  }

  return (
    <div {...getItemProps(item.itemProps)}>
      {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ""}

      <Popover
        placement="bottom"
        title={eventTitle(item.type, item.event_id, item.number)}
        content={eventContent(data, item.customer, item.entity, item.order)}
        trigger="hover"
      >
        <div
          className="rct-item-content"
          style={{
            maxHeight: `${itemContext.dimensions.height}`,
            width: "100%"
          }}
        >
        </div>
      </Popover>

      {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ""}
    </div>
  );
};

class Gantt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
    };
  }

  onChangeUsers = (values) => {
    let userIds = values.map((value) => value.id);

    this.setState({ users: values }, () => {
      this.props.onChangeGanttUsers(userIds);
    });
  };

  onChangeDateRange = (values) => {
    this.props.onChangeDateRange(values);
  };

  onChangeTaskAssigneeType = (e) => {
    this.props.onChangeTaskAssigneeType(e.target.value)
  };

  onTimeChange = (visibleTimeStart, visibleTimeEnd, updateScrollCanvas, unit) => {
    updateScrollCanvas(visibleTimeStart, visibleTimeEnd);

    this.onChangeDateRange([
      moment(moment(visibleTimeStart), dateFormat),
      moment(moment(visibleTimeEnd - 1), dateFormat)
    ]);
  };

  render() {
    const {
      isGanttLoading,
      startDate,
      endDate,
      ganttEvents,
      ganttTotals,
      groupBy,
      status,
      onChangeGroupBy,
      onChangeGanttUsers,
      onChangeDateRange,
      onChangeGanttStatus
    } = this.props;

    let {
      users,
    } = this.state;

    const totalStyle = {
      cursor: "pointer",
      color: status == null ? "#1777d1" : "#000000d9"
    };
    const processingStyle = {
      cursor: "pointer",
      color: status == "processing" ? "#1777d1" : "#000000d9"
    };
    const finishedStyle = {
      cursor: "pointer",
      color: status == "finished" ? "#1777d1" : "#000000d9"
    };
    const noAssigneesStyle = {
      cursor: "pointer",
      color: status == "noAssignees" ? "#1777d1" : "#000000d9"
    };

    let eventType = ganttEvents.type;

    let now = moment();
    let initTimeStart = moment(startDate, dateFormat).startOf('day');
    let initTimeEnd = moment(endDate, dateFormat).endOf('day');

    const groups = ganttEvents.groups || [];
    const events = ganttEvents.tasks?.map((task, i) => {
      let eventProps = eventType === 'tasks' ?
        {
          style: {
            background: 'none',
            border: 'none',
          }
        } :
        {}

      return {
        type: eventType,
        id: i,
        event_id: task.id,
        group: task.group,
        start_time: moment(task.start_time),
        end_time: moment(task.end_time),
        now: now,
        title: task.title,
        number: task.number,
        status: task.status,
        progress: task.progress,
        customer: task.customer,
        order: task.order,
        entity: task.entity,
        canResize: false,
        canMove: false,
        itemProps: eventProps,
      };
    });

    return (
      <PageHeader
        footer={
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Timeline
                buffer={1}
                sidebarWidth={300}
                groups={groups}
                items={events}
                visibleTimeStart={initTimeStart.valueOf()}
                visibleTimeEnd={initTimeEnd.valueOf()}
                groupRenderer={groupRenderer}
                itemRenderer={itemRenderer}
                onTimeChange={this.onTimeChange}
              />
            </Col>
          </Row>
        }
      >
        <Row gutter={16}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Row gutter={16}>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Radio.Group value={groupBy} onChange={onChangeGroupBy}>
                      <Radio value="users">исполнители</Radio>
                      <Radio value="orders">заказы</Radio>
                      <Radio value="tasks">задачи</Radio>
                    </Radio.Group>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <RangePicker
                      style={{width: "100%"}}
                      value={[
                        moment(startDate, dateFormat),
                        moment(endDate, dateFormat)
                      ]}
                      format={dateFormat}
                      onChange={this.onChangeDateRange}
                    />
                  </Col>
                </Row>
                <Row gutter={16} style={{paddingTop: 10}}>
                  <Col xs={0} sm={0} md={0} lg={12} xl={12}/>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Button
                      type={"ghost"}
                      key="today"
                      onClick={() => this.onChangeDateRange([
                        moment(now, dateFormat),
                        moment(now, dateFormat)
                      ])}>
                      {"Сегодня"}
                    </Button>
                    <Button
                      type={"ghost"}
                      key="month"
                      onClick={() => this.onChangeDateRange([
                        moment(now.clone().add(-15, 'days'), dateFormat),
                        moment(now.clone().add(15, 'days'), dateFormat)
                      ])}
                      style={{ marginLeft: "8px" }}>
                      {"Месяц"}
                    </Button>
                    <Button
                      type={"ghost"}
                      key="quarter"
                      onClick={() => this.onChangeDateRange([
                        moment(now.clone().add(-45, 'days'), dateFormat),
                        moment(now.clone().add(15, 'days'), dateFormat)
                      ])}
                      style={{ marginLeft: "8px" }}>
                      {"Квартал"}
                    </Button>
                  </Col>
                </Row>

                <Row gutter={16} style={{paddingTop: 10}}>
                  <Col xs={0} sm={0} md={0} lg={12} xl={12}/>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <SelectMultiFetch
                      showSearch
                      placeholder="Пользователи"
                      onChange={this.onChangeUsers}
                      selected={users ? users : []}
                      url={"/users/search/?all='true'"}
                    />
                  </Col>
                </Row>

                {groupBy === 'tasks' ?
                  <Row gutter={16} style={{paddingTop: 10}}>
                    <Col xs={0} sm={0} md={0} lg={12} xl={12}/>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Radio.Group
                        defaultValue="all"
                        buttonStyle="solid"
                        onChange={this.onChangeTaskAssigneeType}
                      >
                        <Radio.Button value="all">Все</Radio.Button>
                        <Radio.Button value="current">Мои</Radio.Button>
                        <Radio.Button value="observer">Наблюдаю</Radio.Button>
                        <Radio.Button value="own">Поручил</Radio.Button>
                      </Radio.Group>
                    </Col>
                  </Row> :
                  null}
              </Col>
            </Row>
          </Col>
          <Col xs={0} sm={0} md={0} lg={2} xl={2}/>
          <Col xs={24} sm={24} md={12} lg={10} xl={10}>
            <Row gutter={16}>
              <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                <Statistic
                  title="Всего задач"
                  prefix={
                    <span onClick={onChangeGanttStatus.bind(this, null)}>
                      {ganttTotals.total}
                    </span>
                  }
                  value={" "}
                  valueStyle={totalStyle}
                />
              </Col>
              <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                <Statistic
                  title="Задач в работе"
                  prefix={
                    <span
                      onClick={onChangeGanttStatus.bind(this, "processing")}
                    >
                      {ganttTotals.processing}
                    </span>
                  }
                  value={" "}
                  valueStyle={processingStyle}
                />
              </Col>
              <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                <Statistic
                  title="Задач завершено"
                  prefix={
                    <span onClick={onChangeGanttStatus.bind(this, "finished")}>
                      {ganttTotals.finished}
                    </span>
                  }
                  value={" "}
                  valueStyle={finishedStyle}
                />
              </Col>
              <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                <Statistic
                  title="Нет исполнителей"
                  prefix={
                    <span
                      onClick={onChangeGanttStatus.bind(this, "noAssignees")}
                    >
                      {ganttTotals.noassignees}
                    </span>
                  }
                  value={" "}
                  // valueStyle={noAssigneesStyle}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </PageHeader>
    );
  }
}

export default Gantt;
