// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Form, Checkbox, Divider } from "antd";

const FormItem = Form.Item;

class Profile extends Component {
  render() {
    const {
      settings,
      onChangeTickets,
    } = this.props;

    return (
        <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Divider>Настройки видимости</Divider>
        </Col>
        <Col xs={24} sm={24} md={24} lg={4} xl={4}>
          <Checkbox
            checked={settings.tickets ? settings.tickets.all : false}
            onChange={onChangeTickets}
          >
            Заявки
          </Checkbox>
        </Col>
      </Row>
    );
  }
}

Profile.propTypes = {};

export default Profile;
